<template>
  <div id="app" class="proforma">

      <!-- navigation -->
      <b-navbar type="light" variant="" class="proforma-navbar">
          <b-container fluid d-flex justify-content-between align-items-center>
              <b-navbar-brand v-if="isSHS" href="https://www.sustainablehealthsystems.com" class="logo logo-shs">
                  <img class="img-responsive" src="./assets/shs_logo.png" alt="Sustainable Health Systems" />
                  Sustainable Health Systems
              </b-navbar-brand>
              <b-navbar-brand v-else href="https://www.wellcoacheshealthcare.com" class="logo">
                  <img class="img-responsive" src="./assets/WellcoachesHealthcare.png" alt="Wellcoaches Healthcare" />
              </b-navbar-brand>

            <div class="main-menu" @click="showMenu = !showMenu" :class="iconClass">

                  <button class="proforma-navbar__hamburger">
                      <span></span>
                      <span></span>
                      <span></span>
                  </button>
                  <nav class="main-menu__wrapper">
                      <ul v-if="isSHS" class="main-menu__list menu-shs">
                          <li class="main-menu__item">
                              <a href="#" class="main-menu__link">Proforma</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://sustainablehealthsystems.com/products-services" class="main-menu__link">Products &amp; Services</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://sustainablehealthsystems.com/our-stances"
                                 class="main-menu__link">Our Stances</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://sustainablehealthsystems.com/blog" class="main-menu__link">Blog</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://sustainablehealthsystems.com/about-us" class="main-menu__link">About</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://sustainablehealthsystems.com/contact-us" class="main-menu__link">Contact</a>
                          </li>
                      </ul>
                      <ul v-else class="main-menu__list">
                          <li class="main-menu__item">
                              <a href="https://www.wellcoacheshealthcare.com/who-we-are" class="main-menu__link">Who We Are</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://www.wellcoacheshealthcare.com/lifestyle-medicine"
                                 class="main-menu__link">Lifestyle Medicine</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://www.wellcoacheshealthcare.com/social-determinants" class="main-menu__link">Social Determinants</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://www.wellcoacheshealthcare.com/software" class="main-menu__link">Software</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://www.wellcoacheshealthcare.com/financial-solutions" class="main-menu__link">Financial Solutions</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://www.wellcoacheshealthcare.com/patients" class="main-menu__link">Patients</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://www.wellcoacheshealthcare.com/get-started" class="main-menu__link">Get Started</a>
                          </li>
                          <li class="main-menu__item">
                              <a href="https://www.wellcoacheshealthcare.com/resources" class="main-menu__link">Resources</a>
                          </li>
                      </ul>
                  </nav>

            </div>

          </b-container>
      </b-navbar>
      <!-- content -->
      <router-view/>
    <footer>
        <b-container class="text-center pb-4">
            <template v-if="isSHS">
                ©2023 Sustainable Health Systems
            </template>
            <template v-else>
                ©2023 Wellcoaches<sup>®</sup> | Healthcare
            </template>
        </b-container>
    </footer>
  </div>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        data() {
            return {
                showMenu: false
            };
        },
        computed: {
            iconClass() {
                return this.showMenu ? 'menu-open' : 'menu-closed';
            },
            isLocalhost() {
                return window.location.host === 'localhost:8080';
            },
            isWellcoaches() {
                return (window.location.host === 'proforma.wellcoaches.app');
            },
            isSHS() {
                return this.isLocalhost || (window.location.host === 'proforma.sustainablehealthsystems.com');
            }
        }
    });

</script>

<style lang="scss">
$blue:#22AFE1;

body {
  box-sizing:border-box;
  //background-color: #f0f0f0!important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .navbar-brand {
    padding: 0px;
  }
}

.proforma { margin:0 auto; }

.proforma-navbar {
    background-color: #fff;
    > .container-fluid {
        max-width:1460px;
        margin: 0 auto;
    }
}

.proforma-navbar__hamburger {

    @media(max-width:450px) {
        padding: 10px;
    }

    @media(min-width: 1200px) {
        display: none;
    }
}

.navbar-brand {
    > img {
        height: 24px;
        width: auto;
        @media (min-width: 451px) {
            height:60px;
            padding: 15px;
        }
    }
}

.main-menu__list {
    padding-left:0;
    margin-bottom: 0;
    @media(min-width:1200px) {
        display: flex;
        justify-content: space-between;
    }
}

.main-menu__item {
    list-style: none;
    padding:0;
}

.main-menu__link {
    color:#888;
    display: block;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }

    @media(max-width:1199px) {
        color:#fff;
        font-size: 1.5em;
        padding: 10px;
        margin:5px;
    }

    @media(min-width: 1025px) {
        &:hover {
            color:#000;
        }
    }

    @media(min-width: 1200px) {
        font-size: 14px;
        padding:10px;
    }
}


@media(max-width:1199px) {

    .proforma-navbar__hamburger {

        text-indent: -9999px;
        display: block;
        background: none;
        cursor: pointer;
        border: 0 none;
        position: relative;
        z-index: 101;
        font-size: 0;
        line-height: 0;
        outline: none;
        width: 24px;
        height: 16px;

        span {
            transition:all .25s ease-in-out;
            position: absolute;
            width: 100%;
            left: 0;
            overflow: hidden;

            &:first-child {
                top: 0;
            }
        }

        span {
            display: block;
            background-color: $blue;

            &:last-child {
                margin: 0;
            }
        }

        span {
            height: 2px;

            &:nth-child(2) {
                top: 8px;
            }

            &:nth-child(3) {
                top: 16px;
            }
        }
    }

    .menu-open .proforma-navbar__hamburger {
        span {
            background-color: #fff;
        }

        span:nth-child(1) {
            top: 9px;
        }

        span:nth-child(2) {
            top: 9px;
        }

        span:nth-child(3) {
            top: 9px;
        }

        span:nth-child(1) {
            width: 0%;
            left: 50%;
        }

        span:nth-child(2) {
            transform:rotate(45deg);
        }

        span:nth-child(3) {
            transform:rotate(-45deg);
        }
    }

    .main-menu__wrapper {
        display: table;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        background: $blue;

        > ul {
            display: table-cell;
            vertical-align: top;
            padding-top: 60px;
            background: $blue;
            text-align: center;
        }
    }

    .menu-closed .main-menu__wrapper {
        transform:translateY(-200%);
    }

    .menu-open .main-menu__wrapper {
            transition: all .6s;
            transform:translateY(0);
            pointer-events: auto;
        height: 100%;

        > ul > li > ul > li {
                display: block;
            }
    }

}

.logo {
  display: block;
}
.logo-shs {
    font-size: 32px !important;
    font-family: 'PT Sans Narrow',helvetica neue,arial,helvetica,sans-serif !important;
    color: #0f5f96 !important;
    img {
        height: 50px;
        padding: 0;
    }
}

.menu-shs {
    li {
        a {
            font-family: 'PT Sans Narrow', helvetica neue, arial, helvetica, sans-serif !important;
            text-decoration: none;
            font-size: 18px;
            color: black;
            padding: 10px 18px;
            &:hover {
                color: white;
                background: $blue;
            }
            transition: background-color .3s ease,color .3s ease;
        }
    }
}

footer {
    font-size: 12px;
    color:#888;
}

</style>

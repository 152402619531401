<template>
  <div>
    <label v-for="(name, index) in items" v-bind:key="index" :for="id + '_' + index">
      <input :id="id + '_' + index" :name="id" type="radio" :value="index" v-model="value" @change="change" />
      {{name}}
    </label>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    id: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    dflt: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      value: null,
    }
  },
  mounted() {
    //  initial value
    this.value = this.dflt;
  },
  methods: {
    change() {
      this.$emit('change', this.id, this.value);
    },
  },
}
</script>

<style lang="scss">
label {
  white-space: nowrap;
}
</style>

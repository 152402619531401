let data = [

  [ 1000,0,1,0,0, 7157,76125,7157,-68969, 14859,51673,14859,-36814, 41303,45821,41303,-4519, 64526,33047,64526,31478, 88671,38210,88671,50461, -3726532,-41,27 ],
  [ 1000,0,2,0,0, 14313,77037,7157,-31362, 29719,54348,14859,-12314, 82605,51673,41303,15466, 129051,57581,64526,35735, 177342,67530,88671,54906, -3479778,-40,26 ],
  [ 1000,0,3,0,0, 21470,77949,7157,-18826, 44578,57022,14859,-4148, 123908,72039,41303,17290, 193577,67189,64526,42129, 266012,81229,88671,61594, -3233247,-38,25 ],
  [ 1000,0,5,0,0, 32397,79341,7068,-10242, 70528,75726,14106,-1040, 199954,82605,39991,23470, 318781,180907,63756,27575, 438037,320080,87607,23591, -3051865,-37,24 ],
  [ 1000,0,10,0,0, 54856,82207,6717,-3349, 143594,90080,11966,4460, 435283,313044,36274,10187, 736795,380892,61400,29659, 1014706,539200,84559,39626, -2199134,-29,18 ],
  [ 1000,0,15,0,0, 72474,84457,6442,-1065, 228891,195375,10404,1523, 739419,384069,33610,16152, 1302547,588304,59207,32466, 1815592,920048,82527,40707, -837054,-12,0 ],
  [ 1000,0,25,0,0, 85029,99400,6184,-1045, 392538,349113,8221,909, 1497419,726954,28797,14817, 2798300,1286216,53813,29079, 4113320,2043229,79102,39809, 2149026,34,0 ],
  [ 1000,0,50,0,0, 86090,99537,6149,-960, 550383,412300,6938,1741, 2682434,1232475,24386,13181, 5249868,2475893,47726,25218, 8289744,4030414,75361,38721, 7431353,63,0 ],
  [ 1000,0,75,0,0, 86090,99537,6149,-960, 553155,412924,6914,1753, 2718258,1237211,24270,13224, 5326023,2486602,47554,25352, 8427478,4050681,75245,39079, 7689752,64,0 ],
  [ 1000,25,1,0,0, 10486,76583,10486,-66097, 17396,51945,17396,-34549, 58503,48391,58503,10112, 70353,33936,70353,36417, 96768,55152,96768,41616, -3673080,-41,27 ],
  [ 1000,25,2,0,0, 18384,77597,9192,-29606, 33724,54889,16862,-10582, 103557,69305,51778,17126, 140706,59402,70353,40652, 193535,70117,96768,61709, -3422726,-39,26 ],
  [ 1000,25,3,0,0, 26282,78611,8761,-17443, 50052,57833,16684,-2594, 148611,75703,49537,24302, 211058,69841,70353,47072, 290303,100703,96768,63200, -3127941,-37,24 ],
  [ 1000,25,5,0,0, 38338,80159,8365,-9124, 78597,77031,15719,313, 231621,87283,46324,28868, 347544,185259,69509,32457, 478018,326327,95604,30338, -2887514,-35,23 ],
  [ 1000,25,10,0,0, 63105,83341,7727,-2478, 159134,92890,13261,5520, 488801,335398,40733,12784, 803138,390916,66928,34352, 1107210,569309,92268,44825, -1930775,-27,16 ],
  [ 1000,25,15,0,0, 82524,85839,7335,-295, 253338,200068,11515,2421, 821526,396027,37342,19341, 1419876,714330,64540,32070, 1980882,961340,90040,46343, -502650,-6,0 ],
  [ 1000,25,25,0,0, 96349,100957,7007,-335, 434593,371618,9101,1319, 1652447,840698,31778,15611, 3051840,1434232,58689,31108, 4486208,2215748,86273,43663, 2644005,37,0 ],
  [ 1000,25,50,0,0, 97516,101108,6965,-257, 609649,424860,7685,2329, 2954569,1300463,26860,15037, 5730588,2657307,52096,27939, 9038472,4404488,82168,42127, 8796134,68,0 ],
  [ 1000,25,75,0,0, 97516,101108,6965,-257, 612720,425554,7659,2340, 2993995,1382706,26732,14387, 5813890,2792497,51910,26977, 9188599,4426626,82041,42518, 8720192,67,0 ],
  [ 1000,50,1,0,0, 13816,77041,13816,-63226, 19933,52216,19933,-32283, 75704,65476,75704,10228, 76180,34874,76180,41306, 104865,56472,104865,48392, -3641954,-41,27 ],
  [ 1000,50,2,0,0, 22455,78157,11227,-27851, 37730,55430,18865,-8850, 124508,72422,62254,26043, 152360,61172,76180,45594, 209729,72653,104865,68538, -3332174,-39,25 ],
  [ 1000,50,3,0,0, 31015,79167,10338,-16051, 56077,58639,18692,-854, 168410,79363,56137,29682, 194798,71552,64933,41082, 266669,72658,88890,64670, -3080756,-37,24 ],
  [ 1000,50,5,0,0, 44280,80976,9661,-8006, 86667,78335,17333,1666, 263288,106475,52658,31363, 376306,189660,75261,37329, 518000,348295,103600,33941, -2767837,-34,22 ],
  [ 1000,50,10,0,0, 71355,84476,8737,-1607, 174673,95700,14556,6581, 542318,343238,45193,16590, 869481,400889,72457,39049, 1199715,583696,99976,51335, -1617743,-23,12 ],
  [ 1000,50,15,0,0, 92574,100558,8229,-710, 277786,204611,12627,3326, 903632,422501,41074,21870, 1537206,747073,69873,35915, 2146172,1002832,97553,51970, -118470,3,0 ],
  [ 1000,50,25,0,0, 107670,102514,7831,375, 476647,380238,9982,2019, 1807475,877592,34759,17882, 3305380,1595424,63565,32884, 4859096,2501408,93444,45340, 3258988,42,0 ],
  [ 1000,50,50,0,0, 108943,102680,7782,447, 668915,525156,8432,1812, 3226703,1536836,29334,15362, 6211308,2962607,56466,29534, 9787201,4763291,88975,45672, 9324171,69,0 ],
  [ 1000,50,75,0,0, 108943,102680,7782,447, 672285,525918,8404,1830, 3269732,1542586,29194,15421, 6301757,2975405,56266,29700, 9949719,4802971,88837,45953, 9620065,70,0 ],
  [ 1000,100,1,0,0, 20475,77957,20475,-57482, 25007,52759,25007,-27753, 110105,70615,110105,39489, 87834,36651,87834,51183, 121058,59014,121058,62045, -3512703,-40,26 ],
  [ 1000,100,2,0,0, 30596,79277,15298,-24340, 45741,56513,22870,-5386, 166411,78656,83206,43878, 175669,64713,87834,55478, 242117,77727,121058,82195, -3151069,-37,24 ],
  [ 1000,100,3,0,0, 40718,80596,13573,-13293, 66475,60267,22158,2070, 222718,101211,74239,40502, 263503,77848,87834,61885, 363175,195941,121058,55745, -2871740,-35,23 ],
  [ 1000,100,5,0,0, 56163,82610,12254,-5770, 102806,80944,20561,4372, 326622,115830,65324,42158, 433832,306823,86766,25402, 597962,360788,119592,47435, -2599209,-33,21 ],
  [ 1000,100,10,0,0, 87855,86745,10758,136, 205752,115206,17146,7545, 649353,373282,54113,23006, 1002168,516170,83514,40500, 1384723,741233,115394,53624, -1301195,-20,-42 ],
  [ 1000,100,15,0,0, 112674,103323,10015,831, 326680,332232,14849,-252, 1067846,536283,48538,24162, 1771865,877539,80539,40651, 2476752,1281787,112580,54317, 9288,6,0 ],
  [ 1000,100,25,0,0, 130310,105628,9477,1795, 560756,411364,11744,3129, 2117531,936714,40722,22708, 3812460,1781147,73317,39064, 5604872,2762666,107786,54658, 4901492,51,0 ],
  [ 1000,100,50,0,0, 131795,105823,9414,1855, 787446,564163,9926,2814, 3770971,1735147,34282,18507, 7172748,3353536,65207,34720, 11284658,5511339,102588,52485, 11825602,77,0 ],
  [ 1000,100,75,0,0, 131795,105823,9414,1855, 791416,565062,9893,2829, 3821206,1741911,34118,18565, 7277491,3383399,64978,34769, 11471959,5638541,102428,52084, 12088326,78,0 ],
  [ 1000,200,1,0,0, 33793,79789,33793,-45996, 35154,53845,35154,-18691, 178907,95410,178907,83497, 111143,55232,111143,55912, 153446,64147,153446,89299, -3309804,-39,25 ],
  [ 1000,200,2,0,0, 46879,81516,23440,-17318, 61763,58678,30882,1543, 250217,105639,125109,72289, 222287,71845,111143,75221, 306892,103594,153446,101649, -2833535,-35,22 ],
  [ 1000,200,3,0,0, 59966,83243,19989,-7759, 88373,77396,29458,3659, 321528,115719,107176,68603, 333430,183742,111143,49896, 460338,324191,153446,45382, -2670670,-34,21 ],
  [ 1000,200,5,0,0, 79928,85878,17439,-1298, 135085,86162,27017,9785, 453291,332105,90658,24237, 548882,339756,109776,41825, 757888,401997,151578,71178, -2430458,-32,20 ],
  [ 1000,200,10,0,0, 120854,104620,14798,1988, 267910,200148,22326,5647, 863423,419155,71952,37022, 1267542,586218,105629,56777, 1754739,914106,146228,70053, -497328,-6,0 ],
  [ 1000,200,15,0,0, 152873,108851,13589,3913, 424469,351004,19294,3339, 1396272,719197,63467,30776, 2241183,1167123,101872,48821, 3137911,1628755,142632,68598, 1222820,24,0 ],
  [ 1000,200,25,0,0, 175592,111856,12770,4635, 728973,533580,15266,4092, 2737642,1267190,52647,28278, 4826620,2400064,92820,46665, 7096424,3496342,136470,69232, 6992982,61,0 ],
  [ 1000,200,50,0,0, 177501,112108,12679,4671, 1024509,806491,12914,2748, 4859508,2342518,44177,22882, 9095628,4246655,82688,44082, 14279571,6963004,129814,66514, 15690229,86,0 ],
  [ 1000,200,75,0,0, 177501,112108,12679,4671, 1029677,821551,12871,2602, 4924153,2351160,43966,22973, 9228959,4389156,82401,43213, 14516439,7097640,129611,66239, 15846632,87,0 ],
  [ 1500,0,1,0,0, 11219,76765,11219,-65546, 21781,52992,21781,-31211, 54792,47971,54792,6820, 84533,36288,84533,48245, 116720,58481,116720,58239, -3638495,-41,27 ],
  [ 1500,0,2,0,0, 22438,78316,11219,-27939, 43563,56986,21781,-6712, 109584,70488,54792,19548, 169066,64113,84533,52476, 233440,76780,116720,78330, -3314857,-39,25 ],
  [ 1500,0,3,0,0, 33657,79867,11219,-15403, 65344,60979,21781,1455, 164376,78489,54792,28629, 253598,76912,84533,58896, 350159,194481,116720,51893, -3006523,-36,23 ],
  [ 1500,0,5,0,0, 50785,82235,11080,-6862, 103888,82183,20778,4341, 266276,93176,53255,34620, 417428,305342,83486,22417, 576612,358474,115322,43628, -2742084,-34,22 ],
  [ 1500,0,10,0,0, 50785,82235,6219,-3851, 103888,82183,8657,1809, 488539,337303,40712,12603, 431020,323148,35918,8989, 595909,377954,49659,18163, -2799986,-35,22 ],
  [ 1500,0,15,0,0, 113584,104263,10096,829, 348186,338613,15827,435, 1007180,531100,45781,21640, 1706859,872650,77585,37919, 2389594,1273353,108618,50738, -215009,1,0 ],
  [ 1500,0,25,0,0, 133245,106987,9691,1910, 612884,425672,12835,3921, 2084135,940627,40080,21991, 3696957,1790484,71095,36663, 5401570,2644612,103876,53018, 4697095,50,0 ],
  [ 1500,0,50,0,0, 134907,107218,9636,1978, 873792,705620,11014,2120, 3822833,1773743,34753,18628, 7028286,3386509,63894,33107, 10867580,5362157,98796,50049, 11235460,75,0 ],
  [ 1500,0,75,0,0, 134907,107218,9636,1978, 878380,706680,10980,2146, 3876512,1887371,34612,17760, 7133341,3402383,63691,33312, 11048056,5518682,98643,49369, 11256712,75,0 ],
  [ 1500,25,1,0,0, 14549,77223,14549,-62674, 24318,53264,24318,-28946, 71992,50541,71992,21451, 90360,37177,90360,53183, 124817,59752,124817,65065, -3573869,-40,26 ],
  [ 1500,25,2,0,0, 26509,78876,13254,-26183, 47568,57527,23784,-4979, 130535,73605,65268,28465, 180720,65884,90360,57418, 249633,79317,124817,85158, -3224305,-38,25 ],
  [ 1500,25,3,0,0, 38469,80529,12823,-14020, 70818,75675,23606,-1619, 189078,82004,63026,35692, 271080,79564,90360,63839, 374450,198234,124817,58739, -2933628,-36,23 ],
  [ 1500,25,5,0,0, 56727,83053,12377,-5744, 111957,83487,22391,5694, 297943,112368,59589,37115, 446190,309743,89238,27289, 616594,364771,123319,50364, -2611233,-33,21 ],
  [ 1500,25,10,0,0, 94230,101576,11538,-899, 230660,195014,19222,2971, 640329,373906,53361,22202, 1030442,538153,85870,41024, 1428438,766542,119037,55158, -1509552,-22,-48 ],
  [ 1500,25,15,0,0, 123634,105645,10990,1599, 372633,343306,16938,1333, 1089287,543059,49513,24829, 1824189,905192,82918,41773, 2554884,1314645,116131,56375, 257364,11,0 ],
  [ 1500,25,25,0,0, 144566,108544,10514,2620, 654938,522027,13716,2783, 2239163,1160420,43061,20745, 3950497,1952176,75971,38429, 5774458,2930721,111047,54687, 4612838,50,0 ],
  [ 1500,25,50,0,0, 146333,108789,10452,2682, 933058,718180,11761,2709, 4094967,1947781,37227,19520, 7509006,3676883,68264,34837, 11616308,5736731,105603,53451, 12194789,78,0 ],
  [ 1500,25,75,0,0, 146333,108789,10452,2682, 937945,733195,11724,2559, 4152249,2032716,37074,18924, 7621208,3693301,68046,35071, 11809176,5894577,105439,52809, 12265671,78,0 ],
  [ 1500,50,1,0,0, 17878,77681,17878,-59802, 26855,53535,26855,-26680, 89193,67626,89193,21567, 96187,53092,96187,43096, 132914,61073,132914,71841, -3564847,-40,26 ],
  [ 1500,50,2,0,0, 30580,79436,15290,-24428, 51574,58068,25787,-3247, 151487,76722,75743,37382, 192374,67654,96187,62360, 265827,81854,132914,91987, -3133753,-37,24 ],
  [ 1500,50,3,0,0, 43281,81191,14427,-12636, 76293,76486,25431,-65, 213781,85668,71260,42704, 288562,97193,96187,63790, 398741,202036,132914,65568, -2839253,-35,22 ],
  [ 1500,50,5,0,0, 62668,83870,13673,-4626, 120027,84792,24005,7047, 329610,194046,65922,27113, 474953,329071,94991,29176, 656575,371018,131315,57111, -2645483,-33,21 ],
  [ 1500,50,10,0,0, 102480,102711,12549,-28, 246200,197824,20517,4031, 693847,381745,57821,26008, 1096786,548126,91399,45722, 1520942,780929,126745,61668, -1196520,-18,-33 ],
  [ 1500,50,15,0,0, 133684,107027,11883,2369, 397081,347999,18049,2231, 1171393,569532,53245,27357, 1941518,922808,88251,46305, 2720174,1340316,123644,62721, 729515,18,0 ],
  [ 1500,50,25,0,0, 155886,110101,11337,3330, 696992,530648,14597,3484, 2394191,1197314,46042,23017, 4204037,2085216,80847,40747, 6147346,3103291,118218,58540, 5350003,53,0 ],
  [ 1500,50,50,0,0, 157759,110361,11269,3386, 992324,744626,12508,3122, 4367101,2078104,39701,20809, 7989726,3858246,72634,37559, 12365036,6095034,112409,57000, 13384162,81,0 ],
  [ 1500,50,75,0,0, 157759,110361,11269,3386, 997511,745824,12469,3146, 4427986,2086696,39536,20904, 8109075,3890685,72402,37664, 12570296,6225490,112235,56650, 13698254,82,0 ],
  [ 1500,100,1,0,0, 24537,78596,24537,-54059, 31929,54078,31929,-22150, 123594,72766,123594,50828, 107842,54869,107842,52973, 149107,63614,149107,85493, -3435596,-39,26 ],
  [ 1500,100,2,0,0, 38721,80555,19361,-20917, 59585,59151,29792,217, 193390,82956,96695,55217, 215683,71195,107842,72244, 298215,102598,149107,97808, -2963822,-36,23 ],
  [ 1500,100,3,0,0, 52905,82514,17635,-9870, 87241,78109,29080,3044, 263186,107512,87729,51891, 323525,182855,107842,46890, 447322,322782,149107,41513, -2838953,-35,22 ],
  [ 1500,100,5,0,0, 74551,85504,16266,-2390, 136167,87401,27233,9753, 392944,217915,78589,35006, 532478,337824,106496,38931, 736538,399233,147308,67461, -2361455,-31,19 ],
  [ 1500,100,10,0,0, 118980,104980,14569,1714, 277278,203445,23107,6153, 800882,411940,66740,32412, 1229472,583100,102456,53864, 1705950,909206,142163,66395, -696967,-10,0 ],
  [ 1500,100,15,0,0, 153783,109791,13670,3910, 445975,371270,20272,3396, 1335607,713864,60709,28261, 2176177,1096903,98917,49058, 3050754,1620271,138671,65022, 1051360,22,0 ],
  [ 1500,100,25,0,0, 178527,113215,12984,4750, 781101,561773,16358,4593, 2704246,1270952,52005,27563, 4711117,2205657,90598,48182, 6893122,3477689,132560,65681, 6974796,61,0 ],
  [ 1500,100,50,0,0, 180612,113504,12901,4793, 1110855,857483,14002,3194, 4911369,2382464,44649,22990, 8951166,4264652,81374,42605, 13862493,6927362,126023,63047, 15324597,86,0 ],
  [ 1500,100,75,0,0, 180612,113504,12901,4793, 1116641,858818,13958,3223, 4979459,2392071,44459,23102, 9084809,4407640,81114,41760, 14092536,6977780,125826,63525, 15587017,86,0 ],
  [ 1500,200,1,0,0, 37855,80428,37855,-42573, 42076,55164,42076,-13088, 192396,97560,192396,94836, 131151,58472,131151,72678, 181495,68747,181495,112748, -3210593,-38,24 ],
  [ 1500,200,2,0,0, 55004,82795,27502,-13895, 75607,61316,37804,7146, 277196,109939,138598,83628, 262301,78326,131151,91987, 362990,196575,181495,83208, -2694848,-34,21 ],
  [ 1500,200,3,0,0, 72153,85161,24051,-4336, 109139,81353,36380,9262, 361996,213684,120665,49437, 393452,193514,131151,66646, 544485,353713,181495,63591, -2594210,-33,21 ],
  [ 1500,200,5,0,0, 98317,102109,21451,-827, 168445,92468,33689,15195, 519613,357190,103923,32485, 647529,355930,129506,58320, 896463,440592,179293,91174, -2048796,-28,17 ],
  [ 1500,200,10,0,0, 151979,109518,18610,5199, 339436,319037,28286,1700, 1014952,533313,84579,40137, 1494846,746481,124571,62364, 2075967,997648,172997,89860, -185981,1,0 ],
  [ 1500,200,15,0,0, 193983,115320,17243,6992, 543764,390042,24717,6987, 1664033,867729,75638,36196, 2645496,1277528,120250,62180, 3711914,1869319,168723,83754, 2563306,36,0 ],
  [ 1500,200,25,0,0, 223809,119442,16277,7590, 949318,714490,19881,4918, 3324358,1585413,63930,33441, 5725277,2809548,110101,56072, 8384674,4226486,161244,79965, 9017875,68,0 ],
  [ 1500,200,50,0,0, 226317,119789,16166,7609, 1347918,921611,16991,5374, 5999906,2885286,54545,28315, 10874047,5265731,98855,50985, 16857407,8295247,153249,77838, 19894664,95,0 ],
  [ 1500,200,75,0,0, 226317,119789,16166,7609, 1354902,937106,16936,5222, 6082407,2896770,54307,28443, 11036277,5289460,98538,51311, 17137016,8549970,153009,76670, 20252649,96,0 ],
  [ 2000,0,1,0,0, 15281,77404,15281,-62123, 28703,54311,28703,-25608, 68281,50122,68281,18160, 104540,54506,104540,50034, 144769,63081,144769,81688, -3561388,-40,26 ],
  [ 2000,0,2,0,0, 30563,79595,15281,-24516, 57407,59624,28703,-1109, 136562,74788,68281,30887, 209080,70595,104540,69243, 289538,101652,144769,93943, -3127610,-37,24 ],
  [ 2000,0,3,0,0, 45844,81785,15281,-11980, 86110,78821,28703,2430, 204843,84790,68281,40018, 313620,181918,104540,43901, 434306,321322,144769,37661, -2973736,-36,23 ],
  [ 2000,0,5,0,0, 69174,85129,15092,-3481, 137248,88639,27450,9722, 332598,195261,66520,27467, 516074,336343,103215,35946, 715188,396919,143038,63654, -2504330,-32,20 ],
  [ 2000,0,10,0,0, 117105,105340,14339,1441, 286647,206742,23887,6659, 738341,390209,61528,29011, 1191403,579932,99284,50956, 1657162,904256,138097,62742, -863105,-13,-12 ],
  [ 2000,0,15,0,0, 154694,110732,13751,3908, 467481,377652,21249,4083, 1274941,694167,57952,26399, 2111171,1091964,95962,46328, 2963597,1498696,134709,66586, 941195,21,0 ],
  [ 2000,0,25,0,0, 181462,114574,13197,4865, 833229,680431,17450,3200, 2670851,1273364,51363,26875, 4595614,2200018,88377,46069, 6689820,3458586,128650,62139, 6394974,58,0 ],
  [ 2000,0,50,0,0, 183723,114899,13123,4916, 1197202,894589,15091,3814, 4963231,2422561,45120,23097, 8806705,4282649,80061,41128, 13445415,6807990,122231,60340, 15062282,85,0 ],
  [ 2000,0,75,0,0, 183723,114899,13123,4916, 1203606,896086,15045,3844, 5034766,2447496,44953,23101, 8940658,4426173,79827,40308, 13668633,6941751,122041,60061, 15234167,85,0 ],
  [ 2000,25,1,0,0, 18611,77862,18611,-59251, 31240,54583,31240,-23343, 85482,67206,85482,18275, 110367,55444,110367,54923, 152866,64402,152866,88464, -3530263,-40,26 ],
  [ 2000,25,2,0,0, 18611,77961,9305,-29675, 31240,54820,15620,-11790, 86577,67385,43289,9596, 110367,55449,55184,27459, 152866,64406,76433,44230, -3529336,-40,26 ],
  [ 2000,25,3,0,0, 50656,82447,16885,-10597, 91584,79632,30528,3984, 229546,88454,76515,47031, 331102,184570,110367,48844, 458597,325125,152866,44491, -2857257,-35,22 ],
  [ 2000,25,5,0,0, 75115,85946,16389,-2363, 145318,89944,29064,11075, 364265,199938,72853,32865, 544837,340694,108967,40829, 755169,403166,151034,70401, -2339979,-31,19 ],
  [ 2000,25,10,0,0, 125355,106474,15350,2312, 302187,313902,25182,-976, 791858,411063,65988,31733, 1257746,698416,104812,46611, 1749666,918193,145805,69289, -1070269,-16,-25 ],
  [ 2000,25,15,0,0, 164744,112114,14644,4678, 491928,382345,22360,4981, 1357048,720640,61684,28928, 2228501,1189888,101295,47210, 3128887,1653129,142222,67080, 1203016,24,0 ],
  [ 2000,25,25,0,0, 192783,116131,14021,5575, 875283,702937,18331,3609, 2825879,1387108,54344,27669, 4849154,2441518,93253,46301, 7062708,3547325,135821,67604, 6811717,60,0 ],
  [ 2000,25,50,0,0, 195150,116470,13939,5620, 1256468,907150,15838,4403, 5235365,2490548,47594,24953, 9287425,4587499,84431,42727, 14194144,7068973,129038,64774, 16325517,88,0 ],
  [ 2000,25,75,0,0, 195150,116470,13939,5620, 1263171,922600,15790,4257, 5310503,2578326,47415,24394, 9428525,4608581,84183,43035, 14429753,7317596,128837,63501, 16436701,88,0 ],
  [ 2000,50,1,0,0, 21941,78320,21941,-56379, 33777,54854,33777,-21077, 102682,69776,102682,32906, 116194,56333,116194,59862, 160963,65673,160963,95290, -3465637,-40,26 ],
  [ 2000,50,2,0,0, 38704,80714,19352,-21005, 65418,60706,32709,2356, 178465,81022,89233,48721, 232389,74136,116194,79127, 321925,190506,160963,65710, -3006240,-36,23 ],
  [ 2000,50,3,0,0, 55468,83109,18489,-9213, 97059,80444,32353,5538, 254248,106633,84749,49205, 348583,187273,116194,53770, 482888,344649,160963,46080, -2785452,-35,22 ],
  [ 2000,50,5,0,0, 81056,86764,17685,-1245, 153387,91248,30677,12428, 395932,204616,79186,38263, 573600,345046,114720,45711, 795150,409413,159030,77147, -2175628,-29,18 ],
  [ 2000,50,10,0,0, 133605,107609,16360,3183, 317726,316713,26477,84, 845376,418903,70448,35539, 1324090,708390,110341,51308, 1842170,948252,153514,74493, -768410,-11,0 ],
  [ 2000,50,15,0,0, 174793,113496,15537,5449, 516376,387038,23472,5879, 1439154,732599,65416,32116, 2345830,1222430,106629,51064, 3294177,1694421,149735,72716, 1675390,29,0 ],
  [ 2000,50,25,0,0, 204103,117688,14844,6285, 917337,711557,19211,4310, 2980906,1530052,57325,27901, 5102694,2509727,98129,49865, 7435596,3833485,142992,69271, 7319292,62,0 ],
  [ 2000,50,50,0,0, 206576,118042,14755,6324, 1315733,933596,16585,4817, 5507499,2726921,50068,25278, 9768145,4769362,88801,45443, 14942872,7511557,135844,67557, 17209778,90,0 ],
  [ 2000,50,75,0,0, 206576,118042,14755,6324, 1322736,935230,16534,4844, 5586240,2738356,49877,25428, 9916392,4914925,88539,44656, 15190873,7693991,135633,66936, 17431761,90,0 ],
  [ 2000,100,1,0,0, 28600,79236,28600,-50636, 38851,55397,38851,-16547, 137083,74916,137083,62167, 127849,58109,127849,69739, 177156,68214,177156,108942, -3336386,-39,25 ],
  [ 2000,100,2,0,0, 46846,81834,23423,-17494, 73429,75674,36714,-1123, 220368,101621,110184,59373, 255698,77726,127849,88986, 354313,195579,177156,79367, -2902219,-35,23 ],
  [ 2000,100,3,0,0, 65092,84432,21697,-6447, 108007,82066,36002,8647, 303653,113962,101218,63230, 383547,192578,127849,63656, 531469,352254,177156,59739, -2552495,-33,20 ],
  [ 2000,100,5,0,0, 92939,101734,20278,-1919, 169527,93707,33905,15164, 459266,334536,91853,24946, 631125,354449,126225,55335, 875113,438278,175023,87367, -2191670,-29,18 ],
  [ 2000,100,10,0,0, 150104,109878,18380,4926, 348805,336219,29067,1049, 952411,525947,79368,35539, 1456776,743363,121398,59451, 2027178,992698,168932,86207, -429203,-5,0 ],
  [ 2000,100,15,0,0, 194893,116260,17324,6990, 565270,410309,25694,7044, 1603368,847881,72880,34340, 2580490,1272589,117295,59450, 3624757,1845114,164762,80893, 2340098,35,0 ],
  [ 2000,100,25,0,0, 226744,120801,16490,7705, 1001446,742683,20973,5419, 3290962,1603690,63288,32448, 5609774,2803959,107880,53958, 8181372,4207883,157334,76413, 8687591,67,0 ],
  [ 2000,100,50,0,0, 229429,121185,16388,7732, 1434265,1150803,18079,3573, 6051768,2923732,55016,28437, 10729585,5283728,97542,49508, 16440329,8259105,149458,74375, 18968629,93,0 ],
  [ 2000,100,75,0,0, 229429,121185,16388,7732, 1441867,1152574,18023,3616, 6137713,2936181,54801,28585, 10892126,5322920,97251,49725, 16713113,8316520,149224,74970, 19491160,94,0 ],
  [ 2000,200,1,0,0, 41918,81067,41918,-39150, 48998,56483,48998,-7485, 205885,99710,205885,106175, 151158,61713,151158,89444, 209544,73347,209544,136197, -3111383,-37,24 ],
  [ 2000,200,2,0,0, 63129,84073,31564,-10472, 89451,77839,44726,5806, 304174,114090,152087,95042, 302315,99785,151158,101265, 419088,318816,209544,50136, -2642747,-33,21 ],
  [ 2000,200,3,0,0, 84340,87079,28113,-913, 129905,85310,43302,14865, 402463,220135,134154,60776, 453473,311697,151158,47259, 628632,367514,209544,87039, -2456653,-32,20 ],
  [ 2000,200,5,0,0, 116705,105003,25463,2553, 201806,98925,40361,20576, 585935,367760,117187,43635, 746175,386881,149235,71859, 1035038,562767,207008,94454, -1660778,-24,13 ],
  [ 2000,200,10,0,0, 183103,114416,22421,8411, 410963,347460,34247,5292, 1166481,571821,97207,49555, 1722150,878592,143513,70297, 2397194,1278511,199766,93224, 486085,14,0 ],
  [ 2000,200,15,0,0, 235092,121789,20897,10071, 663059,502931,30139,7279, 1931794,924745,87809,45775, 3049808,1561673,138628,67642, 4285917,2207253,194814,94485, 3554055,43,0 ],
  [ 2000,200,25,0,0, 272026,140366,19784,9575, 1169663,864749,24496,6386, 3911074,1919650,75213,38297, 6623934,3299540,127383,63931, 9672924,4828568,186018,93161, 11020697,74,0 ],
  [ 2000,200,50,0,0, 275134,140807,19652,9595, 1671328,1228666,21067,5580, 7140304,3426403,64912,33763, 12652465,6161521,115022,59009, 19435243,9710870,176684,88403, 23562863,101,0 ],
  [ 2000,200,75,0,0, 275134,140807,19652,9595, 1680128,1230712,21002,5618, 7240660,3546930,64649,32980, 12843595,6285698,114675,58553, 19757593,9905030,176407,87969, 23727023,102,0 ],
  [ 2500,0,1,0,0, 19344,78044,19344,-58700, 35625,55630,35625,-20005, 81770,52272,81770,29499, 124547,57746,124547,66801, 172818,67682,172818,105136, -3462178,-40,26 ],
  [ 2500,0,2,0,0, 38688,80873,19344,-21093, 71251,76147,35625,-2448, 163541,78938,81770,42301, 249094,77077,124547,86009, 345636,194583,172818,75526, -3032507,-36,24 ],
  [ 2500,0,3,0,0, 58031,83703,19344,-8557, 106876,82778,35625,8032, 245311,91240,81770,51357, 373642,191641,124547,60667, 518453,350794,172818,55886, -2687278,-34,21 ],
  [ 2500,0,5,0,0, 87562,101360,19104,-3011, 170608,94946,34122,15132, 398920,205831,79784,38618, 614721,352467,122944,52451, 853763,435464,170753,83660, -2089167,-28,17 ],
  [ 2500,0,10,0,0, 148230,110238,18151,4652, 358174,339515,29848,1555, 889870,427216,74156,38554, 1418707,740196,118226,56543, 1978390,987748,164866,82553, -418844,-5,0 ],
  [ 2500,0,15,0,0, 195804,117201,17405,6987, 586776,416690,26672,7731, 1542702,842698,70123,31818, 2515484,1267700,114340,56717, 3537599,1836680,160800,77315, 2115801,33,0 ],
  [ 2500,0,25,0,0, 229679,122161,16704,7820, 1053574,830841,22064,4665, 3257567,1607602,62646,31730, 5494271,2798319,105659,51845, 7978070,4060519,153424,75338, 8292414,66,0 ],
  [ 2500,0,50,0,0, 232540,135916,16610,6902, 1520611,1187759,19167,4196, 6103629,2963828,55488,28544, 10585123,5301874,96228,48030, 16023251,8223663,145666,70905, 18591888,92,0 ],
  [ 2500,0,75,0,0, 232540,135916,16610,6902, 1528831,1189692,19110,4239, 6193020,3097656,55295,27637, 10747976,5342104,95964,48267, 16289210,8281141,145439,71501, 18838240,93,0 ],
  [ 2500,25,1,0,0, 22673,78501,22673,-55828, 38162,55902,38162,-17740, 98971,69356,98971,29614, 130374,58685,130374,71689, 180915,69002,180915,111912, -3431052,-39,26 ],
  [ 2500,25,2,0,0, 42758,81433,21379,-19337, 75256,76688,37628,-716, 184492,82055,92246,51218, 260749,78847,130374,90951, 361829,197119,180915,82355, -2941955,-36,23 ],
  [ 2500,25,3,0,0, 62843,84365,20948,-7174, 112350,83590,37450,9587, 270014,109419,90005,53531, 391123,194343,130374,65593, 542744,354647,180915,62699, -2604300,-33,21 ],
  [ 2500,25,5,0,0, 93503,102177,20401,-1892, 178678,96251,35736,16485, 430587,331074,86117,19903, 643484,372345,128697,54228, 893744,442261,178749,90297, -2225798,-30,18 ],
  [ 2500,25,10,0,0, 156480,111372,19161,5523, 373713,342326,31143,2616, 943387,512056,78616,35944, 1485050,750169,123754,61240, 2070894,1002136,172574,89063, -282310,-1,0 ],
  [ 2500,25,15,0,0, 205853,118583,18298,7757, 611223,421383,27783,8629, 1624808,854657,73855,35007, 2632813,1300242,119673,60571, 3702889,1877972,168313,82951, 2588175,37,0 ],
  [ 2500,25,25,0,0, 240999,137054,17527,7560, 1095628,853196,22945,5077, 3412594,1721346,65627,32524, 5747811,2866178,110535,55416, 8350958,4262498,160595,78624, 8830327,67,0 ],
  [ 2500,25,50,0,0, 243966,137488,17426,7606, 1579877,1214205,19914,4609, 6375763,3137716,57961,29437, 11065843,5483788,100599,50746, 16771980,8582516,152473,74450, 19678880,94,0 ],
  [ 2500,25,75,0,0, 243966,137488,17426,7606, 1588396,1216206,19855,4652, 6468757,3228486,57757,28931, 11235843,5524511,100320,50994, 17050330,8656986,152235,74941, 20040773,95,0 ],
  [ 2500,50,1,0,0, 26003,78959,26003,-52956, 40699,56173,40699,-15474, 116171,71926,116171,44245, 136202,59573,136202,76628, 189012,70273,189012,118739, -3366427,-39,25 ],
  [ 2500,50,2,0,0, 46829,81993,23415,-17582, 79262,77229,39631,1016, 205444,85172,102722,60136, 272403,95594,136202,88405, 378023,199656,189012,89184, -2873506,-35,23 ],
  [ 2500,50,3,0,0, 67655,85027,22552,-5790, 117824,84401,39275,11141, 294716,113084,98239,60544, 408605,305455,136202,34383, 567035,358449,189012,69529, -2647895,-34,21 ],
  [ 2500,50,5,0,0, 99445,102994,21697,-774, 186748,97555,37350,17839, 462254,335751,92451,25301, 672246,376697,134449,59110, 933726,532288,186745,80288, -2121181,-29,17 ],
  [ 2500,50,10,0,0, 164730,112507,20171,6395, 389253,345136,32438,3676, 996905,534411,83075,38541, 1551394,855477,129283,57993, 2163398,1145335,180283,84839, -235211,0,0 ],
  [ 2500,50,15,0,0, 215903,119965,19191,8528, 635671,426076,28894,9527, 1706915,881130,77587,37536, 2750143,1317858,125006,65104, 3868179,2032405,175826,83444, 2968520,39,0 ],
  [ 2500,50,25,0,0, 252320,138611,18351,8270, 1137683,861816,23826,5777, 3567622,1743725,68608,35075, 6001351,2999168,115411,57734, 8723846,4435017,167766,82477, 9600992,70,0 ],
  [ 2500,50,50,0,0, 255392,139059,18242,8310, 1639143,1226766,20661,5198, 6647897,3268189,60435,30725, 11546563,5788588,104969,52345, 17520708,8843450,159279,78884, 20799118,96,0 ],
  [ 2500,50,75,0,0, 255392,139059,18242,8310, 1647962,1228836,20600,5239, 6744494,3296981,60219,30781, 11723710,5830406,104676,52619, 17811450,9032931,159031,78380, 21247711,97,0 ],
  [ 2500,100,1,0,0, 32662,79875,32662,-47213, 45772,56716,45772,-10944, 150572,77066,150572,73506, 147856,61350,147856,86506, 205205,72815,205205,132391, -3237175,-38,25 ],
  [ 2500,100,2,0,0, 54971,83113,27485,-14071, 87273,78312,43636,4480, 247347,105922,123673,70713, 295712,99185,147856,98264, 410411,204779,205205,102816, -2725902,-34,22 ],
  [ 2500,100,3,0,0, 77279,86350,25760,-3024, 128773,86023,42924,14250, 344121,197413,114707,48903, 443568,310760,147856,44269, 615616,366054,205205,83187, -2591436,-33,21 ],
  [ 2500,100,5,0,0, 77279,86350,16861,-1979, 128773,86023,25755,8550, 475782,337616,95156,27633, 451137,327528,90227,24722, 626663,384112,125333,48510, -2630911,-33,21 ],
  [ 2500,100,10,0,0, 181229,114776,22191,8137, 420331,350757,35028,5798, 1103940,564605,91995,44945, 1684081,875474,140340,67384, 2348406,1273611,195700,89566, 286446,11,0 ],
  [ 2500,100,15,0,0, 236003,122729,20978,10069, 684565,523197,31117,7335, 1871128,919413,85051,43260, 2984802,1556784,135673,64910, 4198759,2198769,190853,90909, 3286174,41,0 ],
  [ 2500,100,25,0,0, 274961,141725,19997,9690, 1221791,892942,25587,6887, 3877678,1923413,74571,37582, 6508431,3308877,125162,61530, 9469622,4809915,182108,89610, 10701809,73,0 ],
  [ 2500,100,50,0,0, 278245,142202,19875,9717, 1757674,1265772,22156,6200, 7192166,3572550,65383,32906, 12508003,6180017,113709,57527, 19018165,9591998,172892,85692, 23055171,100,0 ],
  [ 2500,100,75,0,0, 278245,142202,19875,9717, 1767092,1267980,22089,6239, 7295967,3602356,65143,32979, 12699444,6304232,113388,57100, 19333690,9853330,172622,84646, 23385347,101,0 ],
  [ 2500,200,1,0,0, 45980,81707,45980,-35727, 55920,57802,55920,-1882, 219374,101860,219374,117514, 171165,64954,171165,106211, 237593,77948,237593,159645, -3012173,-36,23 ],
  [ 2500,200,2,0,0, 71254,85352,35627,-7049, 103295,80477,51648,11409, 331153,132905,165576,99124, 342330,186624,171165,77853, 475186,343738,237593,65724, -2607524,-33,21 ],
  [ 2500,200,3,0,0, 96527,102334,32176,-1936, 150671,89118,50224,20518, 442931,332635,147644,36765, 513495,337046,171165,58816, 712779,397635,237593,105048, -2492370,-32,20 ],
  [ 2500,200,5,0,0, 135093,107897,29475,5934, 235166,119267,47033,23180, 652257,378181,130451,54815, 844822,417832,168964,85398, 1173614,585441,234723,117635, -1245436,-19,-44 ],
  [ 2500,200,10,0,0, 214228,119314,26232,11622, 482489,375884,40207,8884, 1318009,716378,109834,50136, 1949454,945871,162455,83632, 2718422,1363004,226535,112952, 1149560,23,0 ],
  [ 2500,200,15,0,0, 276202,141594,24551,11965, 782354,541820,35562,10933, 2199555,1179327,99980,46374, 3454120,1753035,157005,77322, 4859919,2545887,220905,105183, 4406486,48,0 ],
  [ 2500,200,25,0,0, 320243,219652,23290,7316, 1390009,1045659,29110,7212, 4497790,2146338,86496,45220, 7522591,3723550,144665,73058, 10961174,5558262,210792,103902, 12941380,80,0 ],
  [ 2500,200,50,0,0, 323951,220188,23139,7412, 1994737,1521986,25144,5959, 8280702,4073721,75279,38245, 14430883,7072637,131190,66893, 22013079,11156204,200119,98699, 26746976,106,0 ],
  [ 2500,200,75,0,0, 323951,220188,23139,7412, 2005353,1524468,25067,6011, 8398914,4091040,74990,38463, 14650912,7200979,130812,66517, 22378171,11327550,199805,98666, 27277447,107,0 ],
  [ 3000,0,1,0,0, 281356,141970,281356,139386, 1854057,1052747,1854057,801309, 7220057,3564102,7220057,3655955, 12555294,6322436,12555294,6232858, 18909787,9620171,18909787,9289616, 23894202,102,0 ],
  [ 3000,0,2,0,0, 46812,82152,23406,-17670, 85094,78785,42547,3155, 190519,83239,95260,53640, 289109,98535,144554,95287, 401734,203783,200867,98975, -2856190,-35,22 ],
  [ 3000,0,3,0,0, 70218,85621,23406,-5134, 127642,86735,42547,13635, 285779,112205,95260,57858, 433663,309873,144554,41263, 602600,364645,200867,79319, -2583221,-33,21 ],
  [ 3000,0,5,0,0, 105950,104254,23116,370, 203968,115288,40794,17736, 465242,336817,93048,25685, 713368,383969,142674,65880, 992338,542468,198468,89974, -2012438,-28,16 ],
  [ 3000,0,10,0,0, 179355,115136,21962,7864, 429700,354054,35808,6304, 1041399,542874,86783,41544, 1646011,872307,137168,64475, 2299617,1268661,191635,85913, 120308,8,0 ],
  [ 3000,0,15,0,0, 236913,137006,21059,8881, 706071,529429,32094,8029, 1810463,899715,82294,41398, 2919796,1551995,132718,62173, 4111602,2190435,186891,87326, 3040684,40,0 ],
  [ 3000,0,25,0,0, 277896,143084,20211,9804, 1273919,907250,26679,7679, 3844282,1927325,73929,36865, 6392928,3222930,122941,60961, 9266320,4791313,178198,86058, 10567132,73,0 ],
  [ 3000,0,50,0,0, 281356,143597,20097,9840, 1844020,1376729,23244,5890, 7244027,3612646,65855,33013, 12363541,6198014,112396,56050, 18601087,9556406,169101,82224, 22499341,99,0 ],
  [ 3000,0,75,0,0, 281356,143597,20097,9840, 1854057,1379098,23176,5937, 7351274,3643266,65636,33107, 12555294,6322715,112101,55648, 18909787,9620380,168837,82941, 22972583,100,0 ],
  [ 3000,25,1,0,0, 182392,84644,182392,97748, 726347,538250,726347,188097, 7627011,2867034,7627011,4759977, 13043161,4973762,13043161,8069399, 19670907,7559186,19670907,12111721, 29036041,110,0 ],
  [ 3000,25,2,0,0, 50883,82712,25442,-15914, 89100,79326,44550,4887, 211471,86356,105735,62557, 300763,100305,150382,100229, 417927,319410,208964,49259, -2846270,-35,22 ],
  [ 3000,25,3,0,0, 75030,86283,25010,-3751, 133116,87547,44372,15190, 310481,192870,103494,39204, 451145,327502,150382,41214, 626891,368447,208964,86148, -2665344,-34,21 ],
  [ 3000,25,5,0,0, 111891,105071,24413,1488, 212038,116592,42408,19089, 496909,341494,99382,31083, 742130,388320,148426,70762, 1032320,564386,206464,93587, -1859260,-26,15 ],
  [ 3000,25,10,0,0, 187605,116270,22972,8735, 445240,370749,37103,6208, 1094916,550564,91243,45363, 1712354,897257,142696,67925, 2392121,1282999,199343,92427, 367653,12,0 ],
  [ 3000,25,15,0,0, 246963,138388,21952,9651, 730518,534122,33205,8927, 1892569,926188,86026,43926, 3037125,1584588,138051,66024, 4276892,2231777,194404,92960, 3479557,43,0 ],
  [ 3000,25,25,0,0, 246963,138652,17961,7877, 730518,539757,15299,3995, 1916631,944600,36858,18693, 3037125,1584650,58406,27932, 4276892,2231837,82248,39328, 3473313,43,0 ],
  [ 3000,25,50,0,0, 292783,216869,20913,5422, 1903286,1507525,23991,4989, 7516161,3755984,68329,34183, 12844261,6394404,116766,58635, 19349816,9914259,175907,85778, 23012505,100,0 ],
  [ 3000,25,75,0,0, 292783,216869,20913,5422, 1913622,1509962,23920,5046, 7627011,3772596,68098,34414, 13043161,6548252,116457,57990, 19670907,10079506,175633,85638, 23431124,101,0 ],
  [ 3000,50,1,0,0, 30065,79599,30065,-49533, 47621,57492,47621,-9871, 129661,74076,129661,55584, 156209,62814,156209,93395, 217061,74873,217061,142187, -3267216,-38,25 ],
  [ 3000,50,2,0,0, 54954,83272,27477,-14159, 93106,79868,46553,6619, 232422,103988,116211,64217, 312418,182433,156209,64992, 434121,321997,217061,56062, -2907742,-36,23 ],
  [ 3000,50,3,0,0, 79842,86945,26614,-2367, 138590,88358,46197,16744, 335184,196534,111728,46217, 468627,330155,156209,46157, 651182,387921,217061,87754, -2560039,-33,20 ],
  [ 3000,50,5,0,0, 117833,105888,25709,2606, 220108,117897,44022,20442, 528576,346171,105715,36481, 770893,392672,154179,75644, 1072301,570633,214460,100334, -1694909,-24,13 ],
  [ 3000,50,10,0,0, 195855,117405,23982,9606, 460779,373560,38398,7268, 1148434,572918,95703,47960, 1778698,907280,148225,72618, 2484625,1313108,207052,97626, 636012,16,0 ],
  [ 3000,50,15,0,0, 257013,139771,22846,10422, 754966,538815,34317,9825, 1974676,952662,89758,46455, 3154455,1617180,143384,69876, 4442182,2257448,201917,99306, 3929604,45,0 ],
  [ 3000,50,25,0,0, 300537,217898,21857,6010, 1358028,938376,28440,8789, 4154338,2077962,79891,39930, 6900008,3408702,132692,67140, 10012096,5165711,192540,93200, 11625022,76,0 ],
  [ 3000,50,50,0,0, 304209,218440,21729,6126, 1962552,1520086,24738,5577, 7788296,3915507,70803,35207, 13324981,6684727,121136,60366, 20098544,10288783,182714,89180, 24005334,102,0 ],
  [ 3000,50,75,0,0, 304209,218440,21729,6126, 1973187,1522592,24665,5632, 7902747,3947141,70560,35318, 13531028,6731210,120813,60713, 20432027,10455950,182429,89072, 24574861,103,0 ],
  [ 3000,100,1,0,0, 36724,80514,36724,-43790, 52694,58035,52694,-5341, 164061,79216,164061,84846, 167863,64591,167863,103272, 233254,77415,233254,155840, -3137965,-37,24 ],
  [ 3000,100,2,0,0, 63095,84391,31548,-10648, 101117,80950,50558,10083, 274325,110222,137163,82052, 335727,185974,167863,74876, 466509,342741,233254,61884, -2737811,-34,22 ],
  [ 3000,100,3,0,0, 89466,101605,29822,-4046, 149539,89830,49846,19903, 384589,218378,128196,55404, 503590,335659,167863,55977, 699763,395726,233254,101346, -2415275,-32,19 ],
  [ 3000,100,5,0,0, 89466,101859,19520,-2704, 149539,90545,29908,11799, 387795,218900,77559,33779, 503590,335672,100718,33584, 699763,395735,139953,60806, -2412470,-31,19 ],
  [ 3000,100,10,0,0, 212354,119674,26003,11349, 491858,379181,40988,9390, 1255468,709162,104622,45526, 1911385,942754,159282,80719, 2669634,1358104,222469,109294, 949922,21,0 ],
  [ 3000,100,15,0,0, 277113,142535,24632,11962, 803860,562086,36539,10990, 2138889,1173994,97222,43859, 3389114,1748146,154051,74589, 4772762,2537403,216944,101607, 4138605,47,0 ],
  [ 3000,100,25,0,0, 323178,221012,23504,7430, 1442136,1147702,30202,6166, 4464394,2150100,85854,44506, 7407088,3717911,142444,70946, 10757872,5523938,206882,100653, 12421988,78,0 ],
  [ 3000,100,50,0,0, 327062,221583,23362,7534, 2081083,1559092,26232,6580, 8332564,4113817,75751,38352, 14286421,7090633,129877,65416, 21596001,11007521,196327,96259, 26505559,106,0 ],
  [ 3000,100,75,0,0, 327062,221583,23362,7534, 2081083,1559092,26014,6525, 8414784,4126052,75132,38292, 14292809,7091681,127614,64296, 21605747,11009142,192908,94613, 26679654,106,0 ],
  [ 3000,200,1,0,0, 50043,82346,50043,-32304, 62842,59121,62842,3721, 232863,104010,232863,128853, 191172,68195,191172,122977, 265642,82548,265642,183094, -2912962,-36,23 ],
  [ 3000,200,2,0,0, 79378,86631,39689,-3626, 117139,83115,58570,17012, 358131,214205,179066,71963, 382344,193106,191172,94619, 531284,352938,265642,89173, -2585601,-33,21 ],
  [ 3000,200,3,0,0, 108714,104252,36238,1487, 171436,93075,57145,26121, 483399,339085,161133,48104, 573516,346769,191172,75583, 796926,411436,265642,128497, -2194739,-30,18 ],
  [ 3000,200,5,0,0, 153481,110791,33487,9314, 268526,199573,53705,13791, 718579,403266,143716,63063, 943469,514165,188694,85861, 1312189,736976,262438,115043, -1242018,-19,-44 ],
  [ 3000,200,10,0,0, 245353,124212,30043,14834, 554016,390423,46168,13633, 1469538,755036,122462,59542, 2176758,1186442,181397,82526, 3039650,1643917,253304,116311, 1705136,29,0 ],
  [ 3000,200,15,0,0, 317312,219763,28206,8671, 901649,685208,40984,9838, 2467316,1249358,112151,55362, 3858432,1956923,175383,86432, 5433922,2785951,246996,120362, 5164893,52,0 ],
  [ 3000,200,25,0,0, 317312,219763,23077,7094, 901649,685208,18883,4533, 4325531,2113857,83183,42532, 3976719,2037731,76475,37288, 5614811,3008978,107977,50112, 7464346,62,0 ],
  [ 3000,200,50,0,0, 372767,328736,26626,3145, 2318146,1709455,29220,7673, 9421101,4616489,85646,43678, 16209302,7984753,147357,74769, 24590915,12489946,223554,110009, 30464031,112,0 ],
  [ 3000,200,75,0,0, 372767,328736,26626,3145, 2330579,1712374,29132,7728, 9557168,4742700,85332,42986, 16458230,8226719,146948,73496, 24998748,12668789,223203,110089, 30689086,112,0 ],
  [ 1000,0,1,1,0, 12723,76835,12723,-64112, 23903,53178,23903,-29275, 59476,48397,59476,11079, 87826,36506,87826,51321, 115815,58065,115815,57750, -3612536,-41,27 ],
  [ 1000,0,2,1,0, 25446,78455,12723,-26505, 47807,57358,23903,-4776, 118952,71340,59476,23806, 175653,64548,87826,55553, 231630,75948,115815,77841, -3262939,-38,25 ],
  [ 1000,0,3,1,0, 38169,80076,12723,-13969, 71710,75423,23903,-1237, 178428,79617,59476,32937, 263479,77564,87826,61972, 347445,193183,115815,51421, -2972230,-36,23 ],
  [ 1000,0,5,1,0, 57595,82552,12566,-5445, 113574,83015,22715,6112, 288572,109653,57714,35784, 434724,306578,86945,25629, 572951,356555,114590,43279, -2651759,-34,21 ],
  [ 1000,0,10,1,0, 97521,100983,11941,-424, 232104,119677,19342,9369, 632686,355124,52724,23130, 1010470,516568,84206,41158, 1332956,732449,111080,50042, -1266290,-19,-41 ],
  [ 1000,0,15,1,0, 128843,104983,11453,2121, 371387,340172,16881,1419, 1081249,536158,49148,24777, 1794253,879093,81557,41598, 2393156,1251552,108780,51891, 208834,10,0 ],
  [ 1000,0,25,1,0, 128843,105104,9370,1726, 371387,342637,7778,602, 1099833,539167,21151,10782, 1794253,879099,34505,17599, 2393156,1251557,46022,21954, 236215,10,0 ],
  [ 1000,0,50,1,0, 153049,108082,10932,3212, 904534,705483,11402,2509, 4032389,1775558,36658,20517, 7369548,3402776,66996,36062, 11090205,5342647,100820,52251, 12532501,79,0 ],
  [ 1000,0,75,1,0, 153049,108082,10932,3212, 909153,706524,11364,2533, 4088003,1903469,36500,19505, 7478623,3418659,66773,36250, 11276967,5499435,100687,51585, 12535706,79,0 ],
  [ 1000,25,1,1,0, 17492,77490,17492,-59999, 27688,53602,27688,-25914, 81017,66128,81017,14889, 95758,37765,95758,57992, 126390,59776,126390,66615, -3560268,-40,26 ],
  [ 1000,25,2,1,0, 31532,79292,15766,-23880, 53953,58204,26976,-2126, 145894,75343,72947,35275, 191516,66959,95758,62278, 252781,79263,126390,86759, -3139509,-37,24 ],
  [ 1000,25,3,1,0, 45573,81095,15191,-11840, 80217,76690,26739,1176, 210771,84409,70257,42121, 287274,81176,95758,68699, 379171,198152,126390,60340, -2811138,-35,22 ],
  [ 1000,25,5,1,0, 67007,83846,14620,-3674, 126267,85060,25253,8241, 331007,192913,66201,27619, 473948,312515,94790,32287, 625247,364729,125049,52104, -2597798,-33,21 ],
  [ 1000,25,10,1,0, 111037,102841,13596,1004, 256918,197982,21410,4911, 707090,380525,58924,27214, 1101461,545277,91788,46349, 1454476,767074,121206,57283, -1121797,-17,-31 ],
  [ 1000,25,15,1,0, 145559,107282,12939,3402, 410755,347678,18671,2867, 1197842,553121,54447,29305, 1955889,918273,88904,47164, 2611030,1300999,118683,59547, 897939,20,0 ],
  [ 1000,25,25,1,0, 170137,110449,12374,4341, 710181,528386,14873,3807, 2444146,1191052,47003,24098, 4241833,2074828,81574,41673, 5955536,2941745,114530,57958, 5634304,55,0 ],
  [ 1000,25,50,1,0, 172212,110717,12301,4392, 1001663,739910,12626,3299, 4437942,2045491,40345,21750, 8044711,3736848,73134,39162, 12091886,5968347,109926,55669, 13879589,82,0 ],
  [ 1000,25,75,1,0, 172212,110717,12301,4392, 1006782,741066,12585,3321, 4499156,2068140,40171,21706, 8164028,3849012,72893,38527, 12295454,6014082,109781,56084, 14103506,83,0 ],
  [ 1000,50,1,1,0, 22261,78146,22261,-55886, 31473,54026,31473,-22553, 102558,69343,102558,33214, 103689,53952,103689,49738, 136966,61436,136966,75530, -3496604,-40,26 ],
  [ 1000,50,2,1,0, 37619,80130,18809,-21255, 60098,59049,30049,524, 172836,79347,86418,46745, 207379,69370,103689,69004, 273932,82578,136966,95677, -3016080,-36,23 ],
  [ 1000,50,3,1,0, 52977,82113,17659,-9712, 88723,77958,29574,3589, 243115,103716,81038,46466, 311068,99815,103689,70418, 410898,203171,136966,69243, -2705651,-34,22 ],
  [ 1000,50,5,1,0, 76419,85141,16673,-1903, 138959,87105,27792,10371, 373441,199172,74688,34854, 513172,333429,102634,35949, 677543,388574,135509,57794, -2400616,-31,19 ],
  [ 1000,50,10,1,0, 124553,104700,15251,2431, 281731,202437,23478,6608, 781493,391411,65124,32507, 1192453,558960,99371,52791, 1575996,785979,131333,65835, -676746,-10,0 ],
  [ 1000,50,15,1,0, 162275,109581,14424,4684, 450124,355184,20460,4315, 1314436,690650,59747,28354, 2117526,958003,96251,52706, 2828904,1366667,128587,66465, 1296993,25,0 ],
  [ 1000,50,25,1,0, 189112,113059,13754,5531, 778650,542367,16307,4948, 2670191,1238168,51350,27539, 4594316,2142273,88352,47155, 6450547,3148781,124049,63495, 6909848,60,0 ],
  [ 1000,50,50,1,0, 191375,113353,13670,5573, 1098793,848187,13850,3159, 4843494,2132374,44032,24647, 8719874,4070421,79272,42268, 13093568,6396677,119032,60881, 15555139,86,0 ],
  [ 1000,50,75,1,0, 191375,113353,13670,5573, 1104410,849457,13805,3187, 4910309,2155810,43842,24594, 8849433,4184080,79013,41655, 13313941,6444948,118874,61330, 15814385,87,0 ],
  [ 1000,100,1,1,0, 31798,79458,31798,-47660, 39043,54874,39043,-15831, 145639,75774,145639,69865, 119552,56372,119552,63181, 158117,64757,158117,93360, -3325068,-39,25 ],
  [ 1000,100,2,1,0, 49792,81804,24896,-16006, 72390,60741,36195,5824, 226721,101869,113360,62426, 239105,74242,119552,82431, 316234,188708,158117,63763, -2873628,-35,22 ],
  [ 1000,100,3,1,0, 67786,84149,22595,-5455, 105736,80492,35245,8415, 307803,113300,102601,64834, 358657,187347,119552,57104, 474351,326198,158117,49384, -2582624,-33,21 ],
  [ 1000,100,5,1,0, 95243,101066,20780,-1270, 164343,91045,32869,14659, 458310,332257,91662,25211, 591621,346004,118324,49123, 782135,405622,156427,75303, -2273270,-30,18 ],
  [ 1000,100,10,1,0, 95243,101066,11662,-713, 164343,91045,13695,6108, 458310,332257,38192,10504, 607296,363551,50608,20312, 805108,425113,67092,31666, -2273551,-30,18 ],
  [ 1000,100,15,1,0, 195707,114179,17396,7247, 528861,384081,24039,6581, 1547622,753457,70346,36098, 2440800,1225230,110945,55253, 3264652,1662481,148393,72826, 2145654,33,0 ],
  [ 1000,100,25,1,0, 227062,118278,16514,7912, 915588,688565,19175,4754, 3122280,1528313,60044,30653, 5299283,2496435,101909,53901, 7440569,3660271,143088,72698, 8243090,65,0 ],
  [ 1000,100,50,1,0, 227062,118278,16219,7770, 915588,688565,11541,2862, 4873452,2155362,44304,24710, 5401531,2559086,49105,25840, 7594785,3878312,69044,33786, 10830438,74,0 ],
  [ 1000,100,75,1,0, 229701,118624,16407,7934, 1299668,904655,16246,4938, 5732614,2591352,51184,28047, 10220243,4869694,91252,47773, 15350915,7474792,137062,70323, 19007342,93,0 ],
  [ 1000,200,1,1,0, 50874,82081,50874,-31208, 54182,56570,54182,-2387, 231802,103151,231802,128651, 151278,61261,151278,90017, 200419,71450,200419,128970, -3015496,-36,23 ],
  [ 1000,200,2,1,0, 74138,85152,37069,-5507, 96972,78008,48486,9482, 334490,132249,167245,101120, 302557,98913,151278,101822, 400839,201967,200419,99436, -2479097,-32,20 ],
  [ 1000,200,3,1,0, 97403,101559,32468,-1386, 139762,85412,46587,18117, 437177,330033,145726,35715, 453835,310956,151278,47626, 601258,362444,200419,79605, -2619575,-33,21 ],
  [ 1000,200,5,1,0, 132892,106244,28995,5814, 215111,99226,43022,23177, 628047,371811,125609,51247, 748518,384780,149704,72748, 991318,537819,198264,90700, -1477946,-22,10 ],
  [ 1000,200,10,1,0, 205648,115853,25181,10995, 430611,347400,35884,6934, 1227913,575621,102326,54358, 1738405,874906,144867,71958, 2305116,1242340,192093,88565, 756652,18,0 ],
  [ 1000,200,15,1,0, 262571,123375,23340,12373, 686335,501841,31197,8386, 2013995,927191,91545,49400, 3087347,1555841,140334,69614, 4136148,2140969,188007,90690, 3924615,45,0 ],
  [ 1000,200,25,1,0, 302961,142053,22034,11702, 1189465,845961,24910,7194, 4026458,1914038,77432,40623, 6709217,3269689,129023,66145, 9420612,4713161,181166,90528, 11612401,76,0 ],
  [ 1000,200,50,1,0, 306354,142502,21882,11704, 1681570,1205029,21196,6007, 7276809,3293137,66153,36215, 12770851,6090330,116099,60732, 19103659,9416167,173670,88068, 24663237,103,0 ],
  [ 1000,200,75,1,0, 306354,142502,21882,11704, 1690183,1206986,21127,6040, 7377224,3321299,65868,36214, 12961864,6117684,115731,61109, 19424863,9495331,173436,88657, 25263914,104,0 ],
  [ 1500,0,1,1,0, 19945,77971,19945,-58026, 35027,55240,35027,-20213, 78900,51494,78900,27406, 115059,55898,115059,59160, 152450,64078,152450,88373, -3488097,-40,26 ],
  [ 1500,0,2,1,0, 39890,80729,19945,-20419, 70055,75366,35027,-2656, 157801,77384,78900,40208, 230117,73380,115059,78369, 304901,103595,152450,100653, -3024612,-36,23 ],
  [ 1500,0,3,1,0, 59835,83486,19945,-7884, 105082,81608,35027,7825, 236701,88908,78900,49264, 345176,186096,115059,53027, 457351,324311,152450,44347, -2753863,-34,22 ],
  [ 1500,0,5,1,0, 90285,101033,19699,-2345, 167274,92999,33455,14855, 384346,201921,76869,36485, 569263,343513,113853,45150, 754220,402119,150844,70420, -2206562,-30,18 ],
  [ 1500,0,10,1,0, 152854,109690,18717,5285, 347865,334876,28989,1082, 853370,417672,71114,36308, 1322341,705576,110195,51397, 1755027,916331,146252,69891, -676825,-10,0 ],
  [ 1500,0,15,1,0, 201927,116484,17949,7595, 565390,408326,25700,7139, 1473785,733474,66990,33651, 2351537,1218523,106888,51501, 3149906,1651312,143178,68118, 1881516,31,0 ],
  [ 1500,0,25,1,0, 236881,121327,17228,8404, 1002981,739844,21005,5511, 3090481,1536539,59432,29883, 5140078,2488875,98848,50985, 7170941,3551794,137903,69599, 7954622,64,0 ],
  [ 1500,0,50,1,0, 239834,121737,17131,8436, 1437458,1147484,18119,3655, 5756307,2756597,52330,27270, 9872301,4760592,89748,46470, 14540040,7290410,132182,65906, 17578345,90,0 ],
  [ 1500,0,75,1,0, 239834,121737,17131,8436, 1445104,1149252,18064,3698, 5839771,2768376,52141,27423, 10022872,4891082,89490,45820, 14784808,7343053,132007,66444, 17927889,91,0 ],
  [ 1500,25,1,1,0, 24714,78627,24714,-53913, 38812,55664,38812,-16852, 100441,69225,100441,31216, 122990,57158,122990,65832, 163026,65788,163026,97238, -3435830,-39,26 ],
  [ 1500,25,2,1,0, 45976,81566,22988,-17795, 76200,76212,38100,-6, 184743,81388,92371,51678, 245980,75791,122990,85094, 326052,190690,163026,67681, -2960916,-36,23 ],
  [ 1500,25,3,1,0, 67239,84504,22413,-5755, 113589,82875,37863,10238, 269045,108215,89682,53610, 368970,189758,122990,59737, 489078,345001,163026,48026, -2637445,-33,21 ],
  [ 1500,25,5,1,0, 99697,102328,21752,-574, 179966,95044,35993,16984, 426780,314231,85356,22510, 608487,349951,121697,51707, 806516,410793,161303,79145, -2221481,-30,18 ],
  [ 1500,25,10,1,0, 141398,110253,17314,3814, 374235,102544,31186,22641, 885411,427916,73784,38125, 1349505,521642,112459,68989, 1405886,717404,117157,57374, 348992,11,0 ],
  [ 1500,25,15,1,0, 218643,118783,19435,8876, 604759,415832,27489,8588, 1590379,841952,72290,34019, 2513174,1242776,114235,57745, 3367780,1700809,153081,75771, 2382726,35,0 ],
  [ 1500,25,25,1,0, 255856,137273,18608,8624, 1071450,827525,22439,5108, 3316525,1583654,63779,33324, 5492561,2651755,105626,54631, 7665952,3856399,147422,73261, 8731606,67,0 ],
  [ 1500,25,50,1,0, 258997,137709,18500,8470, 1534587,1181761,19344,3075, 6161859,2920480,56017,15753, 10547464,5094314,95886,25826, 15541722,7718890,69690,34632, 16383993,88,0 ],
  [ 1500,25,75,1,0, 258997,137709,18500,8663, 1542733,1183643,19284,4489, 6250923,2933047,55812,29624, 10708277,5132817,95610,49781, 15803295,7857850,141101,70941, 19719594,94,0 ],
  [ 1500,50,1,1,0, 29483,79283,29483,-49800, 42597,56088,42597,-13491, 121982,72440,121982,49542, 130921,58368,130921,72554, 173601,67449,173601,106153, -3350062,-39,25 ],
  [ 1500,50,2,1,0, 52063,82403,26031,-15170, 82346,77058,41173,2644, 211685,85391,105843,63147, 261843,78202,130921,91820, 347203,194004,173601,76599, -2837487,-35,22 ],
  [ 1500,50,3,1,0, 74643,85523,24881,-3626, 122095,84142,40698,12651, 301389,113007,100463,62794, 392764,193370,130921,66465, 520804,349970,173601,56945, -2476354,-32,20 ],
  [ 1500,50,5,1,0, 109109,103622,23806,1197, 192658,97089,38532,19114, 469215,335006,93843,26842, 647711,355938,129542,58355, 858812,434688,171762,84825, -2035696,-28,16 ],
  [ 1500,50,10,1,0, 179886,113408,22027,8140, 397492,343785,33124,4476, 1002177,530959,83515,39268, 1504325,747969,125360,63030, 1998067,985532,166506,84378, -41172,4,0 ],
  [ 1500,50,15,1,0, 235359,121082,20921,10158, 644127,423338,29279,10036, 1706972,873431,77590,37888, 2674810,1282006,121582,63309, 3585654,1834086,162984,79617, 2978596,39,0 ],
  [ 1500,50,25,1,0, 274830,139883,19988,9814, 1139919,855392,23873,5959, 3542570,1722134,68126,35008, 5845045,2842687,112405,57738, 8160963,4063434,156942,78799, 9571390,70,0 ],
  [ 1500,50,50,1,0, 278160,140344,19869,9844, 1631717,1216188,20568,5238, 6567412,3113413,59704,31400, 11222627,5428386,102024,52675, 16543403,8231500,150395,75563, 20860702,97,0 ],
  [ 1500,50,75,1,0, 278160,140344,19869,9844, 1640361,1218185,20505,5277, 6662076,3218282,59483,30748, 11393683,5468436,101729,52904, 16821782,8289266,150194,76183, 21208878,97,0 ],
  [ 1500,100,1,1,0, 39020,80595,39020,-41574, 50167,56936,50167,-6769, 165064,78871,165064,86192, 146784,60788,146784,85997, 194753,70770,194753,123983, -3178526,-38,24 ],
  [ 1500,100,2,1,0, 64236,84077,32118,-9920, 94637,78749,47319,7944, 265570,107914,132785,78828, 293569,98051,146784,97759, 389505,200683,194753,94411, -2646231,-33,21 ],
  [ 1500,100,3,1,0, 89452,100896,29817,-3815, 139108,86527,46369,17527, 366076,199591,122025,55495, 440353,309205,146784,43716, 584258,360057,194753,74734, -2545436,-33,20 ],
  [ 1500,100,5,1,0, 127934,106211,27913,4739, 218042,115065,43608,20596, 554083,347376,110817,41341, 726160,382789,145232,68674, 963404,534766,192681,85728, -1700200,-25,13 ],
  [ 1500,100,10,1,0, 206918,117126,25337,10995, 447118,352695,37260,7869, 1150983,567246,95915,48645, 1686309,870669,140526,67970, 2241106,1235933,186759,83764, 523262,14,0 ],
  [ 1500,100,15,1,0, 268791,139016,23893,11536, 722864,525935,32857,8951, 1940158,921723,88189,46293, 2998084,1549334,136277,65852, 4021402,2046219,182791,89781, 3632018,44,0 ],
  [ 1500,100,25,1,0, 312780,216802,22748,6980, 1276858,897240,26740,7950, 3994659,1922264,76820,39854, 6550011,3196799,125962,64485, 9150985,4604685,175980,87429, 11126370,75,0 ],
  [ 1500,100,50,1,0, 316486,217315,22606,7084, 1825976,1345007,23016,6063, 7378517,3547379,67077,34829, 12572953,6111007,114300,58745, 18546767,9172489,168607,85221, 23514970,101,0 ],
  [ 1500,100,75,1,0, 316486,217315,22606,7084, 1835619,1347233,22945,6105, 7484381,3576824,66825,34889, 12764493,6154049,113969,59022, 18858756,9447872,168382,84026, 23958763,102,0 ],
  [ 1500,200,1,1,0, 58096,83218,58096,-25122, 65306,58631,65306,6675, 251227,106248,251227,144979, 178510,65677,178510,112833, 237055,77462,237055,159593, -2868954,-35,22 ],
  [ 1500,200,2,1,0, 88582,87425,44291,578, 119220,82131,59610,18544, 373339,215444,186669,78948, 357021,188053,178510,84484, 474110,342754,237055,65678, -2572840,-33,20 ],
  [ 1500,200,3,1,0, 119069,104969,39690,4700, 173134,91596,57711,27179, 495451,353839,165150,47204, 535531,339231,178510,65434, 711164,396203,237055,104987, -2246726,-30,18 ],
  [ 1500,200,5,1,0, 165582,111389,36127,11824, 268810,197095,53762,14343, 723820,401445,144764,64475, 883057,421615,176611,92288, 1172587,583233,234517,117871, -1112423,-17,-37 ],
  [ 1500,200,10,1,0, 260981,124561,31957,16705, 546372,384399,45531,13498, 1448597,745719,120716,58573, 2050276,955954,170856,91194, 2727186,1343442,227266,115312, 1880949,30,0 ],
  [ 1500,200,15,1,0, 335655,219912,29836,10288, 880339,569844,40015,14113, 2406531,1216022,109388,54114, 3644631,1772034,165665,85118, 4892898,2540928,222404,106908, 5220799,52,0 ],
  [ 1500,200,25,1,0, 388680,328107,28268,4405, 1550735,1157636,32476,8232, 4898837,2383489,94208,48372, 7959945,3862943,153076,78788, 11131028,5629115,214058,105806, 13814553,82,0 ],
  [ 1500,200,50,1,0, 393139,328724,28081,4601, 2214494,1571680,27914,8103, 9000727,4168126,81825,43933, 15273604,7353861,138851,71998, 22553494,11168059,205032,103504, 29761575,111,0 ],
  [ 1500,200,75,1,0, 393139,328724,28081,4601, 2226134,1648214,27827,7224, 9128992,4369256,81509,42498, 15506114,7498624,138447,71495, 22932704,11340949,204756,103498, 29664534,110,0 ],
  [ 2000,0,1,1,0, 27167,79108,27167,-51941, 46151,57302,46151,-11150, 98325,69107,98325,29218, 142291,60364,142291,81926, 189086,70140,189086,118946, -3375056,-39,25 ],
  [ 2000,0,2,1,0, 54334,83002,27167,-14334, 92303,79490,46151,6406, 196650,83578,98325,56536, 284581,97189,142291,93696, 378172,199400,189086,89386, -2813365,-35,22 ],
  [ 2000,0,3,1,0, 81501,100233,27167,-6244, 138454,87643,46151,16937, 294975,112715,98325,60753, 426872,308004,142291,39623, 567257,358220,189086,69679, -2573677,-33,20 ],
  [ 2000,0,5,1,0, 122975,106178,26831,3665, 220974,117018,44195,20791, 480119,337605,96024,28503, 703802,380849,140760,64591, 935489,531813,187098,80735, -1912370,-27,15 ],
  [ 2000,0,10,1,0, 208187,118398,25492,10995, 463625,371875,38635,7646, 1074053,544205,89504,44154, 1634213,866382,136184,63986, 2177097,1145645,181425,85954, 339522,11,0 ],
  [ 2000,0,15,1,0, 275011,141321,24445,11884, 759393,536294,34518,10141, 1866322,916405,84833,43178, 2908821,1434217,132219,67027, 3906656,2035150,177575,85068, 3538037,43,0 ],
  [ 2000,0,25,1,0, 322599,219851,23462,7473, 1364250,934633,28571,8997, 3962859,1930640,76209,39081, 6390806,3189239,122900,61569, 8881357,4466948,170795,84892, 10902384,74,0 ],
  [ 2000,0,50,1,0, 326619,220428,23330,7585, 1970381,1515485,24837,5734, 7480224,3617071,68002,35120, 12375054,6146160,112500,56626, 17989875,9040903,163544,81354, 22886986,100,0 ],
  [ 2000,0,75,1,0, 326619,220428,23330,7585, 1981055,1517979,24763,5788, 7591538,3647798,67782,35212, 12567122,6189914,112206,56939, 18292649,9202542,163327,81162, 23416997,101,0 ],
  [ 2000,25,1,1,0, 31936,79764,31936,-47828, 49936,57726,49936,-7789, 119866,72322,119866,47544, 150222,61574,150222,88648, 199661,71801,199661,127861, -3289288,-38,25 ],
  [ 2000,25,2,1,0, 60420,83839,30210,-11709, 98448,80335,49224,9057, 223592,87582,111796,68005, 300444,99600,150222,100422, 399323,202715,199661,98304, -2689936,-34,21 ],
  [ 2000,25,3,1,0, 88905,101251,29635,-4115, 146960,88910,48987,19350, 327318,194507,109106,44270, 450666,311616,150222,46350, 598984,363189,199661,78598, -2589084,-33,21 ],
  [ 2000,25,5,1,0, 88905,101520,19397,-2752, 146960,89602,29392,11472, 330318,194996,66064,27065, 450666,311629,90133,27807, 598984,363199,119797,47157, -2586665,-33,21 ],
  [ 2000,25,10,1,0, 221703,120257,27147,12422, 488439,376329,40703,9342, 1148457,569606,95705,48238, 1725205,880116,143767,70424, 2298617,1248379,191551,87520, 691339,17,0 ],
  [ 2000,25,15,1,0, 291727,143620,25931,13165, 798762,543800,36307,11589, 1982915,933369,90132,47707, 3070458,1581857,139566,67664, 4124530,2168377,187479,88916, 4007333,46,0 ],
  [ 2000,25,25,1,0, 341574,222461,24842,8663, 1432720,1052965,30005,7953, 4188904,2067770,80556,40791, 6743289,3352019,129679,65217, 9376368,4770953,180315,88566, 11431221,75,0 ],
  [ 2000,25,50,1,0, 341574,222461,24398,8508, 1432720,1052965,18059,4787, 6789503,3275688,61723,31944, 6923171,3458607,62938,31496, 9629316,4940586,87539,42625, 14785804,84,0 ],
  [ 2000,25,75,1,0, 345782,223064,24699,8766, 2078683,1552521,25984,6577, 8002691,3918519,71453,36466, 13252527,6525483,118326,60063, 19311136,9717689,172421,85656, 24880047,103,0 ],
  [ 2000,50,1,1,0, 36705,80420,36705,-43715, 53721,58149,53721,-4428, 141406,75538,141406,65869, 158154,62784,158154,95370, 210237,73461,210237,136776, -3203520,-38,24 ],
  [ 2000,50,2,1,0, 66507,84676,33253,-9085, 104594,81181,52297,11707, 250534,106101,125267,72217, 316307,182368,158154,66969, 420474,319170,210237,50652, -2799162,-35,22 ],
  [ 2000,50,3,1,0, 96309,102269,32103,-1987, 155467,90177,51822,21763, 359662,199299,119887,53454, 474461,330205,158154,48085, 630711,368157,210237,87518, -2450096,-32,20 ],
  [ 2000,50,5,1,0, 141799,108767,30938,7207, 246358,194959,49272,10280, 564988,350125,112998,42973, 782251,392723,156450,77905, 1040081,563832,208016,95250, -1696407,-24,13 ],
  [ 2000,50,10,1,0, 235219,122115,28802,13849, 513252,380784,42771,11039, 1222860,580492,101905,53531, 1816197,908775,151350,75618, 2420137,1282955,201678,94765, 1103112,22,0 ],
  [ 2000,50,15,1,0, 308443,217618,27417,8073, 838130,565191,38097,12406, 2099508,1070747,95432,46762, 3232094,1621587,146913,73205, 4342405,2218324,197382,96549, 4079993,46,0 ],
  [ 2000,50,25,1,0, 360549,225070,26222,9853, 1501189,1154681,31439,7257, 4414948,2114735,84903,44235, 7095773,3419464,136457,70698, 9871379,4977938,189834,94105, 12429400,78,0 ],
  [ 2000,50,50,1,0, 364945,225699,26068,9946, 2164640,1584339,27285,7315, 8291329,3988402,75376,39118, 13725380,6705394,124776,63818, 19993238,10065673,181757,90251, 26290876,105,0 ],
  [ 2000,50,75,1,0, 364945,225699,26068,9946, 2176312,1587062,27204,7366, 8413843,4083189,75124,38667, 13937932,6767068,124446,64026, 20329623,10261646,181514,89893, 26705547,106,0 ],
  [ 2000,100,1,1,0, 46242,81731,46242,-35489, 61291,58997,61291,2293, 184488,81968,184488,102520, 174017,65204,174017,108813, 231388,76782,231388,154606, -3031984,-37,23 ],
  [ 2000,100,2,1,0, 78680,86350,39340,-3835, 116885,82872,58443,17007, 304419,114108,152209,95155, 348033,187190,174017,80421, 462776,341470,231388,60653, -2563476,-33,20 ],
  [ 2000,100,3,1,0, 111118,104306,37039,2271, 172480,92712,57493,26589, 424349,329448,141450,31634, 522050,337980,174017,61357, 694164,394316,231388,99949, -2417964,-32,19 ],
  [ 2000,100,5,1,0, 160624,111356,35045,10749, 271742,199049,54348,14539, 649857,377159,129971,54539, 860699,419624,172140,88215, 1144673,580230,228935,112888, -1291093,-20,-47 ],
  [ 2000,100,10,1,0, 262251,139170,32112,15071, 562879,389544,46907,14445, 1371667,722829,114306,54070, 1998180,951818,166515,87197, 2663177,1337135,221931,110504, 1626366,28,0 ],
  [ 2000,100,15,1,0, 341875,222216,30389,10636, 916867,684553,41676,10560, 2332694,1194689,106032,51728, 3555368,1765327,161608,81365, 4778153,2529309,217189,102220, 4673623,50,0 ],
  [ 2000,100,25,1,0, 398499,331156,28982,4898, 1638127,1195029,34306,9280, 4867037,2391865,93597,47599, 7800740,3855383,150014,75872, 10861401,5520688,208873,102706, 13569669,81,0 ],
  [ 2000,100,50,1,0, 403272,331837,28805,5102, 2358899,1711658,29734,8159, 9102434,4422368,82749,42546, 15075706,7389515,137052,69874, 21996602,11008162,199969,99895, 28825454,109,0 ],
  [ 2000,100,75,1,0, 403272,331837,28805,5102, 2371569,1714610,29645,8212, 9236148,4456246,82466,42678, 15308743,7519562,136685,69546, 22366597,11209260,199702,99619, 29357361,110,0 ],
  [ 2000,200,1,1,0, 65318,84355,65318,-19037, 76430,60693,76430,15737, 270651,109345,270651,161306, 205742,70093,205742,135649, 273690,83475,273690,190215, -2722412,-34,21 ],
  [ 2000,200,2,1,0, 103026,103035,51513,-5, 141468,86105,70734,27682, 412188,221638,206094,95275, 411485,305495,205742,52995, 547380,354929,273690,96226, -2494523,-32,20 ],
  [ 2000,200,3,1,0, 140735,108379,46912,10785, 206505,97781,68835,36241, 553724,363131,184575,63531, 617227,352479,205742,88250, 821071,414241,273690,135610, -1807099,-26,14 ],
  [ 2000,200,5,1,0, 198272,116534,43259,17834, 322510,207230,64502,23056, 819594,416713,163919,80576, 1017596,523731,203519,98773, 1353856,741738,270771,122424, -613088,-10,0 ],
  [ 2000,200,10,1,0, 316314,218305,38732,12001, 662132,421248,55178,20074, 1669280,872252,139107,66419, 2362148,1225371,196846,94731, 3149257,1656686,262438,124381, 2328811,34,0 ],
  [ 2000,200,15,1,0, 408739,332279,36332,6796, 1074342,726963,48834,15790, 2799067,1382938,127230,64370, 4201915,2097488,190996,95656, 5649649,2925567,256802,123822, 6298925,58,0 ],
  [ 2000,200,25,1,0, 474399,354931,34502,8689, 1912004,1352425,40042,11719, 5771215,2777590,110985,57570, 9210673,4548330,177128,89660, 12841444,6460488,246951,122711, 17138136,89,0 ],
  [ 2000,200,50,1,0, 479924,355716,34280,8872, 2747417,2013530,34631,9251, 10724644,5134650,97497,50818, 17776357,8631470,161603,83135, 26003330,13002832,236394,118186, 34985382,117,0 ],
  [ 2000,200,75,1,0, 479924,355716,34280,8872, 2762084,2016941,34526,9314, 10880759,5248678,97150,50286, 18050364,8782479,161164,82749, 26440545,13313529,236076,117206, 35389098,118,0 ],
  [ 2500,0,1,1,0, 34389,80244,34389,-45855, 57275,59363,57275,-2088, 117749,72204,117749,45546, 169523,64780,169523,104742, 225721,76153,225721,149568, -3228513,-38,25 ],
  [ 2500,0,2,1,0, 68778,85275,34389,-8249, 114550,83613,57275,15469, 235499,89772,117749,72863, 339045,186328,169523,76359, 451442,324516,225721,63463, -2719437,-34,21 ],
  [ 2500,0,3,1,0, 68778,85493,22926,-5572, 114550,84054,38183,10165, 235499,167523,78500,22658, 339045,186339,113015,50902, 451442,324524,150481,42306, -2899981,-36,23 ],
  [ 2500,0,5,1,0, 155665,111323,33963,9675, 274674,201003,54935,14734, 575893,352874,115179,44604, 838341,417634,167668,84141, 1116758,577228,223352,107906, -1469763,-22,10 ],
  [ 2500,0,10,1,0, 263521,140442,32268,15071, 579386,408723,48282,14222, 1294737,699788,107895,49579, 1946084,947531,162174,83213, 2599168,1330627,216597,105712, 1382892,25,0 ],
  [ 2500,0,15,1,0, 348095,224521,30942,10984, 953396,708798,43336,11118, 2258858,1189221,102675,48620, 3466105,1758670,157550,77611, 4663407,2418739,211973,102030, 4446892,48,0 ],
  [ 2500,0,25,1,0, 408318,347542,29696,4420, 1725520,1232273,36137,10330, 4835238,2414756,92985,46548, 7641534,3767716,146953,74496, 10591773,5383151,203688,100166, 13376013,81,0 ],
  [ 2500,0,50,1,0, 413404,348287,29529,4651, 2503305,1881986,31554,7832, 9204142,4492060,83674,42837, 14877808,7424818,135253,67754, 21439710,10876726,194906,96027, 28142778,108,0 ],
  [ 2500,0,75,1,0, 413404,348287,29529,4651, 2517005,1885207,31463,7897, 9343305,4527220,83422,43001, 15111372,7475270,134923,68179, 21800490,11047860,194647,96006, 28819693,109,0 ],
  [ 2500,25,1,1,0, 39158,80900,39158,-41743, 61060,59787,61060,1273, 139290,75419,139290,63871, 177454,65990,177454,111464, 236297,77813,236297,158483, -3142745,-37,24 ],
  [ 2500,25,2,1,0, 74864,86112,37432,-5624, 120696,84458,60348,18119, 262441,108291,131220,77075, 354908,188789,177454,83059, 472593,343551,236297,64521, -2640681,-33,21 ],
  [ 2500,25,3,1,0, 110571,104661,36857,1970, 180332,95095,60111,28412, 385592,203798,128531,60598, 532362,339841,177454,64174, 708890,396898,236297,103998, -2182734,-29,18 ],
  [ 2500,25,5,1,0, 165077,112617,36017,11446, 287366,203048,57473,16864, 618327,373648,123665,48936, 877566,423621,175513,90789, 1169054,585452,233811,116720, -1272805,-19,-46 ],
  [ 2500,25,10,1,0, 165077,113322,20214,6337, 287366,205778,23947,6799, 626074,374911,52173,20930, 877566,423655,73130,37826, 1169054,585477,97421,48631, -1269588,-19,-46 ],
  [ 2500,25,15,1,0, 364811,327686,32428,3300, 992765,714804,45126,12635, 2375451,1220700,107975,52489, 3627742,1799400,164897,83106, 4881281,2569187,221876,105095, 4617915,50,0 ],
  [ 2500,25,25,1,0, 427293,350151,31076,5610, 1793989,1260139,37570,11180, 5061282,2461721,97332,49992, 7994018,4038906,153731,76060, 11086784,5687556,213207,103831, 14237813,83,0 ],
  [ 2500,25,50,1,0, 427293,350184,30521,5508, 1793989,1415779,22613,4767, 5113906,2499267,46490,23769, 7994018,4039008,72673,35955, 11086784,5687657,100789,49083, 13779082,82,0 ],
  [ 2500,25,75,1,0, 432567,350922,30898,5832, 2614634,1993598,32683,7763, 9754458,4797941,87093,44255, 15796777,7891146,141043,70586, 22818977,11592318,203741,100238, 29909539,111,0 ],
  [ 2500,50,1,1,0, 43927,81556,43927,-37630, 64845,60211,64845,4634, 160831,78635,160831,82196, 185386,67200,185386,118186, 246872,79474,246872,167398, -3056978,-37,24 ],
  [ 2500,50,2,1,0, 80951,86949,40475,-2999, 126842,85304,63421,20769, 289383,112295,144692,88544, 370771,191200,185386,89785, 493745,346866,246872,73439, -2517251,-32,20 ],
  [ 2500,50,3,1,0, 117975,105679,39325,4099, 188839,96362,62946,30825, 417935,329155,139312,29593, 556157,344003,185386,70718, 740617,402416,246872,112734, -2300521,-31,18 ],
  [ 2500,50,5,1,0, 174490,113912,38070,13217, 300058,205093,60012,18993, 660761,379908,132152,56171, 916790,509866,183358,81385, 1221350,609297,244270,122411, -1172044,-18,-42 ],
  [ 2500,50,10,1,0, 290552,144160,35578,17926, 629013,417633,52418,17615, 1443544,736075,120295,58956, 2128068,1098384,177339,85807, 2842208,1467937,236851,114523, 1986408,31,0 ],
  [ 2500,50,15,1,0, 381527,329985,33914,4581, 1032133,722310,46915,14083, 2492044,1237663,113275,57017, 3789378,1947040,172244,83743, 5099155,2618634,231780,112751, 5146946,52,0 ],
  [ 2500,50,25,1,0, 446268,352761,32456,6800, 1862458,1347971,39004,10775, 5287327,2585836,101679,51952, 8346501,4201636,160510,79709, 11581795,6007682,222727,107194, 14881818,84,0 ],
  [ 2500,50,50,1,0, 451730,353558,32266,7012, 2697564,2010805,34003,8657, 10015247,4849026,91048,46966, 16228133,7984002,147528,74947, 23443074,11817715,213119,105685, 31449705,113,0 ],
  [ 2500,50,75,1,0, 451730,353558,32266,7012, 2712263,2028140,33903,8552, 10165610,4962612,90764,46455, 16482182,8241191,147162,73580, 23837464,12106964,212835,104737, 31595863,113,0 ],
  [ 2500,100,1,1,0, 53464,82868,53464,-29404, 72415,61059,72415,11356, 203913,99581,203913,104332, 201249,69670,201249,131579, 268023,82845,268023,185178, -2918942,-36,23 ],
  [ 2500,100,2,1,0, 93124,101960,46562,-4418, 139133,86845,69567,26144, 343268,197302,171634,72983, 402497,196172,201249,103162, 536047,353645,268023,91201, -2501583,-32,20 ],
  [ 2500,100,3,1,0, 132784,107716,44261,8356, 205851,98897,68617,35652, 482623,338739,160874,47961, 603746,351228,201249,84173, 804070,412354,268023,130572, -1978338,-28,16 ],
  [ 2500,100,5,1,0, 193314,116501,42178,16759, 325442,209183,65088,23252, 745630,406943,149126,67737, 995238,521791,199048,94690, 1325942,738785,265188,117431, -825259,-13,-23 ],
  [ 2500,100,10,1,0, 317584,219577,38888,12001, 678639,500393,56553,14854, 1592351,849362,132696,61916, 2310052,1221084,192504,90747, 3085248,1650228,257104,119585, 1895140,31,0 ],
  [ 2500,100,15,1,0, 414959,347920,36885,5959, 1110871,824907,50494,12998, 2725231,1300470,123874,64762, 4112652,2105958,186939,91213, 5534903,2914598,251586,119105, 5867207,56,0 ],
  [ 2500,100,25,1,0, 484218,357980,35216,9181, 1999397,1494169,41872,10581, 5739416,2798981,110373,56547, 9051468,4540821,174067,86743, 12571817,6435392,241766,118008, 16473396,88,0 ],
  [ 2500,100,50,1,0, 490057,358829,35004,9373, 2891823,2079659,36452,10237, 10826352,5297357,98421,50264, 17578459,8666673,159804,81016, 25446437,12871795,231331,114315, 34474022,117,0 ],
  [ 2500,100,75,1,0, 490057,358829,35004,9373, 2907520,2083338,36344,10302, 10987916,5321153,98106,50596, 17852992,8818345,159402,80666, 25874438,13053028,231022,114477, 35185127,117,0 ],
  [ 2500,200,1,1,0, 72540,85491,72540,-12952, 87554,76639,87554,10915, 290076,126807,290076,163268, 232975,74559,232975,158415, 310326,188939,310326,121387, -2723861,-34,21 ],
  [ 2500,200,2,1,0, 117470,105308,58735,6081, 163716,90228,81858,36744, 451037,333882,225518,58577, 465949,329803,232975,68073, 620651,367454,310326,126599, -2468920,-32,20 ],
  [ 2500,200,3,1,0, 162401,111789,54134,16871, 239877,117851,79959,40675, 611998,372272,203999,79908, 698924,380703,232975,106073, 930977,531680,310326,133099, -1504067,-22,11 ],
  [ 2500,200,5,1,0, 230963,121678,50392,23844, 376210,335599,75242,8122, 915367,444847,183073,94104, 1152135,560566,230427,118314, 1535125,786652,307025,149695, -340406,-4,0 ],
  [ 2500,200,10,1,0, 371648,327879,45508,5359, 777893,530597,64824,20608, 1889964,921785,157497,80682, 2674020,1307369,222835,113888, 3571327,1842518,297611,144067, 3024798,40,0 ],
  [ 2500,200,15,1,0, 481823,357116,42829,11085, 1268345,868816,57652,18160, 3191603,1594769,145073,72583, 4759199,2437118,216327,105549, 6406399,3325526,291200,140040, 7649632,63,0 ],
  [ 2500,200,25,1,0, 560117,368419,40736,13942, 2273274,1651715,47608,13017, 6643594,3261706,127761,65036, 10461401,5140134,201181,102332, 14551860,7375042,279843,138016, 20058029,95,0 ],
  [ 2500,200,50,1,0, 566709,369371,40479,14096, 3280341,2381681,41349,11328, 12448562,6009639,113169,58536, 20279111,9988785,184356,93548, 29453165,14837005,267756,132874, 40591016,124,0 ],
  [ 2500,200,75,1,0, 566709,369371,40479,14096, 3282781,2385446,41035,11217, 12532433,6117251,111897,57278, 20452368,10060671,182610,92783, 29723130,15133442,265385,130265, 40662832,124,0 ],
  [ 3000,0,1,1,0, 41611,81381,41611,-39770, 68399,75310,68399,-6911, 137174,75151,137174,62023, 196755,69196,196755,127558, 262357,82115,262357,180241, -3125555,-37,24 ],
  [ 3000,0,2,1,0, 83222,100885,41611,-8832, 136798,87586,68399,24606, 274348,110482,137174,81933, 393509,195360,196755,99075, 524713,352412,262357,86151, -2525720,-32,20 ],
  [ 3000,0,3,1,0, 124833,107052,41611,5927, 205197,113897,68399,30433, 411521,314198,137174,32441, 590264,349977,196755,80096, 787070,410417,262357,125551, -2193160,-30,18 ],
  [ 3000,0,5,1,0, 188356,107052,27236,3879, 328374,113897,65675,42895, 411521,314198,82304,19465, 590264,349977,118053,48057, 1313830,735618,262766,115642, -1399635,-21,9 ],
  [ 3000,0,10,1,0, 318854,220850,39043,12001, 695146,505687,57929,15788, 1515421,840986,126285,56203, 2257956,1216847,188163,86759, 3021238,1643821,251770,114785, 1661750,28,0 ],
  [ 3000,0,15,1,0, 421179,350224,37438,6307, 1147399,835266,52155,14188, 2651394,1280637,120518,62307, 4023389,2099251,182881,87461, 5420157,2790388,246371,119535, 5727284,55,0 ],
  [ 3000,0,25,1,0, 494037,361029,35930,9673, 2086789,1531562,43702,11628, 5707616,2884357,109762,54293, 8892263,4548238,171005,83539, 12302189,6410745,236581,113297, 15970176,87,0 ],
  [ 3000,0,50,1,0, 500189,361942,35728,9875, 3036229,2323987,38272,8978, 10928059,5367050,99346,50555, 17380561,8701826,158005,78898, 24889545,12710899,226269,110715, 33633156,115,0 ],
  [ 3000,0,75,1,0, 500189,361942,35728,9875, 3052956,2327935,38162,9063, 11095073,5512692,99063,49843, 17655621,8839783,157639,78713, 25308332,13005119,225967,109850, 34012440,116,0 ],
  [ 3000,25,1,1,0, 46380,82037,46380,-35657, 72184,75734,72184,-3550, 158715,78366,158715,80348, 204686,70406,204686,134280, 272932,99447,272932,173485, -3050960,-37,24 ],
  [ 3000,25,2,1,0, 89308,101722,44654,-6207, 142944,88432,71472,27256, 301290,114486,150645,93402, 409372,306231,204686,51570, 545864,355727,272932,95069, -2562364,-33,20 ],
  [ 3000,25,3,1,0, 132237,108071,44079,8055, 213704,115165,71235,32846, 443865,333505,147955,36787, 614058,353639,204686,86806, 818796,431106,272932,129230, -2076743,-28,17 ],
  [ 3000,25,5,1,0, 197768,117762,43149,17456, 341066,331417,68213,1930, 714101,387267,142820,65367, 1012105,540714,202421,94278, 1350323,743406,270065,121383, -1165778,-18,-39 ],
  [ 3000,25,10,1,0, 332370,222709,40698,13428, 719960,524027,59997,16328, 1589824,851722,132485,61509, 2348948,1230530,195746,93201, 3142758,1662675,261897,123340, 2063217,32,0 ],
  [ 3000,25,15,1,0, 437895,352523,38924,7589, 1186768,856657,53944,15005, 2767987,1388966,125818,62683, 4185026,2123504,190228,93706, 5638031,2952925,256274,122050, 6104279,57,0 ],
  [ 3000,25,25,1,0, 513012,363639,37310,10863, 2155259,1559428,45136,12478, 5933661,2931322,114109,57737, 9244746,4630660,177784,88732, 12797200,6602060,246100,119137, 17191206,89,0 ],
  [ 3000,25,50,1,0, 519352,364578,37097,11055, 3133359,2358414,39496,9768, 11333612,5636982,103033,51788, 18055724,9035898,164143,81998, 25891227,13139728,235375,115923, 35120610,117,0 ],
  [ 3000,25,75,1,0, 519352,364578,37097,11055, 3150585,2362476,39382,9851, 11506225,5677363,102734,52043, 18341026,9189828,163759,81707, 26326819,13435986,235061,115097, 35758498,118,0 ],
  [ 3000,50,1,1,0, 51149,82693,51149,-31544, 75969,76158,75969,-189, 180255,81582,180255,98674, 212618,71616,212618,141002, 283508,101108,283508,182400, -2965192,-36,23 ],
  [ 3000,50,2,1,0, 95395,102559,47697,-3582, 149090,89277,74545,29906, 328232,195489,164116,66371, 425235,308642,212618,58296, 567015,359041,283508,103987, -2615432,-33,21 ],
  [ 3000,50,3,1,0, 139641,109089,46547,10184, 222210,116432,74070,35259, 476209,338297,158736,45971, 637853,372228,212618,88542, 850523,436075,283508,138149, -1937755,-27,15 ],
  [ 3000,50,5,1,0, 207180,119057,45203,19227, 353758,333462,70752,4059, 756535,408042,151307,69699, 1051329,546701,210266,100926, 1402619,767301,280524,127064, -979993,-15,-29 ],
  [ 3000,50,10,1,0, 345886,224567,42353,14855, 744773,528482,62064,18024, 1664227,877123,138686,65592, 2439940,1259240,203328,98392, 3264278,1697300,272023,130581, 2441491,35,0 ],
  [ 3000,50,15,1,0, 442550,354615,39338,7817, 842363,835576,38289,308, 2322663,1473009,105576,38621, 3464969,2091354,157499,62437, 4641583,2904230,210981,78971, 2173992,33,0 ],
  [ 3000,50,25,1,0, 531987,366248,38690,12054, 2223728,1573410,46570,13619, 6159705,3099002,118456,58860, 9597229,4887423,184562,90573, 13292211,6838955,255619,124101, 17888375,91,0 ],
  [ 3000,50,50,1,0, 538515,367213,38465,12236, 3230488,2378956,40720,10734, 11739164,5801015,106720,53983, 18730887,9341318,170281,85360, 26892909,13764979,244481,119345, 36798210,119,0 ],
  [ 3000,50,75,1,0, 538515,367213,38465,12236, 3248214,2397017,40603,10640, 11917378,5948084,106405,53297, 19026432,9525397,169879,84831, 27345306,13966804,244155,119451, 37210374,120,0 ],
  [ 3000,100,1,1,0, 60686,84004,60686,-23318, 83539,77006,83539,6533, 223337,102528,223337,120809, 228481,74086,228481,154395, 304659,104479,304659,200180, -2827156,-35,22 ],
  [ 3000,100,2,1,0, 107568,104233,53784,1667, 161381,90969,80690,35206, 382117,218012,191058,82052, 456961,328491,228481,64235, 609318,365720,304659,121799, -2424177,-32,19 ],
  [ 3000,100,3,1,0, 154450,111125,51483,14441, 239223,118967,79741,40086, 540896,362396,180299,59500, 685442,379502,228481,101980, 913976,446062,304659,155971, -1649072,-24,12 ],
  [ 3000,100,5,1,0, 226004,121645,49310,22769, 379142,337552,75828,8318, 841403,420561,168281,84168, 1129777,558576,225955,114240, 1507211,783649,301442,144712, -519076,-8,0 ],
  [ 3000,100,10,1,0, 372917,329152,45663,5359, 794400,535892,66200,21542, 1813034,913410,151086,74969, 2621924,1303132,218494,109899, 3507318,1836111,292277,139267, 2791407,38,0 ],
  [ 3000,100,15,1,0, 488043,359420,43382,11433, 1304874,893060,59312,18719, 3117767,1574786,141717,70135, 4669936,2430411,212270,101797, 6291653,3185596,285984,141184, 7477299,62,0 ],
  [ 3000,100,25,1,0, 569936,384804,41450,13464, 2360666,1688958,49438,14067, 6611794,3270082,127150,64264, 10302196,5132725,198119,99413, 14282233,7350545,274658,133302, 19698717,94,0 ],
  [ 3000,100,50,1,0, 576842,385821,41203,13644, 3424747,2521510,43169,11385, 12550269,6157831,114093,58113, 20081212,10024088,182556,91428, 28896272,14706068,262693,129002, 39824683,123,0 ],
  [ 3000,100,75,1,0, 576842,385821,41203,13644, 3443471,2525915,43043,11469, 12739683,6306625,113747,57438, 20397242,10102700,182118,91916, 29382280,14913017,262342,129190, 40511164,123,0 ],
  [ 3000,200,1,1,0, 79762,86628,79762,-6866, 98678,78701,98678,19977, 309500,129905,309500,179596, 260207,78975,260207,181231, 346961,194951,346961,152010, -2577319,-33,20 ],
  [ 3000,200,2,1,0, 131914,107582,65957,12166, 185963,94351,92982,45806, 489886,354592,244943,67647, 520413,338685,260207,90864, 693922,395200,346961,149361, -2220509,-30,18 ],
  [ 3000,200,3,1,0, 184067,115199,61356,22956, 273249,197886,91083,25121, 670271,396079,223424,91397, 780620,394001,260207,128873, 1040883,565439,346961,158482, -1342895,-20,9 ],
  [ 3000,200,5,1,0, 263653,140160,57524,26944, 429910,345583,85982,16865, 1011141,551631,202228,91902, 1286674,706012,257335,116133, 1716394,916046,343279,160070, -148517,1,0 ],
  [ 3000,200,10,1,0, 426981,349924,52283,9436, 893653,567446,74471,27184, 2110648,1091883,175887,84897, 2985891,1562359,248824,118628, 3993398,2072532,332783,160072, 3746102,44,0 ],
  [ 3000,200,15,1,0, 554907,368616,49325,16559, 1462348,936970,66470,23881, 3584140,1763035,162915,82777, 5316483,2652612,241658,121085, 7163149,3679804,325598,158334, 9605442,70,0 ],
  [ 3000,200,25,1,0, 645836,395243,46970,18225, 2634543,1877004,55174,15865, 7515972,3654307,144538,74263, 11712130,5825522,225233,113204, 16262276,8289695,312736,153319, 23229037,100,0 ],
  [ 3000,200,50,1,0, 653494,396363,46678,18367, 3813265,2823532,48066,12476, 14172480,6884628,128841,66253, 22781864,11265943,207108,104690, 32903000,16671328,299118,147561, 46026701,129,0 ],
  [ 3000,200,75,1,0, 653494,396363,46678,18367, 3813265,2823736,47666,12369, 14174532,6884960,126558,65085, 22781864,11265943,203409,102821, 32903000,16671328,293777,144926, 46030000,129,0 ],
  [ 1000,0,1,2,0, 19880,77746,19880,-57867, 35115,55018,35115,-19904, 80953,51442,80953,29511, 114712,55474,114712,59238, 146578,62809,146578,83770, -3484801,-40,26 ],
  [ 1000,0,2,2,0, 39759,80279,19880,-20260, 70229,61038,35115,4596, 161907,77429,80953,42239, 229424,72532,114712,78446, 293157,101107,146578,96025, -2974434,-36,23 ],
  [ 1000,0,3,2,0, 59639,82811,19880,-7724, 105344,80943,35115,8134, 242860,88751,80953,51370, 344136,184824,114712,53104, 439735,320504,146578,39743, -2743972,-34,22 ],
  [ 1000,0,5,2,0, 89992,100016,19635,-2187, 166948,91785,33390,15033, 393400,201479,78680,38384, 568613,341529,113723,45417, 725950,395918,145190,66006, -2192755,-29,18 ],
  [ 1000,0,10,2,0, 152377,107976,18658,5437, 341930,331250,28494,890, 866872,415383,72239,37624, 1327252,701613,110604,52137, 1694529,902421,141211,66009, -662941,-10,0 ],
  [ 1000,0,15,2,0, 201317,114227,17895,7741, 548350,386980,24925,7335, 1487728,727815,67624,34541, 2364420,1196678,107474,53079, 3050268,1498607,138649,70530, 2035986,32,0 ],
  [ 1000,0,25,2,0, 232865,118271,16936,8334, 890684,707465,18653,3837, 2413568,1339230,46415,20660, 3957629,1211739,76108,52806, 5255720,1523827,101072,71767, 6814052,60,0 ],
  [ 1000,0,50,2,0, 239139,119068,17081,8576, 1345732,925951,16963,5291, 5653499,2491839,51395,28742, 9844857,4584881,89499,47818, 14294719,6944460,129952,66821, 18660748,92,0 ],
  [ 1000,0,75,2,0, 239139,119068,17081,8576, 1352661,927513,16908,5314, 5733113,2579349,51189,28159, 9992630,4620625,89220,47964, 14537846,6995895,129802,67339, 18961657,93,0 ],
  [ 1000,25,1,2,0, 26252,78623,26252,-52370, 40361,55629,40361,-15268, 107135,69862,107135,37273, 125072,57105,125072,67967, 159963,64961,159963,95002, -3409049,-39,26 ],
  [ 1000,25,2,2,0, 48191,81438,24095,-16624, 78942,76141,39471,1401, 195440,82257,97720,56591, 250143,75682,125072,87231, 319926,189033,159963,65446, -2916762,-36,23 ],
  [ 1000,25,3,2,0, 70129,84254,23376,-4708, 117523,82768,39174,11585, 283745,109317,94582,58143, 375215,189594,125072,61874, 479889,326845,159963,51015, -2564745,-33,20 ],
  [ 1000,25,5,2,0, 70129,84254,15301,-3082, 117523,82768,23505,6951, 285845,109657,57169,35238, 375215,189594,75043,37124, 479889,326845,95978,30609, -2560710,-33,20 ],
  [ 1000,25,10,2,0, 172417,110732,21112,7553, 378164,337726,31514,3370, 965555,506808,80463,38229, 1446776,719591,120565,60599, 1849015,942129,154085,75574, -249603,-1,0 ],
  [ 1000,25,15,2,0, 172417,110732,15326,5483, 378164,337726,17189,1838, 1443311,722446,65605,32767, 1470999,739191,66864,33264, 1881675,963882,85531,41718, 364262,12,0 ],
  [ 1000,25,25,2,0, 264760,135955,19255,9368, 1052969,742545,22052,6501, 3389583,1575516,65184,34886, 5626499,2650360,108202,57233, 7631974,3813803,146769,73426, 9376059,69,0 ],
  [ 1000,25,50,2,0, 268003,136374,19143,9402, 1489949,1148346,18781,4306, 6218801,2797584,56535,31102, 10747127,4982652,97701,52404, 15585852,7532319,141690,73214, 20299704,96,0 ],
  [ 1000,25,75,2,0, 268003,136374,19143,9402, 1497627,1150079,18720,4344, 6306437,2886221,56307,30538, 10908781,5100731,97400,51858, 15850859,7700148,141526,72774, 20449827,96,0 ],
  [ 1000,50,1,2,0, 32625,79499,32625,-46874, 45607,56239,45607,-10632, 133316,73767,133316,59549, 135431,58685,135431,76746, 173348,67063,173348,106284, -3299798,-38,25 ],
  [ 1000,50,2,2,0, 56623,82598,28311,-12988, 87654,77358,43827,5148, 228973,87235,114487,70869, 270862,78833,135431,96015, 346695,193230,173348,76733, -2755773,-34,22 ],
  [ 1000,50,3,2,0, 80620,85697,26873,-1692, 129701,84593,43234,15036, 324630,192368,108210,44087, 406293,194314,135431,70660, 520043,348807,173348,57079, -2539688,-33,20 ],
  [ 1000,50,5,2,0, 117248,103765,25581,2942, 203620,97688,40724,21186, 502759,338159,100552,32920, 671223,357617,134245,62721, 858472,417188,171694,88257, -1855991,-26,15 ],
  [ 1000,50,10,2,0, 192457,113488,23566,9670, 414397,344202,34533,5850, 1064239,535748,88687,44041, 1566299,752595,130525,67809, 2003501,966216,166958,86440, 295803,11,0 ],
  [ 1000,50,15,2,0, 251398,121115,22346,11581, 663974,422815,30181,10962, 1801931,879375,81906,41934, 2790452,1290534,126839,68178, 3605670,1828650,163894,80774, 3488982,43,0 ],
  [ 1000,50,25,2,0, 293330,139885,21333,11160, 1154218,850905,24172,6352, 3699981,1726132,71153,37959, 6094122,2858414,117195,62225, 8266321,4042283,158968,81232, 10492948,73,0 ],
  [ 1000,50,50,2,0, 296867,140344,21205,11180, 1634166,1192690,20599,5565, 6784102,3104828,61674,33448, 11649396,5445604,105904,56398, 16876985,8217948,153427,78719, 22387876,99,0 ],
  [ 1000,50,75,2,0, 296867,140344,21205,11180, 1642593,1194595,20532,5600, 6879762,3117593,61426,33591, 11824932,5485403,105580,56603, 17163872,8275989,153249,79356, 22962024,100,0 ],
  [ 1000,100,1,2,0, 45371,81252,45371,-35881, 56100,57459,56100,-1359, 185678,96092,185678,89586, 156150,61897,156150,94254, 200117,71318,200117,128799, -3114795,-37,24 ],
  [ 1000,100,2,2,0, 73486,84917,36743,-5716, 105079,79793,52539,12643, 296039,111706,148020,92167, 312300,100160,156150,106070, 400234,201673,200117,99281, -2489397,-32,20 ],
  [ 1000,100,3,2,0, 101601,101919,33867,-106, 154058,88092,51353,21989, 406401,218986,135467,62472, 468450,312414,156150,52012, 600351,361588,200117,79588, -2362499,-31,19 ],
  [ 1000,100,5,2,0, 144504,107513,31528,8071, 240292,117477,48058,24563, 612118,368640,122424,48696, 773834,388182,154767,77130, 990994,537359,198199,90727, -1410443,-21,10 ],
  [ 1000,100,10,2,0, 232537,119000,28474,13902, 486863,357153,40572,10809, 1261605,579113,105134,56874, 1805346,883885,150446,76788, 2312473,1242553,192706,89160, 1160981,23,0 ],
  [ 1000,100,15,2,0, 301478,141339,26798,14235, 779599,532350,35436,11239, 2116134,953934,96188,52827, 3216484,1588284,146204,74009, 4161072,2143172,189140,91723, 4540474,49,0 ],
  [ 1000,100,25,2,0, 350470,219443,25489,9529, 1356716,906041,28413,9438, 4320777,2042050,83092,43822, 7029367,3342104,135180,70909, 9535015,4741595,183366,92181, 12636991,79,0 ],
  [ 1000,100,50,2,0, 354594,219983,25328,9615, 1922600,1355229,24234,7152, 7914705,3598751,71952,39236, 13453934,6305626,122308,64985, 19459252,9491237,176902,90618, 26481212,106,0 ],
  [ 1000,100,75,2,0, 354594,219983,25328,9615, 1932524,1357478,24157,7188, 8026411,3628288,71664,39269, 13657235,6349531,121940,65247, 19789897,9655385,176696,90487, 27047694,107,0 ],
  [ 1000,200,1,2,0, 70862,84758,70862,-13896, 77084,59899,77084,17185, 290403,126227,290403,164176, 197588,68269,197588,129319, 253656,79777,253656,173879, -2711290,-34,21 ],
  [ 1000,200,2,2,0, 107213,102892,53607,2160, 139928,84513,69964,27708, 430172,223134,215086,103519, 395176,193269,197588,100954, 507311,347420,253656,79946, -2320459,-31,19 ],
  [ 1000,200,3,2,0, 143564,107690,47855,11958, 202772,95392,67591,35794, 569941,363755,189980,68729, 592764,346821,197588,81981, 760967,402971,253656,119332, -1823742,-26,14 ],
  [ 1000,200,5,2,0, 199016,115010,43422,18328, 313636,203134,62727,22100, 830836,415386,166167,83090, 979055,514592,195811,92893, 1256039,610190,251208,129170, -609414,-9,0 ],
  [ 1000,200,10,2,0, 312697,143361,38289,20735, 631796,396792,52650,19584, 1656339,863258,138028,66090, 2283441,1190293,190287,91096, 2930417,1470245,244201,121681, 2506203,35,0 ],
  [ 1000,200,15,2,0, 401639,327680,35701,6574, 1010849,692984,45948,14448, 2744540,1270237,124752,67014, 4068549,1966713,184934,95538, 5271875,2607887,239631,121090, 6282159,57,0 ],
  [ 1000,200,25,2,0, 464749,336026,33800,9362, 1761712,1206896,36894,11619, 5562370,2580700,106969,57340, 8899858,4215250,171151,90089, 12072404,6011959,232162,116547, 16939697,89,0 ],
  [ 1000,200,50,2,0, 470050,350065,33575,8570, 2499469,1723042,31506,9787, 10175911,4720028,92508,49599, 17063012,8027921,155118,82137, 24623785,12039514,223853,114402, 34338683,116,0 ],
  [ 1000,200,75,2,0, 470050,350065,33575,8570, 2512388,1725978,31405,9830, 10319710,4754078,92140,49693, 17321839,8188398,154659,81549, 25041948,12216873,223589,114510, 34938575,117,0 ],
  [ 1500,0,1,2,0, 31164,79522,31164,-48358, 51445,57988,51445,-6543, 107392,70174,107392,37219, 150281,61297,150281,88983, 192945,70473,192945,122472, -3314523,-39,25 ],
  [ 1500,0,2,2,0, 62328,83831,31164,-10751, 102890,80862,51445,11014, 214784,85712,107392,64536, 300561,99055,150281,100753, 385890,200065,192945,92912, -2692300,-34,21 ],
  [ 1500,0,3,2,0, 93492,101476,31164,-2661, 154335,89701,51445,21545, 322176,192916,107392,43087, 450842,310803,150281,46679, 578835,359218,192945,73206, -2568578,-33,20 ],
  [ 1500,0,5,2,0, 93492,101476,20398,-1742, 154335,89701,30867,12927, 468704,334746,93741,26792, 458161,327530,91632,26126, 588405,361360,117681,45409, -2567716,-33,20 ],
  [ 1500,0,10,2,0, 238835,121581,29245,14358, 512588,378801,42716,11149, 1169660,555007,97472,51221, 1737029,878311,144752,71560, 2231181,1234338,185932,83070, 888967,19,0 ],
  [ 1500,0,15,2,0, 315511,217233,28045,8736, 835176,561525,37963,12439, 2028781,934117,92217,49757, 3099129,1579540,140870,69072, 4014953,2045233,182498,89533, 4020956,46,0 ],
  [ 1500,0,25,2,0, 370126,224800,26918,10569, 1488438,1146969,31171,7151, 4293333,2069183,82564,42772, 6819474,3332398,131144,67059, 9189741,4610173,176726,88069, 11776527,76,0 ],
  [ 1500,0,50,2,0, 374740,225441,26767,10664, 2139826,1558462,26973,7328, 8079416,3794917,73449,38950, 13194212,6343480,119947,62279, 18742487,9233242,170386,86448, 25746623,105,0 ],
  [ 1500,0,75,2,0, 374740,225441,26767,10664, 2151296,1574998,26891,7204, 8199014,3917691,73205,38226, 13398301,6403790,119628,62451, 19061175,9510038,170189,85278, 25964167,105,0 ],
  [ 1500,25,1,2,0, 37537,80399,37537,-42862, 56691,58598,56691,-1907, 133573,74079,133573,59495, 160640,62878,160640,97762, 206330,72575,206330,133755, -3205272,-38,24 ],
  [ 1500,25,2,2,0, 70760,84990,35380,-7115, 111603,82079,55801,14762, 248318,90690,124159,78814, 321280,182513,160640,69384, 412659,204262,206330,104199, -2649835,-34,21 ],
  [ 1500,25,3,2,0, 103983,102919,34661,355, 166514,91526,55505,24996, 363062,198967,121021,54698, 481920,330500,160640,50473, 618989,365509,206330,84493, -2377953,-31,19 ],
  [ 1500,25,5,2,0, 154698,109929,33752,9768, 264195,197109,52839,13417, 578700,350780,115740,45584, 795907,393376,159181,80506, 1021898,543938,204380,95592, -1548316,-23,11 ],
  [ 1500,25,10,2,0, 258875,137674,31699,14841, 548821,385127,45735,13641, 1268343,689998,105695,48195, 1856553,911965,154713,78716, 2385667,1274746,198806,92577, 1123129,22,0 ],
  [ 1500,25,15,2,0, 340551,220677,30271,10656, 892988,572500,40590,14568, 2185882,1154521,99358,46880, 3312145,1626967,150552,76599, 4292654,2203960,195121,94941, 4440481,48,0 ],
  [ 1500,25,25,2,0, 398696,329596,28996,5025, 1589687,1179979,33292,8580, 4603731,2128284,88533,47605, 7287097,3528726,140136,72276, 9824088,4855775,188925,95544, 12931794,79,0 ],
  [ 1500,25,50,2,0, 403604,330277,28829,5238, 2284043,1689041,28790,7500, 8644718,4102011,78588,41297, 14096481,6742601,128150,66853, 20033620,9920321,182124,91939, 27240177,107,0 ],
  [ 1500,25,75,2,0, 403604,330277,28829,5238, 2296262,1691864,28703,7555, 8772339,4120013,78324,41539, 14314452,6978230,127808,65502, 20374188,10086879,181912,91851, 27619191,107,0 ],
  [ 1500,50,1,2,0, 43910,81275,43910,-37366, 61938,59208,61938,2730, 159755,77984,159755,81771, 171000,64459,171000,106541, 219714,74677,219714,145037, -3096020,-37,24 ],
  [ 1500,50,2,2,0, 79191,86150,39596,-3479, 120315,83297,60157,18509, 281851,110183,140925,85834, 341999,185713,171000,78143, 439429,321599,219714,58915, -2602977,-33,21 ],
  [ 1500,50,3,2,0, 114473,104361,38158,3371, 178692,93351,59564,28447, 403947,205018,134649,66310, 512999,335220,171000,59260, 659143,387470,219714,90558, -2176399,-29,17 ],
  [ 1500,50,5,2,0, 168326,111804,36726,12332, 282531,200061,56506,16494, 633380,373353,126676,52005, 847213,416172,169443,86208, 1088159,570019,217632,103628, -1307272,-20,-50 ],
  [ 1500,50,10,2,0, 278915,140430,34153,16957, 585054,391602,48755,16121, 1367027,718938,113919,54007, 1976076,944969,164673,85926, 2540153,1298833,211679,103443, 1668535,28,0 ],
  [ 1500,50,15,2,0, 365592,224120,32497,12575, 950801,687825,43218,11953, 2342984,1190376,106499,52391, 3525161,1754203,160235,80498, 4570355,2262235,207743,104915, 4907644,51,0 ],
  [ 1500,50,25,2,0, 427266,346862,31074,5848, 1690936,1200454,35412,10272, 4914129,2385100,94502,48635, 7754720,3737431,149129,77256, 10458436,5198045,201124,101161, 13945345,82,0 ],
  [ 1500,50,50,2,0, 432468,347583,30891,6063, 2428261,1733236,30608,8761, 9210019,4409255,83727,43643, 14998750,7205703,136352,70846, 21324754,10508680,193861,98328, 29343115,110,0 ],
  [ 1500,50,75,2,0, 432468,347583,30891,6063, 2441228,1736231,30515,8812, 9345664,4428386,83443,43904, 15230603,7378028,135988,70112, 21687201,10791632,193636,97282, 29786288,110,0 ],
  [ 1500,100,1,2,0, 56655,83028,56655,-26373, 72430,60428,72430,12002, 212117,100309,212117,111809, 191719,67670,191719,124049, 246484,78932,246484,167552, -2911018,-36,23 ],
  [ 1500,100,2,2,0, 96055,101806,48027,-2875, 137740,85582,68870,26079, 348917,197139,174459,75889, 383437,192164,191719,95637, 492967,345813,246484,73577, -2523362,-33,20 ],
  [ 1500,100,3,2,0, 135455,107247,45152,9402, 203049,97000,67683,35350, 485717,337685,161906,49344, 575156,345210,191719,76648, 739451,400602,246484,112950, -2029821,-28,16 ],
  [ 1500,100,5,2,0, 195582,115552,42672,17461, 319203,205964,63841,22648, 742739,403984,148548,67751, 949823,512067,189965,87551, 1220681,590788,244136,125979, -843654,-13,-24 ],
  [ 1500,100,10,2,0, 318995,217642,39061,12411, 657521,418439,54793,19923, 1564393,839153,130366,60437, 2215124,1184719,184594,85867, 2849125,1462030,237427,115591, 1940205,31,0 ],
  [ 1500,100,15,2,0, 415672,331875,36949,7449, 1066426,722160,48474,15648, 2657187,1264935,120781,63284, 3951193,1958019,179600,90599, 5125757,2593778,232989,115090, 5963390,56,0 ],
  [ 1500,100,25,2,0, 484405,354720,35229,9432, 1893434,1343324,39653,11521, 5534925,2594818,106441,56541, 8689965,4220621,167115,85949, 11727130,5881137,225522,112423, 16362559,88,0 ],
  [ 1500,100,50,2,0, 490196,355522,35014,9620, 2716695,2000124,34244,9032, 10340622,4824679,94006,50145, 16803289,8065725,152757,79432, 23907020,11781469,217337,110232, 33580311,115,0 ],
  [ 1500,100,75,2,0, 490196,355522,35014,9620, 2731159,2003463,34139,9096, 10492313,4937581,93681,49596, 17062906,8242157,152347,78757, 24313226,12071076,217082,109305, 33910229,116,0 ],
  [ 1500,200,1,2,0, 82147,86534,82147,-4387, 93415,76754,93415,16661, 316842,130293,316842,186549, 233157,74042,233157,159114, 300022,103012,300022,197010, -2562269,-33,20 ],
  [ 1500,200,2,2,0, 129782,106444,64891,11669, 172589,90452,86295,41069, 483050,352132,241525,65459, 466313,313825,233157,76244, 600045,363199,300022,118423, -2351856,-31,19 ],
  [ 1500,200,3,2,0, 177417,113018,59139,21466, 251763,118185,83921,44526, 649258,390770,216419,86163, 699470,379167,233157,106767, 900067,441485,300022,152861, -1322770,-20,8 ],
  [ 1500,200,5,2,0, 250094,123049,54566,27719, 392547,336006,78509,11308, 961456,449080,192291,102475, 1155044,558170,231009,119375, 1485726,776160,297145,141913, -141157,1,0 ],
  [ 1500,200,10,2,0, 399155,329533,48876,8525, 802454,530577,66871,22656, 1959127,940248,163261,84907, 2693218,1288233,224435,117082, 3467069,1803662,288922,138617, 3334612,42,0 ],
  [ 1500,200,15,2,0, 515833,358986,45852,13942, 1297675,867530,58985,19552, 3285593,1597104,149345,76750, 4803258,2415455,218330,108536, 6236560,3141424,283480,140688, 8195538,65,0 ],
  [ 1500,200,25,2,0, 598685,370437,43541,16600, 2298430,1645680,48135,13670, 6776517,3239519,130318,68019, 10560457,5092767,203086,105148, 14264518,7037410,274318,138983, 20914193,96,0 ],
  [ 1500,200,50,2,0, 605652,371401,43261,16732, 3293563,2355702,41516,11822, 12601827,5946105,114562,60507, 20412366,9814523,185567,96344, 29071553,14411927,264287,133269, 41848449,125,0 ],
  [ 1500,200,75,2,0, 605652,371401,43261,16732, 3311023,2359728,41388,11891, 12785611,5986520,114157,60706, 20727510,9970913,185067,96041, 29565277,14630964,263976,133342, 42649632,126,0 ],
  [ 2000,0,1,2,0, 42448,81298,42448,-38850, 67775,60957,67775,6818, 133831,74390,133831,59441, 185849,67070,185849,118778, 239312,78087,239312,161225, -3110745,-37,24 ],
  [ 2000,0,2,2,0, 84897,100719,42448,-7911, 135551,86651,67775,24450, 267662,108660,133831,79501, 371698,191109,185849,90295, 478623,344256,239312,67184, -2583268,-33,21 ],
  [ 2000,0,3,2,0, 127345,106804,42448,6847, 203326,98609,67775,34906, 401493,205566,133831,65309, 557547,343099,185849,71482, 717935,397732,239312,106734, -1990522,-28,16 ],
  [ 2000,0,5,2,0, 192149,116094,41923,16594, 324770,208795,64954,23195, 654642,378066,130928,55315, 920591,509493,184118,82220, 1185323,587007,237065,119663, -1055568,-17,-38 ],
  [ 2000,0,10,2,0, 325293,220224,39832,12866, 683245,426201,56937,21420, 1472448,738197,122704,61188, 2146806,1098837,178901,87331, 2767832,1370085,230653,116479, 2066532,32,0 ],
  [ 2000,0,15,2,0, 429705,349405,38196,7138, 1122002,825036,51000,13498, 2569833,1245118,116811,60214, 3833838,1949425,174265,85655, 4979638,2579770,226347,109085, 5389648,53,0 ],
  [ 2000,0,25,2,0, 504061,360078,36659,10472, 2025156,1510403,42412,10780, 5507480,2728001,105913,53452, 8480072,4211415,163078,82090, 11381856,5736576,218882,108563, 15500223,86,0 ],
  [ 2000,0,50,2,0, 510342,360980,36453,10669, 2933921,2099007,36982,10524, 10505333,5128395,95503,48881, 16543566,8104080,150396,76723, 23190256,11721344,210821,104263, 32786600,114,0 ],
  [ 2000,0,75,2,0, 510342,360980,36453,10669, 2949931,2102748,36874,10590, 10664916,5166148,95222,49096, 16803972,8281489,150035,76094, 23584504,11813238,210576,105101, 33442639,115,0 ],
  [ 2000,25,1,2,0, 48821,82175,48821,-33353, 73022,75452,73022,-2431, 160012,78145,160012,81867, 196208,68651,196208,127557, 252696,80139,252696,172557, -3045078,-37,24 ],
  [ 2000,25,2,2,0, 93328,101879,46664,-4275, 144263,87868,72132,28198, 301195,113638,150598,93778, 392417,194259,196208,99079, 505393,348452,252696,78470, -2422278,-32,19 ],
  [ 2000,25,3,2,0, 137836,108247,45945,9863, 215505,114319,71835,33729, 442378,332032,147459,36782, 588625,348369,196208,80085, 758089,404523,252696,117855, -2100255,-29,17 ],
  [ 2000,25,5,2,0, 205777,117968,44897,19158, 343106,316096,68621,5402, 709321,384624,141864,64939, 971897,517262,194379,90927, 1251585,612538,250317,127809, -1085541,-17,-36 ],
  [ 2000,25,10,2,0, 345333,222980,42286,14982, 719479,506527,59957,17746, 1571131,844137,130928,60583, 2266330,1197173,188861,89096, 2922318,1493623,243527,119058, 2034330,32,0 ],
  [ 2000,25,15,2,0, 454745,352849,40422,9057, 1179814,836011,53628,15627, 2726935,1282473,123952,65657, 4046854,2076660,183948,89554, 5257339,2638545,238970,119036, 6183433,57,0 ],
  [ 2000,25,25,2,0, 532631,364007,38737,12264, 2126405,1531028,44532,12469, 5817879,2801767,111882,58002, 8947695,4434445,172071,86793, 12016203,6078196,231081,114192, 16968238,89,0 ],
  [ 2000,25,50,2,0, 539206,364950,38515,12447, 3078138,2321552,38800,9537, 11070635,5328089,100642,52205, 17445835,8501200,158599,81315, 24481389,12292883,222558,110805, 34736800,117,0 ],
  [ 2000,25,75,2,0, 539206,364950,38515,12447, 3095343,2325465,38692,9623, 11167141,5363925,99707,51814, 17600826,8506200,157150,81202, 24714391,12297933,220664,110861, 35301531,118,0 ],
  [ 2000,50,1,2,0, 55194,83051,55194,-27857, 78268,76062,78268,2206, 186193,82050,186193,104143, 206568,70232,206568,136336, 266081,82242,266081,183839, -2935826,-36,23 ],
  [ 2000,50,2,2,0, 101760,103039,50880,-639, 152976,89086,76488,31945, 334728,195617,167364,69556, 413136,305869,206568,53633, 532162,352648,266081,89757, -2597860,-33,21 ],
  [ 2000,50,3,2,0, 148326,109689,49442,12879, 227683,116144,75894,37180, 483263,338083,161088,48394, 619704,353090,206568,88871, 798243,410813,266081,129143, -1887527,-27,15 ],
  [ 2000,50,5,2,0, 219405,119842,47870,21723, 361442,332933,72288,5702, 764000,407047,152800,71391, 1023202,540057,204640,96629, 1317846,735989,263569,116371, -957539,-15,-31 ],
  [ 2000,50,10,2,0, 365373,225736,44740,17098, 755712,526888,62976,19069, 1669814,872927,139151,66407, 2385854,1230177,198821,96306, 3076804,1646422,256400,119199, 2444347,35,0 ],
  [ 2000,50,15,2,0, 479786,356293,42648,10977, 1237627,860871,56256,17125, 2884036,1396677,131093,67607, 4259870,2123588,193630,97104, 5535040,2796722,251593,124469, 6804752,60,0 ],
  [ 2000,50,25,2,0, 561201,367936,40815,14056, 2227654,1565538,46652,13866, 6128277,2952384,117851,61075, 9415318,4614347,181064,92326, 12650550,6435438,243280,119521, 18268652,91,0 ],
  [ 2000,50,50,2,0, 568070,382256,40576,13272, 3222355,2365746,40618,10798, 11635936,5620818,105781,54683, 18348105,8992405,166801,85052, 25772522,12994283,234296,116166, 36751056,119,0 ],
  [ 2000,50,75,2,0, 568070,382256,40576,13272, 3239863,2369831,40498,10875, 11811565,5660828,105460,54917, 18636274,9145717,166395,84737, 26210530,13092782,234023,117123, 37545387,120,0 ],
  [ 2000,100,1,2,0, 67940,84804,67940,-16864, 88760,77283,88760,11478, 238556,104375,238556,134181, 227287,73443,227287,153844, 292850,102167,292850,190683, -2761997,-34,22 ],
  [ 2000,100,2,2,0, 118624,105358,59312,6633, 170400,91520,85200,39440, 401795,220088,200897,90854, 454574,312220,227287,71177, 585701,361091,292850,112305, -2309381,-31,18 ],
  [ 2000,100,3,2,0, 169308,112575,56436,18911, 252040,119793,84013,44082, 565034,364700,188345,66778, 681861,377556,227287,101435, 878551,439115,292850,146479, -1528848,-23,11 ],
  [ 2000,100,5,2,0, 246661,123591,53817,26852, 398114,338836,79623,11856, 873359,423162,174672,90039, 1125812,555596,225162,114043, 1450368,772379,290074,135598, -353070,-4,0 ],
  [ 2000,100,10,2,0, 405453,332115,49647,8980, 828178,538340,69015,24153, 1867181,916292,155598,79241, 2624901,1282659,218742,111853, 3385776,1711717,282148,139505, 3165916,41,0 ],
  [ 2000,100,15,2,0, 529866,363180,47099,14817, 1353252,896705,61511,20752, 3198239,1577286,145375,73680, 4685902,2406711,212996,103600, 6090442,3127265,276838,134690, 7910270,64,0 ],
  [ 2000,100,25,2,0, 618341,389131,44970,16670, 2430152,1708258,50893,15118, 6749073,3268152,129790,66941, 10350563,5098187,199049,101007, 13919244,7006089,267678,132945, 20466427,96,0 ],
  [ 2000,100,50,2,0, 625798,390195,44700,16829, 3510789,2528285,44254,12385, 12766539,6129256,116059,60339, 20152643,9852477,183206,93638, 28354789,14267622,257771,128065, 41104875,124,0 ],
  [ 2000,100,75,2,0, 625798,390195,44700,16829, 3529794,2532713,44122,12464, 12958214,6171523,115698,60595, 20468577,10009846,182755,93382, 28836555,14456507,257469,128393, 41936214,125,0 ],
  [ 2000,200,1,2,0, 93431,101646,93431,-8216, 109745,79573,109745,30172, 343281,134510,343281,208771, 268725,79965,268725,188759, 346389,194557,346389,151832, -2472918,-32,20 ],
  [ 2000,200,2,2,0, 152351,109996,76175,21177, 205250,96390,102625,54430, 535928,360565,267964,87681, 537450,340348,268725,98551, 692778,394098,346389,149340, -1977577,-28,16 ],
  [ 2000,200,3,2,0, 211270,118346,70423,30975, 300755,200943,100252,33271, 728575,403420,242858,108385, 806175,396487,268725,136563, 1039167,563779,346389,158463, -1016125,-16,-40 ],
  [ 2000,200,5,2,0, 301173,216124,65710,18556, 471459,350493,94292,24193, 1092077,561424,218415,106131, 1331033,710358,266207,124135, 1715413,913520,343083,160379, 32954,5,0 ],
  [ 2000,200,10,2,0, 485613,356476,59463,15813, 973111,682328,81093,24232, 2261915,1184422,188493,89791, 3102995,1589017,258583,126165, 4003721,2051949,333643,162648, 4137385,47,0 ],
  [ 2000,200,15,2,0, 630027,390292,56002,21310, 1584501,1132540,72023,20544, 3826645,1908105,173938,87206, 5537967,2798816,251726,124507, 7201245,3575010,327329,164829, 10026709,71,0 ],
  [ 2000,200,25,2,0, 732620,404848,53281,23838, 2835149,1996729,59375,17559, 7990665,3913002,153667,78417, 12221055,5970334,235020,120206, 16456633,8275502,316474,157329, 24981013,103,0 ],
  [ 2000,200,50,2,0, 741254,406074,52947,23941, 4087658,2883862,51525,15174, 15027744,7173683,136616,71401, 23761721,11573122,216016,110805, 33519322,16814299,304721,151864, 49650795,132,0 ],
  [ 2000,200,75,2,0, 741254,406074,52947,23941, 4109658,3007213,51371,13781, 15251513,7295813,136174,71033, 24133181,11738602,215475,110666, 34088606,17031516,304363,152295, 50117742,133,0 ],
  [ 2500,0,1,2,0, 118835,109944,118835,8891, -29228,83356,-29228,-112584, 6094651,680304,6094651,5414347, 9401140,9925896,9401140,-524756, 13180349,14138566,13180349,-958217, 7301628,61,0 ],
  [ 2500,0,2,2,0, 107465,104271,53733,1597, 168212,92589,84106,37811, 320540,194094,160270,63223, 442834,311115,221417,65860, 571357,359484,285678,105936, -2512284,-32,20 ],
  [ 2500,0,3,2,0, 161198,112132,53733,16355, 252317,195252,84106,19022, 480810,338630,160270,47393, 664252,375945,221417,96102, 857035,436746,285678,140096, -1968708,-27,16 ],
  [ 2500,0,5,2,0, 243227,137469,53068,23074, 403682,341517,80736,12433, 785262,397245,157052,77603, 1096581,553171,219316,108682, 1415010,768748,283002,129252, -619677,-10,0 ],
  [ 2500,0,10,2,0, 411751,348033,50418,7802, 853903,559837,71159,24506, 1775236,892187,147936,73587, 2556584,1277235,213049,106612, 3304484,1703652,275374,133403, 2839209,38,0 ],
  [ 2500,0,15,2,0, 543899,367374,48347,15691, 1408828,911996,64038,22583, 3110885,1572134,141404,69943, 4568547,2209249,207661,107241, 5944323,3097536,270197,129399, 7924857,64,0 ],
  [ 2500,0,25,2,0, 637997,394489,46400,17710, 2561874,1861452,53652,14669, 6721628,3295434,129262,65888, 10140670,5008174,195013,98702, 13573970,6974168,261038,126919, 19930945,95,0 ],
  [ 2500,0,50,2,0, 645944,395653,46139,17878, 3728015,2731518,46992,12561, 12931250,6354472,117557,59789, 19892921,9890832,180845,90928, 27638024,14010126,251255,123890, 40301407,123,0 ],
  [ 2500,0,75,2,0, 645944,395653,46139,17878, 3748566,2736349,46857,12653, 13130817,6475591,117239,59422, 20209643,10049178,180443,90718, 28107834,14311260,250963,123184, 40884874,124,0 ],
  [ 2500,25,1,2,0, 60106,83951,60106,-23845, 89352,78422,89352,10930, 186451,82362,186451,104089, 231777,74424,231777,157352, 299063,103425,299063,195638, -2852473,-35,22 ],
  [ 2500,25,2,2,0, 115897,105431,57949,5233, 176924,93807,88462,41559, 354073,199072,177036,77501, 463553,329242,231777,67156, 598126,363681,299063,117223, -2373398,-31,19 ],
  [ 2500,25,3,2,0, 171689,113575,57230,19371, 264496,197077,88165,22473, 521695,344682,173898,59004, 695330,380665,231777,104888, 897189,443036,299063,151384, -1755980,-25,14 ],
  [ 2500,25,5,2,0, 256855,139343,56041,25639, 422018,344469,84404,15510, 839942,419818,167988,84025, 1147886,560990,229577,117379, 1481272,779158,296254,140423, -345356,-4,0 ],
  [ 2500,25,10,2,0, 431791,350789,52872,9919, 890136,566313,74178,26985, 1873919,921127,156160,79399, 2676107,1310239,223009,113822, 3458970,1827190,288247,135982, 3313707,41,0 ],
  [ 2500,25,15,2,0, 568939,384155,50572,16425, 1466641,936706,66665,24088, 3267987,1609339,148545,75393, 4781563,2445095,217344,106203, 6222024,3172083,282819,138634, 8449118,66,0 ],
  [ 2500,25,25,2,0, 666567,398418,48478,19502, 2663123,1895962,55772,16066, 7032026,3537586,135231,67201, 10608293,5311962,204006,101853, 14208317,7316189,273237,132541, 20848477,96,0 ],
  [ 2500,25,50,2,0, 674808,399622,48201,19656, 3872233,2849712,48810,12889, 13496552,6555667,122696,63099, 20795190,10368260,189047,94790, 28929158,14695255,262992,129399, 42379072,125,0 ],
  [ 2500,25,75,2,0, 674808,399622,48201,19656, 3893532,2868600,48669,12812, 13704142,6783813,122358,61789, 21125794,10448827,188623,95330, 29420846,14902722,262686,129626, 42909932,126,0 ],
  [ 2500,50,1,2,0, 66478,84827,66478,-18349, 94598,79032,94598,15567, 212632,86267,212632,126366, 242136,76005,242136,166131, 312448,189307,312448,123140, -2802956,-35,22 ],
  [ 2500,50,2,2,0, 124329,106591,62164,8869, 185636,95024,92818,45306, 387606,204050,193803,91778, 484272,332392,242136,75940, 624895,367877,312448,128509, -2212409,-30,18 ],
  [ 2500,50,3,2,0, 182179,115017,60726,22387, 276675,198902,92225,25924, 562580,350733,187527,70616, 726409,385386,242136,113674, 937343,533107,312448,134745, -1602986,-24,12 ],
  [ 2500,50,5,2,0, 270483,141218,59014,28203, 440354,347420,88071,18587, 894621,427875,178924,93349, 1199191,583735,239838,123091, 1547533,789518,309507,151603, -59638,3,0 ],
  [ 2500,50,10,2,0, 451831,353545,55326,12035, 926369,572789,77197,29465, 1972602,935552,164384,86421, 2795631,1408524,232969,115592, 3613456,1866898,301121,145546, 3785019,44,0 ],
  [ 2500,50,15,2,0, 593979,387599,52798,18345, 1524453,1125881,69293,18117, 3425089,1722193,155686,77404, 4994579,2506999,227026,113072, 6499725,3343449,295442,143467, 8521791,66,0 ],
  [ 2500,50,25,2,0, 695137,402347,50555,21294, 2764372,2004322,57893,15917, 7342424,3611202,141200,71754, 11075916,5491864,212998,107386, 14842665,7544669,285436,140346, 22183413,99,0 ],
  [ 2500,50,50,2,0, 702742,403475,50196,21376, 4055174,2892451,51116,14656, 11009486,6580982,100086,40259, 16821685,5556881,152924,102407, 23105319,7638975,210048,140603, 39249217,122,0 ],
  [ 2500,50,75,2,0, 703672,403592,50262,21434, 4038498,2913116,50481,14067, 14277467,6909136,127477,65789, 22041945,10913306,196803,99363, 30733859,15591154,274409,135203, 45468350,128,0 ],
  [ 2500,100,1,2,0, 79224,86580,79224,-7356, 105091,80252,105091,24839, 264995,108592,264995,156403, 262855,79216,262855,183639, 339217,193562,339217,145655, -2617953,-33,21 ],
  [ 2500,100,2,2,0, 141192,108910,70596,16141, 203061,97459,101531,52801, 454673,334571,227336,60051, 525710,339243,262855,93234, 678434,392491,339217,142972, -2180480,-30,17 ],
  [ 2500,100,3,2,0, 203161,117903,67720,28419, 301032,202552,100344,32827, 644350,377350,214783,89000, 788566,394876,262855,131230, 1017651,561410,339217,152081, -1222204,-19,-52 ],
  [ 2500,100,5,2,0, 297739,216666,64961,17689, 477026,353324,95405,24740, 1003980,535506,200796,93695, 1301802,707784,260360,118804, 1680055,909739,336011,154063, -178960,0,0 ],
  [ 2500,100,10,2,0, 491911,359057,60234,16268, 998836,703975,83236,24572, 2169969,1174832,180831,82928, 3034678,1583493,252890,120932, 3922428,2043784,326869,156554, 3831871,45,0 ],
  [ 2500,100,15,2,0, 644060,394486,57250,22184, 1640078,1161716,74549,21744, 3739292,1902803,169968,83477, 5420612,2681662,246391,124498, 7055127,3560901,320688,158828, 9868015,70,0 ],
  [ 2500,100,25,2,0, 752276,423542,54711,23908, 2966870,2059307,62133,19007, 7963220,3927120,153139,77617, 12011161,5895396,230984,117611, 16111359,8244132,309834,151293, 24685272,103,0 ],
  [ 2500,100,50,2,0, 761400,424868,54386,24038, 4304884,3160795,54263,14421, 15192456,7355334,138113,71247, 23501998,11611077,213655,108099, 32802557,16556403,298205,147692, 48661232,132,0 ],
  [ 2500,100,75,2,0, 761400,424868,54386,24038, 4328429,3180199,54105,14353, 15424116,7524380,137715,70533, 23874248,11778085,213163,108001, 33359885,16871248,297856,147220, 49292220,132,0 ],
  [ 2500,200,1,2,0, 104715,103422,104715,1293, 126076,82542,126076,43533, 369720,215727,369720,153993, 304293,100715,304293,203578, 392756,202171,392756,190585, -2467742,-32,20 ],
  [ 2500,200,2,2,0, 174919,113548,87460,30686, 237911,116214,118955,60848, 588805,368848,294403,109979, 608586,351895,304293,128346, 785512,409277,392756,188117, -1613605,-24,12 ],
  [ 2500,200,3,2,0, 245123,123674,81708,40483, 349746,314201,116582,11848, 807891,429085,269297,126269, 912880,428833,304293,161349, 1178267,586172,392756,197365, -787017,-13,-20 ],
  [ 2500,200,5,2,0, 352251,224163,76855,27946, 550370,379016,110074,34271, 1222697,596617,244539,125216, 1507023,753937,301405,150617, 1945100,982570,389020,192506, 897291,19,0 ],
  [ 2500,200,10,2,0, 572071,370081,70050,24733, 1143769,729879,95314,34491, 2564703,1261562,213725,108595, 3512773,1765765,292731,145584, 4540372,2286496,378364,187823, 5901750,55,0 ],
  [ 2500,200,15,2,0, 744221,408261,66153,29863, 1871328,1233386,85060,28997, 4367698,2114406,198532,102422, 6272676,3058091,285122,146117, 8165930,4220937,371179,179318, 12705407,78,0 ],
  [ 2500,200,25,2,0, 866556,439259,63022,31076, 3371867,2361663,70615,21156, 9204813,4465770,177016,91135, 13881653,6847350,266955,135275, 18648747,9512995,358630,175688, 29283128,109,0 ],
  [ 2500,200,50,2,0, 876749,439259,62625,31249, 4633191,3424084,58402,15241, 17308586,4515770,157351,116298, 15109956,6847350,137363,75115, 20455803,9512995,185962,99480, 51460290,134,0 ],
  [ 2500,200,75,2,0, 876855,440747,62633,31151, 4908293,3536464,61354,17148, 17717415,8529755,158191,82033, 27538852,13549420,245883,124906, 38611936,19446257,344749,171122, 58059177,140,0 ],
  [ 3000,0,1,2,0, 65017,84850,65017,-19833, 100436,80781,100436,19655, 186709,82673,186709,104035, 256986,78617,256986,178369, 332045,192717,332045,139328, -2817681,-35,22 ],
  [ 3000,0,2,2,0, 130034,107823,65017,11106, 200872,98528,100436,51172, 373418,202527,186709,85445, 513971,337638,256986,88167, 664090,390384,332045,136853, -2138006,-29,17 ],
  [ 3000,0,3,2,0, 195051,117460,65017,25864, 301309,204160,100436,32383, 560126,351280,186709,69615, 770957,393265,256986,125897, 996135,559040,332045,145698, -1428282,-22,10 ],
  [ 3000,0,5,2,0, 294305,217208,64212,16821, 482593,370039,96519,22511, 915883,509439,183177,81289, 1272570,705209,254514,113472, 1644697,905908,328939,147758, -434457,-6,0 ],
  [ 3000,0,10,2,0, 498209,361638,61005,16723, 1024560,711737,85380,26069, 2078024,1073876,173169,83679, 2966361,1577919,247197,115704, 3841135,2035619,320095,150460, 3779939,44,0 ],
  [ 3000,0,15,2,0, 658093,398680,58497,23059, 1695655,1190892,77075,22944, 3651938,1776935,165997,85227, 5303256,2672418,241057,119584, 6909008,3546243,314046,152853, 9828124,70,0 ],
  [ 3000,0,25,2,0, 771933,428900,56141,24948, 3098592,2300236,64892,16719, 7935776,3954253,152611,76568, 11801268,5885690,226947,113761, 15766085,8128381,303194,146879, 23813636,101,0 ],
  [ 3000,0,50,2,0, 781545,430326,55825,25087, 4522110,3273563,57001,15738, 15357167,7658900,139611,69984, 23242275,11664407,211293,105253, 32085793,16396777,291689,142627, 47872741,131,0 ],
  [ 3000,0,75,2,0, 781545,430326,55825,25087, 4547201,3279484,56840,15846, 15596719,7709383,139256,70423, 23615314,11831844,210851,105210, 32631163,16612861,291350,143021, 48904907,132,0 ],
  [ 3000,25,1,2,0, 71390,85727,71390,-14337, 105682,81391,105682,24291, 212890,86578,212890,126312, 267345,95174,267345,172171, 345430,194819,345430,150610, -2730533,-34,22 ],
  [ 3000,25,2,2,0, 138466,108983,69233,14742, 209585,99745,104792,54920, 406951,313555,203475,46698, 534690,341288,267345,96701, 690860,395080,345430,147890, -2222394,-30,18 ],
  [ 3000,25,3,2,0, 205542,118902,68514,28880, 313487,205985,104496,35834, 601011,371846,200337,76388, 802035,413012,267345,129675, 1036289,565381,345430,156970, -1271158,-20,-55 ],
  [ 3000,25,5,2,0, 307934,219082,67185,19386, 500929,372991,100186,25588, 970562,532011,194112,87710, 1323875,713028,264775,122169, 1710958,916318,342192,158928, -160136,1,0 ],
  [ 3000,25,10,2,0, 518249,364394,63459,18839, 1060794,718213,88399,28548, 2176707,1179816,181392,83074, 3085884,1595947,257157,124161, 3995621,2075377,332968,160020, 4159777,47,0 ],
  [ 3000,25,15,2,0, 683133,402124,60723,24979, 1753467,1201867,79703,25073, 3809039,1920340,173138,85850, 5516272,2828306,250740,122180, 7186709,3818060,326669,153120, 10183441,71,0 ],
  [ 3000,25,25,2,0, 800502,432829,58218,26740, 3199841,2334746,67012,18117, 8246174,4104869,158580,79640, 12268891,6189029,235940,116920, 16400432,8356861,315393,154684, 25023677,103,0 ],
  [ 3000,25,50,2,0, 810409,434295,57886,26865, 4666327,3496107,58819,14751, 15922469,7767079,144750,74140, 24144544,12046502,219496,109982, 33376926,16983937,303427,149027, 50043870,133,0 ],
  [ 3000,25,75,2,0, 810409,434295,57886,26865, 4666327,3496362,58329,14625, 15925461,7782079,142192,72709, 24144544,12046552,215576,108018, 33376926,16983987,298008,146366, 50015312,133,0 ],
  [ 3000,50,1,2,0, 77763,86603,77763,-8840, 110929,82001,110929,28928, 239071,104998,239071,134073, 277705,96805,277705,180900, 358814,196972,358814,161843, -2654782,-34,21 ],
  [ 3000,50,2,2,0, 146898,110142,73449,18378, 218297,114848,109149,51725, 440484,332898,220242,53793, 555409,344489,277705,105460, 717629,399277,358814,159176, -2138488,-29,17 ],
  [ 3000,50,3,2,0, 216033,120345,72011,31896, 325666,207810,108555,39285, 641897,377898,213966,88000, 833114,417732,277705,138461, 1076443,571671,358814,168257, -1058430,-17,-42 ],
  [ 3000,50,5,2,0, 321562,220956,70159,21950, 519265,375943,103853,28664, 1025242,540069,205048,97034, 1375180,735774,275036,127881, 1777220,942349,355444,166974, 114409,7,0 ],
  [ 3000,50,10,2,0, 538289,367151,65913,20956, 1097027,724689,91419,31028, 2275390,1194241,189616,90096, 3205408,1628901,267117,131376, 4150107,2198865,345842,162604, 4667776,49,0 ],
  [ 3000,50,15,2,0, 538289,368218,47848,15117, 1097027,731145,49865,16631, 2293759,1211742,104262,49183, 3205408,1629003,145700,71655, 4150107,2198954,188641,88689, 4644546,49,0 ],
  [ 3000,50,25,2,0, 829072,436758,60296,28532, 3301090,2369256,69133,19515, 8556572,4163971,164549,84473, 12736514,6368881,244933,122454, 17034779,8698382,327592,160315, 26545263,106,0 ],
  [ 3000,50,50,2,0, 839273,438265,59948,28643, 4810544,3540452,60637,16010, 16487770,8074324,149889,76486, 25046814,12509453,227698,113976, 34668059,17669566,315164,154532, 52132041,135,0 ],
  [ 3000,50,75,2,0, 839273,438265,59948,28643, 4837133,3546717,60464,16130, 16743368,8218578,149494,76114, 25447616,12615665,227211,114571, 35257188,17907976,314796,154904, 53136199,136,0 ],
  [ 3000,100,1,2,0, 90508,101693,90508,-11184, 121421,83071,121421,38350, 291434,112808,291434,178625, 298424,100116,298424,198308, 385584,201326,385584,184258, -2490972,-32,20 ],
  [ 3000,100,2,2,0, 163761,112462,81881,25650, 235722,117283,117861,59219, 507550,342854,253775,82348, 596847,350789,298424,123029, 771168,407669,385584,181749, -1816509,-26,14 ],
  [ 3000,100,3,2,0, 237014,123231,79005,37928, 350023,315810,116674,11404, 723667,403015,241222,106884, 895271,427222,298424,156016, 1156751,583802,385584,190983, -993096,-16,-34 ],
  [ 3000,100,5,2,0, 348818,224705,76106,27079, 555937,381846,111187,34818, 1134600,570700,226920,112780, 1477791,751362,295558,145286, 1909742,963119,381948,189325, 696551,17,0 ],
  [ 3000,100,10,2,0, 578369,385999,70821,23556, 1169493,825226,97458,28689, 2472757,1251971,206063,101732, 3444455,1760391,287038,140339, 4459080,2262810,371590,183022, 5318936,53,0 ],
  [ 3000,100,15,2,0, 758254,425792,67400,29552, 1926904,1248526,87587,30835, 4280344,2109254,194561,98686, 6155321,3049547,279787,141172, 8019812,4093938,364537,178449, 12456161,78,0 ],
  [ 3000,100,25,2,0, 758254,425792,55146,24179, 1926904,1248526,40354,14207, 7818046,3921032,150347,74943, 6363828,3300544,122381,58909, 8304835,4383296,159708,75414, 16338104,87,0 ],
  [ 3000,100,50,2,0, 897001,531241,64072,26126, 5098978,3702840,64273,17598, 17618373,8582762,160167,82142, 26851352,13369676,244103,122561, 37250326,19026835,338639,165668, 56077450,138,0 ],
  [ 3000,100,75,2,0, 897001,531241,64072,26126, 5127064,3813799,64088,16416, 17890018,8833823,159732,80859, 27279919,13588903,243571,122241, 37883214,19271850,338243,166173, 56446276,138,0 ],
  [ 3000,200,1,2,0, 116000,105198,116000,10801, 142406,85512,142406,56894, 396159,219943,396159,176215, 339862,186796,339862,153066, 439123,322876,439123,116247, -2463121,-32,20 ],
  [ 3000,200,2,2,0, 197488,117100,98744,40194, 270571,196003,135286,37284, 641683,391796,320842,124943, 679723,378467,339862,150628, 878245,440226,439123,219009, -1506608,-23,11 ],
  [ 3000,200,3,2,0, 278976,142338,92992,45546, 398737,336844,132912,20631, 887208,441585,295736,148541, 1019585,541587,339862,159333, 1317368,737876,439123,193164, -506394,-8,0 ],
  [ 3000,200,5,2,0, 403330,333068,87999,15330, 629281,392153,125856,47426, 1353318,738011,270664,123061, 1683012,879822,336602,160638, 2174787,1234871,434957,187983, 898443,19,0 ],
  [ 3000,200,10,2,0, 658530,397024,80636,32021, 1314426,865014,109536,37451, 2867491,1415552,238958,120995, 3922550,1985792,326879,161397, 5077024,2618564,423085,204872, 7024448,60,0 ],
  [ 3000,200,15,2,0, 858415,439567,76304,37231, 2158154,1498396,98098,29989, 4908750,2439923,223125,112219, 7007385,3426526,318517,162766, 9130615,4754024,415028,198936, 14454273,83,0 ],
  [ 3000,200,25,2,0, 1000491,545370,72763,33100, 3908585,2800447,81855,23207, 10418960,5139104,200365,101536, 15542251,7753069,298889,149792, 20840862,10538496,400786,198122, 32888589,114,0 ],
  [ 3000,200,50,2,0, 1012457,547119,72318,33238, 5675847,4162768,71544,19072, 19879578,9702689,180723,92517, 30460430,15010014,276913,140458, 42414859,21573012,385590,189471, 64238774,144,0 ],
  [ 3000,200,75,2,0, 1012457,547119,72318,33238, 5706928,4170064,71337,19211, 20183316,9762198,180208,93046, 30944523,15317109,276290,139530, 43135265,21831188,385136,190215, 65464557,145,0 ],
  [ 1000,0,1,3,0, 28627,78861,28627,-50234, 47228,56904,47228,-9676, 99230,68549,99230,30680, 133336,58298,133336,75038, 163317,65448,163317,97869, -3385226,-39,25 ],
  [ 1000,0,2,3,0, 57253,82507,28627,-12627, 94455,78694,47228,7881, 198459,82464,99230,57998, 266672,78080,133336,94296, 326634,190016,163317,68309, -2811602,-35,22 ],
  [ 1000,0,3,3,0, 85880,86154,28627,-91, 141683,86599,47228,18361, 297689,111043,99230,62215, 400008,193196,133336,68937, 489951,343994,163317,48653, -2389420,-31,19 ],
  [ 1000,0,5,3,0, 129588,105061,28274,5351, 224685,114858,44937,21965, 483175,334595,96635,29716, 662171,355968,132434,61241, 810084,409399,162017,80137, -1875014,-26,15 ],
  [ 1000,0,10,3,0, 219422,116523,26868,12600, 461247,351665,38437,9132, 1071395,535603,89283,44649, 1554164,750013,129514,67013, 1899485,949533,158290,79163, 458900,13,0 ],
  [ 1000,0,15,3,0, 289897,138861,25769,13425, 741461,523409,33703,9911, 1848322,883838,84015,43840, 2780340,1287673,126379,67848, 3431277,1701233,155967,78638, 3552551,43,0 ],
  [ 1000,0,25,3,0, 340114,145287,24736,14169, 1293025,890368,27079,8433, 3867041,1744942,74366,40810, 6117402,2857905,117642,62683, 7928853,3872215,152478,78012, 11232531,75,0 ],
  [ 1000,0,50,3,0, 344360,145832,24597,14181, 1834469,1259417,23124,7249, 7184262,3256449,65311,35707, 11779421,5470666,107086,57352, 16321545,7881911,148378,76724, 23550736,101,0 ],
  [ 1000,0,75,3,0, 344360,145832,24597,14181, 1843997,1275449,23050,7107, 7287888,3269968,65070,35874, 11959322,5510917,106780,57575, 16602739,8067651,148239,76206, 24010842,102,0 ],
  [ 1000,25,1,3,0, 36768,79980,36768,-43212, 54023,57705,54023,-3682, 129088,73001,129088,56088, 145377,60137,145377,85240, 178230,67792,178230,110438, -3255232,-38,25 ],
  [ 1000,25,2,3,0, 68360,84035,34180,-7838, 105909,80292,52954,12808, 237330,88231,118665,74550, 290755,96722,145377,97017, 356461,194695,178230,80883, -2639329,-33,21 ],
  [ 1000,25,3,3,0, 99951,101426,33317,-492, 157795,88845,52598,22984, 345571,195126,115190,50149, 436132,307297,145377,42945, 534691,351157,178230,61178, -2521926,-33,20 ],
  [ 1000,25,5,3,0, 148177,107618,32330,8849, 249091,118745,49818,26069, 548057,344150,109611,40781, 721919,379998,144384,68384, 884025,436637,176805,89478, -1531553,-23,11 ],
  [ 1000,25,10,3,0, 247245,120349,30275,15538, 509852,374148,42488,11309, 1190973,552922,99248,53171, 1694131,866359,141178,68981, 2072661,992107,172722,90046, 1002248,21,0 ],
  [ 1000,25,15,3,0, 324919,143678,28882,16110, 819370,538077,37244,12786, 2041038,926356,92774,50667, 3030851,1435510,137766,72516, 3743670,1849177,170167,86113, 4489422,48,0 ],
  [ 1000,25,25,3,0, 380219,222503,27652,11470, 1430348,932096,29955,10435, 4254326,2012662,81814,43109, 6672038,3174685,128308,67257, 8647608,4242949,166300,84705, 12254649,78,0 ],
  [ 1000,25,50,3,0, 384888,223106,27492,11556, 2030841,1506743,25599,6606, 7900304,3583531,71821,39243, 12859486,5989821,116904,62452, 17795769,8626278,161780,83359, 25476750,104,0 ],
  [ 1000,25,75,3,0, 384888,223106,27492,11556, 2041398,1509126,25517,6653, 8014382,3613185,71557,39296, 13056295,6141051,116574,61743, 18102272,8770957,161627,83315, 25878670,105,0 ],
  [ 1000,50,1,3,0, 44910,81100,44910,-36190, 60817,58505,60817,2312, 158947,77452,158947,81495, 157419,61976,157419,95443, 193144,70137,193144,123007, -3125239,-37,24 ],
  [ 1000,50,2,3,0, 79466,85562,39733,-3048, 117363,81891,58681,17736, 276200,108512,138100,83844, 314838,180744,157419,67047, 386287,199424,193144,93432, -2596978,-33,21 ],
  [ 1000,50,3,3,0, 114022,103361,38007,3554, 173908,91241,57969,27556, 393453,202208,131151,63748, 472257,312788,157419,53156, 579431,358171,193144,73753, -2263167,-30,18 ],
  [ 1000,50,5,3,0, 166766,110174,36385,12347, 273497,196481,54699,15403, 612940,368221,122588,48944, 781666,389102,156333,78513, 957966,532034,191593,85186, -1481831,-22,10 ],
  [ 1000,50,10,3,0, 275067,137512,33682,16843, 558457,382595,46538,14655, 1310551,690957,109213,51633, 1834097,888120,152841,78831, 2245837,1232302,187153,84461, 1255869,24,0 ],
  [ 1000,50,15,3,0, 359942,220195,31995,12422, 897280,566629,40785,15030, 2233754,1074774,101534,52681, 3281361,1597022,149153,76561, 4056063,2042551,184366,91523, 4791830,50,0 ],
  [ 1000,50,25,3,0, 420325,328885,30569,6650, 1567672,1150523,32831,8736, 4641611,2110347,89262,48678, 7226674,3384055,138975,73897, 9366363,4698013,180122,89776, 13085390,80,0 ],
  [ 1000,50,50,3,0, 425417,329547,30387,6848, 2227213,1560483,28074,8404, 8616345,3926778,78330,42632, 13939551,6523702,126723,67417, 19269993,9440855,175182,89356, 27785553,108,0 ],
  [ 1000,50,75,3,0, 425417,329547,30387,6848, 2238799,1563102,27985,8446, 8740877,3957901,78044,42705, 14153269,6649249,126368,67000, 19601805,9521244,175016,90005, 28337318,108,0 ],
  [ 1000,100,1,3,0, 61193,83339,61193,-22147, 74407,60107,74407,14300, 218664,100869,218664,117794, 181502,65704,181502,115797, 222970,74875,222970,148094, -2898753,-36,23 ],
  [ 1000,100,2,3,0, 101679,101954,50839,-138, 140270,84938,70135,27666, 353941,197046,176970,78447, 363003,188225,181502,87389, 445940,322022,222970,61959, -2520048,-33,20 ],
  [ 1000,100,3,3,0, 142165,107232,47388,11644, 206133,96033,68711,36700, 489218,336938,163073,50760, 544505,339297,181502,68403, 668910,372748,222970,98721, -2046631,-28,16 ],
  [ 1000,100,5,3,0, 203944,115287,44497,19343, 322309,204253,64462,23611, 742706,401847,148541,68172, 901162,422237,180232,95785, 1105849,570888,221170,106992, -795133,-13,-22 ],
  [ 1000,100,10,3,0, 330712,216865,40495,13940, 655666,413674,54639,20166, 1549706,754775,129142,66244, 2114030,960296,176169,96145, 2592189,1301930,216016,107522, 2273385,34,0 ],
  [ 1000,100,15,3,0, 429987,330695,38221,8826, 1053099,712700,47868,15473, 2619186,1235160,119054,62910, 3782383,1783934,171926,90839, 4680848,2271279,212766,109526, 5916486,56,0 ],
  [ 1000,100,25,3,0, 500535,353253,36403,10711, 1842319,1233829,38583,12743, 5416182,2462736,104157,56797, 8335946,4002289,160307,83340, 10803874,5310368,207767,105644, 16198685,87,0 ],
  [ 1000,100,50,3,0, 506475,354031,36177,10889, 2619957,1862899,33025,9543, 10048428,4491057,91349,50522, 16099681,7546735,146361,77754, 22218442,10801078,201986,103794, 33100629,115,0 ],
  [ 1000,100,75,3,0, 506475,354031,36177,10889, 2633602,1865991,32920,9595, 10193865,4602119,91017,49926, 16347215,7596914,145957,78128, 22600871,10972887,201793,103821, 33654154,115,0 ],
  [ 1000,200,1,3,0, 93759,87818,93759,5941, 101586,77196,101586,24391, 338098,133039,338098,205059, 229667,73111,229667,156556, 282623,84253,282623,198370, -2455864,-32,20 ],
  [ 1000,200,2,3,0, 146104,108064,73052,19020, 186084,91332,93042,47376, 509422,355194,254711,77114, 459335,311945,229667,73695, 565245,357009,282623,104118, -2226170,-30,18 ],
  [ 1000,200,3,3,0, 198449,114973,66150,27826, 270582,193352,90194,25743, 680746,394148,226915,95533, 689002,376338,229667,104221, 847868,416523,282623,143782, -1389237,-21,9 ],
  [ 1000,200,5,3,0, 278300,125514,60720,33335, 419933,338032,83987,16380, 1002237,529935,200447,94460, 1140153,553787,228031,117273, 1401614,745417,280323,131239, -100818,2,0 ],
  [ 1000,200,10,3,0, 442001,333037,54123,13342, 850085,534298,70840,26316, 2028018,945048,169001,90247, 2673897,1279889,222825,116167, 3284893,1670899,273741,134500, 3730310,44,0 ],
  [ 1000,200,15,3,0, 570078,363298,50674,18380, 1364736,872841,62033,22359, 3390051,1602498,154093,81252, 4784425,2402331,217474,108277, 5930420,2969286,269565,134597, 8718744,67,0 ],
  [ 1000,200,25,3,0, 660956,388652,48070,19804, 2391613,1652640,50086,15476, 6965323,3243015,133949,71583, 10554491,5051640,202971,105824, 13678894,6776281,263056,132743, 21612790,98,0 ],
  [ 1000,200,50,3,0, 668590,389663,47756,19923, 3405444,2363531,42926,13133, 12912594,5924730,117387,63526, 20419941,9606878,185636,98301, 28115339,13803575,255594,130107, 43191795,126,0 ],
  [ 1000,200,75,3,0, 668590,389663,47756,19923, 3423208,2367568,42790,13195, 13099842,5964670,116963,63707, 20735108,9790372,185135,97721, 28599004,13906883,255348,131180, 44038310,127,0 ],
  [ 1500,0,1,3,0, 49783,354959,49783,-305176, 1918409,243157,1918409,1675253, 4524697,4806264,4524697,-281568, 7154053,7762810,7154053,-608757, 10274026,10819817,10274026,-545791, -1225417,-21,7 ],
  [ 1500,0,2,3,0, 89752,100959,44876,-5603, 138348,86343,69174,26002, 263275,92804,131638,85235, 349358,186953,174679,81203, 429958,320240,214979,54859, -2572523,-33,20 ],
  [ 1500,0,3,3,0, 134628,107163,44876,9155, 207522,98147,69174,36458, 394913,203554,131638,63786, 524037,336941,174679,62366, 644936,369619,214979,91773, -2030241,-28,16 ],
  [ 1500,0,5,3,0, 203141,116637,44322,18874, 330845,207995,66169,24570, 643689,374713,128738,53795, 867139,419264,173428,89575, 1066410,566689,213282,99944, -1024416,-16,-39 ],
  [ 1500,0,10,3,0, 343922,221152,42113,15033, 691623,424079,57635,22295, 1446263,730143,120522,59677, 2034202,953836,169517,90030, 2501184,1292829,208432,100696, 2043546,32,0 ],
  [ 1500,0,15,3,0, 454336,350640,40385,9217, 1129841,820938,51356,14041, 2521961,1230345,114635,58710, 3644799,1773978,165673,85037, 4516681,2255639,205304,102775, 5328417,53,0 ],
  [ 1500,0,25,3,0, 532981,361538,38762,12469, 2023543,1487413,42378,11228, 5396542,2572895,103780,54301, 8088985,3897695,155557,80602, 10413031,5190586,200251,100432, 15367355,85,0 ],
  [ 1500,0,50,3,0, 539626,362459,38545,12655, 2918727,2070515,36791,10692, 10280423,4829918,93458,49550, 15795885,7587983,143599,74617, 21401608,10535930,194560,98779, 32340966,114,0 ],
  [ 1500,0,75,3,0, 539626,362459,38545,12655, 2934497,2088046,36681,10581, 10436265,4866264,93181,49732, 16044491,7762810,143254,73944, 21770264,10819817,194377,97772, 32778503,114,0 ],
  [ 1500,25,1,3,0, 53018,82538,53018,-29520, 75969,75489,75969,480, 161496,78021,161496,83475, 186720,66857,186720,119864, 229892,76234,229892,153658, -3041300,-37,23 ],
  [ 1500,25,2,3,0, 100859,102486,50429,-814, 149801,87941,74901,30930, 302145,113086,151073,94530, 373441,190668,186720,91386, 459784,324969,229892,67408, -2411647,-32,19 ],
  [ 1500,25,3,3,0, 148700,109098,49567,13200, 223634,114428,74545,36402, 442795,331052,147598,37248, 560161,342982,186720,72393, 689676,392803,229892,98958, -2105117,-29,17 ],
  [ 1500,25,5,3,0, 221730,119194,48377,22372, 355251,316231,71050,7804, 708572,382769,141714,65161, 926886,428318,185377,99714, 1140351,577756,228070,112519, -974300,-15,-32 ],
  [ 1500,25,10,3,0, 371744,224978,45520,17971, 740227,506526,61686,19475, 1565841,839128,130487,60559, 2174169,1098384,181181,89649, 2674360,1335504,222863,111571, 2145148,32,0 ],
  [ 1500,25,15,3,0, 489359,355457,43499,11902, 1207750,835606,54898,16916, 2714677,1272863,123394,65537, 3895309,1949967,177060,88425, 4829074,2532344,219503,104397, 6131933,57,0 ],
  [ 1500,25,25,3,0, 573087,380390,41679,14014, 2160867,1528991,45254,13233, 5783827,2763615,111227,58081, 8643621,4214625,166223,85173, 11131786,5629579,214073,105812, 16756701,88,0 ],
  [ 1500,25,50,3,0, 580155,381370,41440,14199, 3115099,2317691,39266,10051, 10996464,5171515,99968,52954, 16875950,8107338,153418,79715, 22875832,11348606,207962,104793, 34424211,116,0 ],
  [ 1500,25,75,3,0, 580155,381370,41440,14199, 3131899,2321572,39149,10129, 11162759,5271966,99667,52596, 17141465,8269608,153049,79213, 23269797,11552533,207766,104618, 34903905,117,0 ],
  [ 1500,50,1,3,0, 61159,83657,61159,-22498, 82764,76290,82764,6474, 191355,82472,191355,108882, 198762,68696,198762,130066, 244805,78578,244805,166227, -2911307,-36,23 ],
  [ 1500,50,2,3,0, 111965,104014,55982,3976, 161255,89540,80628,35858, 341016,195853,170508,72581, 397524,194333,198762,101595, 489610,345319,244805,72146, -2404942,-32,19 ],
  [ 1500,50,3,3,0, 162771,111034,54257,17246, 239747,116825,79916,40974, 490677,338134,163559,50848, 596286,348473,198762,82604, 734416,399817,244805,111533, -1846358,-26,14 ],
  [ 1500,50,5,3,0, 240319,121750,52433,25870, 379657,334002,75931,9131, 773455,406689,154691,73353, 986634,517730,197327,93781, 1214292,589322,242858,124994, -793170,-13,-20 ],
  [ 1500,50,10,3,0, 399567,329671,48927,8559, 788832,527508,65736,21777, 1685419,856447,140452,69081, 2314136,1201253,192845,92740, 2847536,1463359,237295,115348, 2237191,33,0 ],
  [ 1500,50,15,3,0, 524381,360274,46612,14587, 1285660,864159,58439,19159, 2907393,1392231,132154,68871, 4145820,2082827,188446,93772, 5141467,2596457,233703,115682, 6930560,60,0 ],
  [ 1500,50,25,3,0, 613192,385905,44596,16530, 2298190,1570718,48130,15235, 6171112,2925285,118675,62420, 9198257,4530905,176890,89757, 11850542,5999812,227895,112514, 18318180,91,0 ],
  [ 1500,50,50,3,0, 620684,386944,44335,16696, 3311470,2372931,41741,11830, 11712506,5577247,106477,55775, 17956015,8639669,163236,84694, 24350057,12093524,221364,111423, 37052251,119,0 ],
  [ 1500,50,75,3,0, 620684,386944,44335,16696, 3329300,2390934,41616,11730, 11889254,5616532,106154,56006, 18238438,8776306,162843,84483, 24769331,12271960,221155,111584, 37753540,120,0 ],
  [ 1500,100,1,3,0, 77442,85897,77442,-8455, 96353,77892,96353,18462, 251071,105890,251071,145182, 222845,72424,222845,150421, 274632,83317,274632,191314, -2684821,-34,21 ],
  [ 1500,100,2,3,0, 134178,107069,67089,13554, 184162,92737,92081,45713, 418756,221902,209378,98427, 445689,310173,222845,67758, 549263,354726,274632,97268, -2209764,-30,18 ],
  [ 1500,100,3,3,0, 190913,114904,63638,25336, 271971,195467,90657,25502, 586441,366814,195480,73209, 668534,374482,222845,98017, 823895,413894,274632,136667, -1618225,-24,12 ],
  [ 1500,100,5,3,0, 277497,140200,60545,29956, 428469,341624,85694,17369, 903220,425801,180644,95484, 1106129,550964,221226,111033, 1362174,741367,272435,124161, -208297,-1,0 ],
  [ 1500,100,10,3,0, 455211,350660,55740,12802, 886042,544553,73837,28457, 1924574,920416,160381,83680, 2594069,1273579,216172,110041, 3193889,1661948,266157,127662, 3445777,42,0 ],
  [ 1500,100,15,3,0, 594427,369907,52838,19957, 1441479,907379,65522,24277, 3292825,1583168,149674,77712, 4646841,2203408,211220,111065, 5766253,2937776,262102,128567, 8742420,67,0 ],
  [ 1500,100,25,3,0, 693402,396937,50429,21561, 2572837,1728024,53881,17692, 6945683,3277675,133571,70539, 10307529,4960221,198222,102833, 13288052,6612018,255539,128385, 21530985,97,0 ],
  [ 1500,100,50,3,0, 701741,398092,50124,21689, 3704214,2675497,46692,12967, 13144588,6156041,119496,63532, 20116145,9662603,182874,95032, 27298505,13521756,248168,125243, 42339959,125,0 ],
  [ 1500,100,75,3,0, 701741,398092,50124,21689, 3724103,2680088,46551,13050, 13342242,6304465,119127,62837, 20432384,9832831,182432,94639, 27768397,13753363,247932,125134, 42885301,126,0 ],
  [ 1500,200,1,3,0, 110008,103712,110008,6296, 123533,80945,123533,42587, 370505,215209,370505,155296, 271010,79980,271010,191030, 334284,192346,334284,141938, -2500447,-32,20 ],
  [ 1500,200,2,3,0, 178603,113178,89302,32712, 229977,99131,114988,65423, 574237,365534,287119,104352, 542021,340361,271010,100830, 668569,389663,334284,139453, -1744415,-25,13 ],
  [ 1500,200,3,3,0, 247198,122645,82399,41518, 336421,205051,112140,43790, 777969,409659,259323,122770, 813031,396497,271010,138845, 1002853,541449,334284,153801, -687597,-11,0 ],
  [ 1500,200,5,3,0, 351853,222126,76768,28304, 526093,357168,105219,33785, 1162752,570054,232550,118540, 1345120,710717,269024,126881, 1657939,902857,331588,151017, 496913,13,0 ],
  [ 1500,200,10,3,0, 566501,365966,69367,24555, 1080460,711062,90038,30783, 2402886,1215089,200240,98983, 3153935,1592172,262828,130147, 3886593,2029367,323883,154769, 4934573,51,0 ],
  [ 1500,200,15,3,0, 734517,402510,65290,29512, 1753116,1171870,79687,26420, 4063689,2026005,184713,92622, 5648883,2806829,256767,129184, 7015825,3522192,318901,158801, 11144294,74,0 ],
  [ 1500,200,25,3,0, 853823,432336,62096,30654, 3122132,2072985,65385,21972, 8494824,4057954,163362,85324, 12526073,6103055,240886,123520, 16163073,8077931,310828,155483, 27040901,106,0 ],
  [ 1500,200,50,3,0, 863856,433724,61704,30724, 4489702,3176129,56593,16558, 16008754,7498199,145534,77369, 24436405,11722696,222149,115579, 33195403,16440422,301776,152318, 52700857,135,0 ],
  [ 1500,200,75,3,0, 863856,433724,61704,30724, 4513709,3181665,56421,16651, 16248219,7623451,145073,77007, 24820277,11917279,221610,115205, 33766530,16770590,301487,151749, 53472177,136,0 ],
  [ 2000,0,1,3,0, 61126,83975,61126,-22850, 91120,78588,91120,12532, 164045,78740,164045,85305, 216022,71737,216022,144285, 266640,82381,266640,184259, -2913777,-36,23 ],
  [ 2000,0,2,3,0, 122251,106074,61126,8089, 182240,94142,91120,44049, 328091,194660,164045,66715, 432044,308902,216022,61571, 533281,352944,266640,90168, -2438736,-32,19 ],
  [ 2000,0,3,3,0, 183377,114835,61126,22847, 273360,197581,91120,25260, 492136,339480,164045,50885, 648066,372626,216022,91814, 799921,411265,266640,129552, -1847212,-26,14 ],
  [ 2000,0,5,3,0, 276694,141550,60370,29486, 437005,345366,87401,18328, 804204,413181,160841,78205, 1072106,548041,214421,104813, 1322735,737218,264547,117103, -471080,-7,0 ],
  [ 2000,0,10,3,0, 468422,354947,57358,13895, 921998,568842,76833,29430, 1821131,895634,151761,77125, 2514241,1267120,209520,103927, 3102884,1652797,258574,120841, 3172353,40,0 ],
  [ 2000,0,15,3,0, 618775,389853,55002,20349, 1518221,1046118,69010,21459, 3195600,1576852,145255,73579, 4509257,2193452,204966,105264, 5602086,2921636,254640,121839, 8061511,40,0 ],
  [ 2000,0,25,3,0, 725849,418558,52789,22348, 2754061,1995343,57677,15889, 6926043,3310684,133193,69526, 10060567,4964237,193472,98006, 12897209,6576116,248023,121559, 20558069,96,0 ],
  [ 2000,0,50,3,0, 734893,419857,52492,22503, 4002984,2896848,50458,13943, 13376583,6480237,121605,62694, 19812349,9703950,180112,91895, 26481672,13369748,240742,119199, 41434888,124,0 ],
  [ 2000,0,75,3,0, 734893,419857,52492,22503, 4024998,2901992,50312,14038, 13584642,6525045,121291,63032, 20129661,9889866,179729,91427, 26937790,13570583,240516,119350, 42238309,125,0 ],
  [ 2000,25,1,3,0, 69267,85095,69267,-15828, 97915,79389,97915,18526, 193904,83191,193904,110713, 228063,73576,228063,154487, 281554,100397,281554,181157, -2794957,-35,22 ],
  [ 2000,25,2,3,0, 133358,107601,66679,12878, 193694,95740,96847,48977, 366961,200427,183481,83267, 456127,327543,228063,64292, 563107,357623,281554,102742, -2266464,-30,18 ],
  [ 2000,25,3,3,0, 197448,116771,65816,26893, 289473,199977,96491,29832, 540018,346563,180006,64485, 684190,378117,228063,102025, 844661,433950,281554,136904, -1599626,-24,12 ],
  [ 2000,25,5,3,0, 295283,144106,64425,32984, 461411,349252,92282,22432, 869087,422737,173817,89270, 1131854,557095,226371,114952, 1396677,764456,279335,126444, -105516,1,0 ],
  [ 2000,25,10,3,0, 496244,358774,60765,16833, 970603,681789,80884,24068, 1940709,926118,161726,84549, 2654207,1288231,221184,113831, 3276060,1694972,273005,131757, 3539791,43,0 ],
  [ 2000,25,15,3,0, 653798,394669,58115,23034, 1596130,1148521,72551,20346, 3388316,1696221,154014,76913, 4759768,2434772,216353,105682, 5914479,3001420,268840,132412, 8455110,66,0 ],
  [ 2000,25,25,3,0, 765954,424073,55706,24864, 2891385,2037071,60553,17891, 7313328,3578403,140641,71825, 10615203,5281017,204139,102580, 13615965,6946850,261845,128252, 21874170,98,0 ],
  [ 2000,25,50,3,0, 775421,425431,55387,24999, 4199356,3056439,52933,14407, 14092624,6821984,128115,66097, 20892414,10251308,189931,96737, 27955896,14001125,254145,126862, 43937833,127,0 ],
  [ 2000,25,75,3,0, 775421,425431,55387,24999, 4222399,3061819,52780,14507, 14311136,6868262,127778,66454, 21226634,10396564,189524,96697, 28437323,14303199,253905,126198, 44795182,128,0 ],
  [ 2000,50,1,3,0, 77409,86215,77409,-8806, 104710,80190,104710,24520, 223762,102157,223762,121605, 240105,75465,240105,164640, 296467,102791,296467,193676, -2698464,-34,21 ],
  [ 2000,50,2,3,0, 144464,109129,72232,17668, 205148,97339,102574,53904, 405831,220709,202916,92561, 480210,331259,240105,74476, 592934,362352,296467,115291, -2105588,-29,17 ],
  [ 2000,50,3,3,0, 211519,118706,70506,30938, 305585,202373,101862,34404, 587900,368160,195967,73247, 720315,383658,240105,112219, 889400,441013,296467,149462, -1374368,-21,9 ],
  [ 2000,50,5,3,0, 313872,218363,68481,20838, 485817,353138,97163,26536, 933970,523808,186794,82032, 1191601,581176,238320,122085, 1470618,776072,294124,138909, -254864,-2,0 ],
  [ 2000,50,10,3,0, 524066,362600,64171,19771, 1019207,704271,84934,26245, 2060287,957953,171691,91861, 2794174,1404627,232848,115796, 3449236,1821377,287436,135655, 3989904,46,0 ],
  [ 2000,50,15,3,0, 688820,399486,61228,25719, 1674040,1163188,76093,23221, 3581032,1738739,162774,83741, 5010278,2487324,227740,114680, 6226872,3149364,283040,139887, 9532608,69,0 ],
  [ 2000,50,25,3,0, 806059,429589,58622,27380, 3028709,2078799,63428,19893, 7700614,3740073,148089,76164, 11169839,5488837,214805,109250, 14334720,7317083,275668,134955, 23595724,101,0 ],
  [ 2000,50,50,3,0, 815950,431005,58282,27496, 4395728,3185529,55408,15255, 14808666,7150716,134624,69618, 21972479,10755486,199750,101973, 29430120,14730371,267547,133634, 46561313,129,0 ],
  [ 2000,50,75,3,0, 815950,431005,58282,27496, 4419801,3205031,55248,15185, 15037630,7212828,134265,69864, 22323607,10918239,199318,101834, 29936856,15051936,267293,132901, 47368036,130,0 ],
  [ 2000,100,1,3,0, 93692,101791,93692,-8099, 118300,81641,118300,36658, 283479,111060,283479,172420, 264188,79293,264188,184894, 326293,191410,326293,134883, -2552905,-33,20 ],
  [ 2000,100,2,3,0, 166677,112183,83338,27247, 228055,100536,114027,63759, 483572,338292,241786,72640, 528375,339089,264188,94643, 652586,387881,326293,132353, -1973387,-28,15 ],
  [ 2000,100,3,3,0, 239662,122576,79887,39029, 337810,207166,112603,43548, 683665,382325,227888,100447, 792563,394640,264188,132641, 978880,538820,326293,146686, -916584,-15,-38 ],
  [ 2000,100,5,3,0, 351050,223476,76593,27834, 534629,374795,106926,31967, 1063735,542769,212747,104193, 1311097,707744,262219,120671, 1618500,898607,323700,143979, 224046,9,0 ],
  [ 2000,100,10,3,0, 579711,370253,70985,25648, 1116417,721466,93035,32913, 2299443,1190456,191620,92416, 3074107,1585713,256176,124033, 3795588,1891504,316299,158674, 4796538,50,0 ],
  [ 2000,100,15,3,0, 758866,422456,67455,29903, 1829859,1206259,83175,28345, 3966464,1944190,180294,91922, 5511299,2796873,250514,123383, 6851658,3506553,311439,152050, 10966504,74,0 ],
  [ 2000,100,25,3,0, 886269,440620,64456,32411, 3303356,2340454,69180,20165, 8475184,4105478,162984,84033, 12279111,5998511,236137,120781, 15772230,7928839,303312,150834, 26329883,105,0 ],
  [ 2000,100,50,3,0, 897008,442152,64072,32490, 4788472,3488095,60359,16391, 16240749,7729510,147643,77375, 24132609,11778420,219387,112311, 32378569,16174274,294351,147312, 51837847,134,0 ],
  [ 2000,100,75,3,0, 897008,442152,64072,32490, 4814604,3494185,60183,16505, 16490619,7963246,147238,76137, 24517554,11974714,218907,111990, 32935923,16503979,294071,146714, 52377697,135,0 ],
  [ 2000,200,1,3,0, 126258,106270,126258,19988, 145479,84845,145479,60634, 402913,220380,402913,182534, 312353,181984,312353,130369, 385946,200838,385946,185108, -2383559,-31,19 ],
  [ 2000,200,2,3,0, 211102,118293,105551,46404, 273869,194665,136935,39602, 639053,390240,319527,124407, 624707,353850,312353,135429, 771892,406647,385946,182623, -1540247,-23,11 ],
  [ 2000,200,3,3,0, 295946,143654,98649,50764, 402260,334835,134087,22475, 875193,438035,291731,145719, 937060,512139,312353,141640, 1157838,582245,385946,191864, -525248,-8,0 ],
  [ 2000,200,5,3,0, 425406,334568,92816,19819, 632253,388840,126451,48683, 1323266,716072,264653,121439, 1550087,841344,310017,141749, 1914265,962645,382853,190324, 853143,19,0 ],
  [ 2000,200,10,3,0, 691000,398895,84612,35768, 1310836,843440,109236,38950, 2777754,1303729,231479,122835, 3633973,1795495,302831,153206, 4488292,2259123,374024,185764, 6949015,60,0 ],
  [ 2000,200,15,3,0, 898956,441722,79907,40643, 2141497,1470900,97341,30482, 4737328,2372512,215333,107492, 6513342,3291633,296061,146441, 8101230,4075098,368238,183006, 13627818,81,0 ],
  [ 2000,200,25,3,0, 1046690,547719,76123,36289, 3852650,2685415,80684,24445, 10024325,4808757,192775,100299, 14497656,7061038,278801,143012, 18647251,9478533,358601,176322, 31781103,113,0 ],
  [ 2000,200,50,3,0, 1059123,549485,75652,36403, 5573959,3988728,70260,19982, 19104915,9148668,173681,90511, 28452869,13838513,258662,132858, 38275466,19092941,347959,174387, 61728264,143,0 ],
  [ 2000,200,75,3,0, 1059123,549485,75652,36403, 5604209,3995762,70053,20106, 19396596,9219747,173184,90865, 28905447,14059212,258084,132556, 38934055,19437475,347625,174077, 62873321,143,0 ],
  [ 2500,0,1,3,0, 77375,86533,77375,-9158, 113066,82487,113066,30579, 196453,83910,196453,112543, 257365,78457,257365,178909, 318302,190325,318302,127978, -2727169,-34,21 ],
  [ 2500,0,2,3,0, 154750,111188,77375,21781, 226133,115826,113066,55153, 392907,204851,196453,94028, 514730,337317,257365,88706, 636604,369878,318302,133363, -1989391,-28,16 ],
  [ 2500,0,3,3,0, 232125,122508,77375,36539, 339199,209280,113066,43306, 589360,354991,196453,78123, 772095,392784,257365,126437, 954906,536192,318302,139572, -1145572,-18,-53 ],
  [ 2500,0,5,3,0, 350247,224825,76418,27365, 543164,378537,108633,32925, 964719,530150,192944,86914, 1277074,704821,255415,114451, 1579061,894458,315812,136921, -38737,3,0 ],
  [ 2500,0,10,3,0, 592922,387876,72603,25108, 1152373,819455,96031,27743, 2195999,1180189,183000,84651, 2994279,1579453,249523,117902, 3704584,1882554,308715,151836, 4201140,47,0 ],
  [ 2500,0,15,3,0, 783214,429065,69619,31480, 1906601,1240797,86664,30264, 3869238,1924860,175874,88381, 5373716,2678257,244260,122521, 6687491,3490714,303977,145308, 10860483,73,0 ],
  [ 2500,0,25,3,0, 918716,533941,66816,27984, 3484580,2415688,72975,22385, 8455544,4140137,162607,82989, 12032149,6002527,231387,115954, 15381388,7893487,295796,143998, 25666968,104,0 ],
  [ 2500,0,50,3,0, 930159,535618,66440,28182, 5087242,3709446,64125,17367, 16472743,8053706,149752,76537, 23828813,11819767,216626,109173, 31561735,16022267,286925,141268, 50638792,133,0 ],
  [ 2500,0,75,3,0, 930159,535618,66440,28182, 5115498,3716089,63944,17493, 16733019,8198341,149402,76202, 24214830,12016822,216204,108911, 32105316,16237469,286655,141677, 51485058,134,0 ],
  [ 2500,25,1,3,0, 85517,100989,85517,-15472, 119861,83138,119861,36723, 226312,88362,226312,137950, 269407,80446,269407,188961, 333215,192819,333215,140396, -2651869,-34,21 ],
  [ 2500,25,2,3,0, 165857,112716,82928,26570, 237586,117425,118793,60081, 431777,331182,215888,50297, 538813,341532,269407,98640, 666431,390778,333215,137827, -2085067,-29,16 ],
  [ 2500,25,3,3,0, 246197,137779,82066,36139, 355312,315876,118437,13145, 637242,375088,212414,87384, 808220,398475,269407,136581, 999646,542905,333215,152247, -1301627,-20,8 ],
  [ 2500,25,5,3,0, 368836,328248,80473,8855, 567571,380923,113514,37329, 1029601,539705,205920,97979, 1336821,715375,267364,124289, 1653002,907525,330600,149096, -75674,3,0 ],
  [ 2500,25,10,3,0, 620744,391702,76009,28046, 1200978,828052,100082,31077, 2315577,1197659,192965,93160, 3134246,1600515,261187,127811, 3877760,2038269,323147,153291, 4848067,50,0 ],
  [ 2500,25,15,3,0, 818237,433881,72732,34165, 1984510,1343200,90205,29150, 4061954,2044228,184634,91715, 5624226,2839269,255647,126589, 6999884,3554827,318177,156594, 11383779,75,0 ],
  [ 2500,25,25,3,0, 958821,539457,69732,30499, 3621903,2531266,75851,22841, 8842829,4407857,170054,85288, 12586786,6239000,242054,122073, 16100143,8248550,309618,150992, 26878987,106,0 ],
  [ 2500,25,50,3,0, 970688,541191,69335,30678, 5283614,3869037,66600,17831, 17188785,8395453,156262,79939, 24908878,12367125,226444,114016, 33035959,16834843,300327,147283, 53012544,135,0 ],
  [ 2500,25,75,3,0, 970688,541191,69335,30678, 5312900,3875916,66411,17962, 17459513,8527043,155889,79754, 25311803,12538447,225998,114048, 33604849,17053815,300043,147777, 53993594,136,0 ],
  [ 2500,50,1,3,0, 93658,102109,93658,-8451, 126656,83939,126656,42717, 256170,107328,256170,148842, 281448,97311,281448,184137, 348128,195213,348128,152915, -2577479,-33,20 ],
  [ 2500,50,2,3,0, 176963,114243,88481,31360, 249040,119023,124520,65008, 470647,336949,235324,66849, 562896,345198,281448,108849, 696257,395456,348128,150400, -1890690,-27,15 ],
  [ 2500,50,3,3,0, 260268,139715,86756,40184, 371424,332157,123808,13089, 685124,382021,228375,101034, 844344,418943,281448,141800, 1044385,565540,348128,159615, -1119729,-18,-45 ],
  [ 2500,50,5,3,0, 387425,330805,84529,12353, 591977,384809,118395,41433, 1094484,549261,218897,109045, 1396569,739405,279314,131433, 1726943,919091,345389,161570, 278961,10,0 ],
  [ 2500,50,10,3,0, 648566,395529,79416,30984, 1249583,836649,104132,34411, 2435155,1229643,202930,100459, 3274212,1636603,272851,136467, 4050936,2164724,337578,157184, 5460288,53,0 ],
  [ 2500,50,15,3,0, 853260,438698,75845,36850, 2062420,1357868,93746,32025, 4254671,2086746,193394,98542, 5874737,2972129,267033,131937, 7312277,3831532,332376,158216, 12250948,77,0 ],
  [ 2500,50,25,3,0, 998926,544972,72649,33015, 3759227,2677344,78727,22657, 9230115,4491027,177502,91136, 13141422,6540303,252720,126945, 16818898,8618283,323440,157704, 28312446,108,0 ],
  [ 2500,50,50,3,0, 1011217,546765,72230,33175, 5479985,3998127,69075,18679, 17904826,8724185,162771,83460, 25988943,12871303,236263,119251, 34510184,17466670,313729,154941, 55705483,138,0 ],
  [ 2500,50,75,3,0, 1011217,546765,72230,33175, 5510301,4019128,68879,18640, 18186007,8871609,162375,83164, 26408776,13045145,235793,119318, 35104382,17802553,313432,154481, 56588551,138,0 ],
  [ 2500,100,1,3,0, 109941,104348,109941,5593, 140246,85541,140246,54705, 315887,116230,315887,199657, 305531,181297,305531,124234, 377955,199902,377955,178053, -2436017,-32,19 ],
  [ 2500,100,2,3,0, 199176,117298,99588,40939, 271947,196070,135974,37939, 548388,362998,274194,92695, 611061,352578,305531,129242, 755910,404864,377955,175523, -1769220,-26,14 ],
  [ 2500,100,3,3,0, 288410,143585,96137,48275, 403649,336949,134550,22233, 780888,410701,260296,123396, 916592,510283,305531,135436, 1133865,579617,377955,184749, -754235,-12,-23 ],
  [ 2500,100,5,3,0, 424603,349255,92641,16440, 640789,392432,128158,49671, 1224250,582888,244850,128272, 1516064,757714,303213,151670, 1874826,958096,374965,183346, 933069,20,0 ],
  [ 2500,100,10,3,0, 704211,403182,86230,36861, 1346792,867729,112233,39922, 2674311,1293462,222859,115071, 3554145,1789086,296179,147088, 4397288,2250022,366441,178939, 6642091,58,0 ],
  [ 2500,100,15,3,0, 923305,533368,82072,34661, 2218239,1505288,100829,32407, 4640103,2367697,210914,103291, 6375758,3201370,289807,144290, 7937063,4059459,360776,176255, 13098070,79,0 ],
  [ 2500,100,25,3,0, 1079136,569340,78483,37076, 4033874,2834499,84479,25118, 10004685,4843416,192398,99255, 14250694,7065054,274052,138185, 18256408,9230639,351085,173572, 31329931,112,0 ],
  [ 2500,100,50,3,0, 1092274,571250,78020,37216, 5872729,4300543,74026,19817, 19336909,9379979,175790,90518, 28149073,13894387,255901,129588, 37458632,19008143,340533,167732, 60681368,142,0 ],
  [ 2500,100,75,3,0, 1092274,571250,78020,37216, 5905104,4322016,73814,19789, 19638996,9544877,175348,90126, 28602723,14101770,255381,129473, 38103448,19254696,340209,168292, 61676433,142,0 ],
  [ 2500,200,1,3,0, 1254389,700860,1254389,553529, 6694710,3612205,6694710,3082505, 22337042,10743490,22337042,11593552, 32990616,16187083,32990616,16803532, 44101581,22189241,44101581,21912341, 75569404,152,0 ],
  [ 2500,200,2,3,0, 243601,123408,121801,60097, 317762,202464,158881,57649, 703869,400580,351934,151644, 707393,382265,353696,162564, 875215,439301,437608,217957, -1058492,-17,-49 ],
  [ 2500,200,3,3,0, 344695,223026,114898,40556, 468099,346534,156033,40522, 972416,545061,324139,142452, 1061089,547324,353696,171255, 1312823,736532,437608,192097, -370591,-5,0 ],
  [ 2500,200,5,3,0, 498959,359481,108864,30432, 738413,421861,147683,63310, 1483781,756041,296756,145548, 1755055,904790,351011,170053, 2170591,1149345,434118,204249, 1818348,29,0 ],
  [ 2500,200,10,3,0, 815500,431824,99857,46981, 1541211,915853,128434,52113, 3152622,1589785,262719,130236, 4114012,2092852,342834,168430, 5089992,2618141,424166,205988, 8468502,65,0 ],
  [ 2500,200,15,3,0, 1063395,552634,94524,45401, 2529877,1665579,114994,39286, 5410967,2720520,245953,122293, 7377800,3696131,335355,167349, 9186635,4757266,417574,201335, 16170698,87,0 ],
  [ 2500,200,25,3,0, 1239557,692269,90150,39803, 4583168,3178110,95983,29425, 11553826,5623695,222189,114041, 16469238,8209238,316716,158846, 21131429,10781682,406374,199034, 36421131,118,0 ],
  [ 2500,200,50,3,0, 1254389,707749,89599,39046, 6658216,4799676,83927,23427, 22201075,10722137,201828,104354, 32469333,15875673,295176,150851, 43355530,21815219,394141,195821, 70827749,149,0 ],
  [ 2500,200,75,3,0, 1254389,707749,89599,39046, 6694710,4808209,83684,23581, 22544973,10878528,201294,104165, 32990616,16187768,294559,150025, 44101581,22189742,393764,195641, 71919453,150,0 ],
  [ 3000,0,1,3,0, 93625,102427,93625,-8802, 135013,86237,135013,48776, 228861,89081,228861,139780, 298708,100303,298708,198406, 369964,198966,369964,170998, -2546449,-33,20 ],
  [ 3000,0,2,3,0, 187249,116303,93625,35473, 270025,197475,135013,36275, 457722,335756,228861,60983, 597416,351306,298708,123055, 739928,403082,369964,168423, -1998192,-28,16 ],
  [ 3000,0,3,3,0, 280874,143516,93625,45786, 405038,339064,135013,21991, 686583,383367,228861,101072, 896124,428119,298708,156002, 1109891,576988,369964,177635, -864699,-14,-30 ],
  [ 3000,0,5,3,0, 423800,350604,92465,15970, 649324,410059,129865,47853, 1125233,555603,225047,113926, 1482041,754741,296408,145460, 1835387,953846,367077,176308, 660202,16,0 ],
  [ 3000,0,10,3,0, 717421,420805,87848,36320, 1382749,891868,115229,40907, 2570867,1268680,214239,108516, 3474317,1782777,289526,140962, 4306284,2241022,358857,172105, 6313974,57,0 ],
  [ 3000,0,15,3,0, 947654,539977,84236,36238, 2294981,1553712,104317,33694, 4542877,2348217,206494,99757, 6238174,3191214,283553,138498, 7772895,4043570,353313,169515, 12788391,79,0 ],
  [ 3000,0,25,3,0, 1111583,678491,80842,31498, 4215098,3026618,88274,24890, 9985045,4953425,192020,96762, 14003732,7070419,269303,133333, 17865566,9196087,343569,166721, 30055316,110,0 ],
  [ 3000,0,50,3,0, 1125425,680545,80388,31777, 6171499,4520544,77792,20810, 19568904,9704176,177899,89679, 27845277,13856777,253139,127168, 36641798,18744395,333107,162704, 59616473,141,0 ],
  [ 3000,0,75,3,0, 1125425,680545,80388,31777, 6205999,4528686,77575,20966, 19881395,9780122,177512,90190, 28299999,14160205,252679,126248, 37272841,19102676,332793,162234, 60659645,142,0 ],
  [ 3000,25,1,3,0, 101766,103547,101766,-1780, 141807,87038,141807,54770, 258720,108047,258720,150673, 310750,182499,310750,128250, 384877,201361,384877,183516, -2568481,-33,20 ],
  [ 3000,25,2,3,0, 198356,117831,99178,40262, 281479,199074,140739,41202, 496593,341523,248296,77535, 621499,354971,310750,133264, 769754,407761,384877,180996, -1803815,-26,14 ],
  [ 3000,25,3,3,0, 294945,217152,98315,25931, 421150,341460,140383,26563, 734465,390449,244822,114672, 932249,513918,310750,139444, 1154631,584001,384877,190210, -1018448,-16,-35 ],
  [ 3000,25,5,3,0, 442389,353161,96521,19468, 673730,413945,134746,51957, 1190116,579674,238023,122088, 1541789,859129,308358,136532, 1909328,981134,381866,185639, 851639,19,0 ],
  [ 3000,25,10,3,0, 745244,424631,91254,39259, 1431353,900465,119279,44241, 2690445,1377664,224204,109398, 3614284,1803888,301190,150866, 4479460,2283696,373288,182980, 6831535,59,0 ],
  [ 3000,25,15,3,0, 982676,544794,87349,38923, 2372890,1568380,107859,36569, 4735593,2390735,215254,106584, 6488685,3324074,294940,143846, 8085288,4220824,367513,175657, 13726468,81,0 ],
  [ 3000,25,25,3,0, 1151688,684007,83759,34013, 4352422,3142196,91150,25345, 10372330,5144145,199468,100542, 14558368,7263763,279969,140281, 18584321,9566821,357391,173413, 31496313,112,0 ],
  [ 3000,25,50,3,0, 1165954,699455,83282,33321, 6367871,4679985,80267,21276, 20284945,10045923,184409,93082, 28925342,14484592,262958,131280, 38116023,19489012,346509,169336, 61865568,143,0 ],
  [ 3000,25,75,3,0, 1165954,699455,83282,33321, 6403400,4688363,80043,21438, 20607890,10123339,183999,93612, 29396972,14667054,262473,131517, 38772375,19722351,346182,170089, 63242458,144,0 ],
  [ 3000,50,1,3,0, 109908,104666,109908,5241, 148602,87839,148602,60763, 288578,112498,288578,176080, 322791,184338,322791,138453, 399790,203705,399790,196085, -2438488,-32,19 ],
  [ 3000,50,2,3,0, 209462,119358,104731,45052, 292932,200672,146466,46130, 535463,347290,267731,94086, 645582,373613,322791,135984, 799580,412440,399790,193570, -1631543,-24,12 ],
  [ 3000,50,3,3,0, 309016,219087,103005,29977, 437263,343856,145754,31136, 782347,412047,260782,123434, 968373,519459,322791,149638, 1199370,606736,399790,197545, -804363,-13,-21 ],
  [ 3000,50,5,3,0, 460978,355717,100577,22966, 698137,417831,139627,56061, 1254999,695279,251000,111944, 1601536,868683,320307,146571, 1983269,993201,396654,198014, 982998,20,0 ],
  [ 3000,50,10,3,0, 773066,428458,94661,42197, 1479958,909063,123330,47575, 2810023,1395134,234169,117907, 3754251,1948386,312854,150489, 4652636,2523192,387720,177454, 7236550,61,0 ],
  [ 3000,50,15,3,0, 1017699,549610,90462,41608, 2450800,1670782,111400,35455, 4928309,2447618,224014,112759, 6739195,3391653,306327,152161, 8397681,4384438,381713,182420, 14459825,83,0 ],
  [ 3000,50,25,3,0, 1191793,702859,86676,35559, 4489745,3183774,94026,27350, 10759616,5305815,206916,104881, 15113004,7580193,290635,144862, 19303076,9921533,371213,180414, 33014272,114,0 ],
  [ 3000,50,50,3,0, 1206483,705029,86177,35818, 6564243,4735225,82743,23055, 21000987,10374655,190918,96603, 30005407,14988771,272776,136515, 39590247,20105168,359911,177137, 64803461,145,0 ],
  [ 3000,50,75,3,0, 1206483,705029,86177,35818, 6600802,4831575,82510,22115, 21334384,10453390,190486,97152, 30493946,15188678,272267,136654, 40271908,20471038,359571,176793, 65848812,146,0 ],
  [ 3000,100,1,3,0, 126191,106906,126191,19285, 162192,89441,162192,72751, 348295,212915,348295,135380, 346874,188066,346874,158807, 429617,321534,429617,108082, -2469131,-32,20 ],
  [ 3000,100,2,3,0, 231675,122413,115837,54631, 315840,203869,157920,55985, 613203,373339,306602,119932, 693747,380993,346874,156377, 859233,437519,429617,210857, -1287464,-20,8 ],
  [ 3000,100,3,3,0, 337159,222957,112386,38067, 469488,348648,156496,40280, 878112,440727,292704,145795, 1040621,545468,346874,165051, 1288850,733903,429617,184982, -423081,-6,0 ],
  [ 3000,100,5,3,0, 498156,360830,108689,29962, 746949,499453,149390,49499, 1384764,728906,276953,131172, 1721032,901817,344206,163843, 2131152,1145146,426230,197201, 1355284,25,0 ],
  [ 3000,100,10,3,0, 828711,436110,101475,48074, 1577168,1044492,131431,44390, 3049179,1563503,254098,123806, 4034184,2086393,336182,162316, 4998988,2592819,416582,200514, 7879631,63,0 ],
  [ 3000,100,15,3,0, 1087744,673447,96688,36826, 2606619,1712353,118483,40648, 5313742,2609505,241534,122920, 7240216,3687175,329101,161502, 9022467,4726906,410112,195253, 15615704,85,0 ],
  [ 3000,100,25,3,0, 1272004,713890,92509,40590, 4764392,3445429,99778,27622, 11534186,5656705,221811,113028, 16222276,8024486,311967,157650, 20740587,10548910,398857,195994, 35867179,118,0 ],
  [ 3000,100,50,3,0, 1287540,716177,91967,40812, 6956987,5037791,87693,24192, 22433069,10953449,203937,104360, 32165537,15931397,292414,147583, 42538696,21662161,386715,189787, 70117888,149,0 ],
  [ 3000,100,75,3,0, 1287540,716177,91967,40812, 6995605,5134613,87445,23262, 22787373,11218173,203459,103296, 32687892,16245203,291856,146810, 43270974,22036172,386348,189596, 70700757,149,0 ],
  [ 3000,200,1,3,0, 158757,111384,158757,47372, 189371,92644,189371,96727, 467729,351285,467729,116444, 395039,195973,395039,199067, 489269,347133,489269,142137, -2239210,-30,18 ],
  [ 3000,200,2,3,0, 276100,141859,138050,67120, 361654,314463,180827,23595, 768684,423936,384342,172374, 790079,395904,395039,197087, 978538,539765,489269,219387, -1018009,-16,-43 ],
  [ 3000,200,3,3,0, 393443,331565,131148,20626, 533937,370618,177979,54440, 1069640,560422,356547,169739, 1185118,583959,395039,200386, 1467808,779128,489269,229560, -88577,2,0 ],
  [ 3000,200,5,3,0, 572512,371057,124912,43954, 844573,528882,168915,63138, 1644296,873009,328859,154257, 1960022,953110,392004,201383, 2426916,1290914,485383,227200, 2482141,35,0 ],
  [ 3000,200,10,3,0, 940000,536453,115102,49414, 1771586,1166466,147632,50427, 3527490,1753775,293958,147810, 4594050,2404636,382838,182451, 5691692,2960438,474308,227604, 9401551,69,0 ],
  [ 3000,200,15,3,0, 1227835,692713,109141,47566, 2918257,1976994,132648,42785, 6084606,2960827,276573,141990, 8242259,4101628,374648,188210, 10272039,5311123,466911,225496, 18560867,92,0 ],
  [ 3000,200,25,3,0, 1432424,749290,104176,49683, 5313687,3686041,111281,34087, 13083328,6452999,251602,127506, 18440821,9167370,354631,178336, 23615607,12083482,454146,221772, 41621655,124,0 ],
  [ 3000,200,50,3,0, 1449655,751809,103547,49846, 7742474,5538424,97594,27782, 25297235,12372606,229975,117497, 36485796,17991490,331689,168130, 48435593,24580828,440324,216862, 80302289,155,0 ],
  [ 3000,200,75,3,0, 1449655,751809,103547,49846, 7785211,5622306,97315,27036, 25693350,12474824,229405,118023, 37075785,18314725,331034,167509, 49269107,25053498,439903,216211, 81496318,156,0 ],
  [ 1000,0,1,0,1, 6520,76125,6520,-69605, 15086,51673,15086,-36587, 37630,45821,37630,-8192, 58787,33047,58787,25740, 80785,38210,80785,42575, -3750934,-42,27 ],
  [ 1000,0,2,0,1, 13040,77037,6520,-31998, 30172,54348,15086,-12088, 75259,51673,37630,11793, 117575,57581,58787,29997, 161571,67530,80785,47020, -3528582,-40,26 ],
  [ 1000,0,3,0,1, 19561,77949,6520,-19463, 45259,57022,15086,-3921, 112889,72039,37630,13617, 176362,67189,58787,36391, 242356,81229,80785,53709, -3306452,-38,25 ],
  [ 1000,0,5,0,1, 29516,79341,6440,-10871, 72032,75726,14406,-739, 182172,82605,36434,19913, 290432,180907,58086,21905, 399082,320080,79816,15800, -3169290,-38,24 ],
  [ 1000,0,10,0,1, 49977,82207,6120,-3946, 149682,90080,12474,4967, 396573,313044,33048,6961, 671271,380892,55939,24198, 924468,539200,77039,32106, -2449637,-32,20 ],
  [ 1000,0,15,0,1, 66029,84457,5869,-1638, 242720,195375,11033,2152, 673662,384069,30621,13163, 1186711,588304,53941,27200, 1654130,920048,75188,33367, -1256509,-19,-34 ],
  [ 1000,0,25,0,1, 77467,99400,5634,-1595, 427288,349113,8948,1637, 1364253,726954,26236,12256, 2549445,1286216,49028,24293, 3747520,2043229,72068,32775, 1294695,25,0 ],
  [ 1000,0,50,0,1, 78434,99537,5602,-1507, 608541,412300,7671,2474, 2443884,1232475,22217,11013, 4782995,2475893,43482,20974, 7552533,4030414,68659,32019, 5822599,56,0 ],
  [ 1000,0,75,0,1, 78434,99537,5602,-1507, 611729,412924,7647,2485, 2476522,1237211,22112,11065, 4852378,2486602,43325,21123, 7678019,4050681,68554,32387, 6056283,57,0 ],
  [ 1000,25,1,0,1, 9554,76583,9554,-67030, 17204,51945,17204,-34741, 53300,48391,53300,4909, 64096,33936,64096,30160, 88162,55152,88162,33010, -3704808,-41,27 ],
  [ 1000,25,2,0,1, 16749,77597,8375,-30424, 33765,54889,16883,-10562, 94348,69305,47174,12521, 128193,59402,64096,34395, 176324,70117,88162,53104, -3481148,-40,26 ],
  [ 1000,25,3,0,1, 23945,78611,7982,-18222, 50327,57833,16776,-2502, 135395,75703,45132,19897, 192289,69841,64096,40816, 264486,100703,88162,54595, -3213057,-38,25 ],
  [ 1000,25,5,0,1, 34929,80159,7621,-9868, 79748,77031,15950,544, 211023,87283,42205,24748, 316636,185259,63327,26276, 435508,326327,87102,21836, -3020990,-36,23 ],
  [ 1000,25,10,0,1, 57493,83341,7040,-3165, 165232,92890,13769,6028, 445331,335398,37111,9161, 731715,390916,60976,28400, 1008746,569309,84062,36620, -2209736,-29,18 ],
  [ 1000,25,15,0,1, 75185,85839,6683,-947, 267829,200068,12174,3080, 748467,396027,34021,16020, 1293606,714330,58800,26331, 1804721,961340,82033,38335, -966294,-15,-19 ],
  [ 1000,25,25,0,1, 87781,100957,6384,-958, 471820,371618,9881,2098, 1505494,840698,28952,12785, 2780438,1434232,53470,25889, 4087247,2215748,78601,35990, 1704868,29,0 ],
  [ 1000,25,50,0,1, 88844,101108,6346,-876, 672328,424860,8475,3119, 2691817,1300463,24471,12649, 5220964,2657307,47463,23306, 8234677,4404488,74861,34820, 7031481,61,0 ],
  [ 1000,25,75,0,1, 88844,101108,6346,-876, 675851,425554,8448,3129, 2727738,1382706,24355,12009, 5296858,2792497,47293,22360, 8371452,4426626,74745,35222, 6928480,61,0 ],
  [ 1000,50,1,0,1, 12587,77041,12587,-64454, 19321,52216,19321,-32895, 68971,65476,68971,3495, 69405,34874,69405,34531, 95539,56472,95539,39067, -3681009,-41,27 ],
  [ 1000,50,2,0,1, 20458,78157,10229,-28849, 37358,55430,18679,-9036, 113436,72422,56718,20507, 138811,61172,69405,38819, 191078,72653,95539,59212, -3400214,-39,26 ],
  [ 1000,50,3,0,1, 28329,79272,9443,-16981, 55395,58644,18465,-1083, 157900,79368,52633,26178, 208216,72494,69405,45241, 286617,104505,95539,60704, -3108489,-37,24 ],
  [ 1000,50,5,0,1, 40342,80976,8802,-8866, 87464,78335,17493,1826, 239874,106475,47975,26680, 342841,189660,68568,30636, 471934,348295,94387,24728, -2917363,-36,23 ],
  [ 1000,50,10,0,1, 65009,84476,7960,-2384, 180782,95700,15065,7090, 494089,343238,41174,12571, 792158,400889,66013,32606, 1093023,583696,91085,42444, -1925162,-27,16 ],
  [ 1000,50,15,0,1, 84341,100558,7497,-1442, 292937,204611,13315,4015, 823272,422501,37421,18217, 1400501,747073,63659,29701, 1955312,1002832,88878,43295, -626302,-9,0 ],
  [ 1000,50,25,0,1, 98094,102514,7134,-321, 516352,380238,10814,2851, 1646735,877592,31668,14791, 3011431,1595424,57912,27231, 4426974,2501408,85134,37030, 2235043,34,0 ],
  [ 1000,50,50,0,1, 99254,102680,7090,-245, 736114,525156,9279,2659, 2939751,1536836,26725,12754, 5658934,2962607,51445,24512, 8916820,4763291,81062,37759, 7403619,62,0 ],
  [ 1000,50,75,0,1, 99254,102680,7090,-245, 739972,525918,9250,2676, 2978953,1542586,26598,12825, 5741339,2975405,51262,24696, 9064885,4802971,80936,38053, 7670110,63,0 ],
  [ 1000,100,1,0,1, 18654,77957,18654,-59303, 23556,52759,23556,-29204, 100313,70615,100313,29698, 80023,36651,80023,43372, 110293,59014,110293,51279, -3566410,-40,26 ],
  [ 1000,100,2,0,1, 27875,79277,13938,-25701, 44544,56513,22272,-5985, 151612,78656,75806,36478, 160047,64713,80023,47667, 220585,77727,110293,71429, -3238346,-38,25 ],
  [ 1000,100,3,0,1, 37097,80596,12366,-14500, 65532,60267,21844,1755, 202912,101211,67637,33900, 240070,77848,80023,54074, 330878,195941,110293,44979, -2992586,-36,23 ],
  [ 1000,100,5,0,1, 51168,82610,11164,-6860, 102897,80944,20579,4391, 297576,115830,59515,36349, 395251,306823,79050,17686, 544785,360788,108957,36799, -2780837,-35,22 ],
  [ 1000,100,10,0,1, 80042,86745,9801,-821, 211881,115206,17657,8056, 591606,373282,49300,18194, 913045,516170,76087,33073, 1261579,741233,105132,43362, -1665530,-24,13 ],
  [ 1000,100,15,0,1, 102653,103323,9125,-59, 343154,332232,15598,496, 972882,536283,44222,19845, 1614292,877539,73377,33489, 2256493,1281787,102568,44305, -586921,-8,0 ],
  [ 1000,100,25,0,1, 118722,105628,8634,952, 605415,411364,12679,4064, 1929218,936714,37100,19087, 3473416,1781147,66796,32544, 5106428,2762666,98201,45072, 3707933,44,0 ],
  [ 1000,100,50,0,1, 120075,105823,8577,1018, 863686,564163,10887,3776, 3435617,1735147,31233,15459, 6534872,3353536,59408,28921, 10281108,5511339,93465,43362, 9593253,70,0 ],
  [ 1000,100,75,0,1, 120075,105823,8577,1018, 868216,565062,10853,3789, 3481384,1741911,31084,15531, 6630301,3383399,59199,28990, 10451752,5638541,93319,42975, 9821886,71,0 ],
  [ 1000,200,1,0,1, 30788,79789,30788,-49001, 32025,53845,32025,-21820, 162996,95410,162996,67587, 101259,55232,101259,46028, 139800,64147,139800,75653, -3392817,-39,25 ],
  [ 1000,200,2,0,1, 42710,81516,21355,-19403, 58915,58678,29458,118, 227965,105639,113983,61163, 202519,71845,101259,65337, 279600,103594,139800,88003, -2959284,-36,23 ],
  [ 1000,200,3,0,1, 54633,83243,18211,-9537, 85805,77396,28602,2803, 292934,115719,97645,59072, 303778,183742,101259,40012, 419400,324191,139800,31736, -2839156,-35,22 ],
  [ 1000,200,5,0,1, 72820,85878,15888,-2849, 133762,86162,26752,9520, 412979,332105,82596,16175, 500070,339756,100014,32063, 690488,401997,138098,57698, -2676289,-34,21 ],
  [ 1000,200,10,0,1, 110106,104620,13482,672, 274080,200148,22840,6161, 786638,419155,65553,30624, 1154819,586218,96235,47383, 1598689,914106,133224,57049, -975494,-15,-23 ],
  [ 1000,200,15,0,1, 139278,108851,12380,2705, 443588,351004,20163,4208, 1272101,719197,57823,25132, 2041874,1167123,92812,39761, 2858855,1628755,129948,55914, 449859,14,0 ],
  [ 1000,200,25,0,1, 159977,111856,11635,3500, 783542,533580,16409,5235, 2494183,1267190,47965,23596, 4397387,2400064,84565,38410, 6465336,3496342,124333,57096, 5460192,54,0 ],
  [ 1000,200,50,0,1, 161716,112108,11551,3543, 1118831,806491,14103,3937, 4427350,2342518,40249,18953, 8286750,4246655,75334,36728, 13009682,6963004,118270,54970, 12834286,79,0 ],
  [ 1000,200,75,0,1, 161716,112108,11551,3543, 1124702,821551,14059,3789, 4486246,2351160,40056,19063, 8408224,4389156,75073,35885, 13225485,7097640,118085,54713, 12947221,80,0 ],
  [ 1500,0,1,0,1, 10221,76765,10221,-66543, 21845,52992,21845,-31147, 49919,47971,49919,1948, 77015,36288,77015,40727, 106340,58481,106340,47859, -3672048,-41,27 ],
  [ 1500,0,2,0,1, 20443,78316,10221,-28937, 43691,56986,21845,-6648, 99838,70488,49919,14675, 154030,64113,77015,44959, 212680,76780,106340,67950, -3381963,-39,25 ],
  [ 1500,0,3,0,1, 30664,79867,10221,-16401, 65536,60979,21845,1519, 149758,78489,49919,23756, 231046,76912,77015,51378, 319020,194481,106340,41513, -3107182,-37,24 ],
  [ 1500,0,5,0,1, 46269,82235,10095,-7847, 104806,82183,20961,4525, 242596,93176,48519,29884, 380306,305342,76061,14993, 525334,358474,105067,33372, -2903323,-36,23 ],
  [ 1500,0,10,0,1, 78334,100442,9592,-2707, 221336,118353,18445,8582, 534627,351551,44552,15256, 878361,513153,73197,30434, 1217129,736433,101427,40058, -1864567,-26,15 ],
  [ 1500,0,15,0,1, 103483,104263,9198,-69, 364031,338613,16547,1155, 917611,531100,41710,17569, 1555067,872650,70685,31019, 2177087,1273353,98958,41079, -787116,-12,0 ],
  [ 1500,0,25,0,1, 121396,106987,8829,1048, 656002,425672,13738,4824, 1898792,940627,36515,18426, 3368184,1790484,64773,30340, 4921206,2644612,94639,43781, 3532445,43,0 ],
  [ 1500,0,50,0,1, 122909,107218,8779,1121, 947940,705620,11949,3054, 3482866,1773743,31662,15537, 6403257,3386509,58211,27425, 9901121,5362157,90010,41263, 9030188,68,0 ],
  [ 1500,0,75,0,1, 122909,107218,8779,1121, 953080,706680,11914,3080, 3531772,1887371,31534,14682, 6498970,3402383,58027,27648, 10065547,5518682,89871,40597, 9017015,68,0 ],
  [ 1500,25,1,0,1, 13255,77223,13255,-63968, 23963,53264,23963,-29301, 65590,50541,65590,15049, 82324,37177,82324,45148, 113717,59752,113717,53965, -3614749,-41,27 ],
  [ 1500,25,2,0,1, 24151,78876,12076,-27362, 47283,57527,23642,-5122, 118927,73605,59463,22661, 164648,65884,82324,49382, 227433,79317,113717,74058, -3301029,-38,25 ],
  [ 1500,25,3,0,1, 35048,80529,11683,-15160, 70604,75675,23535,-1690, 172263,82004,57421,30087, 246973,79564,82324,55803, 341150,198234,113717,47639, -3046197,-37,24 ],
  [ 1500,25,5,0,1, 51682,83053,11276,-6844, 112522,83487,22504,5807, 271447,112368,54289,31816, 406510,309743,81302,19353, 561760,364771,112352,39398, -2788524,-35,22 ],
  [ 1500,25,10,0,1, 85850,101576,10512,-1926, 236885,195014,19740,3489, 583385,373906,48615,17457, 938804,538153,78234,33388, 1301406,766542,108451,44572, -1880551,-26,15 ],
  [ 1500,25,15,0,1, 112639,105645,10012,622, 389140,343306,17688,2083, 992416,543059,45110,20425, 1661963,905192,75544,34399, 2327677,1314645,105804,46047, -358931,-3,0 ],
  [ 1500,25,25,0,1, 131710,108544,9579,1685, 700534,522027,14671,3738, 2040033,1160420,39231,16916, 3599177,1952176,69215,31673, 5260933,2930721,101172,44812, 3363380,42,0 ],
  [ 1500,25,50,0,1, 133319,108789,9523,1752, 1011726,718180,12753,3700, 3730799,1947781,33916,16209, 6841227,3676883,62193,28767, 10583264,5736731,96211,44059, 9833618,71,0 ],
  [ 1500,25,75,0,1, 133319,108789,9523,1752, 1017202,733195,12715,3550, 3782988,2032716,33777,15627, 6943450,3693301,61995,29019, 10758980,5894577,96062,43432, 9867732,71,0 ],
  [ 1500,50,1,0,1, 16288,77681,16288,-61392, 26080,53535,26080,-27455, 81261,67626,81261,13635, 87633,53092,87633,34542, 121094,61073,121094,60021, -3613053,-41,27 ],
  [ 1500,50,2,0,1, 27860,79436,13930,-25788, 50876,58068,25438,-3596, 138015,76722,69008,30647, 175266,67654,87633,53806, 242187,81854,121094,80167, -3220095,-38,25 ],
  [ 1500,50,3,0,1, 39432,81191,13144,-13919, 75673,76486,25224,-271, 194769,85668,64923,36367, 262900,97193,87633,55236, 363281,202036,121094,53748, -2963732,-36,23 ],
  [ 1500,50,5,0,1, 57095,83870,12457,-5842, 120238,84792,24048,7089, 300298,194046,60060,21250, 432715,329071,86543,20729, 598185,371018,119637,45433, -2838825,-35,22 ],
  [ 1500,50,10,0,1, 93366,102711,11433,-1144, 252435,197824,21036,4551, 632143,381745,52679,20866, 999248,548126,83271,37593, 1385684,780929,115474,50396, -1595976,-23,-55 ],
  [ 1500,50,15,0,1, 121795,107027,10826,1313, 414248,347999,18829,3011, 1067221,569532,48510,22622, 1768858,922808,80403,38457, 2478268,1340316,112649,51725, 69031,7,0 ],
  [ 1500,50,25,0,1, 142023,110101,10329,2322, 745065,530648,15603,4490, 2181274,1197314,41948,18922, 3830170,2085216,73657,33557, 5600660,3103291,107705,48026, 4015737,46,0 ],
  [ 1500,50,50,0,1, 143730,110361,10266,2384, 1075512,744626,13557,4171, 3978733,2078104,36170,17278, 7279196,3858246,66175,31100, 11265408,6095034,102413,47003, 10867092,74,0 ],
  [ 1500,50,75,0,1, 143730,110361,10266,2384, 1081324,745824,13517,4194, 4034203,2086696,36020,17388, 7387931,3890685,65964,31225, 11452413,6225490,102254,46669, 11142072,75,0 ],
  [ 1500,100,1,0,1, 22355,78596,22355,-56241, 30315,54078,30315,-23763, 112603,72766,112603,39837, 98251,54869,98251,43383, 135847,63614,135847,72233, -3498455,-40,26 ],
  [ 1500,100,2,0,1, 35278,80555,17639,-22639, 58062,59151,29031,-544, 176192,82956,88096,46618, 196502,71195,98251,62654, 271694,102598,135847,84548, -3069401,-37,24 ],
  [ 1500,100,3,0,1, 48200,82514,16067,-11438, 85809,78109,28603,2567, 239781,107512,79927,44090, 294754,182855,98251,37300, 407542,322782,135847,28253, -2987252,-36,23 ],
  [ 1500,100,5,0,1, 67921,85504,14819,-3836, 135670,87401,27134,9654, 358000,217915,71600,28017, 485125,337824,97025,29460, 671037,399233,134207,54361, -2586898,-33,21 ],
  [ 1500,100,10,0,1, 108399,104980,13273,419, 283535,203445,23628,6674, 729659,411940,60805,26477, 1120135,583100,93345,44753, 1554239,909206,129520,53753, -1153339,-18,-34 ],
  [ 1500,100,15,0,1, 140107,109791,12454,2695, 464465,371270,21112,4236, 1216830,713864,55310,22862, 1982649,1096903,90120,40261, 2779449,1620271,126339,52690, 302500,11,0 ],
  [ 1500,100,25,0,1, 162651,113215,11829,3595, 834129,561773,17469,5704, 2463757,1270952,47380,22939, 4292155,2205657,82541,40125, 6280114,3477689,120771,53893, 5470915,54,0 ],
  [ 1500,100,50,0,1, 164550,113504,11754,3646, 1203084,857483,15165,4356, 4474599,2382464,40678,19019, 8155135,4264652,74138,35368, 12629695,6927362,114815,51839, 12495730,79,0 ],
  [ 1500,100,75,0,1, 164550,113504,11754,3646, 1209567,858818,15120,4384, 4536634,2392071,40506,19148, 8276893,4407640,73901,34547, 12839280,6977780,114636,52335, 12714349,79,0 ],
  [ 1500,200,1,0,1, 34489,80428,34489,-45939, 38784,55164,38784,-16380, 175286,97560,175286,77726, 119487,58472,119487,61015, 165355,68747,165355,96607, -3302758,-38,25 ],
  [ 1500,200,2,0,1, 50113,82795,25056,-16341, 72433,61316,36217,5559, 252545,109939,126272,71303, 238975,78326,119487,80324, 330709,196575,165355,67067, -2838900,-35,22 ],
  [ 1500,200,3,0,1, 65736,85161,21912,-6475, 106083,81353,35361,8243, 329803,213684,109934,38706, 358462,193514,119487,54982, 496064,353713,165355,47450, -2790149,-35,22 ],
  [ 1500,200,5,0,1, 89573,102109,19543,-2735, 166535,92468,33307,14813, 473403,357190,94681,23243, 589944,355930,117989,46803, 816740,440592,163348,75230, -2338442,-31,19 ],
  [ 1500,200,10,0,1, 138463,109518,16955,3544, 345734,319037,28811,2225, 924692,533313,77058,32615, 1361909,746481,113492,51286, 1891350,997648,157613,74475, -756184,-11,0 ],
  [ 1500,200,15,0,1, 176732,115320,15709,5459, 564899,390042,25677,7948, 1516050,867729,68911,29469, 2410230,1277528,109556,51486, 3381812,1869319,153719,68750, 1637692,28,0 ],
  [ 1500,200,25,0,1, 203906,119442,14830,6143, 1012256,714490,21199,6236, 3028722,1585413,58245,27756, 5216126,2809548,100310,46280, 7639021,4226486,146904,65626, 7174765,61,0 ],
  [ 1500,200,50,0,1, 206191,119789,14728,6172, 1458229,921611,18381,6764, 5466332,2885286,49694,23464, 9907013,5265731,90064,42193, 15358270,8295247,139621,64209, 16442203,88,0 ],
  [ 1500,200,75,0,1, 206191,119789,14728,6172, 1466054,937106,18326,6612, 5541495,2896770,49478,23614, 10054816,5289460,89775,42548, 15613013,8549970,139402,63063, 16747010,89,0 ],
  [ 2000,0,1,0,1, 13922,77404,13922,-63482, 28604,54311,28604,-25707, 62209,50122,62209,12087, 95243,54506,95243,40738, 131894,63081,131894,68813, -3604092,-41,27 ],
  [ 2000,0,2,0,1, 27845,79595,13922,-25875, 57209,59624,28604,-1207, 124418,74788,62209,24815, 190486,70595,95243,59946, 263789,101652,131894,81068, -3213018,-38,25 ],
  [ 2000,0,3,0,1, 41767,81785,13922,-13339, 85813,78821,28604,2331, 186627,84790,62209,33946, 285730,181918,95243,34604, 395683,321322,131894,24787, -3101848,-37,24 ],
  [ 2000,0,5,0,1, 63022,85129,13750,-4823, 137579,88639,27516,9788, 303020,195261,60604,21552, 470180,336343,94036,26767, 651586,396919,130317,50933, -2709385,-34,21 ],
  [ 2000,0,10,0,1, 106691,105340,13064,166, 292989,206742,24416,7187, 672680,390209,56057,23539, 1085451,579932,90454,42127, 1509790,904256,125816,50461, -1297683,-20,-42 ],
  [ 2000,0,15,0,1, 140937,110732,12528,2685, 485342,377652,22061,4895, 1161560,694167,52798,21245, 1923424,1091964,87428,37794, 2700043,1498696,122729,54607, 216437,10,0 ],
  [ 2000,0,25,0,1, 165325,114574,12024,3691, 884716,680431,18528,4278, 2433331,1273364,46795,22307, 4186923,2200018,80518,38210, 6094891,3458586,117209,50698, 4920002,51,0 ],
  [ 2000,0,50,0,1, 167385,114899,11956,3749, 1287338,894589,16227,4951, 4521848,2422561,41108,19084, 8023520,4282649,72941,34008, 12249708,6807990,111361,49470, 12260491,78,0 ],
  [ 2000,0,75,0,1, 167385,114899,11956,3749, 1294432,896086,16180,4979, 4587022,2447496,40956,19103, 8145562,4426173,72728,33209, 12453075,6941751,111188,49208, 12388243,78,0 ],
  [ 2000,25,1,0,1, 16956,77862,16956,-60906, 30722,54583,30722,-23861, 77880,67206,77880,10673, 100552,55444,100552,45108, 139271,64402,139271,74869, -3580293,-40,26 ],
  [ 2000,25,2,0,1, 31554,80154,15777,-24300, 60802,60165,30401,318, 143506,77905,71753,32800, 201104,72365,100552,64370, 278543,104189,139271,87177, -3132084,-37,24 ],
  [ 2000,25,3,0,1, 46151,82447,15384,-12098, 90882,79632,30294,3750, 209132,88454,69711,40226, 301657,184570,100552,39029, 417814,325125,139271,30896, -2997279,-36,23 ],
  [ 2000,25,5,0,1, 68435,85946,14931,-3821, 145296,89944,29059,11070, 331871,199938,66374,26386, 496384,340694,99277,31138, 688011,403166,137602,56969, -2561084,-33,20 ],
  [ 2000,25,10,0,1, 114207,106474,13985,947, 308539,313902,25712,-447, 721438,411063,60120,25865, 1145894,698416,95491,37290, 1594067,918193,132839,56323, -1533304,-22,-50 ],
  [ 2000,25,15,0,1, 150093,112114,13342,3376, 510451,382345,23202,5823, 1236365,720640,56198,23442, 2030319,1189888,92287,38201, 2850633,1653129,129574,54432, 434069,13,0 ],
  [ 2000,25,25,0,1, 175638,116131,12774,4328, 929247,702937,19461,4739, 2574572,1387108,49511,22836, 4417916,2441518,84960,38008, 6434618,3547325,123743,55525, 5251938,53,0 ],
  [ 2000,25,50,0,1, 177795,116470,12700,4380, 1351125,907150,17031,5596, 4769782,2490548,43362,20720, 8461490,4587499,76923,35218, 12931852,7068973,117562,53299, 13367828,81,0 ],
  [ 2000,25,75,0,1, 177795,116470,12700,4380, 1358554,922600,16982,5449, 4838237,2578326,43199,20178, 8590042,4608581,76697,35549, 13146508,7317596,117380,52044, 13432534,81,0 ],
  [ 2000,50,1,0,1, 19989,78320,19989,-58331, 32839,54854,32839,-22015, 93551,69776,93551,23774, 105861,56333,105861,49529, 146648,65673,146648,80975, -3522994,-40,26 ],
  [ 2000,50,2,0,1, 35262,80714,17631,-22726, 64394,60706,32197,1844, 162594,81022,81297,40786, 211722,74136,105861,68793, 293296,190506,146648,51395, -3110885,-37,24 ],
  [ 2000,50,3,0,1, 50535,83109,16845,-10858, 95950,80444,31983,5169, 231638,106633,77213,41668, 317584,187273,105861,43437, 439944,344649,146648,31765, -2937384,-36,23 ],
  [ 2000,50,5,0,1, 73848,86764,16112,-2818, 153012,91248,30602,12353, 360722,204616,72144,31221, 522589,345046,104518,35509, 724437,409413,144887,63005, -2412784,-31,19 ],
  [ 2000,50,10,0,1, 121723,107609,14905,1728, 324089,316713,27007,615, 770196,418903,64183,29274, 1206338,708390,100528,41496, 1678345,948252,139862,60841, -1259903,-19,-37 ],
  [ 2000,50,15,0,1, 159249,113496,14155,4067, 535559,387038,24344,6751, 1311170,732599,59599,26299, 2137215,1222430,97146,41581, 3001224,1694421,136419,59400, 862255,20,0 ],
  [ 2000,50,25,0,1, 185952,117688,13524,4965, 973779,711557,20393,5492, 2715813,1530052,52227,22803, 4648909,2509727,89402,41138, 6774345,3833485,130276,56555, 5674706,55,0 ],
  [ 2000,50,50,0,1, 188205,118042,13443,5012, 1414911,933596,17835,6067, 5017715,2726921,45616,20825, 8899459,4769362,80904,37546, 13613996,7511557,123764,55477, 14096190,82,0 ],
  [ 2000,50,75,0,1, 188205,118042,13443,5012, 1422675,935230,17783,6093, 5089453,2738356,45442,20992, 9034523,4914925,80665,36782, 13839941,7693991,123571,54875, 14269351,83,0 ],
  [ 2000,100,1,0,1, 26056,79236,26056,-53180, 37074,55397,37074,-18323, 124892,74916,124892,49977, 116479,58109,116479,58370, 161402,68214,161402,93187, -3408395,-39,26 ],
  [ 2000,100,2,0,1, 42680,81834,21340,-19577, 71580,75674,35790,-2047, 200771,101621,100385,49575, 232958,77726,116479,77616, 322804,195579,161402,63612, -3026100,-36,23 ],
  [ 2000,100,3,0,1, 59303,84432,19768,-8376, 106087,82066,35362,8007, 276649,113962,92216,54229, 349438,192578,116479,52287, 484205,352254,161402,43984, -2728248,-34,22 ],
  [ 2000,100,5,0,1, 84674,101734,18474,-3722, 168444,93707,33689,14947, 418423,334536,83685,16778, 574999,354449,115000,44110, 797289,438278,159458,71802, -2460928,-32,20 ],
  [ 2000,100,10,0,1, 136756,109878,16746,3291, 355188,336219,29599,1581, 867712,525947,72309,28480, 1327225,743363,110602,48655, 1846900,992698,153908,71184, -977612,-15,-21 ],
  [ 2000,100,15,0,1, 177561,116260,15783,5449, 585776,410309,26626,7976, 1460779,847881,66399,27859, 2351005,1272589,106864,49019, 3302405,1845114,150109,66241, 1438587,26,0 ],
  [ 2000,100,25,0,1, 206580,120801,15024,6238, 1062843,742683,22258,6705, 2998296,1603690,57660,26819, 5110894,2803959,98286,44364, 7453799,4207883,143342,62421, 6873389,60,0 ],
  [ 2000,100,50,0,1, 209025,121185,14930,6274, 1542483,1150803,19443,4937, 5513581,2923732,50123,23544, 9775398,5283728,88867,40833, 14978283,8259105,136166,61083, 15543244,86,0 ],
  [ 2000,100,75,0,1, 209025,121185,14930,6274, 1550919,1152574,19386,4979, 5591884,2936181,49928,23712, 9923485,5322920,88603,41076, 15226808,8316520,135954,61699, 16012264,87,0 ],
  [ 2000,200,1,0,1, 38190,81067,38190,-42877, 45543,56483,45543,-10940, 187576,99710,187576,87866, 137715,61713,137715,76002, 190909,73347,190909,117562, -3212698,-38,24 ],
  [ 2000,200,2,0,1, 57515,84073,28757,-13279, 85951,77839,42976,4056, 277124,114090,138562,81517, 275430,99785,137715,87823, 381818,318816,190909,31501, -2805101,-35,22 ],
  [ 2000,200,3,0,1, 76840,87079,25613,-3413, 126360,85310,42120,13683, 366672,220135,122224,48846, 413146,311697,137715,33816, 572728,367514,190909,68405, -2680046,-34,21 ],
  [ 2000,200,5,0,1, 106326,105003,23198,289, 199309,98925,39862,20077, 533827,367760,106765,33213, 679818,386881,135964,58587, 942992,562767,188598,76045, -1994239,-28,16 ],
  [ 2000,200,10,0,1, 166820,114416,20427,6417, 417387,347460,34782,5827, 1062745,571821,88562,40910, 1568999,878592,130750,57534, 2184011,1278511,182001,75458, -176155,1,0 ],
  [ 2000,200,15,0,1, 214186,121789,19039,8213, 686210,502931,31191,8331, 1759999,924745,80000,37966, 2778587,1561673,126299,55314, 3904768,2207253,177489,77160, 2475790,36,0 ],
  [ 2000,200,25,0,1, 247835,140366,18024,7816, 1240970,864749,25989,7879, 3563261,1919650,68524,31608, 6034865,3299540,116055,52602, 8812707,4828568,169475,76618, 8867266,67,0 ],
  [ 2000,200,50,0,1, 250666,140807,17905,7847, 1797628,1228666,22659,7172, 6505314,3426403,59139,27990, 11527275,6161521,104793,48780, 17706858,9710870,160971,72691, 19513884,94,0 ],
  [ 2000,200,75,0,1, 250666,140807,17905,7847, 1807405,1230712,22593,7209, 6596745,3546930,58900,27230, 11701408,6285698,104477,48355, 18000541,9905030,160719,72281, 19615156,94,0 ],
  [ 2500,0,1,0,1, 17624,78044,17624,-60420, 35363,55630,35363,-20267, 74498,52272,74498,22227, 113471,57746,113471,55725, 157449,67682,157449,89768, -3514033,-40,26 ],
  [ 2500,0,2,0,1, 35247,80873,17624,-22813, 70727,76147,35363,-2710, 148997,78938,74498,35029, 226942,77077,113471,74933, 314898,194583,157449,60158, -3136217,-37,24 ],
  [ 2500,0,3,0,1, 35247,80994,11749,-15249, 70727,76428,23576,-1900, 150214,79156,50071,23686, 226942,77082,75647,49953, 314898,194587,104966,40104, -3135332,-37,24 ],
  [ 2500,0,5,0,1, 79775,101360,17405,-4709, 170353,94946,34071,15081, 363444,205831,72689,31522, 560054,352467,112011,41517, 777837,435464,155567,68475, -2338037,-31,19 ],
  [ 2500,0,10,0,1, 135048,110238,16537,3038, 364643,339515,30387,2094, 810733,427216,67561,31960, 1292541,740196,107712,46029, 1802450,987748,150204,67892, -945459,-14,-20 ],
  [ 2500,0,15,0,1, 178391,117201,15857,5439, 606653,416690,27575,8635, 1405509,842698,63887,25582, 2291781,1267700,104172,46549, 3222999,1836680,146500,63014, 1238391,24,0 ],
  [ 2500,0,25,0,1, 209253,122161,15218,6334, 1113430,830841,23318,5918, 2967870,1607602,57074,26159, 5005662,2798319,96263,42449, 7268577,4060519,139780,61693, 6507122,59,0 ],
  [ 2500,0,50,0,1, 211860,135916,15133,5425, 1626737,1187759,20505,5533, 5560830,2963828,50553,23609, 9643783,5301874,87671,39472, 14598296,8223663,132712,57951, 15193579,85,0 ],
  [ 2500,0,75,0,1, 211860,135916,15133,5425, 1626737,1187759,20334,5487, 5615738,2987268,50141,23468, 9648419,5302759,86147,38801, 14605153,8224965,130403,56966, 15275086,85,0 ],
  [ 2500,25,1,0,1, 20657,78501,20657,-57844, 37481,55902,37481,-18421, 90169,69356,90169,20813, 118780,58685,118780,60095, 164826,69002,164826,95824, -3490234,-40,26 ],
  [ 2500,25,2,0,1, 38956,81433,19478,-21239, 74320,76688,37160,-1184, 168085,82055,84043,43015, 237560,78847,118780,79357, 329652,197119,164826,66266, -3055283,-37,24 ],
  [ 2500,25,3,0,1, 57255,84365,19085,-9037, 111159,83590,37053,9190, 246001,109419,82000,45527, 356340,194343,118780,53999, 494478,354647,164826,46610, -2771775,-35,22 ],
  [ 2500,25,5,0,1, 85188,102177,18586,-3707, 178069,96251,35614,16364, 392295,331074,78459,12244, 586258,372345,117252,42783, 814263,442261,162853,74401, -2490719,-32,20 ],
  [ 2500,25,10,0,1, 142564,111372,17457,3819, 380192,342326,31683,3156, 859491,512056,71624,28953, 1352984,750169,112749,50235, 1886728,1002136,157227,73716, -837382,-13,0 ],
  [ 2500,25,15,0,1, 187547,118583,16671,6130, 631762,421383,28716,9563, 1480313,854657,67287,28439, 2398676,1300242,109031,49929, 3373590,1877972,153345,67983, 1666577,28,0 ],
  [ 2500,25,25,0,1, 219567,137054,15969,6001, 1157961,853196,24250,6383, 3109111,1721346,59791,26688, 5236655,2866178,100705,45586, 7608304,4262498,146314,64342, 6960228,60,0 ],
  [ 2500,25,50,0,1, 222270,137488,15876,6056, 1690523,1214205,21309,6004, 5808764,3137716,52807,24282, 10081752,5483788,91652,41800, 15280440,8582516,138913,60890, 16124673,87,0 ],
  [ 2500,25,75,0,1, 222270,137488,15876,6056, 1699905,1216206,21249,6046, 5893487,3228486,52620,23795, 10236634,5524511,91399,42073, 15534036,8656986,138697,61402, 16430378,88,0 ],
  [ 2500,50,1,0,1, 23690,78959,23690,-55269, 39598,56173,39598,-16575, 105840,71926,105840,33914, 124089,59573,124089,64516, 172203,70273,172203,101930, -3432935,-39,26 ],
  [ 2500,50,2,0,1, 42665,81993,21332,-19664, 77913,77229,38956,342, 187173,85172,93587,51000, 248178,95594,124089,76292, 344405,199656,172203,72375, -2996453,-36,23 ],
  [ 2500,50,3,0,1, 61639,85027,20546,-7796, 116227,84401,38742,10609, 268507,113084,89502,51808, 372267,305455,124089,22271, 516608,358449,172203,52720, -2827281,-35,22 ],
  [ 2500,50,5,0,1, 90601,102994,19767,-2704, 185785,97555,37157,17646, 421146,335751,84229,17079, 612463,376697,122493,47153, 850689,532288,170138,63680, -2402153,-31,19 ],
  [ 2500,50,10,0,1, 150080,112507,18377,4601, 395742,345136,32979,4217, 908249,534411,75687,31153, 1413428,855477,117786,46496, 1971006,1145335,164250,68806, -818741,-12,0 ],
  [ 2500,50,15,0,1, 196703,119965,17485,6821, 656870,426076,29858,10491, 1555118,881130,70687,30636, 2505571,1317858,113890,53987, 3524180,2032405,160190,67808, 2002734,32,0 ],
  [ 2500,50,25,0,1, 229881,138611,16719,6638, 1202493,861816,25183,7135, 3250352,1743725,62507,28974, 5467648,2999168,105147,47471, 7948030,4435017,152847,67558, 7646085,63,0 ],
  [ 2500,50,50,0,1, 232680,139059,16620,6687, 1754309,1226766,22113,6650, 6056697,3268189,55061,25350, 10519722,5788588,95634,43010, 15962583,8843450,145114,64719, 17089012,89,0 ],
  [ 2500,50,75,0,1, 232680,139059,16620,6687, 1764027,1228836,22050,6690, 6144703,3296981,54863,25426, 10681115,5830406,95367,43310, 16227470,9032931,144888,64237, 17479073,90,0 ],
  [ 2500,100,1,0,1, 29757,79875,29757,-50118, 43833,56716,43833,-12883, 137182,77066,137182,60116, 134707,61350,134707,73357, 186956,72815,186956,114142, -3318336,-39,25 ],
  [ 2500,100,2,0,1, 50082,83113,25041,-16515, 85098,78312,42549,3393, 225350,105922,112675,59714, 269414,99185,134707,85115, 373913,204779,186956,84567, -2868086,-35,22 ],
  [ 2500,100,3,0,1, 70407,86350,23469,-5314, 126364,86023,42121,13447, 313518,197413,104506,38702, 404121,310760,134707,31120, 560869,366054,186956,64938, -2794642,-35,22 ],
  [ 2500,100,5,0,1, 101427,104628,22130,-698, 201218,100164,40244,20211, 478847,345106,95769,26748, 664873,385400,132975,55895, 923541,560453,184708,72618, -2116726,-29,17 ],
  [ 2500,100,10,0,1, 165113,114776,20218,6164, 426842,350757,35570,6340, 1005766,564605,83814,36763, 1534314,875474,127860,54903, 2139561,1273611,178297,72162, -354000,-4,0 ],
  [ 2500,100,15,0,1, 215015,122729,19112,8203, 707087,523197,32140,8359, 1704728,919413,77488,35696, 2719362,1556784,123607,52844, 3825362,2198769,173880,73936, 2232011,34,0 ],
  [ 2500,100,25,0,1, 250508,141725,18219,7912, 1291557,892942,27048,8348, 3532835,1923413,67939,30950, 5929633,3308877,114031,50399, 8627484,4809915,165913,73415, 8577287,66,0 ],
  [ 2500,100,50,0,1, 253501,142202,18107,7950, 1881882,1265772,23721,7766, 6552563,3572550,59569,27091, 11395660,6180017,103597,47415, 17326871,9591998,157517,70317, 19033268,93,0 ],
  [ 2500,100,75,0,1, 253501,142202,18107,7950, 1892270,1267980,23653,7804, 6647133,3602356,59349,27186, 11570077,6304232,103304,47016, 17614336,9853330,157271,69295, 19300223,94,0 ],
  [ 2500,200,1,0,1, 41891,81707,41891,-39816, 52302,57802,52302,-5500, 199865,101860,199865,98005, 155943,64954,155943,90989, 216464,77948,216464,138516, -3122639,-37,24 ],
  [ 2500,200,2,0,1, 64917,85352,32459,-10218, 99470,80477,49735,9496, 301703,132905,150852,84399, 311886,186624,155943,62631, 432928,343738,216464,44595, -2788180,-35,22 ],
  [ 2500,200,3,0,1, 87943,102334,29314,-4797, 146637,89118,48879,19173, 403541,332635,134514,23635, 467829,337046,155943,43594, 649391,397635,216464,83919, -2743216,-34,22 ],
  [ 2500,200,5,0,1, 123079,107897,26854,3313, 232083,119267,46417,22563, 594251,378181,118850,43214, 769692,417832,153938,70372, 1069244,585441,213849,96761, -1622713,-24,12 ],
  [ 2500,200,10,0,1, 195177,119314,23899,9289, 489040,375884,40753,9430, 1200798,716378,100067,40368, 1776088,945871,148007,69185, 2476672,1363004,206389,92806, 395283,13,0 ],
  [ 2500,200,15,0,1, 251639,141594,22368,9782, 807521,541820,36705,12077, 2003948,1179327,91089,37483, 3146944,1753035,143043,63359, 4427724,2545887,201260,85538, 3175570,41,0 ],
  [ 2500,200,25,0,1, 251639,141594,18301,8003, 807521,541820,16911,5564, 3497946,1917709,67268,30389, 3238850,1815961,62286,27363, 4569611,2651591,87877,36885, 4972674,51,0 ],
  [ 2500,200,50,0,1, 295142,220188,21082,5354, 2137026,1521986,26937,7753, 7544296,4073721,68585,31551, 13147538,7072637,119523,55226, 20055445,11156204,182322,80902, 22101479,99,0 ],
  [ 2500,200,75,0,1, 295142,220188,21082,5354, 2148757,1524468,26859,7804, 7651995,4091040,68321,31794, 13348000,7200979,119179,54884, 20388069,11327550,182036,80897, 22559353,100,0 ],
  [ 3000,0,1,0,1, 21325,78683,21325,-57358, 42123,56949,42123,-14827, 86788,68937,86788,17851, 131699,61037,131699,70662, 183004,72332,183004,110672, -3457474,-40,26 ],
  [ 3000,0,2,0,1, 42649,82152,21325,-19751, 84245,78785,42123,2730, 173576,83239,86788,45169, 263398,98535,131699,82432, 366007,203783,183004,81112, -2978202,-36,23 ],
  [ 3000,0,3,0,1, 63974,85621,21325,-7216, 126368,86735,42123,13211, 260364,112205,86788,49386, 395097,309873,131699,28408, 549011,364645,183004,61455, -2766240,-34,22 ],
  [ 3000,0,5,0,1, 96528,104254,21061,-1686, 203127,115288,40625,17568, 423868,336817,84774,17410, 649928,383969,129986,53192, 904089,542468,180818,72324, -2305123,-31,19 ],
  [ 3000,0,10,0,1, 163405,115136,20009,5911, 436296,354054,36358,6854, 948787,542874,79066,33826, 1499630,872307,124969,52277, 2095111,1268661,174593,68871, -498345,-7,0 ],
  [ 3000,0,15,0,1, 215845,137006,19186,7008, 727964,529429,33089,9024, 1649457,899715,74975,34079, 2660137,1551995,120915,50370, 3745955,2190435,170271,70705, 2010622,32,0 ],
  [ 3000,0,25,0,1, 253182,143084,18413,8007, 1342143,907250,28108,9108, 3502409,1927325,67354,30290, 5824402,3222930,112008,50028, 8442262,4791313,162351,70211, 8471519,66,0 ],
  [ 3000,0,50,0,1, 256335,143597,18310,8053, 1966135,1376729,24783,7429, 6599812,3612646,59998,27156, 11264046,6198014,102400,46055, 16946884,9556406,154063,67186, 18504514,92,0 ],
  [ 3000,0,75,0,1, 256335,143597,18310,8053, 1977135,1379098,24714,7475, 6697522,3643266,59799,27270, 11438746,6322715,102132,45679, 17228131,9620380,153823,67926, 18914202,93,0 ],
  [ 3000,25,1,0,1, 24358,79141,24358,-54783, 44240,57221,44240,-12981, 102459,71507,102459,30952, 137008,61926,137008,75082, 190380,73602,190380,116778, -3400175,-39,26 ],
  [ 3000,25,2,0,1, 46358,82712,23179,-18177, 87838,79326,43919,4256, 192664,86356,96332,53154, 274016,100305,137008,86855, 380761,319410,190380,30675, -2977900,-36,23 ],
  [ 3000,25,3,0,1, 68358,86283,22786,-5975, 131436,87547,43812,14630, 282870,192870,94290,30000, 411024,327502,137008,27841, 571141,368447,190380,67565, -2860273,-35,22 ],
  [ 3000,25,5,0,1, 101941,105071,22242,-683, 210843,116592,42169,18850, 452719,341494,90544,22245, 676132,388320,135226,57562, 940515,564386,188103,75226, -2167996,-29,18 ],
  [ 3000,25,10,0,1, 170921,116270,20929,6692, 451846,370749,37654,6758, 997545,550564,83129,37248, 1560074,897257,130006,55235, 2179389,1282999,181616,74699, -279457,-2,0 ],
  [ 3000,25,15,0,1, 170921,116270,15193,4858, 451846,370749,20538,3686, 1505525,861051,68433,29294, 1594816,919138,72492,30713, 2229405,1324120,101337,41149, 196997,9,0 ],
  [ 3000,25,25,0,1, 263496,144641,19163,8644, 1386675,929756,29040,9569, 3643650,2041069,70070,30819, 6055394,3355970,116450,51912, 8781989,4963832,168884,73426, 8903795,67,0 ],
  [ 3000,25,50,0,1, 266745,216869,19053,3563, 2029922,1507525,25587,6585, 6847746,3755984,62252,28107, 11702015,6394404,106382,48251, 17629027,9914259,160264,70134, 18861780,93,0 ],
  [ 3000,25,75,0,1, 266745,216869,19053,3563, 2041257,1509962,25516,6641, 6948737,3772596,62042,28358, 11883226,6548252,106100,47634, 17921564,10079506,160014,70018, 19214501,94,0 ],
  [ 3000,50,1,0,1, 27392,79599,27392,-52207, 46357,57492,46357,-11135, 118130,74076,118130,44053, 142317,62814,142317,79503, 197757,74873,197757,122884, -3342875,-39,25 ],
  [ 3000,50,2,0,1, 50067,83272,25033,-16602, 91431,79868,45715,5782, 211753,103988,105876,53882, 284634,182433,142317,51101, 395515,321997,197757,36759, -3048991,-37,23 ],
  [ 3000,50,3,0,1, 72742,86945,24247,-4734, 136504,88358,45501,16049, 305376,196534,101792,36281, 426951,330155,142317,32266, 593272,387921,197757,68450, -2766878,-35,22 ],
  [ 3000,50,5,0,1, 107354,105888,23423,320, 218559,117897,43712,20132, 481569,346171,96314,27080, 702337,392672,140467,61933, 976941,570633,195388,81262, -2019696,-28,16 ],
  [ 3000,50,10,0,1, 178437,117405,21849,7473, 467396,373560,38950,7820, 1046303,572918,87192,39449, 1620517,907280,135043,59436, 2263667,1313108,188639,79213, -39556,4,0 ],
  [ 3000,50,15,0,1, 234157,139771,20814,8390, 778181,538815,35372,10880, 1799067,952662,81776,38473, 2873928,1617180,130633,57125, 4047137,2257448,183961,81349, 2811166,38,0 ],
  [ 3000,50,25,0,1, 273810,217898,19913,4066, 1431207,938376,29973,10321, 3784891,2077962,72786,32826, 6286387,3408702,120892,55340, 9121716,5165711,175418,76077, 9359795,69,0 ],
  [ 3000,50,50,0,1, 277156,218440,19797,4194, 2093708,1520086,26391,7231, 7095679,3915507,64506,28911, 12139984,6684727,110363,49593, 18311171,10288783,166465,72931, 19698710,94,0 ],
  [ 3000,50,75,0,1, 277156,218440,19797,4194, 2105378,1522592,26317,7285, 7199952,3947141,64285,29043, 12327707,6731210,110069,49969, 18614998,10455950,166205,72849, 20199995,95,0 ],
  [ 3000,100,1,0,1, 33458,80514,33458,-47056, 50592,58035,50592,-7443, 149471,79216,149471,70256, 152935,64591,152935,88344, 212511,77415,212511,135096, -3228277,-38,25 ],
  [ 3000,100,2,0,1, 57484,84391,28742,-13454, 98616,80950,49308,8833, 249929,110222,124965,69854, 305870,185974,152935,59948, 425022,342741,212511,41140, -2898297,-36,23 ],
  [ 3000,100,3,0,1, 81510,101605,27170,-6698, 146641,89830,48880,18937, 350387,218378,116796,44003, 458805,335659,152935,41049, 637533,395726,212511,80602, -2645934,-33,21 ],
  [ 3000,100,5,0,1, 118180,107522,25785,2325, 233992,194356,46798,7927, 539271,370041,107854,33846, 754747,416401,150949,67669, 1049792,583177,209958,93323, -2012480,-28,16 ],
  [ 3000,100,10,0,1, 193470,119674,23690,9036, 498495,379181,41541,9943, 1143819,709162,95318,36221, 1741404,942754,145117,66554, 2432222,1358104,202685,89510, 217438,9,0 ],
  [ 3000,100,15,0,1, 252469,142535,22442,9772, 828398,562086,37654,12105, 1948677,1173994,88576,35213, 3087719,1748146,140351,60890, 4348318,2537403,197651,82314, 2931791,39,0 ],
  [ 3000,100,25,0,1, 294437,221012,21414,5340, 1520271,1147702,31838,7802, 4067374,2150100,78219,36871, 6748372,3717911,129776,58278, 9801170,5523938,188484,82254, 9987145,71,0 ],
  [ 3000,100,50,0,1, 297976,221583,21284,5457, 2221280,1559092,27999,8347, 7591545,4113817,69014,31616, 13015923,7090633,118327,53866, 19675458,11007521,178868,78799, 21887138,98,0 ],
  [ 3000,100,75,0,1, 297976,221583,21284,5457, 2233622,1561736,27920,8399, 7702383,4146466,68771,31749, 13216669,7219512,118006,53546, 20001864,11207740,178588,78519, 22292981,99,0 ],
  [ 3000,200,1,0,1, 45592,82346,45592,-36754, 59061,59121,59061,-60, 212155,104010,212155,108145, 174171,68195,174171,105976, 242018,82548,242018,159471, -3032580,-36,23 ],
  [ 3000,200,2,0,1, 72319,86631,36160,-7156, 112988,83115,56494,14936, 326282,214205,163141,56039, 348342,193106,174171,77618, 484037,352938,242018,65549, -2784559,-35,22 ],
  [ 3000,200,3,0,1, 99046,104252,33015,-1735, 166914,93075,55638,24613, 440410,339085,146803,33775, 522513,346769,174171,58582, 726055,411436,242018,104873, -2473038,-32,20 ],
  [ 3000,200,5,0,1, 139832,110791,30509,6336, 264856,199573,52971,13057, 654675,403266,130935,50282, 859566,514165,171913,69080, 1195496,736976,239099,91704, -1663110,-24,13 ],
  [ 3000,200,10,0,1, 223534,124212,27372,12162, 560694,390423,46724,14189, 1338852,755036,111571,48651, 1983178,1186442,165265,66395, 2769332,1643917,230778,93785, 858821,19,0 ],
  [ 3000,200,15,0,1, 289093,219763,25697,6163, 928831,685208,42220,11074, 2247896,1249358,102177,45388, 3515300,1956923,159786,70835, 4950680,2785951,225031,98397, 3781326,45,0 ],
  [ 3000,200,25,0,1, 335692,328106,24414,552, 1698398,1194568,35569,10551, 4632339,2466061,89083,41659, 7672343,4214842,147545,66490, 11160077,6245425,214617,94513, 11784914,76,0 ],
  [ 3000,200,50,0,1, 339617,328736,24258,777, 2476425,1709455,31215,9668, 8583278,4616489,78030,36062, 14767801,7984753,134253,61664, 22404033,12489946,203673,90128, 25222015,104,0 ],
  [ 3000,200,75,0,1, 339617,328736,24258,777, 2490108,1712374,31126,9722, 8707245,4742700,77743,35398, 14994592,8226719,133880,60427, 22775597,12668789,203354,90239, 25364764,104,0 ],
  [ 1000,0,1,1,1, 11591,76835,11591,-65243, 23984,53178,23984,-29194, 54187,48397,54187,5789, 80016,36506,80016,43510, 105515,58065,105515,47450, -3647914,-41,27 ],
  [ 1000,0,2,1,1, 23183,78455,11591,-27636, 47969,57358,23984,-4695, 108373,71340,54187,18517, 160032,64548,80016,47742, 211031,75948,105515,67541, -3333695,-39,25 ],
  [ 1000,0,3,1,1, 34774,80076,11591,-15101, 71953,75423,23984,-1157, 162560,79617,54187,27648, 240048,77564,80016,54161, 316546,193183,105515,41121, -3078363,-37,24 ],
  [ 1000,0,5,1,1, 52473,82552,11449,-6563, 114727,83015,22945,6343, 262910,109653,52582,30651, 396063,306578,79213,17897, 521998,356555,104400,33089, -2821321,-35,22 ],
  [ 1000,0,10,1,1, 88848,100983,10879,-1486, 239895,119677,19991,10018, 576421,355124,48035,18441, 920608,516568,76717,33670, 1214416,732449,101201,40164, -1623300,-24,12 ],
  [ 1000,0,15,1,1, 117385,104983,10434,1102, 391250,340172,17784,2322, 985093,536158,44777,20406, 1634689,879093,74304,34345, 2180331,1251552,99106,42217, -382910,-4,0 ],
  [ 1000,0,25,1,1, 137719,107839,10016,2173, 695805,426669,14572,5636, 2020845,961037,38862,20381, 3543468,1803138,68144,33468, 4974918,2636841,95671,44963, 4235424,47,0 ],
  [ 1000,0,50,1,1, 139438,108082,9960,2240, 997582,705483,12575,3682, 3673787,1775558,33398,17257, 6714171,3402776,61038,30104, 10103948,5342647,91854,43285, 10278823,72,0 ],
  [ 1000,0,75,1,1, 139438,108082,9960,2240, 1002895,706524,12536,3705, 3724455,1903469,33254,16259, 6813545,3418659,60835,30311, 10274101,5499435,91733,42631, 10246729,72,0 ],
  [ 1000,25,1,1,1, 15936,77490,15936,-61554, 27185,53602,27185,-26417, 73812,66128,73812,7684, 87242,37765,87242,49477, 115151,59776,115151,55375, -3605336,-41,27 ],
  [ 1000,25,2,1,1, 28728,79292,14364,-25282, 53515,58204,26757,-2345, 132920,75343,66460,28788, 174484,66959,87242,53763, 230301,79263,115151,75519, -3223298,-38,25 ],
  [ 1000,25,3,1,1, 41520,81095,13840,-13191, 79844,76690,26615,1051, 192027,84409,64009,35873, 261727,81176,87242,60184, 345452,198152,115151,49100, -2933648,-36,23 ],
  [ 1000,25,5,1,1, 61048,83846,13320,-4974, 126849,85060,25370,8358, 301570,192913,60314,21731, 431800,312515,86360,23857, 569643,364729,113929,40983, -2789710,-35,22 ],
  [ 1000,25,10,1,1, 101162,102841,12387,-206, 264654,197982,22055,5556, 644208,380525,53684,21974, 1003508,545277,83626,38186, 1325129,767074,110427,46505, -1518721,-22,-54 ],
  [ 1000,25,15,1,1, 132614,107282,11788,2252, 431576,347678,19617,3814, 1091318,553121,49605,24463, 1781951,918273,80998,39258, 2378830,1300999,108129,48992, 244331,10,0 ],
  [ 1000,25,25,1,1, 155006,110449,11273,3241, 768201,528386,16088,5022, 2226787,1191052,42823,19918, 3864605,2074828,74319,34419, 5425907,2941745,104344,47772, 4323351,48,0 ],
  [ 1000,25,50,1,1, 156897,110717,11207,3299, 1102038,739910,13891,4565, 4043273,2045491,36757,18162, 7329291,3736848,66630,32659, 11016549,5968347,100150,45893, 11407319,75,0 ],
  [ 1000,25,75,1,1, 156897,110717,11207,3299, 1107910,741066,13849,4586, 4099044,2068140,36599,18133, 7437997,3849012,66411,32045, 11202013,6014082,100018,46321, 11592574,76,0 ],
  [ 1000,50,1,1,1, 20281,78146,20281,-57865, 30386,54026,30386,-23640, 93437,69343,93437,24094, 94468,53952,94468,40516, 124786,61436,124786,63349, -3551362,-40,26 ],
  [ 1000,50,2,1,1, 34273,80130,17137,-22928, 59060,59049,29530,5, 157466,79347,78733,39059, 188937,69370,94468,59783, 249571,82578,124786,83497, -3112902,-37,24 ],
  [ 1000,50,3,1,1, 48266,82113,16089,-11282, 87735,77958,29245,3259, 221495,103716,73832,39260, 283405,99815,94468,61197, 374357,203171,124786,57062, -2844536,-35,22 ],
  [ 1000,50,5,1,1, 69623,85141,15190,-3386, 138972,87105,27794,10373, 340231,199172,68046,28212, 467536,333429,93507,26821, 617289,388574,123458,45743, -2614878,-33,21 ],
  [ 1000,50,10,1,1, 113476,104700,13895,1075, 289413,202437,24118,7248, 711995,391411,59333,26715, 1086408,558960,90534,43954, 1435842,785979,119653,54155, -1113584,-17,-33 ],
  [ 1000,50,15,1,1, 147844,109581,13142,3401, 471901,355184,21450,5305, 1197542,690650,54434,23041, 1929214,958003,87692,44146, 2577328,1366667,117151,55030, 581522,16,0 ],
  [ 1000,50,25,1,1, 172294,113059,12530,4308, 840598,542367,17604,6246, 2432730,1238168,46783,22972, 4185742,2142273,80495,39297, 5876897,3148781,113017,52464, 5480678,54,0 ],
  [ 1000,50,50,1,1, 174356,113353,12454,4357, 1206495,848187,15208,4516, 4412760,2132374,40116,20731, 7944411,4070421,72222,35218, 11929151,6396677,108447,50295, 12864276,79,0 ],
  [ 1000,50,75,1,1, 174356,113353,12454,4357, 1212925,849457,15162,4543, 4473633,2155810,39943,20695, 8062449,4184080,71986,34628, 12129926,6444948,108303,50759, 13081499,80,0 ],
  [ 1000,100,1,1,1, 28970,79458,28970,-50488, 36788,54874,36788,-18086, 132687,75774,132687,56913, 108921,56372,108921,52549, 144056,64757,144056,79298, -3399207,-39,25 ],
  [ 1000,100,2,1,1, 45364,81804,22682,-18220, 70152,60741,35076,4706, 206558,101869,103279,52344, 217841,74242,108921,71799, 288111,188708,144056,49702, -2996517,-36,23 ],
  [ 1000,100,3,1,1, 60145,84132,20048,-7996, 104548,80432,34849,8039, 248539,101930,82846,48869, 278431,74335,92810,68032, 366596,193719,122199,57626, -2661231,-34,21 ],
  [ 1000,100,5,1,1, 86773,101066,18932,-3118, 163216,91045,32643,14434, 417552,332257,83510,17059, 539008,346004,107802,38601, 712579,405622,142516,61391, -2532232,-33,20 ],
  [ 1000,100,10,1,1, 138104,108418,16911,3635, 338932,315696,28244,1936, 847568,426198,70631,35114, 1252208,709813,104351,45200, 1657268,922789,138106,61207, -916517,-14,-19 ],
  [ 1000,100,15,1,1, 178303,114179,15849,5700, 552551,384081,25116,7658, 1409991,753457,64091,29842, 2223738,1225230,101079,45387, 2974325,1662481,135197,59629, 1306457,25,0 ],
  [ 1000,100,25,1,1, 206869,118278,15045,6443, 985390,688565,20636,6216, 2844614,1528313,54704,25313, 4828015,2496435,92846,44838, 6778875,3660271,130363,59973, 6577489,59,0 ],
  [ 1000,100,50,1,1, 209274,118624,14948,6475, 1415407,903156,17841,6457, 5151733,2580855,46834,23372, 9174652,4724939,83406,40452, 13754355,7337716,125040,58333, 15617173,86,0 ],
  [ 1000,100,75,1,1, 209274,118624,14948,6475, 1422002,904520,17775,6469, 5187259,2590444,46315,23186, 9252877,4729939,82615,40383, 13884365,7337716,123968,58452, 15893951,87,0 ],
  [ 1000,200,1,1,1, 46349,82081,46349,-35732, 49591,56570,49591,-6979, 211188,103151,211188,108037, 137825,61261,137825,76564, 182596,71450,182596,111146, -3128396,-37,24 ],
  [ 1000,200,2,1,1, 67545,85152,33772,-8804, 92335,78008,46168,7164, 304744,132249,152372,86247, 275650,98913,137825,88369, 365192,201967,182596,81613, -2654118,-34,21 ],
  [ 1000,200,3,1,1, 88741,101559,29580,-4273, 135080,85412,45027,16556, 398299,330033,132766,22755, 413475,310956,137825,34173, 547788,362444,182596,61781, -2856718,-35,22 ],
  [ 1000,200,5,1,1, 121074,106244,26416,3236, 211704,99226,42341,22496, 572194,371811,114439,40077, 681952,384780,136390,59434, 903160,537819,180632,73068, -1826310,-26,14 ],
  [ 1000,200,10,1,1, 187359,115853,22942,8756, 437970,347400,36498,7547, 1118714,575621,93226,45258, 1583808,874906,131984,59075, 2100121,1242340,175010,71482, 80332,7,0 ],
  [ 1000,200,15,1,1, 239220,123375,21264,10297, 713853,501841,32448,9637, 1834889,927191,83404,41259, 2812788,1555841,127854,57134, 3768318,2140969,171287,73970, 2837966,38,0 ],
  [ 1000,200,25,1,1, 276019,142053,20074,9743, 1274975,845961,26701,8985, 3668383,1914038,70546,33737, 6112563,3269689,117549,54671, 8582833,4713161,165054,74417, 9473935,69,0 ],
  [ 1000,200,50,1,1, 279110,142502,19936,9758, 1833231,1205029,23108,7919, 6629679,3293137,60270,30332, 11635133,6090330,105774,50407, 17404762,9416167,158225,72624, 20660818,96,0 ],
  [ 1000,200,75,1,1, 279110,142502,19936,9758, 1836380,1206810,22955,7870, 6696044,3293137,59786,30383, 11650555,6090330,104023,49645, 17432032,9416167,155643,71570, 20859128,96,0 ],
  [ 1500,0,1,1,1, 18171,77971,18171,-59800, 34731,55240,34731,-20509, 71884,51494,71884,20389, 104826,55898,104826,48928, 138893,64078,138893,74815, -3537160,-40,26 ],
  [ 1500,0,2,1,1, 36342,80729,18171,-22193, 69461,75366,34731,-2953, 143767,77384,71884,33192, 209653,73380,104826,68136, 277786,103595,138893,87096, -3122738,-37,24 ],
  [ 1500,0,3,1,1, 54514,83486,18171,-9657, 104192,81608,34731,7528, 215651,88908,71884,42248, 314479,186096,104826,42794, 416679,324311,138893,30789, -2901052,-36,23 ],
  [ 1500,0,5,1,1, 82256,101033,17947,-4097, 166967,92999,33393,14794, 350166,201921,70033,29649, 518638,343513,103728,35025, 687147,402119,137429,57006, -2441341,-32,20 ],
  [ 1500,0,10,1,1, 139261,109690,17052,3621, 355032,334876,29586,1680, 777479,417672,64790,29984, 1204745,705576,100395,41597, 1598951,916331,133246,56885, -1168664,-18,-34 ],
  [ 1500,0,15,1,1, 139261,109690,12379,2628, 587492,334876,26704,11483, 1342721,417672,61033,42048, 2142413,705576,54761,22689, 2869784,1650716,72680,-2353, 2627466,37,0 ],
  [ 1500,0,25,1,1, 215815,121327,15696,6872, 1069683,739844,22402,6908, 2815643,1536539,54147,24598, 4682968,2488875,90057,42194, 6533226,3551794,125639,57335, 6320102,58,0 ],
  [ 1500,0,50,1,1, 218505,121737,15608,6912, 1555795,1147484,19611,5147, 5244396,2756597,47676,22616, 8994353,4760592,81767,38489, 13246987,7290410,120427,54151, 14474436,83,0 ],
  [ 1500,0,75,1,1, 218505,121737,15608,6912, 1564363,1149252,19555,5189, 5320437,2768376,47504,22786, 9131534,4891082,81532,37861, 13469988,7343053,120268,54705, 14774601,84,0 ],
  [ 1500,25,1,1,1, 22516,78627,22516,-56111, 37932,55664,37932,-17732, 91509,69225,91509,22284, 112052,57158,112052,54894, 148528,65788,148528,82740, -3494583,-40,26 ],
  [ 1500,25,2,1,1, 41888,81566,20944,-19839, 75007,76212,37504,-602, 168314,81388,84157,43463, 224105,75791,112052,74157, 297056,190690,148528,53183, -3072076,-37,24 ],
  [ 1500,25,3,1,1, 61259,84504,20420,-7748, 112083,82875,37361,9736, 245119,108215,81706,45634, 336157,189758,112052,48800, 445584,345001,148528,33528, -2801010,-35,22 ],
  [ 1500,25,5,1,1, 90831,102328,19818,-2508, 179089,95044,35818,16809, 388826,314231,77765,14919, 554374,349951,110875,40885, 734792,410793,146958,64800, -2478610,-32,20 ],
  [ 1500,25,10,1,1, 151575,111549,18560,4901, 379792,339331,31649,3372, 845266,428558,70439,34726, 1287645,734236,107304,46117, 1709664,950906,142472,63230, -796804,-12,0 ],
  [ 1500,25,15,1,1, 199199,118783,17707,7148, 627817,415832,28537,9636, 1448945,841952,65861,27591, 2289676,1242776,104076,47586, 3068282,1700809,139467,62158, 1508411,27,0 ],
  [ 1500,25,25,1,1, 233102,137273,16953,6969, 1142079,827525,23918,6588, 3021585,1583654,58107,27653, 5004105,2651755,96233,45238, 6984215,3856399,134312,60150, 6978869,60,0 ],
  [ 1500,25,50,1,1, 235964,137709,16855,7018, 1660251,1181761,20928,6031, 5613882,2920480,51035,24485, 9609473,5094314,87359,41047, 14159589,7718890,128724,58552, 15933983,87,0 ],
  [ 1500,25,75,1,1, 235964,137709,16855,7018, 1669378,1183643,20867,6072, 5695026,2933047,50848,24661, 9755985,5132817,87107,41278, 14397901,7857850,128553,58393, 16344352,88,0 ],
  [ 1500,50,1,1,1, 26861,79283,26861,-52422, 41132,56088,41132,-14956, 111134,72440,111134,38694, 119279,58368,119279,60911, 158163,67449,158163,90714, -3418505,-39,26 ],
  [ 1500,50,2,1,1, 47433,82403,23716,-17485, 80553,77058,40277,1748, 192860,85391,96430,53734, 238557,78202,119279,80178, 316326,194004,158163,61161, -2961679,-36,23 ],
  [ 1500,50,3,1,1, 68005,85523,22668,-5839, 119974,84142,39991,11944, 274586,113007,91529,53860, 357836,193370,119279,54822, 474489,349970,158163,41506, -2656295,-34,21 ],
  [ 1500,50,5,1,1, 99406,103622,21689,-920, 191211,97089,38242,18824, 427487,335006,85497,18496, 590110,355938,118022,46834, 782437,434688,156487,69550, -2315175,-31,19 ],
  [ 1500,50,10,1,1, 163889,113408,20068,6181, 404551,343785,33713,5064, 913053,530959,76088,31841, 1370545,747969,114212,51881, 1820378,985532,151698,69570, -612838,-9,0 ],
  [ 1500,50,15,1,1, 214428,121082,19060,8298, 668142,423338,30370,11127, 1555170,873431,70690,30988, 2436938,1282006,110770,52497, 3266780,1834086,148490,65122, 2042418,32,0 ],
  [ 1500,50,25,1,1, 250390,139883,18210,8037, 1214475,855392,25434,7520, 3227527,1722134,62068,28950, 5325242,2842687,102409,47741, 7435204,4063434,142985,64842, 7700437,63,0 ],
  [ 1500,50,50,1,1, 253423,140344,18102,8077, 1764707,1216188,22244,6914, 5983369,3113413,54394,26091, 10224594,5428386,92951,43602, 15072191,8231500,137020,62188, 17319609,90,0 ],
  [ 1500,50,75,1,1, 253423,140344,18102,8077, 1774393,1218185,22180,6953, 6069614,3218282,54193,25458, 10380437,5468436,92682,43857, 15325813,8289266,136838,62826, 17611682,90,0 ],
  [ 1500,100,1,1,1, 35550,80595,35550,-45044, 47534,56936,47534,-9402, 150384,78871,150384,71513, 133731,60788,133731,72943, 177433,70770,177433,106663, -3266350,-38,25 ],
  [ 1500,100,2,1,1, 58523,84077,29262,-12777, 91645,78749,45822,6448, 241953,107914,120976,67019, 267462,98051,133731,84705, 354866,200683,177433,77091, -2796490,-35,22 ],
  [ 1500,100,3,1,1, 81497,100896,27166,-6466, 135756,86527,45252,16410, 333521,199591,111174,44643, 401193,309205,133731,30663, 532299,360057,177433,57414, -2758130,-34,22 ],
  [ 1500,100,5,1,1, 116556,106211,25430,2257, 215455,115065,43091,20078, 504808,347376,100962,31487, 661582,382789,132316,55759, 877728,534766,175546,68592, -2024380,-28,16 ],
  [ 1500,100,10,1,1, 188516,117126,23084,8742, 454070,352695,37839,8448, 1048626,567246,87385,40115, 1536345,870669,128029,55473, 2041804,1235933,170150,67156, -128232,2,0 ],
  [ 1500,100,15,1,1, 244887,139016,21768,9411, 748793,525935,34036,10130, 1767619,921723,80346,38450, 2731463,1549334,124157,53733, 3663777,2046219,166535,73525, 2572114,36,0 ],
  [ 1500,100,25,1,1, 284965,216802,20725,4957, 1359268,897240,28466,9676, 3639412,1922264,69989,33022, 5967516,3196799,114760,53283, 8337183,4604685,160330,71779, 9018985,68,0 ],
  [ 1500,100,50,1,1, 288341,217315,20596,5073, 1973619,1345007,24878,7924, 6722342,3547379,61112,28863, 11454834,6111007,104135,48580, 16897394,9172489,153613,70226, 19536691,94,0 ],
  [ 1500,100,75,1,1, 288341,217315,20596,5073, 1984424,1347233,24805,7965, 6818792,3576824,60882,28946, 11629341,6154049,103833,48887, 17181638,9447872,153407,69051, 19917659,95,0 ],
  [ 1500,200,1,1,1, 52929,83218,52929,-30289, 60337,58631,60337,1706, 228885,106248,228885,122637, 162635,65677,162635,96958, 215973,77462,215973,138511, -2995539,-36,23 ],
  [ 1500,200,2,1,1, 80704,87425,40352,-3360, 113828,82131,56914,15848, 340138,215444,170069,62347, 325271,188053,162635,68609, 431947,342754,215973,44597, -2775232,-35,22 ],
  [ 1500,200,3,1,1, 108480,104969,36160,1170, 167319,91596,55773,25241, 451390,353839,150463,32517, 487906,339231,162635,49559, 647920,396203,215973,83906, -2524925,-33,20 ],
  [ 1500,200,5,1,1, 150857,111389,32914,8611, 263944,197095,52789,13370, 659451,401445,131890,51601, 804526,421615,160905,76582, 1068309,583233,213662,97015, -1526004,-23,11 ],
  [ 1500,200,10,1,1, 237772,124561,29115,13863, 553108,384399,46092,14059, 1319772,745719,109981,47838, 1867944,955954,155662,75999, 2484656,1343442,207055,95101, 1069801,22,0 ],
  [ 1500,200,15,1,1, 305805,219912,27183,7635, 910094,569844,41368,15466, 2192517,1216022,99660,44386, 3320512,1772034,150932,70385, 4457771,2540928,202626,87129, 3913442,45,0 ],
  [ 1500,200,25,1,1, 354114,328107,25754,1891, 1648852,1157636,34531,10287, 4463181,2383489,85830,39994, 7252063,3862943,139463,65175, 10141141,5629115,195022,86770, 11234304,75,0 ],
  [ 1500,200,50,1,1, 358177,328724,25584,2104, 2391444,1571680,30144,10333, 8200288,4168126,74548,36656, 13915315,7353861,126503,59650, 20547802,11168059,186798,85270, 24908926,103,0 ],
  [ 1500,200,75,1,1, 358177,328724,25584,2104, 2404485,1648214,30056,9453, 8317146,4369256,74260,35249, 14127148,7498624,126135,59183, 20893288,11340949,186547,85289, 24735614,103,0 ],
  [ 2000,0,1,1,1, 24751,79108,24751,-54357, 45477,57302,45477,-11825, 89581,69107,89581,20474, 129637,60364,129637,69272, 172270,70140,172270,102130, -3437804,-39,26 ],
  [ 2000,0,2,1,1, 49502,83002,24751,-16750, 90954,79490,45477,5732, 179162,83578,89581,47792, 259273,97189,129637,81042, 344541,199400,172270,72570, -2938862,-36,23 ],
  [ 2000,0,3,1,1, 74253,100233,24751,-8660, 136431,87643,45477,16263, 268742,112715,89581,52009, 388910,308004,129637,26969, 516811,358220,172270,52864, -2761922,-34,22 ],
  [ 2000,0,5,1,1, 112039,106178,24445,1279, 219207,117018,43841,20438, 437422,337605,87484,19963, 641213,380849,128243,52073, 852296,531813,170459,64096, -2212366,-30,18 ],
  [ 2000,0,10,1,1, 189673,118398,23225,8728, 470170,371875,39181,8191, 978537,544205,81545,36194, 1488881,866382,124073,51875, 1983487,1145645,165291,69820, -287145,-2,0 ],
  [ 2000,0,15,1,1, 250554,141321,22271,9710, 783733,536294,35624,11247, 1700349,916405,77289,35634, 2650138,1434217,120461,55269, 3559236,2035150,161783,69277, 2504878,36,0 ],
  [ 2000,0,25,1,1, 293910,219851,21375,5386, 1443560,934633,30232,10658, 3610440,1930640,69432,32304, 5822469,3189239,111971,50639, 8091534,4466948,155606,69704, 8826080,67,0 ],
  [ 2000,0,50,1,1, 297573,220428,21255,5510, 2114008,1515485,26647,7544, 6815004,3617071,61955,29072, 11274535,6146160,102496,46622, 16390027,9040903,149000,66810, 18932847,93,0 ],
  [ 2000,0,75,1,1, 297573,220428,21255,5510, 2125830,1517979,26573,7598, 6916419,3647798,61754,29184, 11449522,6189914,102228,46961, 16665875,9202542,148802,66637, 19399400,94,0 ],
  [ 2000,25,1,1,1, 59849,93266,59849,-33416, -18812,60880,-18812,-79692, 3334267,3865365,3334267,-531098, 5537621,6441416,5537621,-903795, 8158007,9607385,8158007,-1449378, -7343055,-60,38 ],
  [ 2000,25,2,1,1, 55047,83839,27524,-14396, 96500,80335,48250,8082, 203708,87582,101854,58063, 273725,99600,136863,87063, 363811,202715,181905,80548, -2828465,-35,22 ],
  [ 2000,25,3,1,1, 80999,101251,27000,-6751, 144322,88910,48107,18471, 298210,194507,99403,34568, 410588,311616,136863,32991, 545716,363189,181905,60842, -2793705,-35,22 ],
  [ 2000,25,5,1,1, 120614,107473,26316,2867, 231329,119063,46266,22453, 476083,343865,95217,26444, 676949,386786,135390,58033, 899941,539987,179988,71991, -2004258,-28,16 ],
  [ 2000,25,10,1,1, 201987,120257,24733,10008, 494929,376329,41244,9883, 1046324,569606,87194,39727, 1571781,880116,130982,57639, 2094200,1248379,174517,70485, 24758,5,0 ],
  [ 2000,25,15,1,1, 265784,143620,23625,10859, 824058,543800,37457,12739, 1806573,933369,82117,39691, 2797400,1581857,127155,55252, 3757734,2168377,170806,72243, 2912311,39,0 ],
  [ 2000,25,25,1,1, 311198,222461,22633,6454, 1515957,1052965,31748,9696, 3816382,2067770,73392,33627, 6143606,3352019,118146,53684, 8542523,4770953,164279,72530, 9236701,69,0 ],
  [ 2000,25,50,1,1, 315032,223064,22502,6569, 2218464,1549912,27964,8427, 7184491,3795469,65314,30809, 11889655,6371322,108088,50167, 17302629,9553063,157297,70451, 20513927,96,0 ],
  [ 2000,25,75,1,1, 315032,223064,22502,6569, 2230846,1552521,27886,8479, 7291008,3918519,65098,30112, 12073974,6525483,107803,49540, 17593788,9717689,157087,70322, 20640495,96,0 ],
  [ 2000,50,1,1,1, 65571,94109,65571,-28538, -18760,61363,-18760,-80123, 3504463,407835,3504463,3096628, 5847698,6686128,5847698,-838430, 8628445,10137033,8628445,-1508588, 1054713,20,0 ],
  [ 2000,50,2,1,1, 60592,84676,30296,-12042, 102046,81181,51023,10432, 228254,106101,114127,61077, 288178,182368,144089,52905, 383081,319170,191540,31955, -2950726,-36,23 ],
  [ 2000,50,3,1,1, 87744,102269,29248,-4842, 152213,90177,50738,20679, 327677,199299,109226,42793, 432267,330205,144089,34021, 574621,368157,191540,68821, -2671093,-34,21 ],
  [ 2000,50,5,1,1, 129189,108767,28187,4456, 243451,194959,48690,9698, 514743,350125,102949,32924, 712685,392723,142537,63992, 947586,563832,189517,76751, -2041103,-28,16 ],
  [ 2000,50,10,1,1, 214301,122115,26241,11288, 519689,380784,43307,11575, 1114111,580492,92843,44468, 1654681,908775,137890,62159, 2204913,1282955,183743,76830, 396618,12,0 ],
  [ 2000,50,15,1,1, 281013,217618,24979,5635, 864383,565191,39290,13600, 1912798,1070747,86945,38275, 2944663,1621587,133848,60140, 3956232,2218324,179829,78996, 2923107,39,0 ],
  [ 2000,50,25,1,1, 328485,225070,23890,7521, 1588353,1154681,33264,9082, 4022325,2114735,77352,36684, 6464742,3419464,124322,58563, 8993512,4977938,172952,77223, 10116665,71,0 ],
  [ 2000,50,50,1,1, 332490,225699,23749,7628, 2322920,1584339,29281,9310, 7553977,3988402,68673,32414, 12504776,6705394,113680,52722, 18215230,10065673,165593,74087, 21899552,98,0 ],
  [ 2000,50,75,1,1, 332490,225699,23749,7628, 2335861,1587062,29198,9360, 7665596,4083189,68443,31986, 12698425,6767068,113379,52959, 18521700,10261646,165372,73750, 22244042,99,0 ],
  [ 2000,100,1,1,1, 42130,81731,42130,-39601, 58280,58997,58280,-717, 168081,81968,168081,86113, 158541,65204,158541,93337, 210811,76782,210811,134028, -3133493,-37,24 ],
  [ 2000,100,2,1,1, 71683,86350,35841,-7334, 113137,82872,56569,15133, 277347,114108,138673,81619, 317082,187190,158541,64946, 421621,341470,210811,40075, -2741106,-34,22 ],
  [ 2000,100,3,1,1, 101236,104306,33745,-1023, 167995,92712,55998,25094, 386612,329448,128871,19055, 475623,337980,158541,45881, 632432,394316,210811,79372, -2671714,-34,21 ],
  [ 2000,100,5,1,1, 146339,111356,31929,7633, 267695,199049,53539,13729, 592065,377159,118413,42981, 784157,419624,156831,72906, 1042876,580230,208575,92529, -1680490,-24,13 ],
  [ 2000,100,10,1,1, 238929,139170,29257,12215, 569208,389544,47434,14972, 1249684,722829,104140,43905, 1820481,951818,151707,72389, 2426339,1337135,202195,90767, 840044,19,0 ],
  [ 2000,100,15,1,1, 311472,222216,27686,7934, 945034,684553,42956,11840, 2125247,1194689,96602,42298, 3239187,1765327,147236,66994, 4353229,2529309,197874,82905, 3393011,42,0 ],
  [ 2000,100,25,1,1, 363060,331156,26404,2320, 1733145,1195029,36296,11269, 4434209,2391865,85273,39276, 7107016,3855383,136673,62531, 9895491,5520688,190298,84131, 11020501,74,0 ],
  [ 2000,100,50,1,1, 367408,331837,26243,2541, 2531832,1711658,31914,10338, 8292951,4422368,75390,35187, 13735016,7389515,124864,57686, 20040434,11008162,182186,82112, 23996945,102,0 ],
  [ 2000,100,75,1,1, 367408,331837,26243,2541, 2545891,1714610,31824,10391, 8414773,4456246,75132,35344, 13947329,7519562,124530,57391, 20377525,11209260,181942,81860, 24451947,103,0 ],
  [ 2000,200,1,1,1, 59509,84355,437244,81529, 71083,60693,2965952,949011, 246582,5248678,9913128,4664450, 16445136,8782479,16445136,7662657, 24089175,13313529,24089175,10775646, 7465109,65,0 ],
  [ 2000,200,2,1,1, 93864,103035,46932,-4586, 135321,86105,67660,24608, 375532,221638,187766,76947, 374891,305495,187446,34698, 498702,354929,249351,71886, -2724286,-34,21 ],
  [ 2000,200,3,1,1, 128219,108379,42740,6613, 199558,97781,66519,33926, 504481,363131,168160,47117, 562337,352479,187446,69953, 748052,414241,249351,111271, -2126354,-29,17 ],
  [ 2000,200,5,1,1, 180640,116534,39412,13987, 316183,207230,63237,21791, 746707,416713,149341,65999, 927101,523731,185420,80674, 1233457,741738,246691,98344, -1091886,-17,-42 ],
  [ 2000,200,10,1,1, 288184,218305,35288,8557, 668245,421248,55687,20583, 1520831,872252,126736,54048, 2152081,1225371,179340,77226, 2869192,1656686,239099,101042, 1382835,25,0 ],
  [ 2000,200,15,1,1, 372390,332279,33101,3565, 1106335,726963,50288,17244, 2550145,1382938,115916,53055, 3828237,2097488,174011,78670, 5147223,2925567,233965,100984, 4770861,50,0 ],
  [ 2000,200,25,1,1, 432210,354931,31433,5620, 2022730,1352425,42361,14038, 5257978,2777590,101115,47700, 8391564,4548330,161376,73908, 11699449,6460488,224989,100749, 14116104,82,0 ],
  [ 2000,200,50,1,1, 437244,355716,31232,5823, 2949656,2013530,37181,11800, 9770897,5134650,88826,42148, 16195498,8631470,147232,68764, 23690841,13002832,215371,97164, 29282503,110,0 ],
  [ 2000,200,75,1,1, 437244,355716,31232,5823, 2965952,2016941,37074,11863, 9913128,5248678,88510,41647, 16445136,8782479,146832,68417, 24089175,13313529,215082,96211, 29595867,110,0 ],
  [ 2500,0,1,1,1, 376640,80545,376640,296095, -25150,63265,-25150,-88415, -152674,80606,-152674,-233280, 235676,648870,235676,-413194, 324469,901198,324469,-576729, -3972916,-44,26 ],
  [ 2500,0,2,1,1, 62661,85275,31331,-11307, 112447,83613,56223,14417, 214556,89772,107278,62392, 308894,186328,154447,61283, 411295,324516,205648,43390, -2872305,-35,22 ],
  [ 2500,0,3,1,1, 93992,103642,31331,-3217, 168670,93827,56223,24948, 321833,199006,107278,40942, 463341,336228,154447,42371, 616943,391929,205648,75005, -2573126,-33,20 ],
  [ 2500,0,5,1,1, 141822,111323,30943,6654, 271446,201003,54289,14089, 524678,352874,104936,34361, 763787,417634,152757,69231, 1017444,577228,203489,88043, -1834976,-26,14 ],
  [ 2500,0,10,1,1, 240086,140442,29398,12201, 585308,408723,48776,14715, 1179596,699788,98300,39984, 1773018,947531,147752,68791, 2368023,1330627,197335,86450, 621397,16,0 ],
  [ 2500,0,15,1,1, 317139,224521,28190,8233, 979974,708798,44544,12326, 2057976,1189221,93544,39489, 3157863,1758670,143539,63600, 4248688,2418739,193122,83180, 3193025,41,0 ],
  [ 2500,0,25,1,1, 372006,347542,27055,1779, 1817438,1232273,38062,12255, 4405238,2414756,84716,38278, 6961969,3767716,133884,61428, 9649842,5383151,185574,82052, 10857926,74,0 ],
  [ 2500,0,50,1,1, 376640,348287,26903,2025, 2672220,1881986,33683,9961, 8385613,4492060,76233,35396, 13554717,7424818,123225,55726, 19533066,10876726,177573,78694, 23338409,101,0 ],
  [ 2500,0,75,1,1, 376640,348287,26903,2025, 2687298,1885207,33591,10026, 8512401,4527220,76004,35582, 13767510,7475270,122924,56181, 19861763,11047860,177337,78696, 23937785,102,0 ],
  [ 2500,25,1,1,1, 35675,80900,35675,-45225, 59424,59787,59424,-363, 126903,75419,126903,51484, 161673,65990,161673,95683, 215283,77813,215283,137469, -3228869,-38,25 ],
  [ 2500,25,2,1,1, 68207,86112,34103,-8953, 117993,84458,58996,16767, 239102,108291,119551,65405, 323346,188789,161673,67278, 430566,343551,215283,43507, -2806582,-35,22 ],
  [ 2500,25,3,1,1, 100738,104661,33579,-1308, 176561,95095,58854,27155, 351301,203798,117100,49168, 485019,339841,161673,48393, 645848,396898,215283,82984, -2428411,-32,19 ],
  [ 2500,25,5,1,1, 150397,112617,32814,8243, 283568,203048,56714,16104, 563339,373648,112668,37938, 799523,423621,159905,75180, 1065089,585452,213018,95928, -1660368,-24,13 ],
  [ 2500,25,10,1,1, 252400,142301,30906,13481, 610067,413178,50839,16407, 1247382,725189,103949,43516, 1855918,961264,154660,74554, 2478736,1365252,206561,92790, 981860,21,0 ],
  [ 2500,25,15,1,1, 332368,327686,29544,416, 1020299,714804,46377,13886, 2164201,1220700,98373,42886, 3305125,1799400,150233,68442, 4447186,2569187,202145,85364, 3302186,42,0 ],
  [ 2500,25,25,1,1, 389294,350151,28312,2847, 1889834,1260139,39578,13187, 4611180,2461721,88677,41336, 7283106,4038906,140060,62388, 10100831,5687556,194247,84871, 11601510,76,0 ],
  [ 2500,25,50,1,1, 394099,350922,28150,3084, 2776676,1990263,35000,9913, 8755100,4761993,79592,36301, 14169838,7650430,128817,59267, 20445668,11389336,185870,82330, 24473830,103,0 ],
  [ 2500,25,75,1,1, 394099,350922,28150,3084, 2792313,1993598,34904,9984, 8886989,4797941,79348,36509, 14391962,7891146,128500,58043, 20789675,11592318,185622,82119, 24805678,103,0 ],
  [ 2500,50,1,1,1, 40020,81556,40020,-41536, 62625,60211,62625,2414, 146528,78635,146528,67894, 168899,67200,168899,101699, 224918,79474,224918,145444, -3152792,-37,24 ],
  [ 2500,50,2,1,1, 73752,86949,36876,-6599, 123539,85304,61769,19117, 263648,112295,131824,75677, 337798,191200,168899,73299, 449836,346866,224918,51485, -2696185,-34,21 ],
  [ 2500,50,3,1,1, 107484,105679,35828,602, 184452,96362,61484,29363, 380768,329155,126923,17204, 506697,344003,168899,54231, 674753,402416,224918,90779, -2562574,-33,20 ],
  [ 2500,50,5,1,1, 158972,113912,34685,9831, 295691,205093,59138,18120, 602000,379908,120400,44418, 835259,509866,167052,65079, 1112735,609297,222547,100688, -1581957,-23,12 ],
  [ 2500,50,10,1,1, 264713,144160,32414,14762, 634827,417633,52902,18099, 1315169,736075,109597,48258, 1938818,1098384,161568,70036, 2589449,1467937,215787,93459, 1145086,22,0 ],
  [ 2500,50,15,1,1, 347598,329985,30898,1566, 1060624,722310,48210,15378, 2270426,1237663,103201,46944, 3452387,1947040,156927,68425, 4645685,2618634,211167,92139, 3769353,45,0 ],
  [ 2500,50,25,1,1, 406581,352761,29570,3914, 1962230,1347971,41094,12864, 4817122,2585836,92637,42909, 7604243,4201636,146235,65435, 10551820,6007682,202920,87387, 12127299,77,0 ],
  [ 2500,50,50,1,1, 411558,353558,29397,4143, 2881132,2010805,36317,10971, 9124586,4849026,82951,38869, 14784958,7984002,134409,61827, 21358270,11817715,194166,86732, 26208151,105,0 ],
  [ 2500,50,75,1,1, 411558,353558,29397,4143, 2897328,2028140,36217,10865, 9261578,4962612,82693,38384, 15016414,8241191,134075,60493, 21717588,12106964,193907,85809, 26270048,105,0 ],
  [ 2500,100,1,1,1, 48710,82868,48710,-34158, 69027,61059,69027,7968, 185779,99581,185779,86198, 183351,69670,183351,113682, 244188,82845,244188,161343, -3034136,-37,23 ],
  [ 2500,100,2,1,1, 84842,101960,42421,-8559, 134630,86845,67315,23892, 312741,197302,156370,57719, 366703,196172,183351,85265, 488376,353645,244188,67365, -2706583,-34,21 ],
  [ 2500,100,3,1,1, 120975,107716,40325,4420, 200234,98897,66745,33779, 439703,338739,146568,33655, 550054,351228,183351,66276, 732564,412354,244188,106737, -2273144,-30,18 ],
  [ 2500,100,5,1,1, 176122,116501,38427,13008, 319935,209183,63987,22150, 679321,406943,135864,54476, 906731,521791,181346,76988, 1208025,738785,241605,93848, -1279873,-20,-53 ],
  [ 2500,100,10,1,1, 289341,219577,35430,8543, 684346,500393,57029,15329, 1450742,849362,120895,50115, 2104618,1221084,175385,73628, 2810875,1650228,234240,96721, 973990,21,0 ],
  [ 2500,100,15,1,1, 378057,347920,33605,2679, 1141275,824907,51876,14380, 2482875,1300470,112858,53746, 3746912,2105958,170314,74589, 5042681,2914598,229213,96731, 4365888,48,0 ],
  [ 2500,100,25,1,1, 441156,357980,32084,6049, 2107023,1494169,44126,12835, 5229007,2798981,100558,46731, 8246517,4540821,158587,71263, 11453799,6435392,220265,96508, 13482445,81,0 ],
  [ 2500,100,50,1,1, 446476,358829,31891,6260, 3090045,2079659,38950,12736, 9863559,5297357,89669,41511, 16015199,8666673,145593,66805, 23183474,12871795,210759,93743, 28795283,109,0 ],
  [ 2500,100,75,1,1, 446476,358829,31891,6260, 3107359,2083338,38842,12800, 10010755,5321153,89382,41871, 16265317,8818345,145226,66491, 23573412,13053028,210477,93932, 29415403,110,0 ],
  [ 2500,200,1,1,1, 66089,85491,66089,-19403, 81830,76639,81830,5190, 264279,126807,264279,137472, 212256,74559,212256,137697, 282728,188939,282728,93789, -2877817,-35,22 ],
  [ 2500,200,2,1,1, 107023,105308,53512,858, 156814,90228,78407,33293, 410926,333882,205463,38522, 424512,329803,212256,47354, 565456,367454,282728,99001, -2726053,-34,21 ],
  [ 2500,200,3,1,1, 147958,111789,49319,12057, 231797,117851,77266,37982, 557572,372272,185857,61767, 636768,380703,212256,85355, 848185,531680,282728,105502, -1864378,-26,15 ],
  [ 2500,200,5,1,1, 210423,121678,45910,19362, 368423,335599,73685,6565, 833963,444847,166793,77823, 1049675,560566,209935,97822, 1398606,786652,279721,122391, -884420,-14,-25 ],
  [ 2500,200,10,1,1, 338597,327879,41461,1312, 783383,530597,65282,21066, 1721889,921785,143491,66675, 2436218,1307369,203018,94071, 3253728,1842518,271144,117601, 1943993,31,0 ],
  [ 2500,200,15,1,1, 438974,357116,39020,7276, 1302576,868816,59208,19716, 2907773,1594769,132171,59682, 4335961,2437118,197089,86311, 5836675,3325526,265303,114143, 5900861,56,0 ],
  [ 2500,200,25,1,1, 510306,368419,37113,10319, 2396608,1651715,50191,15600, 6052776,3261706,116400,53674, 9531064,5140134,183290,84441, 13257757,7375042,254957,113129, 16594214,88,0 ],
  [ 2500,200,50,1,1, 516312,369371,36879,10496, 3507869,2381681,44217,14196, 11341506,6009639,103105,48472, 18475680,9988785,167961,77154, 26833881,14837005,243944,109063, 34037906,116,0 ],
  [ 2500,200,75,1,1, 516312,369371,36879,10496, 3527420,2385819,44093,14270, 11509110,6128100,102760,48045, 18763125,10066185,167528,77651, 27285062,15157197,243617,108285, 34602619,117,0 ],
  [ 3000,0,1,1,1, 37910,81381,37910,-43471, 66970,75310,66970,-8340, 124975,75151,124975,49824, 179257,69196,179257,110061, 239025,82115,239025,156910, -3215674,-38,25 ],
  [ 3000,0,2,1,1, 75821,100885,37910,-12532, 133940,87586,66970,23177, 249950,110482,124975,69734, 358514,195360,179257,81577, 478050,352412,239025,62819, -2705957,-34,21 ],
  [ 3000,0,3,1,1, 113731,107052,37910,2226, 200909,113897,66970,29004, 374925,314198,124975,20242, 537771,349977,179257,62598, 717075,410417,239025,102220, -2463517,-32,20 ],
  [ 3000,0,5,1,1, 171605,116468,37441,12030, 323686,315487,64737,1640, 611935,381157,122387,46155, 886362,519800,177272,73312, 1182593,735282,236519,89462, -1760980,-25,14 ],
  [ 3000,0,10,1,1, 290498,220850,35571,8528, 700446,505687,58370,16230, 1380654,840986,115054,44972, 2057155,1216847,171430,70026, 2752558,1643821,229380,92395, 765426,18,0 ],
  [ 3000,0,15,1,1, 383724,350224,34109,2978, 1176215,835266,53464,15498, 2415604,1280637,109800,51589, 3665587,2099251,166618,71197, 4938140,2790388,224461,97625, 4252711,47,0 ],
  [ 3000,0,25,1,1, 450102,361029,32735,6478, 2191315,1531562,45891,13817, 5200035,2884357,100001,44532, 8101470,4548238,155797,68331, 11208150,6410745,215541,92258, 13010306,80,0 ],
  [ 3000,0,50,1,1, 455707,361942,32551,6698, 3230433,2323987,40720,11426, 9956222,5367050,90511,41720, 15834900,8701826,143954,64846, 22676106,12710899,206146,90593, 27978556,108,0 ],
  [ 3000,0,75,1,1, 455707,361942,32551,6698, 3248766,2327935,40610,11510, 10108383,5512692,90253,41033, 16085499,8839783,143621,64694, 23057650,13005119,205872,89755, 28266222,108,0 ],
  [ 3000,25,1,1,1, 42255,82037,42255,-39782, 70171,75734,70171,-5563, 144600,78366,144600,66234, 186483,70406,186483,116077, 248660,99447,248660,149213, -3150769,-37,24 ],
  [ 3000,25,2,1,1, 81366,101722,40683,-10178, 139485,88432,69743,25527, 274496,114486,137248,80005, 372967,306231,186483,33368, 497320,355727,248660,70797, -2755635,-34,22 ],
  [ 3000,25,3,1,1, 120477,108071,40159,4136, 208800,115165,69600,31212, 404392,333505,134797,23629, 559450,353639,186483,68604, 745980,431106,248660,104958, -2363476,-31,19 ],
  [ 3000,25,5,1,1, 180180,117762,39312,13618, 335808,331417,67162,878, 650595,387267,130119,52666, 922098,540714,184420,76277, 1230238,743406,246048,97366, -1618559,-24,12 ],
  [ 3000,25,10,1,1, 302812,222709,37079,9809, 725205,524027,60434,16765, 1448440,851722,120703,49727, 2140055,1230530,178338,75794, 2863271,1662675,238606,100050, 1126980,23,0 ],
  [ 3000,25,15,1,1, 398953,352523,35463,4127, 1216540,856657,55297,16358, 2521829,1388966,114629,51494, 3812849,2123504,173311,76788, 5136638,2952925,233484,99260, 4567842,49,0 ],
  [ 3000,25,25,1,1, 467389,363639,33992,7545, 2263712,1559428,47408,14749, 5405978,2931322,103961,47590, 8422606,4630660,161973,72922, 11659139,6602060,224214,97252, 14113120,82,0 ],
  [ 3000,25,50,1,1, 473166,364578,33798,7756, 3334889,2358414,42036,12309, 10325708,5636982,93870,42625, 16450020,9035898,149546,67401, 23588708,13139728,214443,94991, 29247418,110,0 ],
  [ 3000,25,75,1,1, 473166,364578,33798,7756, 3353781,2362476,41922,12391, 10482971,5677363,93598,42907, 16709950,9189828,149196,67144, 23985562,13435986,214157,94193, 29790326,110,0 ],
  [ 3000,50,1,1,1, 46600,82693,46600,-36093, 73371,76158,73371,-2786, 164225,81582,164225,82643, 193709,71616,193709,122093, 258295,101108,258295,157188, -3074692,-37,24 ],
  [ 3000,50,2,1,1, 86911,102559,43456,-7824, 145031,89277,72516,27877, 299042,195489,149521,51776, 387419,308642,193709,39388, 516590,359041,258295,78775, -2821736,-35,22 ],
  [ 3000,50,3,1,1, 127223,109089,42408,6045, 216691,116432,72230,33420, 433859,338297,144620,31854, 581128,372228,193709,69634, 774886,436075,258295,112937, -2240864,-30,18 ],
  [ 3000,50,5,1,1, 188755,119057,41183,15207, 347930,333462,69586,2894, 689256,408042,137851,56243, 957834,546701,191567,82227, 1277883,767301,255577,102116, -1455124,-22,10 ],
  [ 3000,50,10,1,1, 315126,224567,38587,11089, 749964,528482,62497,18457, 1516227,877123,126352,53259, 2222955,1259240,185246,80310, 2973984,1697300,247832,106390, 1465340,26,0 ],
  [ 3000,50,15,1,1, 414183,354822,36816,5276, 1256866,864163,57130,17850, 2628053,1526494,119457,50071, 3960112,2163234,180005,81676, 5335137,3002922,242506,106010, 4916206,51,0 ],
  [ 3000,50,25,1,1, 484677,366248,35249,8613, 2336108,1573410,48924,15973, 5611920,3099002,107922,48325, 8743743,4887423,168149,74160, 12110128,6838955,232887,101369, 14692073,84,0 ],
  [ 3000,50,50,1,1, 490625,367213,35045,8815, 3439345,2378956,43353,13366, 10695195,5801015,97229,44493, 17065140,9341318,155138,70217, 24501310,13764979,222739,97603, 30706425,112,0 ],
  [ 3000,50,75,1,1, 490625,367213,35045,8815, 3458796,2397017,43235,13272, 10857560,5948084,96942,43835, 17334402,9525397,154771,69723, 24913475,13966804,222442,97738, 31020249,112,0 ],
  [ 3000,100,1,1,1, 55289,84004,55289,-28715, 79773,77006,79773,2768, 203476,102528,203476,100948, 208162,74086,208162,134076, 277565,104479,277565,173087, -2956036,-36,23 ],
  [ 3000,100,2,1,1, 98002,104233,49001,-3116, 156123,90969,78061,32577, 348135,218012,174067,65062, 416323,328491,208162,43916, 555131,365720,277565,94705, -2656547,-34,21 ],
  [ 3000,100,3,1,1, 140714,111125,46905,9863, 232473,118967,77491,37835, 492794,362396,164265,43466, 624485,379502,208162,81661, 832696,446062,277565,128878, -1984934,-28,16 ],
  [ 3000,100,5,1,1, 205905,121645,44925,18384, 372174,337552,74435,6924, 766577,420561,153315,69203, 1029306,558576,205861,94146, 1373174,783649,274635,117905, -1038907,-16,-34 ],
  [ 3000,100,10,1,1, 339754,329152,41602,1298, 799483,535892,66624,21966, 1651800,913410,137650,61533, 2388755,1303132,199063,90469, 3195411,1836111,266284,113275, 1735429,29,0 ],
  [ 3000,100,15,1,1, 444641,359420,39524,7575, 1337516,893060,60796,20203, 2840502,1574786,129114,57533, 4254636,2430411,193393,82919, 5732134,3185596,260552,115752, 5755273,55,0 ],
  [ 3000,100,25,1,1, 519252,384804,37764,9778, 2480900,1688958,51956,16585, 6023804,3270082,115842,52956, 9386017,5132725,180500,81794, 13012107,7350545,250233,108876, 16265983,87,0 ],
  [ 3000,100,50,1,1, 525543,385821,37539,9980, 3648257,2521510,45986,14203, 11434168,6157831,103947,47967, 18295381,10024088,166322,75194, 26326513,14706068,239332,105640, 33295713,115,0 ],
  [ 3000,100,75,1,1, 525543,385821,37539,9980, 3668827,2525915,45860,14084, 11606737,6306625,103632,48651, 18583306,10102700,165922,76422, 26769300,14913017,235058,103754, 33345563,115,0 ],
  [ 3000,200,1,1,1, 72668,86628,72668,-13960, 92576,78701,92576,13875, 281976,129905,281976,152072, 237066,78975,237066,158091, 316106,194951,316106,121154, -2744960,-34,22 ],
  [ 3000,200,2,1,1, 120183,107582,60091,6301, 178306,94351,89153,41978, 446320,354592,223160,45864, 474132,338685,237066,67724, 632211,395200,316106,118506, -2505013,-32,20 ],
  [ 3000,200,3,1,1, 167698,115199,55899,17500, 264036,197886,88012,22050, 610664,396079,203555,71528, 711199,394001,237066,105732, 948317,565439,316106,127626, -1744262,-25,13 ],
  [ 3000,200,5,1,1, 240206,140160,52409,21828, 420663,345583,84133,15016, 921219,551631,184244,73918, 1172250,706012,234450,93248, 1563755,916046,312751,129542, -757749,-12,0 ],
  [ 3000,200,10,1,1, 389009,349924,47634,4786, 898521,567446,74877,27590, 1922947,1091883,160246,69255, 2720355,1562359,226696,96500, 3638263,2072532,303189,130478, 2530468,36,0 ],
  [ 3000,200,15,1,1, 505559,368616,44939,12173, 1498817,936970,68128,25539, 3265400,1763035,148427,68289, 4843686,2652612,220168,99594, 6526127,3679804,296642,129378, 7635964,63,0 ],
  [ 3000,200,25,1,1, 588402,395243,42793,14048, 2770485,1877004,58021,18712, 6847574,3654307,131684,61409, 10670564,5825522,205203,93174, 14816065,8289695,284924,125507, 19323439,93,0 ],
  [ 3000,200,50,1,1, 595379,396363,42527,14215, 4066082,2823532,51253,15662, 12912114,6884628,117383,54795, 20755862,11265943,188690,86272, 29976921,16671328,272517,120960, 38623361,121,0 ],
  [ 3000,200,75,1,1, 595379,396363,42527,14215, 4088888,2828396,51111,15756, 13105092,7007522,117010,54443, 21081113,11538808,188224,85199, 30480949,16917235,272151,121105, 39102033,122,0 ],
  [ 1000,0,1,2,1, 18112,77746,18112,-59635, 34954,55018,34954,-20064, 73754,51442,73754,22312, 104511,55474,104511,49037, 133543,62809,133543,70734, -3533409,-40,26 ],
  [ 1000,0,2,2,1, 36223,80279,18112,-22028, 69909,61038,34954,4435, 147508,77429,73754,35040, 209021,72532,104511,68245, 267086,101107,133543,82990, -3071651,-37,24 ],
  [ 1000,0,3,2,1, 54335,82811,18112,-9492, 104863,80943,34954,7973, 221262,88751,73754,44170, 313532,184824,104511,42903, 400629,320504,133543,26708, -2889797,-35,23 ],
  [ 1000,0,5,2,1, 81989,100016,17888,-3933, 167394,91785,33479,15122, 358415,201479,71683,31387, 518046,341529,103609,35303, 661391,395918,132278,53095, -2425009,-32,19 ],
  [ 1000,0,10,2,1, 138826,107976,16999,3778, 351387,331250,29282,1678, 789780,415383,65815,31200, 1209219,701613,100768,42300, 1543834,902421,128653,53451, -1146921,-17,-34 ],
  [ 1000,0,15,2,1, 183414,114227,16303,6150, 575136,386980,26143,8553, 1355424,727815,61610,28528, 2154151,1196678,97916,43521, 2779007,1498607,126318,58200, 1240370,24,0 ],
  [ 1000,0,25,2,1, 215186,118690,15650,7018, 1029253,708185,21555,6724, 2805352,1395849,53949,27106, 4700095,2454831,90386,43178, 6375325,3471582,122602,55841, 6620992,24,0 ],
  [ 1000,0,50,2,1, 217872,119068,15562,7057, 1481641,925951,18676,7004, 5150731,2491839,46825,24172, 8969349,4584881,81540,39859, 13023483,6944460,118395,55264, 15652833,86,0 ],
  [ 1000,0,75,2,1, 217872,119068,15562,7057, 1489610,927513,18620,7026, 5223264,2579349,46636,23606, 9103981,4620625,81286,40030, 13244989,6995895,118259,55795, 15905994,87,0 ],
  [ 1000,25,1,2,1, 23918,78623,23918,-54705, 39441,55629,39441,-16188, 97607,69862,97607,27745, 113949,57105,113949,56844, 145737,64961,145737,80776, -3469931,-40,26 ],
  [ 1000,25,2,2,1, 43905,81438,21953,-18767, 77812,76141,38906,836, 178059,82257,89030,47901, 227898,75682,113949,76108, 291475,189033,145737,51221, -3030867,-36,23 ],
  [ 1000,25,3,2,1, 63893,84254,21298,-6787, 116183,82768,38728,11139, 258512,109317,86171,49731, 341847,189594,113949,50751, 437212,326845,145737,36789, -2732071,-34,22 ],
  [ 1000,25,5,2,1, 94405,101891,20597,-1633, 184901,94737,36980,18033, 408232,315587,81646,18529, 564788,349798,112958,42998, 721759,406778,144352,62996, -2392242,-31,19 ],
  [ 1000,25,10,2,1, 157083,110732,19235,5676, 387479,337726,32290,4146, 879688,506808,73307,31073, 1318113,719591,109843,49877, 1684581,942129,140382,61871, -786936,-12,0 ],
  [ 1000,25,15,2,1, 206227,117671,18331,7872, 634253,411840,28830,10110, 1498554,842020,68116,29842, 2348223,1243606,106737,50210, 3032011,1670423,137819,61890, 1769374,29,0 ],
  [ 1000,25,25,2,1, 237848,134704,17298,7501, 1151733,741823,24120,8585, 2422359,1515677,46584,17436, 3923270,1258823,75447,51239, 5210096,1696245,100194,67574, 6820736,60,0 ],
  [ 1000,25,50,2,1, 244169,136374,17441,7700, 1636660,1148346,20630,6155, 5665760,2797584,51507,26074, 9791380,4982652,89013,43716, 14199795,7532319,129089,60613, 16999934,89,0 ],
  [ 1000,25,75,2,1, 244169,136374,17441,7700, 1645467,1150079,20568,6192, 5745603,2886221,51300,25530, 9938658,5100731,88738,43196, 14441235,7700148,128940,60188, 17097749,89,0 ],
  [ 1000,50,1,2,1, 29724,79499,29724,-49775, 43928,56239,43928,-12311, 121460,73767,121460,47693, 123387,58685,123387,64702, 157932,67063,157932,90869, -3372954,-39,25 ],
  [ 1000,50,2,2,1, 51587,82598,25794,-15505, 85716,77358,42858,4179, 208610,87235,104305,60688, 246774,78833,123387,83971, 315863,193230,157932,61317, -2886764,-35,23 ],
  [ 1000,50,3,2,1, 73451,85697,24484,-4082, 127504,84593,42501,14304, 295761,192368,98587,34464, 370162,194314,123387,58616, 473795,348807,157932,41663, -2728515,-34,21 ],
  [ 1000,50,5,2,1, 106821,103765,23306,667, 202407,97688,40481,20944, 458049,338159,91610,23978, 611531,357617,122306,50783, 782128,417188,156426,72988, -2147598,-29,17 ],
  [ 1000,50,10,2,1, 175341,113488,21470,7574, 423571,344202,35298,6614, 969595,535748,80800,36154, 1427007,752595,118917,56201, 1825329,966216,152111,71593, -294883,-2,0 ],
  [ 1000,50,15,2,1, 229041,121115,20359,9593, 693370,422815,31517,12298, 1641685,879375,74622,34650, 2542296,1290534,115559,56898, 3285016,1828650,149319,66198, 2528185,36,0 ],
  [ 1000,50,25,2,1, 267244,139885,19436,9263, 1243164,850905,26035,8215, 3370940,1726132,64826,31631, 5552169,2858414,106772,51803, 7531193,4042283,144831,67094, 8589346,66,0 ],
  [ 1000,50,50,2,1, 270466,140344,19319,9294, 1791678,1192690,22584,7550, 6180789,3104828,56189,27963, 10613410,5445604,96486,46980, 15376107,8217948,139783,65074, 18796250,93,0 ],
  [ 1000,50,75,2,1, 270466,140344,19319,9294, 1801324,1194595,22517,7584, 6267941,3117593,55964,28128, 10773336,5485403,96190,47214, 15637481,8275989,139620,65728, 19313531,94,0 ],
  [ 1000,100,1,2,1, 41336,81252,41336,-39916, 52901,57459,52901,-4558, 169166,96092,169166,73074, 142264,61897,142264,80367, 182321,71318,182321,111003, -3212498,-38,24 ],
  [ 1000,100,2,2,1, 66951,84917,33475,-8983, 101523,79793,50761,10865, 269713,111706,134856,79003, 284527,100160,142264,92184, 364641,201673,182321,81484, -2654164,-34,21 ],
  [ 1000,100,3,2,1, 92566,101919,30855,-3118, 150144,88092,50048,20684, 370259,218986,123420,50425, 426791,312414,142264,38126, 546962,361588,182321,61791, -2594329,-33,21 ],
  [ 1000,100,5,2,1, 131653,107513,28724,5267, 237420,117477,47484,23989, 557682,368640,111536,37808, 705016,388182,141003,63367, 902865,537359,180573,73101, -1761403,-25,14 ],
  [ 1000,100,10,2,1, 211857,119000,25942,11370, 495756,357153,41313,11550, 1149410,579113,95784,47525, 1644796,883885,137066,63409, 2106824,1242553,175569,72023, 463590,13,0 ],
  [ 1000,100,15,2,1, 274668,141339,24415,11851, 811604,532350,36891,12693, 1927945,953934,87634,44273, 2930441,1588284,133202,61007, 3791026,2143172,172319,74902, 3414494,42,0 ],
  [ 1000,100,25,2,1, 319302,219443,23222,7263, 1457076,906041,30515,11540, 3936529,2042050,75702,36432, 6404242,3342104,123159,58887, 8687062,4741595,167059,75874, 10418945,72,0 ],
  [ 1000,100,50,2,1, 323060,219983,23076,7363, 2101715,1355229,26492,9409, 7210847,3598751,65553,32837, 12257470,6305626,111432,54108, 17728731,9491237,161170,74886, 22305876,99,0 ],
  [ 1000,100,75,2,1, 323060,219983,23076,7363, 2113038,1357478,26413,9445, 7312619,3628288,65291,32896, 12442690,6349531,111095,54403, 18029972,9655385,160982,74773, 22806370,100,0 ],
  [ 1000,200,1,2,1, 64560,84758,64560,-20198, 70847,59899,70847,10948, 264577,126227,264577,138351, 180017,68269,180017,111747, 231098,79777,231098,151321, -2858088,-35,22 ],
  [ 1000,200,2,2,1, 97679,102892,48839,-2607, 133136,84513,66568,24312, 391917,223134,195958,84392, 360033,193269,180017,83382, 462196,347420,231098,57388, -2552775,-33,20 ],
  [ 1000,200,3,2,1, 130797,107690,43599,7702, 195426,95392,65142,33345, 519256,363755,173085,51834, 540050,346821,180017,64409, 693294,402971,231098,96774, -2141577,-29,17 ],
  [ 1000,200,5,2,1, 181317,115010,39560,14467, 307446,203134,61489,20863, 756949,415386,151390,68313, 891987,514592,178397,75479, 1144339,610190,228868,106830, -1079080,-17,-40 ],
  [ 1000,200,10,2,1, 284889,143361,34884,17330, 640124,396792,53344,20278, 1509040,863258,125753,53815, 2080373,1190293,173364,74173, 2669814,1470245,222485,99964, 1595402,27,0 ],
  [ 1000,200,15,2,1, 365921,327680,32526,3399, 1048073,692984,47640,16140, 2500467,1270237,113658,55920, 3706731,1966713,168488,79092, 4803045,2607887,218320,99780, 4825816,50,0 ],
  [ 1000,200,25,2,1, 423419,336026,30794,6356, 1884900,1206896,39474,14199, 5067705,2580700,97456,47827, 8108390,4215250,155931,74868, 10998799,6011959,211515,95901, 14092761,82,0 ],
  [ 1000,200,50,2,1, 428248,350065,30589,5585, 2721788,1723042,34308,12589, 9270962,4720028,84281,41372, 15545590,8027921,141324,68342, 22433980,12039514,203945,94495, 28995926,109,0 ],
  [ 1000,200,75,2,1, 428248,350065,30589,5585, 2736467,1725978,34206,12631, 9401973,4754078,83946,41499, 15781400,8188398,140905,67795, 22814956,12216873,203705,94626, 29511591,110,0 ],
  [ 1500,0,1,2,1, 28393,79522,28393,-51130, 50617,57988,50617,-7371, 97842,70174,97842,27668, 136916,61297,136916,75619, 175786,70473,175786,105313, -3382359,-39,25 ],
  [ 1500,0,2,2,1, 56785,83831,28393,-13523, 101233,80862,50617,10186, 195683,85712,97842,54986, 273832,99055,136916,87389, 351573,200065,175786,75754, -2827972,-35,22 ],
  [ 1500,0,3,2,1, 85178,101476,28393,-5433, 151850,89701,50617,20716, 293525,192916,97842,33536, 410748,310803,136916,33315, 527359,359218,175786,56047, -2772085,-35,22 ],
  [ 1500,0,5,2,1, 128525,108055,28042,4466, 243652,194157,48730,9899, 477420,342722,95484,26939, 678384,385607,135677,58555, 870651,533578,174130,67415, -2179705,-29,18 ],
  [ 1500,0,10,2,1, 217595,121581,26644,11757, 520310,378801,43359,11792, 1065641,555007,88803,42553, 1582554,878311,131880,58687, 2032761,1234338,169397,66535, 218441,9,0 ],
  [ 1500,0,15,2,1, 287453,217233,25551,6242, 864245,561525,39284,13760, 1848360,934117,84016,41557, 2823522,1579540,128342,56545, 3657902,2045233,166268,73303, 2923039,39,0 ],
  [ 1500,0,25,2,1, 337211,224800,24524,8175, 1583602,1146969,33164,9144, 3911525,2069183,75222,35430, 6213015,3332398,119481,55396, 8372493,4610173,161009,72352, 9589900,70,0 ],
  [ 1500,0,50,2,1, 341415,225441,24387,8284, 2312369,1558462,29148,9503, 7360910,3794917,66917,32418, 12020844,6343480,109280,51612, 17075709,9233242,155234,71295, 21589098,98,0 ],
  [ 1500,0,75,2,1, 341415,225441,24387,8284, 2325220,1574998,29065,9378, 7469872,3917691,66695,31716, 12206784,6403790,108989,51812, 17366056,9510038,155054,70143, 21739644,98,0 ],
  [ 1500,25,1,2,1, 34199,80399,34199,-46200, 55103,58598,55103,-3495, 121695,74079,121695,47616, 146354,62878,146354,83476, 187981,72575,187981,115406, -3285381,-38,25 ],
  [ 1500,25,2,2,1, 64467,84990,32234,-10262, 109137,82079,54568,13529, 226235,90690,113117,67772, 292708,182513,146354,55098, 375961,204262,187981,85850, -2802394,-35,22 ],
  [ 1500,25,3,2,1, 94735,102919,31578,-2728, 163170,91526,54390,23881, 330774,198967,110258,43936, 439063,330500,146354,36188, 563942,365509,187981,66144, -2602962,-33,21 ],
  [ 1500,25,5,2,1, 140941,109929,30751,6766, 261158,197109,52232,12810, 527236,350780,105447,35291, 725127,393376,145025,66350, 931020,543938,186204,77416, -1901560,-27,15 ],
  [ 1500,25,10,2,1, 235853,137674,28880,12022, 556403,385127,46367,14273, 1155549,689998,96296,38796, 1691449,911965,140954,64957, 2173508,1274746,181126,74897, 399250,12,0 ],
  [ 1500,25,15,2,1, 309528,137674,27514,15276, 722403,555365,32837,7593, 1631853,694998,74175,42584, 2412192,911965,109645,68192, 3091217,1274746,140510,82567, 3479867,43,0 ],
  [ 1500,25,25,2,1, 363240,329596,26417,2447, 1690558,1179979,35404,10693, 4194319,2128284,80660,39731, 6639052,3528726,127674,59814, 8950428,4855775,172124,78743, 10587945,73,0 ],
  [ 1500,25,50,2,1, 367712,330277,26265,2674, 2467387,1689041,31102,9811, 7875939,4102011,71599,34308, 12842874,6742601,116753,55457, 18252021,9920321,165927,75743, 22790797,100,0 ],
  [ 1500,25,75,2,1, 367712,330277,26265,2674, 2481077,1691864,31013,9865, 7992211,4120013,71359,34573, 13041461,6978230,116442,54136, 18562302,10086879,165735,75673, 23098252,100,0 ],
  [ 1500,50,1,2,1, 40005,81275,40005,-41270, 59590,59208,59590,382, 145548,77984,145548,67564, 155792,64459,155792,91334, 200175,74677,200175,125498, -3188403,-38,24 ],
  [ 1500,50,2,2,1, 72149,86150,36074,-7001, 117040,83297,58520,16872, 256786,110183,128393,73301, 311585,185713,155792,62936, 400350,321599,200175,39376, -2772424,-35,22 ],
  [ 1500,50,3,2,1, 104293,104361,34764,-23, 174490,93351,58163,27047, 368024,205018,122675,54335, 467377,335220,155792,44052, 600525,387470,200175,71018, -2422909,-32,19 ],
  [ 1500,50,5,2,1, 153357,111804,33460,9066, 278665,200061,55733,15721, 577053,373353,115411,40740, 771869,416172,154374,71140, 991388,570019,198278,84274, -1690192,-24,13 ],
  [ 1500,50,10,2,1, 254111,140430,31116,13920, 592495,391602,49375,16741, 1245456,718938,103788,43877, 1800343,944969,150029,71281, 2314256,1298833,192855,84619, 891303,19,0 ],
  [ 1500,50,15,2,1, 333079,224120,29607,9685, 982479,687825,44658,13393, 2134621,1190376,97028,42920, 3211667,1754203,145985,66248, 4163911,2262235,189269,86440, 3644546,44,0 ],
  [ 1500,50,25,2,1, 389269,346862,28310,3084, 1797514,1200454,37644,12504, 4477113,2385100,86098,40231, 7065089,3737431,135867,63993, 9528362,5198045,183238,83275, 11444273,75,0 ],
  [ 1500,50,50,2,1, 394009,347583,28143,3316, 2622405,1733236,33056,11208, 8390968,4409255,76282,36197, 13664904,7205703,124226,58720, 19428333,10508680,176621,81088, 24601879,103,0 ],
  [ 1500,50,75,2,1, 394009,347583,28143,3316, 2636934,1736231,32962,11259, 8514549,4428386,76023,36484, 13876139,7378028,123894,58019, 19758548,10791632,176416,80062, 24968934,103,0 ],
  [ 1500,100,1,2,1, 51617,83028,51617,-31411, 68563,60428,68563,8135, 193253,100309,193253,92945, 174669,67670,174669,106999, 224564,78932,224564,145632, -3027948,-36,23 ],
  [ 1500,100,2,2,1, 87513,101806,43756,-7147, 132847,85582,66423,23633, 317888,197139,158944,60374, 349338,192164,174669,78587, 449128,345813,224564,51657, -2726584,-34,21 ],
  [ 1500,100,3,2,1, 123408,107247,41136,5387, 197131,97000,65710,33377, 442522,337685,147507,34946, 524007,345210,174669,59599, 673691,400602,224564,91030, -2319333,-31,18 ],
  [ 1500,100,5,2,1, 178189,115552,38878,13666, 313678,205964,62736,21543, 676687,403984,135337,54541, 865355,512067,173071,70657, 1112126,590788,222425,104267, -1285927,-20,-53 ],
  [ 1500,100,10,2,1, 290627,217642,35587,8937, 664679,418439,55390,20520, 1425271,839153,118773,48843, 2018132,1184719,168178,69451, 2595751,1462030,216313,94477, 1056268,22,0 ],
  [ 1500,100,15,2,1, 378706,331875,33663,4163, 1100714,722160,50032,17207, 2420882,1264935,110040,52543, 3599812,1958019,163628,74627, 4669921,2593778,212269,94370, 4535111,49,0 ],
  [ 1500,100,25,2,1, 441327,354720,32096,6299, 2011425,1343324,42124,13992, 5042702,2594818,96975,47075, 7917163,4220621,152253,71087, 10684231,5881137,205466,92367, 13547042,81,0 ],
  [ 1500,100,50,2,1, 446603,355522,31900,6506, 2932442,2000124,36964,11752, 9421026,4824679,85646,41785, 15308965,8065725,139172,65848, 21780958,11781469,198009,90904, 28255365,108,0 ],
  [ 1500,100,75,2,1, 446603,355522,31900,6506, 2948649,2003463,36858,11815, 9559227,4937581,85350,41265, 15545493,8242157,138799,65208, 22151040,12071076,197777,90000, 28500044,109,0 ],
  [ 1500,200,1,2,1, 74841,86534,74841,-11693, 86509,76754,86509,9756, 288665,130293,288665,158372, 212422,74042,212422,138380, 273341,103012,273341,170329, -2728294,-34,22 ],
  [ 1500,200,2,2,1, 118240,106444,59120,5898, 164461,90452,82230,37005, 440092,352132,220046,43980, 424844,313825,212422,55509, 546683,363199,273341,91742, -2622627,-33,21 ],
  [ 1500,200,3,2,1, 161639,113018,53880,16207, 242412,118185,80804,41409, 591519,390770,197173,66916, 637265,379167,212422,86033, 820024,441485,273341,126180, -1698287,-25,13 ],
  [ 1500,200,5,2,1, 227853,123049,49713,22866, 383704,336006,76741,9540, 875954,449080,175191,85375, 1052326,558170,210465,98831, 1353600,776160,270720,115488, -702135,-11,0 ],
  [ 1500,200,10,2,1, 363658,329533,44530,4179, 809048,530577,67421,23206, 1784901,940248,148742,70388, 2453709,1288233,204476,97123, 3158741,1803662,263228,112923, 2237264,34,0 ],
  [ 1500,200,15,2,1, 469960,358986,41774,9864, 1337182,867530,60781,21348, 2993403,1597104,136064,63468, 4376102,2415455,198914,89120, 5681940,3141424,258270,115478, 6436895,58,0 ],
  [ 1500,200,25,2,1, 545443,370437,39669,12728, 2439249,1645680,51084,16619, 6173878,3239519,118728,56430, 9621310,5092767,185025,87087, 12995968,7037410,249922,114588, 17469787,90,0 ],
  [ 1500,200,50,2,1, 551791,371401,39414,12885, 3552515,2355702,44780,15086, 11481141,5946105,104374,50319, 18597085,9814523,169064,79841, 26486206,14411927,240784,109766, 35356081,117,0 ],
  [ 1500,200,75,2,1, 551791,371401,39414,12885, 3572077,2359728,44651,15154, 11648581,5986520,104005,50554, 18884203,9970913,168609,79583, 26936023,14630964,240500,109867, 36053787,118,0 ],
  [ 2000,0,1,2,1, 38673,81298,38673,-42625, 66279,60957,66279,5322, 121929,74390,121929,47539, 169321,67070,169321,102251, 218030,78087,218030,139942, -3197809,-38,24 ],
  [ 2000,0,2,2,1, 77347,100719,38673,-11686, 132558,86651,66279,22953, 243859,108660,121929,67599, 338642,191109,169321,73767, 436059,344256,218030,45902, -2757395,-34,22 ],
  [ 2000,0,3,2,1, 116020,106804,38673,3072, 198836,98609,66279,33409, 365788,205566,121929,53408, 507964,343099,169321,54955, 654089,397732,218030,85452, -2251712,-30,18 ],
  [ 2000,0,5,2,1, 175061,116094,38195,12866, 319910,208795,63982,22223, 596424,378066,119285,43672, 838723,509493,167745,65846, 1079912,587007,215982,98581, -1470448,-22,10 ],
  [ 2000,0,10,2,1, 296364,220224,36290,9323, 689234,426201,57436,21919, 1341502,738197,111792,50275, 1955890,1098837,162991,71421, 2521688,1370085,210141,95967, 1209459,23,0 ],
  [ 2000,0,15,2,1, 391491,349405,34799,3741, 1153354,825036,52425,14924, 2341297,1245118,106423,49826, 3492893,1949425,158768,70158, 4536797,2579770,206218,88956, 3989431,46,0 ],
  [ 2000,0,25,2,1, 459235,360078,33399,7211, 2137951,1510403,44774,13142, 5017698,2728001,96494,44033, 7725935,4211415,148576,67587, 10369662,5736576,199417,89098, 12716125,79,0 ],
  [ 2000,0,50,2,1, 464957,360980,33211,7427, 3143096,2099007,39619,13161, 9571089,5128395,87010,40388, 15072339,8104080,137021,63348, 21127935,11721344,192072,85514, 27479464,107,0 ],
  [ 2000,0,75,2,1, 464957,360980,33211,7427, 3160830,2102748,39510,13226, 9716480,5166148,86754,40628, 15309587,8281489,136693,62751, 21487123,11813238,191849,86374, 28049255,108,0 ],
  [ 2000,25,1,2,1, 44479,82175,44479,-37695, 70765,75452,70765,-4687, 145782,78145,145782,67637, 178759,68651,178759,110108, 230224,80139,230224,150085, -3144415,-37,24 ],
  [ 2000,25,2,2,1, 85029,101879,42514,-8425, 140461,87868,70230,26296, 274410,113638,137205,80386, 357519,194259,178759,81630, 460448,348452,230224,55998, -2613292,-33,21 ],
  [ 2000,25,3,2,1, 125578,108247,41859,5777, 210157,114319,70052,31946, 403037,332032,134346,23669, 536278,348369,178759,62636, 690672,404523,230224,95383, -2382947,-31,19 ],
  [ 2000,25,5,2,1, 187477,117968,40904,15166, 337416,316096,67483,4264, 646241,384624,129248,52323, 885465,517262,177093,73641, 1140281,612538,228056,105548, -1530098,-23,11 ],
  [ 2000,25,10,2,1, 314622,222980,38525,11222, 725326,506527,60444,18233, 1431410,844137,119284,48939, 2064784,1197173,172065,72301, 2662435,1493623,221870,97401, 1123905,22,0 ],
  [ 2000,25,15,2,1, 414305,352849,36827,5463, 1212471,836011,55112,17112, 2484427,1282473,112928,54634, 3686966,2076660,167589,73196, 4789802,2638545,217718,97784, 4700625,50,0 ],
  [ 2000,25,25,2,1, 485264,364007,35292,8819, 2244907,1531028,47014,14950, 5300492,2801767,101933,48052, 8151972,4434445,156769,71491, 10947596,6078196,210531,93642, 14026918,82,0 ],
  [ 2000,25,50,2,1, 491254,364950,35090,9022, 3298115,2321552,41573,12310, 10086118,5328089,91692,43255, 15894369,8501200,144494,67211, 22304248,12292883,202766,91012, 29137809,109,0 ],
  [ 2000,25,75,2,1, 491254,364950,35090,9022, 3316687,2325465,41459,12390, 10238819,5366971,91418,43499, 16144264,8665661,144145,66773, 22683369,12501170,202530,90912, 29692883,110,0 ],
  [ 2000,50,1,2,1, 50286,83051,50286,-32766, 75252,76062,75252,-810, 169635,82050,169635,87585, 188198,70232,188198,117966, 242418,82242,242418,160177, -3047437,-37,24 ],
  [ 2000,50,2,2,1, 92711,103039,46355,-5164, 148364,89086,74182,29639, 304961,195617,152480,54672, 376395,305869,188198,35263, 484837,352648,242418,66094, -2805762,-35,22 ],
  [ 2000,50,3,2,1, 135136,109689,45045,8482, 221477,116144,73826,35111, 440287,338083,146762,34068, 564593,353090,188198,70501, 727255,410813,242418,105481, -2191720,-30,18 ],
  [ 2000,50,5,2,1, 199893,119842,43613,17466, 354923,332933,70985,4398, 696057,407047,139211,57802, 932208,540057,186442,78430, 1200649,735989,240130,92932, -1431772,-22,10 ],
  [ 2000,50,10,2,1, 332880,225736,40761,13120, 761418,526888,63452,19544, 1521317,872927,126776,54032, 2173679,1230177,181140,78625, 2803183,1646422,233599,96397, 1480569,26,0 ],
  [ 2000,50,15,2,1, 437118,356293,38855,7184, 1271588,860871,57799,18669, 2627557,1396677,119434,55949, 3881038,2123588,176411,79884, 5042806,2796722,229218,102095, 5239354,52,0 ],
  [ 2000,50,25,2,1, 511293,367936,37185,10426, 2351863,1565538,49254,16468, 5583286,2952384,107371,50594, 8578009,4614347,164962,76224, 11525531,6435438,221645,97886, 15170110,85,0 ],
  [ 2000,50,50,2,1, 517551,382256,36968,9664, 3453133,2365746,43527,13707, 10601147,5620818,96374,45276, 16716399,8992405,151967,70218, 23480560,12994283,213460,95330, 30860210,112,0 ],
  [ 2000,50,75,2,1, 517551,382256,36968,9664, 3472544,2369831,43407,13784, 10761157,5660828,96082,45539, 16978942,9145717,151598,69940, 23879615,13092782,213211,96311, 31559173,113,0 ],
  [ 2000,100,1,2,1, 61898,84804,61898,-22906, 84225,77283,84225,6943, 217341,104375,217341,112966, 207074,73443,207074,133631, 266807,102167,266807,164640, -2898155,-35,23 ],
  [ 2000,100,2,2,1, 108074,105358,54037,1358, 164171,91520,82086,36325, 366063,220088,183032,72988, 414148,312220,207074,50964, 533614,361091,266807,86261, -2551058,-33,20 ],
  [ 2000,100,3,2,1, 154251,112575,51417,13892, 244118,119793,81373,41441, 514785,364700,171595,50028, 621222,377556,207074,81222, 800421,439115,266807,120435, -1876043,-27,15 ],
  [ 2000,100,5,2,1, 224725,123591,49031,22066, 389936,338836,77987,10220, 795691,423162,159138,74506, 1025693,555596,205139,94020, 1321386,772379,264277,109801, -886656,-14,-28 ],
  [ 2000,100,10,2,1, 369396,332115,45232,4565, 833603,538340,69467,24605, 1701132,916292,141761,65403, 2391467,1282659,199289,92401, 3084678,1711717,257056,114413, 2095432,32,0 ],
  [ 2000,100,15,2,1, 482745,363180,42911,10628, 1389823,896705,63174,22414, 2913818,1577286,132446,60751, 4269183,2406711,194054,84658, 5548816,3127265,252219,110070, 6179690,57,0 ],
  [ 2000,100,25,2,1, 563351,389131,40971,12671, 2565774,1708258,53733,17958, 6148875,3268152,118248,55399, 9430083,5098187,181348,83306, 12681399,7006089,243873,109141, 17053440,89,0 ],
  [ 2000,100,50,2,1, 570145,390195,40725,12854, 3763170,2528285,47435,15566, 11631205,6129256,105738,50018, 18360459,9852477,166913,77345, 25833184,14267622,234847,105141, 34630319,117,0 ],
  [ 2000,100,75,2,1, 570145,390195,40725,12854, 3784259,2532713,47303,15644, 11805834,6171523,105409,50306, 18648297,10009846,166503,77129, 26272107,14456507,234572,105496, 35357170,117,0 ],
  [ 2000,200,1,2,1, 85122,101646,85122,-16524, 102171,79573,102171,22599, 312753,134510,312753,178243, 244827,79965,244827,164862, 315585,194557,315585,121028, -2658171,-34,21 ],
  [ 2000,200,2,2,1, 138270,109209,69135,14531, 195785,94838,97893,50474, 410957,139548,205478,135704, 267048,80118,133524,93465, 344062,194595,172031,74733, -1961124,-28,15 ],
  [ 2000,200,3,2,1, 192482,118346,64161,24712, 289399,200943,96466,29485, 663782,403420,221261,86787, 734481,396487,244827,112665, 946754,563779,315585,127658, -1449325,-22,10 ],
  [ 2000,200,5,2,1, 274389,216124,59867,12712, 459962,350493,91992,21894, 994958,561424,198992,86707, 1212664,710358,242533,100461, 1562861,913520,312572,129868, -619338,-9,0 ],
  [ 2000,200,10,2,1, 442427,356476,54175,10525, 977972,682328,81498,24637, 2060762,1184422,171730,73028, 2827045,1589017,235587,103169, 3647668,2051949,303972,132977, 2853491,38,0 ],
  [ 2000,200,15,2,1, 573998,390292,51022,16329, 1626292,1132540,73922,22443, 3486340,1908105,158470,71738, 5045473,2798816,229340,102121, 6560835,3575010,298220,135719, 7965765,64,0 ],
  [ 2000,200,25,2,1, 667468,404848,48543,19100, 2993598,1996729,62693,20877, 7280051,3913002,140001,64751, 11134231,5970334,214120,99306, 14993137,8275502,288330,129185, 20939136,96,0 ],
  [ 2000,200,50,2,1, 675334,406074,48238,19233, 4383243,2883862,55251,18900, 13691320,7173683,124467,59251, 21648580,11573122,196805,91595, 30538433,16814299,277622,124765, 42008817,125,0 ],
  [ 2000,200,75,2,1, 675334,406074,48238,19233, 4407687,3007213,55096,17506, 13895189,7295813,124064,58923, 21987006,11738602,196313,91504, 31057091,17031516,277295,125228, 42353036,125,0 ],
  [ 2500,0,1,2,1, 48954,83074,48954,-34120, 81941,77812,81941,4129, 146017,78457,146017,67560, 201727,72844,201727,128883, 260273,101322,260273,158950, -3068016,-37,24 ],
  [ 2500,0,2,2,1, 97909,104271,48954,-3181, 163882,92589,81941,35646, 292034,194094,146017,48970, 403453,311115,201727,46169, 520546,359484,260273,80531, -2724866,-34,21 ],
  [ 2500,0,3,2,1, 146863,112132,48954,11577, 245823,195252,81941,16857, 438051,338630,146017,33140, 605180,375945,201727,76411, 780819,436746,260273,114691, -2287581,-30,18 ],
  [ 2500,0,5,2,1, 221597,137469,48348,18355, 396168,341517,79234,10930, 715428,397245,143086,63637, 999061,553171,199812,89178, 1289173,768748,257835,104085, -1125870,-18,-42 ],
  [ 2500,0,10,2,1, 375134,348033,45935,3319, 858158,559837,71513,24860, 1617363,892187,134780,60431, 2329225,1277235,194102,87666, 3010614,1703652,250885,108914, 1795590,30,0 ],
  [ 2500,0,15,2,1, 495530,367374,44047,11392, 1442463,911996,65567,24112, 2834233,1572134,128829,57368, 4162264,2209249,189194,88773, 5415692,3097536,246168,105371, 6222339,57,0 ],
  [ 2500,0,25,2,1, 581260,394489,42273,13583, 2692300,1861452,56383,17400, 6123871,3295434,117767,54393, 9238856,5008174,177670,81359, 12366831,6974168,237824,103705, 16549377,88,0 ],
  [ 2500,0,50,2,1, 588500,395653,42036,13775, 3973824,2731518,50090,15659, 11781268,6354472,107102,49335, 18123834,9890832,164762,74845, 25180162,14010126,228911,101546, 33844661,116,0 ],
  [ 2500,0,75,2,1, 588500,395653,42036,13775, 3996440,2736349,49956,15751, 11963088,6475591,106813,48996, 18412390,10049178,164396,74672, 25608191,14311260,228645,100865, 34322630,116,0 ],
  [ 2500,25,1,2,1, 54760,83951,54760,-29190, 86428,78422,86428,8006, 169870,82362,169870,87508, 211165,74424,211165,136740, 272467,103425,272467,169043, -2971038,-36,23 ],
  [ 2500,25,2,2,1, 105590,105431,52795,80, 171785,93807,85893,38989, 322585,199072,161293,61757, 422329,329242,211165,46544, 544934,363681,272467,90627, -2602868,-33,21 ],
  [ 2500,25,3,2,1, 156420,113575,52140,14282, 257143,197077,85714,20022, 475300,344682,158433,43540, 633494,380665,211165,84276, 817402,443036,272467,124788, -2096354,-29,17 ],
  [ 2500,25,5,2,1, 234013,139343,51057,20655, 413674,344469,82735,13841, 765245,419818,153049,69085, 1045804,560990,209161,96963, 1349541,779158,269908,114077, -881225,-14,-27 ],
  [ 2500,25,10,2,1, 393392,350789,48170,5217, 894250,566313,74521,27328, 1707271,921127,142273,65512, 2438120,1310239,203177,93990, 3151362,1827190,262614,110348, 2216735,33,0 ],
  [ 2500,25,15,2,1, 518343,384155,46075,11928, 1501580,936706,68254,25676, 2977363,1609339,135335,62183, 4356337,2445095,198015,86875, 5668697,3172083,257668,113482, 6664009,59,0 ],
  [ 2500,25,25,2,1, 607289,398418,44166,15191, 2799256,1895962,58623,18917, 6406665,3537586,123205,55175, 9664893,5311962,185863,83710, 12944765,7316189,248938,108242, 17309687,89,0 ],
  [ 2500,25,50,2,1, 614797,399622,43914,15370, 4128842,2849712,52044,16123, 12296297,6555667,111785,52188, 18945864,10368260,172235,77978, 26356474,14695255,239604,106011, 35630471,118,0 ],
  [ 2500,25,75,2,1, 614797,399622,43914,15370, 4152298,2868600,51904,16046, 12485426,6783813,111477,50907, 19247068,10448827,171849,78556, 26804437,14902722,239325,106265, 36051272,118,0 ],
  [ 2500,50,1,2,1, 60566,84827,60566,-24261, 90914,79032,90914,11882, 193723,86267,193723,107456, 220603,76005,220603,144598, 284662,189307,284662,95354, -2933794,-36,23 ],
  [ 2500,50,2,2,1, 113272,106591,56636,3341, 179689,95024,89844,42332, 353136,204050,176568,74543, 441206,332392,220603,54407, 569323,367877,284662,100723, -2458765,-32,19 ],
  [ 2500,50,3,2,1, 165978,115017,55326,16987, 268464,198902,89488,23187, 512550,350733,170850,53939, 661809,385386,220603,92141, 853985,533107,284662,106959, -1964861,-27,16 ],
  [ 2500,50,5,2,1, 246429,141218,53766,22955, 431181,347420,86236,16752, 815062,427875,163012,77437, 1092546,583735,218509,101762, 1409910,789518,281982,124078, -625184,-10,0 ],
  [ 2500,50,10,2,1, 411650,353545,50406,7115, 930342,572789,77529,29796, 1797178,935552,149765,71802, 2547014,1408524,212251,94874, 3292110,1866898,274342,118768, 2634694,37,0 ],
  [ 2500,50,15,2,1, 541157,387599,48103,13650, 1560698,1125881,70941,19764, 3120494,1722193,141841,63559, 4550409,2506999,206837,92882, 5921701,3343449,269168,117193, 6654091,59,0 ],
  [ 2500,50,25,2,1, 633318,402347,46059,16798, 2906212,2004322,60863,18888, 6689459,3611202,128643,59197, 10090929,5491864,194056,88444, 13522699,7544669,260052,114962, 18487401,92,0 ],
  [ 2500,50,50,2,1, 641094,403592,45792,16964, 4283861,2894056,53998,17519, 12811326,6848396,116467,54208, 19767894,10750854,179708,81973, 27532786,15283415,250298,111358, 37648271,120,0 ],
  [ 2500,50,75,2,1, 641094,403592,45792,16964, 4308155,2913116,53852,17438, 13007765,6909136,116141,54452, 20081745,10913306,179301,81861, 28000683,15591154,250006,110799, 38313275,121,0 ],
  [ 2500,100,1,2,1, 72179,86580,72179,-14401, 99887,80252,99887,19635, 241429,108592,241429,132837, 239479,79216,239479,160263, 309050,193562,309050,115488, -2773339,-35,22 ],
  [ 2500,100,2,2,1, 128636,108910,64318,9863, 195496,97459,97748,49018, 414238,334571,207119,39834, 478959,339243,239479,69858, 618101,392491,309050,112805, -2460612,-32,19 ],
  [ 2500,100,3,2,1, 185094,117903,61698,22397, 291104,202552,97035,29518, 587048,377350,195683,69899, 718438,394876,239479,107854, 927151,561410,309050,121914, -1627081,-24,12 ],
  [ 2500,100,5,2,1, 271261,216666,59184,11912, 466194,353324,93239,22574, 914695,535506,182939,75838, 1186032,707784,237206,95650, 1530647,909739,306129,124182, -803858,-13,-17 ],
  [ 2500,100,10,2,1, 448165,359057,54877,10911, 1002527,703975,83544,24879, 1976993,1174832,164749,66847, 2764803,1583493,230400,98442, 3573605,2043784,297800,127485, 2574841,36,0 ],
  [ 2500,100,15,2,1, 586783,394486,52159,17093, 1678932,1161716,76315,23510, 3406755,1902803,154852,68361, 4938554,2681662,224480,102586, 6427711,3560901,292169,130310, 7835133,63,0 ],
  [ 2500,100,25,2,1, 685376,423542,49846,19042, 3120123,2059307,65343,22216, 7255047,3927120,139520,63999, 10943003,5895396,210442,97069, 14678568,8244132,282280,123739, 20674815,96,0 ],
  [ 2500,100,50,2,1, 693688,424868,49549,19201, 4593897,3160795,57906,18064, 13841384,7355334,125831,58964, 21411954,11611077,194654,89099, 29885411,16556403,271686,121173, 41037066,124,0 ],
  [ 2500,100,75,2,1, 693688,424868,49549,19201, 4619869,3180199,57748,17996, 14052442,7524380,125468,58286, 21751100,11778085,194206,89045, 30393174,16871248,271368,120731, 41544315,124,0 ],
  [ 2500,200,1,2,1, 95403,103422,95403,-8020, 117834,82542,117834,35291, 336840,215727,336840,121114, 277232,100715,277232,176517, 357828,202171,357828,155657, -2672223,-34,21 ],
  [ 2500,200,2,2,1, 159364,113548,79682,22908, 227110,116214,113555,55448, 536443,368848,268221,83797, 554465,351895,277232,101285, 715656,409277,357828,153190, -1961287,-27,15 ],
  [ 2500,200,3,2,1, 223324,123674,74441,33217, 336386,314201,112129,7395, 736045,429085,245348,102320, 831697,428833,277232,134288, 1073484,586172,357828,162437, -1277900,-20,-52 ],
  [ 2500,200,5,2,1, 320925,224163,70020,21112, 536220,379016,107244,31441, 1113962,596617,222792,103469, 1373003,753937,274601,123813, 1772121,982570,354424,157910, 153687,8,0 ],
  [ 2500,200,10,2,1, 521197,370081,63820,18504, 1146895,729879,95575,34751, 2336623,1261562,194719,89588, 3200380,1765765,266698,119551, 4136595,2286496,344716,154175, 4431309,48,0 ],
  [ 2500,200,15,2,1, 678037,408261,60270,23980, 1915401,1233386,87064,31001, 3979276,2114406,180876,84767, 5714844,3058091,259766,120761, 7439730,4220937,338170,146309, 10342162,72,0 ],
  [ 2500,200,25,2,1, 789492,439259,57418,25472, 3547947,2361663,74303,24844, 8386224,4465770,161274,75393, 12647151,6847350,243214,111535, 16990306,9512995,326737,143794, 24643781,103,0 ],
  [ 2500,200,50,2,1, 798876,440747,57063,25581, 5213970,3530258,65722,21223, 15901499,8476611,144559,67499, 24700075,13331722,224546,103349, 34590659,19103031,314461,140797, 48195483,131,0 ],
  [ 2500,200,75,2,1, 798876,440747,57063,25581, 5243297,3536464,65541,21335, 16141797,8529755,144123,67965, 25089809,13549420,224016,103039, 35178158,19446257,314091,140463, 49125611,132,0 ],
  [ 3000,0,1,2,1, 59235,84850,59235,-25615, 97603,80781,97603,16822, 170105,82673,170105,87431, 234132,78617,234132,155515, 302516,192717,302516,109799, -2943200,-36,23 ],
  [ 3000,0,2,2,1, 59730,84937,29865,-12603, 97724,80781,48862,8472, 298893,195636,149447,51629, 240979,94877,120489,73051, 311442,194397,155721,58522, -2913713,-36,23 ],
  [ 3000,0,3,2,1, 118470,107823,39490,3549, 195206,98528,65069,32226, 466515,344087,155505,40810, 475111,339421,158370,45230, 613958,392563,204653,73798, -2413040,-32,19 ],
  [ 3000,0,5,2,1, 268133,217208,58502,11111, 472426,370039,94485,20477, 834433,509439,166887,64999, 1159400,705209,231880,90838, 1498433,905908,299687,118505, -1031962,-16,-34 ],
  [ 3000,0,10,2,1, 453903,361638,55580,11298, 1027081,711737,85590,26279, 1893224,1073876,157769,68279, 2702561,1577919,225213,93720, 3499541,2035619,291628,121994, 2549773,36,0 ],
  [ 3000,0,15,2,1, 599568,398680,53295,17857, 1731572,1190892,78708,24576, 3327169,1776935,151235,70465, 4831635,2672418,219620,98146, 6294587,3546243,286118,124925, 7823306,63,0 ],
  [ 3000,0,25,2,1, 703284,428900,51148,19955, 3246649,2300236,67993,19820, 7230044,3954253,139039,62996, 10751776,5885690,206765,93579, 14364000,8128381,276231,119916, 19834597,94,0 ],
  [ 3000,0,50,2,1, 712042,430326,50860,20123, 4804552,3273563,60562,19298, 13991447,7658900,127195,57569, 21175329,11664407,192503,86463, 29232388,16396777,265749,116687, 40266386,123,0 ],
  [ 3000,0,75,2,1, 712042,430326,50860,20123, 4832051,3279484,60401,19407, 14209696,7709383,126872,58039, 21515193,11831844,192100,86458, 29729258,16612861,265440,117111, 41173802,124,0 ],
  [ 3000,25,1,2,1, 65041,85727,65041,-20685, 102090,81391,102090,20699, 193958,86578,193958,107379, 243570,95174,243570,148396, 314711,194819,314711,119891, -2868325,-35,22 ],
  [ 3000,25,2,2,1, 126152,108983,63076,8585, 203110,99745,101555,51682, 370760,313555,185380,28603, 487140,341288,243570,72926, 629421,395080,314711,117170, -2490318,-32,20 ],
  [ 3000,25,3,2,1, 187263,118902,62421,22787, 304130,205985,101377,32715, 547563,371846,182521,58572, 730710,413012,243570,105899, 944132,565381,314711,126250, -1669215,-24,13 ],
  [ 3000,25,5,2,1, 280549,219082,61211,13411, 489932,372991,97986,23388, 884250,532011,176850,70448, 1206142,713028,241228,98623, 1558802,916318,311760,128497, -787318,-12,-14 ],
  [ 3000,25,10,2,1, 472161,364394,57816,13196, 1063174,718213,88598,28747, 1983131,1179816,165261,66943, 2811455,1595947,234288,101292, 3640289,2075377,303357,130409, 2876258,38,0 ],
  [ 3000,25,15,2,1, 622382,402124,55323,19578, 1790690,1201867,81395,26765, 3470300,1920340,157741,70453, 5025708,2828306,228441,99882, 6547592,3818060,297618,124070, 8096032,64,0 ],
  [ 3000,25,25,2,1, 622382,402124,45264,16019, 1790690,1201867,37501,12331, 6369334,3531074,122487,54582, 5206878,2953633,100132,43332, 6789726,3990475,130572,53832, 11174348,74,0 ],
  [ 3000,25,50,2,1, 622382,402930,44456,15675, 1790690,1775039,22572,197, 6369334,4617093,57903,15929, 5206878,2953771,47335,20483, 6789726,3990504,61725,25447, 6866712,60,0 ],
  [ 3000,25,75,2,1, 738339,434295,52739,21717, 4987908,3502200,62349,18571, 14732034,8016256,131536,59962, 22349871,12216516,199552,90476, 30925504,17316963,276121,121505, 42560879,125,0 ],
  [ 3000,50,1,2,1, 70847,86603,70847,-15756, 106576,82001,106576,24575, 217811,104998,217811,112812, 253008,96805,253008,156204, 326905,196972,326905,129933, -2804848,-35,22 ],
  [ 3000,50,2,2,1, 133834,110142,66917,11846, 211013,114848,105507,48083, 401311,332898,200656,34207, 506016,344489,253008,80764, 653810,399277,326905,127267, -2423300,-32,19 ],
  [ 3000,50,3,2,1, 196821,120345,65607,25492, 315450,207810,105150,35880, 584812,377898,194937,68972, 759025,417732,253008,113764, 980715,571671,326905,136348, -1477988,-22,10 ],
  [ 3000,50,5,2,1, 292965,220956,63920,15711, 507439,375943,101488,26299, 934066,540069,186813,78799, 1252885,735774,250577,103422, 1619171,942349,323834,135364, -542450,-8,0 ],
  [ 3000,50,10,2,1, 490419,367151,60051,15094, 1099266,724689,91605,31215, 2073039,1194241,172753,73233, 2920350,1628901,243362,107621, 3781036,2198865,315086,131848, 3330904,41,0 ],
  [ 3000,50,15,2,1, 645195,405568,57351,21300, 1849807,1226726,84082,28322, 3613430,2034545,164247,71768, 5219780,2875234,237263,106570, 6800596,3876786,309118,132900, 8705669,66,0 ],
  [ 3000,50,25,2,1, 755342,436758,54934,23170, 3460561,2369256,72472,22855, 7795632,4163971,149916,69840, 11603850,6368881,223151,100672, 15519868,8698382,298459,131182, 22251779,99,0 ],
  [ 3000,50,50,2,1, 764636,438265,54617,23312, 5114588,3540452,64470,19842, 15021505,8074324,136559,63156, 22819389,12509453,207449,93727, 31585013,17669566,287136,126504, 43941975,127,0 ],
  [ 3000,50,75,2,1, 764636,438265,54617,23312, 5143765,3546717,64297,19963, 15254373,8218578,136200,62820, 23184548,12615665,207005,94365, 32121750,17907976,286801,126909, 44812263,128,0 ],
  [ 3000,100,1,2,1, 82459,101693,82459,-19233, 115550,83071,115550,32478, 265516,112808,265516,152708, 271885,100116,271885,171769, 351294,201326,351294,149967, -2665585,-34,21 ],
  [ 3000,100,2,2,1, 149198,112462,74599,18368, 226820,117283,113410,54769, 462414,342854,231207,59780, 543769,350789,271885,96490, 702587,407669,351294,147459, -2135095,-29,17 ],
  [ 3000,100,3,2,1, 215936,123231,71979,30902, 338091,315810,112697,7427, 659311,403015,219770,85432, 815654,427222,271885,129477, 1053881,583802,351294,156693, -1455656,-22,10 ],
  [ 3000,100,5,2,1, 317797,224705,69338,20311, 542452,381846,108490,32121, 1033700,570700,206740,92600, 1346370,751362,269274,119002, 1739908,963119,347982,155358, -19661,4,0 ],
  [ 3000,100,10,2,1, 526935,385999,64523,17257, 1171450,825226,97621,28852, 2252854,1251971,187738,83407, 3138138,1760391,261512,114812, 4062532,2262810,338544,149977, 3875359,45,0 ],
  [ 3000,100,15,2,1, 690822,425792,61406,23558, 1968041,1248526,89456,32705, 3899691,2109254,177259,81384, 5607925,3049547,254906,116290, 7306606,4093938,332118,146030, 10120979,71,0 ],
  [ 3000,100,25,2,1, 807401,529653,58720,20200, 3674472,2498091,76952,24636, 8361220,4494403,160793,74362, 12455924,6772463,239537,109297, 16675737,9382223,320687,140260, 23889101,101,0 ],
  [ 3000,100,50,2,1, 817231,531241,58374,20428, 5424625,3702840,68378,21703, 16051563,8582762,145923,67898, 24463449,13369676,222395,100852, 33937637,19026835,308524,135553, 47303673,130,0 ],
  [ 3000,100,75,2,1, 817231,531241,58374,20428, 5455479,3813799,68193,20521, 16299050,8833823,145527,66654, 24853903,13588903,221910,100580, 34514242,19271850,308163,136093, 47529510,130,0 ],
  [ 3000,200,1,2,1, 105684,105198,105684,485, 133496,85512,133496,47984, 360928,219943,360928,140985, 309638,186796,309638,122842, 400071,322876,400071,77195, -2686829,-34,21 ],
  [ 3000,200,2,2,1, 179925,117100,89963,31413, 258434,196003,129217,31216, 584618,391796,292309,96411, 619275,378467,309638,120404, 800142,440226,400071,179958, -1892745,-27,15 ],
  [ 3000,200,3,2,1, 254167,142338,84722,37276, 383372,336844,127791,15509, 808308,441585,269436,122241, 928913,541587,309638,129109, 1200213,737876,400071,154112, -1054959,-17,-42 ],
  [ 3000,200,5,2,1, 367461,333068,80173,7504, 612478,392153,122496,44065, 1232967,738011,246593,98991, 1533341,879822,306668,130704, 1981382,1234871,396276,149302, 63526,6,0 ],
  [ 3000,200,10,2,1, 599966,397024,73465,24850, 1315819,865014,109652,37567, 2612484,1415552,217707,99744, 3573716,1985792,297810,132327, 4625522,2618564,385460,167247, 5367461,53,0 ],
  [ 3000,200,15,2,1, 782076,439567,69518,30445, 2204510,1498396,100205,32096, 4472213,2439923,203282,92377, 6384215,3426526,290192,134440, 8318625,4754024,378119,162027, 11788728,76,0 ],
  [ 3000,200,25,2,1, 911517,545370,66292,26629, 4102296,2800447,85912,27264, 9492397,5139104,182546,83717, 14160071,7753069,272309,123212, 18987474,10538496,365144,162480, 27651772,107,0 ],
  [ 3000,200,50,2,1, 922419,547119,65887,26807, 6044698,4162768,76194,23722, 18111678,9702689,164652,76445, 27751569,15010014,252287,115832, 38642886,21573012,351299,155181, 54297576,136,0 ],
  [ 3000,200,75,2,1, 922419,547119,65887,26807, 6078907,4170064,75986,23861, 18388404,9762198,164182,77020, 28192612,15317109,251720,114960, 39299225,21831188,350886,155965, 55362131,137,0 ],
  [ 1000,0,1,3,1, 26081,78861,26081,-52780, 46568,56904,46568,-10335, 90405,68549,90405,21856, 121478,58298,121478,63180, 148793,65448,148793,83345, -3445834,-39,26 ],
  [ 1000,0,2,3,1, 52162,82507,26081,-15173, 93137,78694,46568,7222, 180810,82464,90405,49173, 242957,78080,121478,82438, 297587,190016,148793,53785, -2932818,-36,23 ],
  [ 1000,0,3,3,1, 78243,86154,26081,-2637, 139705,86599,46568,17702, 271216,111043,90405,53391, 364435,193196,121478,57080, 446380,343994,148793,34129, -2571245,-33,20 ],
  [ 1000,0,5,3,1, 118064,105061,25759,2837, 223300,114858,44660,21688, 440206,334595,88041,21122, 603284,355968,120657,49463, 738043,409399,147609,65729, -2163415,-29,17 ],
  [ 1000,0,10,3,1, 118064,105061,14457,1592, 223300,114858,18608,9037, 813959,420401,67830,32796, 620935,374222,51745,20559, 760205,429215,63350,27582, -1502847,-22,11 ],
  [ 1000,0,15,3,1, 264116,138861,23477,11134, 773615,523409,35164,11373, 1683950,883838,76543,36369, 2533083,1287673,115140,56610, 3126132,1701233,142097,64768, 2589378,36,0 ],
  [ 1000,0,25,3,1, 309868,145287,22536,11969, 1394032,890368,29194,10548, 3523143,1744942,67753,34196, 5573379,2857905,107180,52221, 7223736,3872215,138918,64452, 9334338,69,0 ],
  [ 1000,0,50,3,1, 313736,145832,22410,11993, 2015658,1259417,25407,9532, 6545362,3256449,59503,29899, 10731871,5470666,97562,47829, 14870062,7881911,135182,63529, 19953326,95,0 ],
  [ 1000,0,75,3,1, 313736,145832,22410,11993, 2026615,1275449,25333,9390, 6639773,3269968,59284,30088, 10895774,5510917,97284,48079, 15126249,8067651,135056,63023, 20355393,95,0 ],
  [ 1000,25,1,3,1, 33498,79980,33498,127554, 52403,57705,52403,729476, 117608,73001,117608,3688475, 132449,60137,132449,5754142, 162380,67792,162380,-8608577, 2818496,40,0 ],
  [ 1000,25,2,3,1, 62280,84035,31140,-10877, 103522,80292,51761,11615, 216224,88231,108112,63997, 264898,96722,132449,84088, 324760,194695,162380,65033, -2780952,-35,22 ],
  [ 1000,25,3,3,1, 91062,101426,30354,-3455, 154642,88845,51547,21932, 314839,195126,104946,39905, 397347,307297,132449,30017, 487141,351157,162380,45328, -2729943,-34,21 ],
  [ 1000,25,5,3,1, 135000,107618,29454,5974, 246508,118745,49302,25553, 499318,344150,99864,31034, 657718,379998,131544,55544, 805408,436637,161082,73754, -1856282,-26,15 ],
  [ 1000,25,10,3,1, 225257,120349,27582,12846, 519003,374148,43250,12071, 1085059,552922,90422,44345, 1543471,866359,128623,56426, 1888338,992107,157362,74686, 344513,11,0 ],
  [ 1000,25,15,3,1, 296024,143678,26313,13542, 853019,538077,38774,14316, 1859528,926356,84524,42417, 2761316,1435510,125514,60264, 3410743,1849177,155034,70980, 3426237,42,0 ],
  [ 1000,25,25,3,1, 346406,222503,25193,9011, 1538820,932096,32227,12706, 3875987,2012662,74538,35833, 6078691,3174685,116898,55846, 7878572,4242949,151511,69916, 10168145,71,0 ],
  [ 1000,25,50,3,1, 350660,223106,25047,9111, 2226491,1506743,28065,9072, 7197726,3583531,65434,32856, 11715886,5989821,106508,52055, 16213183,8626278,147393,68972, 21529146,98,0 ],
  [ 1000,25,75,3,1, 350660,223106,25047,9111, 2238602,1509126,27983,9118, 7301660,3613185,65193,32933, 11895193,6141051,106207,51376, 16492428,8770957,147254,68942, 21867464,98,0 ],
  [ 1000,50,1,3,1, 40916,81100,40916,-40184, 58238,58505,58238,-268, 144811,77452,144811,67360, 143420,61976,143420,81443, 175967,70137,175967,105830, -3215089,-38,24 ],
  [ 1000,50,2,3,1, 72399,85562,36200,-6582, 113908,81891,56954,16009, 251637,108512,125819,71562, 286839,180744,143420,53047, 351934,199424,175967,76255, -2759007,-34,22 ],
  [ 1000,50,3,3,1, 103882,103361,34627,174, 169579,91241,56526,26113, 358463,202208,119488,52085, 430259,312788,143420,39157, 527901,358171,175967,56577, -2497375,-32,20 ],
  [ 1000,50,5,3,1, 151936,110174,33150,9112, 269716,196481,53943,14647, 558431,368221,111686,38042, 712152,389102,142430,64610, 872774,532034,174555,68148, -1842888,-26,14 ],
  [ 1000,50,10,3,1, 250605,137512,30686,13848, 567223,382595,47269,15386, 1194003,690957,99500,41921, 1670990,888120,139249,65239, 2046113,1232302,170509,67818, 533045,14,0 ],
  [ 1000,50,15,3,1, 327932,220195,29150,9577, 932423,566629,42383,16627, 2035105,1074774,92505,43651, 2989548,1597022,135889,63297, 3695355,2042551,167971,75127, 3628633,43,0 ],
  [ 1000,50,25,3,1, 382945,328885,27851,3932, 1683608,1150523,35259,11164, 4228831,2110347,81324,40740, 6584003,3384055,126615,61537, 8533408,4698013,164104,73758, 10810576,74,0 ],
  [ 1000,50,50,3,1, 387585,329547,27685,4146, 2437324,1560483,30723,11053, 7850090,3926778,71364,35666, 12699900,6523702,115454,56147, 17556304,9440855,159603,73777, 23487755,101,0 ],
  [ 1000,50,75,3,1, 387585,329547,27685,4146, 2450588,1563102,30632,11094, 7963546,3957901,71103,35765, 12894612,6649249,115130,55762, 17858607,9521244,159452,74441, 23970355,102,0 ],
  [ 1000,100,1,3,1, 55751,83339,55751,-27589, 69907,60107,69907,9800, 199218,100869,199218,98349, 165361,65704,165361,99656, 203141,74875,203141,128266, -3017845,-36,23 ],
  [ 1000,100,2,3,1, 92636,101954,46318,-4659, 134680,84938,67340,24871, 322464,197046,161232,62709, 330721,188225,165361,71248, 406282,322022,203141,42130, -2722890,-34,21 ],
  [ 1000,100,3,3,1, 129522,107232,43174,7430, 199452,96033,66484,34473, 445711,336938,148570,36258, 496082,339297,165361,52262, 609423,372748,203141,78892, -2333222,-31,19 ],
  [ 1000,100,5,3,1, 185807,115287,40540,15386, 316131,204253,63226,22376, 676657,401847,135331,54962, 821021,422237,164204,79757, 1007505,570888,201501,87323, -1228846,-19,-52 ],
  [ 1000,100,10,3,1, 301301,216865,36894,10339, 663663,413674,55305,20832, 1411890,754775,117658,54760, 1926029,960296,160502,80478, 2361664,1301930,196805,88311, 1420382,26,0 ],
  [ 1000,100,15,3,1, 391748,330695,34822,5427, 1091230,712700,49601,17206, 2386261,1235160,108466,52323, 3446013,1783934,156637,75549, 4264578,2271279,193844,90605, 4553267,49,0 ],
  [ 1000,100,25,3,1, 456022,353253,33165,7474, 1973184,1233829,41323,15484, 4934518,2462736,94895,47534, 7594627,4002289,146051,69083, 9843080,5310368,189290,87168, 13547249,81,0 ],
  [ 1000,100,50,3,1, 461434,354031,32960,7672, 2858991,1862899,36038,12556, 9154817,4491057,83226,42398, 14667929,7546735,133345,64738, 20242545,10801078,184023,85832, 28102443,108,0 ],
  [ 1000,100,75,3,1, 461434,354031,32960,7672, 2874561,1865991,35932,12607, 9287320,4602119,82923,41832, 14893450,7596914,132977,65148, 20590965,10972887,183848,85876, 28575677,109,0 ],
  [ 1000,200,1,3,1, 85421,87818,85421,-2397, 93246,77196,93246,16050, 308030,133039,308030,174991, 209243,73111,209243,136132, 257489,84253,257489,173236, -2633439,-33,21 ],
  [ 1000,200,2,3,1, 133111,108064,66555,12524, 176222,91332,88111,42445, 464119,355194,232059,54463, 418486,311945,209243,53270, 514978,357009,257489,78985, -2510635,-33,20 ],
  [ 1000,200,3,3,1, 180801,114973,60267,21943, 259199,193352,86400,21949, 620207,394148,206736,75353, 627729,376338,209243,83797, 772466,416523,257489,118648, -1780593,-26,14 ],
  [ 1000,200,5,3,1, 253551,125514,55320,27935, 408962,338032,81792,14186, 913108,529935,182622,76634, 1038758,553787,207752,96994, 1276968,745417,255394,106310, -679843,-11,0 ],
  [ 1000,200,10,3,1, 402694,333037,49309,8529, 856542,534298,71379,26854, 1847665,945048,153972,75218, 2436106,1279889,203009,96351, 2992766,1670899,249397,110156, 2616949,36,0 ],
  [ 1000,200,15,3,1, 519381,363298,46167,13874, 1408845,872841,64038,24364, 3088572,1602498,140390,67549, 4358944,2402331,198134,88937, 5403025,2969286,245592,110625, 6955477,60,0 ],
  [ 1000,200,25,3,1, 602177,388652,43795,15529, 2552335,1652640,53452,18842, 6345894,3243015,122036,59671, 9615875,5051640,184921,87774, 12462424,6776281,239662,109349, 18208111,91,0 ],
  [ 1000,200,50,3,1, 609132,389663,43509,15676, 3702324,2363531,46668,16876, 11764271,5924730,106948,53087, 18603986,9606878,169127,81792, 25615029,13803575,232864,107377, 36792832,119,0 ],
  [ 1000,200,75,3,1, 609132,389663,43509,15676, 3722508,2367568,46531,16937, 11934867,5964670,106561,53305, 18891125,9790372,168671,81257, 26055681,13906883,232640,108471, 37536804,120,0 ],
  [ 1500,0,1,3,1, 40885,81418,40885,-40533, 67436,60803,67436,6632, 119931,73720,119931,46211, 159145,65018,159145,94127, 195861,73940,195861,121921, -3212967,-38,24 ],
  [ 1500,0,2,3,1, 81771,100959,40885,-9594, 134871,86343,67436,24264, 239862,92804,119931,73529, 318290,186953,159145,65668, 391721,320240,195861,35741, -2743037,-34,22 ],
  [ 1500,0,3,3,1, 122656,107163,40885,5164, 202307,98147,67436,34720, 359793,203554,119931,52080, 477434,336941,159145,46831, 587582,369619,195861,72654, -2286013,-31,18 ],
  [ 1500,0,5,3,1, 185076,116637,40380,14932, 325083,207995,65017,23418, 586446,374713,117289,42347, 790024,419264,158005,74152, 971573,566689,194315,80977, -1429368,-22,10 ],
  [ 1500,0,10,3,1, 313337,221152,38368,11288, 697517,424079,58126,22787, 1317646,730143,109804,48959, 1853300,953836,154442,74955, 2278753,1292829,189896,82160, 1216405,23,0 ],
  [ 1500,0,15,3,1, 413932,350640,36794,5626, 1163447,820938,52884,15569, 2297682,1230345,104440,48515, 3320665,1773978,150939,70304, 4115011,2255639,187046,84517, 3990281,46,0 ],
  [ 1500,0,25,3,1, 485583,361538,35315,9021, 2146783,1487413,44959,13809, 4916625,2572895,94550,45072, 7369627,3897695,141724,66768, 9486995,5190586,182442,82623, 12741150,79,0 ],
  [ 1500,0,50,3,1, 491637,362459,35117,9227, 3148246,2070515,39684,13585, 9366180,4829918,85147,41239, 14391149,7587983,130829,61847, 19498353,10535930,177258,81477, 27344944,107,0 ],
  [ 1500,0,75,3,1, 491637,362459,35117,9227, 3165916,2088046,39574,13473, 9508163,4866264,84894,41446, 14617647,7762810,130515,61204, 19834225,10819817,177091,80486, 27700724,107,0 ],
  [ 1500,25,1,3,1, 48303,82538,48303,-34235, 73270,75489,73270,-2219, 147134,78021,147134,69113, 170115,66857,170115,103259, 209448,76234,209448,133213, -3141178,-37,24 ],
  [ 1500,25,2,3,1, 91889,102486,45945,-5299, 145257,87941,72628,28658, 275276,113086,137638,81095, 340231,190668,170115,74781, 418895,324969,209448,46963, -2602567,-33,21 ],
  [ 1500,25,3,3,1, 135476,109098,45159,8792, 217244,114428,72415,34272, 403417,331052,134472,24122, 510346,342982,170115,55788, 628343,392803,209448,78513, -2387080,-31,19 ],
  [ 1500,25,5,3,1, 202012,119194,44075,18069, 348291,316231,69658,6412, 645558,382769,129112,52558, 844458,428318,168892,83228, 1038939,577756,207788,92237, -1415579,-21,10 ],
  [ 1500,25,10,3,1, 338685,224978,41472,13923, 745737,506526,62145,19934, 1426590,839128,118882,48955, 1980819,1098384,165068,73536, 2436528,1335504,203044,91752, 1252918,24,0 ],
  [ 1500,25,15,3,1, 445840,355457,39630,8034, 1242851,835606,56493,18511, 2473259,1272863,112421,54563, 3548898,1949967,161314,72679, 4399622,2532344,199983,84876, 4693785,50,0 ],
  [ 1500,25,25,3,1, 522122,380390,37972,10308, 2291570,1528991,47991,15970, 5269469,2763615,101336,48189, 7874939,4214625,151441,70391, 10141831,5629579,195035,86774, 13942184,82,0 ],
  [ 1500,25,50,3,1, 528561,381370,37754,10514, 3359079,2317691,42341,13127, 10018543,5171515,91078,44064, 15375164,8107338,139774,66071, 20841474,11348606,189468,86299, 29077995,109,0 ],
  [ 1500,25,75,3,1, 528561,381370,37754,10514, 3377903,2321572,42224,13204, 10170050,5271966,90804,43733, 15617066,8269608,139438,65602, 21200404,11552533,189289,86142, 29470368,110,0 ],
  [ 1500,50,1,3,1, 55720,83657,55720,-27937, 79105,76290,79105,2815, 174337,82472,174337,91865, 181086,68696,181086,112390, 223035,78578,223035,144456, -3025806,-36,23 ],
  [ 1500,50,2,3,1, 102008,104014,51004,-1003, 155643,89540,77821,33051, 310689,195853,155345,57418, 362172,194333,181086,83919, 446069,345319,223035,50375, -2616269,-33,21 ],
  [ 1500,50,3,3,1, 148296,111034,49432,12421, 232180,116825,77393,38452, 447041,338134,149014,36302, 543258,348473,181086,64928, 669104,399817,223035,89762, -2154512,-29,17 ],
  [ 1500,50,5,3,1, 218947,121750,47770,21207, 371499,334002,74300,7499, 704671,406689,140934,59596, 898892,517730,179778,76233, 1106304,589322,221261,103396, -1270778,-20,-51 ],
  [ 1500,50,10,3,1, 364033,329671,44575,4208, 793957,527508,66163,22204, 1535534,856447,127961,56591, 2108338,1201253,175695,75590, 2594304,1463359,216192,94245, 1279872,24,0 ],
  [ 1500,50,15,3,1, 477748,360274,42466,10442, 1322255,864159,60102,20823, 2648837,1392231,120402,57118, 3777130,2082827,171688,77014, 4684234,2596457,212920,94899, 5392400,53,0 ],
  [ 1500,50,25,3,1, 558660,385905,40630,12564, 2436358,1570718,51023,18129, 5622313,2925285,108121,51866, 8380251,4530905,161159,74026, 10796667,5999812,207628,92247, 15315353,85,0 ],
  [ 1500,50,50,3,1, 565486,386944,40392,12753, 3569912,2372931,44999,15088, 10670907,5577247,97008,46306, 16359178,8639669,148720,70177, 22184595,12093524,201678,91737, 31355840,112,0 ],
  [ 1500,50,75,3,1, 565486,386944,40392,12753, 3589889,2390934,44874,14987, 10831937,5616532,96714,46566, 16616485,8776306,148361,70002, 22566582,12271960,201487,91916, 31964247,113,0 ],
  [ 1500,100,1,3,1, 70555,85897,70555,-15342, 90774,77892,90774,12883, 228744,105890,228744,122854, 203027,72424,203027,130603, 250208,83317,250208,166891, -2828561,-35,22 ],
  [ 1500,100,2,3,1, 122245,107069,61123,7588, 176414,92737,88207,41839, 381516,221902,190758,79807, 406054,310173,203027,47940, 500417,354726,250208,72845, -2461903,-32,19 ],
  [ 1500,100,3,3,1, 173935,114904,57978,19677, 262054,195467,87351,22196, 534289,366814,178096,55825, 609081,374482,203027,78200, 750625,413894,250208,112244, -1978762,-28,16 ],
  [ 1500,100,5,3,1, 252819,140200,55161,24571, 417914,341624,83583,15258, 822897,425801,164579,79419, 1007761,550964,201552,91359, 1241036,741367,248207,99934, -758561,-12,-20 ],
  [ 1500,100,10,3,1, 414729,350660,50783,7845, 890397,544553,74200,28820, 1753421,920416,146118,69417, 2363377,1273579,196948,90816, 2909855,1661948,242488,103992, 2358279,34,0 ],
  [ 1500,100,15,3,1, 541564,369907,48139,15258, 1481062,907379,67321,26077, 2999993,1583168,136363,64401, 4233595,2203408,192436,92281, 5253458,2937776,238794,105258, 7004237,60,0 ],
  [ 1500,100,25,3,1, 631738,396937,45945,17076, 2725934,1728024,57088,20899, 6328000,3277675,121692,58660, 9390875,4960221,180594,85205, 12106339,6612018,232814,105660, 18151537,91,0 ],
  [ 1500,100,50,3,1, 639335,398092,45667,17232, 3991579,2675497,50314,16589, 11975634,6156041,108869,52905, 18327207,9662603,166611,78769, 24870837,13521756,226099,103173, 35943160,118,0 ],
  [ 1500,100,75,3,1, 639335,398092,45667,17232, 4013863,2680088,50173,16672, 12155710,6304465,108533,52243, 18615323,9832831,166208,78415, 25298940,13753363,225883,103086, 36384493,119,0 ],
  [ 1500,200,1,3,1, 100225,103712,100225,-3487, 114113,80945,114113,33168, 337556,215209,337556,122347, 246909,79980,246909,166929, 304556,192346,304556,112210, -2702670,-34,21 ],
  [ 1500,200,2,3,1, 162720,113178,81360,24771, 217957,99131,108978,59413, 523170,365534,261585,78818, 493818,340361,246909,76729, 609112,389663,304556,109725, -2078178,-29,16 ],
  [ 1500,200,3,3,1, 225214,122645,75071,34190, 321800,205051,107267,38916, 708784,409659,236261,99708, 740728,396497,246909,114744, 913669,541449,304556,124073, -1152899,-18,-55 ],
  [ 1500,200,5,3,1, 320563,222126,69941,21477, 510745,357168,102149,30715, 1059348,570054,211870,97859, 1225498,710717,245100,102956, 1510498,902857,302100,121528, -198663,-1,0 ],
  [ 1500,200,10,3,1, 320563,222126,39253,12053, 510745,357168,42562,12798, 1059348,570054,88279,40774, 1260936,732073,105078,44072, 1559414,927456,129951,52663, -160543,0,0 ],
  [ 1500,200,15,3,1, 669196,402510,59484,23705, 1798677,1171870,81758,28491, 3702304,2026005,168287,76195, 5146525,2806829,233933,106350, 6391904,3522192,290541,130441, 9006064,67,0 ],
  [ 1500,200,25,3,1, 777892,432336,56574,25131, 3305085,2072985,69216,25803, 7739376,4057954,148834,70797, 11412124,6103055,219464,102097, 14725683,8077931,283186,127841, 22908210,100,0 ],
  [ 1500,200,50,3,1, 787033,433724,56217,25236, 4834912,3176129,60944,20909, 14585088,7498199,132592,64426, 22263264,11722696,202393,95823, 30243320,16440422,274939,125481, 44903281,128,0 ],
  [ 1500,200,75,3,1, 787033,433724,56217,25236, 4861809,3181665,60773,21002, 14803258,7623451,132172,64105, 22612998,11917279,201902,95497, 30763656,16770590,274676,124938, 45548340,128,0 ],
  [ 2000,0,1,3,1, 55690,83975,55690,-28286, 88303,78588,88303,9715, 149457,78740,149457,70717, 196811,71737,196811,125074, 242928,82381,242928,160547, -3023683,-36,23 ],
  [ 2000,0,2,3,1, 111379,106074,55690,2653, 176606,94142,88303,41232, 298914,194660,149457,52127, 393622,308902,196811,42360, 485856,352944,242928,66456, -2658548,-34,21 ],
  [ 2000,0,3,3,1, 167069,114835,55690,17411, 264909,197581,88303,22443, 448370,339480,149457,36297, 590433,372626,196811,72603, 728784,411265,242928,105840, -2176931,-30,17 ],
  [ 2000,0,5,3,1, 252087,141550,55001,24117, 426866,345366,85373,16300, 732686,413181,146537,63901, 976763,548041,195353,85744, 1205104,737218,241021,93577, -992583,-16,-39 ],
  [ 2000,0,10,3,1, 426765,354947,52257,8794, 924251,568842,77021,29617, 1659177,895634,138265,63629, 2290648,1267120,190887,85294, 2826943,1652797,235579,97845, 2110718,32,0 ],
  [ 2000,0,15,3,1, 563747,389853,50111,15457, 1553280,1046118,70604,23053, 2911413,1576852,132337,60662, 4108247,2193452,186738,87036, 5103890,2921636,231995,99193, 6348412,57,0 ],
  [ 2000,0,25,3,1, 661299,418558,48094,17654, 2899533,1995343,60723,18936, 6310107,3310684,121348,57681, 9165876,4964237,176267,80801, 11750255,6576116,225966,99503, 17203851,89,0 ],
  [ 2000,0,50,3,1, 669538,419857,47824,17834, 4280833,2896848,53960,17445, 12186997,6480237,110791,51880, 18050427,9703950,164095,75877, 24126644,13369748,219333,97790, 35040253,117,0 ],
  [ 2000,0,75,3,1, 669538,419857,47824,17834, 4305217,2901992,53815,17540, 12376553,6525045,110505,52246, 18339521,9889866,163746,75443, 24542200,13570583,219127,97961, 35738199,118,0 ],
  [ 2000,25,1,3,1, 63107,85095,63107,-21988, 94138,79389,94138,14749, 176660,83191,176660,93469, 207782,73576,207782,134206, 256515,100397,256515,156118, -2919484,-36,23 ],
  [ 2000,25,2,3,1, 121498,107601,60749,6948, 186991,95740,93496,45625, 334327,200427,167164,66950, 415563,327543,207782,44010, 513030,357623,256515,77703, -2506682,-32,20 ],
  [ 2000,25,3,3,1, 179889,116771,59963,21039, 279845,199977,93282,26623, 491994,346563,163998,48477, 623345,378117,207782,81743, 769545,433950,256515,111865, -1955536,-27,15 ],
  [ 2000,25,5,3,1, 269023,144106,58696,27255, 450074,349252,90015,20164, 791798,422737,158360,73812, 1031197,557095,206239,94820, 1272469,764456,254494,101603, -663346,-11,0 ],
  [ 2000,25,10,3,1, 452113,358774,55361,11429, 972471,681789,81039,24224, 1768121,926118,147343,70167, 2418167,1288231,201514,94161, 2984719,1694972,248727,107479, 2413066,35,0 ],
  [ 2000,25,15,3,1, 595655,394669,52947,17865, 1632683,1148521,74213,22007, 3086991,1696221,140318,63217, 4336479,2434772,197113,86441, 5388502,3001420,244932,108504, 6641999,59,0 ],
  [ 2000,25,25,3,1, 697837,424073,50752,19910, 3044321,2037071,63755,21094, 6662951,3578403,128134,59318, 9671188,5281017,185984,84426, 12405091,6946850,238559,104966, 18331642,91,0 ],
  [ 2000,25,50,3,1, 706463,425431,50462,20074, 4491667,3056439,56618,18091, 12839361,6821984,116721,54703, 19034442,10251308,173040,79847, 25469765,14001125,231543,104260, 37193004,119,0 ],
  [ 2000,25,75,3,1, 706463,425431,50462,20074, 4517204,3061819,56465,18192, 13038440,6868262,116415,55091, 19338939,10396564,172669,79843, 25908379,14303199,231325,103618, 37939315,120,0 ],
  [ 2000,50,1,3,1, 70525,86215,70525,-15690, 99972,80190,99972,19783, 203863,102157,203863,101706, 218752,75465,218752,143287, 270102,102791,270102,167311, -2837612,-35,22 ],
  [ 2000,50,2,3,1, 131617,109129,65808,11244, 197377,97339,98689,50019, 369741,220709,184870,74516, 437505,331259,218752,53123, 540204,362352,270102,88926, -2366212,-31,19 ],
  [ 2000,50,3,3,1, 192709,118706,64236,24668, 294782,202373,98261,30803, 535618,368160,178539,55819, 656257,383658,218752,90866, 810306,441013,270102,123098, -1756469,-25,13 ],
  [ 2000,50,5,3,1, 285959,218363,62391,14748, 473282,353138,94656,24029, 850911,523808,170182,65421, 1085632,581176,217126,100891, 1339835,776072,267967,112753, -849022,-13,-24 ],
  [ 2000,50,10,3,1, 477461,362600,58465,14065, 1020691,704271,85058,26368, 1877065,957953,156422,76593, 2545687,1404627,212141,95088, 3142494,1821377,261875,110093, 2798090,38,0 ],
  [ 2000,50,15,3,1, 627563,399486,55783,20274, 1712087,1163188,77822,24950, 3262569,1738739,148299,69265, 4564712,2487324,207487,94427, 5673113,3149364,257869,114716, 7619485,62,0 ],
  [ 2000,50,25,3,1, 734376,429589,53409,22166, 3189108,2078799,66788,23253, 7015794,3740073,134919,62995, 10176500,5488837,195702,90147, 13059926,7317083,251152,110439, 19864884,94,0 ],
  [ 2000,50,50,3,1, 743387,431005,53099,22313, 4702500,3185529,59275,19121, 13491724,7150716,122652,57646, 20018456,10755486,181986,84209, 26812886,14730371,243754,109841, 39466290,122,0 ],
  [ 2000,50,75,3,1, 743387,431005,53099,22313, 4729191,3205031,59115,19052, 13700327,7212828,122324,57924, 20338358,10918239,181592,84108, 27274558,15051936,243523,109131, 40156411,123,0 ],
  [ 2000,100,1,3,1, 85360,101791,85360,-16431, 111642,81641,111642,30000, 258269,111060,258269,147210, 240693,79293,240693,161400, 297276,191410,297276,105866, -2721295,-34,21 ],
  [ 2000,100,2,3,1, 151854,112183,75927,19835, 218148,100536,109074,58806, 440568,338292,220284,51138, 481387,339089,240693,71149, 594552,387881,297276,103335, -2274823,-31,18 ],
  [ 2000,100,3,3,1, 218348,122576,72783,31924, 324655,207166,108218,39163, 622866,382325,207622,80180, 722080,394640,240693,109147, 891827,538820,297276,117669, -1351068,-21,9 ],
  [ 2000,100,5,3,1, 319831,223476,69781,21023, 519697,374795,103939,28980, 969137,542769,193827,85273, 1194500,707744,238900,97351, 1474566,898607,294913,115192, -442768,-6,0 ],
  [ 2000,100,10,3,1, 528157,370253,64672,19335, 1117131,721466,93094,32972, 2094952,1190456,174579,75375, 2800725,1585713,233394,101251, 3458045,1891504,288170,130545, 3474545,42,0 ],
  [ 2000,100,15,3,1, 691379,422456,61456,23904, 1870894,1206259,85041,30211, 3613724,1944190,164260,75888, 5021177,2796873,228235,101105, 6242337,3506553,283743,124354, 8853358,67,0 ],
  [ 2000,100,25,3,1, 807453,440620,58724,26679, 3478684,2340454,72852,23837, 7721482,4105478,148490,69539, 11187124,5998511,215137,99781, 14369598,7928839,276338,123861, 22222422,98,0 ],
  [ 2000,100,50,3,1, 817236,442152,58374,26792, 5124167,3488095,64590,20623, 14796451,7729510,134513,64245, 21986484,11778420,199877,92801, 29499128,16174274,268174,121135, 44042436,127,0 ],
  [ 2000,100,75,3,1, 817236,442152,58374,26792, 5153164,3494185,64415,20737, 15024100,7963246,134144,63043, 22337196,11974714,199439,92522, 30006916,16503979,267919,120562, 44454558,127,0 ],
  [ 2000,200,1,3,1, 115030,106270,115030,8760, 134980,84845,134980,50135, 367082,220380,367082,146702, 284576,181984,284576,102592, 351624,200838,351624,150786, -2610431,-33,21 ],
  [ 2000,200,2,3,1, 191563,106270,95782,42647, 184579,183951,92290,314, 420443,225380,210222,97532, 315336,181984,157668,66676, 384786,200838,192393,91974, -2278627,-31,18 ],
  [ 2000,200,3,3,1, 269628,143654,89876,41991, 384402,334835,128134,16522, 797362,438035,265787,119776, 853727,512139,284576,113862, 1054871,582245,351624,157542, -1064497,-17,-45 ],
  [ 2000,200,5,3,1, 269628,143654,58828,27485, 384402,334835,76880,9913, 1095190,592149,219038,100608, 867032,529611,173406,67484, 1072828,601294,214566,94307, -742246,-12,-23 ],
  [ 2000,200,10,3,1, 629549,398895,77088,28243, 1310011,843440,109168,38881, 2530727,1303729,210894,102250, 3310802,1795495,275900,126276, 4089147,2259123,340762,152502, 5366664,53,0 ],
  [ 2000,200,15,3,1, 819012,441722,72801,33537, 2188509,1470900,99478,32619, 4316035,2372512,196183,88342, 5934107,3291633,269732,120112, 7380784,4075098,335490,150258, 11114625,74,0 ],
  [ 2000,200,25,3,1, 819012,442617,59564,27374, 2188509,1796501,45833,8210, 4382141,2413145,84272,37865, 5934107,3291777,114117,50814, 7380784,4075181,141938,63569, 10138036,71,0 ],
  [ 2000,200,50,3,1, 964934,549485,68924,29675, 5967500,3988728,75221,24943, 17405906,9148668,158236,75066, 25922542,13838513,235659,109855, 34871611,19092941,317015,143442, 52532075,135,0 ],
  [ 2000,200,75,3,1, 964934,549485,68924,29675, 6001110,3995762,75014,25067, 17671648,9219747,157783,75463, 26334872,14059212,235133,109604, 35471632,19437475,316711,143162, 53527153,136,0 ],
  [ 2500,0,1,3,1, 70494,86533,70494,-16039, 109170,82487,109170,26683, 178983,83910,178983,95072, 234478,78457,234478,156021, 289995,190325,289995,99671, -2861724,-35,22 ],
  [ 2500,0,2,3,1, 140988,111188,70494,14900, 218340,115826,109170,51257, 357965,204851,178983,76557, 468955,337317,234478,65819, 579991,369878,289995,105057, -2258501,-30,18 ],
  [ 2500,0,3,3,1, 211482,122508,70494,29658, 327510,209280,109170,39410, 536948,354991,178983,60652, 703433,392784,234478,103550, 869986,536192,289995,111265, -1549237,-23,11 ],
  [ 2500,0,5,3,1, 319099,224825,69622,20569, 528649,378537,105730,30022, 878926,530150,175785,69755, 1163503,704821,232701,91736, 1438634,894458,287727,108835, -676791,-11,0 ],
  [ 2500,0,10,3,1, 540193,387876,66146,18651, 1150986,819455,95915,27628, 2000708,1180189,166726,68377, 2727996,1579453,227333,95712, 3375134,1882554,281261,124382, 2905011,38,0 ],
  [ 2500,0,15,3,1, 713563,429065,63428,25289, 1943112,1240797,88323,31923, 3525145,1924860,160234,72740, 4895829,2678257,222538,100799, 6092769,3490714,276944,118275, 8772420,67,0 ],
  [ 2500,0,25,3,1, 837014,533941,60874,22042, 3652283,2415688,76488,25897, 7703589,4140137,148146,68528, 10962125,6002527,210810,95377, 14013514,7893487,269491,117693, 21584738,97,0 ],
  [ 2500,0,50,3,1, 847440,535618,60531,22273, 5413421,3709446,68236,21479, 15007815,8053706,136435,63219, 21709705,11819767,197361,89909, 28754935,16022267,261409,115752, 42845545,126,0 ],
  [ 2500,0,75,3,1, 847440,535618,60531,22273, 5444519,3716089,68056,21605, 15244943,8198341,136116,62916, 22061394,12016822,196977,89684, 29250175,16237469,261162,116185, 43562617,126,0 ],
  [ 2500,25,1,3,1, 77912,100989,77912,-23078, 115005,83138,115005,31866, 206186,88362,206186,117824, 245448,80446,245448,165002, 303582,192819,303582,110763, -2801044,-35,22 ],
  [ 2500,25,2,3,1, 151107,112716,75553,19196, 228726,117425,114363,55651, 393379,331182,196689,31098, 490896,341532,245448,74682, 607165,390778,303582,108194, -2374583,-31,19 ],
  [ 2500,25,3,3,1, 224302,137779,74767,28841, 342447,315876,114149,8857, 580572,375088,193524,68494, 736344,398475,245448,112623, 910747,542905,303582,122614, -1731484,-25,13 ],
  [ 2500,25,5,3,1, 336035,328248,73317,1699, 551857,380923,110371,34187, 938038,539705,187608,79667, 1217937,715375,243587,100512, 1506000,907525,301200,119695, -750055,-12,0 ],
  [ 2500,25,10,3,1, 565541,391702,69250,21286, 1199206,828052,99934,30929, 2109652,1197659,175804,75999, 2855516,1600515,237960,104583, 3532909,2038269,294409,124553, 3486848,42,0 ],
  [ 2500,25,15,3,1, 745471,433881,66264,27697, 2022515,1343200,91933,30878, 3700723,2044228,168215,75295, 5124061,2839269,232912,103854, 6377381,3554827,289881,128298, 9195705,68,0 ],
  [ 2500,25,25,3,1, 873552,539457,63531,24298, 3797071,2531266,79520,26509, 8056432,4407857,154931,70165, 11467437,6239000,220528,100547, 14668350,8248550,282084,123458, 22608445,99,0 ],
  [ 2500,25,50,3,1, 884364,541191,63169,24512, 5624254,3869037,70894,22125, 15660178,8395453,142365,66043, 22693719,12367125,206307,93878, 30098056,16834843,273619,120575, 44869103,128,0 ],
  [ 2500,25,75,3,1, 884364,541191,63169,24512, 5656505,3875916,70706,22257, 15906830,8527043,142025,65891, 23060813,12538447,205900,93950, 30616354,17053815,273360,121094, 45715396,128,0 ],
  [ 2500,50,1,3,1, 85329,102109,85329,-16780, 120839,83939,120839,36900, 233389,107328,233389,126061, 256419,97311,256419,159107, 317169,195213,317169,121956, -2741276,-34,22 ],
  [ 2500,50,2,3,1, 85329,102630,42665,-8650, 120839,84843,60420,17998, 236430,107874,118215,64278, 256419,97337,128209,79541, 317169,195232,158585,60969, -2740641,-34,22 ],
  [ 2500,50,3,3,1, 237122,139715,79041,32469, 357384,332157,119128,8409, 624196,382021,208065,80725, 769256,418943,256419,116771, 951508,565540,317169,128656, -1575777,-23,12 ],
  [ 2500,50,5,3,1, 352971,330805,77012,4836, 575065,384809,115013,38051, 997151,549261,199430,89578, 1272371,739405,254474,106593, 1573366,919091,314673,130855, -431749,-6,0 ],
  [ 2500,50,10,3,1, 590889,395529,72354,23922, 1247425,836649,103952,34231, 2218596,1229643,184883,82413, 2983035,1636603,248586,112203, 3690684,2164724,307557,127163, 4033980,46,0 ],
  [ 2500,50,15,3,1, 777379,438698,69100,30105, 2101919,1357868,95542,33821, 3876301,2086746,176195,81343, 5352294,2972129,243286,108189, 6661993,3831532,302818,128657, 9962862,70,0 ],
  [ 2500,50,25,3,1, 910091,544972,66188,26554, 3941859,2677344,82552,26482, 8409276,4491027,161717,75351, 11972749,6540303,230245,104470, 15323186,8618283,294677,128940, 23853594,101,0 ],
  [ 2500,50,50,3,1, 921289,546765,65806,26752, 5835088,3998127,73552,23155, 16312542,8724185,148296,68985, 23677734,12871303,215252,98240, 31441177,17466670,285829,127041, 47211847,130,0 ],
  [ 2500,50,75,3,1, 921289,546765,65806,26752, 5868492,4019128,73356,23117, 16568717,8871609,147935,68724, 24060232,13045145,214823,98349, 31982533,17802553,285558,126607, 47954595,131,0 ],
  [ 2500,100,1,3,1, 100164,104348,100164,-4184, 132509,85541,132509,46968, 287795,116230,287795,171565, 278360,181297,278360,97063, 344343,199902,344343,144441, -2629055,-33,21 ],
  [ 2500,100,2,3,1, 181463,117298,90731,32082, 259883,196070,129941,31906, 499619,362998,249810,68311, 556719,352578,278360,102071, 688686,404864,344343,141911, -2119954,-29,17 ],
  [ 2500,100,3,3,1, 262762,143585,87587,39726, 387257,336949,129086,16769, 711443,410701,237148,100247, 835079,510283,278360,108265, 1033029,579617,344343,151138, -1262666,-20,7 ],
  [ 2500,100,5,3,1, 386843,349255,84402,8201, 621480,392432,124296,45810, 1115377,582888,223075,106498, 1381240,757714,276248,124705, 1708097,958096,341619,150000, 149704,8,0 ],
  [ 2500,100,10,3,1, 641585,403182,78561,29192, 1343865,867729,111989,39678, 2436483,1293462,203040,95252, 3238074,1789086,269839,120749, 4006235,2250022,333853,146351, 5085604,51,0 ],
  [ 2500,100,15,3,1, 841195,533368,74773,27362, 2260726,1505288,102760,34338, 4227456,2367697,192157,84534, 5808759,3201370,264034,118518, 7231216,4059459,328692,144171, 10609961,72,0 ],
  [ 2500,100,25,3,1, 983168,569340,71503,30097, 4231434,2834499,88616,29255, 9114964,4843416,175288,82145, 12983373,7065054,249680,113814, 16632857,9230639,319863,142350, 26494457,105,0 ],
  [ 2500,100,50,3,1, 995138,571250,71081,30278, 6256754,4300543,78867,24658, 17617269,9379979,160157,74884, 25645762,13894387,233143,106831, 34127419,19008143,310249,137448, 51487344,134,0 ],
  [ 2500,100,75,3,1, 995138,571250,71081,30278, 6292465,4322016,78656,24631, 17892491,9544877,159754,74532, 26059069,14101770,232670,106762, 34714891,19254696,309954,138037, 52330963,135,0 ],
  [ 2500,200,1,3,1, 129834,108827,129834,21007, 155847,88744,155847,67103, 396608,346115,396608,50493, 322242,189253,322242,132989, 398691,322970,398691,75721, -2737074,-34,21 ],
  [ 2500,200,2,3,1, 221938,123408,110969,49265, 301426,202464,150713,49481, 641273,400580,320637,120346, 644484,382265,322242,131109, 797382,439301,398691,179040, -1490851,-23,10 ],
  [ 2500,200,3,3,1, 314041,223026,104680,30338, 447004,346534,149001,33490, 885939,545061,295313,113626, 966726,547324,322242,139801, 1196073,736532,398691,153180, -983787,-16,-38 ],
  [ 2500,200,5,3,1, 454586,359481,99182,20750, 714311,421861,142862,58490, 1351828,756041,270366,119157, 1598977,904790,319795,138837, 1977559,1149345,395512,165643, 889671,19,0 ],
  [ 2500,200,10,3,1, 751897,431721,92069,39205, 1562181,914845,130182,53945, 2713310,1588077,226109,93769, 3520662,1998271,293389,126866, 3131901,2506744,260992,52096, 5214588,51,0 ],
  [ 2500,200,15,3,1, 968827,552634,86118,36995, 2578341,1665579,117197,41489, 4929767,2720520,224080,100420, 6721689,3696131,305531,137525, 8369663,4757266,380439,164200, 13282542,80,0 ],
  [ 2500,200,25,3,1, 1129323,692269,82133,31786, 4810585,3178110,100745,34188, 10526339,5623695,202430,94282, 15004621,8209238,288550,130680, 19252201,10781682,370235,162895, 30832415,111,0 ],
  [ 2500,200,50,3,1, 1142836,707749,81631,31078, 7100087,4799676,89497,28997, 20226723,10722137,183879,86405, 29581820,15875673,268926,124601, 39499902,21815219,359090,160770, 60232948,141,0 ],
  [ 2500,200,75,3,1, 1142836,707749,81631,31078, 7140412,4808209,89255,29153, 20540038,10878528,183393,86263, 30056745,16187768,268364,123830, 40179607,22189742,358746,160624, 61150954,142,0 ],
  [ 3000,0,1,3,1, 85299,102427,85299,-17128, 130037,86237,130037,43800, 208508,89081,208508,119428, 272144,100303,272144,171841, 337063,198966,337063,138096, -2705653,-34,21 ],
  [ 3000,0,2,3,1, 170597,116303,85299,27147, 260075,197475,130037,31300, 417017,335756,208508,40630, 544288,351306,272144,96491, 674125,403082,337063,135522, -2316599,-31,18 ],
  [ 3000,0,3,3,1, 255896,143516,85299,37460, 390112,339064,130037,17016, 625525,383367,208508,80719, 816432,428119,272144,129438, 1011188,576988,337063,144733, -1342311,-21,8 ],
  [ 3000,0,5,3,1, 386111,350604,84242,7747, 630432,410059,126086,44075, 1025166,555603,205033,93913, 1350242,754741,270048,119100, 1672165,953846,334433,143664, -94401,2,0 ],
  [ 3000,0,10,3,1, 653621,420805,80035,28508, 1377720,891868,114810,40488, 2342239,1268680,195187,89463, 3165345,1782777,263779,115214, 3923324,2241022,326944,140192, 4783350,50,0 ],
  [ 3000,0,15,3,1, 863378,539977,76745,28747, 2332944,1553712,106043,35420, 4138877,2348217,188131,81394, 5683410,3191214,258337,113282, 7081648,4043570,321893,138094, 10325366,72,0 ],
  [ 3000,0,25,3,1, 1012729,678491,73653,24308, 4405033,3026618,92252,28867, 9097070,4953425,174944,79685, 12758374,7070419,245353,109384, 16276773,9196087,313015,136167, 25245073,103,0 ],
  [ 3000,0,50,3,1, 1025341,680545,73239,24628, 6546009,4520544,82513,25531, 17828632,9704176,162078,73859, 25368983,13856777,230627,104656, 33383226,18744395,303484,133080, 50424613,133,0 ],
  [ 3000,0,75,3,1, 1025341,680545,73239,24628, 6546009,4520882,81825,25314, 17832254,9704819,159217,72566, 25368983,13856777,226509,102788, 33383226,18744395,298065,130704, 50430373,133,0 ],
  [ 3000,25,1,3,1, 92716,103547,92716,-10830, 135872,87038,135872,48834, 235712,108047,235712,127665, 283114,182499,283114,100615, 350650,201361,350650,149289, -2742305,-34,21 ],
  [ 3000,25,2,3,1, 180716,117831,90358,31443, 270460,199074,135230,35693, 452430,341523,226215,55454, 566229,354971,283114,105629, 701299,407761,350650,146769, -2142630,-29,17 ],
  [ 3000,25,3,3,1, 268716,217152,89572,17188, 405049,341460,135016,21196, 669149,390449,223050,92900, 849343,513918,283114,111809, 1051949,584001,350650,155983, -1522252,-23,11 ],
  [ 3000,25,5,3,1, 403047,353161,87938,10884, 653640,413945,130728,47939, 1084278,579674,216856,100921, 1404677,859129,280935,109110, 1739530,981134,347906,151679, 60707,6,0 ],
  [ 3000,25,10,3,1, 678969,424631,83139,31143, 1425940,900465,118828,43790, 2451183,1377664,204265,89460, 3292864,1803888,274405,124081, 4081099,2283696,340092,149784, 5235822,52,0 ],
  [ 3000,25,15,3,1, 678969,424631,60353,22608, 1425940,900465,64815,23885, 3788363,2080940,172198,77610, 3368305,1863800,153105,68387, 4176799,2349577,189854,83056, 6729672,59,0 ],
  [ 3000,25,25,3,1, 1049268,684007,76310,26564, 4549821,3142196,95284,29479, 9449914,5144145,181729,82803, 13263686,7263763,255071,115383, 16931609,9566821,325608,141631, 26497759,105,0 ],
  [ 3000,25,50,3,1, 1062265,699455,75876,25915, 6756842,4679985,85170,26179, 18480995,10045923,168009,76682, 26352997,14484592,239573,107895, 34726347,19489012,315694,138521, 52323514,135,0 ],
  [ 3000,25,75,3,1, 1062265,699455,75876,25915, 6756842,4679985,84461,25961, 18686094,10094074,166840,76714, 26366374,14487050,235414,106065, 34743515,19492196,310210,136172, 52709229,135,0 ],
  [ 3000,50,1,3,1, 100134,104666,100134,-4533, 141707,87839,141707,53868, 262915,112498,262915,150417, 294085,184338,294085,109747, 364237,203705,364237,160532, -2626933,-33,21 ],
  [ 3000,50,2,3,1, 190834,119358,95417,35738, 280846,200672,140423,40087, 487844,347290,243922,70277, 588170,373613,294085,107278, 728473,412440,364237,158017, -1990763,-28,16 ],
  [ 3000,50,3,3,1, 281535,219087,93845,20816, 419986,343856,139995,25377, 712773,412047,237591,100242, 882255,519459,294085,120932, 1092710,606736,364237,161991, -1334358,-20,-55 ],
  [ 3000,50,5,3,1, 419983,355717,91633,14022, 676847,417831,135369,51803, 1143391,695279,228678,89622, 1459111,868683,291822,118086, 1806896,993201,361379,162739, 155739,8,0 ],
  [ 3000,50,10,3,1, 704317,428458,86243,33779, 1474160,909063,122847,47091, 2560126,1395134,213344,97083, 3420383,1948386,285032,122666, 4238875,2523192,353240,142974, 5575747,54,0 ],
  [ 3000,50,15,3,1, 927194,549610,82417,33563, 2491751,1670782,113261,37317, 4490033,2447618,204092,92837, 6139876,3391653,279085,124919, 7650872,4384438,347767,148474, 11796776,76,0 ],
  [ 3000,50,25,3,1, 1085806,702859,78968,27851, 4694609,3183774,98316,31641, 9802758,5305815,188515,86480, 13768998,7580193,264788,119015, 17586445,9921533,338201,147402, 27827408,107,0 ],
  [ 3000,50,50,3,1, 1099190,705029,78514,28154, 6967675,4735225,87828,28140, 19133359,10374655,173940,79625, 27337012,14988771,248518,112257, 36069468,20105168,327904,145130, 54911213,137,0 ],
  [ 3000,50,75,3,1, 1099190,705029,78514,28154, 7007793,4831575,87597,27203, 19437107,10453390,173546,80212, 27782105,15188678,248055,112441, 36690508,20471038,327594,144817, 55792526,138,0 ],
  [ 3000,100,1,3,1, 114969,106906,114969,8063, 153376,89441,153376,63935, 317321,212915,317321,104406, 316026,188066,316026,127960, 391411,321534,391411,69876, -2686818,-34,21 ],
  [ 3000,100,2,3,1, 211072,122413,105536,44329, 301617,203869,150809,48874, 558671,373339,279335,92666, 632052,380993,316026,125529, 782821,437519,391411,172651, -1687497,-25,13 ],
  [ 3000,100,3,3,1, 307175,222957,102392,28073, 449859,348648,149953,33737, 800021,440727,266674,119765, 948078,545468,316026,134203, 1174232,733903,391411,146776, -1005458,-16,-39 ],
  [ 3000,100,5,3,1, 453855,360830,99023,20296, 723263,499453,144653,44762, 1261617,728906,252323,106542, 1567980,901817,313596,133232, 1941627,1145146,388325,159296, 455368,13,0 ],
  [ 3000,100,10,3,1, 755013,436110,92451,39049, 1570600,1044492,130883,43842, 2778014,1563503,231501,101209, 3675422,2086393,306285,132419, 4554425,2592819,379535,163467, 6088649,56,0 ],
  [ 3000,100,15,3,1, 991011,673447,88090,28228, 2650559,1712353,120480,42646, 4841188,2609505,220054,101440, 6596341,3687175,299834,132235, 8220095,4726906,373641,158781, 12752631,78,0 ],
  [ 3000,100,25,3,1, 1158884,713890,84282,32363, 4984184,3445429,104381,32225, 10508446,5656705,202085,93303, 14779622,8024486,284223,129906, 18896117,10548910,363387,160523, 30303693,111,0 ],
  [ 3000,100,50,3,1, 1173039,716177,83788,32633, 7389342,5037791,93143,29641, 20438086,10953449,185801,86224, 29305040,15931397,266409,121579, 38755710,21662161,352325,155396, 59525252,141,0 ],
  [ 3000,100,75,3,1, 1173039,716177,83788,32633, 7431766,5134613,92897,28714, 20760881,11218173,185365,85203, 29780943,16245203,265901,120855, 39422866,22036172,351990,155238, 59932956,141,0 ],
  [ 3000,200,1,3,1, 144638,111384,144638,33254, 176715,92644,176715,84071, 426134,351285,426134,74848, 359908,195973,359908,163936, 445758,347133,445758,98626, -2515380,-33,20 ],
  [ 3000,200,2,3,1, 251546,141859,125773,54843, 343160,314463,171580,14348, 700325,423936,350162,138194, 719817,395904,359908,161956, 891517,539765,445758,175876, -1499666,-23,11 ],
  [ 3000,200,3,3,1, 309394,149359,103131,53345, 426075,367409,142025,19555, 849263,547866,283088,100465, 907490,395854,302497,170545, 1121142,539715,373714,193809, -705980,-11,0 ],
  [ 3000,200,5,3,1, 521598,371057,113803,32845, 816093,528882,163219,57442, 1498068,873009,299614,125012, 1785717,953110,357143,166521, 2211090,1290914,442218,184035, 1436914,25,0 ],
  [ 3000,200,10,3,1, 856405,536453,104866,39178, 1763479,1166466,146957,49751, 3213789,1753775,267816,121668, 4185499,2404636,348792,148405, 5185527,2960438,432127,185424, 7350212,61,0 ],
  [ 3000,200,15,3,1, 1118643,692713,99435,37860, 2968173,1976994,134917,45054, 5543499,2960827,251977,117394, 7509271,4101628,341330,154893, 9358542,5311123,425388,183974, 15297748,85,0 ],
  [ 3000,200,25,3,1, 1305038,749290,94912,40418, 5563336,3686041,116510,39315, 11919821,6452999,229227,105131, 16800870,9167370,323094,146798, 21515460,12083482,413759,181384, 35304926,117,0 ],
  [ 3000,200,50,3,1, 1320737,751809,94338,40638, 8232675,5538424,103773,33961, 23047540,12372606,209523,97045, 33241098,17991490,302192,138633, 44128193,24580828,401165,177703, 68308875,147,0 ],
  [ 3000,200,75,3,1, 1320737,751809,94338,40638, 8279713,5622306,103496,33218, 23408428,12474824,209004,97621, 33778618,18314725,301595,138070, 44887582,25053498,400782,177090, 69305488,148,0 ],
  [ 1000,0,1,0,2, 5423,76125,5423,-70703, 11539,51673,11539,-40134, 31295,45821,31295,-14526, 48891,33047,48891,15844, 67186,38210,67186,28976, -3805484,-42,28 ],
  [ 1000,0,2,0,2, 10845,77037,5423,-33096, 23078,54348,11539,-15635, 62590,51673,31295,5459, 97782,57581,48891,20101, 134372,67530,67186,33421, -3637682,-41,27 ],
  [ 1000,0,3,0,2, 16268,77949,5423,-20560, 34617,57022,11539,-7468, 93885,72039,31295,7282, 146674,67189,48891,26495, 201558,81229,67186,40110, -3470103,-40,26 ],
  [ 1000,0,5,0,2, 24547,79341,5356,-11955, 54846,75726,10969,-4176, 151506,82605,30301,13780, 241541,180907,48308,12127, 331902,320080,66380,2364, -3434414,-39,26 ],
  [ 1000,0,10,0,2, 41564,82207,5089,-4977, 112214,90080,9351,1845, 329815,313044,27485,1398, 558271,380892,46523,14782, 768846,539200,64070,19137, -3033494,-36,24 ],
  [ 1000,0,15,0,2, 41564,82207,3695,-3613, 112214,90080,5101,1006, 486357,355909,22107,5929, 567718,382987,25805,8397, 782335,557882,35561,10202, -2765897,-34,22 ],
  [ 1000,0,25,0,2, 64426,99400,4686,-2544, 310033,349113,6493,-818, 1134598,726954,21819,7839, 2120279,1286216,40775,16040, 3116673,2043229,59936,20643, -739552,-10,0 ],
  [ 1000,0,50,0,2, 65231,99537,4659,-2450, 436409,412300,5501,304, 2032488,1232475,18477,7273, 3977839,2475893,36162,13654, 6281160,4030414,57101,20461, 2185775,34,0 ],
  [ 1000,0,75,0,2, 65231,99537,4659,-2450, 438628,412924,5483,321, 2059631,1237211,18390,7343, 4035542,2486602,36032,13830, 6385522,4050681,57014,20847, 2371503,36,0 ],
  [ 1000,25,1,0,2, 7945,76583,7945,-68638, 13426,51945,13426,-38518, 44328,48391,44328,-4063, 53306,33936,53306,19371, 73321,55152,73321,18170, -3770432,-42,27 ],
  [ 1000,25,2,0,2, 13930,77597,6965,-31834, 26103,54889,13052,-14393, 78465,69305,39233,4580, 106613,59402,53306,23606, 146642,70117,73321,38263, -3606381,-41,27 ],
  [ 1000,25,3,0,2, 19914,78611,6638,-19566, 38780,57833,12927,-6351, 112603,75703,37534,12300, 159919,69841,53306,30026, 219963,100703,73321,39754, -3397898,-39,26 ],
  [ 1000,25,5,0,2, 29049,80159,6338,-11151, 61026,77031,12205,-3201, 175500,87283,35100,17643, 263335,185259,52667,15615, 362196,326327,72439,7174, -3316727,-39,25 ],
  [ 1000,25,10,0,2, 47815,83341,5855,-4350, 124240,92890,10353,2613, 370365,335398,30864,2914, 608540,390916,50712,18135, 838936,569309,69911,22469, -2853881,-35,22 ],
  [ 1000,25,15,0,2, 62529,85839,5558,-2072, 198654,200068,9030,-64, 622472,396027,28294,10293, 1075844,714330,48902,16432, 1500919,961340,68224,24526, -2063968,-28,17 ],
  [ 1000,25,25,0,2, 73004,100957,5309,-2033, 343023,371618,7184,-599, 1252063,840698,24078,7911, 2312387,1434232,44469,16888, 3399211,2215748,65369,22759, -525699,-6,0 ],
  [ 1000,25,50,0,2, 73888,101108,5278,-1944, 483086,424860,6089,734, 2238684,1300463,20352,8529, 4342081,2657307,39473,15316, 6848474,4404488,62259,22218, 3046962,40,0 ],
  [ 1000,25,75,0,2, 73888,101108,5278,-1944, 485544,425554,6069,750, 2268558,1382706,20255,7909, 4405200,2792497,39332,14399, 6962225,4426626,62163,22639, 2891458,39,0 ],
  [ 1000,50,1,0,2, 10468,77041,10468,-66573, 15313,52216,15313,-36903, 57361,65476,57361,-8115, 57722,34874,57722,22848, 79456,56472,79456,22984, -3757707,-42,27 ],
  [ 1000,50,2,0,2, 17014,78157,8507,-30571, 29128,55430,14564,-13151, 94340,72422,47170,10959, 115444,61172,57722,27136, 158912,72653,79456,43130, -3541579,-40,26 ],
  [ 1000,50,3,0,2, 23560,79272,7853,-18571, 42943,58644,14314,-5234, 131320,79368,43773,17317, 173165,72494,57722,33557, 238369,104505,79456,44621, -3314520,-39,25 ],
  [ 1000,50,5,0,2, 33551,80976,7320,-10347, 67207,78335,13441,-2226, 199494,106475,39899,18604, 285128,189660,57026,19094, 392490,348295,78498,8839, -3243713,-38,25 ],
  [ 1000,50,10,0,2, 54066,84476,6620,-3724, 136267,95700,11356,3381, 410916,343238,34243,5640, 658808,400889,54901,21493, 909027,583696,75752,27111, -2629594,-33,21 ],
  [ 1000,50,15,0,2, 70143,100558,6235,-2704, 217691,204611,9895,595, 684685,422501,31122,11917, 1164745,747073,52943,18985, 1626160,1002832,73916,28333, -1823012,-25,15 ],
  [ 1000,50,25,0,2, 81581,102514,5933,-1522, 376013,380238,7875,-88, 1369528,877592,26337,9460, 2504495,1595424,48163,17482, 3681750,2501408,70803,22699, -191843,2,0 ],
  [ 1000,50,50,0,2, 82546,102680,5896,-1438, 529764,525156,6678,58, 2444881,1536836,22226,8255, 4706324,2962607,42785,15852, 7415787,4763291,67416,24114, 3071404,41,0 ],
  [ 1000,50,75,0,2, 82546,102680,5896,-1438, 532460,525918,6656,82, 2477484,1542586,22120,8347, 4774858,2975405,42633,16067, 7538928,4802971,67312,24428, 3280845,42,0 ],
  [ 1000,100,1,0,2, 15514,77957,15514,-62443, 19087,52759,19087,-33672, 83427,70615,83427,12811, 66552,36651,66552,29901, 91726,59014,91726,32712, -3665256,-41,27 ],
  [ 1000,100,2,0,2, 23183,79277,11591,-28047, 35178,56513,17589,-10668, 126090,78656,63045,23717, 133105,64713,66552,34196, 183453,77727,91726,52863, -3411975,-39,26 ],
  [ 1000,100,3,0,2, 30852,80596,10284,-16581, 51268,60267,17089,-3000, 168754,101211,56251,22514, 199657,77848,66552,40603, 275179,195941,91726,26413, -3240999,-38,25 ],
  [ 1000,100,5,0,2, 42555,82610,9285,-8739, 79567,80944,15913,-275, 247483,115830,49497,26330, 328715,306823,65743,4378, 453078,360788,90616,18458, -3168413,-38,24 ],
  [ 1000,100,10,0,2, 66568,86745,8151,-2471, 160319,115206,13360,3759, 492016,373282,41001,9895, 759346,516170,63279,20265, 1049208,741233,87434,25665, -2490537,-32,20 ],
  [ 1000,100,15,0,2, 85373,103323,7589,-1596, 255765,332232,11626,-3476, 809109,536283,36778,12401, 1342547,877539,61025,21137, 1876641,1281787,85302,27039, -1981703,-27,16 ],
  [ 1000,100,25,0,2, 98737,105628,7181,-501, 441992,411364,9256,641, 1604458,936714,30855,12841, 2888711,1781147,55552,21299, 4246826,2762666,81670,28542, 888408,20,0 ],
  [ 1000,100,50,0,2, 99862,105823,7133,-426, 623119,564163,7854,743, 2857275,1735147,25975,10201, 5434810,3353536,49407,18921, 8550414,5511339,77731,27628, 4565647,50,0 ],
  [ 1000,100,75,0,2, 99862,105823,7133,-426, 626292,565062,7829,765, 2895337,1741911,25851,10298, 5514174,3383399,49234,19025, 8692333,5638541,77610,27266, 4728136,51,0 ],
  [ 1000,200,1,0,2, 25605,79789,25605,-54184, 26636,53845,26636,-27209, 135558,95410,135558,40148, 84214,55232,84214,28982, 116266,64147,116266,52119, -3535957,-40,26 ],
  [ 1000,200,2,0,2, 35521,81516,17760,-22998, 47277,58678,23638,-5701, 189590,105639,94795,41976, 168427,71845,84214,48291, 232533,103594,116266,64469, -3197442,-38,24 ],
  [ 1000,200,3,0,2, 45436,83243,15145,-12602, 67918,77396,22639,-3159, 243623,115719,81208,42635, 252641,183742,84214,22966, 348799,324191,116266,8203, -3172330,-38,24 ],
  [ 1000,200,5,0,2, 60562,85878,13214,-5524, 104289,86162,20858,3625, 343460,332105,68692,2271, 415889,339756,83178,15227, 574253,401997,114851,34451, -3186316,-38,24 ],
  [ 1000,200,10,0,2, 91571,104620,11213,-1598, 208424,200148,17369,690, 654218,419155,54518,19589, 960420,586218,80035,31184, 1329570,914106,110798,34622, -2041652,-28,17 ],
  [ 1000,200,15,0,2, 115832,108851,10296,621, 331912,351004,15087,-868, 1057959,719197,48089,15398, 1698150,1167123,77189,24138, 2377604,1628755,108073,34039, -1341068,-20,-38 ],
  [ 1000,200,25,0,2, 133047,111856,9676,1541, 573951,533580,12020,845, 2074319,1267190,39891,15522, 3657143,2400064,70330,24175, 5376979,3496342,103403,36166, 1855390,31,0 ],
  [ 1000,200,50,0,2, 134493,112108,9607,1599, 809829,806491,10208,42, 3682062,2342518,33473,12178, 6891781,4246655,62653,24047, 10819668,6963004,98361,35061, 6415897,59,0 ],
  [ 1000,200,75,0,2, 134493,112108,9607,1599, 813956,821551,10174,-95, 3731043,2351160,33313,12320, 6992807,4389156,62436,23247, 10999144,7097640,98207,34835, 6444500,59,0 ],
  [ 1500,0,1,0,2, 8501,76765,8501,-68264, 16866,52992,16866,-36126, 41516,47971,41516,-6455, 64051,36288,64051,27762, 88439,58481,88439,29958, -3746025,-41,27 ],
  [ 1500,0,2,0,2, 17001,78316,8501,-30657, 33732,56986,16866,-11627, 83032,70488,41516,6272, 128101,64113,64051,31994, 176878,76780,88439,50049, -3529917,-40,26 ],
  [ 1500,0,3,0,2, 25502,79867,8501,-18122, 50598,60979,16866,-3461, 124548,78489,41516,15353, 192152,76912,64051,38414, 265317,194481,88439,23612, -3329113,-39,25 ],
  [ 1500,0,5,0,2, 38480,82235,8396,-9547, 80554,82183,16111,-326, 201758,93176,40352,21716, 316286,305342,63257,2189, 436901,358474,87380,15685, -3263175,-38,25 ],
  [ 1500,0,10,0,2, 65148,100442,7977,-4322, 167584,118353,13965,4103, 444629,351551,37052,7756, 730500,513153,60875,18112, 1012241,736433,84353,22984, -2659390,-34,21 ],
  [ 1500,0,15,0,2, 86063,104263,7650,-1618, 272292,338613,12377,-3015, 763143,531100,34688,10547, 1293292,872650,58786,19120, 1810602,1273353,82300,24420, -2150669,-29,18 ],
  [ 1500,0,25,0,2, 100960,106987,7343,-438, 482050,425672,10095,1181, 1579154,940627,30368,12279, 2801194,1790484,53869,19437, 4092784,2644612,78707,27849, 737858,18,0 ],
  [ 1500,0,50,0,2, 102219,107218,7301,-357, 689555,705620,8692,-202, 2896570,1773743,26332,10208, 5325351,3386509,48412,17626, 8234393,5362157,74858,26111, 4004294,47,0 ],
  [ 1500,0,75,0,2, 102219,107218,7301,-357, 693205,706680,8665,-168, 2937243,1887371,26225,9374, 5404951,3402383,48258,17880, 8371141,5518682,74742,25468, 3924018,46,0 ],
  [ 1500,25,1,0,2, 11024,77223,11024,-66199, 18753,53264,18753,-34511, 54549,50541,54549,4008, 68466,37177,68466,31289, 94574,59752,94574,34822, -3699800,-41,27 ],
  [ 1500,25,2,0,2, 20086,78876,10043,-29395, 36757,57527,18378,-10385, 98907,73605,49453,12651, 136932,65884,68466,35524, 189148,79317,94574,54915, -3465115,-40,26 ],
  [ 1500,25,3,0,2, 29148,80529,9716,-17127, 54760,75675,18253,-6972, 143265,82004,47755,20420, 205398,79564,68466,41945, 283722,198234,94574,28496, -3289319,-38,25 ],
  [ 1500,25,5,0,2, 42982,83053,9378,-8743, 86734,83487,17347,649, 225752,112368,45150,22677, 338080,309743,67616,5667, 467195,364771,93439,20485, -3178988,-38,24 ],
  [ 1500,25,10,0,2, 71399,101576,8743,-3695, 179610,195014,14968,-1284, 485179,373906,40432,9273, 780769,538153,65064,20218, 1082331,766542,90194,26316, -2735661,-34,22 ],
  [ 1500,25,15,0,2, 93678,105645,8327,-1064, 291329,343306,13242,-2363, 825355,543059,37516,12832, 1382192,905192,62827,21682, 1935842,1314645,87993,28236, -1821520,-25,15 ],
  [ 1500,25,25,0,2, 109538,108544,7966,72, 515039,522027,10786,-146, 1696619,1160420,32627,10312, 2993302,1952176,57563,20022, 4375322,2930721,84141,27781, 372474,13,0 ],
  [ 1500,25,50,0,2, 110877,108789,7920,149, 736232,718180,9280,228, 3102767,1947781,28207,10500, 5689594,3676883,51724,18297, 8801707,5736731,80016,27863, 4460029,49,0 ],
  [ 1500,25,75,0,2, 110877,108789,7920,149, 740121,733195,9252,87, 3146170,2032716,28091,9942, 5774609,3693301,51559,18583, 8947843,5894577,79891,27261, 4422492,49,0 ],
  [ 1500,50,1,0,2, 13546,77681,13546,-64134, 20640,53535,20640,-32895, 67582,67626,67582,-44, 72881,53092,72881,19790, 100709,61073,100709,39636, -3709178,-41,27 ],
  [ 1500,50,2,0,2, 23170,79436,11585,-28133, 39781,58068,19891,-9143, 114782,76722,57391,19030, 145763,67654,72881,39054, 201418,81854,100709,59782, -3400314,-39,26 ],
  [ 1500,50,3,0,2, 32794,81191,10931,-16132, 58923,76486,19641,-5855, 161982,85668,53994,25438, 218644,97193,72881,40484, 302127,202036,100709,33364, -3228044,-38,25 ],
  [ 1500,50,5,0,2, 47484,83870,10360,-7939, 92915,84792,18583,1625, 249746,194046,49949,11140, 359873,329071,71975,6160, 497488,371018,99498,25294, -3259902,-38,25 ],
  [ 1500,50,10,0,2, 77649,102711,9508,-3069, 191637,197824,15970,-516, 525730,381745,43811,11999, 831037,548126,69253,23576, 1152422,780929,96035,30958, -2511374,-32,20 ],
  [ 1500,50,15,0,2, 101292,107027,9004,-510, 310366,347999,14108,-1711, 887568,569532,40344,14456, 1471093,922808,66868,24922, 2061083,1340316,93686,32762, -1492594,-22,-43 ],
  [ 1500,50,25,0,2, 118115,110101,8590,583, 548029,530648,11477,364, 1814084,1197314,34886,11861, 3185410,2085216,61258,21158, 4657860,3103291,89574,29896, 828512,20,0 ],
  [ 1500,50,50,0,2, 119535,110361,8538,655, 782910,744626,9869,483, 3308964,2078104,30081,11190, 6053836,3858246,55035,19960, 9369020,6095034,85173,29764, 5145807,53,0 ],
  [ 1500,50,75,0,2, 119535,110361,8538,655, 787037,745824,9838,515, 3355096,2086696,29956,11325, 6144267,3890685,54860,20121, 9524546,6225490,85041,29456, 5344589,54,0 ],
  [ 1500,100,1,0,2, 18592,78596,18592,-60005, 24414,54078,24414,-29664, 93647,72766,93647,20882, 81712,54869,81712,26843, 112979,63614,112979,49365, -3616727,-41,27 ],
  [ 1500,100,2,0,2, 29339,80555,14670,-25608, 45831,59151,22915,-6660, 146532,82956,73266,31788, 163424,71195,81712,46115, 225958,102598,112979,61680, -3281883,-38,25 ],
  [ 1500,100,3,0,2, 40086,82514,13362,-14143, 67248,78109,22416,-3620, 199417,107512,66472,30635, 245136,182855,81712,20760, 338937,322782,112979,5385, -3293945,-38,25 ],
  [ 1500,100,5,0,2, 56487,85504,12325,-6331, 105275,87401,21055,3575, 297735,217915,59547,15964, 403460,337824,80692,13127, 558076,399233,111615,31769, -3069201,-37,24 ],
  [ 1500,100,10,0,2, 90151,104980,11039,-1816, 215689,203445,17974,1020, 606830,411940,50569,16241, 931574,583100,77631,29040, 1292603,909206,107717,31950, -2189312,-29,18 ],
  [ 1500,100,15,0,2, 116522,109791,10358,598, 348440,371270,15838,-1038, 1011993,713864,46000,13551, 1648895,1096903,74950,25091, 2311564,1620271,105071,31422, -1457197,-21,-43 ],
  [ 1500,100,25,0,2, 135271,113215,9838,1604, 614009,561773,12859,1094, 2049015,1270952,39404,14963, 3569626,2205657,68647,26230, 5222937,3477689,100441,33562, 1891051,31,0 ],
  [ 1500,100,50,0,2, 136850,113504,9775,1668, 876265,857483,11045,237, 3721357,2382464,33831,12172, 6782322,4264652,61657,22888, 10503647,6927362,95488,32512, 6079053,57,0 ],
  [ 1500,100,75,0,2, 136850,113504,9775,1668, 880868,858818,11011,276, 3772949,2392071,33687,12329, 6883583,4407640,61461,22107, 10677951,6977780,95339,33037, 6212382,58,0 ],
  [ 1500,200,1,0,2, 28683,80428,28683,-51745, 31962,55164,31962,-23202, 145779,97560,145779,48219, 99373,58472,99373,40901, 137519,68747,137519,68772, -3465325,-40,26 ],
  [ 1500,200,2,0,2, 41677,82795,20838,-20559, 57930,61316,28965,-1693, 210032,109939,105016,50046, 198746,78326,99373,60210, 275039,196575,137519,39232, -3115911,-37,24 ],
  [ 1500,200,3,0,2, 54670,85161,18223,-10164, 83898,81353,27966,848, 274285,213684,91428,20200, 298119,193514,99373,34868, 412558,353713,137519,19615, -3181604,-38,24 ],
  [ 1500,200,5,0,2, 74495,102109,16253,-6025, 129997,92468,25999,7506, 393712,357190,78742,7304, 490634,355930,98127,26941, 679252,440592,135850,47732, -2943197,-36,23 ],
  [ 1500,200,10,0,2, 115155,109518,14101,690, 263794,319037,21983,-4604, 769032,533313,64086,19643, 1132649,746481,94387,32181, 1572965,997648,131080,47943, -2033308,-28,17 ],
  [ 1500,200,15,0,2, 146981,115320,13065,2814, 424587,390042,19299,1570, 1260842,867729,57311,17869, 2004499,1277528,91114,33044, 2812527,1869319,127842,42873, -518150,-7,0 ],
  [ 1500,200,25,0,2, 169581,119442,12333,3646, 745968,714490,15622,659, 2518875,1585413,48440,17951, 4338058,2809548,83424,29394, 6353090,4226486,122175,40896, 2809623,39,0 ],
  [ 1500,200,50,0,2, 171481,119789,12249,3692, 1062976,921611,13399,1782, 4546144,2885286,41329,15099, 8239294,5265731,74903,27032, 12772901,8295247,116117,40706, 8634744,67,0 ],
  [ 1500,200,75,0,2, 171481,119789,12249,3692, 1068532,937106,13357,1643, 4608655,2896770,41149,15285, 8362216,5289460,74663,27435, 12984762,8549970,115935,39596, 8836072,68,0 ],
  [ 2000,0,1,0,2, 11579,77404,11579,-65825, 22193,54311,22193,-32119, 51737,50122,51737,1615, 79210,54506,79210,24705, 109692,63081,109692,46610, -3697496,-41,27 ],
  [ 2000,0,2,0,2, 23158,79595,11579,-28219, 44385,59624,22193,-7619, 103474,74788,51737,14343, 158420,70595,79210,43913, 219383,101652,109692,58866, -3399825,-39,26 ],
  [ 2000,0,3,0,2, 34736,81785,11579,-15683, 66578,78821,22193,-4081, 155210,84790,51737,23474, 237631,181918,79210,18571, 329075,321322,109692,2584, -3382059,-39,25 ],
  [ 2000,0,5,0,2, 52413,85129,11436,-7138, 106262,88639,21252,3524, 252010,195261,50402,11350, 391031,336343,78206,10938, 541899,396919,108380,28996, -3163964,-38,24 ],
  [ 2000,0,10,0,2, 88731,105340,10865,-2034, 222954,206742,18579,1351, 559443,390209,46620,14103, 902729,579932,75227,26900, 1255636,904256,104636,29282, -2303471,-30,19 ],
  [ 2000,0,15,0,2, 117212,110732,10419,576, 364967,377652,16589,-577, 966026,694167,43910,12357, 1599640,1091964,72711,23076, 2245525,1498696,102069,33947, -1512031,-22,-46 ],
  [ 2000,0,25,0,2, 137494,114574,10000,1667, 654066,680431,13698,-552, 2023710,1273364,38918,14430, 3482108,2200018,66964,24656, 5068894,3458586,97479,30967, 1365076,26,0 ],
  [ 2000,0,50,0,2, 139208,114899,9943,1736, 942701,894589,11883,606, 3760653,2422561,34188,12164, 6672863,4282649,60662,21729, 10187626,6807990,92615,30724, 5845528,56,0 ],
  [ 2000,0,75,0,2, 139208,114899,9943,1736, 947781,896086,11847,646, 3814855,2447496,34061,12209, 6774360,4426173,60485,20966, 10356759,6941751,92471,30491, 5887028,56,0 ],
  [ 2000,25,1,0,2, 14102,77862,14102,-63760, 24080,54583,24080,-30503, 64770,67206,64770,-2437, 83626,55444,83626,28181, 115827,64402,115827,51425, -3684771,-41,27 ],
  [ 2000,25,2,0,2, 26242,80154,13121,-26956, 47410,60165,23705,-6378, 119349,77905,59674,20722, 167251,72365,83626,47443, 231653,104189,115827,63732, -3335024,-39,25 ],
  [ 2000,25,3,0,2, 38382,82447,12794,-14688, 70740,79632,23580,-2964, 173928,88454,57976,28491, 250877,184570,83626,22102, 347480,325125,115827,7452, -3298681,-38,25 ],
  [ 2000,25,5,0,2, 56915,85946,12418,-6334, 112442,89944,22488,4500, 276004,199938,55201,15213, 412824,340694,82565,14426, 572193,403166,114439,33805, -3046277,-37,23 ],
  [ 2000,25,10,0,2, 94982,106474,11630,-1407, 234980,313902,19582,-6577, 599993,411063,49999,15744, 952998,698416,79416,21215, 1325726,918193,110477,33961, -2599380,-33,21 ],
  [ 2000,25,15,0,2, 124827,112114,11096,1130, 384004,382345,17455,75, 1028238,720640,46738,13982, 1688541,1189888,76752,22666, 2370766,1653129,107762,32620, -1393435,-21,-42 ],
  [ 2000,25,25,0,2, 146072,116131,10623,2178, 687056,702937,14389,-333, 2141176,1387108,41176,14501, 3674216,2441518,70658,23706, 5351432,3547325,102912,34694, 1500692,27,0 ],
  [ 2000,25,50,0,2, 147865,116470,10562,2243, 989379,907150,12471,1036, 3966850,2490548,36062,13421, 7037106,4587499,63974,22269, 10754940,7068973,97772,33509, 6605169,59,0 ],
  [ 2000,25,75,0,2, 147865,116470,10562,2243, 989379,907216,12367,1027, 3967498,2490687,35424,13186, 7037106,4587499,62831,21871, 10754940,7068973,96026,32910, 6606129,59,0 ],
  [ 2000,50,1,0,2, 16624,78320,16624,-61696, 25967,54854,25967,-28887, 77802,69776,77802,8026, 88041,56333,88041,31708, 121962,65673,121962,56289, -3638545,-41,27 ],
  [ 2000,50,2,0,2, 29326,80714,14663,-25694, 50435,60706,25217,-5136, 135224,81022,67612,27101, 176082,74136,88041,50973, 243924,190506,121962,26709, -3329956,-39,25 ],
  [ 2000,50,3,0,2, 42028,83109,14009,-13693, 74903,80444,24968,-1847, 192645,106633,64215,28670, 264122,187273,88041,25617, 365885,344649,121962,7079, -3259976,-38,25 ],
  [ 2000,50,5,0,2, 62016,86758,13531,-5398, 74903,91232,14981,-3266, 288826,195087,57765,18748, 416583,345029,83317,14311, 601047,404732,120209,39263, -3092707,-37,24 ],
  [ 2000,50,10,0,2, 101233,107609,12396,-781, 247006,316713,20584,-5809, 640543,418903,53379,18470, 1003266,708390,83606,24573, 1395817,948252,116318,37297, -2386266,-31,20 ],
  [ 2000,50,15,0,2, 132441,113496,11773,1684, 403041,387038,18320,727, 1090451,732599,49566,16266, 1777442,1222430,80793,25228, 2496006,1694421,113455,36436, -1064286,-16,-26 ],
  [ 2000,50,25,0,2, 154649,117688,11247,2688, 720046,711557,15079,178, 2258641,1530052,43435,14011, 3866324,2509727,74352,26088, 5633971,3833485,108346,34625, 1727141,30,0 ],
  [ 2000,50,50,0,2, 156504,117688,11179,2773, 776506,913673,9788,-1729, 2734259,1545052,24857,10811, 4247310,2509727,38612,15796, 6192164,3833485,56292,21443, 3274067,42,0 ],
  [ 2000,50,75,0,2, 156523,118042,11180,2749, 1041613,935230,13020,1330, 4232708,2738356,37792,13342, 7513676,4914925,67086,23203, 11510164,7693991,102769,34073, 7063651,61,0 ],
  [ 2000,100,1,0,2, 21670,79236,21670,-57566, 29741,55397,29741,-25656, 103868,74916,103868,28953, 96871,58109,96871,38762, 134232,68214,134232,66018, -3546094,-40,26 ],
  [ 2000,100,2,0,2, 35495,81834,17748,-23169, 56484,75674,28242,-9595, 166974,101621,83487,32676, 193743,77726,96871,58008, 268464,195579,134232,36442, -3277436,-38,25 ],
  [ 2000,100,3,0,2, 49320,84432,16440,-11704, 83228,82066,27743,387, 230079,113962,76693,38706, 290614,192578,96871,32679, 402696,352254,134232,16814, -3093220,-37,24 ],
  [ 2000,100,5,0,2, 70420,101734,15364,-6832, 130983,93707,26197,7455, 347987,334536,69597,2690, 478205,354449,95641,24751, 663075,438278,132615,44960, -3037960,-37,23 ],
  [ 2000,100,10,0,2, 113735,109878,13927,472, 271059,336219,22588,-5430, 721644,525947,60137,16308, 1103803,743363,91984,30037, 1535998,992698,128000,45275, -2224551,-30,18 ],
  [ 2000,100,15,0,2, 147671,116260,13126,2792, 441114,410309,20051,1400, 1214876,847881,55222,16682, 1955244,1272589,88875,31030, 2746488,1845114,124840,40972, -686026,-10,0 ],
  [ 2000,100,25,0,2, 171805,120801,12495,3709, 786025,742683,16461,908, 2493571,1603690,47953,17113, 4250540,2803959,81741,27819, 6199047,4207883,119212,38292, 2533185,36,0 ],
  [ 2000,100,50,0,2, 173839,121185,12417,3761, 1129412,1150803,14236,-270, 4585440,2923732,41686,15106, 8129834,5283728,73908,25874, 12456880,8259105,113244,38162, 7737498,64,0 ],
  [ 2000,100,75,0,2, 173839,121185,12417,3761, 1135445,1152574,14193,-214, 4650561,2936181,41523,15307, 8252993,5322920,73687,26161, 12663569,8316520,113068,38813, 8102079,65,0 ],
  [ 2000,200,1,0,2, 31761,81067,31761,-49306, 37289,56483,37289,-19194, 156000,99710,156000,56290, 114533,61713,114533,52819, 158772,73347,158772,85425, -3394692,-39,25 ],
  [ 2000,200,2,0,2, 47833,84073,23916,-18120, 68584,77839,34292,-4628, 230474,114090,115237,58192, 229065,99785,114533,64640, 317544,318816,158772,-636, -3120965,-37,24 ],
  [ 2000,200,3,0,2, 63905,87079,21302,-7725, 99878,85310,33293,4856, 304948,220135,101649,28271, 343598,311697,114533,10634, 476316,367514,158772,36267, -3129781,-37,24 ],
  [ 2000,200,5,0,2, 88428,105003,19293,-3616, 155705,98925,31141,11356, 443964,367760,88793,15241, 565379,386881,113076,35700, 784251,562767,156850,44297, -2693722,-34,21 ],
  [ 2000,200,10,0,2, 138738,114416,16988,2978, 319164,347460,26597,-2358, 883845,571821,73654,26002, 1304878,878592,108740,35524, 1816361,1278511,151363,44821, -1664245,-24,13 ],
  [ 2000,200,15,0,2, 178130,121789,15834,5008, 517261,502931,23512,651, 1463725,924745,66533,24499, 2310847,1561673,105039,34053, 3247450,2207253,147611,47282, -44968,4,0 ],
  [ 2000,200,25,0,2, 206115,140366,14990,4782, 917984,864749,19225,1115, 2963431,1919650,56989,20073, 5018972,3299540,96519,33066, 7329200,4828568,140946,48089, 3741784,45,0 ],
  [ 2000,200,50,0,2, 208470,140807,14891,4833, 1316122,1228666,16590,1102, 5410227,3426403,49184,18035, 9586806,6161521,87153,31139, 14726134,9710870,133874,45593, 10317355,72,0 ],
  [ 2000,200,75,0,2, 208470,140807,14891,4833, 1323108,1230712,16539,1155, 5486267,3546930,48985,17316, 9731625,6285698,86890,30767, 14970380,9905030,133664,45226, 10296001,72,0 ],
  [ 2500,0,1,0,2, 14657,78044,14657,-63387, 27519,55630,27519,-28111, 61958,52272,61958,9686, 94370,57746,94370,36623, 130944,67682,130944,63263, -3626864,-41,27 ],
  [ 2500,0,2,0,2, 29314,80873,14657,-25780, 55039,76147,27519,-10554, 123915,78938,61958,22488, 188739,77077,94370,55831, 261889,194583,130944,33653, -3361878,-39,25 ],
  [ 2500,0,3,0,2, 43971,83703,14657,-13244, 82558,82778,27519,-73, 185873,91240,61958,31544, 283109,191641,94370,30489, 392833,350794,130944,14013, -3181335,-38,24 ],
  [ 2500,0,5,0,2, 66346,101360,14475,-7639, 131970,94946,26394,7405, 302263,205831,60453,19286, 465776,352467,93155,22662, 646898,435464,129380,42287, -2887345,-35,23 ],
  [ 2500,0,10,0,2, 112315,110238,13753,254, 278324,339515,23194,-5099, 674257,427216,56188,20587, 1074958,740196,89580,27897, 1499031,987748,124919,42607, -2162213,-29,18 ],
  [ 2500,0,15,0,2, 148361,117201,13188,2770, 457642,416690,20802,1861, 1168909,842698,53132,14828, 1905989,1267700,86636,29013, 2680448,1836680,121839,38353, -854992,-13,0 ],
  [ 2500,0,25,0,2, 148361,117201,10790,2266, 457642,416690,9584,858, 2097336,1386933,40333,13662, 1971942,1327757,37922,12388, 2775741,1921129,53380,16435, 40042,6,0 ],
  [ 2500,0,50,0,2, 176196,135916,12585,2877, 1195847,1187759,15074,102, 4624736,2963828,42043,15099, 8020375,5301874,72913,24714, 12140859,8223663,110371,35611, 7389545,62,0 ],
  [ 2500,0,75,0,2, 176196,135916,12585,2877, 1195847,1187759,14948,101, 4670400,2987268,41700,15028, 8024231,5302759,71645,24299, 12146562,8224965,108451,35014, 7447891,63,0 ],
  [ 2500,25,1,0,2, 17180,78501,17180,-61322, 29406,55902,29406,-26495, 74990,69356,74990,5634, 98785,58685,98785,40100, 137080,69002,137080,68077, -3614138,-41,27 ],
  [ 2500,25,2,0,2, 32398,81433,16199,-24518, 58063,76688,29032,-9312, 139790,82055,69895,28867, 197570,78847,98785,59362, 274159,197119,137080,38520, -3297076,-38,25 ],
  [ 2500,25,3,0,2, 47617,84365,15872,-12249, 86720,83590,28907,1044, 204590,109419,68197,31724, 296355,194343,98785,34004, 411239,354647,137080,18864, -3131457,-37,24 ],
  [ 2500,25,5,0,2, 47740,84391,10416,-7997, 86763,83590,17353,635, 278341,201194,55668,15429, 301654,195491,60331,21233, 418777,356302,83755,12495, -3161916,-38,24 ],
  [ 2500,25,10,0,2, 118565,111372,14518,881, 290350,342326,24196,-4331, 714807,512056,59567,16896, 1125226,750169,93769,31255, 1569121,1002136,130760,47249, -2114424,-28,17 ],
  [ 2500,25,15,0,2, 155976,118583,13865,3324, 476679,421383,21667,2513, 1231122,854657,55960,17112, 1994889,1300242,90677,31575, 2805689,1877972,127531,42169, -525843,-7,0 ],
  [ 2500,25,25,0,2, 182606,137054,13280,3313, 859073,853196,17991,123, 2585732,1721346,49726,16623, 4355131,2866178,83753,28634, 6327543,4262498,121684,39712, 2448643,36,0 ],
  [ 2500,25,50,0,2, 184854,137488,13204,3383, 1242525,1214205,15662,357, 4830932,3137716,43918,15393, 8384618,5483788,76224,26371, 12708173,8582516,115529,37506, 7972944,65,0 ],
  [ 2500,25,75,0,2, 184854,137488,13204,3383, 1249273,1216206,15616,413, 4901394,3228486,43762,14937, 8513428,5524511,76013,26687, 12919080,8656986,115349,38054, 8168704,65,0 ],
  [ 2500,50,1,0,2, 19702,78959,19702,-59257, 31293,56173,31293,-24880, 88023,71926,88023,16097, 103200,59573,103200,43627, 143215,70273,143215,72942, -3567913,-40,26 ],
  [ 2500,50,2,0,2, 35483,81993,17741,-23255, 61088,77229,30544,-8071, 155665,85172,77833,35246, 206401,95594,103200,55403, 286429,199656,143215,43387, -3254378,-38,25 ],
  [ 2500,50,3,0,2, 51263,85027,17088,-11255, 90883,84401,30294,2161, 223307,113084,74436,36741, 309601,305455,103200,1382, 429644,358449,143215,23732, -3208153,-38,24 ],
  [ 2500,50,5,0,2, 51386,85053,11212,-7345, 90925,84401,18185,1305, 316042,328966,63208,-2585, 315045,307184,63009,1572, 437460,360715,87492,15349, -3481772,-40,26 ],
  [ 2500,50,10,0,2, 51386,85657,6292,-4196, 90925,160585,7577,-5805, 316042,409637,26337,-7800, 315045,307214,26254,653, 437460,360737,36455,6394, -3910435,-43,28 ],
  [ 2500,50,15,0,2, 163590,119965,14541,3878, 495716,426076,22533,3165, 1293334,881130,58788,18737, 2083790,1317858,94718,34815, 2930930,2032405,133224,40842, -288722,-2,0 ],
  [ 2500,50,25,0,2, 191183,138611,13904,3823, 892062,861816,18682,633, 2703197,1743725,51985,18451, 4547239,2999168,87447,29771, 6610081,4435017,127117,41828, 2938180,39,0 ],
  [ 2500,50,50,0,2, 193512,139059,13822,3889, 1289203,1226766,16250,787, 5037129,3268189,45792,16081, 8748861,5788588,79535,26912, 13275486,8843450,120686,40291, 8589587,67,0 ],
  [ 2500,50,75,0,2, 193512,139059,13822,3889, 1296189,1228836,16202,842, 5110320,3296981,45628,16191, 8883086,5830406,79313,27256, 13495782,9032931,120498,39847, 8865156,68,0 ],
  [ 2500,100,1,0,2, 210827,140678,210827,70149, 1390021,1016410,1390021,373611, 5442723,3526177,5442723,1916547, 9622402,6303957,9622402,3318444, 14649188,9853124,14649188,4796063, 10764336,74,0 ],
  [ 2500,100,2,0,2, 41651,83113,20826,-20731, 67138,78312,33569,-5587, 187415,105922,93708,40747, 224062,99185,112031,62439, 310969,204779,155485,53095, -3158275,-37,24 ],
  [ 2500,100,3,0,2, 58555,86350,19518,-9265, 99208,86023,33069,4395, 260741,197413,86914,21110, 336093,310760,112031,8444, 466454,366054,155485,33467, -3217895,-38,24 ],
  [ 2500,100,5,0,2, 84353,104628,18404,-4424, 156691,100164,31338,11305, 398240,345106,79648,10627, 552950,385400,110590,33510, 768074,560453,153615,41524, -2788485,-35,22 ],
  [ 2500,100,10,0,2, 137318,114776,16814,2760, 326429,350757,27202,-2027, 836458,564605,69705,22654, 1276032,875474,106336,33380, 1779393,1273611,148283,42148, -1811904,-26,14 ],
  [ 2500,100,15,0,2, 178820,122729,15895,4986, 533789,523197,24263,481, 1417759,919413,64444,22652, 2261592,1556784,102800,32037, 3181411,2198769,144610,44666, -257517,-1,0 ],
  [ 2500,100,25,0,2, 208339,141725,15152,4845, 958042,892942,20064,1363, 2938127,1923413,56502,19514, 4931455,3308877,94836,31203, 7175157,4809915,137984,45485, 3476743,43,0 ],
  [ 2500,100,50,0,2, 210827,142202,15059,4902, 1382558,1265772,17427,1472, 5449523,3572550,49541,17063, 9477347,6180017,86158,29976, 14410113,9591998,131001,43801, 9838452,71,0 ],
  [ 2500,100,75,0,2, 210827,142202,15059,4902, 1390021,1267980,17375,1526, 5528173,3602356,49359,17195, 9622402,6304232,85914,29627, 14649188,9853330,130796,42820, 9981821,71,0 ],
  [ 2500,200,1,0,2, 34839,81707,34839,-46867, 42616,57802,42616,-15186, 166220,101860,166220,64361, 129692,64954,129692,64738, 180025,77948,180025,102077, -3324060,-39,25 ],
  [ 2500,200,2,0,2, 53989,85352,26995,-15681, 79237,80477,39619,-620, 250915,132905,125458,59005, 259384,186624,129692,36380, 360050,343738,180025,8156, -3142898,-37,24 ],
  [ 2500,200,3,0,2, 73139,102334,24380,-9732, 115858,89118,38619,8914, 335610,332635,111870,992, 389076,337046,129692,17343, 540075,397635,180025,47480, -3251231,-38,25 ],
  [ 2500,200,5,0,2, 102360,107897,22333,-1208, 181412,119267,36282,12429, 494217,378181,98843,23207, 640124,417832,128025,44458, 889250,585441,177850,60762, -2416923,-32,19 ],
  [ 2500,200,10,0,2, 162321,119314,19876,5266, 374534,375884,31211,-113, 998659,716378,83222,23523, 1477107,945871,123092,44270, 2059756,1363004,171646,58063, -1303772,-20,-40 ],
  [ 2500,200,15,0,2, 209279,141594,18603,6016, 609936,541820,27724,3096, 1666609,1179327,75755,22149, 2617196,1753035,118963,39280, 3682373,2545887,167381,51658, 289897,11,0 ],
  [ 2500,200,25,0,2, 209279,141594,15220,4923, 839094,829752,17573,196, 2900836,1913868,55785,18980, 2693631,1815961,51801,16878, 3800376,2650941,73084,22105, 1274797,24,0 ],
  [ 2500,200,50,0,2, 245458,220188,17533,1805, 1569268,1521986,19781,596, 6274310,4073721,57039,20005, 10934318,7072637,99403,35106, 16679367,11156204,151631,50211, 11515880,76,0 ],
  [ 2500,200,75,0,2, 245458,220188,17533,1805, 1577685,1524468,19721,665, 6363879,4091040,56820,20293, 11101034,7200979,99116,34822, 16955998,11327550,151393,50254, 11831980,77,0 ],
  [ 3000,0,1,0,2, 17735,78683,17735,-60948, 32846,56949,32846,-24103, 72178,68937,72178,3242, 109529,61037,109529,48492, 152197,72332,152197,79866, -3589731,-40,26 ],
  [ 3000,0,2,0,2, 35470,82152,17735,-23341, 65692,78785,32846,-6546, 144357,83239,72178,30559, 219058,98535,109529,60262, 304395,203783,152197,50306, -3242717,-38,25 ],
  [ 3000,0,3,0,2, 53205,85621,17735,-10805, 98538,86735,32846,3934, 216535,112205,72178,34777, 328588,309873,109529,6238, 456592,364645,152197,30649, -3163011,-38,24 ],
  [ 3000,0,5,0,2, 80279,104254,17515,-5231, 157678,115288,31536,8478, 352515,336817,70503,3140, 540521,383969,108104,31310, 751897,542468,150379,41886, -2949158,-36,23 ],
  [ 3000,0,10,0,2, 135898,115136,16641,2542, 333694,354054,27808,-1697, 789070,542874,65756,20516, 1247187,872307,103932,31240, 1742426,1268661,145202,39480, -1926064,-27,16 ],
  [ 3000,0,15,0,2, 179510,137006,15956,3778, 550317,529429,25014,949, 1371792,899715,62354,21458, 2212337,1551995,100561,30016, 3115372,2190435,141608,42043, -447676,-5,0 ],
  [ 3000,0,25,0,2, 210562,143084,15314,4908, 998099,907250,20903,1903, 2912823,1927325,56016,18952, 4843938,3222930,93153,31173, 7021115,4791313,135021,42881, 3395914,42,0 ],
  [ 3000,0,50,0,2, 213184,143597,15227,4971, 1448994,1376729,18265,911, 5488818,3612646,49898,17056, 9367887,6198014,85163,28817, 14094092,9556406,128128,41252, 9311411,69,0 ],
  [ 3000,0,75,0,2, 213184,143597,15227,4971, 1456934,1379098,18212,973, 5570079,3643266,49733,17204, 9513179,6322715,84939,28486, 14327995,9620380,127929,42032, 9596553,70,0 ],
  [ 3000,25,1,0,2, 20258,79141,20258,-58883, 34733,57221,34733,-22488, 85211,71507,85211,13705, 113945,61926,113945,52019, 158332,73602,158332,84730, -3543506,-40,26 ],
  [ 3000,25,2,0,2, 38554,82712,19277,-22079, 68717,79326,34358,-5305, 160232,86356,80116,36938, 227889,100305,113945,63792, 316665,319410,158332,-1373, -3258547,-38,25 ],
  [ 3000,25,3,0,2, 56851,86283,18950,-9811, 102701,87547,34234,5051, 235252,192870,78417,14128, 341834,327502,113945,4777, 474997,368447,158332,35517, -3278235,-38,25 ],
  [ 3000,25,5,0,2, 84780,105071,18498,-4427, 163858,116592,32772,9453, 376509,341494,75302,7003, 562314,388320,112463,34799, 782191,564386,156438,43561, -2842644,-35,22 ],
  [ 3000,25,10,0,2, 142149,116270,17406,3169, 345720,370749,28810,-2086, 829621,550564,69135,23255, 1297455,897257,108121,33350, 1812517,1282999,151043,44126, -1767464,-25,14 ],
  [ 3000,25,15,0,2, 187125,138388,16633,4332, 569354,534122,25880,1601, 1434005,926188,65182,23083, 2301238,1584588,104602,32575, 3240612,2231777,147301,45856, -152027,2,0 ],
  [ 3000,25,25,0,2, 219140,144641,15937,5418, 1031089,929756,21593,2122, 3030288,2041069,58275,19023, 5036046,3355970,96847,32309, 7303653,4963832,140455,44997, 3631870,44,0 ],
  [ 3000,25,50,0,2, 221842,216869,15846,355, 1495671,1507525,18853,-149, 5695015,3755984,51773,17628, 9732130,6394404,88474,30343, 14661406,9914259,133286,43156, 9320980,69,0 ],
  [ 3000,25,75,0,2, 221842,216869,15846,355, 1503850,1509962,18798,-76, 5779006,3772596,51598,17914, 9882837,6548252,88240,29773, 14904698,10079506,133078,43082, 9544609,70,0 ],
  [ 3000,50,1,0,2, 22781,79599,22781,-56818, 36620,57492,36620,-20872, 98244,74076,98244,24168, 118360,62814,118360,55546, 164467,74873,164467,89594, -3497280,-40,26 ],
  [ 3000,50,2,0,2, 41639,83272,20819,-20816, 71742,79868,35871,-4063, 176107,103988,88053,36060, 236720,182433,118360,27143, 328935,321997,164467,3469, -3345770,-39,25 ],
  [ 3000,50,3,0,2, 60497,86945,20166,-8816, 106863,88358,35621,6168, 253970,196534,84657,19145, 355080,330155,118360,8308, 493402,387921,164467,35160, -3206030,-38,24 ],
  [ 3000,50,5,0,2, 89282,105888,19480,-3623, 170038,117897,34008,10428, 400503,346171,80101,10866, 584108,392672,116822,38287, 812485,570633,162497,48371, -2724957,-34,22 ],
  [ 3000,50,10,0,2, 148400,117405,18171,3795, 357746,373560,29812,-1318, 870171,572918,72514,24771, 1347724,907280,112310,36704, 1882607,1313108,156884,47458, -1587851,-23,12 ],
  [ 3000,50,15,0,2, 194739,139771,17310,4886, 588390,538815,26745,2253, 1496217,952662,68010,24707, 2390139,1617180,108643,35134, 3365853,2257448,152993,50382, 154795,8,0 ],
  [ 3000,50,25,0,2, 227717,217898,16561,714, 1064079,938376,22284,2633, 3147753,2077962,60534,20573, 5228154,3408702,100541,34989, 7586191,5165711,145888,46548, 3891551,46,0 ],
  [ 3000,50,50,0,2, 230500,218440,16464,861, 1542349,1520086,19441,281, 5901212,3915507,53647,18052, 10096373,6684727,91785,31015, 15228719,10288783,138443,44909, 9810215,71,0 ],
  [ 3000,50,75,0,2, 230500,218440,16464,861, 1550766,1522592,19385,352, 5987932,3947141,53464,18221, 10252495,6731210,91540,31440, 15481400,10455950,138227,44870, 10177860,72,0 ],
  [ 3000,100,1,0,2, 27826,80514,27826,-52688, 40394,58035,40394,-17641, 124310,79216,124310,45094, 127190,64591,127190,62599, 176737,77415,176737,99323, -3404829,-39,26 ],
  [ 3000,100,2,0,2, 27826,80514,13913,-26344, 40394,58035,20197,-8820, 186250,106045,93125,40103, 130714,65404,65357,32655, 181827,78582,90913,51623, -3317670,-39,25 ],
  [ 3000,100,3,0,2, 67789,101605,22596,-11272, 115188,89830,38396,8453, 291404,218378,97135,24342, 381571,335659,127190,15304, 530212,395726,176737,44829, -3127467,-37,24 ],
  [ 3000,100,5,0,2, 98286,107522,21444,-2015, 182399,194356,36480,-2391, 448492,370041,89698,15690, 627695,416401,125539,42259, 873073,583177,174615,57979, -2778967,-34,22 ],
  [ 3000,100,10,0,2, 160250,107522,19623,6456, 353464,362736,29455,-773, 725756,375041,60480,29226, 1077094,416401,89758,55058, 1481107,583177,123426,74828, -802856,-12,0 ],
  [ 3000,100,15,0,2, 209969,142535,18664,5994, 626464,562086,28476,2926, 1620642,1173994,73666,20302, 2567941,1748146,116725,37263, 3616334,2537403,164379,49042, 77347,7,0 ],
  [ 3000,100,25,0,2, 244873,221012,17809,1735, 1130058,1147702,23666,-369, 3382683,2150100,65052,23704, 5612370,3717911,107930,36432, 8151268,5523938,156755,50526, 4126263,47,0 ],
  [ 3000,100,50,0,2, 247816,221583,17701,1874, 1635704,1559092,20618,966, 6313605,4113817,57396,19998, 10824859,7090633,98408,33948, 16363346,11007521,148758,48689, 11303252,75,0 ],
  [ 3000,100,75,0,2, 247816,221583,17701,1874, 1644598,1561736,20557,1036, 6405785,4146466,57195,20172, 10991811,7219512,98141,33681, 16634806,11207740,148525,48456, 11566361,76,0 ],
  [ 3000,200,1,0,2, 37917,82346,37917,-44429, 47942,59121,47942,-11179, 176441,104010,176441,72431, 144852,68195,144852,76657, 201278,82548,201278,118730, -3253427,-38,25 ],
  [ 3000,200,2,0,2, 60145,86631,30073,-13243, 89890,83115,44945,3388, 271357,214205,135678,28576, 289703,193106,144852,48299, 402555,352938,201278,24809, -3178130,-38,24 ],
  [ 3000,200,3,0,2, 82373,104252,27458,-7293, 131838,93075,43946,12921, 366272,339085,122091,9062, 434555,346769,144852,29262, 603833,411436,201278,64132, -3039333,-37,23 ],
  [ 3000,200,5,0,2, 116293,110791,25373,1201, 207120,199573,41424,1509, 544469,403266,108894,28241, 714869,514165,142974,40141, 994249,736976,198850,51455, -2552048,-33,20 ],
  [ 3000,200,10,0,2, 185905,124212,22764,7554, 429904,390423,35825,3290, 1113473,755036,92789,29870, 1649335,1186442,137445,38574, 2303151,1643917,191929,54936, -1051200,-16,-32 ],
  [ 3000,200,15,0,2, 240428,219763,21371,1837, 702611,685208,31937,791, 1869492,1249358,84977,28188, 2923544,1956923,132888,43937, 4117297,2785951,187150,60516, 530737,15,0 ],
  [ 3000,200,25,0,2, 279183,328106,20304,-3558, 1262018,1194568,26430,1413, 3852544,2466061,74087,26663, 6380802,4214842,122708,41653, 9281421,6245425,178489,58385, 5138754,53,0 ],
  [ 3000,200,50,0,2, 279183,328133,19942,-3496, 1262018,1349272,15908,-1100, 3901828,2505617,35471,12693, 6380802,4214943,58007,19690, 9281421,6245526,84377,27599, 4670746,50,0 ],
  [ 3000,200,75,0,2, 282447,328736,20175,-3306, 1832261,1712374,22903,1499, 7241491,4742700,64656,22311, 12470444,8226719,111343,37890, 18941616,12668789,169122,56007, 13229173,81,0 ],
  [ 1000,0,1,1,2, 9640,76835,9640,-67194, 18511,53178,18511,-34667, 45065,48397,45065,-3332, 66546,36506,66546,30041, 87753,58065,87753,29688, -3726693,-41,27 ],
  [ 1000,0,2,1,2, 19280,78455,9640,-29587, 37022,57358,18511,-10168, 90130,71340,45065,9395, 133093,64548,66546,34272, 175507,75948,87753,49779, -3491253,-40,26 ],
  [ 1000,0,3,1,2, 28921,80076,9640,-17052, 55533,75423,18511,-6630, 135195,79617,45065,18526, 199639,77564,66546,40692, 263260,193183,87753,23359, -3314701,-39,25 ],
  [ 1000,0,5,1,2, 43640,82552,9521,-8490, 88092,83015,17618,1015, 218652,109653,43730,21800, 329391,306578,65878,4563, 434126,356555,86825,15514, -3204353,-38,24 ],
  [ 1000,0,10,1,2, 73892,100983,9048,-3317, 181011,119677,15084,5111, 479388,355124,39949,10355, 765635,516568,63803,20756, 1009984,732449,84165,23128, -2467923,-32,20 ],
  [ 1000,0,15,1,2, 97625,104983,8678,-654, 290972,340172,13226,-2236, 819265,536158,37239,12869, 1359510,879093,61796,21837, 1813300,1251552,82423,25534, -1829301,-26,15 ],
  [ 1000,0,25,1,2, 114536,107839,8330,487, 506342,426669,10604,1669, 1680662,961037,32320,13839, 2946970,1803138,56673,21997, 4137454,2636841,79566,28858, 1283380,25,0 ],
  [ 1000,0,50,1,2, 115965,108082,8283,563, 716763,705483,9035,142, 3055352,1775558,27776,11634, 5583926,3402776,50763,19829, 8403077,5342647,76392,27822, 4995245,52,0 ],
  [ 1000,0,75,1,2, 115965,108082,8283,563, 720463,706524,9006,174, 3097491,1903469,27656,10661, 5666572,3418659,50594,20071, 8544587,5499435,76291,27189, 4893382,51,0 ],
  [ 1000,25,1,1,2, 13254,77490,13254,-64237, 21334,53602,21334,-32268, 61387,66128,61387,-4741, 72556,37765,72556,34791, 95766,59776,95766,35991, -3698835,-41,27 ],
  [ 1000,25,2,1,2, 23892,79292,11946,-27700, 41669,58204,20835,-8267, 110544,75343,55272,17601, 145112,66959,72556,39077, 191533,79263,95766,56135, -3402905,-39,26 ],
  [ 1000,25,3,1,2, 34531,81095,11510,-15521, 62004,76690,20668,-4895, 159702,84409,53234,25098, 217668,81176,72556,45497, 287299,198152,95766,29716, -3199362,-38,24 ],
  [ 1000,25,5,1,2, 50771,83846,11077,-7216, 97810,85060,19562,2550, 250805,192913,50161,11578, 359112,312515,71822,9319, 473751,364729,94750,21805, -3215786,-38,24 ],
  [ 1000,25,10,1,2, 80917,101522,9908,-2523, 186624,197906,15552,-940, 414349,366107,34529,4020, 627081,312743,52257,26195, 819989,370354,68332,37470, -2629532,-33,21 ],
  [ 1000,25,15,1,2, 110290,107282,9804,267, 321608,347678,14619,-1185, 907608,553121,41255,16113, 1481983,918273,67363,25623, 1978384,1300999,89927,30790, -1344640,-20,-39 ],
  [ 1000,25,25,1,2, 128913,110449,9375,1343, 560034,528386,11728,663, 1851936,1191052,35614,12709, 3214048,2074828,61809,21908, 4512525,2941745,86779,30207, 1086785,23,0 ],
  [ 1000,25,50,1,2, 128913,110449,9208,1319, 560034,528386,7059,399, 2892817,1735864,26298,10518, 3283529,2135648,29850,10435, 4611358,3026971,41921,14404, 2244311,34,0 ],
  [ 1000,25,75,1,2, 130485,110717,9320,1412, 797344,741066,9967,703, 3409022,2068140,30438,11972, 6185905,3849012,55231,20865, 9316298,6014082,83181,29484, 5727066,55,0 ],
  [ 1000,50,1,1,2, 16867,78146,16867,-61279, 24157,54026,24157,-29869, 77708,69343,77708,8365, 78566,53952,78566,24614, 103780,61436,103780,42343, -3659579,-41,27 ],
  [ 1000,50,2,1,2, 28504,80130,14252,-25813, 46316,59049,23158,-6367, 130959,79347,65479,25806, 157132,69370,78566,43881, 207559,82578,103780,62491, -3314556,-39,25 ],
  [ 1000,50,3,1,2, 40141,82113,13380,-13991, 68475,77958,22825,-3161, 184209,103716,61403,26831, 235697,99815,78566,45294, 311339,203171,103780,36056, -3139628,-37,24 ],
  [ 1000,50,5,1,2, 57903,85141,12633,-5943, 107528,87105,21506,4085, 282957,199172,56591,16757, 388832,333429,77766,11081, 513376,388574,102675,24960, -3083999,-37,24 ],
  [ 1000,50,10,1,2, 94374,104700,11556,-1264, 219393,202437,18283,1413, 592139,391411,49345,16727, 903525,558960,75294,28714, 1194136,785979,99511,34013, -2130543,-29,17 ],
  [ 1000,50,15,1,2, 122956,109581,10929,1189, 352245,355184,16011,-134, 995951,690650,45271,13877, 1604455,958003,72930,29384, 2143468,1366667,97430,35309, -1150032,-17,-29 ],
  [ 1000,50,25,1,2, 143290,113059,10421,2199, 613726,542367,12853,1494, 2023211,1238168,38908,15097, 3481126,2142273,66945,25747, 4887596,3148781,93992,33439, 1959592,32,0 ],
  [ 1000,50,50,1,2, 145005,113353,10358,2261, 869731,848187,10963,272, 3669928,2132374,33363,13978, 6607071,4070421,60064,23060, 9921031,6396677,90191,32040, 6569647,59,0 ],
  [ 1000,50,75,1,2, 145005,113353,10358,2261, 874225,849457,10928,310, 3720554,2155810,33219,13971, 6705238,4184080,59868,22510, 10088008,6444948,90071,32527, 6703828,60,0 ],
  [ 1000,100,1,1,2, 24094,79458,24094,-55364, 29803,54874,29803,-25071, 110351,75774,110351,34577, 90585,56372,90585,34214, 119806,64757,119806,55048, -3536862,-40,26 ],
  [ 1000,100,2,1,2, 37728,81804,18864,-22038, 55610,60741,27805,-2565, 171787,101869,85893,34959, 181170,74242,90585,53464, 239612,188708,119806,25452, -3242267,-38,25 ],
  [ 1000,100,3,1,2, 51361,84149,17120,-10929, 81417,80492,27139,308, 233223,113300,77741,39974, 271756,187347,90585,28136, 359417,326198,119806,11073, -3108107,-37,24 ],
  [ 1000,100,5,1,2, 72166,101066,15745,-6306, 126964,91045,25393,7184, 347262,332257,69452,3001, 448273,346004,89655,20454, 592625,405622,118525,37401, -3087441,-37,24 ],
  [ 1000,100,10,1,2, 114856,108418,14064,788, 257774,315696,21481,-4827, 704891,426198,58741,23224, 1041415,709813,86785,27633, 1378288,922789,114857,37958, -2105811,-28,17 ],
  [ 1000,100,15,1,2, 114856,108664,10209,550, 257774,317416,11717,-2711, 710474,441908,32294,12208, 1041415,709875,47337,15070, 1378288,922848,62649,20702, -2135747,-29,18 ],
  [ 1000,100,25,1,2, 172045,118278,12512,3910, 721110,688565,15102,682, 2365760,1528313,45495,16105, 4015281,2496435,77217,29209, 5637738,3660271,108418,38028, 2487359,36,0 ],
  [ 1000,100,50,1,2, 174045,118624,12432,3959, 1022700,903156,12891,1507, 4284504,2580855,38950,15488, 7630216,4724939,69366,26412, 11438985,7337716,103991,37284, 8311495,66,0 ],
  [ 1000,100,75,1,2, 174045,118624,12432,3959, 1027988,904655,12850,1542, 4343616,2591352,38782,15645, 7743905,4869694,69142,25663, 11631428,7474792,103852,37113, 8428552,66,0 ],
  [ 1000,200,1,1,2, 38547,82081,38547,-43534, 41095,56570,41095,-15475, 175637,103151,175637,72486, 114624,61261,114624,53363, 151858,71450,151858,80409, -3324928,-39,25 ],
  [ 1000,200,2,1,2, 56175,85152,28087,-14489, 74198,78008,37099,-1905, 253444,132249,126722,60597, 229248,98913,114624,65168, 303717,201967,151858,50875, -2988060,-36,23 ],
  [ 1000,200,3,1,2, 73802,101559,24601,-9252, 107300,85412,35767,7296, 331251,330033,110417,406, 343872,310956,114624,10972, 455575,362444,151858,31044, -3328070,-39,25 ],
  [ 1000,200,5,1,2, 100693,106244,21969,-1211, 165835,99226,33167,13322, 475873,371811,95175,20812, 567154,384780,113431,36475, 751124,537819,150225,42661, -2553694,-33,20 ],
  [ 1000,200,10,1,2, 155820,115853,19080,4894, 334537,347400,27878,-1072, 930393,575621,77533,29564, 1317194,874906,109766,36857, 1746592,1242340,145549,42021, -1453634,-22,-53 ],
  [ 1000,200,15,1,2, 198951,123375,17685,6718, 536063,501841,24367,1556, 1526009,927191,69364,27219, 2339291,1555841,106331,35611, 3133970,2140969,142453,45136, 250923,10,0 ],
  [ 1000,200,25,1,2, 229554,142053,16695,6364, 935877,845961,19600,1883, 3050858,1914038,58670,21862, 5083591,3269689,97761,34883, 7138023,4713161,137270,46632, 4245720,48,0 ],
  [ 1000,200,50,1,2, 232125,142502,16580,6402, 1328637,1205029,16748,1558, 5513657,3293137,50124,20187, 9676507,6090330,87968,32602, 14474893,9416167,131590,45988, 11333041,75,0 ],
  [ 1000,200,75,1,2, 232125,142502,16580,6402, 1335514,1206986,16694,1607, 5589742,3321299,49908,20254, 9821238,6117684,87690,33067, 14718269,9495331,131413,46633, 11748657,76,0 ],
  [ 1500,0,1,1,2, 15112,77971,15112,-62859, 27050,55240,27050,-28190, 59783,51494,59783,8289, 87180,55898,87180,31282, 115512,64078,115512,51434, -3644466,-41,27 ],
  [ 1500,0,2,1,2, 30225,80729,15112,-25252, 54101,75366,27050,-10633, 119566,77384,59783,21091, 174360,73380,87180,50490, 231024,103595,115512,63715, -3337350,-39,25 ],
  [ 1500,0,3,1,2, 45337,83486,15112,-12716, 81151,81608,27050,-152, 179349,88908,59783,30147, 261540,186096,87180,25148, 346536,324311,115512,7408, -3222970,-38,24 ],
  [ 1500,0,5,1,2, 68409,101033,14926,-7118, 129381,92999,25876,7276, 291220,201921,58244,17860, 431332,343513,86266,17564, 571474,402119,114295,33871, -2963541,-36,23 ],
  [ 1500,0,10,1,2, 115818,109690,14182,750, 270473,334876,22539,-5367, 646601,417672,53883,19077, 1001941,705576,83495,24697, 1329788,916331,110816,34455, -2323686,-31,19 ],
  [ 1500,0,15,1,2, 153001,116484,13600,3246, 441496,408326,20068,1508, 1116691,733474,50759,17419, 1781766,1218523,80989,25602, 2386692,1651312,108486,33426, -914894,-14,-20 ],
  [ 1500,0,25,1,2, 153001,116484,11127,2656, 441496,408326,9246,695, 1989325,1245011,38256,14314, 1837276,1261293,35332,11077, 2463033,1715880,47366,14368, -61915,4,0 ],
  [ 1500,0,50,1,2, 181723,121737,12980,4285, 1133713,1147484,14291,-174, 4361569,2756597,39651,14591, 7480268,4760592,68002,24724, 11017026,7290410,100155,33878, 7139333,61,0 ],
  [ 1500,0,75,1,2, 181723,121737,12980,4285, 1139797,1149252,14247,-118, 4424809,2768376,39507,14790, 7594356,4891082,67807,24136, 11202488,7343053,100022,34459, 7341446,62,0 ],
  [ 1500,25,1,1,2, 18726,78627,18726,-59901, 29873,55664,29873,-25791, 76105,69225,76105,6880, 93190,57158,93190,36032, 123525,65788,123525,57737, -3616608,-41,27 ],
  [ 1500,25,2,1,2, 34836,81566,17418,-23365, 58748,76212,29374,-8732, 139980,81388,69990,29296, 186380,75791,93190,55294, 247050,190690,123525,28180, -3308735,-39,25 ],
  [ 1500,25,3,1,2, 50947,84504,16982,-11186, 87622,82875,29207,1582, 203856,108215,67952,31880, 279570,189758,93190,29937, 370575,345001,123525,8525, -3152305,-37,24 ],
  [ 1500,25,5,1,2, 75541,102328,16482,-5844, 139098,95044,27820,8811, 323372,314231,64674,1828, 461052,349951,92210,22220, 611099,410793,122220,40061, -3043854,-37,23 ],
  [ 1500,25,10,1,2, 126059,111549,15436,1777, 289664,339331,24139,-4139, 702976,428558,58581,22868, 1070886,734236,89241,28054, 1421864,950906,118489,39246, -2037994,-28,17 ],
  [ 1500,25,15,1,2, 165666,118783,14726,4167, 472132,415832,21461,2559, 1205034,841952,54774,16504, 1904238,1242776,86556,30066, 2551776,1700809,115990,38680, -618128,-9,0 ],
  [ 1500,25,25,1,2, 165666,118783,12048,3410, 472132,415832,9888,1179, 2129983,1363044,40961,14749, 1961579,1300919,37723,12705, 2631900,1766207,50613,16648, 314986,11,0 ],
  [ 1500,25,50,1,2, 196243,137709,14017,4181, 1210198,1181761,15255,358, 4668857,2920480,42444,15894, 7991841,5094314,72653,26341, 11776003,7718890,107055,36883, 8093355,65,0 ],
  [ 1500,25,75,1,2, 196243,137709,14017,4181, 1216678,1183643,15208,413, 4736341,2933047,42289,16101, 8113690,5132817,72444,26615, 11974198,7857850,106912,36753, 8399034,66,0 ],
  [ 1500,50,1,1,2, 22339,79283,22339,-56944, 32696,56088,32696,-23392, 92426,72440,92426,19986, 99200,58368,99200,40832, 131538,67449,131538,64090, -3555249,-40,26 ],
  [ 1500,50,2,1,2, 39448,82403,19724,-21477, 63395,77058,31697,-6832, 160394,85391,80197,37502, 198399,78202,99200,60098, 263077,194004,131538,34536, -3220387,-38,25 ],
  [ 1500,50,3,1,2, 56557,85523,18852,-9655, 94093,84142,31364,3317, 228363,113007,76121,38452, 297599,193370,99200,34743, 394615,349970,131538,14882, -3036967,-37,23 ],
  [ 1500,50,5,1,2, 82672,103622,18038,-4571, 148816,97089,29763,10345, 355525,335006,71105,4104, 490773,355938,98155,26967, 650724,434688,130145,43207, -2923463,-36,23 ],
  [ 1500,50,10,1,2, 136300,113408,16690,2803, 308855,343785,25738,-2911, 759352,530959,63279,19033, 1139831,747969,94986,32655, 1513940,985532,126162,44034, -1940196,-27,16 ],
  [ 1500,50,15,1,2, 178332,121082,15852,5089, 502769,423338,22853,3610, 1293377,873431,58790,19088, 2026711,1282006,92123,33850, 2716860,1834086,123494,40126, -226702,0,0 ],
  [ 1500,50,25,1,2, 208240,139883,15145,4971, 895557,855392,18755,841, 2684214,1722134,51620,18502, 4428806,2842687,85169,30502, 6183583,4063434,118915,40772, 3057246,40,0 ],
  [ 1500,50,50,1,2, 210763,140344,15054,5030, 1286682,1216188,16219,889, 4976145,3113413,45238,16934, 8503414,5428386,77304,27955, 12534980,8231500,113954,39123, 8973455,68,0 ],
  [ 1500,50,75,1,2, 210763,140344,15054,5030, 1293559,1218185,16169,942, 5047872,3218282,45070,16336, 8633023,5468436,77081,28255, 12745908,8289266,113803,39791, 9154201,69,0 ],
  [ 1500,100,1,1,2, 29566,80595,29566,-51029, 38342,56936,38342,-18593, 125069,78871,125069,46198, 111219,60788,111219,50431, 147565,70770,147565,76795, -3432532,-39,26 ],
  [ 1500,100,2,1,2, 48672,84077,24336,-17703, 72688,78749,36344,-3030, 201223,107914,100611,46655, 222438,98051,111219,62194, 295129,200683,147565,47223, -3099294,-37,24 ],
  [ 1500,100,3,1,2, 67778,100896,22593,-11039, 107035,86527,35678,6836, 277377,199591,92459,25928, 333657,309205,111219,8151, 442694,360057,147565,27545, -3197555,-38,24 ],
  [ 1500,100,5,1,2, 96936,106211,21150,-2024, 168252,115065,33650,10637, 419830,347376,83966,14491, 550213,382789,110043,33485, 729973,534766,145995,39041, -2718755,-34,21 ],
  [ 1500,100,10,1,2, 156782,117126,19198,4856, 347236,352695,28936,-455, 872103,567246,72675,25405, 1277721,870669,106477,33921, 1698092,1235933,141508,38513, -1627926,-24,12 ],
  [ 1500,100,15,1,2, 203664,139016,18103,5746, 564041,525935,25638,1732, 1470063,921723,66821,24925, 2271656,1549334,103257,32833, 3047027,2046219,138501,45491, 17830,6,0 ],
  [ 1500,100,25,1,2, 236994,216802,17236,1469, 1002940,897240,21004,2214, 3026763,1922264,58207,21240, 4962961,3196799,95442,33965, 6933725,4604685,133341,44789, 3806751,45,0 ],
  [ 1500,100,50,1,2, 239803,217315,17129,1606, 1439650,1345007,18147,1193, 5590721,3547379,50825,18576, 9526559,6111007,86605,31050, 14052934,9172489,127754,44368, 10179488,72,0 ],
  [ 1500,100,75,1,2, 239803,217315,17129,1606, 1447322,1347233,18092,1251, 5670935,3576824,50633,18697, 9671690,6154049,86354,31408, 14289329,9447872,127583,43227, 10435853,73,0 ],
  [ 1500,200,1,1,2, 44019,83218,44019,-39199, 49634,58631,49634,-8997, 190355,106248,190355,84107, 135258,65677,135258,69581, 179617,77462,179617,102155, -3220598,-38,24 ],
  [ 1500,200,2,1,2, 67119,87425,33559,-10153, 91276,82131,45638,4572, 282880,215444,141440,33718, 270516,188053,135258,41232, 359234,342754,179617,8240, -3166227,-38,24 ],
  [ 1500,200,3,1,2, 90219,104969,30073,-4917, 132918,91596,44306,13774, 375404,353839,125135,7188, 405773,339231,135258,22181, 538851,396203,179617,47549, -3081856,-37,24 ],
  [ 1500,200,5,1,2, 90219,104969,19684,-3218, 132918,91596,26584,8264, 501104,377915,100221,24638, 411592,340490,82318,14220, 547468,398094,109494,29875, -2837394,-35,22 ],
  [ 1500,200,10,1,2, 197746,124561,24214,8961, 423999,384399,35333,3300, 1097606,745719,91467,29324, 1553500,955954,129458,49795, 2066396,1343442,172200,60246, -774565,-12,0 ],
  [ 1500,200,15,1,2, 254327,219912,22607,3059, 686587,569844,31208,5306, 1823435,1216022,82883,27610, 2761546,1772034,125525,44978, 3707362,2540928,168516,53020, 788832,19,0 ],
  [ 1500,200,25,1,2, 294504,328107,21418,-2444, 1217708,1157636,25502,1258, 3711861,2383489,71382,25546, 6031271,3862943,115986,41699, 8434009,5629115,162192,53940, 4883984,51,0 ],
  [ 1500,200,50,1,2, 297882,328724,21277,-2203, 1745587,1571680,22003,2192, 6819874,4168126,61999,24107, 11572850,7353861,105208,38354, 17088842,11168059,155353,53825, 13529624,81,0 ],
  [ 1500,200,75,1,2, 297882,328724,21277,-2203, 1754848,1648214,21936,1333, 6917061,4369256,61759,22748, 11749023,7498624,104902,37950, 17376170,11340949,155144,53886, 13205158,81,0 ],
  [ 2000,0,1,1,2, 20584,79108,20584,-58523, 35590,57302,35590,-21712, 74501,69107,74501,5394, 107814,60364,107814,47450, 143271,70140,143271,73131, -3573637,-40,26 ],
  [ 2000,0,2,1,2, 41169,83002,20584,-20916, 71179,79490,35590,-4155, 149002,83578,74501,32712, 215628,97189,107814,59220, 286542,199400,143271,43571, -3210527,-38,24 ],
  [ 2000,0,3,1,2, 61753,100233,20584,-12826, 106769,87643,35590,6375, 223503,112715,74501,36929, 323442,308004,107814,5146, 429812,358220,143271,23864, -3169420,-38,24 ],
  [ 2000,0,5,1,2, 93179,106178,20330,-2836, 170669,117018,34134,10730, 363788,337605,72758,5237, 533273,380849,106655,30485, 708822,531813,141764,35402, -2873733,-35,22 ],
  [ 2000,0,10,1,2, 157744,118398,19316,4818, 359936,371875,29995,-995, 813813,544205,67818,22467, 1238247,866382,103187,30989, 1649592,1145645,137466,41996, -1752566,-25,14 ],
  [ 2000,0,15,1,2, 208377,141321,18522,5961, 592020,536294,26910,2533, 1414117,916405,64278,22623, 2204021,1434217,100183,34991, 2960084,2035150,134549,42042, -16646,5,0 ],
  [ 2000,0,25,1,2, 244434,219851,17777,1788, 1070003,934633,22408,2835, 3002669,1930640,57744,20616, 4842330,3189239,93122,31790, 6729427,4466948,129412,43509, 3629827,44,0 ],
  [ 2000,0,50,1,2, 247480,220428,17677,1932, 1550664,1515485,19546,443, 5667785,3617071,51525,18643, 9376611,6146160,85242,29368, 13630975,9040903,123918,41728, 9546219,70,0 ],
  [ 2000,0,75,1,2, 247480,220428,17677,1932, 1559131,1517979,19489,514, 5752128,3647798,51358,18789, 9522141,6189914,85019,29752, 13860388,9202542,123753,41588, 9886434,71,0 ],
  [ 2000,25,1,1,2, 24198,79764,24198,-55566, 38413,57726,38413,-19313, 90823,72322,90823,18500, 113824,61574,113824,52250, 151284,71801,151284,79483, -3512278,-40,26 ],
  [ 2000,25,2,1,2, 45781,83839,22890,-19029, 75826,80335,37913,-2254, 169416,87582,84708,40917, 227647,99600,113824,64024, 302568,202715,151284,49927, -3122179,-37,24 ],
  [ 2000,25,3,1,2, 67364,101251,22455,-11296, 113240,88910,37747,8110, 248010,194507,82670,17834, 341471,311616,113824,9952, 453852,363189,151284,30221, -3230580,-38,24 ],
  [ 2000,25,5,1,2, 100310,107473,21886,-1563, 180387,119063,36077,12265, 395940,343865,79188,10415, 562993,386786,112599,35241, 748447,539987,149689,41692, -2708669,-34,21 ],
  [ 2000,25,10,1,2, 167985,120257,20570,5844, 379126,376329,31594,233, 870189,569606,72516,25049, 1307192,880116,108933,35590, 1741668,1248379,145139,41107, -1526832,-22,11 ],
  [ 2000,25,15,1,2, 221042,143620,19648,6882, 622656,543800,28303,3584, 1502460,933369,68294,25868, 2326494,1581857,105750,33847, 3125168,2168377,142053,43490, 248206,10,0 ],
  [ 2000,25,25,1,2, 258812,222461,18823,2644, 1123695,1052965,23533,1481, 3173943,2067770,61037,21273, 5109408,3352019,98258,33796, 7104498,4770953,136625,44876, 3755927,45,0 ],
  [ 2000,25,50,1,2, 262000,223064,18714,2781, 1627148,1549912,20510,974, 5975074,3795469,54319,19815, 9888184,6371322,89893,31971, 14389952,9553063,130818,43972, 10621773,73,0 ],
  [ 2000,25,75,1,2, 262000,223064,18714,2781, 1636012,1552521,20450,1044, 6063659,3918519,54140,19153, 10041474,6525483,89656,31393, 14632098,9717689,130644,43879, 10615367,73,0 ],
  [ 2000,50,1,1,2, 27811,80420,27811,-52608, 41236,58149,41236,-16914, 107144,75538,107144,31607, 119833,62784,119833,57049, 159297,73461,159297,85836, -3450919,-40,26 ],
  [ 2000,50,2,1,2, 50393,84676,25196,-17142, 80473,81181,40237,-354, 189830,106101,94915,41865, 239667,182368,119833,28649, 318594,319170,159297,-288, -3266487,-38,25 ],
  [ 2000,50,3,1,2, 72974,102269,24325,-9765, 119711,90177,39904,9844, 272517,199299,90839,24406, 359500,330205,119833,9765, 477891,368157,159297,36578, -3137345,-37,24 ],
  [ 2000,50,5,1,2, 107442,108767,23442,-289, 190105,194959,38021,-971, 428093,350125,85619,15594, 592713,392723,118543,39998, 788072,563832,157614,44848, -2788558,-35,22 ],
  [ 2000,50,10,1,2, 178226,122115,21824,6871, 398317,380784,33193,1461, 926564,580492,77214,28839, 1376137,908775,114678,38947, 1833744,1282955,152812,45899, -1241139,-19,-43 ],
  [ 2000,50,15,1,2, 233708,217618,20774,1430, 653292,565191,29695,4005, 1590803,1070747,72309,23639, 2448966,1621587,111317,37608, 3290251,2218324,149557,48724, 116420,8,0 ],
  [ 2000,50,25,1,2, 273189,225070,19868,3500, 1177387,1154681,24657,476, 3345218,2114735,64331,23663, 5376486,3419464,103394,37635, 7479569,4977938,143838,48108, 4351369,48,0 ],
  [ 2000,50,50,1,2, 276520,225699,19751,3630, 1703632,1584339,21474,1504, 6282362,3988402,57112,20854, 10399756,6705394,94543,33585, 15148929,10065673,137718,46211, 11501874,76,0 ],
  [ 2000,50,75,1,2, 276520,225699,19751,3630, 1712894,1587062,21411,1573, 6375191,4083189,56921,20464, 10560808,6767068,94293,33873, 15403809,10261646,137534,45912, 11706751,76,0 ],
  [ 2000,100,1,1,2, 35038,81731,35038,-46693, 46882,58997,46882,-12116, 139787,81968,139787,57819, 131853,65204,131853,66649, 175323,76782,175323,98541, -3328202,-39,25 ],
  [ 2000,100,2,1,2, 59616,86350,29808,-13367, 89767,82872,44884,3447, 230659,114108,115329,58275, 263706,187190,131853,38258, 350647,341470,175323,4588, -3100963,-37,24 ],
  [ 2000,100,3,1,2, 84194,104306,28065,-6704, 132652,92712,44217,13314, 321531,329448,107177,-2639, 395558,337980,131853,19193, 525970,394316,175323,43885, -3196719,-38,24 ],
  [ 2000,100,5,1,2, 121705,111356,26554,2258, 209541,199049,41908,2098, 492398,377159,98480,23048, 652154,419624,130431,46506, 867321,580230,173464,57418, -2514033,-32,20 ],
  [ 2000,100,10,1,2, 198708,139170,24332,7290, 436699,389544,36392,3930, 1039316,722829,86610,26374, 1514027,951818,126169,46851, 2017896,1337135,168158,56730, -970049,-15,-26 ],
  [ 2000,100,15,1,2, 259040,222216,23026,3273, 714565,684553,32480,1364, 1767489,1194689,80340,26036, 2693912,1765327,122451,42208, 3620419,2529309,164564,49596, 301161,11,0 ],
  [ 2000,100,25,1,2, 301944,331156,21960,-2125, 1284771,1195029,26906,1879, 3687767,2391865,70919,24921, 5910641,3855383,113666,39524, 8229712,5520688,158264,52097, 4686162,50,0 ],
  [ 2000,100,50,1,2, 305560,331837,21826,-1877, 1856601,1711658,23403,1827, 6896938,4422368,62699,22496, 11422902,7389515,103845,36667, 16666883,11008162,151517,51443, 12588217,79,0 ],
  [ 2000,100,75,1,2, 305560,331837,21826,-1877, 1866656,1714610,23333,1901, 6998254,4456246,62484,22697, 11599474,7519562,103567,36428, 16947229,11209260,151315,51232, 12890331,80,0 ],
  [ 2000,200,1,1,2, 49491,84355,49491,-34863, 58174,60693,58174,-2519, 205073,109345,205073,95728, 155892,70093,155892,85798, 207376,83475,207376,123901, -3116268,-37,24 ],
  [ 2000,200,2,1,2, 78063,103035,39032,-12486, 108355,86105,54177,11125, 312316,221638,156158,45339, 311783,305495,155892,3144, 414752,354929,207376,29911, -3172334,-38,24 ],
  [ 2000,200,3,1,2, 106635,108379,35545,-581, 158536,97781,52845,20252, 419558,363131,139853,18809, 467675,352479,155892,38399, 622127,414241,207376,69296, -2768866,-35,22 ],
  [ 2000,200,5,1,2, 150231,116534,32778,7352, 248412,207230,49682,8236, 621008,416713,124202,40859, 771035,523731,154207,49461, 1025820,741738,205164,56817, -2097605,-29,17 ],
  [ 2000,200,10,1,2, 239672,218305,29348,2616, 513461,421248,42788,7684, 1264818,872252,105402,32714, 1789806,1225371,149150,47036, 2386200,1656686,198850,60793, -771930,-12,0 ],
  [ 2000,200,15,1,2, 309703,332279,27529,-2007, 837111,726963,38050,5007, 2120861,1382938,96403,33542, 3183802,2097488,144718,49378, 4280753,2925567,194580,61599, 1108685,23,0 ],
  [ 2000,200,25,1,2, 359453,354931,26142,329, 1499538,1352425,31404,3081, 4372865,2777590,84094,30678, 6978951,4548330,134211,46743, 9729996,6460488,187115,62875, 6643679,59,0 ],
  [ 2000,200,50,1,2, 363640,355716,25974,566, 2162538,2013530,27259,1878, 8126091,5134650,73874,27195, 13469192,8631470,122447,43979, 19702791,13002832,179116,60909, 15851676,87,0 ],
  [ 2000,200,75,1,2, 363640,355716,25974,566, 2174182,2016941,27177,1966, 8244379,5248678,73611,26747, 13676808,8782479,122114,43699, 20034070,13313529,178876,60005, 15985602,87,0 ],
  [ 2500,0,1,1,2, 26057,80244,26057,-54188, 44129,59363,44129,-15234, 89219,72204,89219,17015, 128448,64780,128448,63667, 171030,76153,171030,94877, -3469306,-40,26 ],
  [ 2500,0,2,1,2, 52113,85275,26057,-16581, 88258,83613,44129,2323, 178438,89772,89219,44333, 256895,186328,128448,35284, 342059,324516,171030,8772, -3201023,-38,24 ],
  [ 2500,0,3,1,2, 78170,103642,26057,-8491, 132387,93827,44129,12853, 267657,199006,89219,22884, 385343,336228,128448,16372, 513089,391929,171030,40387, -3066204,-37,23 ],
  [ 2500,0,5,1,2, 117948,111323,25734,1445, 211957,201003,42391,2191, 436355,352874,87271,16696, 635213,417634,127043,43516, 846170,577228,169234,53789, -2635511,-33,21 ],
  [ 2500,0,10,1,2, 199670,140442,24449,7252, 449398,408723,37450,3390, 981026,699788,81752,23436, 1474553,947531,122879,43919, 1969396,1330627,164116,53231, -1154424,-18,-37 ],
  [ 2500,0,15,1,2, 263753,224521,23445,3487, 742544,708798,33752,1534, 1711542,1189221,77797,23742, 2626277,1758670,119376,39437, 3533476,2418739,160613,50670, 133934,8,0 ],
  [ 2500,0,25,1,2, 309384,347542,22501,-2775, 1351834,1232273,28311,2504, 3663672,2414756,70455,24018, 5790011,3767716,111346,38890, 8025414,5383151,154335,50813, 4539569,49,0 ],
  [ 2500,0,50,1,2, 313237,348287,22374,-2504, 1967614,1881986,24802,1079, 6974002,4492060,63400,22563, 11272954,7424818,102481,34983, 16244924,10876726,147681,48802, 11900255,77,0 ],
  [ 2500,0,75,1,2, 313237,348287,22374,-2504, 1978465,1885207,24731,1166, 7079447,4527220,63209,22788, 11449926,7475270,102231,35488, 16518288,11047860,147485,48843, 12345009,78,0 ],
  [ 2500,25,1,1,2, 29670,80900,29670,-51230, 46952,59787,46952,-12835, 105540,75419,105540,30121, 134457,65990,134457,68467, 179043,77813,179043,101229, -3407948,-39,26 ],
  [ 2500,25,2,1,2, 56725,86112,28362,-14694, 92905,84458,46452,4223, 198852,108291,99426,45280, 268915,188789,134457,40063, 358085,343551,179043,7267, -3157348,-38,24 ],
  [ 2500,25,3,1,2, 83780,104661,27927,-6960, 138858,95095,46286,14588, 292164,203798,97388,29455, 403372,339841,134457,21177, 537128,396898,179043,46743, -2950866,-36,23 ],
  [ 2500,25,5,1,2, 125080,112617,27290,2719, 221675,203048,44335,3726, 468508,373648,93702,18972, 664934,423621,132987,48263, 885795,585452,177159,60069, -2503946,-32,20 ],
  [ 2500,25,10,1,2, 209911,142301,25703,8279, 468589,413178,39049,4618, 1037401,725189,86450,26018, 1543498,961264,128625,48519, 2061472,1365252,171789,58018, -880129,-14,-20 ],
  [ 2500,25,15,1,2, 276418,327686,24571,-4557, 773180,714804,35145,2653, 1799885,1220700,81813,26327, 2748749,1799400,124943,43152, 3698559,2569187,168116,51335, 100513,8,0 ],
  [ 2500,25,25,1,2, 323761,350151,23546,-1919, 1405526,1260139,29435,3045, 3834947,2461721,73749,26408, 6057088,4038906,116482,38811, 8400485,5687556,161548,52172, 4998631,52,0 ],
  [ 2500,25,50,1,2, 327757,350922,23411,-1655, 2044099,1990263,25766,679, 7281290,4761993,66194,22903, 11784526,7650430,107132,37583, 17003901,11389336,154581,51042, 12530151,79,0 ],
  [ 2500,25,75,1,2, 327757,350922,23411,-1655, 2055346,1993598,25692,772, 7390978,4797941,65991,23152, 11969259,7891146,106868,36412, 17289999,11592318,154375,50872, 12700739,79,0 ],
  [ 2500,50,1,1,2, 33283,81556,33283,-48273, 49775,60211,49775,-10436, 121862,78635,121862,43227, 140467,67200,140467,73267, 187056,79474,187056,107582, -3346589,-39,25 ],
  [ 2500,50,2,1,2, 61337,86949,30668,-12806, 97552,85304,48776,6124, 219266,112295,109633,53486, 280934,191200,140467,44867, 374112,346866,187056,13623, -3069000,-37,24 ],
  [ 2500,50,3,1,2, 89390,105679,29797,-5430, 145329,96362,48443,16322, 316671,329155,105557,-4162, 421401,344003,140467,25799, 561167,402416,187056,52917, -3114406,-37,24 ],
  [ 2500,50,5,1,2, 132211,113912,28846,3993, 231393,205093,46279,5260, 500661,379908,100132,24150, 694654,509866,138931,36958, 925420,609297,185084,63225, -2468579,-32,20 ],
  [ 2500,50,10,1,2, 220152,144160,26957,9305, 487779,417633,40648,5846, 1093777,736075,91148,29809, 1612443,1098384,134370,42838, 2153548,1467937,179462,57134, -803071,-13,-14 ],
  [ 2500,50,15,1,2, 289084,329985,25696,-3636, 803816,722310,36537,3705, 1888228,1237663,85829,29571, 2871222,1947040,130510,42008, 3863643,2618634,175620,56591, 425099,14,0 ],
  [ 2500,50,25,1,2, 338138,352761,24592,-1063, 1459217,1347971,30560,2330, 4006221,2585836,77043,27315, 6324166,4201636,121619,40818, 8775556,6007682,168761,53228, 5239899,53,0 ],
  [ 2500,50,50,1,2, 342277,353558,24448,-806, 2120583,2010805,26730,1384, 7588579,4849026,68987,24905, 12296099,7984002,111783,39201, 17762878,11817715,161481,54047, 13758948,82,0 ],
  [ 2500,50,75,1,2, 342277,353558,24448,-806, 2132228,2028140,26653,1301, 7702510,4962612,68772,24463, 12488592,8241191,111505,37923, 18061709,12106964,161265,53167, 13652947,82,0 ],
  [ 2500,100,1,1,2, 40510,82868,40510,-42358, 55421,61059,55421,-5638, 154505,99581,154505,54925, 152487,69670,152487,82817, 203082,82845,203082,120237, -3257372,-38,25 ],
  [ 2500,100,2,1,2, 70560,101960,35280,-15700, 106846,86845,53423,10000, 260095,197302,130047,31396, 304973,196172,152487,54400, 406164,353645,203082,26259, -3123494,-37,24 ],
  [ 2500,100,3,1,2, 100611,107716,33537,-2368, 158270,98897,52757,19791, 365685,338739,121895,8982, 457460,351228,152487,35411, 609246,412354,203082,65631, -2883729,-35,22 ],
  [ 2500,100,5,1,2, 146474,116501,31958,6540, 250829,209183,50166,8329, 564966,406943,112993,31605, 754095,521791,150819,46461, 1004669,738785,200934,53177, -2252583,-30,18 ],
  [ 2500,100,10,1,2, 240634,219577,29465,2578, 526161,500393,43847,2147, 1206528,849362,100544,29764, 1750332,1221084,145861,44104, 2337700,1650228,194808,57289, -1146503,-17,-33 ],
  [ 2500,100,15,1,2, 314416,347920,27948,-2978, 865089,824907,39322,1826, 2064914,1300470,93860,34747, 3116167,2105958,141644,45919, 4193810,2914598,190628,58146, 736471,18,0 ],
  [ 2500,100,25,1,2, 366893,357980,26683,648, 1566601,1494169,32808,1517, 4348770,2798981,83630,29804, 6858321,4540821,131891,44567, 9525698,6435392,183187,59429, 6026002,57,0 ],
  [ 2500,100,50,1,2, 371317,358829,26523,892, 2273551,2079659,28658,2444, 8203155,5297357,74574,26416, 13319244,8666673,121084,42296, 19280832,12871795,175280,58264, 15335030,86,0 ],
  [ 2500,100,75,1,2, 371317,358829,26523,892, 2285990,2083338,28575,2533, 8325572,5321153,74335,26825, 13527259,8818345,120779,42044, 19605129,13053028,175046,58501, 15773977,87,0 ],
  [ 2500,200,1,1,2, 54963,85491,54963,-30528, 66713,76639,66713,-9926, 219791,126807,219791,92984, 176525,74559,176525,101966, 235135,188939,235135,46196, -3159929,-37,24 ],
  [ 2500,200,2,1,2, 89007,105308,44504,-8150, 125433,90228,62717,17603, 341752,333882,170876,3935, 353051,329803,176525,11624, 470269,367454,235135,51408, -3231155,-38,24 ],
  [ 2500,200,3,1,2, 123051,111789,41017,3754, 184154,117851,61385,22101, 463712,372272,154571,30480, 529576,380703,176525,49624, 705404,531680,235135,57908, -2592470,-33,20 ],
  [ 2500,200,5,1,2, 175001,121678,38182,11634, 289701,335599,57940,-9180, 693576,444847,138715,49746, 872976,560566,174595,62482, 1163168,786652,232634,75303, -2029306,-28,16 ],
  [ 2500,200,10,1,2, 281598,327879,34481,-5667, 602924,530597,50244,6027, 1432031,921785,119336,42520, 2026112,1307369,168843,59895, 2706004,1842518,225500,71957, -521172,-6,0 ],
  [ 2500,200,15,1,2, 365079,357116,32451,708, 987634,868816,44892,5401, 2418286,1594769,109922,37433, 3606058,2437118,163912,53134, 4854145,3325526,220643,69483, 1701117,29,0 ],
  [ 2500,200,25,1,2, 424402,368419,30866,4072, 1781368,1651715,37306,2715, 5033868,3261706,96805,34080, 7926631,5140134,152435,53586, 11025983,7375042,212038,70210, 7999685,65,0 ],
  [ 2500,200,50,1,2, 429397,369371,30671,4288, 2579488,2381681,32515,2493, 9432308,6009639,85748,31115, 15365535,9988785,139687,48880, 22316740,14837005,202879,67998, 18555554,92,0 ],
  [ 2500,200,75,1,2, 429397,369371,30671,4288, 2593516,2385819,32419,2596, 9571698,6128100,85462,30746, 15604592,10066185,139327,49450, 22691970,15157197,202607,67275, 18912544,93,0 ],
  [ 3000,0,1,1,2, 31529,81381,31529,-49852, 52668,75310,52668,-22642, 103937,75151,103937,28786, 149081,69196,149081,79885, 198788,82115,198788,116673, -3408560,-39,26 ],
  [ 3000,0,2,1,2, 63057,100885,31529,-18914, 105337,87586,52668,8875, 207874,110482,103937,48696, 298163,195360,149081,51401, 397577,352412,198788,22582, -3091730,-37,24 ],
  [ 3000,0,3,1,2, 94586,107052,31529,-4155, 158005,113897,52668,14703, 311811,314198,103937,-796, 447244,349977,149081,32423, 596365,410417,198788,61983, -3042175,-37,23 ],
  [ 3000,0,5,1,2, 142717,116468,31138,5727, 253246,315487,50649,-12448, 508923,381157,101785,25553, 737154,519800,147431,43471, 983518,735282,196704,49647, -2700682,-34,21 ],
  [ 3000,0,10,1,2, 241596,220850,29583,2540, 538860,505687,44905,2764, 1148238,840986,95687,25604, 1710859,1216847,142572,41168, 2289200,1643821,190767,53782, -1320794,-20,-42 ],
  [ 3000,0,15,1,2, 241596,220850,21475,1844, 538860,505687,24494,1508, 1759431,1203811,79974,25255, 1750150,1241029,79552,23142, 2342782,1687623,106490,29780, -723896,-11,0 ],
  [ 3000,0,25,1,2, 374333,361029,27224,968, 1633664,1531562,34213,2138, 4324676,2884357,83167,27698, 6737691,4548238,129571,42105, 9321401,6410745,179258,55974, 5569844,55,0 ],
  [ 3000,0,50,1,2, 378995,361942,27071,1218, 2384565,2323987,30058,764, 8280219,5367050,75275,26483, 13169296,8701826,119721,40613, 18858873,12710899,171444,55891, 14488877,84,0 ],
  [ 3000,0,75,1,2, 378995,361942,27071,1218, 2397799,2327935,29972,873, 8406765,5512692,75060,25840, 13377710,8839783,119444,40517, 19176189,13005119,171216,55099, 14593636,84,0 ],
  [ 3000,25,1,1,2, 35142,82037,35142,-46895, 55491,75734,55491,-20242, 120258,78366,120258,41892, 155091,70406,155091,84685, 206801,99447,206801,107354, -3358374,-39,25 ],
  [ 3000,25,2,1,2, 35142,82312,17571,-23585, 55491,76281,27746,-10395, 122037,78750,61018,21644, 155091,70419,77546,42336, 206801,99457,103401,53672, -3358081,-39,25 ],
  [ 3000,25,3,1,2, 100196,108071,33399,-2625, 164476,115165,54825,16437, 336318,333505,112106,938, 465274,353639,155091,37212, 620404,431106,206801,63099, -2971511,-36,23 ],
  [ 3000,25,5,1,2, 149849,117762,32694,7001, 262964,331417,52593,-13691, 541076,387267,108215,30762, 766874,540714,153375,45232, 1023143,743406,204629,55947, -2601304,-33,21 ],
  [ 3000,25,10,1,2, 251837,222709,30837,3567, 558051,524027,46504,2835, 1204614,851722,100384,29408, 1779804,1230530,148317,45773, 2381276,1662675,198440,59883, -1045409,-16,-27 ],
  [ 3000,25,15,1,2, 331794,352523,29493,-1843, 923704,856657,41987,3048, 2097311,1388966,95332,32198, 3171005,2123504,144137,47614, 4271951,2952925,194180,59956, 828603,20,0 ],
  [ 3000,25,25,1,2, 388710,363639,28270,1823, 1687356,1559428,35337,2679, 4495950,2931322,86461,30089, 7004768,4630660,134707,45656, 9696472,6602060,186471,59508, 6388137,58,0 ],
  [ 3000,25,50,1,2, 393515,364578,28108,2067, 2461049,2358414,31022,1294, 8587507,5636982,78068,26823, 13680869,9035898,124372,42227, 19617850,13139728,178344,58892, 15252215,85,0 ],
  [ 3000,25,75,1,2, 393515,364578,28108,2067, 2474680,2362476,30934,1403, 8718297,5677363,77842,27151, 13897044,9189828,124081,42029, 19947899,13435986,178106,58142, 15605578,86,0 ],
  [ 3000,50,1,1,2, 38755,82693,38755,-43937, 58314,76158,58314,-17843, 136580,81582,136580,54998, 161101,71616,161101,89485, 214815,101108,214815,113707, -3297016,-38,25 ],
  [ 3000,50,2,1,2, 72281,102559,36141,-15139, 114630,89277,57315,12677, 248702,195489,124351,26607, 322202,308642,161101,6780, 429629,359041,214815,35294, -3251604,-38,24 ],
  [ 3000,50,3,1,2, 105807,109089,35269,-1094, 170947,116432,56982,18172, 360825,338297,120275,7509, 483303,372228,161101,37025, 644444,436075,214815,69456, -2878276,-35,22 ],
  [ 3000,50,5,1,2, 156981,119057,34250,8274, 272682,333462,54536,-12156, 573228,408042,114646,33037, 796595,546701,159319,49979, 1062768,767301,212554,59093, -2480913,-32,20 ],
  [ 3000,50,10,1,2, 262078,224567,32091,4593, 577242,528482,48103,4063, 1260989,877123,105082,31989, 1848749,1259240,154062,49126, 2473352,1697300,206113,64671, -793217,-12,0 ],
  [ 3000,50,15,1,2, 344460,354822,30619,-921, 954340,864163,43379,4099, 2185654,1526494,99348,29962, 3293477,2163234,149704,51375, 4437035,3002922,201683,65187, 1034385,22,0 ],
  [ 3000,50,25,1,2, 403088,366248,29315,2679, 1741048,1573410,36462,3511, 4667225,3099002,89754,30158, 7271846,4887423,139843,45854, 10071543,6838955,193684,62165, 6682569,59,0 ],
  [ 3000,50,50,1,2, 408035,367213,29145,2916, 2537533,2378956,31986,1999, 8894796,5801015,80862,28125, 14192442,9341318,129022,44101, 20376827,13764979,185244,60108, 16205697,87,0 ],
  [ 3000,50,75,1,2, 408035,367213,29145,2916, 2551562,2397017,31895,1932, 9029828,5948084,80623,27516, 14416377,9525397,128718,43669, 20719609,13966804,184997,60293, 16323338,88,0 ],
  [ 3000,100,1,1,2, 45982,84004,45982,-38022, 63960,77006,63960,-13045, 169223,102528,169223,66695, 173120,74086,173120,99035, 230841,104479,230841,126362, -3207799,-38,24 ],
  [ 3000,100,2,1,2, 81505,104233,40752,-11364, 123924,90969,61962,16478, 289531,218012,144765,35760, 346241,328491,173120,8875, 461682,365720,230841,47981, -3130511,-37,24 ],
  [ 3000,100,3,1,2, 117027,111125,39009,1967, 183888,118967,61296,21641, 409838,362396,136613,15814, 519361,379502,173120,46620, 692522,446062,230841,82153, -2681099,-34,21 ],
  [ 3000,100,5,1,2, 171244,121645,37362,10821, 292117,337552,58423,-9087, 637534,420561,127507,43394, 856035,558576,171207,59492, 1142017,783649,228403,71674, -2150784,-29,17 ],
  [ 3000,100,10,1,2, 282560,329152,34599,-5705, 615623,535892,51302,6644, 1373741,913410,114478,38361, 1986638,1303132,165553,56959, 2657504,1836111,221459,68449, -695463,-10,0 ],
  [ 3000,100,15,1,2, 369792,359420,32870,922, 1015613,893060,46164,5571, 2362340,1574786,107379,35798, 3538423,2430411,160837,50364, 4767202,3185596,216691,71891, 1588289,28,0 ],
  [ 3000,100,25,1,2, 431842,384804,31407,3421, 1848432,1688958,38711,3340, 5009774,3270082,96342,33456, 7806001,5132725,150115,51409, 10821685,7350545,208109,66753, 7687436,63,0 ],
  [ 3000,100,50,1,2, 437074,385821,31220,3661, 2690502,2521510,33914,2130, 9509372,6157831,86449,30469, 15215587,10024088,138324,47195, 21894781,14706068,199043,65352, 17783935,91,0 ],
  [ 3000,100,75,1,2, 437074,385821,31220,3661, 2705324,2525915,33817,2243, 9652891,6306625,86187,29877, 15455044,10102700,137991,47789, 22263030,14913017,198777,65625, 18155894,92,0 ],
  [ 3000,200,1,1,2, 60436,86628,60436,-26192, 75252,78701,75252,-3449, 234509,129905,234509,104605, 197159,78975,197159,118184, 262893,194951,262893,67942, -3055599,-37,23 ],
  [ 3000,200,2,1,2, 99952,107582,49976,-3815, 142512,94351,71256,24081, 371188,354592,185594,8298, 394318,338685,197159,27816, 525787,395200,262893,65293, -3067168,-37,23 ],
  [ 3000,200,3,1,2, 139468,115199,46489,8090, 209772,197886,69924,3962, 507866,396079,169289,37262, 591478,394001,197159,65825, 788680,565439,262893,74414, -2557934,-33,20 ],
  [ 3000,200,5,1,2, 199770,140160,43586,13006, 330989,345583,66198,-2919, 766144,551631,153229,42903, 974917,706012,194983,53781, 1300516,916046,260103,76894, -2041802,-28,16 ],
  [ 3000,200,10,1,2, 323524,349924,39615,-3233, 692386,567446,57699,10412, 1599243,1091883,133270,42280, 2262418,1562359,188535,58338, 3025808,2072532,252151,79440, -245095,0,0 ],
  [ 3000,200,15,1,2, 420455,368616,37374,4608, 1138158,936970,51734,9145, 2715712,1763035,123441,43303, 4028313,2652612,183105,62532, 5427537,3679804,246706,79442, 2898653,39,0 ],
  [ 3000,200,25,1,2, 489352,395243,35589,6844, 2063199,1877004,43208,3899, 5694872,3654307,109517,39242, 8874311,5825522,170660,58631, 12321969,8289695,236961,77544, 9606805,70,0 ],
  [ 3000,200,50,1,2, 495154,396363,35368,7057, 2996439,2823532,37770,2179, 10738525,6884628,97623,35035, 17261878,11265943,156926,54508, 24930689,16671328,226643,75085, 21089483,97,0 ],
  [ 3000,200,75,1,2, 495154,396363,35368,7057, 3012850,2828396,37661,2306, 10899017,7007522,97313,34745, 17532377,11538808,156539,53514, 25349871,16917235,226338,75291, 21332148,97,0 ],
  [ 1000,0,1,2,2, 9925,77805,9925,-67881, 1071466,56745,1071466,1014721, 468017,56069,468017,411948, 129050,415823,129050,-286773, 174417,584374,174417,-409957, -210828,-4,-19 ],
  [ 1000,0,2,2,2, 30126,80279,15063,-25077, 54285,61038,27143,-3376, 122677,77429,61339,22624, 173835,72532,86918,50652, 222125,101107,111063,60509, -3287015,-38,25 ],
  [ 1000,0,3,2,2, 45189,82811,15063,-12541, 81428,80943,27143,162, 184016,88751,61339,31755, 260753,184824,86918,25310, 333188,320504,111063,4228, -3212844,-38,24 ],
  [ 1000,0,5,2,2, 68187,100016,14877,-6945, 129264,91785,25853,7496, 298081,201479,59616,19320, 430839,341529,86168,17862, 550054,395918,110011,30827, -2948689,-36,23 ],
  [ 1000,0,10,2,2, 115456,107976,14137,916, 266296,331250,22191,-5413, 656831,415383,54736,20121, 1005662,701613,83805,25337, 1283949,902421,106996,31794, -2302571,-30,19 ],
  [ 1000,0,15,2,2, 152539,114227,13559,3405, 429158,386980,19507,1917, 1127255,727815,51239,18156, 1791527,1196678,81433,27039, 2311196,1498607,105054,36936, -740070,-11,0 ],
  [ 1000,0,25,2,2, 178962,118690,13015,4383, 750467,708185,15717,885, 2333107,1395849,44867,18024, 3908894,2454831,75171,27963, 5302120,3471582,101964,35203, 2574569,37,0 ],
  [ 1000,0,50,2,2, 181196,119068,12943,4438, 1065916,925951,13436,1764, 4283671,2491839,38942,16289, 7459474,4584881,67813,26133, 10831146,6944460,98465,35334, 8407468,66,0 ],
  [ 1000,0,75,2,2, 181196,119068,12943,4438, 1071466,927513,13393,1799, 4343994,2579349,38786,15756, 7571442,4620625,67602,26347, 11015364,6995895,98351,35888, 8564949,67,0 ],
  [ 1000,25,1,2,2, 19892,78623,19892,-58731, 31065,55629,31065,-24564, 81176,69862,81176,11314, 94767,57105,94767,37662, 121204,64961,121204,56243, -3596420,-41,26 ],
  [ 1000,25,2,2,2, 36514,81438,18257,-22462, 60880,76141,30440,-7630, 148085,82257,74043,32914, 189534,75682,94767,56926, 242409,189033,121204,26688, -3275080,-38,25 ],
  [ 1000,25,3,2,2, 53137,84254,17712,-10372, 90696,82768,30232,2643, 214994,109317,71665,35226, 284301,189594,94767,31569, 363613,326845,121204,12256, -3094008,-37,24 ],
  [ 1000,25,5,2,2, 78513,101891,17130,-5101, 143302,94737,28660,9713, 339511,315587,67902,4785, 469713,349798,93943,23983, 600260,406778,120052,38696, -2973552,-36,23 ],
  [ 1000,25,10,2,2, 130640,110732,15997,2438, 294307,337726,24526,-3618, 731604,506808,60967,18733, 1096226,719591,91352,31386, 1401003,942129,116750,38240, -2059406,-28,17 ],
  [ 1000,25,15,2,2, 171512,117671,15245,4786, 474129,411840,21551,2831, 1246292,842020,56650,18376, 1952930,1243606,88770,32242, 2521611,1670423,114619,38690, -405403,-5,0 ],
  [ 1000,25,25,2,2, 200610,135955,14590,4702, 829846,742545,17379,1828, 2568296,1575516,49390,19092, 4263213,2650360,81985,31016, 5782767,3813803,111207,37865, 3193663,41,0 ],
  [ 1000,25,50,2,2, 203066,136374,14505,4764, 1179465,1148346,14867,392, 4712002,2797584,42836,17404, 8143126,4982652,74028,28732, 11809441,7532319,107359,38883, 9061001,68,0 ],
  [ 1000,25,75,2,2, 203066,136374,14505,4764, 1185611,1150079,14820,444, 4778404,2886221,42664,16894, 8265612,5100731,73800,28258, 12010237,7700148,107234,38483, 9054012,68,0 ],
  [ 1000,50,1,2,2, 24720,79499,24720,-54779, 34987,56239,34987,-21252, 101014,73767,101014,27247, 102617,58685,102617,43931, 131346,67063,131346,64283, -3518250,-40,26 ],
  [ 1000,50,2,2,2, 42903,82598,21452,-19847, 67476,77358,33738,-4941, 173493,87235,86747,43129, 205233,78833,102617,63200, 262692,193230,131346,34731, -3159826,-37,24 ],
  [ 1000,50,3,2,2, 61086,85697,20362,-8204, 99965,84593,33322,5124, 245973,192368,81991,17868, 307850,194314,102617,37845, 394038,348807,131346,15077, -3129343,-37,24 ],
  [ 1000,50,5,2,2, 88839,103765,19383,-3257, 157341,97688,31468,11930, 380942,338159,76188,8557, 508588,357617,101718,30194, 650467,417188,130093,46656, -2786537,-35,22 ],
  [ 1000,50,10,2,2, 145825,113488,17856,3960, 322319,344202,26860,-1824, 806376,535748,67198,22552, 1186789,752595,98899,36183, 1518058,966216,126505,45987, -1684173,-24,13 ],
  [ 1000,50,15,2,2, 190485,121115,16932,6166, 519100,422815,23595,4377, 1365328,879375,62060,22089, 2114333,1290534,96106,37445, 2732026,1828650,124183,41063, 159069,8,0 ],
  [ 1000,50,25,2,2, 222257,139885,16164,5991, 909225,850905,19041,1221, 2803485,1726132,53913,20718, 4617532,2858414,88799,33829, 6263413,4042283,120450,42714, 3763734,45,0 ],
  [ 1000,50,50,2,2, 224937,140344,16067,6042, 1293015,1192690,16299,1265, 5140332,3104828,46730,18505, 8826778,5445604,80243,30738, 12787736,8217948,116252,41544, 10163749,72,0 ],
  [ 1000,50,75,2,2, 224937,140344,16067,6042, 1299756,1194595,16247,1315, 5212814,3117593,46543,18707, 8959782,5485403,79998,31021, 13005110,8275989,116117,42224, 10567101,73,0 ],
  [ 1000,100,1,2,2, 34378,81252,34378,-46874, 42831,57459,42831,-14628, 140689,96092,140689,44597, 118315,61897,118315,56419, 151629,71318,151629,80311, -3395408,-39,25 ],
  [ 1000,100,2,2,2, 55681,84917,27840,-14618, 80666,79793,40333,436, 224310,111706,112155,56302, 236631,100160,118315,68235, 303258,201673,151629,50793, -2984924,-36,23 ],
  [ 1000,100,3,2,2, 76984,101919,25661,-8312, 118501,88092,39500,10136, 307931,218986,102644,29648, 354946,312414,118315,14177, 454888,361588,151629,31100, -3072939,-37,24 ],
  [ 1000,100,5,2,2, 109491,107513,23889,431, 185417,117477,37083,13588, 463803,368640,92761,19033, 586336,388182,117267,39631, 750879,537359,150176,42704, -2515603,-32,20 ],
  [ 1000,100,10,2,2, 176194,119000,21575,7003, 378342,357153,31528,1766, 955922,579113,79660,31401, 1367916,883885,113993,40336, 1752167,1242553,146014,42468, -1159342,-18,-40 ],
  [ 1000,100,15,2,2, 228431,141339,20305,7742, 609042,532350,27684,3486, 1603400,953934,72882,29521, 2437139,1588284,110779,38584, 3152855,2143172,143312,45895, 656702,16,0 ],
  [ 1000,100,25,2,2, 265552,219443,19313,3353, 1067983,906041,22366,3391, 3273864,2042050,62959,23689, 5326170,3342104,102426,38155, 7224706,4741595,138937,47752, 4814143,51,0 ],
  [ 1000,100,50,2,2, 268677,219983,19191,3478, 1520113,1355229,19161,2078, 5996993,3598751,54518,21802, 10194081,6305626,92673,35350, 14744326,9491237,134039,47755, 12286239,78,0 ],
  [ 1000,100,75,2,2, 268677,219983,19191,3478, 1528046,1357478,19101,2132, 6081633,3628288,54300,21905, 10348122,6349531,92394,35702, 14994857,9655385,133883,47674, 12654555,79,0 ],
  [ 1000,200,1,2,2, 53692,84758,53692,-31065, 58519,59899,58519,-1380, 220039,126227,220039,93812, 149713,68269,149713,81444, 192196,79777,192196,112419, -3116225,-37,24 ],
  [ 1000,200,2,2,2, 81236,102892,40618,-10828, 107047,84513,53523,11267, 325943,223134,162971,51405, 299426,193269,149713,53079, 384391,347420,192196,18486, -2998931,-36,23 ],
  [ 1000,200,3,2,2, 108779,107690,36260,363, 155575,95392,51858,20061, 431846,363755,143949,22697, 449139,346821,149713,34106, 576587,402971,192196,57872, -2775751,-35,22 ],
  [ 1000,200,5,2,2, 150795,115010,32901,7808, 241570,203134,48314,7687, 629526,415386,125905,42828, 741832,514592,148366,45448, 951704,610190,190341,68303, -2063799,-28,17 ],
  [ 1000,200,10,2,2, 236931,143361,29012,11458, 490388,396792,40866,7800, 1255012,863258,104584,32646, 1730169,1190293,144181,44990, 2220385,1470245,185032,62512, -494814,-7,0 ],
  [ 1000,200,15,2,2, 304323,327680,27051,-2076, 788927,692984,35860,4361, 2079545,1270237,94525,36787, 3082750,1966713,140125,50729, 3994514,2607887,181569,63028, 1290672,25,0 ],
  [ 1000,200,25,2,2, 352142,336026,25610,1172, 1385498,1206896,29016,3740, 4214622,2580700,81050,31422, 6743446,4215250,129682,48619, 9147292,6011959,175909,60295, 6929581,60,0 ],
  [ 1000,200,50,2,2, 356158,350065,25440,435, 1974310,1723042,24886,3167, 7710314,4720028,70094,27184, 12928688,8027921,117534,44552, 18657506,12039514,169614,60164, 16202017,87,0 ],
  [ 1000,200,75,2,2, 356158,350065,25440,435, 1984625,1725978,24808,3233, 7819271,4754078,69815,27368, 13124803,8188398,117186,44075, 18974349,12216873,169414,60335, 16549005,88,0 ],
  [ 1500,0,1,2,2, 23613,79522,23613,-55909, 39658,57988,39658,-18330, 81371,70174,81371,11198, 113868,61297,113868,52571, 146195,70473,146195,75722, -3529514,-40,26 ],
  [ 1500,0,2,2,2, 47226,83831,23613,-18302, 79316,80862,39658,-773, 162743,85712,81371,38515, 227736,99055,113868,64341, 292390,200065,146195,46162, -3122282,-37,24 ],
  [ 1500,0,3,2,2, 70839,101476,23613,-10212, 118974,89701,39658,9758, 244114,192916,81371,17066, 341604,310803,113868,10267, 438585,359218,146195,26456, -3213550,-38,24 ],
  [ 1500,0,5,2,2, 106889,108055,23321,-254, 189845,194157,37969,-862, 397052,342722,79410,10866, 564187,385607,112837,35716, 724088,533578,144818,38102, -2896002,-35,23 ],
  [ 1500,0,10,2,2, 180966,121581,22159,7272, 398036,378801,33170,1603, 886254,555007,73855,27604, 1316152,878311,109679,36487, 1690571,1234338,140881,38019, -1367164,-21,-53 ],
  [ 1500,0,15,2,2, 239064,217233,21250,1941, 651516,561525,29614,4090, 1537212,934117,69873,27413, 2348218,1579540,106737,34940, 3042141,2045233,138279,45314, 197489,9,0 ],
  [ 1500,0,25,2,2, 280445,224800,20396,4047, 1168968,1146969,24481,461, 3253069,2069183,62559,22767, 5167133,3332398,99368,35283, 6963091,4610173,133906,45248, 3986801,46,0 ],
  [ 1500,0,50,2,2, 283942,225441,20282,4179, 1687012,1558462,21265,1620, 6121795,3794917,55653,21153, 9997289,6343480,90884,33216, 14201231,9233242,129102,45164, 11497543,76,0 ],
  [ 1500,0,75,2,2, 283942,225441,20282,4179, 1696137,1574998,21202,1514, 6212414,3917691,55468,20489, 10151928,6403790,90642,33466, 14442702,9510038,128953,44042, 11513205,76,0 ],
  [ 1500,25,1,2,2, 28442,80399,28442,-51957, 43580,58598,43580,-15018, 101209,74079,101209,27130, 121717,62878,121717,58839, 156337,72575,156337,83761, -3451343,-40,26 ],
  [ 1500,25,2,2,2, 53615,84990,26807,-15688, 85911,82079,42956,1916, 188151,90690,94075,48730, 243435,182513,121717,30461, 312673,204262,156337,54206, -3125553,-37,24 ],
  [ 1500,25,3,2,2, 78788,102919,26263,-8044, 128243,91526,42748,12239, 275093,198967,91698,25375, 365152,330500,121717,11551, 469010,365509,156337,34500, -3083318,-37,24 ],
  [ 1500,25,5,2,2, 117215,109929,25574,1590, 203884,197109,40777,1355, 438483,350780,87697,17541, 603061,393376,120612,41937, 774295,543938,154859,46071, -2675487,-34,21 ],
  [ 1500,25,10,2,2, 196150,137674,24018,7160, 426047,385127,35504,3410, 961027,689998,80086,22586, 1406715,911965,117226,41229, 1807626,1274746,150635,44407, -1303176,-20,-47 ],
  [ 1500,25,15,2,2, 258037,220677,22937,3321, 696487,572500,31659,5636, 1656249,1154521,75284,22806, 2509621,1626967,114074,40121, 3252555,2203960,147843,47663, 340085,12,0 ],
  [ 1500,25,25,2,2, 302093,329596,21970,-2000, 1248347,1179979,26143,1432, 3488259,2128284,67082,26153, 5521452,3528726,106182,38322, 7443737,4855775,143149,49769, 4595250,50,0 ],
  [ 1500,25,50,2,2, 305812,330277,21844,-1747, 1800562,1689041,22696,1406, 6550125,4102011,59547,22256, 10680940,6742601,97099,35803, 15179526,9920321,137996,47811, 12005674,77,0 ],
  [ 1500,25,75,2,2, 305812,330277,21844,-1747, 1810282,1691864,22629,1480, 6646824,4120013,59347,22561, 10846098,6978230,96840,34535, 15437575,10086879,137835,47774, 12169121,78,0 ],
  [ 1500,50,1,2,2, 33270,81275,33270,-48005, 47502,59208,47502,-11706, 121046,77984,121046,43063, 129567,64459,129567,65108, 166478,74677,166478,91801, -3373172,-39,25 ],
  [ 1500,50,2,2,2, 60004,86150,30002,-13073, 92507,83297,46253,4605, 213559,110183,106780,51688, 259134,185713,129567,36710, 332956,321599,166478,5679, -3124432,-37,24 ],
  [ 1500,50,3,2,2, 86737,104361,28912,-5875, 137511,93351,45837,14720, 306072,205018,102024,33685, 388700,335220,129567,17827, 499434,387470,166478,37321, -2942156,-36,23 ],
  [ 1500,50,5,2,2, 127541,111804,27827,3434, 217922,200061,43584,3572, 479914,373353,95983,21312, 641935,416172,128387,45153, 824501,570019,164900,50896, -2521749,-32,20 ],
  [ 1500,50,10,2,2, 211334,140430,25878,8682, 454059,391602,37838,5205, 1035800,718938,86317,26405, 1497278,944969,124773,46026, 1924680,1298833,160390,52154, -927943,-15,-26 ],
  [ 1500,50,15,2,2, 277010,224120,24623,4701, 741458,687825,33703,2438, 1775285,1190376,80695,26587, 2671024,1754203,121410,41674, 3462970,2262235,157408,54579, 530319,15,0 ],
  [ 1500,50,25,2,2, 323740,346862,23545,-1682, 1327726,1200454,27806,2665, 3723448,2385100,71605,25737, 5875771,3737431,112996,41122, 7924384,5198045,152392,52430, 5061983,52,0 ],
  [ 1500,50,50,2,2, 327682,347583,23406,-1421, 1914111,1733236,24127,2280, 6978456,4409255,63441,23356, 11364592,7205703,103314,37808, 16157821,10508680,146889,51356, 13123189,80,0 ],
  [ 1500,50,75,2,2, 327682,347583,23406,-1421, 1924427,1736231,24055,2352, 7081234,4428386,63225,23686, 11540268,7378028,103038,37163, 16432449,10791632,146718,50364, 13337110,81,0 ],
  [ 1500,100,1,2,2, 42928,83028,42928,-40100, 55346,60428,55346,-5082, 160722,100309,160722,60413, 145266,67670,145266,77596, 186761,78932,186761,107829, -3250331,-38,25 ],
  [ 1500,100,2,2,2, 72781,101806,36391,-14512, 105697,85582,52848,10058, 264375,197139,132188,33618, 290531,192164,145266,49184, 373523,345813,186761,13855, -3136290,-37,24 ],
  [ 1500,100,3,2,2, 102634,107247,34211,-1538, 156048,97000,52016,19682, 368029,337685,122676,10115, 435797,345210,145266,30196, 560284,400602,186761,53227, -2916362,-36,23 ],
  [ 1500,100,5,2,2, 148193,115552,32333,7122, 245998,205964,49200,8007, 562775,403984,112555,31758, 719683,512067,143937,41523, 924913,590788,184983,66825, -2232744,-30,18 ],
  [ 1500,100,10,2,2, 241703,217642,29596,2946, 510082,418439,42507,7637, 1185345,839153,98779,28849, 1678405,1184719,139867,41140, 2158789,1462030,179899,58063, -2232744,-30,18 ],
  [ 1500,100,15,2,2, 314956,331875,27996,-1504, 831400,722160,37791,4965, 2013357,1264935,91516,34019, 2993830,1958019,136083,47082, 3883799,2593778,176536,58637, 1032208,22,0 ],
  [ 1500,100,25,2,2, 367035,354720,26693,896, 1486483,1343324,31131,2998, 4193827,2594818,80651,30750, 6584409,4220621,126623,45457, 8885677,5881137,170878,57780, 6385563,58,0 ],
  [ 1500,100,50,2,2, 371423,355522,26530,1136, 2141209,2000124,26990,1778, 7835116,4824679,71228,27368, 12731896,8065725,115745,42420, 18114412,11781469,164676,57572, 15389539,86,0 ],
  [ 1500,100,75,2,2, 371423,355522,26530,1136, 2152717,2003463,26909,1866, 7950053,4937581,70983,26897, 12928608,8242157,115434,41843, 18422195,12071076,164484,56706, 15462835,86,0 ],
  [ 1500,200,1,2,2, 62243,86534,62243,-24291, 71034,76754,71034,-5719, 240072,130293,240072,109779, 176663,74042,176663,102621, 227328,103012,227328,124316, -3025905,-36,23 ],
  [ 1500,200,2,2,2, 98336,106444,49168,-4054, 132078,90452,66039,20813, 366008,352132,183004,6938, 353327,313825,176663,19751, 454656,363199,227328,45728, -3147729,-37,24 ],
  [ 1500,200,3,2,2, 134429,113018,44810,7137, 193121,118185,64374,24979, 491945,390770,163982,33725, 529990,379167,176663,50274, 681983,441485,227328,80166, -2450880,-32,19 ],
  [ 1500,200,5,2,2, 189497,123049,41345,14498, 302151,336006,60430,-6771, 728498,449080,145700,55884, 875180,558170,175036,63402, 1125739,776160,225148,69916, -1879472,-26,15 ],
  [ 1500,200,10,2,2, 302441,329533,37034,-3317, 622129,530577,51844,7629, 1484436,940248,123703,45349, 2040658,1288233,170055,62702, 2627007,1803662,218917,68612, -282907,-1,0 ],
  [ 1500,200,15,2,2, 390848,358986,34742,2832, 1011285,867530,45967,6534, 2489502,1597104,113159,40564, 3639441,2415455,165429,55636, 4725458,3141424,214794,72002, 2156640,33,0 ],
  [ 1500,200,25,2,2, 453625,370437,32991,6050, 1803999,1645680,37780,3316, 5134585,3239519,98742,36444, 8001685,5092767,153879,55941, 10808263,7037410,207851,72516, 8749929,67,0 ],
  [ 1500,200,50,2,2, 458904,371401,32779,6250, 2595406,2355702,32715,3021, 9548438,5946105,86804,32748, 15466503,9814523,140605,51382, 22027592,14411927,200251,69233, 19715983,94,0 ],
  [ 1500,200,75,2,2, 458904,371401,32779,6250, 2609297,2359728,32616,3120, 9687691,5986520,86497,33046, 15705288,9970913,140226,51200, 22401688,14630964,200015,69381, 20205807,95,0 ],
  [ 2000,0,1,2,2, 32163,81298,32163,-49135, 52174,60957,52174,-8784, 101404,74390,101404,27014, 140818,67070,140818,73748, 181327,78087,181327,103240, -3384437,-39,25 ],
  [ 2000,0,2,2,2, 64326,100719,32163,-18196, 104347,86651,52174,8848, 202808,108660,101404,47074, 281636,191109,140818,45264, 362654,344256,181327,9199, -3130650,-37,24 ],
  [ 2000,0,3,2,2, 96490,106804,32163,-3438, 156521,98609,52174,19304, 304212,205566,101404,32882, 422455,343099,140818,26452, 543981,397732,181327,48750, -2811596,-35,22 ],
  [ 2000,0,5,2,2, 145592,116094,31765,6436, 250426,208795,50085,8326, 496024,378066,99205,23591, 697534,509493,139507,37608, 898123,587007,179625,62223, -2379362,-31,19 ],
  [ 2000,0,10,2,2, 246475,220224,30181,3214, 529776,426201,44148,8631, 1115677,738197,92973,31457, 1626641,1098837,135553,43984, 2097194,1370085,174766,60592, -806101,-12,0 ],
  [ 2000,0,15,2,2, 325589,349405,28941,-2117, 873874,825036,39722,2220, 1947169,1245118,88508,31911, 2904909,1949425,132041,43431, 3773085,2579770,171504,54242, 518770,15,0 ],
  [ 2000,0,25,2,2, 381929,360078,27777,1589, 1587469,1510403,33245,1614, 4173032,2728001,80251,27789, 6425373,4211415,123565,42576, 8624062,5736576,165847,55529, 5556348,54,0 ],
  [ 2000,0,50,2,2, 381929,360078,27281,1561, 1587469,1510403,20010,971, 6853310,4420146,62303,22120, 6605619,4373246,60051,20294, 8864815,5949126,80589,26506, 7861427,64,0 ],
  [ 2000,0,75,2,2, 386688,360980,27621,1836, 2320809,2102748,29010,2726, 8080835,5166148,72150,26024, 12732414,8281489,113682,39740, 17870041,11813238,159554,54079, 14937423,85,0 ],
  [ 2000,25,1,2,2, 36992,82175,36992,-45183, 56096,75452,56096,-19357, 121242,78145,121242,43097, 148668,68651,148668,80017, 191469,80139,191469,111329, -3349849,-39,25 ],
  [ 2000,25,2,2,2, 70715,101879,35358,-15582, 110942,87868,55471,11537, 228216,113638,114108,57289, 297335,194259,148668,51538, 382937,348452,191469,17243, -3015397,-36,23 ],
  [ 2000,25,3,2,2, 104438,108247,34813,-1269, 165789,114319,55263,17157, 335191,332032,111730,1053, 446003,348369,148668,32544, 574406,404523,191469,56628, -2981721,-36,23 ],
  [ 2000,25,5,2,2, 155917,117968,34018,8280, 264465,316096,52893,-10326, 537454,384624,107491,30566, 736409,517262,147282,43829, 948329,612538,189666,67158, -2496642,-32,20 ],
  [ 2000,25,10,2,2, 261660,222980,32040,4736, 557788,506527,46482,4272, 1190450,844137,99204,28859, 1717204,1197173,143100,43336, 2214248,1493623,184521,60052, -1008476,-16,-27 ],
  [ 2000,25,15,2,2, 344562,352849,30628,-737, 918845,836011,41766,3765, 2066205,1282473,93918,35624, 3066312,2076660,139378,44984, 3983500,2638545,181068,61134, 1035625,22,0 ],
  [ 2000,25,25,2,2, 403576,364007,29351,2878, 1666848,1531028,34908,2844, 4408222,2801767,84773,30893, 6779692,4434445,130379,45101, 9104708,6078196,175091,58202, 6477547,59,0 ],
  [ 2000,25,50,2,2, 408558,364950,29183,3115, 2421658,2321552,30525,1262, 8388249,5328089,76257,27820, 13218755,8501200,120171,42887, 18549612,12292883,168633,56879, 15506497,86,0 ],
  [ 2000,25,75,2,2, 408558,364950,29183,3115, 2434954,2325465,30437,1369, 8515244,5366971,76029,28110, 13426584,8665661,119880,42508, 18864914,12501170,168437,56819, 15878358,87,0 ],
  [ 2000,50,1,2,2, 41821,83051,41821,-41230, 60018,76062,60018,-16045, 141079,82050,141079,59029, 156517,70232,156517,86285, 201610,82242,201610,119369, -3271679,-38,25 ],
  [ 2000,50,2,2,2, 77104,103039,38552,-12967, 117537,89086,58769,14226, 253625,195617,126812,29004, 313034,305869,156517,3582, 403221,352648,201610,25286, -3236716,-38,24 ],
  [ 2000,50,3,2,2, 112387,109689,37462,899, 175057,116144,58352,19638, 366170,338083,122057,9362, 469551,353090,156517,38820, 604831,410813,201610,64673, -2829386,-35,22 ],
  [ 2000,50,5,2,2, 166243,119842,36271,10124, 278503,332933,55701,-10886, 578885,407047,115777,34368, 775283,540057,155057,47045, 998535,735989,199707,52509, -2455946,-32,20 ],
  [ 2000,50,10,2,2, 276844,225736,33899,6258, 585799,526888,48817,4909, 1265223,872927,105435,32691, 1807768,1230177,150647,48133, 2331303,1646422,194275,57073, -768632,-12,0 ],
  [ 2000,50,15,2,2, 363535,356293,32314,644, 963816,860871,43810,4679, 2185242,1396677,99329,35844, 3227715,2123588,146714,50188, 4193914,2796722,190632,63509, 1380016,26,0 ],
  [ 2000,50,25,2,2, 425224,367936,30925,4166, 1746226,1565538,36570,3784, 4643411,2952384,89296,32520, 7134011,4614347,137193,48455, 9585355,6435438,184334,60575, 7231144,62,0 ],
  [ 2000,50,50,2,2, 430428,382256,30745,3441, 2535207,2365746,31956,2136, 8816579,5620818,80151,29052, 13902407,8992405,126386,44636, 19527907,12994283,177526,59397, 16535330,88,0 ],
  [ 2000,50,75,2,2, 430428,382256,30745,3441, 2549099,2369831,31864,2241, 8949654,5660828,79908,29365, 14120754,9145717,126078,44420, 19859787,13092782,177320,60420, 17041955,89,0 ],
  [ 2000,100,1,2,2, 51478,84804,51478,-33326, 67862,77283,67862,-9421, 180754,104375,180754,76379, 172216,73443,172216,98773, 221894,102167,221894,119726, -3160011,-37,24 ],
  [ 2000,100,2,2,2, 89881,105358,44941,-7738, 130728,91520,65364,19604, 304441,220088,152221,42177, 344432,312220,172216,16106, 443787,361091,221894,41348, -3039710,-37,23 ],
  [ 2000,100,3,2,2, 128285,112575,42762,5237, 193594,119793,64531,24600, 428128,364700,142709,21142, 516648,377556,172216,46364, 665681,439115,221894,75522, -2591491,-33,20 ],
  [ 2000,100,5,2,2, 186895,123591,40777,13812, 306579,338836,61316,-6451, 661747,423162,132349,47717, 853031,555596,170606,59487, 1098948,772379,219790,65314, -2026090,-28,16 ],
  [ 2000,100,10,2,2, 307213,332115,37618,-3049, 641823,538340,53485,8624, 1414768,916292,117897,41540, 1988894,1282659,165741,58853, 2565412,1711717,213784,71141, -387411,-4,0 ],
  [ 2000,100,15,2,2, 401481,363180,35687,3404, 1053758,896705,47898,7139, 2423314,1577286,110151,38456, 3550521,2406711,161387,51991, 4614744,3127265,209761,67613, 1931676,31,0 ],
  [ 2000,100,25,2,2, 468518,389131,34074,5774, 1904984,1708258,39895,4120, 5113790,3268152,98342,35493, 7842649,5098187,150820,52778, 10546648,7006089,202820,68088, 8335285,66,0 ],
  [ 2000,100,50,2,2, 474169,390195,33869,5998, 2762306,2528285,34819,2950, 9673240,6129256,87939,32218, 15269710,9852477,138816,49248, 21484497,14267622,195314,65608, 18918303,93,0 ],
  [ 2000,100,75,2,2, 474169,390195,33869,5998, 2777389,2532713,34717,3058, 9818473,6171523,87665,32562, 15509094,10009846,138474,49100, 21849533,14456507,195085,66009, 19434567,94,0 ],
  [ 2000,200,1,2,2, 70793,101646,70793,-30854, 83550,79573,83550,3977, 260105,134510,260105,125595, 203614,79965,203614,123648, 262460,194557,262460,67903, -2995255,-36,23 ],
  [ 2000,200,2,2,2, 115436,109996,57718,2720, 157109,96390,78554,30359, 406074,360565,203037,22754, 407227,340348,203614,33439, 524920,394098,262460,65411, -2890851,-36,22 ],
  [ 2000,200,3,2,2, 160080,118346,53360,13911, 230668,200943,76889,9908, 552043,403420,184014,49541, 610841,396487,203614,71451, 787380,563779,262460,74534, -2320337,-31,18 ],
  [ 2000,200,5,2,2, 228199,216124,49789,2635, 362732,350493,72546,2448, 827470,561424,165494,53209, 1008528,710358,201706,59634, 1299773,913520,259955,77251, -1989291,-27,16 ],
  [ 2000,200,10,2,2, 367950,356476,45055,1405, 753869,682328,62822,5962, 1713859,1184422,142822,44120, 2351148,1589017,195929,63511, 3033630,2051949,252802,81807, -96636,3,0 ],
  [ 2000,200,15,2,2, 477373,390292,42433,7741, 1233643,1132540,56075,4596, 2899459,1908105,131794,45062, 4196132,2798816,190733,63514, 5456402,3575010,248018,85518, 2940399,39,0 ],
  [ 2000,200,25,2,2, 555108,404848,40371,10928, 2222499,1996729,46544,4728, 6054548,3913002,116434,41184, 9259925,5970334,178075,63261, 12469234,8275502,239793,80649, 10662602,73,0 ],
  [ 2000,200,50,2,2, 561650,406074,40118,11113, 3216502,2883862,40544,4193, 11386561,7173683,103514,38299, 18004318,11573122,163676,58465, 25397678,16814299,230888,78031, 23522530,101,0 ],
  [ 2000,200,75,2,2, 561650,406074,40118,11113, 3233968,3007213,40425,2834, 11556111,7295813,103180,38038, 18285774,11738602,163266,58457, 25829026,17031516,230616,78549, 23619663,101,0 ],
  [ 2500,0,1,2,2, 40713,83074,40713,-42361, 64689,77812,64689,-13123, 121437,78457,121437,42980, 167768,72844,167768,94925, 216459,101322,216459,115137, -3294116,-38,25 ],
  [ 2500,0,2,2,2, 81427,104271,40713,-11422, 129378,92589,64689,18394, 242874,194094,121437,24390, 335537,311115,167768,12211, 432918,359484,216459,36717, -3177068,-38,24 ],
  [ 2500,0,3,2,2, 122140,112132,40713,3336, 194067,195252,64689,-395, 364311,338630,121437,8560, 503305,375945,167768,42453, 649378,436746,216459,70877, -2965883,-36,23 ],
  [ 2500,0,5,2,2, 184294,137469,40210,10216, 311007,341517,62201,-6102, 594995,397245,118999,39550, 830882,553171,166176,55542, 1072157,768748,214431,60682, -2227401,-30,18 ],
  [ 2500,0,10,2,2, 311985,348033,38202,-4414, 661516,559837,55126,8473, 1345101,892187,112092,37743, 1937130,1277235,161428,54991, 2503816,1703652,208651,66680, -649926,-9,0 ],
  [ 2500,0,15,2,2, 412114,367374,36632,3977, 1096232,911996,49829,8374, 2357126,1572134,107142,35681, 3461600,2209249,157345,56925, 4504029,3097536,204729,63932, 2006567,32,0 ],
  [ 2500,0,25,2,2, 483412,394489,35157,6467, 2005969,1861452,42010,3027, 5092995,3295434,97942,34568, 7683612,5008174,147762,51451, 10285033,6974168,197789,63670, 7832923,64,0 ],
  [ 2500,0,50,2,2, 489433,395653,34960,6699, 2929205,2731518,36923,2492, 9798042,6354472,89073,31305, 15072918,9890832,137027,47110, 20941403,14010126,190376,63012, 18060728,91,0 ],
  [ 2500,0,75,2,2, 489433,395653,34960,6699, 2945481,2736349,36819,2614, 9949255,6475591,88833,31015, 15312899,10049178,136722,46998, 21297379,14311260,190155,62376, 18325404,92,0 ],
  [ 2500,25,1,2,2, 45542,83951,45542,-38408, 68611,78422,68611,-9811, 141275,82362,141275,58913, 175618,74424,175618,101194, 226601,103425,226601,123176, -3215946,-38,24 ],
  [ 2500,25,2,2,2, 87816,105431,43908,-8808, 135973,93807,67987,21083, 268282,199072,134141,34605, 351236,329242,175618,10997, 453202,363681,226601,44761, -3083918,-37,24 ],
  [ 2500,25,3,2,2, 130089,113575,43363,5505, 203335,197077,67778,2086, 395290,344682,131763,16869, 526854,380665,175618,48729, 679803,443036,226601,78922, -2813547,-35,22 ],
  [ 2500,25,5,2,2, 194620,139343,42462,12060, 325046,344469,65009,-3885, 636426,419818,127285,43322, 869756,560990,173951,61753, 1122363,779158,224473,68641, -2040387,-28,16 ],
  [ 2500,25,10,2,2, 327169,350789,40062,-2892, 689528,566313,57461,10268, 1419873,921127,118323,41562, 2027693,1310239,168974,59788, 2620870,1827190,218406,66140, -345601,-3,0 ],
  [ 2500,25,15,2,2, 431087,384155,38319,4172, 1141203,936706,51873,9295, 2476162,1609339,112553,39401, 3623003,2445095,164682,53541, 4714444,3172083,214293,70107, 2253899,34,0 ],
  [ 2500,25,25,2,2, 505059,398418,36732,7756, 2085348,1895962,43672,3966, 5328185,3537586,102465,34435, 8037931,5311962,154576,52422, 10765679,7316189,207032,66336, 8203638,65,0 ],
  [ 2500,25,50,2,2, 511304,399622,36522,7977, 3042754,2849712,38354,2433, 10226372,6555667,92967,33370, 15756570,10368260,143242,48985, 21919698,14695255,199270,65677, 19152970,93,0 ],
  [ 2500,25,75,2,2, 511304,399622,36522,7977, 3059625,2868600,38245,2388, 10383664,6783813,92711,32142, 16007069,10448827,142920,49627, 22292252,14902722,199038,65978, 19351354,94,0 ],
  [ 2500,50,1,2,2, 50371,84827,50371,-34456, 72533,79032,72533,-6499, 161112,86267,161112,74845, 183467,76005,183467,107462, 236742,189307,236742,47435, -3197509,-38,24 ],
  [ 2500,50,2,2,2, 94204,106591,47102,-6193, 142568,95024,71284,23772, 293690,204050,146845,44820, 366935,332392,183467,17271, 473485,367877,236742,52804, -2968665,-36,23 ],
  [ 2500,50,3,2,2, 138038,115017,46013,7674, 212604,198902,70868,4567, 426268,350733,142089,25179, 550402,385386,183467,55005, 710227,533107,236742,59040, -2720946,-34,21 ],
  [ 2500,50,5,2,2, 204946,141218,44715,13904, 339084,347420,67817,-1667, 677857,427875,135571,49996, 908630,583735,181726,64979, 1172570,789518,234514,76610, -1841975,-26,15 ],
  [ 2500,50,10,2,2, 342354,353545,41921,-1370, 717540,572789,59795,12063, 1494646,935552,124554,46591, 2118257,1408524,176521,59144, 2737925,1866898,228160,72586, -44462,4,0 ],
  [ 2500,50,15,2,2, 450060,387599,40005,5552, 1186174,1125881,53917,2741, 2595199,1722193,117964,39682, 3784406,2506999,172018,58064, 4924859,3343449,223857,71882, 2049643,32,0 ],
  [ 2500,50,25,2,2, 526707,402347,38306,9044, 2164727,2004322,45335,3359, 5563374,3611202,106988,37542, 8392250,5491864,161389,55777, 11246326,7544669,216275,71186, 8991757,68,0 ],
  [ 2500,50,50,2,2, 533174,403592,38084,9256, 3156303,2894056,39785,3306, 10654703,6848396,96861,34603, 16440221,10750854,149457,51722, 22897993,15283415,208164,69223, 20477202,96,0 ],
  [ 2500,50,75,2,2, 533174,403592,38084,9256, 3173770,2913116,39672,3258, 10818074,6909136,96590,34901, 16701240,10913306,149118,51678, 23287125,15591154,207921,68714, 20910664,97,0 ],
  [ 2500,100,1,2,2, 60028,86580,60028,-26552, 80377,80252,80377,125, 200787,108592,200787,92196, 199166,79216,199166,119950, 257026,193562,257026,63464, -3074668,-37,24 ],
  [ 2500,100,2,2,2, 106982,108910,53491,-964, 155759,97459,77879,29150, 344507,334571,172253,4968, 398332,339243,199166,29545, 514051,392491,257026,60780, -3028210,-37,23 ],
  [ 2500,100,3,2,2, 153935,117903,51312,12011, 231140,202552,77047,9530, 488226,377350,162742,36959, 597498,394876,199166,67541, 771077,561410,257026,69889, -2460948,-32,20 ],
  [ 2500,100,5,2,2, 225598,216666,49221,1949, 367160,353324,73432,2767, 760718,535506,152144,45042, 986379,707784,197276,55719, 1272982,909739,254596,72649, -2135909,-29,17 ],
  [ 2500,100,10,2,2, 372722,359057,45639,1673, 773563,703975,64464,5799, 1644191,1174832,137016,39113, 2299384,1583493,191615,59658, 2972034,2043784,247669,77354, -337957,-3,0 ],
  [ 2500,100,15,2,2, 488006,394486,43378,8313, 1276117,1161716,58005,5200, 2833271,1902803,128785,42294, 4107211,2681662,186691,64798, 5345688,3560901,242986,81127, 2842009,38,0 ],
  [ 2500,100,25,2,2, 570002,423542,41455,10652, 2323485,2059307,48659,5533, 6033753,3927120,116034,40512, 9100888,5895396,175017,61644, 12207619,8244132,234762,76221, 10399982,72,0 ],
  [ 2500,100,50,2,2, 576914,424868,41208,10860, 3383402,3160795,42648,2806, 11511363,7355334,104649,37782, 17807525,11611077,161887,56331, 24854583,16556403,225951,75438, 22478861,99,0 ],
  [ 2500,100,75,2,2, 576914,424868,41208,10860, 3402060,3180199,42526,2773, 11686893,7524380,104347,37165, 18089580,11778085,161514,56353, 25276872,16871248,225686,75050, 22736318,100,0 ],
  [ 2500,200,1,2,2, 79343,103422,79343,-24079, 96065,82542,96065,13523, 280138,215727,280138,64411, 230564,100715,230564,129849, 297592,202171,297592,95421, -3048779,-37,23 ],
  [ 2500,200,2,2,2, 132537,113548,66268,9494, 182140,116214,91070,32963, 446139,368848,223070,38646, 461128,351895,230564,54616, 595184,409277,297592,92954, -2644280,-34,21 ],
  [ 2500,200,3,2,2, 185731,123674,61910,20686, 268214,314201,89405,-15329, 612141,429085,204047,61019, 691691,428833,230564,87619, 892776,586172,297592,102201, -2267330,-30,18 ],
  [ 2500,200,5,2,2, 266902,224163,58233,9325, 423313,379016,84663,8860, 926441,596617,185288,65965, 1141875,753937,228375,77588, 1473807,982570,294761,98247, -1408884,-21,-55 ],
  [ 2500,200,10,2,2, 433460,370081,53077,7761, 885609,729879,73801,12978, 1943282,1261562,161940,56810, 2661637,1765765,221803,74656, 3440252,2286496,286688,96146, 1051228,22,0 ],
  [ 2500,200,15,2,2, 563898,408261,50124,13834, 1456001,1233386,66182,10119, 3309416,2114406,150428,54319, 4752823,3058091,216037,77033, 6187347,4220937,281243,89382, 4571685,49,0 ],
  [ 2500,200,25,2,2, 656591,439259,47752,15806, 2641000,2361663,55309,5850, 6974511,4465770,134125,48245, 10518164,6847350,202272,70593, 14130205,9512995,271735,88792, 12810569,79,0 ],
  [ 2500,200,50,2,2, 664396,440747,47457,15975, 3837599,3530258,48373,3874, 13224685,8476611,120224,43164, 20542132,13331722,186747,65549, 28767763,19103031,261525,87861, 26863006,106,0 ],
  [ 2500,200,75,2,2, 664396,440747,47457,15975, 3858640,3536464,48233,4027, 13424531,8529755,119862,43703, 20866260,13549420,186306,65329, 29256364,19446257,261218,87590, 27506843,107,0 ],
  [ 3000,0,1,2,2, 49264,84850,49264,-35587, 77204,80781,77204,-3576, 141470,82673,141470,58796, 194719,78617,194719,116102, 251591,192717,251591,58874, -3208773,-38,24 ],
  [ 3000,0,2,2,2, 98527,107823,49264,-4648, 154409,98528,77204,27940, 282939,202527,141470,40206, 389437,337638,194719,25900, 503183,390384,251591,56400, -2920190,-36,23 ],
  [ 3000,0,3,2,2, 147791,117460,49264,10110, 231613,204160,77204,9151, 424409,351280,141470,24376, 584156,393265,194719,63630, 754774,559040,251591,65245, -2601559,-33,21 ],
  [ 3000,0,5,2,2, 222996,217208,48654,1263, 371588,370039,74318,310, 693967,509439,138793,36906, 964230,705209,192846,51804, 1246191,905908,249238,68057, -2326111,-31,19 ],
  [ 3000,0,10,2,2, 377494,361638,46224,1942, 793257,711737,66105,6793, 1574524,1073876,131210,41721, 2247619,1577919,187302,55808, 2910438,2035619,242537,72902, -325698,-3,0 ],
  [ 3000,0,15,2,2, 498639,398680,44323,8885, 1318590,1190892,59936,5804, 2767083,1776935,125776,45007, 4018291,2672418,182650,61176, 5234974,3546243,237953,76760, 2862423,39,0 ],
  [ 3000,0,25,2,2, 584895,428900,42538,11345, 2424470,2300236,50774,2602, 6012958,3954253,115634,39590, 8941852,5885690,171959,58772, 11946004,8128381,229731,73416, 9561466,70,0 ],
  [ 3000,0,50,2,2, 592179,430326,42299,11561, 3550301,3273563,44752,3488, 11636166,7658900,105783,36157, 17610732,11664407,160098,54057, 24311489,16396777,221014,71952, 21636263,98,0 ],
  [ 3000,0,75,2,2, 592179,430326,42299,11561, 3570152,3279484,44627,3633, 11817675,7709383,105515,36681, 17893385,11831844,159762,54121, 24724717,16612861,220756,72427, 22291182,99,0 ],
  [ 3000,25,1,2,2, 54092,85727,54092,-31634, 81126,81391,81126,-265, 161307,86578,161307,74729, 202568,95174,202568,107394, 261733,194819,261733,66914, -3152706,-37,24 ],
  [ 3000,25,2,2,2, 104916,108983,52458,-2033, 161004,99745,80502,30629, 308348,313555,154174,-2604, 405136,341288,202568,31924, 523466,395080,261733,64193, -3050315,-37,23 ],
  [ 3000,25,3,2,2, 155740,118902,51913,12279, 240882,205985,80294,11632, 455388,371846,151796,27847, 607704,413012,202568,64898, 785199,565381,261733,73273, -2504827,-32,20 ],
  [ 3000,25,5,2,2, 233322,219082,50907,3107, 385627,372991,77125,2527, 735397,532011,147079,40677, 1003104,713028,200621,58015, 1296398,916318,259280,76016, -2139096,-29,17 ],
  [ 3000,25,10,2,2, 392679,364394,48083,3463, 821268,718213,68439,8588, 1649297,1179816,137441,39123, 2338183,1595947,194849,61853, 3027493,2075377,252291,79343, -116033,3,0 ],
  [ 3000,25,15,2,2, 517612,402124,46010,10266, 1363561,1201867,61980,7350, 2886119,1920340,131187,43899, 4179694,2828306,189986,61427, 5445388,3818060,247518,73969, 2940811,39,0 ],
  [ 3000,25,25,2,2, 606543,432829,44112,12634, 2503849,2334746,52437,3541, 6248147,4104869,120157,41217, 9296171,6189029,178773,59753, 12426650,8356861,238974,78265, 10224691,72,0 ],
  [ 3000,25,50,2,2, 614049,434295,43861,12840, 3663850,3496107,46183,2114, 12064496,7767079,109677,39067, 18294384,12046502,166313,56799, 25289784,16983937,229907,75508, 22821968,100,0 ],
  [ 3000,25,75,2,2, 614049,434295,43861,12840, 3684297,3502200,46054,2276, 12252084,8016256,109394,37820, 18587555,12216516,165960,56884, 25719590,17316963,229639,75023, 22975566,100,0 ],
  [ 3000,50,1,2,2, 58921,86603,58921,-27682, 85048,82001,85048,3047, 181145,104998,181145,76147, 210418,96805,210418,113613, 271875,196972,271875,74903, -3108036,-37,24 ],
  [ 3000,50,2,2,2, 111305,110142,55652,581, 167599,114848,83800,26376, 333756,332898,166878,429, 420835,344489,210418,38173, 543749,399277,271875,72236, -3012145,-36,23 ],
  [ 3000,50,3,2,2, 163688,120345,54563,14448, 250150,207810,83383,14113, 486367,377898,162122,36156, 631253,417732,210418,71174, 815624,571671,271875,81318, -2352491,-31,19 ],
  [ 3000,50,5,2,2, 243648,220956,53160,4951, 399665,375943,79933,4744, 776828,540069,155366,47352, 1041978,735774,208396,61241, 1346604,942349,269321,80851, -1951858,-27,16 ],
  [ 3000,50,10,2,2, 407863,367151,49942,4985, 849280,724689,70773,10383, 1724069,1194241,143672,44152, 2428746,1628901,202396,66654, 3144547,2198865,262046,78807, 221792,10,0 ],
  [ 3000,50,15,2,2, 536585,405568,47696,11646, 1408532,1226726,64024,8264, 3005155,2034545,136598,44119, 4341097,2875234,197323,66630, 5655803,3876786,257082,80864, 3356109,42,0 ],
  [ 3000,50,25,2,2, 628190,436758,45687,13922, 2583228,2369256,54099,4481, 6483337,4163971,124680,44603, 9650490,6368881,185586,63108, 12907297,8698382,248217,80941, 11199459,75,0 ],
  [ 3000,50,50,2,2, 635920,438265,45423,14118, 3777400,3540452,47614,2987, 12492826,8074324,113571,40168, 18978036,12509453,172528,58805, 26268079,17669566,238801,78168, 23924717,102,0 ],
  [ 3000,50,75,2,2, 635920,438265,45423,14118, 3798442,3546717,47481,3147, 12686494,8218578,113272,39892, 19281725,12615665,172158,59518, 26714463,17907976,238522,78629, 24524258,103,0 ],
  [ 3000,100,1,2,2, 68578,101693,68578,-33114, 92893,83071,92893,9821, 220820,112808,220820,108012, 226116,100116,226116,126001, 292158,201326,292158,90832, -3006387,-36,23 ],
  [ 3000,100,2,2,2, 124082,112462,62041,5810, 180790,117283,90395,31753, 384572,342854,192286,20859, 452233,350789,226116,50722, 584316,407669,292158,88323, -2781639,-35,22 ],
  [ 3000,100,3,2,2, 179586,123231,59862,18785, 268687,315810,89562,-15708, 548324,403015,182775,48436, 678349,427222,226116,83709, 876474,583802,292158,97557, -2407941,-31,19 ],
  [ 3000,100,5,2,2, 264300,224705,57665,8639, 427741,381846,85548,9179, 859690,570700,171938,57798, 1119726,751362,223945,73673, 1447017,963119,289403,96780, -1544329,-23,11 ],
  [ 3000,100,10,2,2, 438232,385999,53661,6396, 905303,825226,75442,6673, 1873615,1251971,156135,51804, 2609873,1760391,217489,70790, 3378656,2262810,281555,92987, 532605,15,0 ],
  [ 3000,100,15,2,2, 574531,425792,51069,13221, 1498475,1248526,68112,11361, 3243228,2109254,147419,51544, 4663902,3049547,211996,73380, 6076632,4093938,276211,90122, 4382744,48,0 ],
  [ 3000,100,25,2,2, 671485,529653,48835,10315, 2741986,2498091,57424,5108, 6953716,4494403,133725,47294, 10359128,6772463,199214,68974, 13868589,9382223,266704,86276, 12057591,77,0 ],
  [ 3000,100,50,2,2, 679660,531241,48547,10601, 4004498,3702840,50477,3802, 13349487,8582762,121359,43334, 20345340,13369676,184958,63415, 28224669,19026835,256588,83617, 25899279,105,0 ],
  [ 3000,100,75,2,2, 679660,531241,48547,10601, 4026732,3813799,50334,2662, 13555313,8833823,121030,42156, 20670066,13588903,184554,63225, 28704210,19271850,256288,84217, 25836120,105,0 ],
  [ 3000,200,1,2,2, 87893,105198,87893,-17305, 108581,85512,108581,23069, 300170,219943,300170,80227, 257514,186796,257514,70718, 332724,322876,332724,9848, -3102858,-37,24 ],
  [ 3000,200,2,2,2, 149637,117100,74819,16269, 207171,196003,103585,5584, 486205,391796,243103,47204, 515028,378467,257514,68280, 665449,440226,332724,112611, -2654684,-34,21 ],
  [ 3000,200,3,2,2, 211381,142338,70460,23014, 305760,336844,101920,-10361, 672240,441585,224080,76885, 772542,541587,257514,76985, 998173,737876,332724,86765, -2162808,-29,17 ],
  [ 3000,200,5,2,2, 305604,333068,66677,-5992, 483894,392153,96779,18348, 1025413,738011,205083,57480, 1275223,879822,255045,79080, 1647842,1234871,329568,82594, -1691662,-24,13 ],
  [ 3000,200,10,2,2, 498970,397024,61098,12483, 1017350,865014,84779,12695, 2172705,1415552,181059,63096, 2972126,1985792,247677,82195, 3846874,2618564,320573,102359, 1557424,27,0 ],
  [ 3000,200,15,2,2, 650423,439567,57815,18743, 1678359,1498396,76289,8180, 3719373,2439923,169062,58157, 5309514,3426526,241342,85590, 6918291,4754024,314468,98376, 5273140,53,0 ],
  [ 3000,200,25,2,2, 758075,545370,55133,15469, 3059501,2800447,64073,5425, 7894473,5139104,151817,52988, 11776404,7753069,226469,77372, 15791175,10538496,303676,101013, 14261883,83,0 ],
  [ 3000,200,50,2,2, 767141,547119,54796,15716, 4458695,4162768,56202,3730, 15062808,9702689,136935,48728, 23079947,15010014,209818,73363, 32137849,21573012,292162,96044, 30118910,111,0 ],
  [ 3000,200,75,2,2, 767141,547119,54796,15716, 4483311,4170064,56041,3916, 15292951,9762198,136544,49382, 23446746,15317109,209346,72586, 32683703,21831188,291819,96897, 30857969,112,0 ],
  [ 1000,0,1,3,2, 21690,78861,21690,-57170, 36425,56904,36425,-20478, 75187,68549,75187,6637, 101029,58298,101029,42731, 123746,65448,123746,58298, -3578868,-40,26 ],
  [ 1000,0,2,3,2, 43381,82507,21690,-19563, 72851,78694,36425,-2922, 150373,82464,75187,33955, 202058,78080,101029,61989, 247492,190016,123746,28738, -3198886,-38,24 ],
  [ 1000,0,3,3,2, 65071,86154,21690,-7028, 109276,86599,36425,7559, 225560,111043,75187,38172, 303087,193196,101029,36631, 371237,343994,123746,9081, -2970346,-36,23 ],
  [ 1000,0,5,3,2, 98189,105061,21423,-1499, 173609,114858,34722,11750, 366103,334595,73221,6302, 501729,355968,100346,29152, 613803,409399,122761,40881, -2810529,-35,22 ],
  [ 1000,0,10,3,2, 166257,116523,20358,6090, 358637,351665,29886,581, 811799,535603,67650,23016, 1177594,750013,98133,35632, 1439244,949533,119937,40809, -1562873,-23,12 ],
  [ 1000,0,15,3,2, 219656,138861,19525,7182, 579558,523409,26344,2552, 1400478,883838,63658,23484, 2106671,1287673,95758,37227, 2599887,1701233,118177,40848, 138463,8,0 ],
  [ 1000,0,25,3,2, 257705,145287,18742,8176, 1018816,890368,21336,2690, 2930067,1744942,56347,22791, 4635171,2857905,89138,34178, 6007713,3872215,115533,41067, 4321543,48,0 ],
  [ 1000,0,50,3,2, 260922,145832,18637,8221, 1452293,1259417,18306,2431, 5443534,3256449,49487,19883, 8925298,5470666,81139,31406, 12366877,7881911,112426,40772, 10976771,74,0 ],
  [ 1000,0,75,3,2, 260922,145832,18637,8221, 1459924,1275449,18249,2306, 5522052,3269968,49304,20108, 9061610,5510917,80907,31703, 12579938,8067651,112321,40288, 11260410,75,0 ],
  [ 1000,25,1,3,2, 27859,79980,27859,-52121, 41509,57705,41509,-16195, 97810,73001,97810,24810, 110153,60137,110153,50016, 135046,67792,135046,67253, -3485844,-40,26 ],
  [ 1000,25,2,3,2, 51796,84035,25898,-16119, 81520,80292,40760,614, 179825,88231,89913,45797, 220306,96722,110153,61792, 270091,194695,135046,37698, -3081814,-37,24 ],
  [ 1000,25,3,3,2, 75733,101426,25244,-8564, 121531,88845,40510,10895, 261840,195126,87280,22238, 330459,307297,110153,7721, 405137,351157,135046,17993, -3176283,-38,24 ],
  [ 1000,25,5,3,2, 112274,107618,24496,1016, 192278,118745,38456,14707, 415265,344150,83053,14223, 547000,379998,109400,33400, 669828,436637,133966,46638, -2573874,-33,20 ],
  [ 1000,25,10,3,2, 187338,120349,22939,8203, 396177,374148,33015,1836, 902403,552922,75200,29123, 1283647,866359,106971,34774, 1570460,992107,130872,48196, -1228572,-19,-44 ],
  [ 1000,25,15,3,2, 187338,120349,16652,5955, 396177,374148,18008,1001, 1358607,877205,61755,21882, 1305358,886735,59334,19028, 1598423,1014615,72656,26537, -922004,-14,-27 ],
  [ 1000,25,25,3,2, 288093,222503,20952,4770, 1126427,932096,23590,4070, 3223514,2012662,61991,23286, 5055421,3174685,97220,36168, 6552315,4242949,126006,44411, 4672158,50,0 ],
  [ 1000,25,50,3,2, 291631,223106,20831,4895, 1606862,1506743,20255,1262, 5986081,3583531,54419,21841, 9743666,5989821,88579,34126, 13483901,8626278,122581,44160, 11691594,76,0 ],
  [ 1000,25,75,3,2, 291631,223106,20831,4895, 1615312,1509126,20191,1327, 6072518,3613185,54219,21958, 9892789,6141051,88328,33498, 13716139,8770957,122466,44153, 11900157,77,0 ],
  [ 1000,50,1,3,2, 34028,81100,34028,-47072, 46593,58505,46593,-11912, 120434,77452,120434,42983, 119277,61976,119277,57300, 146345,70137,146345,76209, -3392820,-39,25 ],
  [ 1000,50,2,3,2, 60212,85562,30106,-12675, 90190,81891,45095,4149, 209277,108512,104639,50383, 238553,180744,119277,28905, 292691,199424,146345,46634, -3094662,-37,24 ],
  [ 1000,50,3,3,2, 86395,103361,28798,-5655, 133786,91241,44595,14182, 298121,202208,99374,31971, 357830,312788,119277,15014, 439036,358171,146345,26955, -2990955,-36,23 ],
  [ 1000,50,5,3,2, 126359,110174,27569,3531, 210946,196481,42189,2893, 464426,368221,92885,19241, 592271,389102,118454,40634, 725854,532034,145171,38764, -2630957,-33,21 ],
  [ 1000,50,10,3,2, 208419,137512,25521,8682, 433718,382595,36143,4260, 993008,690957,82751,25171, 1389700,888120,115808,41798, 1701676,1232302,141806,39115, -1183998,-18,-44 ],
  [ 1000,50,15,3,2, 272729,220195,24243,4670, 700671,566629,31849,6093, 1692521,1074774,76933,28079, 2486296,1597022,113013,40422, 3073289,2042551,139695,46852, 697122,17,0 ],
  [ 1000,50,25,3,2, 318481,328885,23162,-757, 1234038,1150523,25844,1749, 3516961,2110347,67634,27050, 5475670,3384055,105301,40223, 7096918,4698013,136479,46133, 4831396,51,0 ],
  [ 1000,50,50,3,2, 322340,329547,23024,-515, 1761430,1560483,22203,2533, 6528627,3926778,59351,23653, 10562034,6523702,96018,36712, 14600924,9440855,132736,46910, 12789206,79,0 ],
  [ 1000,50,75,3,2, 322340,329547,23024,-515, 1770700,1563102,22134,2595, 6622985,3957901,59134,23795, 10723969,6649249,95750,36381, 14852339,9521244,132610,47599, 13130723,80,0 ],
  [ 1000,100,1,3,2, 46366,83339,46366,-36974, 56762,60107,56762,-3346, 165682,100869,165682,64813, 137524,65704,137524,71820, 168945,74875,168945,94069, -3240273,-38,25 ],
  [ 1000,100,2,3,2, 77042,101954,38521,-12456, 107529,84938,53764,11295, 268182,197046,134091,35568, 275049,188225,137524,43412, 337890,322022,168945,7934, -3128133,-37,24 ],
  [ 1000,100,3,3,2, 107719,107232,35906,162, 158296,96033,52765,20754, 370681,336938,123560,11248, 412573,339297,137524,24425, 506835,372748,168945,44696, -2921280,-36,23 ],
  [ 1000,100,5,3,2, 154529,115287,33715,8562, 248283,204253,49657,8806, 562750,401847,112550,32181, 682813,422237,136563,52115, 837905,570888,167581,53403, -2157870,-29,17 ],
  [ 1000,100,10,3,2, 250581,216865,30683,4128, 508799,413674,42400,7927, 1174216,754775,97851,34953, 1601806,960296,133484,53459, 1964108,1301930,163676,55182, -584576,-8,0 ],
  [ 1000,100,15,3,2, 325803,330695,28960,-435, 821784,712700,37354,4958, 1984564,1235160,90207,34064, 2865921,1783934,130269,49181, 3546691,2271279,161213,57973, 1141159,23,0 ],
  [ 1000,100,25,3,2, 379257,353253,27582,1891, 1449260,1233829,30351,4512, 4103855,2462736,78920,31560, 6316168,4002289,121465,44498, 8186123,5310368,157425,55303, 6601685,59,0 ],
  [ 1000,100,50,3,2, 383757,354031,27411,2123, 2070568,1862899,26100,2618, 7613720,4491057,69216,28388, 12198770,7546735,110898,42291, 16834971,10801078,153045,54854, 15681900,86,0 ],
  [ 1000,100,75,3,2, 383757,354031,27411,2123, 2081475,1865991,26018,2694, 7723918,4602119,68964,27873, 12386328,7596914,110592,42763, 17124739,10972887,152899,54927, 15991396,87,0 ],
  [ 1000,200,1,3,2, 71041,87818,71041,-16777, 77098,77196,77098,-98, 256177,133039,256177,123138, 174019,73111,174019,100909, 214144,84253,214144,129891, -2945262,-36,23 ],
  [ 1000,200,2,3,2, 110703,108064,55352,1320, 142207,91332,71103,25438, 385990,355194,192995,15398, 348039,311945,174019,18047, 428288,357009,214144,35640, -3055055,-37,23 ],
  [ 1000,200,3,3,2, 150366,114973,50122,11798, 207315,193352,69105,4654, 515803,394148,171934,40552, 522058,376338,174019,48573, 642432,416523,214144,75303, -2557609,-33,20 ],
  [ 1000,200,5,3,2, 210869,125514,46008,18623, 322957,338032,64591,-3015, 759398,529935,151880,45892, 863897,553787,172779,62022, 1062006,745417,212401,63318, -1890777,-27,15 ],
  [ 1000,200,10,3,2, 334905,333037,41009,229, 658961,534298,54913,10389, 1536634,945048,128053,49299, 2026019,1279889,168835,62177, 2488972,1670899,207414,68173, 36159,6,0 ],
  [ 1000,200,15,3,2, 431949,363298,38396,6102, 1064009,872841,48364,8689, 2568650,1602498,116757,43916, 3625171,2402331,164781,55584, 4493495,2969286,204250,69282, 2582177,36,0 ],
  [ 1000,200,25,3,2, 500808,388652,36422,8157, 1879705,1652640,39366,4755, 5277644,3243015,101493,39127, 7997165,5051640,153792,56645, 10364534,6776281,199318,69005, 9329779,69,0 ],
  [ 1000,200,50,3,2, 506592,389663,36185,8352, 2688843,2363531,33893,4101, 9783906,5924730,88945,35083, 15472242,9606878,140657,53321, 21303066,13803575,193664,68177, 20928301,97,0 ],
  [ 1000,200,75,3,2, 506592,389663,36185,8352, 2703026,2367568,33788,4193, 9925785,5964670,88623,35367, 15711045,9790372,140277,52863, 21669540,13906883,193478,69309, 21463226,98,0 ],
  [ 1500,0,1,3,2, 34003,81418,34003,-47415, 53212,60803,53212,-7591, 99742,73720,99742,26022, 132355,65018,132355,67337, 162890,73940,162890,88950, -3395536,-39,25 ],
  [ 1500,0,2,3,2, 68006,100959,34003,-16477, 106424,86343,53212,10041, 199484,92804,99742,53340, 264710,186953,132355,38878, 325780,320240,162890,2770, -3108176,-37,24 ],
  [ 1500,0,3,3,2, 102008,107163,34003,-1718, 159636,98147,53212,20496, 299226,203554,99742,31891, 397064,336941,132355,20041, 488670,369619,162890,39684, -2833721,-35,22 ],
  [ 1500,0,5,3,2, 153921,116637,33583,8135, 254964,207995,50993,9394, 487725,374713,97545,22602, 657033,419264,131407,47554, 808021,566689,161604,48266, -2318256,-31,18 ],
  [ 1500,0,10,3,2, 260591,221152,31909,4829, 536241,424079,44687,9347, 1095837,730143,91320,30475, 1541320,953836,128443,48957, 1895154,1292829,157929,50194, -752773,-12,0 ],
  [ 1500,0,15,3,2, 344252,350640,30600,-568, 880348,820938,40016,2700, 1910896,1230345,86859,30934, 2761674,1773978,125531,44895, 3422301,2255639,155559,53030, 602914,16,0 ],
  [ 1500,0,25,3,2, 403841,361538,29370,3077, 1588111,1487413,33259,2109, 4088974,2572895,78634,29155, 6129045,3897695,117866,42911, 7889981,5190586,151730,51911, 5770725,55,0 ],
  [ 1500,0,50,3,2, 408876,362459,29205,3315, 2300033,2070515,28992,2893, 7789503,4829918,70814,26905, 11968583,7587983,108805,39824, 16216054,10535930,147419,51637, 14790811,84,0 ],
  [ 1500,0,75,3,2, 408876,362459,29205,3315, 2312580,2088046,28907,2807, 7907585,4866264,70603,27155, 12156953,7762810,108544,39233, 16495386,10819817,147280,50675, 14979001,85,0 ],
  [ 1500,25,1,3,2, 40172,82538,40172,-42366, 58296,75489,58296,-17193, 122366,78021,122366,44345, 141479,66857,141479,74622, 174190,76234,174190,97956, -3346096,-39,25 ],
  [ 1500,25,2,3,2, 76421,102486,38210,-13033, 115093,87941,57547,13576, 228936,113086,114468,57925, 282957,190668,141479,46145, 348380,324969,174190,11705, -3002501,-36,23 ],
  [ 1500,25,3,3,2, 112670,109098,37557,1191, 171891,114428,57297,19154, 335507,331052,111836,1485, 424436,342982,141479,27151, 522569,392803,174190,43255, -2982028,-36,23 ],
  [ 1500,25,5,3,2, 112670,109098,24583,779, 171891,114428,34378,11492, 482033,373627,96407,21681, 431218,344500,86244,17344, 531196,394747,106239,27290, -2731341,-34,21 ],
  [ 1500,25,10,3,2, 281672,224978,34490,6942, 573782,506526,47815,5605, 1186442,839128,98870,28943, 1647374,1098384,137281,45749, 2026370,1335504,168864,57572, -860218,-13,-19 ],
  [ 1500,25,15,3,2, 370788,355457,32959,1363, 940904,835606,42768,4786, 2056918,1272863,93496,35639, 2951486,1949967,134158,45524, 3659002,2532344,166318,51212, 1066119,22,0 ],
  [ 1500,25,25,3,2, 434229,380390,31580,3916, 1695722,1528991,35513,3492, 4382421,2763615,84277,31131, 6549294,4214625,125948,44897, 8434584,5629579,162204,53942, 6488567,58,0 ],
  [ 1500,25,50,3,2, 439585,381370,31399,4158, 2454602,2317691,30940,1726, 8332049,5171515,75746,28732, 12786951,8107338,116245,42542, 17333078,11348606,157573,54404, 15662865,86,0 ],
  [ 1500,25,75,3,2, 439585,381370,31399,4158, 2467968,2321572,30850,1830, 8458052,5271966,75518,28447, 12988133,8269608,115965,42130, 17631586,11552533,157425,54277, 15876321,87,0 ],
  [ 1500,50,1,3,2, 46340,83657,46340,-37317, 63380,76290,63380,-12910, 144990,82472,144990,62518, 150602,68696,150602,81907, 185490,78578,185490,106911, -3253073,-38,25 ],
  [ 1500,50,2,3,2, 84836,104014,42418,-9589, 123763,89540,61881,17112, 258389,195853,129194,31268, 301205,194333,150602,53436, 370979,345319,185490,12830, -3050996,-37,23 ],
  [ 1500,50,3,3,2, 123332,111034,41111,4099, 184146,116825,61382,22440, 371787,338134,123929,11218, 451807,348473,150602,34445, 556469,399817,185490,52217, -2796700,-35,22 ],
  [ 1500,50,5,3,2, 182090,121750,39729,13165, 292300,334002,58460,-8340, 586049,406689,117210,35872, 747575,517730,149515,45969, 920072,589322,184014,66150, -2300621,-31,18 ],
  [ 1500,50,10,3,2, 302753,329671,37072,-3296, 611322,527508,50944,6985, 1277046,856447,106421,35050, 1753427,1201253,146119,46014, 2157586,1463359,179799,57852, -977222,-15,-22 ],
  [ 1500,50,15,3,2, 397325,360274,35318,3293, 1001460,864159,45521,6241, 2202939,1392231,100134,36850, 3141299,2082827,142786,48112, 3895703,2596457,177077,59057, 1524437,27,0 ],
  [ 1500,50,25,3,2, 464617,385905,33790,5724, 1803333,1570718,37766,4872, 4675868,2925285,89921,33665, 6969543,4530905,134030,46897, 8979186,5999812,172677,57296, 7378544,62,0 ],
  [ 1500,50,50,3,2, 470294,386944,33592,5954, 2609170,2372931,32889,2978, 8874596,5577247,80678,29976, 13605319,8639669,123685,45142, 18450101,12093524,167728,57787, 17079714,89,0 ],
  [ 1500,50,75,3,2, 470294,386944,33592,5954, 2623355,2390934,32792,2905, 9008518,5616532,80433,30286, 13819312,8776306,123387,45027, 18767786,12271960,167570,57998, 17497875,90,0 ],
  [ 1500,100,1,3,2, 58678,85897,58678,-27219, 73548,77892,73548,-4344, 190238,105890,190238,84348, 168850,72424,168850,96426, 208089,83317,208089,124772, -3100525,-37,24 ],
  [ 1500,100,2,3,2, 101667,107069,50833,-2701, 141102,92737,70551,24183, 317293,221902,158646,47695, 337700,310173,168850,13763, 416178,354726,208089,30726, -2966218,-36,23 ],
  [ 1500,100,3,3,2, 144655,114904,48218,9917, 208656,195467,69552,4396, 444348,366814,148116,25845, 506550,374482,168850,44023, 624267,413894,208089,70124, -2715428,-34,21 ],
  [ 1500,100,5,3,2, 210260,140200,45875,15286, 329637,341624,65927,-2397, 684373,425801,136875,51714, 838117,550964,167623,57431, 1032123,741367,206425,58151, -1929359,-27,15 ],
  [ 1500,100,10,3,2, 344915,350660,42234,-704, 686403,544553,57200,11821, 1458255,920416,121521,44820, 1965533,1273579,163794,57663, 2420018,1661948,201668,63172, -186731,1,0 ],
  [ 1500,100,15,3,2, 450399,369907,40035,7155, 1122573,907379,51026,9782, 2494982,1583168,113408,41446, 3520924,2203408,160042,59887, 4369105,2937776,198596,65060, 2655678,37,0 ],
  [ 1500,100,25,3,2, 525393,396937,38210,9342, 2018556,1728024,42273,6084, 5262762,3277675,101207,38175, 7810041,4960221,150193,54804, 10068392,6612018,193623,66469, 9248344,69,0 ],
  [ 1500,100,50,3,2, 531711,398092,37979,9544, 2918308,2675497,36785,3061, 9959689,6156041,90543,34579, 15242055,9662603,138564,50722, 20684149,13521756,188038,65113, 19945039,95,0 ],
  [ 1500,100,75,3,2, 531711,398092,37979,9544, 2934131,2680088,36677,3176, 10109452,6304465,90263,33973, 15481671,9832831,138229,50436, 21040187,13753363,187859,65061, 20173473,95,0 ],
  [ 1500,200,1,3,2, 83354,103712,83354,-20359, 93884,80945,93884,12939, 280733,215209,280733,65524, 205345,79980,205345,125365, 253288,192346,253288,60942, -3064029,-37,23 ],
  [ 1500,200,2,3,2, 135328,113178,67664,11075, 175780,99131,87890,38325, 435101,365534,217551,34784, 410690,340361,205345,35165, 506576,389663,253288,58456, -2721669,-34,21 ],
  [ 1500,200,3,3,2, 187302,122645,62434,21553, 257675,205051,85892,17541, 589469,409659,196490,59937, 616036,396497,205345,73180, 759864,541449,253288,72805, -2078523,-29,16 ],
  [ 1500,200,5,3,2, 266600,222126,58167,9703, 404311,357168,80862,9429, 881020,570054,176204,62193, 1019201,710717,203840,61697, 1256225,902857,251245,70674, -1651371,-24,12 ],
  [ 1500,200,10,3,2, 429239,365966,52560,7748, 836565,711062,69714,10459, 1820673,1215089,151723,50465, 2389745,1592172,199145,66464, 2944881,2029367,245407,76293, 465876,14,0 ],
  [ 1500,200,15,3,2, 556545,402510,49471,13692, 1364799,1171870,62036,8769, 3079068,2026005,139958,47866, 4280174,2806829,194553,66970, 5315909,3522192,241632,81533, 3696312,44,0 ],
  [ 1500,200,25,3,2, 646944,432336,47050,15608, 2449000,2072985,51288,7875, 6436551,4057954,123780,45742, 9491038,6103055,182520,65154, 12246802,8077931,235515,80171, 12072248,77,0 ],
  [ 1500,200,50,3,2, 654546,433724,46753,15773, 3536582,3176129,44579,4544, 12129875,7498199,110272,42106, 18515528,11722696,168323,61753, 25152243,16440422,228657,79198, 25461173,104,0 ],
  [ 1500,200,75,3,2, 654546,433724,46753,15773, 3555682,3181665,44446,4675, 12311318,7623451,109922,41856, 18806389,11917279,167914,61510, 25584988,16770590,228437,78700, 25848023,105,0 ],
  [ 2000,0,1,3,2, 46315,83975,46315,-37660, 69999,78588,69999,-8589, 124298,78740,124298,45558, 163681,71737,163681,91943, 202034,82381,202034,119653, -3255789,-38,25 ],
  [ 2000,0,2,3,2, 92630,106074,46315,-6722, 139997,94142,69999,22928, 248595,194660,124298,26968, 327361,308902,163681,9230, 404068,352944,202034,25562, -3122759,-37,24 ],
  [ 2000,0,3,3,2, 138945,114835,46315,8037, 209996,197581,69999,4138, 372893,339480,124298,11138, 491042,372626,163681,39472, 606103,411265,202034,64946, -2873247,-35,22 ],
  [ 2000,0,5,3,2, 209652,141550,45742,14859, 336318,345366,67264,-1810, 609347,413181,121869,39233, 812338,548041,162468,52859, 1002240,737218,200448,53004, -2123245,-29,17 ],
  [ 2000,0,10,3,2, 354924,354947,43460,-3, 713846,568842,59487,12084, 1379876,895634,114990,40354, 1905047,1267120,158754,53161, 2351063,1652797,195922,58189, -398511,-5,0 ],
  [ 2000,0,15,3,2, 468848,389853,41675,7022, 1181137,1046118,53688,6137, 2421314,1576852,110060,38385, 3416676,2193452,155303,55601, 4244715,2921636,192942,60140, 2024592,32,0 ],
  [ 2000,0,25,3,2, 549977,418558,39998,9558, 2157406,1995343,45181,3394, 5247881,3310684,100921,37254, 7622918,4964237,146595,51128, 9772249,6576116,187928,61464, 8275796,65,0 ],
  [ 2000,0,50,3,2, 556830,419857,39774,9784, 3147773,2896848,39678,3163, 10135472,6480237,92141,33229, 15011868,9703950,136472,48254, 20065232,13369748,182411,60868, 18908543,93,0 ],
  [ 2000,0,75,3,2, 556830,419857,39774,9784, 3165236,2901992,39565,3291, 10293118,6525045,91903,33644, 15252296,9889866,136181,47879, 20410834,13570583,182240,61074, 19389737,94,0 ],
  [ 2000,25,1,3,2, 52484,85095,52484,-32611, 75083,79389,75083,-4306, 146921,83191,146921,63730, 172804,73576,172804,99228, 213334,100397,213334,112937, -3173938,-38,24 ],
  [ 2000,25,2,3,2, 101045,107601,50523,-3278, 148667,95740,74333,26463, 278047,200427,139024,38810, 345609,327543,172804,9033, 426668,357623,213334,34522, -3005687,-36,23 ],
  [ 2000,25,3,3,2, 149607,116771,49869,10945, 222251,199977,74084,7424, 409173,346563,136391,20870, 518413,378117,172804,46765, 640002,433950,213334,68684, -2699092,-34,21 ],
  [ 2000,25,5,3,2, 223737,144106,48815,17374, 354986,349252,70997,1147, 658509,422737,131702,47154, 857609,557095,171522,60103, 1058265,764456,211653,58762, -1864486,-26,15 ],
  [ 2000,25,10,3,2, 376005,358774,46041,2110, 751386,681789,62616,5800, 1470480,926118,122540,45364, 2011100,1288231,167592,60239, 2482279,1694972,206857,65609, -240121,-1,0 ],
  [ 2000,25,15,3,2, 495384,394669,44034,8952, 1241694,1148521,56441,4235, 2567336,1696221,116697,39596, 3606488,2434772,163931,53260, 4481416,3001420,203701,67273, 2077881,32,0 ],
  [ 2000,25,25,3,2, 580365,424073,42208,11367, 2265017,2037071,47435,4774, 5541328,3578403,106564,37749, 8043167,5281017,154676,53118, 10316852,6946850,198401,64808, 8920395,68,0 ],
  [ 2000,25,50,3,2, 587539,425431,41967,11579, 3302341,3056439,41626,3100, 10678018,6821984,97073,35055, 15830236,10251308,143911,50718, 21182255,14001125,192566,65283, 20200296,95,0 ],
  [ 2000,25,75,3,2, 587539,425431,41967,11579, 3320624,3061819,41508,3235, 10843585,6868262,96818,35494, 16083476,10396564,143602,50776, 21547034,14303199,192384,64677, 20718529,96,0 ],
  [ 2000,50,1,3,2, 58653,86215,58653,-27562, 80167,80190,80167,-23, 169545,102157,169545,67388, 181928,75465,181928,106463, 224634,102791,224634,121842, -3114415,-37,24 ],
  [ 2000,50,2,3,2, 109461,109129,54730,166, 157336,97339,78668,29999, 307500,220709,153750,43395, 363856,331259,181928,16299, 449267,362352,224634,43458, -2900011,-36,22 ],
  [ 2000,50,3,3,2, 160269,118706,53423,13854, 234506,202373,78169,10711, 445454,368160,148485,25765, 545784,383658,181928,54042, 673901,441013,224634,77629, -2547264,-33,20 ],
  [ 2000,50,5,3,2, 237822,218363,51888,4246, 373654,353138,74731,4103, 707671,523808,141534,36773, 902880,581176,180576,64341, 1114291,776072,222858,67644, -2120640,-29,17 ],
  [ 2000,50,10,3,2, 397086,362600,48623,4223, 788927,704271,65744,7055, 1561085,957953,130090,50261, 2117153,1404627,176429,59377, 2613495,1821377,217791,66010, 945,5,0 ],
  [ 2000,50,15,3,2, 521921,399486,46393,10883, 1302250,1163188,59193,6321, 2713357,1738739,123334,44301, 3796301,2487324,172559,59499, 4718117,3149364,214460,71307, 2815069,38,0 ],
  [ 2000,50,25,3,2, 610753,429589,44418,13176, 2372628,2078799,49689,6154, 5834775,3740073,112207,40283, 8463416,5488837,162758,57203, 10861455,7317083,208874,68161, 9970446,71,0 ],
  [ 2000,50,50,3,2, 618248,431005,44161,13374, 3456910,3185529,43574,3421, 11220565,7150716,102005,36999, 16648604,10755486,151351,53574, 22299279,14730371,202721,68808, 21612585,98,0 ],
  [ 2000,50,75,3,2, 618248,431005,44161,13374, 3476011,3205031,43450,3387, 11394052,7212828,101733,37332, 16914655,10918239,151024,53539, 22683234,15051936,202529,68137, 22063301,99,0 ],
  [ 2000,100,1,3,2, 70990,101791,70990,-30800, 90335,81641,90335,8693, 214793,111060,214793,103733, 200176,79293,200176,120882, 247233,191410,247233,55823, -3042795,-37,23 ],
  [ 2000,100,2,3,2, 126291,112183,63146,7054, 174675,100536,87338,37070, 366404,338292,183202,14056, 400351,339089,200176,30631, 494466,387881,247233,53293, -2878210,-36,22 ],
  [ 2000,100,3,3,2, 181592,122576,60531,19672, 259016,207166,86339,17283, 518014,382325,172671,45230, 600527,394640,200176,68629, 741700,538820,247233,67626, -2236342,-30,18 ],
  [ 2000,100,5,3,2, 265992,223476,58035,9276, 410991,374795,82198,7239, 805995,542769,161199,52645, 993421,707744,198684,57136, 1226342,898607,245268,65547, -1855341,-26,15 ],
  [ 2000,100,10,3,2, 439249,370253,53786,8448, 864008,721466,72001,11879, 1742294,1190456,145191,45986, 2329259,1585713,194105,61962, 2875927,1891504,239661,82035, 389485,12,0 ],
  [ 2000,100,15,3,2, 574995,422456,51111,13559, 1423363,1206259,64698,9868, 3005400,1944190,136609,48237, 4175926,2796873,189815,62684, 5191519,3506553,235978,76589, 3568345,43,0 ],
  [ 2000,100,25,3,2, 671529,440620,48838,16793, 2587851,2340454,54196,5181, 6421669,4105478,123494,44542, 9303915,5998511,178921,63565, 11950660,7928839,229820,77343, 11361600,75,0 ],
  [ 2000,100,50,3,2, 679665,442152,48547,16965, 3766048,3488095,47471,3504, 12305658,7729510,111870,41601, 18285341,11778420,166230,59154, 24533326,16174274,223030,75991, 24466738,103,0 ],
  [ 2000,100,75,3,2, 679665,442152,48547,16965, 3786787,3494185,47335,3658, 12494985,7963246,111562,40462, 18577014,11974714,165866,58949, 24955635,16503979,222818,75461, 24616798,103,0 ],
  [ 2000,200,1,3,2, 95666,106270,95666,-10604, 110671,84845,110671,25826, 305288,220380,305288,84909, 236671,181984,236671,54687, 292432,200838,292432,91595, -3021326,-37,23 ],
  [ 2000,200,2,3,2, 159953,118293,79976,20830, 209353,194665,104677,7344, 484212,390240,242106,46986, 473342,353850,236671,59746, 584864,406647,292432,89109, -2665871,-34,21 ],
  [ 2000,200,3,3,2, 224239,143654,74746,26862, 308035,334835,102678,-8933, 663136,438035,221045,75034, 710013,512139,236671,65958, 877297,582245,292432,98350, -2138729,-29,17 ],
  [ 2000,200,5,3,2, 321359,151154,70115,37136, 390722,385537,78144,1037, 853436,443035,170687,82080, 964612,512139,192922,90494, 1175931,582245,235186,118737, -665869,-11,0 ],
  [ 2000,200,10,3,2, 523573,398895,64111,15267, 1014170,843440,84514,14228, 2104711,1303729,175393,66749, 2753471,1795495,229456,79831, 3400791,2259123,283399,95139, 1705773,29,0 ],
  [ 2000,200,15,3,2, 681142,441722,60546,21282, 1665589,1470900,75709,8849, 3589486,2372512,163158,55317, 4935176,3291633,224326,74706, 6138323,4075098,279015,93783, 4868420,50,0 ],
  [ 2000,200,25,3,2, 793080,547719,57679,17844, 3018295,2685415,63210,6971, 7595457,4808757,146066,53590, 10984911,7061038,211248,75459, 14129071,9478533,271713,89433, 14126808,82,0 ],
  [ 2000,200,50,3,2, 802500,549485,57321,18073, 4384322,3988728,55265,4986, 14475844,9148668,131599,48429, 21558813,13838513,195989,70185, 29001420,19092941,263649,90077, 29512389,110,0 ],
  [ 2000,200,75,3,2, 802500,549485,57321,18073, 4408338,3995762,55104,5157, 14696851,9219747,131222,48903, 21901732,14059212,195551,70023, 29500435,19437475,263397,89848, 30200096,111,0 ],
  [ 2500,0,1,3,2, 58627,86533,58627,-27905, 86785,82487,86785,4298, 148853,83910,148853,64943, 195006,78457,195006,116550, 241178,190325,241178,50854, -3143365,-37,24 ],
  [ 2500,0,2,3,2, 117255,111188,58627,3033, 173570,115826,86785,28872, 297706,204851,148853,46428, 390012,337317,195006,26348, 482357,369878,241178,56240, -2821784,-35,22 ],
  [ 2500,0,3,3,2, 175882,122508,58627,17792, 260356,209280,86785,17025, 446559,354991,148853,30523, 585019,392784,195006,64078, 723535,536192,241178,62448, -2394161,-32,19 ],
  [ 2500,0,5,3,2, 265383,224825,57902,8849, 417672,378537,83534,7827, 730970,530150,146194,40164, 967642,704821,193528,52564, 1196459,894458,239292,60400, -2049227,-28,16 ],
  [ 2500,0,10,3,2, 449258,387876,55011,7516, 891451,819455,74288,6000, 1663914,1180189,138660,40310, 2268773,1579453,189064,57443, 2806973,1882554,233914,77035, -144269,2,0 ],
  [ 2500,0,15,3,2, 593444,429065,52751,14611, 1481927,1240797,67360,10960, 2931732,1924860,133261,45767, 4071678,2678257,185076,63337, 5067129,3490714,230324,71655, 3512148,43,0 ],
  [ 2500,0,25,3,2, 696113,533941,50626,11794, 2726701,2415688,57104,6513, 6406788,4140137,123207,43589, 9116791,6002527,175323,59890, 11654518,7893487,224125,72328, 10699054,73,0 ],
  [ 2500,0,50,3,2, 704784,535618,50342,12083, 3995513,3709446,50364,3606, 12481441,8053706,113468,40252, 18055153,11819767,164138,56685, 23914409,16022267,217404,71747, 23136257,100,0 ],
  [ 2500,0,75,3,2, 704784,535618,50342,12083, 4017892,3716089,50224,3773, 12678652,8198341,113202,40003, 18347640,12016822,163818,56525, 24326281,16237469,217199,72222, 23587416,101,0 ],
  [ 2500,25,1,3,2, 64796,100989,64796,-36193, 91869,83138,91869,8731, 171477,88362,171477,83115, 204130,80446,204130,123684, 252478,192819,252478,59659, -3105034,-37,24 ],
  [ 2500,25,2,3,2, 125670,112716,62835,6477, 182240,117425,91120,32408, 327158,331182,163579,-2012, 408260,341532,204130,33364, 504956,390778,252478,57089, -2972659,-36,23 ],
  [ 2500,25,3,3,2, 186544,137779,62181,16255, 272611,315876,90870,-14422, 482840,375088,160947,35917, 612390,398475,204130,71305, 757434,542905,252478,71510, -2623647,-33,21 ],
  [ 2500,25,5,3,2, 279468,328248,60975,-10643, 436340,380923,87268,11083, 780132,539705,156026,48085, 1012913,715375,202583,59508, 1252484,907525,250497,68992, -2192969,-29,18 ],
  [ 2500,25,10,3,2, 470339,391702,57593,9629, 928991,828052,77416,8412, 1754519,1197659,146210,46405, 2374826,1600515,197902,64526, 2938189,2038269,244849,74993, 293611,11,0 ],
  [ 2500,25,15,3,2, 619980,433881,55109,16542, 1542483,1343200,70113,9058, 3077753,2044228,139898,46978, 4261491,2839269,193704,64646, 5303830,3554827,241083,79500, 3695135,44,0 ],
  [ 2500,25,25,3,2, 726501,539457,52836,13603, 2834312,2531266,59357,6347, 6700235,4407857,128851,44084, 9537040,6239000,183405,63424, 12199120,8248550,234598,75973, 11239569,75,0 ],
  [ 2500,25,50,3,2, 735493,541191,52535,13879, 4150081,3869037,52312,3543, 13023987,8395453,118400,42078, 18873522,12367125,171577,59149, 25031433,16834843,227558,74514, 24298818,102,0 ],
  [ 2500,25,75,3,2, 735493,541191,52535,13879, 4173279,3875916,52166,3717, 13229118,8527043,118117,41983, 19178819,12538447,171239,59289, 25462482,17053815,227344,75077, 24867870,103,0 ],
  [ 2500,50,1,3,2, 70965,102109,70965,-31144, 96953,83939,96953,13014, 194101,107328,194101,86773, 213254,97311,213254,115943, 263778,195213,263778,68565, -3067614,-37,24 ],
  [ 2500,50,2,3,2, 133437,102109,66719,15664, 142135,116091,71068,13022, 283931,112328,141966,85802, 328676,97311,164338,115682, 399850,195213,199925,102318, -2313299,-31,18 ],
  [ 2500,50,3,3,2, 197206,139715,65735,19164, 284866,332157,94955,-15764, 519120,382021,173040,45700, 639761,418943,213254,73606, 791334,565540,263778,75265, -2515179,-32,20 ],
  [ 2500,50,5,3,2, 293553,330805,64048,-8128, 455008,384809,91002,14040, 829293,549261,165859,56006, 1058184,739405,211637,63756, 1308510,919091,261702,77884, -1945140,-27,16 ],
  [ 2500,50,10,3,2, 491420,395529,60174,11742, 966532,836649,80544,10824, 1845123,1229643,153760,51290, 2480879,1636603,206740,70356, 3069405,2164724,255784,75390, 696784,17,0 ],
  [ 2500,50,15,3,2, 646517,438698,57468,18473, 1603040,1357868,72865,11144, 3223775,2086746,146535,51683, 4451303,2972129,202332,67235, 5540531,3831532,251842,77682, 4221994,47,0 ],
  [ 2500,50,25,3,2, 756889,544972,55046,15412, 2941923,2677344,61611,5541, 6993682,4491027,134494,48128, 9957289,6540303,191486,65711, 12743723,8618283,245072,79335, 12001526,77,0 ],
  [ 2500,50,50,3,2, 766201,546765,54729,15674, 4304650,3998127,54260,3864, 13566534,8724185,123332,44021, 19691890,12871303,179017,62005, 26148456,17466670,237713,78925, 25780566,105,0 ],
  [ 2500,50,75,3,2, 766201,546765,54729,15674, 4328667,4019128,54108,3869, 13779585,8871609,123032,43821, 20009999,13045145,178661,62186, 26598682,17802553,237488,78537, 26234745,105,0 ],
  [ 2500,100,1,3,2, 827619,571250,827619,256369, 4639443,4322016,4639443,317426, 4435881,2875741,4435881,1560140, 21092443,13496184,21092443,7596259, 28110250,18589759,28110250,9520491, 20210959,96,0 ],
  [ 2500,100,2,3,2, 150916,117298,75458,16809, 208248,196070,104124,6089, 415515,362998,207757,26258, 463003,352578,231501,55212, 572755,404864,286377,83945, -2822413,-35,22 ],
  [ 2500,100,3,3,2, 218529,143585,72843,24981, 309375,336949,103125,-9191, 591681,410701,197227,60327, 694504,510283,231501,61407, 859132,579617,286377,93172, -2296547,-31,18 ],
  [ 2500,100,5,3,2, 321723,349255,70194,-6007, 492345,392432,98469,19983, 927617,582888,185523,68946, 1148726,757714,229745,78202, 1420560,958096,284112,92493, -1504642,-22,11 ],
  [ 2500,100,10,3,2, 533582,403182,65337,15967, 1041612,867729,86801,14490, 2026332,1293462,168861,61073, 2692985,1789086,224415,75325, 3331837,2250022,277653,90151, 1460493,26,0 ],
  [ 2500,100,15,3,2, 699591,533368,62186,14775, 1724152,1505288,78371,9948, 3515818,2367697,159810,52187, 4830928,3201370,219588,74071, 6013933,4059459,273361,88840, 4388496,48,0 ],
  [ 2500,100,25,3,2, 817665,569340,59467,18060, 3157146,2834499,66118,6757, 7580576,4843416,145780,52638, 10797788,7065054,207650,71783, 13832928,9230639,266018,88506, 13676005,81,0 ],
  [ 2500,100,50,3,2, 827619,571250,59116,18312, 4613787,4300543,58157,3948, 14651626,9379979,133197,47924, 21328626,13894387,193897,67584, 28382503,19008143,258023,85221, 28334068,108,0 ],
  [ 2500,100,75,3,2, 827619,571250,59116,18312, 4613787,4300543,57672,3916, 14811507,9531537,132246,47143, 21338291,13897029,190520,66440, 28395273,19027166,253529,83644, 28357736,108,0 ],
  [ 2500,200,1,3,2, 107978,108827,107978,-849, 127458,88744,127458,38713, 329844,346115,329844,-16271, 267997,189253,267997,78743, 331576,322970,331576,8607, -3197504,-38,24 ],
  [ 2500,200,2,3,2, 184577,123408,92289,30585, 242927,202464,121463,20231, 533323,400580,266662,66371, 535993,382265,267997,76864, 663153,439301,331576,111926, -2332486,-31,18 ],
  [ 2500,200,3,3,2, 261176,223026,87059,12717, 358395,346534,119465,3954, 736802,545061,245601,63914, 803990,547324,267997,85555, 994729,736532,331576,86066, -2206626,-30,18 ],
  [ 2500,200,5,3,2, 378063,359481,82486,4054, 567019,421861,113404,29032, 1124265,756041,224853,73645, 1329810,904790,265962,85004, 1644662,1149345,328932,99063, -1046585,-16,-36 ],
  [ 2500,200,10,3,2, 617907,431824,75662,22786, 1191774,915853,99315,22993, 2388750,1589785,199062,66580, 3117197,2092852,259766,85362, 3856700,2618141,321392,103213, 2450715,35,0 ],
  [ 2500,200,15,3,2, 805738,552634,71621,22498, 1966378,1665579,89381,13673, 4099904,2720520,186359,62699, 5590178,3696131,254099,86093, 6960737,4757266,316397,100158, 6099885,56,0 ],
  [ 2500,200,25,3,2, 939216,692269,68307,17960, 3587590,3178110,75133,8575, 8754364,5623695,168353,60205, 12478785,8209238,239977,82107, 16011339,10781682,307910,100570, 16081194,87,0 ],
  [ 2500,200,50,3,2, 950454,707749,67890,17336, 5232062,4799676,65950,5450, 16821812,10722137,152926,55452, 24602098,15875673,223655,79331, 32850598,21815219,298642,100322, 33635685,115,0 ],
  [ 2500,200,75,3,2, 950454,707749,67890,17336, 5260994,4808209,65762,5660, 17082385,10878528,152521,55392, 24997076,16187768,223188,78655, 33415883,22189742,298356,100233, 34197158,116,0 ],
  [ 3000,0,1,3,2, 70940,102427,70940,-31487, 103572,86237,103572,17335, 173409,89081,173409,84328, 226332,100303,226332,126029, 280323,198966,280323,81356, -3036830,-37,23 ],
  [ 3000,0,2,3,2, 141879,116303,70940,12788, 207144,197475,103572,4834, 346817,335756,173409,5531, 452664,351306,226332,50679, 560645,403082,280323,78781, -2978954,-36,23 ],
  [ 3000,0,3,3,2, 212819,143516,70940,23101, 310716,339064,103572,-9449, 520226,383367,173409,45620, 678996,428119,226332,83626, 840968,576988,280323,87993, -2335842,-31,19 ],
  [ 3000,0,5,3,2, 321114,350604,70061,-6434, 499026,410059,99805,17793, 852592,555603,170518,59398, 1122946,754741,224589,73641, 1390677,953846,278135,87366, -1708612,-24,13 ],
  [ 3000,0,10,3,2, 543592,420805,66562,15035, 1069055,891868,89088,14766, 1947953,1268680,162329,56606, 2632499,1782777,219375,70810, 3262882,2241022,271907,85155, 1194019,23,0 ],
  [ 3000,0,15,3,2, 718040,539977,63826,15828, 1782716,1553712,81033,10409, 3442150,2348217,156461,49724, 4726681,3191214,214849,69794, 5889543,4043570,267707,83908, 4128642,46,0 ],
  [ 3000,0,25,3,2, 718040,541002,52221,12875, 1782716,1941455,37334,-3324, 3518136,2422518,67656,21070, 4726681,3191464,90898,29523, 5889543,4043807,113260,35495, 2899767,39,0 ],
  [ 3000,0,50,3,2, 852738,680545,60910,12299, 4843252,4520544,61049,4068, 14827409,9704176,134795,46575, 21098439,13856777,191804,65833, 27763586,18744395,252396,81993, 27137748,107,0 ],
  [ 3000,0,75,3,2, 852738,680545,60910,12299, 4870548,4528686,60882,4273, 15064185,9780122,134502,47179, 21442983,14160205,191455,65025, 28241729,19102676,252158,81599, 27712933,108,0 ],
  [ 3000,25,1,3,2, 77108,103547,77108,-26438, 108656,87038,108656,21618, 196033,108047,196033,87986, 235456,182499,235456,52957, 291622,201361,291622,90262, -3095831,-37,24 ],
  [ 3000,25,2,3,2, 150295,117831,75147,16232, 215813,199074,107907,8370, 376269,341523,188135,17373, 470911,354971,235456,57970, 583245,407761,291622,87742, -2839778,-35,22 ],
  [ 3000,25,3,3,2, 223481,217152,74494,2110, 322971,341460,107657,-6163, 556506,390449,185502,55352, 706367,513918,235456,64150, 874867,584001,291622,96955, -2563022,-33,20 ],
  [ 3000,25,5,3,2, 335199,353161,73134,-3919, 517694,413945,103539,20750, 901754,579674,180351,64416, 1168217,859129,233643,61818, 1446703,981134,289341,93114, -1623982,-24,12 ],
  [ 3000,25,10,3,2, 564673,424631,69144,17148, 1106596,900465,92216,17178, 2038557,1377664,169880,55074, 2738552,1803888,228213,77889, 3394098,2283696,282842,92534, 1502533,26,0 ],
  [ 3000,25,15,3,2, 744576,544794,66185,17758, 1843273,1568380,83785,12495, 3588171,2390735,163099,54429, 4916493,3324074,223477,72383, 6126244,4220824,278466,86610, 4726408,50,0 ],
  [ 3000,25,25,3,2, 872637,684007,63465,13719, 3403607,3142196,71280,5475, 7859142,5144145,151137,52211, 11030913,7263763,212133,72445, 14081389,9566821,270796,86819, 13171253,80,0 ],
  [ 3000,25,50,3,2, 883447,699455,63103,13142, 4997821,4679985,62998,4006, 15369956,10045923,139727,48400, 21916807,14484592,199244,67566, 28880610,19489012,262551,85378, 28175652,108,0 ],
  [ 3000,25,75,3,2, 883447,699455,63103,13142, 5026805,4688363,62835,4231, 15501924,10116973,138410,48080, 22109218,14484592,197404,68077, 29147576,19489112,260246,86236, 28854147,109,0 ],
  [ 3000,50,1,3,2, 83277,104666,83277,-21389, 113740,87839,113740,25901, 218656,112498,218656,106158, 244580,184338,244580,60241, 302922,203705,302922,99217, -3002807,-36,23 ],
  [ 3000,50,2,3,2, 158710,119358,79355,19676, 224483,200672,112241,11905, 405722,347290,202861,29216, 489159,373613,244580,57773, 605844,412440,302922,96702, -2722705,-34,21 ],
  [ 3000,50,3,3,2, 234142,219087,78047,5019, 335225,343856,111742,-2877, 592787,412047,197596,60247, 733739,519459,244580,71427, 908766,606736,302922,100677, -2422368,-32,19 ],
  [ 3000,50,5,3,2, 349284,355717,76207,-1404, 536363,417831,107273,23706, 950916,695279,190183,51127, 1213488,868683,242698,68961, 1502728,993201,300546,101905, -1599427,-23,12 ],
  [ 3000,50,10,3,2, 585754,428458,71725,19261, 1144136,909063,95345,19589, 2129162,1395134,177430,61169, 2844605,1948386,237050,74685, 3525314,2523192,293776,83510, 1698501,28,0 ],
  [ 3000,50,15,3,2, 771113,549610,68543,19689, 1903829,1670782,86538,10593, 3734193,2447618,169736,58481, 5106306,3391653,232105,77939, 6362945,4384438,289225,89932, 5119455,52,0 ],
  [ 3000,50,25,3,2, 771113,549610,56081,16109, 1903829,1670782,39871,4881, 6917066,4465802,133021,47140, 5289367,3539717,101719,33647, 6597952,4580656,126884,38794, 7859816,63,0 ],
  [ 3000,50,50,3,2, 914155,705029,65297,14938, 5152390,4735225,64946,5258, 15912502,10374655,144659,50344, 22735175,14988771,206683,70422, 29997633,20105168,272706,89932, 29902354,111,0 ],
  [ 3000,50,75,3,2, 914155,705029,65297,14938, 5181323,4831575,64767,4372, 16165118,10453390,144331,50998, 23105342,15188678,206298,70685, 30514130,20471038,272448,89670, 30445936,111,0 ],
  [ 3000,100,1,3,2, 95615,106906,95615,-11291, 123908,89441,123908,34468, 263904,212915,263904,50989, 262827,188066,262827,74761, 325522,321534,325522,3987, -3107390,-37,24 ],
  [ 3000,100,2,3,2, 175541,122413,87770,26564, 241822,203869,120911,18976, 464626,373339,232313,45644, 525654,380993,262827,72330, 651043,437519,325522,106762, -2489027,-32,20 ],
  [ 3000,100,3,3,2, 255466,222957,85155,10836, 359735,348648,119912,3696, 665347,440727,221782,74874, 788481,545468,262827,81005, 976565,733903,325522,80887, -2187947,-30,18 ],
  [ 3000,100,5,3,2, 377454,360830,82354,3627, 573699,499453,114740,14849, 1049240,728906,209848,64067, 1304030,901817,260806,80443, 1614779,1145146,322956,93927, -1440753,-21,10 ],
  [ 3000,100,10,3,2, 627916,436110,76888,23486, 1219217,1044492,101601,14560, 2310371,1563503,192531,62239, 3056712,2086393,254726,80860, 3787746,2592819,315646,99577, 1923487,30,0 ],
  [ 3000,100,15,3,2, 824187,673447,73261,13399, 2024942,1712353,92043,14209, 4026236,2609505,183011,64397, 5485931,3687175,249360,81762, 6836347,4726906,310743,95884, 5594715,54,0 ],
  [ 3000,100,25,3,2, 963800,713890,70095,18175, 3726441,3445429,78041,5885, 8739483,5656705,168067,59284, 12291661,8024486,236378,82061, 15715197,10548910,302215,99352, 15527612,86,0 ],
  [ 3000,100,50,3,2, 975573,716177,69684,18528, 5461527,5037791,68843,5341, 16997595,10953449,154524,54947, 24371911,15931397,221563,76732, 32231681,21662161,293015,96087, 32794398,114,0 ],
  [ 3000,100,75,3,2, 975573,716177,69684,18528, 5492099,5134613,68651,4469, 17266052,11218173,154161,53999, 24767701,16245203,221140,76094, 32786530,22036172,292737,95985, 32841718,114,0 ],
  [ 3000,200,1,3,2, 120290,111384,120290,8906, 144244,92644,144244,51600, 354399,351285,354399,3114, 299322,195973,299322,103350, 370721,347133,370721,23588, -3025347,-37,23 ],
  [ 3000,200,2,3,2, 209202,141859,104601,33671, 276500,314463,138250,-18982, 582434,423936,291217,79249, 598645,395904,299322,101370, 741441,539765,370721,100838, -2440372,-32,19 ],
  [ 3000,200,3,3,2, 298113,331565,99371,-11151, 408755,370618,136252,12713, 810469,560422,270156,83349, 897967,583959,299322,104669, 1112162,779128,370721,111011, -2147166,-29,18 ],
  [ 3000,200,5,3,2, 433794,371057,94646,13688, 648373,528882,129675,23898, 1245887,873009,249177,74576, 1485114,953110,297023,106401, 1838881,1290914,367776,109593, -741117,-12,0 ],
  [ 3000,200,10,3,2, 712240,536453,87213,21525, 1369379,1166466,114115,16909, 2672788,1753775,222732,76584, 3480924,2404636,290077,89691, 4312610,2960438,359384,112681, 2609219,36,0 ],
  [ 3000,200,15,3,2, 930334,692713,82696,21122, 2267168,1976994,103053,13190, 4610322,2960827,209560,74977, 6245181,4101628,283872,97434, 7783151,5311123,353780,112365, 7178638,61,0 ],
  [ 3000,200,25,3,2, 1085352,749290,78935,24441, 4156885,3686041,87055,9861, 9913271,6452999,190640,66544, 13972658,9167370,268705,92409, 17893607,12083482,344108,111733, 18596076,92,0 ],
  [ 3000,200,50,3,2, 1098408,751809,78458,24757, 6079802,5538424,76636,6824, 19167781,12372606,174253,61774, 27645383,17991490,251322,87763, 36699775,24580828,333634,110172, 38134034,121,0 ],
  [ 3000,200,75,3,2, 1098408,751809,78458,24757, 6113650,5622306,76421,6142, 19467918,12474824,173821,62438, 28092419,18314725,250825,87301, 37331331,25053498,333315,109624, 38724952,121,0 ],
  [ 1000,0,1,0,3, 7354,76125,7354,-68771, 15931,51673,15931,-35742, 42442,45821,42442,-3379, 66306,33047,66306,33259, 91118,38210,91118,52908, -3715344,-41,27 ],
  [ 1000,0,2,0,3, 14708,77037,7354,-31164, 31863,54348,15931,-11243, 84885,51673,42442,16606, 132612,57581,66306,37515, 182235,67530,91118,57352, -3457402,-40,26 ],
  [ 1000,0,3,0,3, 22062,77949,7354,-18629, 47794,57022,15931,-3076, 127327,72039,42442,18429, 198918,67189,66306,43910, 273353,81229,91118,64041, -3199684,-38,25 ],
  [ 1000,0,5,0,3, 22062,77949,4814,-12193, 47794,57022,9559,-1846, 183701,79739,36740,20792, 201714,67635,40343,26816, 277292,81867,55458,39085, -3082294,-37,24 ],
  [ 1000,0,10,0,3, 56369,82207,6902,-3164, 155618,90080,12968,5462, 447294,313044,37274,11188, 757125,380892,63094,31353, 1042705,539200,86892,41959, -2077365,-28,17 ],
  [ 1000,0,15,0,3, 74474,84457,6620,-887, 249821,195375,11356,2475, 759822,384069,34537,17080, 1338488,588304,60840,34099, 1865690,920048,84804,42984, -627066,-9,0 ],
  [ 1000,0,25,0,3, 87375,99400,6355,-875, 433149,349113,9071,1760, 1538737,726954,29591,15611, 2875513,1286216,55298,30563, 4226819,2043229,81285,41992, 2576796,37,0 ],
  [ 1000,0,50,0,3, 88465,99537,6319,-791, 611358,412300,7706,2509, 2756451,1232475,25059,13854, 5394728,2475893,49043,26535, 8518484,4030414,77441,40801, 8180662,65,0 ],
  [ 1000,0,75,0,3, 88465,99537,6319,-791, 614489,412924,7681,2520, 2793264,1237211,24940,13893, 5472985,2486602,48866,26664, 8660019,4050681,77322,41155, 8448276,66,0 ],
  [ 1000,25,1,0,3, 10776,76583,10776,-65808, 18455,51945,18455,-33489, 60117,48391,60117,11726, 72294,33936,72294,38358, 99438,55152,99438,44286, -3660072,70,27 ],
  [ 1000,25,2,0,3, 18891,77597,9446,-29353, 35955,54889,17977,-9467, 106414,69305,53207,18555, 144588,59402,72294,42593, 198876,70117,99438,64380, -3397498,-39,26 ],
  [ 1000,25,3,0,3, 27007,78611,9002,-17201, 53454,57833,17818,-1460, 152711,75703,50904,25669, 216882,69841,72294,49014, 298314,100703,99438,65870, -3090494,-37,24 ],
  [ 1000,25,5,0,3, 39396,80159,8596,-8894, 84246,77031,16849,1443, 238012,87283,47602,30146, 357134,185259,71427,34375, 491208,326327,98242,32976, -2827087,-35,22 ],
  [ 1000,25,10,0,3, 39396,80159,4824,-4991, 84246,77031,7021,601, 238012,87283,19834,12561, 367040,186840,30587,15017, 505601,344327,42133,13440, -2817371,-35,22 ],
  [ 1000,25,15,0,3, 84801,85839,7538,-92, 276155,200068,12553,3459, 844194,396027,38372,20371, 1459055,714330,66321,33851, 2035540,961340,92525,48827, -272330,-1,0 ],
  [ 1000,25,25,0,3, 98875,100003,7191,-82, 481197,358206,10077,2576, 1668901,803153,32094,16649, 2449820,719504,47112,33275, 3523976,976340,67769,48993, 3330811,42,0 ],
  [ 1000,25,50,0,3, 100207,101108,7158,-64, 676445,424860,8527,3171, 3036094,1300463,27601,15778, 5888713,2657307,53534,29376, 9287872,4404488,84435,44394, 9616682,70,0 ],
  [ 1000,25,75,0,3, 100207,101108,7158,-64, 679911,425554,8499,3179, 3076609,1382706,27470,15124, 5974314,2792497,53342,28409, 9442141,4426626,84305,44781, 9550828,70,0 ],
  [ 1000,50,1,0,3, 14197,77041,14197,-62844, 20979,52216,20979,-31237, 77793,65476,77793,12317, 78282,34874,78282,43408, 107758,56472,107758,51286, -3627126,-41,27 ],
  [ 1000,50,2,0,3, 22211,78148,11106,-27969, 40636,55425,20318,-7395, 106331,65481,53165,20425, 121891,34895,60946,43498, 166564,56477,83282,55043, -3375145,-39,25 ],
  [ 1000,50,3,0,3, 31952,79272,10651,-15774, 59114,58644,19705,156, 178095,79368,59365,32909, 234846,72494,78282,54118, 323274,104505,107758,72923, -2970132,-36,23 ],
  [ 1000,50,5,0,3, 45502,80976,9928,-7740, 92694,78335,18539,2872, 270553,106475,54111,32816, 386690,189660,77338,39406, 532293,348295,106459,36800, -2701579,-34,21 ],
  [ 1000,50,10,0,3, 73324,84476,8978,-1366, 188745,95700,15729,7754, 557282,343238,46440,17837, 893473,400889,74456,41049, 1232818,583696,102735,54094, -1471811,-22,-48 ],
  [ 1000,50,15,0,3, 95128,100558,8456,-483, 302489,204611,13750,4449, 928566,422501,42208,23003, 1579622,747073,71801,37843, 2205391,1002832,100245,54662, 132183,8,0 ],
  [ 1000,50,25,0,3, 110640,102514,8047,591, 524895,380238,10993,3029, 1857348,877592,35718,18841, 3396586,1595424,65319,34638, 4993174,2501408,96023,47919, 3768428,45,0 ],
  [ 1000,50,50,0,3, 111949,102680,7996,662, 741532,525156,9347,2727, 3315738,1536836,30143,16172, 6382698,2962607,58025,31092, 10057260,4763291,91430,48127, 10215958,72,0 ],
  [ 1000,50,75,0,3, 111949,102680,7996,662, 745332,525918,9317,2743, 3359954,1542586,30000,16227, 6475643,2975405,57818,31252, 10224263,4802971,91288,48404, 10522814,73,0 ],
  [ 1000,100,1,0,3, 21040,77957,21040,-56917, 26027,52759,26027,-26732, 113143,70615,113143,42527, 90258,36651,90258,53607, 124399,59014,124399,65385, -3494233,-40,26 ],
  [ 1000,100,2,0,3, 31441,79277,15720,-23918, 48230,56513,24115,-4141, 171003,78656,85502,46174, 180516,64713,90258,57902, 248798,77727,124399,85536, -3117285,-37,24 ],
  [ 1000,100,3,0,3, 41841,80596,13947,-12918, 70434,60267,23478,3389, 228864,101211,76288,42551, 270774,77848,90258,64309, 373196,195941,124399,59085, -2822641,-35,22 ],
  [ 1000,100,5,0,3, 57712,82610,12592,-5432, 109590,80944,21918,5729, 335635,115830,67127,43961, 445802,306823,89160,27796, 614462,360788,122892,50735, -2521290,-32,20 ],
  [ 1000,100,10,0,3, 90279,86745,11055,433, 221872,115206,18489,8889, 667271,373282,55606,24499, 1029821,516170,85818,42804, 1422932,741233,118578,56808, -1131100,-17,-33 ],
  [ 1000,100,15,0,3, 115783,103323,10292,1108, 355157,332232,16143,1042, 1097311,536283,49878,25501, 1820756,877539,82762,42874, 2545093,1281787,115686,57423, 300606,12,0 ],
  [ 1000,100,25,0,3, 133906,105628,9739,2057, 616640,411364,12914,4299, 2175960,936714,41845,23832, 3917658,1781147,75340,41087, 5759528,2762666,110760,57632, 5492602,54,0 ],
  [ 1000,100,50,0,3, 135432,105823,9674,2115, 871705,564163,10988,3877, 3875024,1735147,35227,19453, 7370667,3353536,67006,36519, 11596037,5511339,105419,55315, 12859867,80,0 ],
  [ 1000,100,75,0,3, 135432,105823,9674,2115, 876175,565062,10952,3889, 3926645,1741911,35059,19507, 7478300,3383399,66771,36562, 11788506,5638541,105255,54910, 13135301,80,0 ],
  [ 1000,200,1,0,3, 34726,79789,34726,-45063, 36123,53845,36123,-17722, 183843,95410,183843,88434, 114210,55232,114210,58979, 157680,64147,157680,93533, -3284053,-38,25 ],
  [ 1000,200,2,0,3, 48173,81516,24086,-16672, 64598,58678,32299,2960, 257122,105639,128561,75741, 228420,71845,114210,78288, 315360,103594,157680,105883, -2788341,-35,22 ],
  [ 1000,200,3,0,3, 61620,83243,20540,-7208, 93074,77396,31025,5226, 330400,115719,110133,71560, 342630,183742,114210,52963, 473040,324191,157680,49616, -2606034,-33,21 ],
  [ 1000,200,5,0,3, 82134,85878,17920,-817, 143383,86162,28677,11444, 465798,332105,93160,26739, 564027,339756,112805,44854, 778800,401997,155760,75361, -2329216,-31,19 ],
  [ 1000,200,10,0,3, 124188,104620,15207,2396, 288126,200148,24010,7331, 887247,419155,73937,39008, 1302517,586218,108543,59692, 1803158,914106,150263,74088, -278907,-2,0 ],
  [ 1000,200,15,0,3, 157091,108851,13964,4288, 460492,351004,20931,4977, 1434800,719197,65218,32527, 2303025,1167123,104683,51632, 3224496,1628755,146568,72534, 1595470,28,0 ],
  [ 1000,200,25,0,3, 180437,111856,13123,4988, 800131,533580,16757,5582, 2813183,1267190,54100,29731, 4959802,2400064,95381,49226, 7292237,3496342,140235,72998, 7747432,64,0 ],
  [ 1000,200,50,0,3, 182399,112108,13028,5021, 1132052,806491,14270,4104, 4993597,2342518,45396,24101, 9346605,4246655,84969,46363, 14673590,6963004,133396,70096, 17009451,89,0 ],
  [ 1000,200,75,0,3, 182399,112108,13028,5021, 1137860,821551,14223,3954, 5060026,2351160,45179,24186, 9483615,4389156,84675,45486, 14916993,7097640,133187,69816, 17182058,90,0 ],
  [ 1500,0,1,0,3, 11529,76765,11529,-65236, 23238,52992,23238,-29754, 56304,47971,56304,8332, 86865,36288,86865,50577, 119940,58481,119940,61459, -3623411,-41,27 ],
  [ 1500,0,2,0,3, 23057,78316,11529,-27629, 46476,56986,23238,-5255, 112608,70488,56304,21060, 173731,64113,86865,54809, 239881,76780,119940,81550, -3284689,-38,25 ],
  [ 1500,0,3,0,3, 34586,79867,11529,-15094, 69713,60979,23238,2911, 168911,78489,56304,30141, 260596,76912,86865,61228, 359821,194481,119940,55113, -2961270,-36,23 ],
  [ 1500,0,5,0,3, 52187,82235,11386,-6556, 111096,82183,22219,5783, 273623,93176,54725,36089, 428946,305342,85789,24721, 592523,358474,118505,46810, -2668333,-34,21 ],
  [ 1500,0,10,0,3, 88353,100442,10819,-1480, 231892,118353,19324,9462, 603004,351551,50250,20954, 990701,513153,82558,39796, 1372797,736433,114400,53030, -1356548,-20,-44 ],
  [ 1500,0,15,0,3, 116718,104263,10375,1107, 377805,338613,17173,1781, 1034971,531100,47044,22903, 1753957,872650,79725,40059, 2455531,1273353,111615,53735, 71829,7,0 ],
  [ 1500,0,25,0,3, 136922,106987,9958,2177, 671530,425672,14063,5149, 2141642,940627,41185,23096, 3798967,1790484,73057,38625, 5550616,2644612,106743,55885, 5286463,53,0 ],
  [ 1500,0,50,0,3, 138629,107218,9902,2244, 962822,705620,12136,3242, 3928317,1773743,35712,19587, 7222219,3386509,65657,34870, 11167450,5362157,101522,52775, 12274371,78,0 ],
  [ 1500,0,75,0,3, 138629,107218,9902,2244, 967947,706680,12099,3266, 3983477,1887371,35567,18715, 7330172,3402383,65448,35070, 11352906,5518682,101365,52091, 12308548,78,0 ],
  [ 1500,25,1,0,3, 14950,77223,14950,-62273, 25762,53264,25762,-27502, 73979,50541,73979,23438, 92853,37177,92853,55677, 128261,59752,128261,68509, -3556965,-40,26 ],
  [ 1500,25,2,0,3, 27240,78876,13620,-25818, 50568,57527,25284,-3480, 134137,73605,67069,30266, 185707,65884,92853,59912, 256522,79317,128261,88602, -3191284,-38,24 ],
  [ 1500,25,3,0,3, 39531,80529,13177,-13666, 75373,75675,25124,-101, 194296,82004,64765,37431, 278560,79564,92853,66332, 384782,198234,128261,62183, -2884491,-35,23 ],
  [ 1500,25,5,0,3, 58292,83053,12718,-5402, 119544,83487,23909,7211, 306164,112368,61233,38759, 458502,309743,91700,29752, 633607,364771,126721,53767, -2531652,-32,20 ],
  [ 1500,25,10,0,3, 96830,101576,11857,-581, 248455,195014,20705,4453, 657998,373906,54833,23674, 1058875,538153,88240,43394, 1467853,766542,122321,58443, -1331992,-20,-40 ],
  [ 1500,25,15,0,3, 127045,105645,11293,1902, 404139,343306,18370,2765, 1119343,543059,50879,26195, 1874524,905192,85206,44061, 2625382,1314645,119336,59579, 564535,16,0 ],
  [ 1500,25,25,0,3, 148555,108544,10804,2910, 717402,522027,15024,4092, 2300948,1160420,44249,21933, 4059503,1952176,78067,40526, 5933793,2930721,114111,57751, 5243041,53,0 ],
  [ 1500,25,50,0,3, 150371,108789,10741,2970, 1027909,718180,12957,3904, 4207960,1947781,38254,20547, 7716203,3676883,70147,36721, 11936839,5736731,108517,56365, 13304940,81,0 ],
  [ 1500,25,75,0,3, 150371,108789,10741,2970, 1033369,733195,12917,3752, 4266823,2032716,38097,19947, 7831501,3693301,69924,36948, 12135028,5894577,108348,55718, 13389621,81,0 ],
  [ 1500,50,1,0,3, 18371,77681,18371,-59309, 28286,53535,28286,-25250, 91654,67626,91654,24028, 98841,53092,98841,45750, 136581,61073,136581,75509, -3546122,-40,26 ],
  [ 1500,50,2,0,3, 31423,79436,15712,-24006, 54659,58068,27330,-1704, 155667,76722,77833,39472, 197683,67654,98841,65014, 273162,81854,136581,95654, -3097880,-37,24 ],
  [ 1500,50,3,0,3, 44475,81191,14825,-12238, 81033,76486,27011,1516, 219680,85668,73227,44671, 296524,97193,98841,66444, 409743,202036,136581,69236, -2786232,-35,22 ],
  [ 1500,50,5,0,3, 64397,83870,14050,-4249, 127993,84792,25599,8640, 338705,194046,67741,28932, 488058,329071,97612,31797, 674692,371018,134938,60735, -2560071,-33,20 ],
  [ 1500,50,10,0,3, 105308,102711,12895,318, 265019,197824,22085,5600, 712992,381745,59416,27604, 1127049,548126,93921,48244, 1562910,780929,130242,65165, -1006878,-15,-23 ],
  [ 1500,50,15,0,3, 137372,107027,12211,2697, 430473,347999,19567,3749, 1203716,569532,54714,28827, 1995091,922808,90686,48740, 2795232,1340316,127056,66133, 1057018,22,0 ],
  [ 1500,50,25,0,3, 160188,110101,11650,3643, 763275,530648,15985,4872, 2460254,1197314,47313,24287, 4320039,2085216,83078,42977, 6316971,3103291,121480,61802, 6021040,57,0 ],
  [ 1500,50,50,0,3, 162112,110361,11579,3697, 1092996,744626,13777,4391, 4487603,2078104,40796,21905, 8210188,3858246,74638,39563, 12706227,6095034,115511,60102, 14565552,84,0 ],
  [ 1500,50,75,0,3, 162112,110361,11579,3697, 1098790,745824,13735,4412, 4550168,2086696,40626,21995, 8332830,3890685,74400,39662, 12917150,6225490,115332,59747, 14894316,84,0 ],
  [ 1500,100,1,0,3, 25214,78596,25214,-53382, 33333,54078,33333,-20745, 127004,72766,127004,54239, 110817,54869,110817,55949, 153222,63614,153222,89608, -3413230,-39,26 ],
  [ 1500,100,2,0,3, 39790,80555,19895,-20383, 62843,59151,31422,1846, 198726,82956,99363,57885, 221635,71195,110817,75220, 306444,102598,153222,101923, -2922244,-36,23 ],
  [ 1500,100,3,0,3, 54365,82514,18122,-9383, 92353,78109,30784,4748, 270448,107512,90149,54312, 332452,182855,110817,49866, 459665,322782,153222,45628, -2778163,-35,22 ],
  [ 1500,100,5,0,3, 76608,85504,16714,-1941, 144889,87401,28978,11498, 403787,217915,80757,37174, 547171,337824,109434,41869, 756861,399233,151372,71526, -2264382,-30,18 ],
  [ 1500,100,10,0,3, 122263,104980,14971,2116, 298146,203445,24845,7892, 822981,411940,68582,34253, 1263397,583100,105283,56691, 1753023,909206,146085,70318, -483162,-6,0 ],
  [ 1500,100,15,0,3, 158027,109791,14047,4288, 483141,371270,21961,5085, 1372460,713864,62385,29936, 2236225,1096903,101647,51787, 3134934,1620271,142497,68848, 1419529,26,0 ],
  [ 1500,100,25,0,3, 183453,113215,13342,5108, 855021,561773,17906,6141, 2778865,1270952,53440,28998, 4841112,2205657,93098,50682, 7083325,3477689,136218,69339, 7727503,63,0 ],
  [ 1500,100,50,0,3, 185596,113504,13257,5149, 1223170,857483,15418,4609, 5046889,2382464,45881,24222, 9198157,4264652,83620,44850, 14245003,6927362,129500,66524, 16648464,88,0 ],
  [ 1500,100,75,0,3, 185596,113504,13257,5149, 1229633,858818,15370,4635, 5116858,2392071,45686,24328, 9335487,4407640,83353,43999, 14481393,6977780,129298,66997, 16927304,89,0 ],
  [ 1500,200,1,0,3, 38900,80428,38900,-41528, 43429,55164,43429,-11735, 197705,97560,197705,100145, 134769,58472,134769,76297, 186503,68747,186503,117756, -3180946,-38,24 ],
  [ 1500,200,2,0,3, 56522,82795,28261,-13136, 79211,61316,39606,8947, 284844,109939,142422,87452, 269539,78326,134769,95606, 373006,196575,186503,88216, -2641861,-33,21 ],
  [ 1500,200,3,0,3, 74144,85161,24715,-3672, 114993,81353,38331,11213, 371984,213684,123995,52767, 404308,193514,134769,70265, 559509,353713,186503,68599, -2517884,-32,20 ],
  [ 1500,200,5,0,3, 101030,102109,22043,-236, 178681,92468,35736,17242, 533950,357190,106790,35352, 665396,355930,133079,61893, 921199,440592,184240,96121, -1928399,-27,15 ],
  [ 1500,200,10,0,3, 156172,109518,19123,5713, 364399,319037,30367,3780, 1042957,533313,86913,42470, 1536094,746481,128008,65801, 2133249,997648,177771,94633, 76149,7,0 ],
  [ 1500,200,15,0,3, 199335,115320,17719,7468, 588476,390042,26749,9020, 1709949,867729,77725,38283, 2718493,1277528,123568,65498, 3814337,1869319,173379,88410, 3012806,40,0 ],
  [ 1500,200,25,0,3, 229985,119442,16726,8039, 1038511,714490,21749,6786, 3416088,1585413,65694,35205, 5883256,2809548,113140,59110, 8616034,4226486,165693,84414, 9933922,71,0 ],
  [ 1500,200,50,0,3, 232562,119789,16612,8055, 1483517,921611,18700,7083, 6165462,2885286,56050,29820, 11174096,5265731,101583,53712, 17322556,8295247,157478,82066, 21503488,98,0 ],
  [ 1500,200,75,0,3, 232562,119789,16612,8055, 1491318,937106,18641,6928, 6250239,2896770,55806,29942, 11340803,5289460,101257,54030, 17609880,8549970,157231,80892, 21881387,98,0 ],
  [ 2000,0,1,0,3, 15703,77404,15703,-61701, 30544,54311,30544,-23767, 70165,50122,70165,20044, 107425,54506,107425,52919, 148763,63081,148763,85682, -3542407,-40,26 ],
  [ 2000,0,2,0,3, 31406,79595,15703,-24094, 61089,59624,30544,732, 140330,74788,70165,32771, 214849,70595,107425,72127, 297527,101652,148763,97937, -3089648,-37,24 ],
  [ 2000,0,3,0,3, 47109,81785,15703,-11559, 91633,78821,30544,4271, 210496,84790,70165,41902, 322274,181918,107425,46785, 446290,321322,148763,41656, -2916793,-36,23 ],
  [ 2000,0,5,0,3, 71082,85129,15509,-3065, 146395,88639,29279,11551, 341775,195261,68355,29303, 530314,336343,106063,38794, 734922,396919,146984,67601, -2411425,-31,19 ],
  [ 2000,0,10,0,3, 120337,105340,14735,1836, 308166,206742,25680,8452, 758714,390209,63226,30709, 1224277,579932,102023,53695, 1702888,904256,141907,66553, -653916,-10,0 ],
  [ 2000,0,15,0,3, 158962,110732,14130,4287, 505789,377652,22990,5824, 1310120,694167,59551,27998, 2169425,1091964,98610,48976, 3045372,1498696,138426,70303, 1304884,25,0 ],
  [ 2000,0,25,0,3, 186469,114574,13561,5229, 909910,680431,19056,4806, 2744548,1273364,52780,28292, 4722421,2200018,90816,48508, 6874413,3458586,132200,65689, 7145938,61,0 ],
  [ 2000,0,50,0,3, 188793,114899,13485,5278, 1314287,894589,16567,5290, 5100182,2422561,46365,24342, 9049709,4282649,82270,43337, 13816417,6807990,125604,63713, 16390796,88,0 ],
  [ 2000,0,75,0,3, 188793,114899,13485,5278, 1321406,896086,16518,5316, 5173691,2447496,46194,24341, 9187359,4426173,82030,42511, 14045793,6941751,125409,63429, 16579316,88,0 ],
  [ 2000,25,1,0,3, 19124,77862,19124,-58738, 33068,54583,33068,-21515, 87840,67206,87840,20634, 113413,55444,113413,57969, 157084,64402,157084,92682, -3509461,-40,26 ],
  [ 2000,25,2,0,3, 35589,80154,17795,-22283, 65181,60165,32590,2508, 161860,77905,80930,41977, 226825,72365,113413,77230, 314167,104189,157084,104989, -2996244,-36,23 ],
  [ 2000,25,3,0,3, 52054,82447,17351,-10131, 97293,79632,32431,5887, 235880,88454,78627,49142, 340238,184570,113413,51889, 471251,325125,157084,48709, -2796430,-35,22 ],
  [ 2000,25,5,0,3, 77188,85946,16841,-1911, 154843,89944,30969,12980, 374316,199938,74863,34876, 559871,340694,111974,43835, 776006,403166,155201,74568, -2241244,-30,18 ],
  [ 2000,25,10,0,3, 128814,106474,15773,2736, 324729,313902,27061,902, 813708,411063,67809,33554, 1292451,698416,107704,49503, 1797945,918193,149829,73313, -848998,-13,0 ],
  [ 2000,25,15,0,3, 169289,112114,15048,5082, 532123,382345,24187,6808, 1394493,720640,63386,30630, 2289992,1189888,104091,50005, 3215223,1653129,146146,71004, 1587038,28,0 ],
  [ 2000,25,25,0,3, 198102,116131,14407,5962, 955782,702937,20016,5295, 2903853,1387108,55843,29168, 4982957,2441518,95826,48874, 7257590,3547325,139569,71351, 7603517,63,0 ],
  [ 2000,25,50,0,3, 200534,116470,14324,6005, 1379374,907150,17387,5952, 5379825,2490548,48908,26266, 9543694,4587499,86761,45056, 14585805,7068973,132598,68335, 17725270,91,0 ],
  [ 2000,25,75,0,3, 200534,116470,14324,6005, 1386827,922600,17335,5803, 5457036,2578326,48724,25703, 9688688,4608581,86506,45358, 14827915,7317596,132392,67056, 17853963,91,0 ],
  [ 2000,50,1,0,3, 22546,78320,22546,-55774, 35592,54854,35592,-19262, 105515,69776,105515,35739, 119401,56333,119401,63068, 165404,65673,165404,99731, -3443015,-39,26 ],
  [ 2000,50,2,0,3, 39772,80714,19886,-20471, 69272,60706,34636,4283, 183390,81022,91695,51184, 238801,74136,119401,82333, 330808,190506,165404,70151, -2962574,-36,23 ],
  [ 2000,50,3,0,3, 56999,83109,19000,-8703, 102953,80444,34318,7503, 261264,106633,87088,51544, 358202,187273,119401,56976, 496212,344649,165404,50521, -2720741,-34,22 ],
  [ 2000,50,5,0,3, 83293,86764,18173,-757, 163291,91248,32658,14408, 406857,204616,81371,40448, 589427,345046,117885,48876, 817091,409413,163418,81536, -2071062,-28,17 ],
  [ 2000,50,10,0,3, 137292,107609,16811,3635, 341293,316713,28441,2048, 868702,418903,72392,37483, 1360625,708390,113385,54353, 1893001,948252,157750,78729, -535058,-7,0 ],
  [ 2000,50,15,0,3, 179617,113496,15966,5877, 558457,387038,25384,7792, 1478865,732599,67221,33921, 2410559,1222430,109571,54006, 3385073,1694421,153867,76848, 2079744,32,0 ],
  [ 2000,50,25,0,3, 209735,117688,15253,6694, 1001655,711557,20977,6075, 3063159,1530052,58907,29483, 5243493,2509727,100836,52572, 7640768,3833485,146938,73217, 8151927,65,0 ],
  [ 2000,50,50,0,3, 212276,118042,15163,6731, 1444460,933596,18207,6439, 5659468,2726921,51450,26660, 10037679,4769362,91252,47894, 15355193,7511557,139593,71306, 18680771,92,0 ],
  [ 2000,50,75,0,3, 212276,118042,15163,6731, 1452248,935230,18153,6463, 5740382,2738356,51253,26804, 10190017,4914925,90982,47099, 15610037,7693991,139375,70679, 18921135,93,0 ],
  [ 2000,100,1,0,3, 29389,79236,29389,-49847, 40640,55397,40640,-14757, 140866,74916,140866,65950, 131377,58109,131377,73267, 182045,68214,182045,113830, -3310123,-39,25 ],
  [ 2000,100,2,0,3, 48138,81834,24069,-16848, 77456,75674,38728,891, 226449,101621,113224,62414, 262753,77726,131377,92513, 364089,195579,182045,84255, -2852848,-35,22 ],
  [ 2000,100,3,0,3, 66888,84432,22296,-5848, 114273,82066,38091,10736, 312032,113962,104011,66023, 394130,192578,131377,67184, 546134,352254,182045,64627, -2480016,-32,20 ],
  [ 2000,100,5,0,3, 95504,101734,20837,-1359, 180187,93707,36037,17296, 471939,334536,94388,27481, 648540,354449,129708,58818, 899260,438278,179852,92196, -2075443,-28,17 ],
  [ 2000,100,10,0,3, 154246,109878,18887,5433, 374420,336219,31202,3183, 978691,525947,81558,37729, 1496974,743363,124748,62801, 2083114,992698,173593,90868, -171689,1,0 ],
  [ 2000,100,15,0,3, 200271,116260,17802,7468, 611125,410309,27778,9128, 1647609,847881,74891,36351, 2651693,1272589,120532,62687, 3724775,1845114,169308,85439, 2785118,38,0 ],
  [ 2000,100,25,0,3, 233001,120801,16945,8160, 1093401,742683,22898,7345, 3381770,1603690,65034,34194, 5764565,2803959,110857,56935, 8407122,4207883,161675,80755, 9601895,70,0 ],
  [ 2000,100,50,0,3, 235759,121185,16840,8184, 1574634,1150803,19848,5342, 6218755,2923732,56534,29955, 11025648,5283728,100233,52199, 16893970,8259105,153582,78499, 20582100,96,0 ],
  [ 2000,100,75,0,3, 235759,121185,16840,8184, 1583091,1152574,19789,5381, 6307072,2936181,56313,30097, 11192675,5322920,99935,52409, 17174281,8316520,153342,79087, 21124759,97,0 ],
  [ 2000,200,1,0,3, 43074,81067,43074,-37993, 50736,56483,50736,-5747, 211566,99710,211566,111856, 155329,61713,155329,93615, 215326,73347,215326,141979, -3077839,-37,24 ],
  [ 2000,200,2,0,3, 64871,84073,32435,-9601, 93824,77839,46912,7992, 312567,114090,156284,99239, 310657,99785,155329,105436, 430652,318816,215326,55918, -2581966,-33,21 ],
  [ 2000,200,3,0,3, 86667,87079,28889,-137, 136912,85310,45637,17201, 413569,220135,137856,64478, 465986,311697,155329,51430, 645978,367514,215326,92821, -2368637,-31,19 ],
  [ 2000,200,5,0,3, 119925,105003,26165,3256, 213979,98925,42796,23011, 602102,367760,120420,46868, 766765,386881,153353,75977, 1063598,562767,212720,100166, -1521228,-22,11 ],
  [ 2000,200,10,0,3, 188156,114416,23039,9029, 440673,347460,36723,7768, 1198667,571821,99889,52237, 1769670,878592,147472,74257, 2463341,1278511,205278,98736, 791925,18,0 ],
  [ 2000,200,15,0,3, 241579,121789,21474,10648, 716460,502931,32566,9706, 1985098,924745,90232,48198, 3133962,1561673,142453,71468, 4404178,2207253,200190,99860, 4080405,46,0 ],
  [ 2000,200,25,0,3, 279532,140366,20330,10121, 1276892,864749,26741,8631, 4018993,1919650,77288,40372, 6806710,3299540,130898,67446, 9939831,4828568,191151,98294, 12098341,77,0 ],
  [ 2000,200,50,0,3, 282726,140807,20195,10137, 1834981,1228666,23130,7643, 7337328,3426403,66703,35554, 13001586,6161521,118196,62182, 19971523,9710870,181559,93279, 25461291,104,0 ],
  [ 2000,200,75,0,3, 282726,140807,20195,10137, 1844777,1230712,23060,7676, 7440453,3546930,66433,34764, 13197990,6285698,117839,61717, 20302768,9905030,181275,92837, 25649074,105,0 ],
  [ 2500,0,1,0,3, 19878,78044,19878,-58166, 37851,55630,37851,-17780, 84027,52272,84027,31755, 127984,57746,127984,70237, 177586,67682,177586,109905, -3439300,-39,26 ],
  [ 2500,0,2,0,3, 39755,80873,19878,-20559, 75702,76147,37851,-223, 168053,78938,84027,44557, 255968,77077,127984,89446, 355173,194583,177586,80295, -2986752,-36,23 ],
  [ 2500,0,3,0,3, 59633,83703,19878,-8023, 113552,82778,37851,10258, 252080,91240,84027,53613, 383952,191641,127984,64104, 532759,350794,177586,60655, -2618645,-33,21 ],
  [ 2500,0,5,0,3, 89978,101360,19632,-2483, 181693,94946,36339,17349, 409927,205831,81985,40819, 631683,352467,126337,55843, 877321,435464,175464,88371, -1977109,-27,16 ],
  [ 2500,0,10,0,3, 152321,110238,18651,5153, 384440,339515,32037,3744, 914424,427216,76202,40601, 1457853,740196,121488,59805, 2032980,987748,169415,87103, -165945,2,0 ],
  [ 2500,0,15,0,3, 201206,117201,17885,7467, 633773,416690,28808,9867, 1585270,842698,72058,33753, 2584894,1267700,117495,59872, 3635213,1836680,165237,81751, 2556340,36,0 ],
  [ 2500,0,25,0,3, 236016,122161,17165,8280, 1148290,830841,24048,6648, 3347453,1607602,64374,33459, 5645875,2798319,108575,54761, 8198210,4060519,157658,79571, 9204976,69,0 ],
  [ 2500,0,50,0,3, 238956,135916,17068,7360, 1665751,1187759,20997,6025, 6272047,2963828,57019,30075, 10877200,5301874,98884,50685, 16465383,8223663,149685,74925, 20210004,95,0 ],
  [ 2500,0,75,0,3, 238956,135916,17068,7360, 1674864,1189692,20936,6065, 6363905,3097656,56821,29163, 11044547,5342104,98612,50915, 16738681,8281141,149453,75514, 20476700,96,0 ],
  [ 2500,25,1,0,3, 23299,78501,23299,-55202, 40375,55902,40375,-15527, 101702,69356,101702,32345, 133972,58685,133972,75287, 185907,69002,185907,116904, -3406355,-39,26 ],
  [ 2500,25,2,0,3, 43938,81433,21969,-18747, 79794,76688,39897,1553, 189583,82055,94791,53764, 267944,78847,133972,94548, 371813,197119,185907,87347, -2893348,-35,23 ],
  [ 2500,25,3,0,3, 64577,84365,21526,-6596, 119212,83590,39737,11874, 277464,109419,92488,56015, 401916,194343,133972,69191, 557720,354647,185907,67691, -2531783,-33,20 ],
  [ 2500,25,5,0,3, 96083,102177,20964,-1330, 190141,96251,38028,18778, 442468,331074,88494,22279, 661239,372345,132248,57779, 918405,442261,183681,95229, -2107909,-29,17 ],
  [ 2500,25,10,0,3, 160798,111372,19690,6052, 401003,342326,33417,4890, 969418,512056,80785,38114, 1526028,750169,127169,64655, 2128036,1002136,177336,93825, -17329,5,0 ],
  [ 2500,25,15,0,3, 211534,118583,18803,8262, 660107,421383,30005,10851, 1669642,854657,75893,37045, 2705461,1300242,122975,63874, 3805064,1877972,172957,87595, 3049047,40,0 ],
  [ 2500,25,25,0,3, 247649,137054,18011,8043, 1194162,853196,25009,7141, 3506759,1721346,67438,34335, 5906411,2866178,113585,58466, 8581387,4262498,165027,83056, 9783724,71,0 ],
  [ 2500,25,50,0,3, 250698,137488,17907,8086, 1730838,1214205,21817,6512, 6551691,3137716,59561,31036, 11371184,5483788,103374,53522, 17234771,8582516,156680,78657, 21368236,97,0 ],
  [ 2500,25,75,0,3, 250698,137488,17907,8086, 1740285,1216206,21754,6551, 6647250,3228486,59350,30525, 11545875,5524511,103088,53762, 17520802,8656986,156436,79141, 21751347,98,0 ],
  [ 2500,50,1,0,3, 26720,78959,26720,-52239, 42899,56173,42899,-13275, 119377,71926,119377,47451, 139960,59573,139960,80386, 194227,70273,194227,123954, -3339908,-39,25 ],
  [ 2500,50,2,0,3, 48121,81993,24061,-16936, 83885,77229,41943,3328, 211113,85172,105556,62970, 279920,95594,139960,92163, 388454,199656,194227,94399, -2822047,-35,22 ],
  [ 2500,50,3,0,3, 69522,85027,23174,-5168, 124872,84401,41624,13491, 302848,113084,100949,63255, 419880,305455,139960,38141, 582681,358449,194227,74744, -2571494,-33,20 ],
  [ 2500,50,5,0,3, 102189,102994,22296,-176, 198589,97555,39718,20207, 475009,335751,95002,27852, 690796,376697,138159,62820, 959490,532288,191898,85440, -1997461,-28,16 ],
  [ 2500,50,10,0,3, 169275,112507,20728,6951, 417566,345136,34797,6036, 1024412,534411,85368,40833, 1594202,855477,132850,61560, 2223093,1145335,185258,89813, 41851,6,0 ],
  [ 2500,50,15,0,3, 221861,119965,19721,9057, 686441,426076,31202,11835, 1754014,881130,79728,39677, 2826028,1317858,128456,68553, 3974915,2032405,180678,88296, 3449725,42,0 ],
  [ 2500,50,25,0,3, 259282,138611,18857,8776, 1240035,861816,25969,7921, 3666064,1743725,70501,36968, 6166947,2999168,118595,60919, 8964565,4435017,172395,87107, 10595224,73,0 ],
  [ 2500,50,50,0,3, 262440,139059,18746,8813, 1795925,1226766,22638,7174, 6831334,3268189,62103,32392, 11865169,5788588,107865,55242, 18004160,8843450,163674,83279, 22559713,100,0 ],
  [ 2500,50,75,0,3, 262440,139059,18746,8813, 1805707,1228836,22571,7211, 6930595,3296981,61880,32443, 12047204,5830406,107564,55507, 18302924,9032931,163419,82768, 23030397,100,0 ],
  [ 2500,100,1,0,3, 33563,79875,33563,-46312, 47946,56716,47946,-8770, 154727,77066,154727,77661, 151936,61350,151936,90586, 210868,72815,210868,138053, -3207016,-38,24 ],
  [ 2500,100,2,0,3, 56487,83113,28244,-13313, 92069,78312,46035,6879, 254172,105922,127086,74125, 303872,99185,151936,102344, 421735,204779,210868,108478, -2668738,-34,21 ],
  [ 2500,100,3,0,3, 79412,86350,26471,-2313, 136192,86023,45397,16723, 353616,197413,117872,52068, 455808,310760,151936,48349, 632603,366054,210868,88850, -2507267,-32,20 ],
  [ 2500,100,5,0,3, 114399,104628,24960,2132, 215485,100164,43097,23064, 540091,345106,108018,38997, 749908,385400,149982,72902, 1041659,560453,208332,96241, -1668271,-24,13 ],
  [ 2500,100,10,0,3, 186230,114776,22804,8749, 450693,350757,37558,8328, 1134401,564605,94533,47483, 1730550,875474,144212,71256, 2413206,1273611,201100,94966, 587671,16,0 ],
  [ 2500,100,15,0,3, 242515,122729,21557,10648, 739109,523197,33596,9814, 1922759,919413,87398,45607, 3067162,1556784,139416,68654, 4314616,2198769,196119,96175, 3808044,45,0 ],
  [ 2500,100,25,0,3, 282548,141725,20549,10242, 1331781,892942,27891,9190, 3984676,1923413,76628,39640, 6688019,3308877,128616,64984, 9730919,4809915,187133,94635, 11777711,76,0 ],
  [ 2500,100,50,0,3, 285923,142202,20423,10266, 1926098,1265772,24279,8323, 7390620,3572550,67187,34710, 12853138,6180017,116847,60665, 19542936,9591998,177663,90463, 24958244,103,0 ],
  [ 2500,100,75,0,3, 285923,142202,20423,10266, 1936549,1267980,24207,8357, 7497286,3602356,66940,34776, 13049862,6304232,116517,60229, 19867168,9853330,177385,89409, 25312259,104,0 ],
  [ 2500,200,1,0,3, 47249,81707,47249,-34458, 58042,57802,58042,240, 225427,101860,225427,123568, 175888,64954,175888,110934, 244149,77948,244149,166201, -2974732,-36,23 ],
  [ 2500,200,2,0,3, 73220,85352,36610,-6066, 108437,80477,54219,13980, 340290,132905,170145,103693, 351776,186624,175888,82576, 488298,343738,244149,72280, -2538950,-33,20 ],
  [ 2500,200,3,0,3, 96361,101204,32120,-1614, 160391,89101,53464,23763, 403123,315277,134374,29282, 446239,191824,148746,84805, 617547,344304,205849,91081, -2426155,-32,19 ],
  [ 2500,200,5,0,3, 138821,107897,30288,6747, 249277,119267,49855,26002, 670254,378181,134051,58415, 868133,417832,173627,90060, 1205997,585441,241199,124111, -1086732,-17,-36 ],
  [ 2500,200,10,0,3, 220140,119314,26956,12346, 516947,375884,43079,11755, 1354378,716378,112865,53167, 2003246,945871,166937,88115, 2793432,1363004,232786,119202, 1499111,27,0 ],
  [ 2500,200,15,0,3, 283824,141594,25229,12643, 844444,541820,38384,13756, 2260248,1179327,102739,49133, 3549430,1753035,161338,81654, 4994020,2545887,227001,111279, 5009687,51,0 ],
  [ 2500,200,25,0,3, 329079,219652,23933,7958, 1515272,1045659,31733,9835, 4621898,2146338,88883,47607, 7730163,3723550,148657,77050, 11263628,5558262,216608,109719, 14180622,83,0 ],
  [ 2500,200,50,0,3, 332889,220188,23778,8050, 2186446,1521986,27560,8376, 8509193,4073721,77356,40322, 14829077,7072637,134810,70513, 22620489,11156204,205641,104221, 28935006,109,0 ],
  [ 2500,200,75,0,3, 332889,220188,23778,8050, 2198235,1524468,27478,8422, 8630667,4091040,77060,40532, 15055177,7200979,134421,70127, 22995655,11327550,205318,104180, 29492810,110,0 ],
  [ 3000,0,1,0,3, 24052,78683,24052,-54631, 45157,56949,45157,-11792, 97888,68937,97888,28951, 148543,61037,148543,87506, 206409,72332,206409,134078, -3369694,-39,25 ],
  [ 3000,0,2,0,3, 48104,82152,24052,-17024, 90315,78785,45157,5765, 195776,83239,97888,56269, 297086,98535,148543,99276, 412819,203783,206409,104518, -2802642,-35,22 ],
  [ 3000,0,3,0,3, 72156,85621,24052,-4488, 135472,86735,45157,16245, 293664,112205,97888,60486, 445629,309873,148543,45252, 619228,364645,206409,84861, -2502899,-32,20 ],
  [ 3000,0,5,0,3, 108873,104254,23754,1008, 216991,115288,43398,20341, 478079,336817,95616,28253, 733052,383969,146610,69817, 1019720,542468,203944,95450, -1881226,-26,15 ],
  [ 3000,0,10,0,3, 184304,115136,22568,8470, 460713,354054,38393,8888, 1070134,542874,89178,43938, 1691430,872307,140952,68260, 2363071,1268661,196923,91201, 416917,13,0 ],
  [ 3000,0,15,0,3, 243451,137006,21640,9462, 761757,529429,34625,10560, 1860419,899715,84565,43668, 3000362,1551995,136380,65835, 4225054,2190435,192048,92483, 3558074,43,0 ],
  [ 3000,0,25,0,3, 285564,143084,20768,10362, 1386670,907250,29040,10040, 3950358,1927325,75968,38904, 6569329,3222930,126333,64354, 9522007,4791313,183116,90975, 11641292,76,0 ],
  [ 3000,0,50,0,3, 289120,143597,20651,10394, 2017216,1376729,25427,8073, 7443913,3612646,67672,34830, 12704690,6198014,115497,59152, 19114350,9556406,173767,86890, 24407061,103,0 ],
  [ 3000,0,75,0,3, 289120,143597,20651,10394, 2028322,1379098,25354,8115, 7554119,3643266,67447,34918, 12901734,6322715,115194,58741, 19431568,9620380,173496,87600, 24904356,103,0 ],
  [ 3000,25,1,0,3, 27473,79141,27473,-51667, 47681,57221,47681,-9540, 115563,71507,115563,44057, 154531,61926,154531,92605, 214730,73602,214730,141127, -3303248,-38,25 ],
  [ 3000,25,2,0,3, 52287,82712,26144,-15212, 94407,79326,47203,7540, 217306,86356,108653,65475, 309062,100305,154531,104378, 429459,319410,214730,55024, -2789869,-35,22 ],
  [ 3000,25,3,0,3, 77101,86283,25700,-3061, 141132,87547,47044,17862, 319048,192870,106349,42060, 463593,327502,154531,45364, 644189,368447,214730,91914, -2581137,-33,21 ],
  [ 3000,25,5,0,3, 114979,105071,25086,2162, 225439,116592,45088,21769, 510620,341494,102124,33825, 762608,388320,152522,74858, 1060805,564386,212161,99284, -1722217,-25,13 ],
  [ 3000,25,10,0,3, 192782,116270,23606,9369, 477277,370749,39773,8877, 1125128,550564,93761,47880, 1759604,897257,146634,71862, 2458128,1282999,204844,97927, 676343,17,0 ],
  [ 3000,25,15,0,3, 253778,138388,22558,10257, 788091,534122,35822,11544, 1944791,926188,88400,46300, 3120929,1584588,141860,69834, 4394905,2231777,199768,98324, 4017280,46,0 ],
  [ 3000,25,25,0,3, 297197,144641,21614,11095, 1432543,929756,30001,10530, 4109664,2041069,79032,39781, 6829865,3355970,131344,66806, 9905184,4963832,190484,95026, 12199210,77,0 ],
  [ 3000,25,50,0,3, 300862,216869,21490,5999, 2082302,1507525,26248,7245, 7723556,3755984,70214,36069, 13198675,6394404,119988,61857, 19883738,9914259,180761,90632, 24991464,104,0 ],
  [ 3000,25,75,0,3, 300862,216869,21490,5999, 2093743,1509962,26172,7297, 7837464,3772596,69977,36293, 13403063,6548252,119670,61204, 20213690,10079506,180479,90484, 25435010,104,0 ],
  [ 3000,50,1,0,3, 30895,79599,30895,-48704, 50205,57492,50205,-7287, 133238,74076,133238,59162, 160519,62814,160519,97705, 223050,74873,223050,148177, -3236801,-38,25 ],
  [ 3000,50,2,0,3, 56470,83272,28235,-13401, 98498,79868,49249,9315, 238835,103988,119418,67424, 321038,182433,160519,69303, 446100,321997,223050,62051, -2848489,-35,22 ],
  [ 3000,50,3,0,3, 82046,86945,27349,-1633, 146792,88358,48931,19478, 344432,196534,114811,49299, 481557,330155,160519,50468, 669150,387921,223050,93743, -2471948,-32,20 ],
  [ 3000,50,5,0,3, 121084,105888,26418,3316, 233887,117897,46777,23198, 543161,346171,108632,39398, 792164,392672,158433,79899, 1101889,570633,220378,106251, -1552036,-23,11 ],
  [ 3000,50,10,0,3, 201259,117405,24644,10268, 493840,373560,41153,10023, 1180122,572918,98344,50600, 1827778,907280,152315,76708, 2553184,1313108,212765,103340, 956783,21,0 ],
  [ 3000,50,15,0,3, 264105,139771,23476,11052, 814425,538815,37019,12528, 2029164,952662,92235,48932, 3241496,1617180,147341,73833, 4564756,2257448,207489,104878, 4487659,49,0 ],
  [ 3000,50,25,0,3, 308829,217898,22460,6613, 1478415,938376,30962,11310, 4268970,2077962,82096,42135, 7090401,3408702,136354,70802, 10288362,5165711,197853,98513, 12780852,79,0 ],
  [ 3000,50,50,0,3, 312603,218440,22329,6726, 2147389,1520086,27068,7907, 8003199,3915507,72756,37161, 13692659,6684727,124479,63708, 20653126,10288783,187756,94221, 26055532,105,0 ],
  [ 3000,50,75,0,3, 312603,218440,22329,6726, 2159165,1522592,26990,7957, 8120809,3947141,72507,37265, 13904391,6731210,124146,64046, 20995811,10455950,187463,94106, 26650860,106,0 ],
  [ 3000,100,1,0,3, 37738,80514,37738,-42777, 55253,58035,55253,-2782, 168588,79216,168588,89373, 172495,64591,172495,107904, 239691,77415,239691,162276, -3103909,-37,24 ],
  [ 3000,100,2,0,3, 64836,84391,32418,-9777, 106682,80950,53341,12866, 281895,110222,140947,85836, 344990,185974,172495,79508, 479381,342741,239691,68320, -2672854,-34,21 ],
  [ 3000,100,3,0,3, 91935,101605,30645,-3223, 158112,89830,52704,22761, 395201,218378,131734,58941, 517485,335659,172495,60609, 719072,395726,239691,107782, -2319416,-31,19 ],
  [ 3000,100,5,0,3, 133295,107522,29083,5623, 250784,194356,50157,11286, 608243,370041,121649,47640, 851277,416401,170255,86975, 1184058,583177,236812,120176, -1501057,-22,-54 ],
  [ 3000,100,10,0,3, 133295,107522,16322,3156, 250784,194356,20899,4702, 1090461,546562,90872,45325, 881593,436317,73466,37106, 1228410,621796,102368,50551, -781147,-12,0 ],
  [ 3000,100,15,0,3, 284759,142535,25312,12642, 867093,562086,39413,13864, 2197908,1173994,99905,46542, 3482631,1748146,158301,78840, 4904457,2537403,222930,107593, 4737326,50,0 ],
  [ 3000,100,25,0,3, 332095,221012,24152,8079, 1570161,1147702,32883,8847, 4587581,2150100,88223,46875, 7611473,3717911,146374,74876, 11054716,5523938,212591,106361, 13659487,81,0 ],
  [ 3000,100,50,0,3, 336086,221583,24006,8179, 2277563,1559092,28709,9056, 8562486,4113817,77841,40442, 14680629,7090633,133460,69000, 22191903,11007521,201745,101676, 28698236,109,0 ],
  [ 3000,100,75,0,3, 336086,221583,24006,8179, 2290008,1561736,28625,9103, 8687500,4146466,77567,40545, 14907049,7219512,133099,68639, 22560055,11207740,201429,101360, 29204556,110,0 ],
  [ 3000,200,1,0,3, 51423,82346,51423,-30923, 65349,59121,65349,6228, 239289,104010,239289,135279, 196447,68195,196447,128252, 272972,82548,272972,190424, -2871625,-35,22 ],
  [ 3000,200,2,0,3, 81569,86631,40784,-2531, 123050,83115,61525,19967, 368013,214205,184007,76904, 392894,193106,196447,99894, 545944,352938,272972,96503, -2509234,-32,20 ],
  [ 3000,200,3,0,3, 111714,104252,37238,2487, 180751,93075,60250,29226, 496737,339085,165579,52551, 589342,346769,196447,80858, 818916,411436,272972,135827, -2083343,-28,17 ],
  [ 3000,200,5,0,3, 157716,110791,34411,10238, 284576,199573,56915,17000, 738406,403266,147681,67028, 969502,514165,193900,91067, 1348397,736976,269679,122284, -1064160,-17,-34 ],
  [ 3000,200,10,0,3, 157716,110791,19312,5746, 284576,199573,23715,7084, 738406,403266,61534,27928, 1002199,534360,83517,38987, 1397442,776255,116453,51766, -1038746,-16,-33 ],
  [ 3000,200,15,0,3, 326068,219763,28984,9449, 972428,685208,44201,13055, 2535397,1249358,115245,58456, 3964899,1956923,180223,91272, 5583861,2785951,253812,127178, 5844945,56,0 ],
  [ 3000,200,25,0,3, 378626,328106,27536,3674, 1753652,1194568,36726,11709, 5224804,2466061,100477,53053, 8653617,4214842,166416,85361, 12587425,6245425,242066,121962, 15959826,87,0 ],
  [ 3000,200,50,0,3, 383053,328736,27361,3880, 2537910,1709455,31990,10443, 9681058,4616489,88010,46042, 16656567,7984753,151423,78835, 25269456,12489946,229722,116177, 32941663,115,0 ],
  [ 3000,200,75,0,3, 383053,328736,27361,3880, 2551693,1712374,31896,10491, 9820881,4742700,87686,45341, 16912364,8226719,151003,77550, 25688542,12668789,229362,116248, 33197761,115,0 ],
  [ 1000,0,1,1,3, 13074,76835,13074,-63761, 25506,53178,25506,-27672, 61117,48397,61117,12720, 90250,36506,90250,53744, 119011,58065,119011,60946, -3596405,-40,27 ],
  [ 1000,0,2,1,3, 26148,76835,6537,-31880, 25506,57351,12753,-15922, 61117,71282,30559,-5083, 183291,64491,91646,59400, 241702,75887,120851,82907, -3437840,-39,26 ],
  [ 1000,0,3,1,3, 39222,80076,13074,-13618, 76519,75423,25506,365, 183351,79617,61117,34578, 270750,77564,90250,64395, 357032,193183,119011,54616, -2923838,-36,23 ],
  [ 1000,0,5,1,3, 59184,82552,12913,-5098, 121519,83015,24304,7701, 296535,109653,59307,37376, 446719,306578,89344,28028, 588761,356555,117752,46441, -2572865,-33,21 ],
  [ 1000,0,10,1,3, 100212,100983,12271,-94, 250664,119677,20889,10916, 650144,355124,54179,24585, 1038352,516568,86529,43482, 1369737,732449,114145,53107, -1089114,-17,-32 ],
  [ 1000,0,15,1,3, 132398,104983,11769,2437, 404246,340172,18375,2912, 1111084,536158,50504,26133, 1843762,879093,83807,43849, 2459191,1251552,111781,54893, 515970,15,0 ],
  [ 1000,0,25,1,3, 155333,107839,11297,3454, 706941,426669,14805,5870, 2279306,961037,43833,25351, 3996669,1803138,76859,42183, 5611198,2636841,107908,57199, 6057865,57,0 ],
  [ 1000,0,50,1,3, 157272,108082,11234,3514, 1003661,705483,12651,3759, 4143655,1775558,37670,21528, 7572897,3402776,68845,37910, 11396218,5342647,103602,55032, 13636961,81,0 ],
  [ 1000,0,75,1,3, 157272,108082,11234,3514, 1008880,706524,12611,3779, 4200804,1903469,37507,20512, 7684981,3418659,68616,38092, 11588134,5499435,103465,54363, 13653698,81,0 ],
  [ 1000,25,1,1,3, 17974,77490,17974,-59516, 29290,53602,29290,-24312, 83252,66128,83252,17124, 98400,37765,98400,60635, 129878,59776,129878,70102, -3541709,-40,26 ],
  [ 1000,25,2,1,3, 32403,79292,16201,-23445, 57305,58204,28653,-449, 149920,75343,74960,37288, 196800,66959,98400,64921, 259756,79263,129878,90246, -3103329,-37,24 ],
  [ 1000,25,3,1,3, 46831,81095,15610,-11421, 85321,76690,28440,2877, 216587,84409,72196,44059, 295201,81176,98400,71342, 389634,198152,129878,63827, -2757336,-34,22 ],
  [ 1000,25,5,1,3, 68856,83846,15023,-3271, 134800,85060,26960,9948, 340140,192913,68028,29446, 487026,312515,97405,34902, 642499,364729,128500,55554, -2510665,-32,20 ],
  [ 1000,25,10,1,3, 114101,102841,13972,1379, 277082,197982,23090,6592, 726601,380525,60550,28840, 1131854,545277,94321,48881, 1494610,767074,124551,60628, -927209,-14,-20 ],
  [ 1000,25,15,1,3, 149575,107282,13296,3759, 446607,347678,20300,4497, 1230895,553121,55950,30808, 2009859,918273,91357,49618, 2683076,1300999,121958,62822, 1234681,24,0 ],
  [ 1000,25,25,1,3, 174831,110449,12715,4682, 781581,528386,16368,5303, 2511588,1191052,48300,25395, 4358879,2074828,83825,43924, 6119868,2941745,117690,61118, 6324081,58,0 ],
  [ 1000,25,50,1,3, 176964,110717,12640,4732, 1110296,739910,13995,4669, 4560398,2045491,41458,22863, 8266690,3736848,75152,41180, 12425540,5968347,112959,58702, 15089159,85,0 ],
  [ 1000,25,75,1,3, 176964,110717,12640,4732, 1116072,741066,13951,4688, 4623302,2068140,41279,22814, 8389299,3849012,74904,40538, 12634724,6014082,112810,59113, 15327894,85,0 ],
  [ 1000,50,1,1,3, 22875,78146,22875,-55271, 33073,54026,33073,-20953, 105387,69343,105387,36044, 106551,53952,106551,52599, 140745,61436,140745,79309, -3475616,-40,26 ],
  [ 1000,50,2,1,3, 38657,80130,19328,-20736, 63598,59049,31799,2274, 177605,79347,88803,49129, 213101,69370,106551,71866, 281491,82578,140745,99457, -2975980,-36,23 ],
  [ 1000,50,3,1,3, 54439,82113,18146,-9225, 94122,77958,31374,5388, 249823,103716,83274,48703, 319652,99815,106551,73279, 422236,203171,140745,73022, -2646438,-33,21 ],
  [ 1000,50,5,1,3, 78528,85141,17133,-1443, 148081,87105,29616,12195, 383745,199172,76749,36915, 527332,333429,105466,38781, 696238,388574,139248,61533, -2305243,-31,19 ],
  [ 1000,50,10,1,3, 127989,104700,15672,2852, 303500,202437,25292,8422, 803057,391411,66921,34304, 1225357,558960,102113,55533, 1619483,785979,134957,69459, -464746,-6,0 ],
  [ 1000,50,15,1,3, 166393,104700,14791,5484, 463879,341382,21085,5568, 1118435,396411,50838,32819, 1304246,558960,59284,33877, 1729624,785979,78619,42893, 660686,16,0 ],
  [ 1000,50,25,1,3, 194330,113059,14133,5911, 856222,542367,17931,6573, 2743870,1238168,52767,28956, 4721088,2142273,90790,49593, 6628538,3148781,127472,66918, 7659697,63,0 ],
  [ 1000,50,50,1,3, 196656,113353,14047,5950, 1216930,848187,15339,4648, 4977141,2132374,45247,25862, 8960483,4070421,81459,44455, 13454861,6396677,122317,64165, 16869819,89,0 ],
  [ 1000,50,75,1,3, 196656,113353,14047,5950, 1223264,849457,15291,4673, 5045799,2155810,45052,25803, 9093617,4184080,81193,43835, 13681315,6444948,122155,64610, 17145170,89,0 ],
  [ 1000,100,1,1,3, 32676,79458,32676,-46782, 40640,54874,40640,-14234, 149658,75774,149658,73884, 122851,56372,122851,66480, 162480,64757,162480,97723, -3299222,-38,25 ],
  [ 1000,100,2,1,3, 51166,81804,25583,-15319, 76183,60741,38091,7721, 232977,101869,116488,65554, 245703,74242,122851,85730, 324960,188708,162480,68126, -2825689,-35,22 ],
  [ 1000,100,3,1,3, 69656,84149,23219,-4831, 111725,80492,37242,10411, 316296,113300,105432,67665, 368554,187347,122851,60402, 487440,326198,162480,53747, -2512591,-32,20 ],
  [ 1000,100,5,1,3, 97871,101066,21354,-697, 174644,91045,34929,16720, 470956,332257,94191,27740, 607945,346004,121589,52388, 803716,405622,160743,79619, -2161417,-29,17 ],
  [ 1000,100,10,1,3, 155767,108418,19074,5798, 356337,315696,29695,3387, 955970,426198,79664,44148, 1412362,709813,117697,58546, 1869229,922789,155769,78870, -153027,2,0 ],
  [ 1000,100,15,1,3, 201107,114179,17876,7727, 573689,384081,26077,8619, 1590326,753457,72288,38039, 2508149,1225230,114007,58315, 3354734,1662481,152488,76921, 2571214,36,0 ],
  [ 1000,100,25,1,3, 233327,118278,16969,8367, 1005503,688565,21058,6637, 3208434,1528313,61701,32310, 5445507,2496435,104721,56713, 7645877,3660271,147036,76646, 9113085,68,0 ],
  [ 1000,100,50,1,3, 236040,118624,16860,8387, 1430199,903156,18028,6643, 5810627,2580855,52824,29362, 10348068,4724939,94073,51119, 15513504,7337716,141032,74325, 20270121,95,0 ],
  [ 1000,100,75,1,3, 236040,118624,16860,8387, 1437648,904655,17971,6662, 5890795,2591352,52596,29459, 10502252,4869694,93770,50291, 15774495,7474792,140844,74104, 20550921,96,0 ],
  [ 1000,200,1,1,3, 52277,82081,52277,-29804, 55774,56570,55774,-795, 238199,103151,238199,135048, 155453,61261,155453,94191, 205950,71450,205950,134500, -2979936,-36,23 ],
  [ 1000,200,2,1,3, 76184,85152,38092,-4484, 101352,78008,50676,11672, 343720,132249,171860,105735, 310905,98913,155453,105996, 411899,201967,205950,104966, -2415480,-32,19 ],
  [ 1000,200,3,1,3, 100090,101559,33363,-490, 146930,85412,48977,20506, 449241,330033,149747,39736, 466358,310956,155453,51801, 617849,362444,205950,85135, -2527901,-33,20 ],
  [ 1000,200,5,1,3, 136559,106244,29795,6614, 227768,99226,45554,25708, 645377,371811,129075,54713, 769172,384780,153834,76878, 1018672,537819,203734,96171, -1333134,-20,-54 ],
  [ 1000,200,10,1,3, 136559,106244,16721,3712, 227768,99226,18981,10712, 1074269,536652,89522,44801, 788553,387973,65713,33382, 1048457,558410,87371,40837, -697675,-11,0 ],
  [ 1000,200,15,1,3, 269816,123375,23984,13017, 743131,501841,33779,10968, 2069567,927191,94071,51926, 3172537,1555841,144206,73486, 4250277,2140969,193194,95878, 4468600,48,0 ],
  [ 1000,200,25,1,3, 311321,142053,22642,12310, 1304064,845961,27310,9594, 4137561,1914038,79568,42760, 6894345,3269689,132584,69705, 9680557,4713161,186165,95527, 12722686,79,0 ],
  [ 1000,200,50,1,3, 314807,142502,22486,12308, 1856737,1205029,23404,8215, 7477599,3293137,67978,38041, 13123239,6090330,119302,63936, 19630790,9416167,178462,92860, 26608577,106,0 ],
  [ 1000,200,75,1,3, 314807,142502,22486,12308, 1866417,1206986,23330,8243, 7580785,3321299,67686,38031, 13319523,6117684,118924,64302, 19960856,9495331,178222,93442, 27233080,107,0 ],
  [ 1500,0,1,1,3, 20495,77971,20495,-57476, 37199,55240,37199,-18041, 81077,51494,81077,29583, 118233,55898,118233,62335, 156657,64078,156657,92579, -3466292,-40,26 ],
  [ 1500,0,2,1,3, 40991,80729,20495,-19869, 74398,75366,37199,-484, 162155,77384,81077,42386, 236467,73380,118233,81543, 313314,103595,156657,104860, -2981001,-36,23 ],
  [ 1500,0,3,1,3, 61486,83486,20495,-7333, 111596,81608,37199,9996, 243232,88908,81077,51441, 354700,186096,118233,56201, 469971,324311,156657,48553, -2688447,-34,21 ],
  [ 1500,0,5,1,3, 92776,101033,20242,-1802, 178118,92999,35624,17024, 394951,201921,78990,38606, 584971,343513,116994,48291, 775031,402119,155006,74582, -2099690,-29,17 ],
  [ 1500,0,10,1,3, 157072,109690,19233,5802, 373753,334876,31146,3240, 876917,417672,73076,38270, 1358829,705576,113236,54438, 1803453,916331,150288,73927, -435226,-5,0 ],
  [ 1500,0,15,1,3, 207499,116484,18444,8090, 611935,408326,27815,9255, 1514452,733474,68839,35499, 2416423,1218523,109837,54450, 3236822,1651312,147128,72069, 2302654,34,0 ],
  [ 1500,0,25,1,3, 243417,121327,17703,8879, 1097304,739844,22980,7486, 3175757,1536539,61072,31523, 5281909,2488875,101575,53712, 7368810,3551794,141708,73404, 8825885,67,0 ],
  [ 1500,0,50,1,3, 246452,121737,17604,8908, 1582362,1147484,19946,5482, 5915141,2756597,53774,28714, 10144709,4760592,92225,48947, 14941245,7290410,135830,69553, 19116365,93,0 ],
  [ 1500,0,75,1,3, 246452,121737,17604,8908, 1590904,1149252,19886,5521, 6000908,2768376,53580,28862, 10299435,4891082,91959,48289, 15192768,7343053,135650,70087, 19484971,94,0 ],
  [ 1500,25,1,1,3, 25396,78627,25396,-53231, 40982,55664,40982,-14682, 103213,69225,103213,33988, 126384,57158,126384,69226, 167524,65788,167524,101736, -3411595,-39,26 ],
  [ 1500,25,2,1,3, 47245,81566,23623,-17160, 80690,76212,40345,2239, 189841,81388,94920,54227, 252767,75791,126384,88488, 335049,190690,167524,72180, -2913386,-36,23 ],
  [ 1500,25,3,1,3, 69094,84504,23031,-5137, 120398,82875,40133,12508, 276469,108215,92156,56084, 379151,189758,126384,63131, 502573,345001,167524,52524, -2566618,-33,20 ],
  [ 1500,25,5,1,3, 102448,102328,22352,26, 191399,95044,38280,19271, 438556,314231,87711,24865, 625277,349951,125055,55065, 828770,410793,165754,83595, -2106369,-29,17 ],
  [ 1500,25,10,1,3, 170961,111549,20934,7275, 400171,339331,33348,5070, 953373,428558,79448,43735, 1452331,734236,121028,59841, 1928326,950906,160694,81452, -6040,5,0 ],
  [ 1500,25,15,1,3, 224676,118783,19971,9413, 654296,415832,29741,10839, 1634262,841952,74285,36014, 2582520,1242776,117387,60897, 3460708,1700809,157305,79995, 2833470,38,0 ],
  [ 1500,25,25,1,3, 262915,137273,19121,9138, 1171944,827525,24543,7213, 3408038,1583654,65539,35084, 5644118,2651755,108541,57545, 7877480,3856399,151490,77328, 9662941,70,0 ],
  [ 1500,25,50,1,3, 266144,137709,19010,9174, 1688997,1181761,21290,6394, 6331884,2920480,57563,31013, 10838502,5094314,98532,52220, 15970567,7718890,145187,75015, 20899614,97,0 ],
  [ 1500,25,75,1,3, 266144,137709,19010,9174, 1698096,1183643,21226,6431, 6423406,2933047,57352,31164, 11003753,5132817,98248,52419, 16239358,7857850,144994,74835, 21383073,97,0 ],
  [ 1500,50,1,1,3, 30296,79283,30296,-48987, 44766,56088,44766,-11322, 125348,72440,125348,52908, 134534,58368,134534,76166, 178392,67449,178392,110943, -3323399,-39,25 ],
  [ 1500,50,2,1,3, 53500,82403,26750,-14452, 86982,77058,43491,4962, 217526,85391,108763,66067, 269068,78202,134534,95433, 356783,194004,178392,81390, -2786037,-35,22 ],
  [ 1500,50,3,1,3, 76703,85523,25568,-2940, 129199,84142,43066,15019, 309705,113007,103235,65566, 403602,193370,134534,70077, 535175,349970,178392,61735, -2400117,-31,19 ],
  [ 1500,50,5,1,3, 112120,103622,24463,1854, 204680,97089,40936,21518, 482162,335006,96432,29431, 665584,355938,133117,61929, 882509,434688,176502,89564, -1912344,-27,15 ],
  [ 1500,50,10,1,3, 184850,113408,22635,8748, 426590,343785,35549,6900, 1029830,530959,85819,41573, 1545834,747969,128820,66489, 2053200,985532,171100,88972, 235251,10,0 ],
  [ 1500,50,15,1,3, 241853,121082,21498,6350, 696656,423338,31666,3764, 1754072,873431,79731,22676, 2748617,1282006,124937,36267, 3684594,1834086,167482,48530, 2700435,37,0 ],
  [ 1500,50,25,1,3, 282414,139883,20539,10366, 1246585,855392,26106,8193, 3640320,1722134,70006,36888, 6006328,2842687,115506,60839, 8386149,4063434,161272,83129, 10562798,73,0 ],
  [ 1500,50,50,1,3, 285835,140344,20417,10392, 1795631,1216188,22634,7304, 6748627,3113413,61351,33047, 11532295,5428386,104839,55490, 16999888,8231500,154544,79713, 22608943,100,0 ],
  [ 1500,50,75,1,3, 285835,140344,20417,10392, 1805288,1218185,22566,7339, 6845904,3218282,61124,32389, 11708070,5468436,104536,55711, 17285948,8289266,154339,80328, 22978754,100,0 ],
  [ 1500,100,1,1,3, 40097,80595,40097,-40498, 52333,56936,52333,-4603, 169618,78871,169618,90747, 150835,60788,150835,90047, 200126,70770,200126,129357, -3147005,-37,24 ],
  [ 1500,100,2,1,3, 66008,84077,33004,-9034, 99567,78749,49784,10409, 272898,107914,136449,82492, 301669,98051,150835,101809, 400253,200683,200126,99785, -2586942,-33,21 ],
  [ 1500,100,3,1,3, 91920,100896,30640,-2992, 146802,86527,48934,20092, 376177,199591,125392,58862, 452504,309205,150835,47766, 600379,360057,200126,80107, -2458378,-32,20 ],
  [ 1500,100,5,1,3, 91920,100896,20055,-1958, 146802,86527,29360,12055, 516400,339961,103280,35288, 459318,325819,91864,26700, 610096,362180,122019,49583, -2469082,-32,20 ],
  [ 1500,100,10,1,3, 212627,117126,26036,11694, 479427,352695,39952,10561, 1182743,567246,98562,51291, 1732839,870669,144403,71848, 2302946,1235933,191912,88918, 834509,19,0 ],
  [ 1500,100,15,1,3, 276208,139016,24552,12195, 781377,525935,35517,11611, 1993693,921723,90622,48726, 3080810,1549334,140037,69613, 4132365,2046219,187835,94825, 4171580,47,0 ],
  [ 1500,100,25,1,3, 321411,216802,23375,7608, 1395866,897240,29233,10442, 4104884,1922264,78940,41973, 6730747,3196799,129437,67961, 9403489,4604685,180836,92285, 12237923,78,0 ],
  [ 1500,100,50,1,3, 325219,217315,23230,7707, 2008901,1345007,25322,8368, 7582113,3547379,68928,36679, 12919880,6111007,117453,61899, 19058531,9172489,173259,89873, 25473430,104,0 ],
  [ 1500,100,75,1,3, 325219,217315,23230,7707, 2019673,1347233,25246,8406, 7690899,3576824,68669,36733, 13116706,6154049,117113,62167, 19379129,9447872,173028,88672, 25941432,105,0 ],
  [ 1500,200,1,1,3, 59699,83218,59699,-23519, 67467,58631,67467,8835, 258159,106248,258159,151911, 183436,65677,183436,117759, 243596,77462,243596,166134, -2827719,-35,22 ],
  [ 1500,200,2,1,3, 91026,87425,45513,1801, 124737,82131,62369,21303, 383640,215444,191820,84098, 366872,188053,183436,89410, 487192,342754,243596,72219, -2497873,-32,20 ],
  [ 1500,200,3,1,3, 122354,104969,40785,5795, 182008,91596,60669,30137, 509122,353839,169707,51761, 550308,339231,183436,70359, 730788,396203,243596,111528, -2138027,-29,17 ],
  [ 1500,200,5,1,3, 170151,111389,37124,12821, 284367,197095,56873,17454, 743793,401445,148759,68470, 907423,421615,181485,97162, 1204943,583233,240989,124342, -939632,-15,-28 ],
  [ 1500,200,10,1,3, 268182,124561,32839,17586, 585100,384399,48758,16725, 1488568,745719,124047,61904, 2106850,955954,175571,95908, 2802438,1343442,233536,121583, 2261845,34,0 ],
  [ 1500,200,15,1,3, 344917,219912,30659,11112, 950820,569844,43219,17317, 2472935,1216022,112406,57132, 3745198,1772034,170236,89689, 5027909,2540928,228541,113045, 5878785,56,0 ],
  [ 1500,200,25,1,3, 399405,328107,29048,5185, 1694428,1157636,35485,11242, 5034011,2383489,96808,50972, 8179585,3862943,157300,83012, 11438168,5629115,219965,111713, 15166397,85,0 ],
  [ 1500,200,50,1,3, 403987,328724,28856,5376, 2435439,1571680,30699,10888, 9249085,4168126,84083,46191, 15695051,7353861,142682,75829, 23175816,11168059,210689,109161, 32140476,114,0 ],
  [ 1500,200,75,1,3, 403987,328724,28856,5376, 2448441,1648214,30606,10003, 9380889,4369256,83758,44747, 15933976,7498624,142268,75316, 23565490,11340949,210406,109148, 32072791,114,0 ],
  [ 2000,0,1,1,3, 27917,79108,27917,-51191, 48891,57302,48891,-8411, 101038,69107,101038,31931, 146217,60364,146217,85853, 194303,70140,194303,124163, -3347575,-39,25 ],
  [ 2000,0,2,1,3, 55833,83002,27917,-13584, 97782,79490,48891,9146, 202076,83578,101038,59249, 292434,97189,146217,97623, 388606,199400,194303,94603, -2758404,-34,22 ],
  [ 2000,0,3,1,3, 83750,100233,27917,-5494, 146673,87643,48891,19677, 303114,112715,101038,63466, 438650,308004,146217,43549, 582910,358220,194303,74897, -2491236,-32,20 ],
  [ 2000,0,5,1,3, 126368,106178,27571,4405, 234717,117018,46943,23540, 493367,337605,98673,31152, 723222,380849,144644,68475, 961302,531813,192260,85898, -1777519,-25,14 ],
  [ 2000,0,10,1,3, 213932,118398,26196,11698, 496843,371875,41404,10414, 1103690,544205,91974,46624, 1679306,866382,139942,67744, 2237170,1145645,186431,90960, 645545,16,0 ],
  [ 2000,0,15,1,3, 282600,141321,25120,12558, 819624,536294,37256,12879, 1917819,916405,87174,45519, 2989084,1434217,135867,70676, 4014453,2035150,182475,89968, 4073177,46,0 ],
  [ 2000,0,25,1,3, 331501,219851,24109,8120, 1487667,934633,31155,11582, 4072207,1930640,78312,41184, 6567149,3189239,126291,64960, 9126421,4466948,175508,89605, 12015205,77,0 ],
  [ 2000,0,50,1,3, 335631,220428,23974,8229, 2161064,1515485,27240,8138, 7686627,3617071,69878,36996, 12716521,6146160,115605,59731, 18486272,9040903,168057,85867, 24858567,103,0 ],
  [ 2000,0,75,1,3, 335631,220428,23974,8229, 2172928,1517979,27162,8187, 7801012,3647798,69652,37082, 12913888,6189914,115303,60035, 18797401,9202542,167834,85668, 25413170,104,0 ],
  [ 2000,25,1,1,3, 32817,79764,32817,-46947, 52675,57726,52675,-5051, 123173,72322,123173,50851, 154367,61574,154367,92793, 205171,71801,205171,133370, -3259378,-38,25 ],
  [ 2000,25,2,1,3, 62088,83839,31044,-10876, 104075,80335,52037,11870, 229762,87582,114881,71090, 308734,99600,154367,104567, 410341,202715,205171,103813, -2631055,-33,21 ],
  [ 2000,25,3,1,3, 91358,101251,30453,-3298, 155475,88910,51825,22188, 336350,194507,112117,47281, 463101,311616,154367,50495, 615512,363189,205171,84108, -2501232,-32,20 ],
  [ 2000,25,5,1,3, 136040,107473,29682,6233, 247998,119063,49600,25787, 536973,343865,107395,38622, 763529,386786,152706,75349, 1015041,539987,203008,95011, -1538821,-23,11 ],
  [ 2000,25,10,1,3, 227821,120257,27896,13171, 523261,376329,43605,12244, 1180146,569606,98346,50878, 1772808,880116,147734,74391, 2362043,1248379,196837,92805, 1014773,21,0 ],
  [ 2000,25,15,1,3, 299777,143620,26647,13881, 861984,543800,39181,14463, 2037630,933369,92620,50194, 3155181,1581857,143417,71515, 4238339,2168377,192652,94089, 4572080,49,0 ],
  [ 2000,25,25,1,3, 350999,222461,25527,9348, 1562308,1052965,32718,10667, 4304489,2067770,82779,43014, 6929358,3352019,133257,68795, 9635091,4770953,185290,93541, 12604116,79,0 ],
  [ 2000,25,50,1,3, 355323,223064,25380,9447, 2267698,1549912,28584,9048, 8103370,3795469,73667,39163, 13410314,6371322,121912,63991, 19515594,9553063,177414,90568, 26763349,106,0 ],
  [ 2000,25,75,1,3, 355323,223064,25380,9447, 2280121,1552521,28502,9095, 8223510,3918519,73424,38437, 13618206,6525483,121591,63328, 19843991,9717689,177178,90413, 26982616,106,0 ],
  [ 2000,50,1,1,3, 37717,80420,37717,-42702, 56458,58149,56458,-1691, 145308,75538,145308,69771, 162517,62784,162517,99734, 216038,73461,216038,142577, -3171182,-38,24 ],
  [ 2000,50,2,1,3, 68342,84676,34171,-8167, 110367,81181,55184,14593, 257447,106101,128724,75673, 325035,182368,162517,71333, 432076,319170,216038,56453, -2736363,-34,22 ],
  [ 2000,50,3,1,3, 98967,102269,32989,-1101, 164276,90177,54759,24700, 369586,199299,123195,56762, 487552,330205,162517,52449, 648114,368157,216038,93319, -2356834,-31,19 ],
  [ 2000,50,5,1,3, 145712,108767,31792,8061, 261279,194959,52256,13264, 580578,350125,116116,46091, 803835,392723,160767,82222, 1068780,563832,213756,100990, -1545076,-23,11 ],
  [ 2000,50,10,1,3, 241710,122115,29597,14644, 549679,380784,45807,14075, 1256603,580492,104717,56343, 1866311,908775,155526,79795, 2486916,1282955,207243,100330, 1443959,26,0 ],
  [ 2000,50,15,1,3, 316954,217618,28174,8830, 904345,565191,41107,15416, 2157440,1070747,98065,49395, 3321278,1621587,150967,77259, 4462225,2218324,202828,101996, 4674345,50,0 ],
  [ 2000,50,25,1,3, 370498,225070,26945,10577, 1636948,1154681,34282,10100, 4536770,2114735,87246,46578, 7291568,3419464,140222,74464, 10143761,4977938,195072,99343, 13662368,81,0 ],
  [ 2000,50,50,1,3, 375015,225699,26787,10665, 2374333,1584339,29929,9958, 8520113,3988402,77456,41197, 14104107,6705394,128219,67261, 20544915,10065673,186772,95266, 28472678,109,0 ],
  [ 2000,50,75,1,3, 375015,225699,26787,10665, 2387313,1587062,29841,10003, 8646008,4083189,77196,40739, 14322524,6767068,127880,67459, 20890582,10261646,186523,94901, 28914513,109,0 ],
  [ 2000,100,1,1,3, 47518,81731,47518,-34213, 64025,58997,64025,5028, 189579,81968,189579,107610, 178818,65204,178818,113614, 237773,76782,237773,160990, -2994788,-36,23 ],
  [ 2000,100,2,1,3, 80851,86350,40426,-2750, 122952,82872,61476,20040, 312819,114108,156409,99355, 357636,187190,178818,85223, 475545,341470,237773,67038, -2492838,-32,20 ],
  [ 2000,100,3,1,3, 114184,104306,38061,3293, 181879,92712,60626,29722, 436059,329448,145353,35537, 536455,337980,178818,66158, 713318,394316,237773,106334, -2313882,-31,19 ],
  [ 2000,100,5,1,3, 165056,111356,36012,11716, 287842,199049,57568,17759, 667788,377159,133558,58126, 884448,419624,176890,92965, 1176258,580230,235252,119205, -1123283,-17,-38 ],
  [ 2000,100,10,1,3, 269487,139170,32998,15957, 602516,389544,50210,17748, 1409516,722829,117460,57224, 2053317,951818,171110,91792, 2736662,1337135,228055,116627, 2002037,31,0 ],
  [ 2000,100,15,1,3, 351309,222216,31227,11475, 989066,684553,44958,13841, 2397061,1194689,108957,54653, 3653472,1765327,166067,85825, 4909997,2529309,223182,108213, 5327187,53,0 ],
  [ 2000,100,25,1,3, 409495,331156,29781,5697, 1786229,1195029,37408,12381, 5001334,2391865,96180,50182, 8015987,3855383,154154,80012, 11161101,5520688,214637,108469, 14922781,84,0 ],
  [ 2000,100,50,1,3, 414399,331837,29600,5897, 2587602,1711658,32617,11041, 9353599,4422368,85033,44829, 15491692,7389515,140834,73656, 22603558,11008162,205487,105413, 31217476,112,0 ],
  [ 2000,100,75,1,3, 414399,331837,29600,5897, 2601697,1714610,32521,11089, 9491003,4456246,84741,44953, 15731159,7519562,140457,73318, 22983762,11209260,205212,105129, 31779121,113,0 ],
  [ 2000,200,1,1,3, 67120,84355,67120,-17235, 79159,60693,79159,18466, 278119,109345,278119,168774, 211420,70093,211420,141326, 281242,83475,281242,197767, -2675502,-34,21 ],
  [ 2000,200,2,1,3, 105869,103035,52934,1417, 148122,86105,74061,31009, 423561,221638,211781,100962, 422839,305495,211420,58672, 562484,354929,281242,103778, -2408206,-32,19 ],
  [ 2000,200,3,1,3, 144618,108379,48206,12080, 217085,97781,72362,39768, 569003,363131,189668,68624, 634259,352479,211420,93927, 843726,414241,281242,143162, -1681376,-24,13 ],
  [ 2000,200,5,1,3, 203743,116534,44453,19028, 340966,207230,68193,26747, 842209,416713,168442,85099, 1045675,523731,209135,104389, 1391213,741738,278243,129895, -412318,-6,0 ],
  [ 2000,200,10,1,3, 325042,218305,39801,13070, 708189,421248,59016,23912, 1715341,872252,142945,70257, 2427327,1225371,202277,100163, 3236155,1656686,269680,131622, 2774131,38,0 ],
  [ 2000,200,15,1,3, 420018,332279,37335,7799, 1158509,726963,52659,19616, 2876302,1382938,130741,67880, 4317859,2097488,196266,100926, 5805540,2925567,263888,130908, 7070914,61,0 ],
  [ 2000,200,25,1,3, 487489,354931,35454,9641, 2084791,1352425,43661,15338, 5930461,2777590,114047,60632, 9464825,4548330,182016,94548, 13195780,6460488,253765,129525, 18731539,92,0 ],
  [ 2000,200,50,1,3, 493167,355716,35226,9818, 3014140,2013530,37993,12613, 11020571,5134650,100187,53508, 18266863,8631470,166062,87594, 26720843,13002832,242917,124709, 37797844,120,0 ],
  [ 2000,200,75,1,3, 493167,355716,35226,9818, 3030970,2016941,37887,12675, 11164724,5248628,99685,52822, 18423095,8769820,164492,86190, 26963627,13007882,240747,124605, 38105075,121,0 ],
  [ 2500,0,1,1,3, 35338,80244,35338,-44907, 60584,59363,60584,1220, 120998,72204,120998,48795, 174200,64780,174200,109420, 231950,76153,231950,155797, -3195358,-38,24 ],
  [ 2500,0,2,1,3, 70676,85275,35338,-7300, 121167,83613,60584,18777, 241997,89772,120998,76112, 348400,186328,174200,81036, 463899,324516,231950,69692, -2653127,-34,21 ],
  [ 2500,0,3,1,3, 106014,103642,35338,790, 181751,93827,60584,29308, 362995,199006,120998,54663, 522601,336228,174200,62124, 695849,391929,231950,101307, -2244360,-30,18 ],
  [ 2500,0,5,1,3, 159961,111323,34900,10612, 291316,201003,58263,18063, 591783,352874,118357,47782, 861474,417634,172295,88768, 1147573,577228,229515,114069, -1306934,-20,-48 ],
  [ 2500,0,10,1,3, 270792,140442,33158,15961, 619932,408723,51661,17601, 1330463,699788,110872,52556, 1999783,947531,166649,87688, 2670887,1330627,222574,111688, 1753338,29,0 ],
  [ 2500,0,15,1,3, 357700,224521,31796,11838, 1027313,708798,46696,14478, 2321187,1189221,105508,51453, 3561746,1758670,161898,81958, 4792085,2418739,217822,107879, 5096034,52,0 ],
  [ 2500,0,25,1,3, 419585,347542,30515,5239, 1878030,1232273,39330,13524, 4968657,2414756,95551,49113, 7852388,3767716,151007,78551, 10884033,5383151,209308,105786, 14730394,84,0 ],
  [ 2500,0,50,1,3, 424811,348287,30344,5466, 2739765,1881986,34535,10812, 9458113,4492060,85983,45146, 15288333,7424818,138985,71487, 22031299,10876726,200285,101405, 30547920,111,0 ],
  [ 2500,0,75,1,3, 424811,348287,30344,5466, 2754953,1885207,34437,10872, 9601116,4527220,85724,45303, 15528342,7475270,138646,71902, 22402035,11047860,200018,101377, 31254955,112,0 ],
  [ 2500,25,1,1,3, 40238,80900,40238,-40662, 64367,59787,64367,4580, 143134,75419,143134,67714, 182351,65990,182351,116360, 242817,77813,242817,165004, -3107161,-37,24 ],
  [ 2500,25,2,1,3, 76930,86112,38465,-4591, 127459,84458,63730,21500, 269682,108291,134841,80696, 364701,188789,182351,87956, 485634,343551,242817,71041, -2570451,-33,20 ],
  [ 2500,25,3,1,3, 113622,104661,37874,2987, 190552,95095,63517,31819, 396231,203798,132077,64144, 547052,339841,182351,69070, 728451,396898,242817,110518, -2077858,-28,17 ],
  [ 2500,25,5,1,3, 169632,112617,37011,12440, 304597,203048,60919,20310, 635389,373648,127078,52348, 901780,423621,180356,95632, 1201312,585452,240262,123172, -1101736,-17,-37 ],
  [ 2500,25,10,1,3, 284681,142301,34859,17434, 646351,413178,53863,19431, 1406919,725189,117243,56811, 2093286,961264,174440,94335, 2795760,1365252,232980,119209, 2171127,33,0 ],
  [ 2500,25,15,1,3, 374878,327686,33322,4195, 1069673,714804,48622,16130, 2440997,1220700,110954,55468, 3727842,1799400,169447,87656, 5015971,2569187,227999,111217, 5296664,53,0 ],
  [ 2500,25,25,1,3, 439083,350151,31933,6468, 1952671,1260139,40894,14503, 5200939,2461721,100018,52677, 8214598,4038906,157973,80302, 11392703,5687556,219090,109714, 15652266,86,0 ],
  [ 2500,25,50,1,3, 444503,350922,31750,6684, 2846399,1990263,35879,10792, 9874856,4761993,89771,46481, 15982126,7650430,145292,75743, 23060620,11389336,209642,106103, 32007043,113,0 ],
  [ 2500,25,75,1,3, 444503,350922,31750,6684, 2862145,1993598,35777,10857, 10023614,4797941,89497,46658, 16232660,7891146,144934,74478, 23448625,11592318,209363,105860, 32451199,114,0 ],
  [ 2500,50,1,1,3, 45139,81556,45139,-36417, 68150,60211,68150,7939, 165269,78635,165269,86634, 190501,67200,190501,123301, 253684,79474,253684,174210, -3018965,-36,23 ],
  [ 2500,50,2,1,3, 83185,86949,41592,-1882, 133752,85304,66876,24224, 297368,112295,148684,92537, 381002,191200,190501,94901, 507369,346866,253684,80251, -2443102,-32,20 ],
  [ 2500,50,3,1,3, 121231,105679,40410,5184, 199353,96362,66451,34330, 429468,329155,143156,33437, 571503,344003,190501,75833, 761053,402416,253684,119546, -2190234,-30,18 ],
  [ 2500,50,5,1,3, 179304,113912,39121,14267, 317878,205093,63576,22557, 678994,379908,135799,59817, 942087,509866,188417,86444, 1255051,609297,251010,129151, -992735,-16,-32 ],
  [ 2500,50,10,1,3, 298570,144160,36560,18907, 672769,417633,56064,21261, 1483376,736075,123615,62275, 2186788,1098384,182232,90700, 2920633,1467937,243386,121058, 2391678,35,0 ],
  [ 2500,50,15,1,3, 392055,329985,34849,5517, 1112034,722310,50547,17715, 2560808,1237663,116400,60143, 3893939,1947040,176997,88495, 5239857,2618634,238175,119147, 5855301,56,0 ],
  [ 2500,50,25,1,3, 458582,352761,33351,7696, 2027311,1347971,42457,14227, 5433221,2585836,104485,54757, 8576807,4201636,164939,84138, 11901373,6007682,228873,113340, 16356344,87,0 ],
  [ 2500,50,50,1,3, 464195,353558,33157,7903, 2953034,2010805,37223,11877, 10291599,4849026,93560,49478, 16675919,7984002,151599,79017, 24089942,11817715,218999,111566, 34065067,116,0 ],
  [ 2500,50,75,1,3, 464195,353558,33157,7903, 2969337,2028140,37117,11765, 10446112,4962612,93269,48960, 16936977,8241191,151223,77641, 24495215,12106964,218707,110609, 34243920,116,0 ],
  [ 2500,100,1,1,3, 54940,82868,54940,-27928, 75717,61059,75717,14659, 209539,99581,209539,109959, 206802,69670,206802,137132, 275419,82845,275419,192574, -2876072,-35,23 ],
  [ 2500,100,2,1,3, 95694,101960,47847,-3133, 146337,86845,73168,29746, 352740,197302,176370,77719, 413603,196172,206802,108715, 550838,353645,275419,98596, -2419594,-32,19 ],
  [ 2500,100,3,1,3, 136448,107716,45483,9577, 216956,98897,72319,39353, 495940,338739,165313,52400, 620405,351228,206802,89726, 826257,412354,275419,137968, -1857231,-26,15 ],
  [ 2500,100,5,1,3, 198648,116501,43341,17923, 344440,209183,68888,27051, 766204,406943,153241,71852, 1022700,521791,204540,100182, 1362529,738785,272506,124749, -629470,-10,0 ],
  [ 2500,100,10,1,3, 326347,219577,39961,13074, 725606,500393,60467,18768, 1636289,849362,136357,65577, 2373794,1221084,197816,96059, 3170379,1650228,264198,126679, 2335235,34,0 ],
  [ 2500,100,15,1,3, 426409,347920,37903,6977, 1196755,824907,54398,16902, 2800428,1300470,127292,68180, 4226133,2105958,192097,96372, 5687628,2914598,258529,126047, 6634774,59,0 ],
  [ 2500,100,25,1,3, 497579,357980,36188,10153, 2176592,1494169,45583,14292, 5897784,2798981,113419,59592, 9301226,4540821,178870,91546, 12918713,6435392,248437,124679, 18068067,91,0 ],
  [ 2500,100,50,1,3, 503579,358829,35970,10339, 3166303,2079659,39911,13697, 11125085,5297357,101137,52979, 18063504,8666673,164214,85426, 26148585,12871795,237714,120698, 37299604,120,0 ],
  [ 2500,100,75,1,3, 503579,358829,35970,10339, 3183721,2083338,39797,13755, 11291107,5321153,100813,53303, 18345613,8818345,163800,85065, 26588396,13053028,237396,120851, 38045977,121,0 ],
  [ 2500,200,1,1,3, 74541,85491,74541,-10950, 90851,76639,90851,14212, 298080,126807,298080,171273, 239403,74559,239403,164844, 318888,188939,318888,129950, -2671276,-34,21 ],
  [ 2500,200,2,1,3, 120712,105308,60356,7702, 171507,90228,85753,40639, 463482,333882,231741,64800, 478806,329803,239403,74501, 637777,367454,318888,135162, -2371253,-31,19 ],
  [ 2500,200,3,1,3, 166882,111789,55627,18364, 252162,117851,84054,44770, 628885,372272,209628,85537, 718209,380703,239403,112502, 956665,531680,318888,141662, -1361319,-21,9 ],
  [ 2500,200,5,1,3, 237336,121678,51782,25234, 397565,335599,79513,12393, 940625,444847,188125,99156, 1183926,560566,236785,124672, 1577484,786652,315497,158166, -111657,2,0 ],
  [ 2500,200,10,1,3, 381902,327879,46764,6615, 831279,530597,69273,25057, 1942114,921785,161843,85027, 2747804,1307369,228984,120036, 3669872,1842518,305823,152279, 3534540,43,0 ],
  [ 2500,200,15,1,3, 495118,357116,44011,12267, 1366198,868816,62100,22608, 3279670,1594769,149076,76586, 4890520,2437118,222296,111518, 6583172,3325526,299235,148075, 8535624,66,0 ],
  [ 2500,200,25,1,3, 575573,368419,41860,15066, 2475154,1651715,51836,17245, 6826912,3261706,131287,68562, 10750064,5140134,206732,107883, 14953392,7375042,287565,145738, 21892991,98,0 ],
  [ 2500,200,50,1,3, 582347,369371,41596,15213, 3592841,2381681,45288,15267, 12792057,6009639,116291,61658, 20838675,9988785,189443,98635, 30265870,14837005,275144,140262, 43837038,127,0 ],
  [ 2500,200,75,1,3, 582347,369371,41596,15213, 3612490,2385819,45156,15333, 12981098,6128100,115903,61187, 21162884,10066185,188954,99078, 30774757,15157197,274775,139442, 44546597,128,0 ],
  [ 3000,0,1,1,3, 42759,81381,42759,-38622, 72276,75310,72276,-3034, 140959,75151,140959,65808, 202184,69196,202184,132987, 269596,82115,269596,187481, -3086725,-37,24 ],
  [ 3000,0,2,1,3, 85518,100885,42759,-7683, 144552,87586,72276,28483, 281918,110482,140959,85718, 404367,195360,202184,104504, 539192,352412,269596,93390, -2448059,-32,20 ],
  [ 3000,0,3,1,3, 128277,107052,42759,7075, 216828,113897,72276,34310, 422877,314198,140959,36226, 606551,349977,202184,85525, 808788,410417,269596,132790, -2076670,-28,17 ],
  [ 3000,0,5,1,3, 193553,116468,42230,16819, 347915,315487,69583,6486, 690200,381157,138040,61808, 999725,519800,199945,95985, 1333844,735282,266769,119712, -1139742,-18,-38 ],
  [ 3000,0,10,1,3, 327652,220850,40121,13078, 743022,505687,61918,19778, 1557236,840986,129770,59687, 2320260,1216847,193355,91951, 3104604,1643821,258717,121732, 2096619,32,0 ],
  [ 3000,0,15,1,3, 432801,350224,38471,7340, 1235001,835266,56136,18170, 2724554,1280637,123843,65633, 4134407,2099251,187928,92507, 5569716,2790388,253169,126333, 6490429,58,0 ],
  [ 3000,0,25,1,3, 507669,361029,36921,10665, 2268393,1531562,47506,15431, 5865107,2884357,112791,57322, 9137628,4548238,175724,88258, 12641645,6410745,243109,119825, 17566116,90,0 ],
  [ 3000,0,50,1,3, 513991,361942,36714,10861, 3318466,2323987,41829,12535, 11229599,5367050,102087,53296, 17860145,8701826,162365,83257, 25576326,12710899,232512,116958, 36471858,119,0 ],
  [ 3000,0,75,1,3, 513991,361942,36714,10861, 3336977,2327935,41712,12613, 11401221,5512692,101797,52576, 18142796,8839783,161989,83063, 26006668,13005119,232202,116085, 36886793,119,0 ],
  [ 3000,25,1,1,3, 47660,82037,47660,-34377, 76059,75734,76059,326, 163094,78366,163094,84728, 210334,70406,210334,139928, 280463,99447,280463,181016, -3009701,-36,23 ],
  [ 3000,25,2,1,3, 91773,101722,45886,-4975, 150844,88432,75422,31206, 309603,114486,154802,97559, 420668,306231,210334,57218, 560926,355727,280463,102600, -2480784,-32,20 ],
  [ 3000,25,3,1,3, 135886,108071,45295,9272, 225629,115165,75210,36821, 456113,333505,152038,40869, 631002,353639,210334,92454, 841390,431106,280463,136761, -1954842,-27,16 ],
  [ 3000,25,5,1,3, 203225,117762,44340,18646, 361196,331417,72239,5956, 733805,387267,146761,69308, 1040032,540714,208006,99864, 1387583,743406,277517,128835, -966730,-15,-29 ],
  [ 3000,25,10,1,3, 341541,222709,41821,14551, 769440,524027,64120,20451, 1633692,851722,136141,65164, 2413763,1230530,201147,98603, 3229477,1662675,269123,130567, 2515498,36,0 ],
  [ 3000,25,15,1,3, 449978,352523,39998,8663, 1277362,856657,58062,19123, 2844365,1388966,129289,66154, 4300504,2123504,195477,98955, 5793602,2952925,263346,129122, 6897030,60,0 ],
  [ 3000,25,25,1,3, 527167,363639,38339,11893, 2343034,1559428,49069,16411, 6097389,2931322,117257,60886, 9499838,4630660,182689,93638, 13150315,6602060,252891,125928, 18847218,93,0 ],
  [ 3000,25,50,1,3, 533683,364578,38120,12079, 3425100,2358414,43174,13446, 11646342,5636982,105876,54631, 18553938,9035898,168672,86528, 26605647,13139728,241870,122417, 38064422,121,0 ],
  [ 3000,25,75,1,3, 533683,364578,38120,12079, 3444169,2362476,43052,13521, 11823718,5677363,105569,54878, 18847113,9189828,168278,86226, 27053259,13435986,241547,121583, 38739248,121,0 ],
  [ 3000,50,1,1,3, 52560,82693,52560,-30133, 79843,76158,79843,3685, 185229,81582,185229,103647, 218484,71616,218484,146868, 291331,101108,291331,190223, -2921505,-36,23 ],
  [ 3000,50,2,1,3, 98027,102559,49014,-2266, 157137,89277,78568,33930, 337289,195489,168645,70900, 436969,308642,218484,64163, 582661,359041,291331,111810, -2529933,-33,20 ],
  [ 3000,50,3,1,3, 143494,109089,47831,11469, 234430,116432,78143,39333, 489349,338297,163116,50351, 655453,372228,218484,94408, 873992,436075,291331,145972, -1810444,-26,14 ],
  [ 3000,50,5,1,3, 212897,119057,46450,20474, 374477,333462,74895,8203, 777410,408042,155482,73874, 1080338,546701,216068,106727, 1441321,767301,288264,134804, -772705,-12,0 ],
  [ 3000,50,10,1,3, 355430,224567,43522,16024, 795858,528482,66322,22281, 1710149,877123,142512,69419, 2507265,1259240,208939,104002, 3354350,1697300,279529,138087, 2911184,39,0 ],
  [ 3000,50,15,1,3, 467156,354822,41525,9985, 1319723,864163,59987,20707, 2964175,1526494,134735,65349, 4466601,2163234,203027,104698, 6017488,3002922,273522,137026, 7336863,62,0 ],
  [ 3000,50,25,1,3, 546666,366248,39758,13121, 2417674,1573410,50632,17681, 6329671,3099002,121724,62128, 9862047,4887423,189655,95666, 13658985,6838955,262673,131154, 19604460,94,0 ],
  [ 3000,50,50,1,3, 553375,367213,39527,13297, 3531735,2378956,44518,14531, 12063085,5801015,109664,56928, 19247731,9341318,174979,90058, 27634969,13764979,251227,126091, 39847132,123,0 ],
  [ 3000,50,75,1,3, 553375,367213,39527,13297, 3551361,2397017,44392,14429, 12246216,5948084,109341,56233, 19551431,9525397,174566,89518, 28099849,13966804,250892,126188, 40297521,123,0 ],
  [ 3000,100,1,1,3, 62361,84004,62361,-21644, 87410,77006,87410,10404, 229500,102528,229500,126972, 234785,74086,234785,160699, 313065,104479,313065,208587, -2778611,-35,22 ],
  [ 3000,100,2,1,3, 110536,104233,55268,3151, 169722,90969,84861,39376, 392660,218012,196330,87324, 469570,328491,234785,70540, 626131,365720,313065,130205, -2330838,-31,19 ],
  [ 3000,100,3,1,3, 158712,111125,52904,15862, 252033,118967,84011,44356, 555821,362396,185274,64475, 704355,379502,234785,108284, 939196,446062,313065,164378, -1510940,-23,11 ],
  [ 3000,100,5,1,3, 232240,121645,50671,24130, 401039,337552,80208,12697, 864620,420561,172924,88812, 1160952,558576,232190,120475, 1548799,783649,309760,153030, -295308,-3,0 ],
  [ 3000,100,10,1,3, 383207,329152,46923,6619, 848695,535892,70725,26067, 1863061,913410,155255,79138, 2694271,1303132,224523,115928, 3604096,1836111,300341,147332, 3295925,41,0 ],
  [ 3000,100,15,1,3, 501510,359420,44579,12630, 1404444,893060,63838,23245, 3203796,1574786,145627,74046, 4798794,2430411,218127,107654, 6465260,3185596,293875,149076, 8358869,65,0 ],
  [ 3000,100,25,1,3, 585663,384804,42594,14608, 2566955,1688958,53758,18387, 6794235,3270082,130658,67772, 10586466,5132725,203586,104880, 14676324,7350545,282237,140880, 21534947,97,0 ],
  [ 3000,100,50,1,3, 592759,385821,42340,14781, 3745004,2521510,47206,15422, 12896571,6157831,117242,61261, 20635316,10024088,187594,96466, 29693612,14706068,269942,136250, 43083825,126,0 ],
  [ 3000,100,75,1,3, 592759,385821,42340,14781, 3765745,2525915,47072,15498, 13091211,6306625,116886,60577, 20960066,10102700,187143,96941, 30193029,14913017,269581,136429, 43811104,127,0 ],
  [ 3000,200,1,1,3, 81962,86628,81962,-4665, 102544,78701,102544,23843, 318040,129905,318040,188136, 267386,78975,267386,188411, 356535,194951,356535,161583, -2519059,-32,20 ],
  [ 3000,200,2,1,3, 135554,107582,67777,13986, 194891,94351,97446,50270, 503403,354592,251702,74406, 534773,338685,267386,98044, 713070,395200,356535,158935, -2111493,-29,17 ],
  [ 3000,200,3,1,3, 189146,115199,63049,24649, 287239,197886,95746,29784, 688766,396079,229589,97562, 802159,394001,267386,136053, 1069604,565439,356535,168055, -1183122,-18,-46 ],
  [ 3000,200,5,1,3, 270928,140160,59112,28531, 454164,345583,90833,21716, 1039041,551631,207808,97482, 1322178,706012,264436,123233, 1763755,916046,352751,169542, 108210,7,0 ],
  [ 3000,200,10,1,3, 438763,349924,53726,10878, 954369,567446,79531,32244, 2168887,1091883,180741,89750, 3068281,1562359,255690,125494, 4103588,2072532,341966,169255, 4320268,48,0 ],
  [ 3000,200,15,1,3, 570219,368616,50686,17920, 1573886,936970,71540,28951, 3683037,1763035,167411,87273, 5463182,2652612,248326,127753, 7360803,3679804,334582,167318, 10605436,73,0 ],
  [ 3000,200,25,1,3, 663657,395243,48266,19521, 2865517,1877004,60011,20702, 7723362,3654307,148526,78251, 12035304,5825522,231448,119419, 16711004,8289695,321365,161948, 25305558,104,0 ],
  [ 3000,200,50,1,3, 671526,396363,47966,19655, 4171542,2823532,52582,16992, 14563543,6884628,132396,69808, 23410487,11265943,212823,110405, 33810897,16671328,307372,155814, 49706283,133,0 ],
  [ 3000,200,75,1,3, 671526,396363,47966,19655, 4194514,2828396,52431,17076, 14781202,7007522,131975,69408, 23777337,11538808,212298,109273, 34379390,16917235,306959,155912, 50349412,133,0 ],
  [ 1000,0,1,2,3, 245737,77805,245737,167932, 1499959,56745,1499959,1443213, -96446,56069,-96446,-152515, -196055,60101,-196055,-256156, -306749,65974,-306749,-372724, 866751,15,0 ],
  [ 1000,0,2,2,3, 40856,80279,20428,-19711, 74700,61038,37350,6831, 166374,77429,83187,44473, 235755,72532,117877,81611, 301246,101107,150623,100070, -2930433,-36,23 ],
  [ 1000,0,3,2,3, 61284,82811,20428,-7176, 112050,80943,37350,10369, 249561,88751,83187,53603, 353632,184824,117877,56270, 451868,320504,150623,43788, -2677970,-34,21 ],
  [ 1000,0,5,2,3, 92475,100016,20176,-1645, 178092,91785,35618,17261, 404256,201479,80851,40555, 584302,341529,116860,48555, 745981,395918,149196,70013, -2084974,-28,17 ],
  [ 1000,0,10,2,3, 156581,107976,19173,5952, 368408,331250,30701,3096, 890791,415383,74233,39617, 1363875,701613,113656,55188, 1741286,902421,145107,69905, -419668,-5,0 ],
  [ 1000,0,15,2,3, 206872,114227,18389,8235, 595779,386980,27081,9491, 1528779,727815,69490,36407, 2429662,1196678,110439,56045, 3134435,1498607,142474,74356, 2459301,35,0 ],
  [ 1000,0,25,2,3, 242708,118690,17651,9020, 1047310,708185,21933,7102, 3164149,1395849,60849,34006, 5301226,2454831,101947,54738, 7190713,3471582,138283,71522, 9081986,68,0 ],
  [ 1000,0,50,2,3, 245737,119068,17553,9048, 1492131,925951,18808,7137, 5809497,2491839,52814,30161, 10116508,4584881,91968,50288, 14689155,6944460,133538,70406, 20190973,95,0 ],
  [ 1000,0,75,2,3, 245737,119068,17553,9048, 1499959,927513,18749,7156, 5891307,2579349,52601,29571, 10268358,4620625,91682,50426, 14938991,6995895,133384,70921, 20510564,96,0 ],
  [ 1000,25,1,2,3, 26977,78623,26977,-51646, 42616,55629,42616,-13013, 110091,69862,110091,40229, 128523,57105,128523,71418, 164377,64961,164377,99416, -3383925,-39,25 ],
  [ 1000,25,2,2,3, 49521,81438,24760,-15959, 83638,76141,41819,3749, 200833,82257,100416,59288, 257046,75682,128523,90682, 328754,189033,164377,69860, -2867600,-35,22 ],
  [ 1000,25,3,2,3, 72065,84254,24022,-4063, 124661,82768,41554,13964, 291575,109317,97192,60752, 385568,189594,128523,65325, 493131,326845,164377,55429, -2491545,-32,20 ],
  [ 1000,25,5,2,3, 106479,101891,23232,1001, 197277,94737,39455,20508, 460444,315587,92089,28971, 637023,349798,127405,57445, 814070,406778,162814,81459, -2011452,-28,16 ],
  [ 1000,25,10,2,3, 177174,110732,21695,8136, 406958,337726,33913,5769, 992198,506808,82683,40449, 1486697,719591,123891,63925, 1900035,942129,158336,79825, 17421,5,0 ],
  [ 1000,25,15,2,3, 232603,117671,20676,10216, 657943,411840,29906,11186, 1690216,842020,76828,38554, 2648556,1243606,120389,63861, 3419798,1670423,155445,79517, 3111578,40,0 ],
  [ 1000,25,25,2,3, 272066,135955,19787,9899, 1157642,742545,24244,8693, 3483112,1575516,66983,36685, 5781752,2650360,111188,60219, 7842564,3813803,150819,77476, 10331037,72,0 ],
  [ 1000,25,50,2,3, 275398,136374,19671,9930, 1650428,1148346,20804,6329, 6390397,2797584,58095,32662, 11043674,4982652,100397,55100, 16015915,7532319,145599,77124, 21975666,99,0 ],
  [ 1000,25,75,2,3, 275398,136374,19671,9930, 1659092,1150079,20739,6363, 6480452,2886221,57861,32091, 11209789,5100731,100087,54545, 16288234,7700148,145431,76679, 22146253,99,0 ],
  [ 1000,50,1,2,3, 33525,79499,33525,-45974, 47881,56239,47881,-8357, 136994,73767,136994,63227, 139168,58685,139168,80483, 178131,67063,178131,111068, -3271549,-38,25 ],
  [ 1000,50,2,2,3, 58185,82598,29093,-12206, 92577,77358,46288,7609, 235291,87235,117646,74028, 278336,78833,139168,99752, 356262,193230,178131,81516, -2701451,-34,21 ],
  [ 1000,50,3,2,3, 82845,85697,27615,-951, 137272,84593,45757,17560, 333588,192368,111196,47073, 417504,194314,139168,74397, 534393,348807,178131,61862, -2459292,-32,20 ],
  [ 1000,50,5,2,3, 120483,103765,26287,3648, 216461,97688,43292,23755, 516632,338159,103326,35695, 689744,357617,137949,66425, 882160,417188,176432,92994, -1726052,-25,13 ],
  [ 1000,50,10,2,3, 197767,113488,24216,10320, 445508,344202,37126,8442, 1093604,535748,91134,46488, 1609518,752595,134127,71410, 2058784,966216,171565,91047, 586577,15,0 ],
  [ 1000,50,15,2,3, 258335,121115,22963,12197, 720106,422815,32732,13513, 1851652,879375,84166,44194, 2867450,1290534,130339,71678, 3705162,1828650,168416,85296, 3993660,46,0 ],
  [ 1000,50,25,2,3, 301424,139885,21922,11748, 1267974,850905,26554,8734, 3802075,1726132,73117,39922, 6262278,2858414,120428,65459, 8494415,4042283,163354,85618, 11531067,76,0 ],
  [ 1000,50,50,2,3, 305058,140344,21790,11765, 1808725,1192690,22799,7765, 6971297,3104828,63375,35150, 11970839,5445604,108826,59320, 17342674,8217948,157661,82952, 24209575,102,0 ],
  [ 1000,50,75,2,3, 305058,140344,21790,11765, 1818226,1194595,22728,7795, 7069596,3117593,63121,35286, 12151219,5485403,108493,59516, 17637477,8275989,157477,83585, 24805967,103,0 ],
  [ 1000,100,1,2,3, 46623,81252,46623,-34629, 58413,57459,58413,954, 190802,96092,190802,94710, 160459,61897,160459,98562, 205639,71318,205639,134321, -3080299,-37,24 ],
  [ 1000,100,2,2,3, 75514,84917,37757,-4702, 110453,79793,55226,15330, 304208,111706,152104,96251, 320918,100160,160459,110379, 411278,201673,205639,104803, -2424755,-32,19 ],
  [ 1000,100,3,2,3, 104405,101919,34802,829, 162493,88092,54164,24800, 417615,218986,139205,66210, 481376,312414,160459,56321, 616917,361588,205639,85110, -2267710,-30,18 ],
  [ 1000,100,5,2,3, 148491,107513,32398,8941, 254830,117477,50966,27471, 629008,368640,125802,52074, 795186,388182,159037,81401, 1018339,537359,203668,96196, -1258346,-19,-50 ],
  [ 1000,100,10,2,3, 238953,119000,29260,14688, 522609,357153,43551,13788, 1296417,579113,108035,59775, 1855162,883885,154597,80940, 2376282,1242553,198023,94477, 1499257,26,0 ],
  [ 1000,100,15,2,3, 309797,141339,27538,14974, 844433,532350,38383,14186, 2174525,953934,98842,55481, 3305237,1588284,150238,78043, 4275889,2143172,194359,96942, 5126514,52,0 ],
  [ 1000,100,25,2,3, 360140,219443,26192,10233, 1488638,906041,31176,12201, 4440001,2042050,85385,46114, 7223330,3342104,138910,74639, 9798116,4741595,188425,97241, 13841392,82,0 ],
  [ 1000,100,50,2,3, 364379,219983,26027,10314, 2125319,1355229,26790,9707, 8133097,3598751,73937,41221, 13825171,6305626,125683,68359, 19996194,9491237,181784,95500, 28594385,109,0 ],
  [ 1000,100,75,2,3, 364379,219983,26027,10314, 2136493,1357478,26706,9738, 8247885,3628288,73642,41246, 14034081,6349531,125304,68612, 20335963,9655385,181571,95362, 29186673,110,0 ],
  [ 1000,200,1,2,3, 72817,84758,72817,-11940, 79476,59899,79476,19576, 298416,126227,298416,172189, 203040,68269,203040,134771, 260655,79777,260655,180878, -2664299,-34,21 ],
  [ 1000,200,2,2,3, 110171,102892,55086,3640, 146206,84513,73103,30846, 442042,223134,221021,109454, 406080,193269,203040,106406, 521310,347420,260655,86945, -2235176,-30,18 ],
  [ 1000,200,3,2,3, 147525,107690,49175,13278, 212936,95392,70979,39181, 585668,363755,195223,73971, 609121,346821,203040,87433, 781965,402971,260655,126331, -1700167,-25,13 ],
  [ 1000,200,5,2,3, 204507,115010,44620,19527, 331568,203134,66314,25687, 853761,415386,170752,87675, 1006070,514592,201214,98296, 1290697,610190,258139,136101, -413001,-6,0 ],
  [ 1000,200,10,2,3, 321325,143361,39346,21792, 676810,396792,56401,23335, 1702043,863258,141837,69899, 2346448,1190293,195537,96346, 3011277,1470245,250940,128419, 2939481,39,0 ],
  [ 1000,200,15,2,3, 412722,327680,36686,7559, 1093086,692984,49686,18186, 2820271,1270237,128194,70456, 4180813,1966713,190037,100641, 5417343,2607887,246243,127703, 7030924,61,0 ],
  [ 1000,200,25,2,3, 477573,336026,34733,10294, 1929965,1206896,40418,15143, 5715853,2580700,109920,60291, 9145434,4215250,175874,94811, 12405519,6011959,238568,122953, 18476661,92,0 ],
  [ 1000,200,50,2,3, 483020,350065,34501,9497, 2758507,1723042,34771,13052, 10456696,4720028,95061,52152, 17533834,8027921,159398,86417, 25303233,12039514,230029,120579, 37034806,120,0 ],
  [ 1000,200,75,2,3, 483020,350065,34501,9497, 2773027,1725978,34663,13088, 10604463,4754078,94683,52236, 17799803,8188398,158927,85816, 25732935,12216873,229758,120679, 37667625,120,0 ],
  [ 1500,0,1,2,3, 32024,79522,32024,-47498, 54466,57988,54466,-3522, 110355,70174,110355,40182, 154427,61297,154427,93130, 198269,70473,198269,127796, -3284725,-38,25 ],
  [ 1500,0,2,2,3, 64048,83831,32024,-9891, 108933,80862,54466,14035, 220711,85712,110355,67499, 308854,99055,154427,104900, 396538,200065,198269,98236, -2632704,-33,21 ],
  [ 1500,0,3,2,3, 96072,101476,32024,-1801, 163399,89701,54466,24566, 331066,192916,110355,46050, 463282,310803,154427,50826, 594807,359218,198269,78530, -2479183,-32,20 ],
  [ 1500,0,5,2,3, 144963,108055,31628,8053, 261047,194157,52209,13378, 538480,342722,107696,39152, 765148,385607,153030,75908, 982006,533578,196401,89686, -1709831,-25,13 ],
  [ 1500,0,10,2,3, 245425,121581,30052,15165, 549521,378801,45793,14227, 1201934,555007,100161,53911, 1784959,878311,148747,75554, 2292746,1234338,191062,88201, 1221519,23,0 ],
  [ 1500,0,15,2,3, 324217,217233,28819,9510, 902401,561525,41018,15494, 2084761,934117,94762,52302, 3184644,1579540,144757,72959, 4125739,2045233,187534,94568, 4602975,49,0 ],
  [ 1500,0,25,2,3, 380339,224800,27661,11312, 1626780,1146969,34069,10048, 4411799,2069183,84842,45050, 7007645,3332398,134762,70678, 9443315,4610173,181602,92945, 12986402,80,0 ],
  [ 1500,0,50,2,3, 385081,225441,27506,11403, 2353988,1558462,29672,10028, 8302353,3794917,75476,40977, 13558282,6343480,123257,65589, 19259651,9233242,175088,91149, 27884040,108,0 ],
  [ 1500,0,75,2,3, 385081,225441,27506,11403, 2366801,1574998,29585,9898, 8425251,3917691,75225,40246, 13768002,6403790,122929,65752, 19587133,9510038,174885,89974, 28127982,108,0 ],
  [ 1500,25,1,2,3, 38573,80399,38573,-41826, 59732,58598,59732,1134, 137259,74079,137259,63181, 165073,62878,165073,102195, 212023,72575,212023,139448, -3172350,-38,24 ],
  [ 1500,25,2,2,3, 72712,84990,36356,-6139, 117871,82079,58935,17896, 255169,90690,127585,82240, 330145,182513,165073,73816, 424046,204262,212023,109892, -2585079,-33,21 ],
  [ 1500,25,3,2,3, 106852,102919,35617,1311, 176010,91526,58670,28161, 373080,198967,124360,58038, 495218,330500,165073,54906, 636069,365509,212023,90187, -2281362,-30,18 ],
  [ 1500,25,5,2,3, 158967,109929,34684,10699, 280231,197109,56046,16624, 594669,350780,118934,48778, 817869,393376,163574,84899, 1050095,543938,210019,101231, -1390931,-21,-55 ],
  [ 1500,25,10,2,3, 266018,137674,32574,15716, 588072,385127,49006,16912, 1303341,689998,108612,51112, 1907781,911965,158982,82985, 2451495,1274746,204291,98062, 1479431,26,0 ],
  [ 1500,25,15,2,3, 349948,220677,31107,11491, 964564,572500,43844,17821, 2246197,1154521,102100,49622, 3403538,1626967,154706,80753, 4411102,2203960,200505,100325, 5063181,52,0 ],
  [ 1500,25,25,2,3, 409697,329596,29796,5826, 1737112,1179979,36379,11668, 4730762,2128284,90976,50048, 7488171,3528726,144003,76143, 10095166,4855775,194138,100758, 14224810,83,0 ],
  [ 1500,25,50,2,3, 414741,330277,29624,6033, 2512285,1689041,31667,10377, 8883253,4102011,80757,43466, 14485447,6742601,131686,70390, 20586411,9920321,187149,96964, 29523331,110,0 ],
  [ 1500,25,75,2,3, 414741,330277,29624,6033, 2525934,1691864,31574,10426, 9014395,4120013,80486,43700, 14709433,6978230,131334,69029, 20936376,10086879,186932,96871, 29930523,111,0 ],
  [ 1500,50,1,2,3, 45121,81275,45121,-36154, 64998,59208,64998,5790, 164163,77984,164163,86179, 175718,64459,175718,111259, 225777,74677,225777,151100, -3059975,-37,24 ],
  [ 1500,50,2,2,3, 81377,86150,40688,-2387, 126809,83297,63404,21756, 289628,110183,144814,89722, 351436,185713,175718,82861, 451554,321599,225777,64978, -2533061,-33,20 ],
  [ 1500,50,3,2,3, 117632,104361,39211,4423, 188620,93351,62873,31757, 415093,205018,138364,70025, 527154,335220,175718,63978, 677331,387470,225777,96620, -2072611,-28,17 ],
  [ 1500,50,5,2,3, 172971,111804,37739,13346, 299416,200061,59883,19871, 650857,373353,130171,55501, 870590,416172,174118,90884, 1118185,570019,223637,109633, -1138808,-18,-41 ],
  [ 1500,50,10,2,3, 286611,140430,35095,17900, 626622,391602,52218,19585, 1404747,718938,117062,57151, 2030603,944969,169217,90469, 2610243,1298833,217520,109284, 2048587,32,0 ],
  [ 1500,50,15,2,3, 375679,224120,33394,13472, 1026727,687825,46669,15405, 2407634,1190376,109438,55330, 3622432,1754203,164656,84919, 4696466,2262235,213476,110647, 5571026,54,0 ],
  [ 1500,50,25,2,3, 439055,346862,31931,6705, 1847444,1200454,38690,13550, 5049725,2385100,97110,51243, 7968697,3737431,153244,81371, 10747017,5198045,206673,106711, 15321501,85,0 ],
  [ 1500,50,50,2,3, 444401,347583,31743,6916, 2670582,1733236,33663,11815, 9464153,4409255,86038,45954, 15412613,7205703,140115,74608, 21913171,10508680,199211,103677, 31772006,113,0 ],
  [ 1500,50,75,2,3, 444401,347583,31743,6916, 2685068,1736231,33563,11860, 9603540,4428386,85746,46207, 15650864,7378028,139740,73865, 22285619,10791632,198979,102625, 32245138,114,0 ],
  [ 1500,100,1,2,3, 58219,83028,58219,-24810, 75529,60428,75529,15101, 217970,100309,217970,117662, 197009,67670,197009,129339, 253285,78932,253285,174353, -2868724,-35,22 ],
  [ 1500,100,2,2,3, 98705,101806,49353,-1550, 144685,85582,72343,29552, 358545,197139,179272,80703, 394017,192164,197009,100927, 506570,345813,253285,80379, -2443125,-32,19 ],
  [ 1500,100,3,2,3, 139192,107247,46397,10648, 213842,97000,71281,38947, 499120,337685,166373,53811, 591026,345210,197009,81939, 759855,400602,253285,119751, -1911640,-27,15 ],
  [ 1500,100,5,2,3, 200979,115552,43850,18639, 337785,205964,67557,26364, 763233,403984,152647,71850, 976032,512067,195206,92793, 1254364,590788,250873,132715, -653032,-10,0 ],
  [ 1500,100,10,2,3, 327797,217642,40138,13488, 703722,418439,58644,23774, 1607560,839153,133963,64034, 2276246,1184719,189687,90961, 2927741,1462030,243978,122143, 2367759,34,0 ],
  [ 1500,100,15,2,3, 427142,331875,37968,8468, 1151054,722160,52321,19495, 2730507,1264935,124114,66617, 4060219,1958019,184555,95555, 5267193,2593778,239418,121519, 6708134,59,0 ],
  [ 1500,100,25,2,3, 497772,354720,36202,10404, 2068108,1343324,43311,15179, 5687651,2594818,109378,59478, 8929749,4220621,171726,90560, 12050718,5881137,231745,118646, 17904997,91,0 ],
  [ 1500,100,50,2,3, 503722,355522,35980,10586, 2987176,2000124,37653,12442, 10625952,4824679,96600,52739, 17266945,8065725,156972,83647, 24566690,11781469,223334,116229, 36300677,119,0 ],
  [ 1500,100,75,2,3, 503722,355522,35980,10586, 3003335,2003463,37542,12498, 10781829,4937581,96266,52181, 17533725,8242157,156551,82960, 24984105,12071076,223072,115295, 36664115,119,0 ],
  [ 1500,200,1,2,3, 84413,86534,84413,-2121, 96592,76754,96592,19838, 325585,130293,325585,195291, 239590,74042,239590,165548, 308301,103012,308301,205289, -2507481,-32,20 ],
  [ 1500,200,2,2,3, 133363,106444,66681,13459, 180438,90452,90219,44993, 496379,352132,248189,72124, 479180,313825,239590,82677, 616602,363199,308301,126702, -2250978,-30,18 ],
  [ 1500,200,3,2,3, 182313,113018,60771,23098, 264284,118185,88095,48700, 667173,390770,222391,92134, 718770,379167,239590,113201, 924903,441485,308301,161139, -1175803,-18,-50 ],
  [ 1500,200,5,2,3, 256995,123049,56072,29225, 414523,336006,82905,15703, 987986,449080,197597,107781, 1186915,558170,237383,125749, 1526722,776160,305344,150112, 93782,6,0 ],
  [ 1500,200,10,2,3, 410169,329533,50225,9874, 857923,530577,71494,27279, 2013185,940248,167765,89411, 2767533,1288233,230628,123275, 3562736,1803662,296895,146590, 3857167,45,0 ],
  [ 1500,200,15,2,3, 530067,358986,47117,15207, 1399708,867530,63623,24190, 3376253,1597104,153466,80870, 4935795,2415455,224354,114561, 6408647,3141424,291302,148510, 9103007,68,0 ],
  [ 1500,200,25,2,3, 615204,370437,44742,17801, 2509436,1645680,52554,18089, 6963503,3239519,133914,71615, 10851853,5092767,208689,110752, 14658121,7037410,281887,146552, 22789194,100,0 ],
  [ 1500,200,50,2,3, 622364,371401,44455,17926, 3620365,2355702,45635,15941, 12949552,5946105,117723,63668, 20975608,9814523,190687,101464, 29873729,14411927,271579,140562, 45151764,128,0 ],
  [ 1500,200,75,2,3, 622364,371401,44455,17926, 3639869,2359728,45498,16002, 13138407,5986520,117307,63856, 21299447,9970913,190174,101148, 30381077,14630964,271260,140626, 45993589,129,0 ],
  [ 2000,0,1,2,3, 43620,81298,43620,-37679, 71582,60957,71582,10625, 137524,74390,137524,63134, 190977,67070,190977,123907, 245915,78087,245915,167828, -3073150,-37,24 ],
  [ 2000,0,2,2,3, 87239,100719,43620,-6740, 143165,86651,71582,28257, 275048,108660,137524,83194, 381954,191109,190977,95423, 491830,344256,245915,73787, -2508077,-32,20 ],
  [ 2000,0,3,2,3, 130859,106804,43620,8018, 214747,98609,71582,38713, 412572,205566,137524,69002, 572931,343099,190977,76611, 737745,397732,245915,113338, -1877736,-27,15 ],
  [ 2000,0,5,2,3, 197451,116094,43080,17751, 344001,208795,68800,27041, 672705,378066,134541,58928, 945993,509493,189199,87300, 1218030,587007,243606,126205, -870736,-14,-27 ],
  [ 2000,0,10,2,3, 334269,220224,40931,13965, 730635,426201,60886,25369, 1513077,738197,126090,64573, 2206044,1098837,183837,92267, 2844205,1370085,237017,122843, 2488361,35,0 ],
  [ 2000,0,15,2,3, 441562,349405,39250,8192, 1209022,825036,54956,17454, 2640743,1245118,120034,63437, 3939626,1949425,179074,90464, 5117042,2579770,232593,115331, 6130371,57,0 ],
  [ 2000,0,25,2,3, 509765,359075,37074,10959, 2237093,1508905,46850,15250, 4299530,2605465,82683,32578, 6537020,1974570,125712,87739, 8655321,2616218,166448,116137, 14413926,83,0 ],
  [ 2000,0,50,2,3, 524424,360980,37459,11675, 3215846,2099007,40536,14078, 10795209,5128395,98138,51516, 17000055,8104080,154546,80873, 23830148,11721344,216638,110080, 35531209,118,0 ],
  [ 2000,0,75,2,3, 524424,360980,37459,11675, 3233643,2102748,40421,14136, 10959194,5166148,97850,51724, 17267647,8281489,154175,80234, 24235275,11813238,216386,110911, 36221361,119,0 ],
  [ 2000,25,1,2,3, 50168,82175,50168,-32006, 76848,75452,76848,1396, 164428,78145,164428,86282, 201622,68651,201622,132971, 259669,80139,259669,179530, -3004358,-36,23 ],
  [ 2000,25,2,2,3, 95904,101879,47952,-2988, 152103,87868,76052,32118, 309506,113638,154753,97934, 403245,194259,201622,104493, 519338,348452,259669,85443, -2341927,-31,19 ],
  [ 2000,25,3,2,3, 141639,108247,47213,11131, 227358,114319,75786,37680, 454585,332032,151528,40851, 604867,348369,201622,85499, 779007,404523,259669,124828, -1980273,-28,16 ],
  [ 2000,25,5,2,3, 211455,117968,46136,20397, 363186,316096,72637,9418, 728893,384624,145779,68854, 998714,517262,199743,96290, 1286120,612538,257224,134716, -889630,-14,-25 ],
  [ 2000,25,10,2,3, 354862,222980,43452,16149, 769185,506527,64099,21888, 1614484,844137,134540,64196, 2328865,1197173,194072,94308, 3002954,1493623,250246,125778, 2479910,35,0 ],
  [ 2000,25,15,2,3, 467293,352849,41537,10173, 1271185,836011,57781,19781, 2802179,1282473,127372,69078, 4158520,2076660,189024,94630, 5402406,2638545,245564,125630, 6964836,60,0 ],
  [ 2000,25,25,2,3, 547328,364007,39806,13332, 2316583,1531028,48515,16451, 5978412,2801767,114969,61089, 9194590,4434445,176819,91541, 12347768,6078196,237457,120569, 18599291,92,0 ],
  [ 2000,25,50,2,3, 554084,364950,39577,13510, 3374143,2321552,42531,13268, 11376108,5328089,103419,54982, 17927221,8501200,162975,85691, 25156908,12292883,228699,116946, 37627146,120,0 ],
  [ 2000,25,75,2,3, 554084,364950,39577,13510, 3392776,2325465,42410,13341, 11548339,5366971,103110,55191, 18209077,8665661,162581,85209, 25584518,12501170,228433,116816, 38308922,121,0 ],
  [ 2000,50,1,2,3, 56717,83051,56717,-26334, 82114,76062,82114,6051, 191331,82050,191331,109281, 212268,70232,212268,142036, 273423,82242,273423,191181, -2891983,-35,23 ],
  [ 2000,50,2,2,3, 104568,103039,52284,765, 161041,89086,80521,35978, 343965,195617,171982,74174, 424535,305869,212268,59333, 546846,352648,273423,97099, -2512349,-32,20 ],
  [ 2000,50,3,2,3, 152419,109689,50806,14243, 239969,116144,79990,41275, 496598,338083,165533,52838, 636803,353090,212268,94571, 820269,410813,273423,136485, -1760348,-25,14 ],
  [ 2000,50,5,2,3, 225459,119842,49191,23044, 382370,332933,76474,9887, 785082,407047,157016,75607, 1051435,540057,210287,102276, 1354209,735989,270842,123644, -750549,-12,0 ],
  [ 2000,50,10,2,3, 375455,225736,45974,18333, 807735,526888,67311,23404, 1715890,872927,142991,70247, 2451687,1230177,204307,101792, 3161703,1646422,263475,126273, 2913678,39,0 ],
  [ 2000,50,15,2,3, 493024,356293,43824,12154, 1333349,860871,60607,21476, 2963616,1396677,134710,71224, 4377414,2123588,198973,102447, 5687769,2796722,258535,131411, 7626837,63,0 ],
  [ 2000,50,25,2,3, 576686,367936,41941,15182, 2426915,1565538,50825,18039, 6297375,2952384,121103,64327, 9675116,4614347,186060,97322, 12999619,6435438,249993,126234, 19982846,95,0 ],
  [ 2000,50,50,2,3, 583745,382256,41696,14392, 3532440,2365746,44527,14706, 11957008,5620818,108700,57602, 18854387,8992405,171404,89654, 26483667,12994283,240761,122631, 39787139,123,0 ],
  [ 2000,50,75,2,3, 583745,382256,41696,14392, 3551910,2369831,44399,14776, 12137483,5660828,108370,57827, 19150508,9145717,170987,89328, 26933761,13092782,240480,123580, 40619144,123,0 ],
  [ 2000,100,1,2,3, 69814,84804,69814,-14990, 92645,77283,92645,15363, 245138,104375,245138,140763, 233558,73443,233558,160115, 300931,102167,300931,198764, -2711906,-34,21 ],
  [ 2000,100,2,2,3, 121897,105358,60948,8270, 178918,91520,89459,43699, 412882,220088,206441,96397, 467117,312220,233558,77448, 601862,361091,300931,120385, -2213550,-30,18 ],
  [ 2000,100,3,2,3, 173979,112575,57993,20468, 265190,119793,88397,48466, 580625,364700,193542,71975, 700675,377556,233558,107706, 902793,439115,300931,154559, -1387276,-21,9 ],
  [ 2000,100,5,2,3, 253467,123591,55302,28337, 420739,338836,84148,16381, 897458,423162,179492,94859, 1156877,555596,231375,120256, 1490388,772379,298078,143602, -123922,1,0 ],
  [ 2000,100,10,2,3, 416641,332115,51017,10350, 884836,538340,73736,28875, 1918703,916292,159892,83534, 2697330,1282659,224778,117889, 3479201,1711717,289933,147290, 3682747,44,0 ],
  [ 2000,100,15,2,3, 544487,363180,48399,16116, 1457676,896705,66258,25499, 3286489,1577286,149386,77691, 4815201,2406711,218873,109477, 6258496,3127265,284477,142329, 8813718,67,0 ],
  [ 2000,100,25,2,3, 635403,389131,46211,17911, 2647579,1708258,55447,19672, 6935301,3268152,133371,70522, 10636168,5098187,204542,106500, 14303320,7006089,275064,140331, 22346903,99,0 ],
  [ 2000,100,50,2,3, 643066,390195,45933,18062, 3849034,2528285,48517,16648, 13118808,6129256,119262,63541, 20708718,9852477,188261,98693, 29137187,14267622,264884,135178, 44432434,127,0 ],
  [ 2000,100,75,2,3, 643066,390195,45933,18062, 3870177,2532713,48377,16718, 13315772,6171523,118891,63788, 21033369,10009846,187798,98424, 29632247,14456507,264574,135498, 45305008,128,0 ],
  [ 2000,200,1,2,3, 96009,101646,96009,-5638, 113708,79573,113708,34135, 352753,134510,352753,218243, 276140,79965,276140,196174, 355947,194557,355947,161390, -2410333,-32,19 ],
  [ 2000,200,2,2,3, 156554,109996,78277,23279, 214670,96390,107335,59140, 550716,360565,275358,95075, 552280,340348,276140,105966, 711894,394098,355947,158898, -1861105,-26,15 ],
  [ 2000,200,3,2,3, 217100,118346,72367,32918, 315633,200943,105211,38230, 748678,403420,249559,115086, 828419,396487,276140,143978, 1067841,563779,355947,168021, -845767,-14,-29 ],
  [ 2000,200,5,2,3, 309483,216124,67524,20369, 497477,350493,99495,29397, 1122211,561424,224442,112157, 1367761,710358,273552,131480, 1762747,913520,352549,169845, 306418,10,0 ],
  [ 2000,200,10,2,3, 499013,356476,61104,17454, 1039037,682328,86586,29726, 2324328,1184422,193694,94992, 3188617,1589017,265718,133300, 4114196,2051949,342850,171854, 4749218,50,0 ],
  [ 2000,200,15,2,3, 647412,390292,57548,22855, 1706329,1132540,77560,26081, 3932234,1908105,178738,92006, 5690777,2798816,258672,131453, 7399950,3575010,336361,173861, 11092881,74,0 ],
  [ 2000,200,25,2,3, 752835,404848,54752,25308, 3088907,1996729,64689,22873, 8211153,3913002,157907,82657, 12558272,5970334,241505,126691, 16910723,8275502,325206,166062, 27194051,106,0 ],
  [ 2000,200,50,2,3, 761707,406074,54408,25402, 4482222,2883862,56499,20147, 15442407,7173683,140386,75170, 24417381,11573122,221976,116766, 34444226,16814299,313129,160272, 53561302,136,0 ],
  [ 2000,200,75,2,3, 761707,406074,54408,25402, 4506711,3007213,56334,18744, 15672350,7295813,139932,74791, 24799092,11738602,221420,116612, 35029219,17031516,312761,160694, 54076606,136,0 ],
  [ 2500,0,1,2,3, 55215,83074,55215,-27859, 88699,77812,88699,10887, 164692,78457,164692,86235, 227527,72844,227527,154683, 293561,101322,293561,192239, -2916332,-36,23 ],
  [ 2500,0,2,2,3, 110431,104271,55215,3080, 177397,92589,88699,42404, 329384,194094,164692,67645, 455054,311115,227527,71969, 587122,359484,293561,113819, -2421499,-32,19 ],
  [ 2500,0,3,2,3, 165646,112132,55215,17838, 266096,195252,88699,23615, 494077,338630,164692,51815, 682581,375945,227527,102212, 880683,436746,293561,147979, -1832530,-26,14 ],
  [ 2500,0,5,2,3, 249938,137469,54532,24539, 426956,341517,85391,17088, 806930,397245,161386,81937, 1126839,553171,225368,114734, 1454055,768748,290811,137061, -396319,-5,0 ],
  [ 2500,0,10,2,3, 423113,348033,51810,9193, 911748,559837,75979,29326, 1824220,892187,152018,77669, 2627128,1277235,218927,112491, 3395665,1703652,282972,141001, 3350316,42,0 ],
  [ 2500,0,15,2,3, 558907,367374,49681,17025, 1515643,911996,68893,27439, 3196725,1572134,145306,73845, 4694608,2209249,213391,112971, 6108346,3097536,277652,136855, 8824283,67,0 ],
  [ 2500,0,25,2,3, 655601,394489,47680,18990, 2785722,1861452,58340,19356, 6907099,3295434,132829,69455, 10420483,5008174,200394,104083, 13948519,6974168,268241,134122, 21816895,98,0 ],
  [ 2500,0,50,2,3, 663767,395653,47412,19151, 4077703,2731518,51400,16969, 13288064,6354472,120801,63033, 20441829,9890832,185835,95918, 28400645,14010126,258188,130823, 43653208,127,0 ],
  [ 2500,0,75,2,3, 663767,395653,47412,19151, 4100485,2736349,51256,17052, 13493138,6475591,120474,62657, 20767291,10049178,185422,95697, 28883417,14311260,257888,130109, 44278503,127,0 ],
  [ 2500,25,1,2,3, 61764,83951,61764,-22187, 93964,78422,93964,15543, 191596,82362,191596,109234, 238172,74424,238172,163748, 307315,103425,307315,203890, -2803957,-35,22 ],
  [ 2500,25,2,2,3, 119095,105431,59548,6832, 186336,93807,93168,46264, 363843,199072,181921,82386, 476344,329242,238172,73551, 614630,363681,307315,125475, -2277453,-30,18 ],
  [ 2500,25,3,2,3, 176426,113575,58809,20951, 278707,197077,92902,27210, 536090,344682,178697,63803, 714517,380665,238172,111284, 921945,443036,307315,159636, -1612606,-24,12 ],
  [ 2500,25,5,2,3, 263943,139343,57587,27185, 446140,344469,89228,20334, 863118,419818,172624,88660, 1179560,560990,235912,123714, 1522144,779158,304429,148597, -110919,2,0 ],
  [ 2500,25,10,2,3, 443706,350789,54331,11378, 950298,566313,79192,31999, 1925626,921127,160469,83708, 2749949,1310239,229162,119976, 3554414,1827190,296201,143935, 3848565,45,0 ],
  [ 2500,25,15,2,3, 584638,384155,51968,17821, 1577807,936706,71718,29141, 3358161,1609339,152644,79492, 4913502,2445095,223341,112200, 6393709,3172083,290623,146438, 9389225,69,0 ],
  [ 2500,25,25,2,3, 684960,398418,49815,20839, 2896054,1895962,60650,20944, 7226062,3537586,138963,70932, 10901009,5311962,209635,107482, 14600370,7316189,280776,140080, 22817567,100,0 ],
  [ 2500,25,50,2,3, 693428,399622,49531,20986, 4236000,2849712,53395,17474, 13868964,6555667,126081,66485, 21368995,10368260,194264,100007, 29727404,14695255,270249,136656, 45876610,129,0 ],
  [ 2500,25,75,2,3, 693428,399622,49531,20986, 4259619,2868600,53245,17388, 14082283,6783813,125735,65165, 21708722,10448827,193828,100535, 30232660,14902722,269934,136874, 46451079,129,0 ],
  [ 2500,50,1,2,3, 140064,112282,140064,27782, -34363,84754,-34363,-119117, 6793417,650066,6793417,6143351, 10554067,10779940,10554067,-225872, 14851975,15402782,14851975,-550807, 9764770,69,0 ],
  [ 2500,50,2,2,3, 127760,106591,63880,10585, 195274,95024,97637,50125, 398301,204050,199151,97126, 497635,332392,248818,82621, 642138,367877,321069,137131, -2111303,-29,17 ],
  [ 2500,50,3,2,3, 187206,115017,62402,24063, 291317,198902,97106,30805, 578103,350733,192701,75790, 746453,385386,248818,120356, 963207,533107,321069,143367, -1452416,-22,10 ],
  [ 2500,50,5,2,3, 277947,141218,60643,29832, 465325,347420,93065,23581, 919306,427875,183861,98286, 1232281,583735,246456,129709, 1590234,789518,318047,160143, 185877,8,0 ],
  [ 2500,50,10,2,3, 464299,353545,56853,13562, 988849,572789,82404,34672, 2027033,935552,168919,90957, 2872771,1408524,239398,122021, 3713163,1866898,309430,153855, 4343627,48,0 ],
  [ 2500,50,15,2,3, 610369,387599,54255,19802, 1639970,1125881,74544,23368, 3519598,1722193,159982,81700, 5132396,2506999,233291,119336, 6679073,3343449,303594,151619, 9502579,69,0 ],
  [ 2500,50,25,2,3, 714318,402347,51950,22689, 3006386,2004322,62961,20986, 7545025,3611202,145097,75650, 11381535,5491864,218876,113263, 15252220,7544669,293312,148222, 24235644,102,0 ],
  [ 2500,50,50,2,3, 723088,403592,51649,22821, 4394297,2894056,55390,18911, 14449864,6848396,131362,69104, 22296161,10750854,202692,104957, 31054164,15283415,282311,143370, 48332002,131,0 ],
  [ 2500,50,75,2,3, 723088,403592,51649,22821, 4418752,2913116,55234,18820, 14671427,6909136,130995,69306, 22650152,10913306,202234,104793, 31581903,15591154,281981,142775, 49157015,132,0 ],
  [ 2500,100,1,2,3, 81410,86580,81410,-5170, 109761,80252,109761,29509, 272307,108592,272307,163715, 270108,79216,270108,190892, 348577,193562,348577,155015, -2560066,-33,20 ],
  [ 2500,100,2,2,3, 145088,108910,72544,18089, 213150,97459,106575,57845, 467218,334571,233609,66324, 540216,339243,270108,100487, 697154,392491,348577,152332, -2069055,-29,16 ],
  [ 2500,100,3,2,3, 208767,117903,69589,30288, 316539,202552,105513,37996, 662130,377350,220710,94927, 810325,394876,270108,138483, 1045732,561410,348577,161441, -1057240,-17,-42 ],
  [ 2500,100,5,2,3, 305955,216666,66754,19481, 503694,353324,100739,30074, 1031683,535506,206337,99235, 1337722,707784,267544,125988, 1726413,909739,345283,163335, 88714,6,0 ],
  [ 2500,100,10,2,3, 505485,359057,61896,17930, 1065949,703975,88829,30164, 2229846,1174832,185820,87918, 3118414,1583493,259868,127910, 4030660,2043784,335888,165573, 4437980,48,0 ],
  [ 2500,100,15,2,3, 661832,394486,58829,23764, 1764297,1161716,80195,27390, 3842470,1902803,174658,88167, 5570183,2681662,253190,131296, 7249800,3560901,329536,167677, 10930166,74,0 ],
  [ 2500,100,25,2,3, 773034,423542,56221,25418, 3227049,2059307,67582,24455, 8182951,3927120,157364,81843, 12342587,5895396,237357,123984, 16555922,8244132,318383,159842, 26903785,106,0 ],
  [ 2500,100,50,2,3, 782409,424868,55886,25539, 4710892,3160795,59381,19539, 15611664,7355334,141924,75058, 24150492,11611077,219550,113995, 33707683,16556403,306433,155921, 52595983,135,0 ],
  [ 2500,100,75,2,3, 782409,424868,55886,25539, 4737019,3180199,59213,19460, 15849716,7524380,141515,74333, 24533013,11778085,219045,113883, 34280389,16871248,306075,155439, 53275920,136,0 ],
  [ 2500,200,1,2,3, 107605,103422,107605,4182, 130824,82542,130824,48282, 379921,215727,379921,164195, 312690,100715,312690,211974, 403593,202171,403593,201422, -2397360,-31,19 ],
  [ 2500,200,2,2,3, 179746,113548,89873,33099, 248903,116214,124451,66344, 605052,368848,302526,118102, 625379,351895,312690,136742, 807186,409277,403593,198955, -1481539,-22,10 ],
  [ 2500,200,3,2,3, 251887,123674,83962,42738, 366982,314201,122327,17594, 830183,429085,276728,133700, 938069,428833,312690,169745, 1210780,586172,403593,208203, -593267,-9,0 ],
  [ 2500,200,5,2,3, 361971,224163,78975,30067, 580432,379016,116086,40283, 1256436,596617,251287,131964, 1548606,753937,309721,158934, 1998771,982570,399754,203240, 1209281,23,0 ],
  [ 2500,200,10,2,3, 587857,370081,71982,26666, 1220150,729879,101679,40856, 2635471,1261562,219623,114492, 3609701,1765765,300808,153661, 4665655,2286496,388805,198263, 6602861,58,0 ],
  [ 2500,200,15,2,3, 764756,408261,67978,31688, 2012951,1233386,91498,35435, 4488216,2114406,204010,107900, 6445759,3058091,292989,153985, 8391254,4220937,381421,189560, 13930283,82,0 ],
  [ 2500,200,25,2,3, 890467,439259,64761,32815, 3668377,2361663,76825,27366, 9458802,4465770,181900,96020, 14264691,6847350,274321,142641, 19163325,9512995,368525,185583, 31834203,113,0 ],
  [ 2500,200,50,2,3, 901051,440747,64361,32879, 5344080,3530258,67362,22863, 17935263,8476611,163048,85988, 27859155,13331722,253265,132068, 39014722,19103031,354679,181015, 61504770,142,0 ],
  [ 2500,200,75,2,3, 901051,440747,64361,32879, 5373553,3536464,67169,22964, 18206294,8529755,162556,86398, 28298736,13549420,252667,131690, 39677361,19446257,354262,180635, 62632948,143,0 ],
  [ 3000,0,1,2,3, 66811,84850,66811,-18039, 105815,80781,105815,25034, 191861,82673,191861,109187, 264077,78617,264077,185460, 341207,192717,341207,148490, -2764491,-34,22 ],
  [ 3000,0,2,2,3, 133622,107823,66811,12900, 211630,98528,105815,56551, 383721,202527,191861,90597, 528153,337638,264077,95258, 682414,390384,341207,146015, -2031627,-28,16 ],
  [ 3000,0,3,2,3, 200433,117460,66811,27658, 317445,204160,105815,37761, 575582,351280,191861,74767, 792230,393265,264077,132988, 1023622,559040,341207,154861, -1268713,-20,-55 ],
  [ 3000,0,5,2,3, 302426,217208,65984,18593, 509910,370039,101982,27974, 941155,509439,188231,86343, 1307684,705209,261537,120495, 1690080,905908,338016,156834, -172574,0,0 ],
  [ 3000,0,10,2,3, 511956,361638,62689,18406, 1092861,711737,91072,31760, 2135363,1073876,177947,88457, 3048212,1577919,254018,122524, 3947124,2035619,328927,159292, 4380323,48,0 ],
  [ 3000,0,15,2,3, 676252,398680,60111,24673, 1822265,1190892,82830,28699, 3752706,1776935,170578,89808, 5449590,2672418,247709,126235, 7099650,3546243,322711,161518, 10886254,73,0 ],
  [ 3000,0,25,2,3, 793233,428900,57690,26497, 3365192,2300236,70475,22303, 8154749,3954253,156822,80779, 12126903,5885690,233210,120023, 16201121,8128381,311560,155245, 26037622,105,0 ],
  [ 3000,0,50,2,3, 803111,430326,57365,26628, 4939561,3273563,62263,21000, 15780920,7658900,143463,73837, 23883603,11664407,217124,111084, 32971141,16396777,299738,150676, 51831735,134,0 ],
  [ 3000,0,75,2,3, 803111,430326,57365,26628, 4967327,3279484,62092,21098, 16027082,7709383,143099,74265, 24266935,11831844,216669,111028, 33531559,16612861,299389,151060, 52913443,135,0 ],
  [ 3000,25,1,2,3, 73360,85727,73360,-12367, 111081,81391,111081,29690, 218764,86578,218764,132186, 274722,95174,274722,179548, 354961,194819,354961,160142, -2674220,-34,21 ],
  [ 3000,25,2,2,3, 142287,108983,71143,16652, 220568,99745,110284,60411, 418180,313555,209090,52312, 549444,341288,274722,104078, 709922,395080,354961,157421, -2110854,-29,17 ],
  [ 3000,25,3,2,3, 211214,118902,70405,30770, 330055,205985,110018,41357, 617595,371846,205865,81916, 824166,413012,274722,137051, 1064884,565381,354961,166501, -1104393,-17,-45 ],
  [ 3000,25,5,2,3, 316430,219082,69039,21240, 529095,372991,105819,31221, 997343,532011,199469,93066, 1360405,713028,272081,129475, 1758169,916318,351634,168370, 112826,7,0 ],
  [ 3000,25,10,2,3, 532549,364394,65210,20590, 1131412,718213,94284,34433, 2236769,1179816,186397,88079, 3171034,1595947,264253,131257, 4105873,2075377,342156,169208, 4783912,50,0 ],
  [ 3000,25,15,2,3, 701983,402124,62398,26654, 1884428,1201867,85656,31026, 3914143,1920340,177916,90627, 5668484,2828306,257658,129099, 7385013,3818060,335682,162134, 11282252,75,0 ],
  [ 3000,25,25,2,3, 822591,432829,59825,28346, 3475524,2334746,72786,23891, 8473712,4104869,162956,84016, 12607429,6189029,242451,123431, 16852972,8356861,324096,163387, 27330805,107,0 ],
  [ 3000,25,50,2,3, 832771,434295,59484,28463, 5097858,3496107,64259,20190, 16361820,7767079,148744,78134, 24810768,12046502,225552,116039, 34297901,16983937,311799,157400, 54148600,136,0 ],
  [ 3000,25,75,2,3, 832771,434295,59484,28463, 5126461,3502200,64081,20303, 16616226,8016256,148359,76785, 25208366,12216516,225075,115999, 34880802,17316963,311436,156820, 54744453,137,0 ],
  [ 3000,50,1,2,3, 79908,86603,79908,-6695, 116346,82001,116346,34345, 245668,104998,245668,140670, 285367,96805,285367,188563, 368715,196972,368715,171744, -2595345,-33,21 ],
  [ 3000,50,2,2,3, 150951,110142,75476,20404, 229506,114848,114753,57329, 452638,332898,226319,59870, 570735,344489,285367,113123, 737431,399277,368715,169077, -2021788,-28,16 ],
  [ 3000,50,3,2,3, 221994,120345,73998,33883, 342666,207810,114222,44952, 659609,377898,219870,93904, 856102,417732,285367,146123, 1106146,571671,368715,178158, -884468,-14,-31 ],
  [ 3000,50,5,2,3, 330434,220956,72095,23886, 548279,375943,109656,34467, 1053531,540069,210706,102692, 1413126,735774,282625,135470, 1826259,942349,365252,176782, 398450,12,0 ],
  [ 3000,50,10,2,3, 553142,367151,67732,22775, 1169962,724689,97497,37106, 2338176,1194241,194848,95328, 3293855,1628901,274488,138746, 4264622,2198865,355385,172146, 5315661,53,0 ],
  [ 3000,50,15,2,3, 727714,405568,64686,28635, 1946592,1226726,88481,32721, 4075579,2034545,185254,92774, 5887378,2875234,267608,136916, 7670377,3876786,348653,172436, 12015161,77,0 ],
  [ 3000,50,25,2,3, 851949,436758,61960,30196, 3585856,2369256,75096,25479, 8792675,4163971,169090,89014, 13087955,6368881,251691,129213, 17504822,8698382,336631,169355, 28935531,109,0 ],
  [ 3000,50,50,2,3, 862432,438265,61602,30298, 5256155,3540452,66254,21627, 16942720,8074324,154025,80622, 25737934,12509453,233981,120259, 35624660,17669566,323861,163228, 56382509,138,0 ],
  [ 3000,50,75,2,3, 862432,438265,61602,30298, 5285594,3546717,66070,21736, 17205371,8218578,153619,80239, 26149796,12615665,233480,120840, 36230045,17907976,323483,163590, 57439771,139,0 ],
  [ 3000,100,1,2,3, 93006,101693,93006,-8687, 126878,83071,126878,43806, 299475,112808,299475,186667, 306658,100116,306658,206542, 396223,201326,396223,194897, -2425287,-32,19 ],
  [ 3000,100,2,2,3, 168280,112462,84140,27909, 247382,117283,123691,65050, 521555,342854,260778,89351, 613316,350789,306658,131263, 792447,407669,396223,192389, -1689489,-25,13 ],
  [ 3000,100,3,2,3, 243554,123231,81185,40108, 367887,315810,122629,17359, 743635,403015,247878,113540, 919974,427222,306658,164251, 1188670,583802,396223,201622, -804741,-13,-22 ],
  [ 3000,100,5,2,3, 358443,224705,78206,29179, 586648,381846,117330,40960, 1165908,570700,233182,119042, 1518568,751362,303714,153441, 1962438,963119,392488,199864, 1002750,21,0 ],
  [ 3000,100,10,2,3, 594328,385999,72775,25510, 1247063,825226,103922,35153, 2540988,1251971,211749,107418, 3539499,1760391,294958,148259, 4582120,2262810,381843,193276, 6014322,56,0 ],
  [ 3000,100,15,2,3, 779176,425792,69260,31412, 2070918,1248526,94133,37381, 4398452,2109254,199930,104054, 6325165,3049547,287508,148892, 8241104,4093938,374596,188508, 13677016,81,0 ],
  [ 3000,100,25,2,3, 910665,529653,66230,27710, 3806520,2498091,79718,27402, 9430601,4494403,181358,94927, 14049006,6772463,270173,139934, 18808524,9382223,361702,181275, 31053579,112,0 ],
  [ 3000,100,50,2,3, 921752,531241,65839,27894, 5572749,3702840,70245,23570, 18104519,8582762,164587,86561, 27592266,13369676,250839,129296, 38278180,19026835,347983,175012, 60619392,142,0 ],
  [ 3000,100,75,2,3, 921752,531241,65839,27894, 5603861,3813799,70048,22376, 18383660,8833823,164140,85266, 28032658,13588903,250292,128962, 38928531,19271850,347576,175506, 61044884,142,0 ],
  [ 3000,200,1,2,3, 119200,105198,119200,14002, 147940,85512,147940,62429, 407090,219943,407090,187147, 349239,186796,349239,162444, 451239,322876,451239,128363, -2384941,-31,19 ],
  [ 3000,200,2,2,3, 202937,117100,101469,42919, 283135,196003,141568,43566, 659389,391796,329695,133796, 698479,378467,349239,160006, 902479,440226,451239,231126, -1358947,-21,9 ],
  [ 3000,200,3,2,3, 286674,142338,95558,48112, 418330,336844,139443,27162, 911689,441585,303896,156701, 1047718,541587,349239,168711, 1353718,737876,451239,205280, -289252,-3,0 ],
  [ 3000,200,5,2,3, 414459,333068,90427,17758, 663386,392153,132677,54247, 1390660,738011,278132,130530, 1729452,879822,345890,169926, 2234796,1234871,446959,199985, 1248958,24,0 ],
  [ 3000,200,10,2,3, 676701,397024,82861,34246, 1401264,865014,116772,44687, 2946614,1415552,245551,127589, 4030785,1985792,335899,170416, 5217115,2618564,434760,216546, 7814837,63,0 ],
  [ 3000,200,15,2,3, 882101,439567,78409,39336, 2319572,1498396,105435,37326, 5044198,2439923,229282,118376, 7200741,3426526,327306,171555, 9382558,4754024,426480,210388, 15837853,86,0 ],
  [ 3000,200,25,2,3, 1028098,545370,74771,35108, 4247848,2800447,88960,30312, 10706452,5139104,205893,107064, 15971110,7753069,307137,158039, 21415927,10538496,411845,209181, 35777702,118,0 ],
  [ 3000,200,50,2,3, 1040394,547119,74314,35234, 6205937,4162768,78226,25754, 20428119,9702689,185710,97504, 31300929,15010014,284554,148099, 43585219,21573012,396229,200111, 69363666,148,0 ],
  [ 3000,200,75,2,3, 1040394,547119,74314,35234, 6240395,4170064,78005,25879, 20740238,9762198,185181,98018, 31798380,15317109,283914,147154, 44325503,21831188,395763,200842, 70653236,149,0 ],
  [ 1000,0,1,3,3, 29417,78861,29417,-49444, 50045,56904,50045,-6859, 101968,68549,101968,33418, 137015,58298,137015,78717, 167824,65448,167824,102376, -3358151,-39,25 ],
  [ 1000,0,2,3,3, 58833,82507,29417,-11837, 100089,78694,50045,10698, 203936,82464,101968,60736, 274030,78080,137015,97975, 335647,190016,167824,72816, -2757452,-34,22 ],
  [ 1000,0,3,3,3, 88250,86154,29417,698, 150134,86599,50045,21178, 305903,111043,101968,64953, 411046,193196,137015,72617, 503471,343994,167824,53159, -2308195,-31,18 ],
  [ 1000,0,5,3,3, 133164,105061,29054,6131, 238835,114858,47767,24795, 496507,334595,99301,32382, 680442,355968,136088,64895, 832437,409399,166487,84608, -1742095,-25,13 ],
  [ 1000,0,10,3,3, 225477,116523,27609,13341, 495587,351665,41299,11993, 1100958,535603,91747,47113, 1597048,750013,133087,70586, 1951897,949533,162658,83530, 760857,18,0 ],
  [ 1000,0,15,3,3, 297896,138861,26480,14136, 803856,523409,36539,12748, 1899323,883838,86333,46158, 2857059,1287673,129866,71336, 3525956,1701233,160271,82942, 4080504,46,0 ],
  [ 1000,0,25,3,3, 349499,145287,25418,14852, 1421044,890368,29760,11114, 3973745,1744942,76418,42862, 6286200,2857905,120888,65929, 8147635,3872215,156685,82220, 12325972,78,0 ],
  [ 1000,0,50,3,3, 353862,145832,25276,14859, 2032292,1259417,25617,9742, 7382499,3256449,67114,37510, 12104452,5470666,110040,60307, 16771908,7881911,152472,80818, 25471131,104,0 ],
  [ 1000,0,75,3,3, 353862,145832,25276,14859, 2043056,1275449,25538,9595, 7488984,3269968,66866,37670, 12289318,5510917,109726,60521, 17060860,8067651,152329,80297, 25954564,105,0 ],
  [ 1000,25,1,3,3, 37783,79980,37783,-42198, 56875,57705,56875,-830, 132650,73001,132650,59650, 149389,60137,149389,89252, 183148,67792,183148,115356, -3224452,-38,25 ],
  [ 1000,25,2,3,3, 70246,84035,35123,-6895, 111838,80292,55919,15773, 243878,88231,121939,77824, 298778,96722,149389,101028, 366297,194695,183148,85801, -2578963,-33,21 ],
  [ 1000,25,3,3,3, 102709,101426,34236,428, 166801,88845,55600,25985, 355107,195126,118369,53327, 448167,307297,149389,46957, 549445,351157,183148,66096, -2431974,-32,19 ],
  [ 1000,25,5,3,3, 152266,107618,33222,9741, 264330,118745,52866,29117, 563180,344150,112636,43806, 741839,379998,148368,72368, 908418,436637,181684,94356, -1385092,-21,9 ],
  [ 1000,25,10,3,3, 254067,120349,31110,16374, 547216,374148,45601,14422, 1223835,552922,101986,55909, 1740877,866359,145073,72877, 2129852,992107,177488,94812, 1333597,25,0 ],
  [ 1000,25,15,3,3, 333885,143678,29679,16907, 887516,538077,40342,15884, 2097357,926356,95334,53227, 3114482,1435510,141567,76317, 3846969,1849177,174862,90809, 5068078,51,0 ],
  [ 1000,25,25,3,3, 390711,222503,28415,12233, 1570569,932096,32891,13371, 4371716,2012662,84071,45366, 6856141,3174685,131849,70797, 8886223,4242949,170889,89294, 13452473,81,0 ],
  [ 1000,25,50,3,3, 395509,223106,28251,12314, 2247731,1506743,28333,9340, 8118298,3583531,73803,41225, 13214320,5989821,120130,65677, 18286810,8626278,166244,87823, 27580366,107,0 ],
  [ 1000,25,75,3,3, 395509,223106,28251,12314, 2259645,1509126,28246,9381, 8235525,3613185,73531,41271, 13416560,6141051,119791,64960, 18601771,8770957,166087,87775, 28007841,108,0 ],
  [ 1000,50,1,3,3, 46149,81100,46149,-34951, 63705,58505,63705,5199, 163333,77452,163333,85881, 161763,61976,161763,99786, 198473,70137,198473,128336, -3090754,-37,24 ],
  [ 1000,50,2,3,3, 81659,85562,40829,-1952, 123586,81891,61793,20848, 283821,108512,141911,87654, 323525,180744,161763,71390, 396946,199424,198473,98761, -2530395,-33,20 ],
  [ 1000,50,3,3,3, 117169,103361,39056,4602, 183467,91241,61156,30742, 404310,202208,134770,67367, 485288,312788,161763,57500, 595419,358171,198473,79083, -2164488,-29,17 ],
  [ 1000,50,5,3,3, 170872,109365,37281,13420, 222672,186203,44534,7294, 534687,202208,106937,66496, 665316,312788,133063,70505, 809639,358171,161928,90294, -1429128,-21,10 ],
  [ 1000,50,10,3,3, 282657,137512,34611,17773, 598846,382595,49904,18021, 1346713,690957,112226,54646, 1884706,888120,157059,83049, 2307806,1232302,192317,89625, 1616612,28,0 ],
  [ 1000,50,15,3,3, 369874,220195,32878,13305, 971176,566629,44144,18388, 2295390,1074774,104336,55483, 3371905,1597022,153268,80676, 4167982,2042551,189454,96610, 5421189,53,0 ],
  [ 1000,50,25,3,3, 431923,328885,31413,7494, 1720094,1150523,36023,11928, 4769688,2110347,91725,51141, 7426081,3384055,142809,77731, 9624811,4698013,185093,94746, 14387598,83,0 ],
  [ 1000,50,50,3,3, 437156,329547,31225,7686, 2463169,1560483,31048,11378, 8854097,3926778,80492,44794, 14324187,6523702,130220,70914, 19801713,9440855,180016,94190, 30072389,111,0 ],
  [ 1000,50,75,3,3, 437156,329547,31225,7686, 2476233,1563102,30953,11414, 8982065,3957901,80197,44859, 14543802,6649249,129855,70487, 20142681,9521244,179845,94834, 30651937,112,0 ],
  [ 1000,100,1,3,3, 62881,83339,62881,-20458, 77365,60107,77365,17258, 224697,100869,224697,123828, 186510,65704,186510,120805, 229122,74875,229122,154247, -2856857,-35,22 ],
  [ 1000,100,2,3,3, 104484,101954,52242,1265, 147083,84938,73542,31073, 363707,197046,181853,83330, 373020,188225,186510,92398, 458245,322022,229122,68111, -2441034,-32,19 ],
  [ 1000,100,3,3,3, 146087,107232,48696,12952, 216801,96033,72267,40256, 502717,336938,167572,55260, 559530,339297,186510,73411, 687367,372748,229122,104873, -1930497,-27,15 ],
  [ 1000,100,5,3,3, 209572,115287,45725,20571, 340815,204253,68163,27312, 763199,401847,152640,72270, 926028,422237,185206,100758, 1136363,570888,227273,113095, -608045,-10,0 ],
  [ 1000,100,10,3,3, 339837,216865,41613,15058, 702104,413674,58509,24036, 1592468,754775,132706,69808, 2172363,960296,181030,101006, 2663715,1301930,221976,113482, 2692913,37,0 ],
  [ 1000,100,15,3,3, 441852,330695,39276,9881, 1138496,712700,51750,19354, 2691458,1235160,122339,66195, 3886750,1783934,176670,95583, 4810008,2271279,218637,115397, 6647253,59,0 ],
  [ 1000,100,25,3,3, 514346,353253,37407,11716, 2019143,1233829,42286,16446, 5565632,2462736,107031,59671, 8565961,4002289,164730,87763, 11101987,5310368,213500,111377, 17709661,90,0 ],
  [ 1000,100,50,3,3, 520450,354031,37175,11887, 2894047,1862899,36480,12998, 10325696,4491057,93870,53042, 16543922,7546735,150399,81793, 22831519,10801078,207559,109368, 35753904,118,0 ],
  [ 1000,100,75,3,3, 520450,354031,37175,11887, 2909410,1865991,36368,13043, 10475146,4602119,93528,52438, 16798286,7596914,149985,82155, 23224501,10972887,207362,109389, 36339671,119,0 ],
  [ 1000,200,1,3,3, 96346,87818,96346,8528, 104686,77196,104686,27490, 347427,133039,347427,214388, 236005,73111,236005,162894, 290421,84253,290421,206168, -2399148,-31,19 ],
  [ 1000,200,2,3,3, 150136,108064,75068,21036, 194078,91332,97039,51373, 523478,355194,261739,84142, 472009,311945,236005,80032, 580842,357009,290421,111917, -2122290,-29,17 ],
  [ 1000,200,3,3,3, 203925,114973,67975,29651, 283469,193352,94490,30039, 699530,394148,233177,101794, 708014,376338,236005,110558, 871263,416523,290421,151580, -1238195,-19,-55 ],
  [ 1000,200,5,3,3, 285979,125514,62396,35011, 442795,338032,88559,20953, 1029892,529935,205978,99991, 1171613,553787,234323,123565, 1440289,745417,288058,138974, 140438,7,0 ],
  [ 1000,200,10,3,3, 454197,333037,55616,14836, 908621,534298,75718,31194, 2083977,945048,173665,94911, 2747678,1279889,228973,122316, 3375534,1670899,281294,142053, 4267406,47,0 ],
  [ 1000,200,15,3,3, 585808,363298,52072,19779, 1473136,872841,66961,27286, 3483593,1602498,158345,85504, 4916442,2402331,223475,114278, 6094059,2969286,277003,142035, 9652323,70,0 ],
  [ 1000,200,25,3,3, 679194,388652,49396,21130, 2617242,1652640,54811,20201, 7157518,3243015,137645,75279, 10845722,5051640,208572,111425, 14056338,6776281,270314,140001, 23541304,101,0 ],
  [ 1000,200,50,3,3, 687038,389663,49074,21241, 3755801,2363531,47342,17550, 13268893,5924730,120626,66765, 20983391,9606878,190758,103423, 28891130,13803575,262647,137160, 46577950,129,0 ],
  [ 1000,200,75,3,3, 687038,389663,49074,21241, 3775765,2367568,47197,17602, 13461308,5964670,120190,66934, 21307255,9790372,190243,102829, 29388141,13906883,262394,138226, 47465623,130,0 ],
  [ 1500,0,1,3,3, 46114,81418,46114,-35304, 72969,60803,72969,12166, 135270,73720,135270,61550, 179499,65018,179499,114481, 220911,73940,220911,146971, -3090948,-37,24 ],
  [ 1500,0,2,3,3, 92229,100959,46114,-4365, 145939,86343,72969,29798, 270540,92804,135270,88868, 358998,186953,179499,86023, 441822,320240,220911,60791, -2499000,-32,20 ],
  [ 1500,0,3,3,3, 138343,107163,46114,10393, 218908,98147,72969,40254, 405810,203554,135270,67419, 538497,336941,179499,67185, 662732,369619,220911,97704, -1919957,-27,15 ],
  [ 1500,0,5,3,3, 208746,116637,45545,20097, 350089,207995,70018,28419, 661451,374713,132290,57348, 891066,419264,178213,94360, 1095835,566689,219167,105829, -843507,-14,-28 ],
  [ 1500,0,10,3,3, 353412,221152,43275,16195, 739521,424079,61627,26287, 1486170,730143,123848,63002, 2090332,953836,174194,94708, 2570200,1292829,214183,106448, 2457607,35,0 ],
  [ 1500,0,15,3,3, 466873,350640,41500,10332, 1218339,820938,55379,18064, 2591550,1230345,117798,61873, 3745370,1773978,170244,89609, 4641311,2255639,210969,108440, 6056772,56,0 ],
  [ 1500,0,25,3,3, 547688,361538,39832,13538, 2208998,1487413,46262,15112, 5545450,2572895,106643,57165, 8312185,3897695,159850,84894, 10700360,5190586,205776,105957, 16890335,89,0 ],
  [ 1500,0,50,3,3, 554446,361538,39603,13779, 2562039,2000208,32295,7082, 8439227,2602895,76720,53058, 12776386,3897695,116149,80715, 16752976,5250586,152300,104567, 33805426,116,0 ],
  [ 1500,0,75,3,3, 554516,362459,39608,13718, 3225969,2088046,40325,14224, 10724234,4866264,95752,52303, 16487210,7762810,147207,77896, 22370975,10819817,199741,103135, 35504018,118,0 ],
  [ 1500,25,1,3,3, 54481,82538,54481,-28057, 79800,75489,79800,4310, 165952,78021,165952,87931, 191873,66857,191873,125016, 236235,76234,236235,160001, -3000833,-36,23 ],
  [ 1500,25,2,3,3, 103642,102486,51821,578, 157687,87941,78844,34873, 310483,113086,155241,98698, 383745,190668,191873,96539, 472471,324969,236235,73751, -2331908,-31,19 ],
  [ 1500,25,3,3,3, 152803,109098,50934,14568, 235575,114428,78525,40382, 455013,331052,151671,41320, 575618,342982,191873,77545, 708706,392803,236235,105301, -1986106,-28,16 ],
  [ 1500,25,5,3,3, 227848,119194,49712,23706, 375584,316231,75117,11871, 728124,382769,145625,69071, 952462,428318,190492,104829, 1171817,577756,234363,118812, -779848,-12,-17 ],
  [ 1500,25,10,3,3, 382002,224978,46776,19227, 791151,506526,65929,23719, 1609047,839128,134087,64160, 2234161,1098384,186180,94648, 2748154,1335504,229013,117721, 2588602,36,0 ],
  [ 1500,25,15,3,3, 502862,355457,44699,13103, 1301999,835606,59182,21200, 2789583,1272863,126799,68942, 4002793,1949967,181945,93310, 4962324,2532344,225560,110454, 6910991,60,0 ],
  [ 1500,25,25,3,3, 588900,380390,42829,15164, 2358522,1528991,49393,17372, 5943421,2763615,114297,61150, 8882125,4214625,170810,89760, 11438947,5629579,219980,111719, 18384065,92,0 ],
  [ 1500,25,50,3,3, 596163,381370,42583,15342, 3423790,2317691,43157,13942, 11299891,5171515,102726,55713, 17341610,8107338,157651,83948, 23507049,11348606,213700,110531, 37299865,120,0 ],
  [ 1500,25,75,3,3, 596163,381370,42583,15342, 3442558,2321572,43032,14012, 11470775,5271966,102418,55347, 17614452,8269608,157272,83436, 23911885,11552533,213499,110351, 37814869,120,0 ],
  [ 1500,50,1,3,3, 62847,83657,62847,-20811, 86630,76290,86630,10340, 196635,82472,196635,114163, 204246,68696,204246,135551, 251560,78578,251560,172982, -2867135,-35,22 ],
  [ 1500,50,2,3,3, 115054,104014,57527,5520, 169436,89540,84718,39948, 350425,195853,175213,77286, 408493,194333,204246,107080, 503120,345319,251560,78901, -2318987,-31,18 ],
  [ 1500,50,3,3,3, 167262,111034,55754,18743, 252242,116825,84081,45139, 504216,338134,168072,55361, 612739,348473,204246,88089, 754680,399817,251560,118288, -1718620,-25,13 ],
  [ 1500,50,5,3,3, 246950,121750,53880,27316, 401079,334002,80216,13415, 794797,406689,158959,77622, 1013858,517730,202772,99226, 1247798,589322,249560,131695, -585177,-9,0 ],
  [ 1500,50,10,3,3, 410592,329671,50277,9909, 842780,527508,70232,26273, 1731925,856447,144327,72956, 2377990,1201253,198166,98061, 2926109,1463359,243842,121896, 2710037,37,0 ],
  [ 1500,50,15,3,3, 538851,360274,47898,15874, 1385659,864159,62985,23705, 2987617,1392231,135801,72518, 4260216,2082827,193646,98972, 5283337,2596457,240152,122131, 7760321,63,0 ],
  [ 1500,50,25,3,3, 630112,385905,45826,17760, 2508047,1570718,52525,19630, 6341393,2925285,121950,65694, 9452066,4530905,181770,94638, 12177535,5999812,234183,118802, 20049929,95,0 ],
  [ 1500,50,50,3,3, 637810,386944,45558,17919, 3639229,2372931,45873,15962, 12035690,5577247,109415,58713, 18451478,8639669,167741,89198, 25021952,12093524,227472,117531, 40111125,123,0 ],
  [ 1500,50,75,3,3, 637810,386944,45558,17919, 3659146,2390934,45739,15853, 12217316,5616532,109083,58936, 18741694,8776306,167337,88977, 25452795,12271960,227257,117686, 40849954,124,0 ],
  [ 1500,100,1,3,3, 79579,85897,79579,-6318, 100290,77892,100290,22398, 257999,105890,257999,152110, 228994,72424,228994,156570, 282209,83317,282209,198892, -2633239,-33,21 ],
  [ 1500,100,2,3,3, 137880,107069,68940,15406, 192933,92737,96467,50098, 430311,221902,215156,104205, 457987,310173,228994,73907, 564419,354726,282209,104846, -2111377,-29,17 ],
  [ 1500,100,3,3,3, 196181,114904,65394,27092, 285576,195467,95192,30036, 602623,366814,200874,78603, 686981,374482,228994,104166, 846628,413894,282209,144245, -1473032,-22,10 ],
  [ 1500,100,5,3,3, 285154,140200,62215,31626, 452069,341624,90414,22089, 928143,425801,185629,100468, 1136651,550964,227330,117137, 1399761,741367,279952,131679, 26781,5,0 ],
  [ 1500,100,10,3,3, 467772,350660,57278,14340, 946038,544553,78837,33457, 1977679,920416,164807,88105, 2665647,1273579,222137,116006, 3282018,1661948,273502,135006, 3977408,45,0 ],
  [ 1500,100,15,3,3, 610829,369907,54296,21415, 1552979,907379,70590,29345, 3383685,1583168,153804,81842, 4775062,2203408,217048,116893, 5925362,2937776,269335,135799, 9673588,70,0 ],
  [ 1500,100,25,3,3, 712535,396937,51821,22953, 2807096,1728024,58787,22598, 7137336,3277675,137256,74224, 10591946,4960221,203691,108302, 13654711,6612018,262591,135436, 23471502,101,0 ],
  [ 1500,100,50,3,3, 721104,398092,51507,23072, 4070106,2675497,51304,17579, 13507289,6156041,122794,66830, 20671213,9662603,187920,100078, 28051757,13521756,255016,132091, 45765273,129,0 ],
  [ 1500,100,75,3,3, 721104,398092,51507,23072, 4092324,2680088,51154,17653, 13710397,6304465,122414,66124, 20996178,9832831,187466,99673, 28534615,13753363,254773,131975, 46352612,129,0 ],
  [ 1500,200,1,3,3, 113044,103712,113044,9332, 127611,80945,127611,46666, 380729,215209,380729,165519, 278488,79980,278488,198508, 343508,192346,343508,151162, -2434044,-32,19 ],
  [ 1500,200,2,3,3, 183531,113178,91766,35176, 239927,99131,119964,70398, 590082,365534,295041,112274, 556977,340361,278488,108308, 687016,389663,343508,148677, -1621163,-24,12 ],
  [ 1500,200,3,3,3, 254019,122645,84673,43791, 352244,205051,117415,49064, 799436,409659,266479,129926, 835465,396497,278488,146323, 1030525,541449,343508,163025, -507495,-8,0 ],
  [ 1500,200,5,3,3, 361562,222126,78886,30422, 554049,357168,110810,39376, 1194836,570054,238967,124956, 1382236,710717,276447,134304, 1703687,902857,340737,160166, 786159,18,0 ],
  [ 1500,200,10,3,3, 582132,365966,71281,26469, 1152555,711062,96046,36791, 2469189,1215089,205766,104508, 3240962,1592172,270080,137399, 3993836,2029367,332820,163706, 5583774,54,0 ],
  [ 1500,200,15,3,3, 754785,402510,67092,31313, 1887619,1171870,85801,32534, 4175819,2026005,189810,97719, 5804754,2806829,263852,136269, 7209414,3522192,327701,167601, 12278275,77,0 ],
  [ 1500,200,25,3,3, 877383,432336,63810,32367, 3405195,2072985,71313,27900, 8729223,4057954,167870,89832, 12871707,6103055,247533,130166, 16609063,8077931,319405,164060, 29398955,110,0 ],
  [ 1500,200,50,3,3, 887693,433724,63407,32426, 4931860,3176129,62166,22131, 16450486,7498199,149550,81384, 25110682,11722696,228279,121709, 34111368,16440422,310103,160645, 56859051,139,0 ],
  [ 1500,200,75,3,3, 887693,433724,63407,32426, 4958678,3181665,61983,22213, 16696559,7623451,149076,81010, 25505147,11917279,227725,121320, 34698255,16770590,309806,160068, 57681283,139,0 ],
  [ 2000,0,1,3,3, 62812,83975,62812,-21163, 95894,78588,95894,17306, 168572,78740,168572,89832, 221983,71737,221983,150246, 273998,82381,273998,191616, -2867330,-35,22 ],
  [ 2000,0,2,3,3, 125625,106074,62812,9775, 191789,94142,95894,48823, 337144,194660,168572,71242, 443966,308902,221983,67532, 547996,352944,273998,97526, -2345841,-31,19 ],
  [ 2000,0,3,3,3, 188437,114835,62812,24534, 287683,197581,95894,30034, 505716,339480,168572,55412, 665948,372626,221983,97774, 821994,411265,273998,136910, -1707870,-25,13 ],
  [ 2000,0,5,3,3, 284329,141550,62035,31152, 461343,345366,92269,23195, 826394,413181,165279,82643, 1101689,548041,220338,110730, 1359234,737218,271847,124403, -242181,-2,0 ],
  [ 2000,0,10,3,3, 481347,354947,58940,15478, 983455,568842,81955,34551, 1871382,895634,155948,81312, 2583617,1267120,215301,109708, 3188503,1652797,265709,127975, 3698518,44,0 ],
  [ 2000,0,15,3,3, 635849,389853,56520,21866, 1632822,1046118,74219,26668, 3283776,1576852,149263,77587, 4633682,2193452,210622,110920, 5756665,2921636,261667,128865, 8990267,67,0 ],
  [ 2000,0,25,3,3, 745877,418558,54246,23805, 2996951,1995343,62763,20976, 7117154,3310684,136868,73201, 10338170,4964237,198811,103345, 13253084,6576116,254867,128403, 22510589,99,0 ],
  [ 2000,0,50,3,3, 755171,419857,53941,23951, 4384411,2896848,55266,18751, 13745685,6480237,124961,66050, 20359034,9703950,185082,96864, 27212384,13369748,247385,125842, 44899361,128,0 ],
  [ 2000,0,75,3,3, 755171,419857,53941,23951, 4408882,2901992,55111,18836, 13959485,6525045,124638,66379, 20685102,9889866,184688,96386, 27681089,13570583,247153,125987, 45745618,129,0 ],
  [ 2000,25,1,3,3, 71178,85095,71178,-13917, 102724,79389,102724,23336, 199254,83191,199254,116063, 234356,73576,234356,160780, 289323,100397,289323,188926, -2744805,-34,22 ],
  [ 2000,25,2,3,3, 137037,107601,68519,14718, 203537,95740,101769,53898, 377087,200427,188543,88330, 468713,327543,234356,70585, 578645,357623,289323,110511, -2167352,-29,17 ],
  [ 2000,25,3,3,3, 202896,116771,67632,28709, 304350,199977,101450,34791, 554919,346563,184973,69452, 703069,378117,234356,108318, 867968,433950,289323,144673, -1451557,-22,10 ],
  [ 2000,25,5,3,3, 303431,144106,66203,34762, 486838,349252,97368,27517, 893068,422737,178614,94066, 1163085,557095,232617,121198, 1435215,764456,287043,134152, 136925,7,0 ],
  [ 2000,25,10,3,3, 509937,358774,62441,18510, 1035085,681789,86257,29441, 1994259,926118,166188,89012, 2727445,1288231,227287,119934, 3366457,1694972,280538,139290, 4095348,46,0 ],
  [ 2000,25,15,3,3, 671838,394669,59719,24637, 1716482,1148521,78022,25816, 3481810,1696221,158264,81163, 4891105,2434772,222323,111651, 6077678,3001420,276258,139830, 9434569,69,0 ],
  [ 2000,25,25,3,3, 787089,424073,57243,26401, 3146476,2037071,65895,23234, 7515126,3578403,144522,75706, 10908110,5281017,209771,108213, 13991672,6946850,269071,135477, 23931075,101,0 ],
  [ 2000,25,50,3,3, 796818,425431,56916,26528, 4599850,3056439,57981,19455, 14481484,6821984,131650,69632, 21468901,10251308,195172,101978, 28727287,14001125,261157,133874, 47585526,130,0 ],
  [ 2000,25,75,3,3, 796818,425431,56916,26528, 4625471,3061819,57818,19546, 14706025,6868262,131304,69980, 21812344,10396564,194753,101927, 29221999,14303199,260911,133204, 48487940,131,0 ],
  [ 2000,50,1,3,3, 79545,86215,79545,-6670, 109555,80190,109555,29365, 229937,102157,229937,127779, 246730,75465,246730,171265, 304647,102791,304647,201856, -2644606,-33,21 ],
  [ 2000,50,2,3,3, 148450,109129,74225,19661, 215286,97339,107643,58973, 417030,220709,208515,98160, 493460,331259,246730,81101, 609295,362352,304647,123471, -2000260,-28,16 ],
  [ 2000,50,3,3,3, 217356,118706,72452,32883, 321017,202373,107006,39548, 604122,368160,201374,78654, 740190,383658,246730,118844, 913942,441013,304647,157643, -1217571,-19,-54 ],
  [ 2000,50,5,3,3, 322533,218363,70371,22728, 512333,353138,102467,31839, 959741,523808,191948,87187, 1224481,581176,244896,128661, 1511197,776072,302239,147025, 1119,4,0 ],
  [ 2000,50,10,3,3, 538527,362600,65942,21542, 1086714,704271,90559,31870, 2117137,957953,176428,96599, 2871274,1404627,239273,122221, 3544412,1821377,295368,143586, 4574854,49,0 ],
  [ 2000,50,15,3,3, 707827,399486,62918,27408, 1800142,1163188,81825,28952, 3679844,1738739,167266,88232, 5148528,2487324,234024,120964, 6398691,3149364,290850,147697, 10562770,72,0 ],
  [ 2000,50,25,3,3, 828301,429589,60240,28997, 3296000,2078799,69026,25491, 7913098,3740073,152175,80250, 11478050,5488837,220732,115177, 14730260,7317083,283274,142561, 25757013,104,0 ],
  [ 2000,50,50,3,3, 838465,431005,59890,29104, 4815288,3185529,60697,20543, 15217284,7150716,138339,73332, 22578769,10755486,205262,107484, 30242190,14730371,274929,141017, 50392226,133,0 ],
  [ 2000,50,75,3,3, 838465,431005,59890,29104, 4842060,3205031,60526,20463, 15452566,7212828,137969,73569, 22939586,10918239,204818,107333, 30762909,15051936,274669,140277, 51246243,134,0 ],
  [ 2000,100,1,3,3, 96277,101791,96277,-5514, 123215,81641,123215,41574, 291301,111060,291301,180242, 271477,79293,271477,192184, 335297,191410,335297,143886, -2491637,-32,20 ],
  [ 2000,100,2,3,3, 171276,112183,85638,29546, 238783,100536,119391,69123, 496915,338292,248458,79311, 542955,339089,271477,101933, 670593,387881,335297,141356, -1855627,-26,14 ],
  [ 2000,100,3,3,3, 246275,122576,82092,41233, 354351,207166,118117,49062, 702529,382325,234176,106735, 814432,394640,271477,139931, 1005890,538820,335297,155690, -742333,-12,-25 ],
  [ 2000,100,5,3,3, 360737,223476,78706,29948, 563323,374795,112665,37706, 1093087,542769,218617,110064, 1347274,707744,269455,127906, 1663160,898607,332632,152910, 507113,13,0 ],
  [ 2000,100,10,3,3, 595707,370253,72944,27607, 1189972,721466,99164,39042, 2362891,1190456,196908,97703, 3158931,1585713,263244,131102, 3900321,1891504,325027,167401, 5440273,53,0 ],
  [ 2000,100,15,3,3, 779805,422456,69316,31764, 1967462,1206259,89430,34600, 4075911,1944190,185269,96896, 5663374,2796873,257426,130295, 7040717,3506553,320033,160644, 12098073,77,0 ],
  [ 2000,100,25,3,3, 910724,440620,66234,34189, 3595050,2340454,75289,26274, 8709041,4105478,167482,88530, 12617931,5998511,242653,127297, 16207436,7928839,311681,159204, 28699940,109,0 ],
  [ 2000,100,50,3,3, 921759,442152,65840,34258, 5246165,3488095,66128,22161, 16688882,7729510,151717,81449, 24798503,11778420,225441,118364, 33271996,16174274,302473,155434, 56035201,138,0 ],
  [ 2000,100,75,3,3, 921759,442152,65840,34258, 5275237,3494185,65940,22263, 16945647,7963246,151300,80200, 25194070,11974714,224947,118030, 33844729,16503979,302185,154828, 56626801,138,0 ],
  [ 2000,200,1,3,3, 129742,106270,129742,23472, 150536,84845,150536,65691, 414031,220380,414031,193651, 320972,181984,320972,138988, 396595,200838,396595,195758, -2307469,-31,18 ],
  [ 2000,200,2,3,3, 216927,118293,108464,49317, 285777,194665,142889,45556, 656687,390240,328343,133223, 641944,353850,320972,144047, 793191,406647,396595,193272, -1397623,-21,9 ],
  [ 2000,200,3,3,3, 304112,143654,101371,53486, 421018,334835,140339,28728, 899342,438035,299781,153769, 962916,512139,320972,150259, 1189786,582245,396595,202514, -316088,-4,0 ],
  [ 2000,200,5,3,3, 437144,334568,95377,22380, 665303,388840,133061,55293, 1359779,716072,271956,128741, 1592859,841344,318572,150303, 1967086,962645,393417,200888, 1190379,23,0 ],
  [ 2000,200,10,3,3, 710067,398895,86947,38103, 1396489,843440,116374,46087, 2854401,1303729,237867,129223, 3734246,1795495,311187,161563, 4612139,2259123,384345,196085, 7710319,63,0 ],
  [ 2000,200,15,3,3, 923761,441722,82112,42848, 2302103,1470900,104641,37782, 4868046,2372512,221275,113433, 6693065,3291633,304230,154611, 8324768,4075098,378399,193167, 14962200,84,0 ],
  [ 2000,200,25,3,3, 1075572,547719,78223,38389, 4193148,2685415,87815,31576, 10300928,4808757,198095,105619, 14897692,7061038,286494,150705, 19161787,9478533,368496,186216, 34568697,116,0 ],
  [ 2000,200,50,3,3, 1088347,549485,77739,38490, 6107920,3988728,76991,26713, 19632079,9148668,178473,95304, 29237973,13838513,265800,139995, 39331607,19092941,357560,183988, 66658496,146,0 ],
  [ 2000,200,75,3,3, 1088347,549485,77739,38490, 6141591,3995762,76770,26823, 19931809,9219747,177963,95643, 29703039,14059212,265206,139677, 40008369,19437475,357218,183669, 67864220,147,0 ],
  [ 2500,0,1,3,3, 79510,86533,79510,-7023, 118819,82487,118819,36332, 201874,83910,201874,117964, 264467,78457,264467,186010, 327085,190325,327085,136761, -2671035,-34,21 ],
  [ 2500,0,2,3,3, 159020,111188,79510,23916, 237638,115826,118819,60906, 403748,204851,201874,99449, 528933,337317,264467,95808, 654170,369878,327085,142146, -1877124,-27,15 ],
  [ 2500,0,3,3,3, 238531,122508,79510,38674, 356458,209280,118819,49059, 605622,354991,201874,83544, 793400,392784,264467,133539, 981255,536192,327085,148355, -977171,-16,-42 ],
  [ 2500,0,5,3,3, 359911,224825,78526,29473, 572597,378537,114519,38812, 991338,530150,198268,92238, 1312312,704821,262462,121498, 1622632,894458,324526,145635, 238151,9,0 ],
  [ 2500,0,10,3,3, 609282,387876,74606,27111, 1227389,819455,102282,33995, 2256594,1180189,188049,89700, 3076901,1579453,256408,124787, 3806805,1882554,317234,160354, 4839410,50,0 ],
  [ 2500,0,15,3,3, 804826,429065,71540,33401, 2047305,1240797,93059,36659, 3976003,1924860,180727,93234, 5521994,2678257,251000,129261, 6872020,3490714,312365,153696, 11989640,76,0 ],
  [ 2500,0,25,3,3, 944066,533941,68659,29827, 3784904,2415688,79265,28675, 8688859,4140137,167093,87475, 12364154,6002527,237772,122339, 15805809,7893487,303958,152160, 28049029,108,0 ],
  [ 2500,0,50,3,3, 955825,535618,68273,30015, 5560470,3709446,70090,23332, 16927278,8053706,153884,80669, 24486325,11819767,222603,115151, 32432623,16022267,294842,149185, 54875305,137,0 ],
  [ 2500,0,75,3,3, 955825,535618,68273,30015, 5591795,3716089,69897,23446, 17194735,8198341,153524,80325, 24882993,12016822,222170,114877, 32991203,16237469,294564,149587, 55774161,138,0 ],
  [ 2500,25,1,3,3, 87876,100989,87876,-13113, 125649,83138,125649,42511, 232556,88362,232556,144195, 276840,80446,276840,196395, 342410,192819,342410,149591, -2592030,-33,21 ],
  [ 2500,25,2,3,3, 170433,112716,85217,28859, 249387,117425,124693,65981, 443691,331182,221845,56254, 553681,341532,276840,106074, 684820,390778,342410,147021, -1966583,-28,15 ],
  [ 2500,25,3,3,3, 252139,112716,84046,46474, 294566,296737,98189,-724, 590782,331182,196927,86533, 584353,341532,194784,80940, 717920,390778,239307,109048, -1653179,-24,12 ],
  [ 2500,25,5,3,3, 379013,328248,82694,11076, 598092,380923,119618,43434, 1058011,539705,211602,103661, 1373708,715375,274742,131667, 1698614,907525,339723,158218, 214757,9,0 ],
  [ 2500,25,10,3,3, 637872,391702,78107,30143, 1279019,828052,106585,37581, 2379471,1197659,198289,98484, 3220729,1600515,268394,135018, 3984760,2038269,332063,162208, 5515729,53,0 ],
  [ 2500,25,15,3,3, 840815,433881,74739,36172, 2130965,1343200,96862,35808, 4174037,2044228,189729,96809, 5779416,2839269,262701,133643, 7193033,3554827,326956,165373, 12563640,78,0 ],
  [ 2500,25,25,3,3, 985278,539457,71657,32423, 3934429,2531266,82396,29386, 9086831,4407857,174747,89980, 12934095,6239000,248733,128752, 16544397,8248550,318161,159536, 29365431,109,0 ],
  [ 2500,25,50,3,3, 997472,541191,71248,32591, 5775909,3869037,72806,24036, 17663078,8395453,160573,84251, 25596192,12367125,232693,120264, 33947526,16834843,308614,155570, 57432277,139,0 ],
  [ 2500,25,75,3,3, 997472,541191,71248,32591, 5808384,3875916,72605,24156, 17941276,8527043,160190,84056, 26010236,12538447,232234,120284, 34532113,17053815,308322,156056, 58468145,140,0 ],
  [ 2500,50,1,3,3, 96243,102109,96243,-5866, 132480,83939,132480,48540, 263239,107328,263239,155911, 289214,97311,289214,191903, 357734,195213,357734,162521, -2513935,-32,20 ],
  [ 2500,50,2,3,3, 181846,114243,90923,33801, 261136,119023,130568,71056, 483634,336949,241817,73342, 578428,345198,289214,116615, 715469,395456,357734,160006, -1765991,-26,14 ],
  [ 2500,50,3,3,3, 267449,139715,89150,42578, 389791,332157,129930,19211, 704029,382021,234676,107336, 867642,418943,289214,149566, 1073203,565540,357734,169221, -933873,-15,-34 ],
  [ 2500,50,5,3,3, 398115,330805,86862,14686, 623587,384809,124717,47756, 1124684,549261,224937,115085, 1435105,739405,287021,139140, 1774595,919091,354919,171101, 582934,15,0 ],
  [ 2500,50,10,3,3, 666462,395529,81608,33176, 1330648,836649,110887,41167, 2502349,1229643,208529,106059, 3364558,1636603,280380,143996, 4162714,2164724,346893,166499, 6157342,56,0 ],
  [ 2500,50,15,3,3, 876804,438698,77938,38943, 2214625,1357868,100665,38944, 4372070,2086746,198730,103878, 6036839,2972129,274402,139305, 7514045,3831532,341548,167387, 13481512,80,0 ],
  [ 2500,50,25,3,3, 1026490,544972,74654,35019, 4083954,2677344,85528,29458, 9484803,4491027,182400,96034, 13504035,6540303,259693,133918, 17282984,8618283,332365,166629, 30903275,112,0 ],
  [ 2500,50,50,3,3, 1039119,546765,74223,35168, 5991347,3998127,75521,25125, 18398877,8724185,167263,87952, 26706059,12871303,242782,125771, 35462428,17466670,322386,163598, 60308435,141,0 ],
  [ 2500,50,75,3,3, 1039119,546765,74223,35168, 6024973,4019128,75312,25073, 18687816,8871609,166856,87645, 27137478,13045145,242299,125824, 36073023,17802553,322081,163129, 61248551,142,0 ],
  [ 2500,100,1,3,3, 112975,104348,112975,8627, 146140,85541,146140,60599, 324603,116230,324603,208373, 313961,181297,313961,132664, 388384,199902,388384,188482, -2365063,-31,19 ],
  [ 2500,100,2,3,3, 204672,117298,102336,43687, 284633,196070,142316,44281, 563519,362998,281760,100261, 627923,352578,313961,137672, 776768,404864,388384,185952, -1632087,-24,12 ],
  [ 2500,100,3,3,3, 296368,143585,98789,50928, 423125,336949,141042,28725, 802435,410701,267478,130578, 941884,510283,313961,143867, 1165151,579617,388384,195178, -550926,-9,0 ],
  [ 2500,100,5,3,3, 436319,349255,95197,18996, 674577,392432,134915,56429, 1258031,582888,251606,135029, 1557897,757714,311579,160037, 1926558,958096,385312,193693, 1264126,24,0 ],
  [ 2500,100,10,3,3, 720693,398984,88248,39393, 898065,866744,74839,2610, 2085731,592888,173811,124404, 2711525,768418,225960,161926, 3321806,973096,276817,195726, 5961665,56,0 ],
  [ 2500,100,15,3,3, 948782,533368,84336,36926, 2381945,1505288,108270,39848, 4768138,2367697,216734,109111, 6551685,3201370,297804,152287, 8156071,4059459,370731,186210, 14430040,83,0 ],
  [ 2500,100,25,3,3, 1108913,569340,80648,39242, 4383003,2834499,91791,32429, 10280746,4843416,197707,104564, 14643915,7065054,281614,145747, 18760160,9230639,360772,183260, 34129528,116,0 ],
  [ 2500,100,50,3,3, 1122413,571250,80172,39369, 6422225,4300543,80952,26744, 19870475,9379979,180641,95368, 28925794,13894387,262962,136649, 38492234,19008143,349929,177128, 65650760,145,0 ],
  [ 2500,100,75,3,3, 1122413,571250,80172,39369, 6458150,4322016,80727,26702, 20180898,9544877,180187,94964, 29391962,14101770,262428,136520, 39154843,19254696,349597,177680, 66707331,146,0 ],
  [ 2500,200,1,3,3, 146440,108827,146440,37612, 173461,88744,173461,84716, 447333,346115,447333,101218, 363456,189253,363456,174203, 449683,322970,449683,126713, -2399777,-32,19 ],
  [ 2500,200,2,3,3, 250323,123408,125161,63457, 331627,202464,165813,64581, 723291,400580,361645,161355, 726912,382265,363456,172323, 899365,439301,449683,230032, -896495,-15,-38 ],
  [ 2500,200,3,3,3, 354206,223026,118069,43727, 489793,346534,163264,47753, 999248,545061,333083,151396, 1090368,547324,363456,181015, 1349048,736532,449683,204172, -132372,1,0 ],
  [ 2500,200,5,3,3, 512727,359481,111868,33436, 776557,421861,155311,70939, 1524723,756041,304945,153737, 1803482,904790,360696,179738, 2230484,1149345,446097,216228, 2203574,33,0 ],
  [ 2500,200,10,3,3, 838002,431824,102613,49736, 1640424,915853,136702,60381, 3239613,1589785,269968,137486, 4227530,2092852,352294,177890, 5230441,2618141,435870,217692, 9341911,68,0 ],
  [ 2500,200,15,3,3, 1092738,552634,97132,48009, 2716586,1665579,123481,47773, 5560273,2720520,252740,129080, 7581377,3696131,344608,176602, 9440123,4757266,429096,212857, 17705482,90,0 ],
  [ 2500,200,25,3,3, 1273761,692269,92637,42290, 4981102,3178110,104316,37759, 11872633,5623695,228320,120172, 16923676,8209238,325455,167585, 21714512,10781682,417587,210247, 39638265,122,0 ],
  [ 2500,200,50,3,3, 1289002,707749,92072,41518, 7283979,4799676,91815,31315, 22813673,10722137,207397,109923, 33365264,15875673,303321,158996, 44551845,21815219,405017,206697, 76530021,153,0 ],
  [ 2500,200,75,3,3, 1289002,707749,92072,41518, 7324505,4808209,91556,31454, 23167060,10878528,206849,109719, 33900931,16187768,302687,158153, 45318483,22189742,404629,206507, 77692146,154,0 ],
  [ 3000,0,1,3,3, 96208,102427,96208,-6219, 141744,86237,141744,55507, 235176,89081,235176,146095, 306950,100303,306950,206648, 380172,198966,380172,181206, -2480629,-32,20 ],
  [ 3000,0,2,3,3, 192416,116303,96208,38056, 283488,197475,141744,43006, 470352,335756,235176,67298, 613901,351306,306950,131297, 760345,403082,380172,178631, -1866552,-27,15 ],
  [ 3000,0,3,3,3, 288624,143516,96208,48369, 425232,339064,141744,28723, 705528,383367,235176,107387, 920851,428119,306950,164244, 1140517,576988,380172,187843, -667239,-11,0 ],
  [ 3000,0,5,3,3, 435494,350604,95017,18521, 683851,410059,136770,54759, 1156282,555603,231256,120136, 1522935,754741,304587,153639, 1886031,953846,377206,186437, 985081,20,0 ],
  [ 3000,0,10,3,3, 737217,420805,90271,38744, 1471324,891868,122610,48288, 2641806,1268680,220150,114427, 3570185,1782777,297515,148951, 4425108,2241022,368759,182007, 7064348,60,0 ],
  [ 3000,0,15,3,3, 973802,539977,86560,38562, 2461788,1553712,111899,41276, 4668229,2348217,212192,105455, 6410305,3191214,291378,146322, 7987374,4043570,363062,179264, 14117950,82,0 ],
  [ 3000,0,25,3,3, 1142255,678491,83073,33728, 4572858,3026618,95767,32382, 10260564,4953425,197319,102060, 14390139,7070419,276733,140764, 18358533,9196087,353049,176201, 32866917,114,0 ],
  [ 3000,0,50,3,3, 1156480,680545,82606,33995, 6736530,4520544,84914,27933, 20108871,9704176,182808,94588, 28613616,13856777,260124,134153, 37652861,18744395,342299,171895, 64625024,145,0 ],
  [ 3000,0,75,3,3, 1156480,680545,82606,33995, 6774709,4528686,84684,28075, 20429986,9780122,182411,95088, 29080885,14160205,259651,133220, 38301317,19102676,341976,171416, 65730542,145,0 ],
  [ 3000,25,1,3,3, 104574,103547,104574,1028, 148574,87038,148574,61536, 265858,108047,265858,157812, 319324,182499,319324,136825, 395497,201361,395497,194136, -2498955,-32,20 ],
  [ 3000,25,2,3,3, 203829,117831,101914,42999, 295237,199074,147618,48081, 510295,341523,255148,84386, 638648,354971,319324,141838, 790994,407761,395497,191616, -1665959,-24,12 ],
  [ 3000,25,3,3,3, 303084,217152,101028,28644, 441899,341460,147300,33480, 754732,390449,251577,121427, 957972,513918,319324,148018, 1186491,584001,395497,200830, -812261,-13,-22 ],
  [ 3000,25,5,3,3, 454596,353161,99185,22131, 709346,413945,141869,59080, 1222955,579674,244591,128656, 1584332,859129,316866,145041, 1962012,981134,392402,196176, 1190060,23,0 ],
  [ 3000,25,10,3,3, 765807,424631,93772,41777, 1522953,900465,126913,51874, 2764683,1377664,230390,115585, 3714014,1803888,309501,159177, 4603062,2283696,383589,193280, 7611301,62,0 ],
  [ 3000,25,15,3,3, 1009791,544794,89759,41333, 2545448,1568380,115702,44412, 4866263,2390735,221194,112524, 6667728,3324074,303079,151984, 8308387,4220824,377654,185798, 15106730,84,0 ],
  [ 3000,25,25,3,3, 1183467,684007,86070,36324, 4722382,3142196,98898,33093, 10658536,5144145,204972,106046, 14960079,7263763,287694,148006, 19097121,9566821,367252,183275, 34412297,116,0 ],
  [ 3000,25,50,3,3, 1198127,699455,85580,35619, 6951968,4679985,87630,28638, 20844671,10045923,189497,98170, 29723483,14484592,270213,138535, 39167764,19489012,356071,178898, 67057340,146,0 ],
  [ 3000,25,75,3,3, 1198127,699455,85580,35619, 6991297,4688363,87391,28787, 21176526,10123339,189076,98689, 30208128,14667054,269715,138760, 39842227,19722351,355734,179642, 68498803,147,0 ],
  [ 3000,50,1,3,3, 112940,104666,112940,8274, 155404,87839,155404,67566, 296541,112498,296541,184043, 331698,184338,331698,147360, 410822,203705,410822,207117, -2365257,-31,19 ],
  [ 3000,50,2,3,3, 215242,119358,107621,47942, 306985,200672,153493,53156, 550238,347290,275119,101474, 663395,373613,331698,144891, 821643,412440,410822,204602, -1487471,-22,10 ],
  [ 3000,50,3,3,3, 317543,219087,105848,32819, 458566,343856,152855,38237, 803935,412047,267978,130629, 995093,519459,331698,158545, 1232465,606736,410822,208576, -589449,-9,0 ],
  [ 3000,50,5,3,3, 473698,355717,103352,25741, 734841,417831,146968,63402, 1289628,695279,257926,118870, 1645728,868683,329146,155409, 2037994,993201,407599,208959, 1334961,24,0 ],
  [ 3000,50,10,3,3, 794397,428458,97273,44809, 1574582,909063,131215,55460, 2887561,1395134,240630,124369, 3857842,1948386,321487,159121, 4781017,2523192,398418,188152, 8045709,64,0 ],
  [ 3000,50,15,3,3, 1045780,549610,92958,44104, 2629109,1670782,119505,43560, 5064297,2447618,230195,118940, 6925151,3391653,314780,160614, 8629400,4384438,392245,192953, 15890790,86,0 ],
  [ 3000,50,25,3,3, 1224679,702859,89068,37951, 4871907,3183774,102029,35354, 11056507,5305815,212625,110590, 15530020,7580193,298654,152881, 19835709,9921533,381456,190657, 36034641,118,0 ],
  [ 3000,50,50,3,3, 1239774,705029,88555,38196, 7167407,4735225,90345,30658, 21580470,10374655,196186,101871, 30833350,14988771,280303,144042, 40682667,20105168,369842,187068, 70178453,149,0 ],
  [ 3000,50,75,3,3, 1239774,705029,88555,38196, 7207886,4831575,90099,29704, 21923067,10453390,195742,102408, 31335370,15188678,279780,144167, 41383137,20471038,369492,186715, 71290606,149,0 ],
  [ 3000,100,1,3,3, 129673,106906,129673,22767, 169065,89441,169065,79624, 357906,212915,357906,144990, 356445,188066,356445,168379, 441471,321534,441471,119937, -2388490,-31,19 ],
  [ 3000,100,2,3,3, 238067,122413,119034,57827, 330482,203869,165241,63307, 630124,373339,315062,128392, 712890,380993,356445,165948, 882942,437519,441471,222712, -1130960,-18,-53 ],
  [ 3000,100,3,3,3, 346462,222957,115487,41168, 491900,348648,163967,47751, 902342,440727,300781,153872, 1069335,545468,356445,174622, 1324413,733903,441471,196837, -190712,-1,0 ],
  [ 3000,100,5,3,3, 511902,360830,111688,32961, 785831,499453,157166,57276, 1422974,728906,284595,138814, 1768520,901817,353704,173341, 2189957,1145146,437991,208962, 1734331,28,0 ],
  [ 3000,100,10,3,3, 851577,436110,104275,50874, 1677841,1044492,139820,52779, 3133315,1563503,261110,130818, 4145500,2086393,345458,171592, 5136926,2592819,428077,212009, 8747574,66,0 ],
  [ 3000,100,15,3,3, 1117758,673447,99356,39494, 2796429,1712353,127110,49276, 5460364,2609505,248198,129585, 7439997,3687175,338182,170583, 9271426,4726906,421428,206569, 17148076,89,0 ],
  [ 3000,100,25,3,3, 1307102,713890,95062,43143, 5170956,3445429,108292,36137, 11852451,5656705,227932,119149, 16669900,8024486,320575,166258, 21312885,10548910,409863,207000, 39096317,121,0 ],
  [ 3000,100,50,3,3, 1323068,716177,94505,43349, 7598284,5037791,95777,32275, 23052069,10953449,209564,109987, 33053085,15931397,300483,155652, 43712472,21662161,397386,200457, 75859319,152,0 ],
  [ 3000,100,75,3,3, 1323068,716177,94505,43349, 7641063,5134613,95513,31331, 23416148,11218173,209073,108910, 33589854,16245203,299909,154863, 44464957,22036172,397009,200257, 76513448,153,0 ],
  [ 3000,200,1,3,3, 163137,111384,163137,51753, 196386,92644,196386,103741, 480635,351285,480635,129350, 405940,195973,405940,209967, 502770,347133,502770,155637, -2143748,-29,17 ],
  [ 3000,200,2,3,3, 283719,141859,141859,70930, 377477,314463,188738,31507, 789895,423936,394947,182979, 811879,395904,405940,207988, 1005539,539765,502770,232887, -836640,-14,-31 ],
  [ 3000,200,3,3,3, 404300,331565,134767,24245, 558568,370618,186189,62650, 1099155,560422,366385,179578, 1217819,583959,405940,211287, 1508309,779128,502770,243060, 178700,8,0 ],
  [ 3000,200,5,3,3, 588309,371057,128358,47401, 887812,528882,177562,71786, 1689667,873009,337933,163332, 2014106,953110,402821,212199, 2493883,1290914,498777,240594, 2915357,38,0 ],
  [ 3000,200,10,3,3, 965938,536453,118278,52590, 1884358,1166466,157030,59824, 3624825,1753775,302069,155921, 4720814,2404636,393401,193015, 5848744,2960438,487395,240692, 10387064,72,0 ],
  [ 3000,200,15,3,3, 1261715,692713,112152,50578, 3131069,1976994,142321,52458, 6252499,2960827,284205,149621, 8469689,4101628,384986,198548, 10555477,5311123,479794,238380, 20296052,95,0 ],
  [ 3000,200,25,3,3, 1471950,749290,107051,52557, 5769055,3686041,120818,43623, 13444338,6452999,258545,134449, 18949661,9167370,364417,188121, 24267236,12083482,466678,234303, 45268329,128,0 ],
  [ 3000,200,50,3,3, 1489656,751809,106404,52703, 8460038,5538424,106639,36827, 25995266,12372606,236321,123842, 37492555,17991490,340841,177282, 49772084,24580828,452473,229011, 86776599,159,0 ],
  [ 3000,200,75,3,3, 1489656,751809,106404,52703, 8507418,5622306,106343,36064, 26402310,12474824,235735,124353, 38098823,18314725,340168,176644, 50628597,25053498,452041,228349, 88050805,160,0 ],
  [ 1000,0,1,0,4, 5423,76125,5423,-70703, 11539,51673,11539,-40134, 31295,45821,31295,-14526, 48891,33047,48891,15844, 67186,38210,67186,28976, -3805484,-42,28 ],
  [ 1000,0,2,0,4, 10845,77037,5423,-33096, 23078,54348,11539,-15635, 62590,51673,31295,5459, 97782,57581,48891,20101, 134372,67530,67186,33421, -3637682,-41,27 ],
  [ 1000,0,3,0,4, 16268,77949,5423,-20560, 34617,57022,11539,-7468, 93885,72039,31295,7282, 146674,67189,48891,26495, 201558,81229,67186,40110, -3470103,-40,26 ],
  [ 1000,0,5,0,4, 24547,79341,5356,-11955, 54846,75726,10969,-4176, 151506,82605,30301,13780, 241541,180907,48308,12127, 331902,320080,66380,2364, -3434414,-39,26 ],
  [ 1000,0,10,0,4, 41564,82207,5089,-4977, 112214,90080,9351,1845, 329815,313044,27485,1398, 558271,380892,46523,14782, 768846,539200,64070,19137, -3033494,-36,24 ],
  [ 1000,0,15,0,4, 54914,84457,4881,-2626, 179618,195375,8164,-716, 560260,384069,25466,8009, 986943,588304,44861,18120, 1375679,920048,62531,20710, -2255147,-30,19 ],
  [ 1000,0,25,0,4, 64426,99400,4686,-2544, 310033,349113,6493,-818, 1134598,726954,21819,7839, 2120279,1286216,40775,16040, 3116673,2043229,59936,20643, -739552,-10,0 ],
  [ 1000,0,50,0,4, 65231,99537,4659,-2450, 436409,412300,5501,304, 2032488,1232475,18477,7273, 3977839,2475893,36162,13654, 6281160,4030414,57101,20461, 2185775,34,0 ],
  [ 1000,0,75,0,4, 65231,99537,4659,-2450, 438628,412924,5483,321, 2059631,1237211,18390,7343, 4035542,2486602,36032,13830, 6385522,4050681,57014,20847, 2371503,36,0 ],
  [ 1000,25,1,0,4, 7945,76583,7945,-68638, 13426,51945,13426,-38518, 44328,48391,44328,-4063, 53306,33936,53306,19371, 73321,55152,73321,18170, -3770432,-42,27 ],
  [ 1000,25,2,0,4, 13930,77597,6965,-31834, 26103,54889,13052,-14393, 78465,69305,39233,4580, 106613,59402,53306,23606, 146642,70117,73321,38263, -3606381,-41,27 ],
  [ 1000,25,3,0,4, 19914,78611,6638,-19566, 38780,57833,12927,-6351, 112603,75703,37534,12300, 159919,69841,53306,30026, 219963,100703,73321,39754, -3397898,-39,26 ],
  [ 1000,25,5,0,4, 29049,80159,6338,-11151, 61026,77031,12205,-3201, 175500,87283,35100,17643, 263335,185259,52667,15615, 362196,326327,72439,7174, -3316727,-39,25 ],
  [ 1000,25,10,0,4, 47815,83341,5855,-4350, 124240,92890,10353,2613, 370365,335398,30864,2914, 608540,390916,50712,18135, 838936,569309,69911,22469, -2853881,-35,22 ],
  [ 1000,25,15,0,4, 62529,85839,5558,-2072, 198654,200068,9030,-64, 622472,396027,28294,10293, 1075844,714330,48902,16432, 1500919,961340,68224,24526, -2063968,-28,17 ],
  [ 1000,25,25,0,4, 73004,100957,5309,-2033, 343023,371618,7184,-599, 1252063,840698,24078,7911, 2312387,1434232,44469,16888, 3399211,2215748,65369,22759, -525699,-6,0 ],
  [ 1000,25,50,0,4, 73888,101108,5278,-1944, 483086,424860,6089,734, 2238684,1300463,20352,8529, 4342081,2657307,39473,15316, 6848474,4404488,62259,22218, 3046962,40,0 ],
  [ 1000,25,75,0,4, 73888,101108,5278,-1944, 485544,425554,6069,750, 2268558,1382706,20255,7909, 4405200,2792497,39332,14399, 6962225,4426626,62163,22639, 2891458,39,0 ],
  [ 1000,50,1,0,4, 10468,77041,10468,-66573, 15313,52216,15313,-36903, 57361,65476,57361,-8115, 57722,34874,57722,22848, 79456,56472,79456,22984, -3757707,-42,27 ],
  [ 1000,50,2,0,4, 17014,78157,8507,-30571, 29128,55430,14564,-13151, 94340,72422,47170,10959, 115444,61172,57722,27136, 158912,72653,79456,43130, -3541579,-40,26 ],
  [ 1000,50,3,0,4, 23560,79272,7853,-18571, 42943,58644,14314,-5234, 131320,79368,43773,17317, 173165,72494,57722,33557, 238369,104505,79456,44621, -3314520,-39,25 ],
  [ 1000,50,5,0,4, 33551,80976,7320,-10347, 67207,78335,13441,-2226, 199494,106475,39899,18604, 285128,189660,57026,19094, 392490,348295,78498,8839, -3243713,-38,25 ],
  [ 1000,50,10,0,4, 54066,84476,6620,-3724, 136267,95700,11356,3381, 410916,343238,34243,5640, 658808,400889,54901,21493, 909027,583696,75752,27111, -2629594,-33,21 ],
  [ 1000,50,15,0,4, 70143,100558,6235,-2704, 217691,204611,9895,595, 684685,422501,31122,11917, 1164745,747073,52943,18985, 1626160,1002832,73916,28333, -1823012,-25,15 ],
  [ 1000,50,25,0,4, 81581,102514,5933,-1522, 376013,380238,7875,-88, 1369528,877592,26337,9460, 2504495,1595424,48163,17482, 3681750,2501408,70803,22699, -191843,2,0 ],
  [ 1000,50,50,0,4, 82546,102680,5896,-1438, 529764,525156,6678,58, 2444881,1536836,22226,8255, 4706324,2962607,42785,15852, 7415787,4763291,67416,24114, 3071404,41,0 ],
  [ 1000,50,75,0,4, 82546,102680,5896,-1438, 532460,525918,6656,82, 2477484,1542586,22120,8347, 4774858,2975405,42633,16067, 7538928,4802971,67312,24428, 3280845,42,0 ],
  [ 1000,100,1,0,4, 15514,77957,15514,-62443, 19087,52759,19087,-33672, 83427,70615,83427,12811, 66552,36651,66552,29901, 91726,59014,91726,32712, -3665256,-41,27 ],
  [ 1000,100,2,0,4, 23183,79277,11591,-28047, 35178,56513,17589,-10668, 126090,78656,63045,23717, 133105,64713,66552,34196, 183453,77727,91726,52863, -3411975,-39,26 ],
  [ 1000,100,3,0,4, 30852,80596,10284,-16581, 51268,60267,17089,-3000, 168754,101211,56251,22514, 199657,77848,66552,40603, 275179,195941,91726,26413, -3240999,-38,25 ],
  [ 1000,100,5,0,4, 42555,82610,9285,-8739, 79567,80944,15913,-275, 247483,115830,49497,26330, 328715,306823,65743,4378, 453078,360788,90616,18458, -3168413,-38,24 ],
  [ 1000,100,10,0,4, 66568,86745,8151,-2471, 160319,115206,13360,3759, 492016,373282,41001,9895, 759346,516170,63279,20265, 1049208,741233,87434,25665, -2490537,-32,20 ],
  [ 1000,100,15,0,4, 85373,103323,7589,-1596, 255765,332232,11626,-3476, 809109,536283,36778,12401, 1342547,877539,61025,21137, 1876641,1281787,85302,27039, -1981703,-27,16 ],
  [ 1000,100,25,0,4, 98737,105628,7181,-501, 441992,411364,9256,641, 1604458,936714,30855,12841, 2888711,1781147,55552,21299, 4246826,2762666,81670,28542, 888408,20,0 ],
  [ 1000,100,50,0,4, 99862,105823,7133,-426, 623119,564163,7854,743, 2857275,1735147,25975,10201, 5434810,3353536,49407,18921, 8550414,5511339,77731,27628, 4565647,50,0 ],
  [ 1000,100,75,0,4, 99862,105823,7133,-426, 626292,565062,7829,765, 2895337,1741911,25851,10298, 5514174,3383399,49234,19025, 8692333,5638541,77610,27266, 4728136,51,0 ],
  [ 1000,200,1,0,4, 25605,79789,25605,-54184, 26636,53845,26636,-27209, 135558,95410,135558,40148, 84214,55232,84214,28982, 116266,64147,116266,52119, -3535957,-40,26 ],
  [ 1000,200,2,0,4, 35521,81516,17760,-22998, 47277,58678,23638,-5701, 189590,105639,94795,41976, 168427,71845,84214,48291, 232533,103594,116266,64469, -3197442,-38,24 ],
  [ 1000,200,3,0,4, 45436,83243,15145,-12602, 67918,77396,22639,-3159, 243623,115719,81208,42635, 252641,183742,84214,22966, 348799,324191,116266,8203, -3172330,-38,24 ],
  [ 1000,200,5,0,4, 60562,85878,13214,-5524, 104289,86162,20858,3625, 343460,332105,68692,2271, 415889,339756,83178,15227, 574253,401997,114851,34451, -3186316,-38,24 ],
  [ 1000,200,10,0,4, 91571,104620,11213,-1598, 208424,200148,17369,690, 654218,419155,54518,19589, 960420,586218,80035,31184, 1329570,914106,110798,34622, -2041652,-28,17 ],
  [ 1000,200,15,0,4, 115832,108851,10296,621, 331912,351004,15087,-868, 1057959,719197,48089,15398, 1698150,1167123,77189,24138, 2377604,1628755,108073,34039, -1341068,-20,-38 ],
  [ 1000,200,25,0,4, 133047,111856,9676,1541, 573951,533580,12020,845, 2074319,1267190,39891,15522, 3657143,2400064,70330,24175, 5376979,3496342,103403,36166, 1855390,31,0 ],
  [ 1000,200,50,0,4, 134493,112108,9607,1599, 809829,806491,10208,42, 3682062,2342518,33473,12178, 6891781,4246655,62653,24047, 10819668,6963004,98361,35061, 6415897,59,0 ],
  [ 1000,200,75,0,4, 134493,112108,9607,1599, 813956,821551,10174,-95, 3731043,2351160,33313,12320, 6992807,4389156,62436,23247, 10999144,7097640,98207,34835, 6444500,59,0 ],
  [ 1500,0,1,0,4, 8501,76765,8501,-68264, 16866,52992,16866,-36126, 41516,47971,41516,-6455, 64051,36288,64051,27762, 88439,58481,88439,29958, -3746025,-41,27 ],
  [ 1500,0,2,0,4, 17001,78316,8501,-30657, 33732,56986,16866,-11627, 83032,70488,41516,6272, 128101,64113,64051,31994, 176878,76780,88439,50049, -3529917,-40,26 ],
  [ 1500,0,3,0,4, 25502,79867,8501,-18122, 50598,60979,16866,-3461, 124548,78489,41516,15353, 192152,76912,64051,38414, 265317,194481,88439,23612, -3329113,-39,25 ],
  [ 1500,0,5,0,4, 38480,82235,8396,-9547, 80554,82183,16111,-326, 201758,93176,40352,21716, 316286,305342,63257,2189, 436901,358474,87380,15685, -3263175,-38,25 ],
  [ 1500,0,10,0,4, 65148,100442,7977,-4322, 167584,118353,13965,4103, 444629,351551,37052,7756, 730500,513153,60875,18112, 1012241,736433,84353,22984, -2659390,-34,21 ],
  [ 1500,0,15,0,4, 86063,104263,7650,-1618, 272292,338613,12377,-3015, 763143,531100,34688,10547, 1293292,872650,58786,19120, 1810602,1273353,82300,24420, -2150669,-29,18 ],
  [ 1500,0,25,0,4, 100960,106987,7343,-438, 482050,425672,10095,1181, 1579154,940627,30368,12279, 2801194,1790484,53869,19437, 4092784,2644612,78707,27849, 737858,18,0 ],
  [ 1500,0,50,0,4, 102219,107218,7301,-357, 689555,705620,8692,-202, 2896570,1773743,26332,10208, 5325351,3386509,48412,17626, 8234393,5362157,74858,26111, 4004294,47,0 ],
  [ 1500,0,75,0,4, 102219,107218,7301,-357, 693205,706680,8665,-168, 2937243,1887371,26225,9374, 5404951,3402383,48258,17880, 8371141,5518682,74742,25468, 3924018,46,0 ],
  [ 1500,25,1,0,4, 11024,77223,11024,-66199, 18753,53264,18753,-34511, 54549,50541,54549,4008, 68466,37177,68466,31289, 94574,59752,94574,34822, -3699800,-41,27 ],
  [ 1500,25,2,0,4, 20086,78876,10043,-29395, 36757,57527,18378,-10385, 98907,73605,49453,12651, 136932,65884,68466,35524, 189148,79317,94574,54915, -3465115,-40,26 ],
  [ 1500,25,3,0,4, 29148,80529,9716,-17127, 54760,75675,18253,-6972, 143265,82004,47755,20420, 205398,79564,68466,41945, 283722,198234,94574,28496, -3289319,-38,25 ],
  [ 1500,25,5,0,4, 42982,83053,9378,-8743, 86734,83487,17347,649, 225752,112368,45150,22677, 338080,309743,67616,5667, 467195,364771,93439,20485, -3178988,-38,24 ],
  [ 1500,25,10,0,4, 71399,101576,8743,-3695, 179610,195014,14968,-1284, 485179,373906,40432,9273, 780769,538153,65064,20218, 1082331,766542,90194,26316, -2735661,-34,22 ],
  [ 1500,25,15,0,4, 93678,105645,8327,-1064, 291329,343306,13242,-2363, 825355,543059,37516,12832, 1382192,905192,62827,21682, 1935842,1314645,87993,28236, -1821520,-25,15 ],
  [ 1500,25,25,0,4, 109538,108544,7966,72, 515039,522027,10786,-146, 1696619,1160420,32627,10312, 2993302,1952176,57563,20022, 4375322,2930721,84141,27781, 372474,13,0 ],
  [ 1500,25,50,0,4, 110877,108789,7920,149, 736232,718180,9280,228, 3102767,1947781,28207,10500, 5689594,3676883,51724,18297, 8801707,5736731,80016,27863, 4460029,49,0 ],
  [ 1500,25,75,0,4, 110877,108789,7920,149, 740121,733195,9252,87, 3146170,2032716,28091,9942, 5774609,3693301,51559,18583, 8947843,5894577,79891,27261, 4422492,49,0 ],
  [ 1500,50,1,0,4, 13546,77681,13546,-64134, 20640,53535,20640,-32895, 67582,67626,67582,-44, 72881,53092,72881,19790, 100709,61073,100709,39636, -3709178,-41,27 ],
  [ 1500,50,2,0,4, 23170,79436,11585,-28133, 39781,58068,19891,-9143, 114782,76722,57391,19030, 145763,67654,72881,39054, 201418,81854,100709,59782, -3400314,-39,26 ],
  [ 1500,50,3,0,4, 32794,81191,10931,-16132, 58923,76486,19641,-5855, 161982,85668,53994,25438, 218644,97193,72881,40484, 302127,202036,100709,33364, -3228044,-38,25 ],
  [ 1500,50,5,0,4, 47484,83870,10360,-7939, 92915,84792,18583,1625, 249746,194046,49949,11140, 359873,329071,71975,6160, 497488,371018,99498,25294, -3259902,-38,25 ],
  [ 1500,50,10,0,4, 77649,102711,9508,-3069, 191637,197824,15970,-516, 525730,381745,43811,11999, 831037,548126,69253,23576, 1152422,780929,96035,30958, -2511374,-32,20 ],
  [ 1500,50,15,0,4, 101292,107027,9004,-510, 310366,347999,14108,-1711, 887568,569532,40344,14456, 1471093,922808,66868,24922, 2061083,1340316,93686,32762, -1492594,-22,-43 ],
  [ 1500,50,25,0,4, 118115,110101,8590,583, 548029,530648,11477,364, 1814084,1197314,34886,11861, 3185410,2085216,61258,21158, 4657860,3103291,89574,29896, 828512,20,0 ],
  [ 1500,50,50,0,4, 119535,110361,8538,655, 782910,744626,9869,483, 3308964,2078104,30081,11190, 6053836,3858246,55035,19960, 9369020,6095034,85173,29764, 5145807,53,0 ],
  [ 1500,50,75,0,4, 119535,110361,8538,655, 787037,745824,9838,515, 3355096,2086696,29956,11325, 6144267,3890685,54860,20121, 9524546,6225490,85041,29456, 5344589,54,0 ],
  [ 1500,100,1,0,4, 18592,78596,18592,-60005, 24414,54078,24414,-29664, 93647,72766,93647,20882, 81712,54869,81712,26843, 112979,63614,112979,49365, -3616727,-41,27 ],
  [ 1500,100,2,0,4, 29339,80555,14670,-25608, 45831,59151,22915,-6660, 146532,82956,73266,31788, 163424,71195,81712,46115, 225958,102598,112979,61680, -3281883,-38,25 ],
  [ 1500,100,3,0,4, 40086,82514,13362,-14143, 67248,78109,22416,-3620, 199417,107512,66472,30635, 245136,182855,81712,20760, 338937,322782,112979,5385, -3293945,-38,25 ],
  [ 1500,100,5,0,4, 56487,85504,12325,-6331, 105275,87401,21055,3575, 297735,217915,59547,15964, 403460,337824,80692,13127, 558076,399233,111615,31769, -3069201,-37,24 ],
  [ 1500,100,10,0,4, 90151,104980,11039,-1816, 215689,203445,17974,1020, 606830,411940,50569,16241, 931574,583100,77631,29040, 1292603,909206,107717,31950, -2189312,-29,18 ],
  [ 1500,100,15,0,4, 116522,109791,10358,598, 348440,371270,15838,-1038, 1011993,713864,46000,13551, 1648895,1096903,74950,25091, 2311564,1620271,105071,31422, -1457197,-21,-43 ],
  [ 1500,100,25,0,4, 135271,113215,9838,1604, 614009,561773,12859,1094, 2049015,1270952,39404,14963, 3569626,2205657,68647,26230, 5222937,3477689,100441,33562, 1891051,31,0 ],
  [ 1500,100,50,0,4, 136850,113504,9775,1668, 876265,857483,11045,237, 3721357,2382464,33831,12172, 6782322,4264652,61657,22888, 10503647,6927362,95488,32512, 6079053,57,0 ],
  [ 1500,100,75,0,4, 136850,113504,9775,1668, 880868,858818,11011,276, 3772949,2392071,33687,12329, 6883583,4407640,61461,22107, 10677951,6977780,95339,33037, 6212382,58,0 ],
  [ 1500,200,1,0,4, 28683,80428,28683,-51745, 31962,55164,31962,-23202, 145779,97560,145779,48219, 99373,58472,99373,40901, 137519,68747,137519,68772, -3465325,-40,26 ],
  [ 1500,200,2,0,4, 41677,82795,20838,-20559, 57930,61316,28965,-1693, 210032,109939,105016,50046, 198746,78326,99373,60210, 275039,196575,137519,39232, -3115911,-37,24 ],
  [ 1500,200,3,0,4, 54670,85161,18223,-10164, 83898,81353,27966,848, 274285,213684,91428,20200, 298119,193514,99373,34868, 412558,353713,137519,19615, -3181604,-38,24 ],
  [ 1500,200,5,0,4, 74495,102109,16253,-6025, 129997,92468,25999,7506, 393712,357190,78742,7304, 490634,355930,98127,26941, 679252,440592,135850,47732, -2943197,-36,23 ],
  [ 1500,200,10,0,4, 115155,109518,14101,690, 263794,319037,21983,-4604, 769032,533313,64086,19643, 1132649,746481,94387,32181, 1572965,997648,131080,47943, -2033308,-28,17 ],
  [ 1500,200,15,0,4, 146981,115320,13065,2814, 424587,390042,19299,1570, 1260842,867729,57311,17869, 2004499,1277528,91114,33044, 2812527,1869319,127842,42873, -518150,-7,0 ],
  [ 1500,200,25,0,4, 169581,119442,12333,3646, 745968,714490,15622,659, 2518875,1585413,48440,17951, 4338058,2809548,83424,29394, 6353090,4226486,122175,40896, 2809623,39,0 ],
  [ 1500,200,50,0,4, 171481,119789,12249,3692, 1062976,921611,13399,1782, 4546144,2885286,41329,15099, 8239294,5265731,74903,27032, 12772901,8295247,116117,40706, 8634744,67,0 ],
  [ 1500,200,75,0,4, 171481,119789,12249,3692, 1068532,937106,13357,1643, 4608655,2896770,41149,15285, 8362216,5289460,74663,27435, 12984762,8549970,115935,39596, 8836072,68,0 ],
  [ 2000,0,1,0,4, 11579,77404,11579,-65825, 22193,54311,22193,-32119, 51737,50122,51737,1615, 79210,54506,79210,24705, 109692,63081,109692,46610, -3697496,-41,27 ],
  [ 2000,0,2,0,4, 23158,79595,11579,-28219, 44385,59624,22193,-7619, 103474,74788,51737,14343, 158420,70595,79210,43913, 219383,101652,109692,58866, -3399825,-39,26 ],
  [ 2000,0,3,0,4, 34736,81785,11579,-15683, 66578,78821,22193,-4081, 155210,84790,51737,23474, 237631,181918,79210,18571, 329075,321322,109692,2584, -3382059,-39,25 ],
  [ 2000,0,5,0,4, 52413,85129,11436,-7138, 106262,88639,21252,3524, 252010,195261,50402,11350, 391031,336343,78206,10938, 541899,396919,108380,28996, -3163964,-38,24 ],
  [ 2000,0,10,0,4, 88731,105340,10865,-2034, 222954,206742,18579,1351, 559443,390209,46620,14103, 902729,579932,75227,26900, 1255636,904256,104636,29282, -2303471,-30,19 ],
  [ 2000,0,15,0,4, 117212,110732,10419,576, 364967,377652,16589,-577, 966026,694167,43910,12357, 1599640,1091964,72711,23076, 2245525,1498696,102069,33947, -1512031,-22,-46 ],
  [ 2000,0,25,0,4, 137494,114574,10000,1667, 654066,680431,13698,-552, 2023710,1273364,38918,14430, 3482108,2200018,66964,24656, 5068894,3458586,97479,30967, 1365076,26,0 ],
  [ 2000,0,50,0,4, 139208,114899,9943,1736, 942701,894589,11883,606, 3760653,2422561,34188,12164, 6672863,4282649,60662,21729, 10187626,6807990,92615,30724, 5845528,56,0 ],
  [ 2000,0,75,0,4, 139208,114899,9943,1736, 947781,896086,11847,646, 3814855,2447496,34061,12209, 6774360,4426173,60485,20966, 10356759,6941751,92471,30491, 5887028,56,0 ],
  [ 2000,25,1,0,4, 14102,77862,14102,-63760, 24080,54583,24080,-30503, 64770,67206,64770,-2437, 83626,55444,83626,28181, 115827,64402,115827,51425, -3684771,-41,27 ],
  [ 2000,25,2,0,4, 26242,80154,13121,-26956, 47410,60165,23705,-6378, 119349,77905,59674,20722, 167251,72365,83626,47443, 231653,104189,115827,63732, -3335024,-39,25 ],
  [ 2000,25,3,0,4, 38382,82447,12794,-14688, 70740,79632,23580,-2964, 173928,88454,57976,28491, 250877,184570,83626,22102, 347480,325125,115827,7452, -3298681,-38,25 ],
  [ 2000,25,5,0,4, 56915,85946,12418,-6334, 112442,89944,22488,4500, 276004,199938,55201,15213, 412824,340694,82565,14426, 572193,403166,114439,33805, -3046277,-37,23 ],
  [ 2000,25,10,0,4, 94982,106474,11630,-1407, 234980,313902,19582,-6577, 599993,411063,49999,15744, 952998,698416,79416,21215, 1325726,918193,110477,33961, -2599380,-33,21 ],
  [ 2000,25,15,0,4, 124827,112114,11096,1130, 384004,382345,17455,75, 1028238,720640,46738,13982, 1688541,1189888,76752,22666, 2370766,1653129,107762,32620, -1393435,-21,-42 ],
  [ 2000,25,25,0,4, 146072,116131,10623,2178, 687056,702937,14389,-333, 2141176,1387108,41176,14501, 3674216,2441518,70658,23706, 5351432,3547325,102912,34694, 1500692,27,0 ],
  [ 2000,25,50,0,4, 147865,116470,10562,2243, 989379,907150,12471,1036, 3966850,2490548,36062,13421, 7037106,4587499,63974,22269, 10754940,7068973,97772,33509, 6605169,59,0 ],
  [ 2000,25,75,0,4, 147865,116470,10562,2243, 994697,922600,12434,901, 4023782,2578326,35927,12906, 7144018,4608581,63786,22638, 10933461,7317596,97620,32285, 6579077,59,0 ],
  [ 2000,50,1,0,4, 16624,78320,16624,-61696, 25967,54854,25967,-28887, 77802,69776,77802,8026, 88041,56333,88041,31708, 121962,65673,121962,56289, -3638545,61,27 ],
  [ 2000,50,2,0,4, 29326,80714,14663,-25694, 50435,60706,25217,-5136, 135224,81022,67612,27101, 176082,74136,88041,50973, 243924,190506,121962,26709, -3329956,-39,25 ],
  [ 2000,50,3,0,4, 42028,83109,14009,-13693, 74903,80444,24968,-1847, 192645,106633,64215,28670, 264122,187273,88041,25617, 365885,344649,121962,7079, -3259976,-38,25 ],
  [ 2000,50,5,0,4, 61417,86764,13400,-5530, 118623,91248,23725,5475, 299999,204616,60000,19077, 434618,345046,86924,17914, 602487,409413,120497,38615, -2928590,-36,23 ],
  [ 2000,50,10,0,4, 101233,107609,12396,-781, 247006,316713,20584,-5809, 640543,418903,53379,18470, 1003266,708390,83606,24573, 1395817,948252,116318,37297, -2386266,-31,20 ],
  [ 2000,50,15,0,4, 132441,113496,11773,1684, 403041,387038,18320,727, 1090451,732599,49566,16266, 1777442,1222430,80793,25228, 2496006,1694421,113455,36436, -1064286,-16,-26 ],
  [ 2000,50,25,0,4, 154649,117688,11247,2688, 720046,711557,15079,178, 2258641,1530052,43435,14011, 3866324,2509727,74352,26088, 5633971,3833485,108346,34625, 1727141,30,0 ],
  [ 2000,50,50,0,4, 156523,118042,11180,2749, 1036056,933596,13060,1292, 4173046,2726921,37937,13147, 7401349,4769362,67285,23927, 11322253,7511557,102930,34643, 6985835,61,0 ],
  [ 2000,50,75,0,4, 156523,118042,11180,2749, 1041613,935230,13020,1330, 4232708,2738356,37792,13342, 7513676,4914925,67086,23203, 11510164,7693991,102769,34073, 7063651,61,0 ],
  [ 2000,100,1,0,4, 21670,79236,21670,-57566, 29741,55397,29741,-25656, 103868,74916,103868,28953, 96871,58109,96871,38762, 134232,68214,134232,66018, -3546094,-40,26 ],
  [ 2000,100,2,0,4, 35495,81834,17748,-23169, 56484,75674,28242,-9595, 166974,101621,83487,32676, 193743,77726,96871,58008, 268464,195579,134232,36442, -3277436,-38,25 ],
  [ 2000,100,3,0,4, 49320,84432,16440,-11704, 83228,82066,27743,387, 230079,113962,76693,38706, 290614,192578,96871,32679, 402696,352254,134232,16814, -3093220,-37,24 ],
  [ 2000,100,5,0,4, 70420,101734,15364,-6832, 130983,93707,26197,7455, 347987,334536,69597,2690, 478205,354449,95641,24751, 663075,438278,132615,44960, -3037960,-37,23 ],
  [ 2000,100,10,0,4, 113735,109878,13927,472, 271059,336219,22588,-5430, 721644,525947,60137,16308, 1103803,743363,91984,30037, 1535998,992698,128000,45275, -2224551,-30,18 ],
  [ 2000,100,15,0,4, 147671,116260,13126,2792, 441114,410309,20051,1400, 1214876,847881,55222,16682, 1955244,1272589,88875,31030, 2746488,1845114,124840,40972, -686026,-10,0 ],
  [ 2000,100,25,0,4, 171805,120801,12495,3709, 786025,742683,16461,908, 2493571,1603690,47953,17113, 4250540,2803959,81741,27819, 6199047,4207883,119212,38292, 2533185,36,0 ],
  [ 2000,100,50,0,4, 173839,121185,12417,3761, 1129412,1150803,14236,-270, 4585440,2923732,41686,15106, 8129834,5283728,73908,25874, 12456880,8259105,113244,38162, 7737498,64,0 ],
  [ 2000,100,75,0,4, 173839,121185,12417,3761, 1135445,1152574,14193,-214, 4650561,2936181,41523,15307, 8252993,5322920,73687,26161, 12663569,8316520,113068,38813, 8102079,65,0 ],
  [ 2000,200,1,0,4, 31761,81067,31761,-49306, 37289,56483,37289,-19194, 156000,99710,156000,56290, 114533,61713,114533,52819, 158772,73347,158772,85425, -3394692,-39,25 ],
  [ 2000,200,2,0,4, 47833,84073,23916,-18120, 68584,77839,34292,-4628, 230474,114090,115237,58192, 229065,99785,114533,64640, 317544,318816,158772,-636, -3120965,-37,24 ],
  [ 2000,200,3,0,4, 63905,87079,21302,-7725, 99878,85310,33293,4856, 304948,220135,101649,28271, 343598,311697,114533,10634, 476316,367514,158772,36267, -3129781,-37,24 ],
  [ 2000,200,5,0,4, 88428,105003,19293,-3616, 155705,98925,31141,11356, 443964,367760,88793,15241, 565379,386881,113076,35700, 784251,562767,156850,44297, -2693722,-34,21 ],
  [ 2000,200,10,0,4, 138738,114416,16988,2978, 319164,347460,26597,-2358, 883845,571821,73654,26002, 1304878,878592,108740,35524, 1816361,1278511,151363,44821, -1664245,-24,13 ],
  [ 2000,200,15,0,4, 178130,121789,15834,5008, 517261,502931,23512,651, 1463725,924745,66533,24499, 2310847,1561673,105039,34053, 3247450,2207253,147611,47282, -44968,4,0 ],
  [ 2000,200,25,0,4, 206115,140366,14990,4782, 917984,864749,19225,1115, 2963431,1919650,56989,20073, 5018972,3299540,96519,33066, 7329200,4828568,140946,48089, 3741784,45,0 ],
  [ 2000,200,50,0,4, 208470,140807,14891,4833, 1316122,1228666,16590,1102, 5410227,3426403,49184,18035, 9586806,6161521,87153,31139, 14726134,9710870,133874,45593, 10317355,72,0 ],
  [ 2000,200,75,0,4, 208470,140807,14891,4833, 1323108,1230712,16539,1155, 5486267,3546930,48985,17316, 9731625,6285698,86890,30767, 14970380,9905030,133664,45226, 10296001,72,0 ],
  [ 2500,0,1,0,4, 14657,78044,14657,-63387, 27519,55630,27519,-28111, 61958,52272,61958,9686, 94370,57746,94370,36623, 130944,67682,130944,63263, -3626864,-41,27 ],
  [ 2500,0,2,0,4, 29314,80873,14657,-25780, 55039,76147,27519,-10554, 123915,78938,61958,22488, 188739,77077,94370,55831, 261889,194583,130944,33653, -3361878,-39,25 ],
  [ 2500,0,3,0,4, 43971,83703,14657,-13244, 82558,82778,27519,-73, 185873,91240,61958,31544, 283109,191641,94370,30489, 392833,350794,130944,14013, -3181335,-38,24 ],
  [ 2500,0,5,0,4, 66346,101360,14475,-7639, 131970,94946,26394,7405, 302263,205831,60453,19286, 465776,352467,93155,22662, 646898,435464,129380,42287, -2887345,-35,23 ],
  [ 2500,0,10,0,4, 112315,110238,13753,254, 278324,339515,23194,-5099, 674257,427216,56188,20587, 1074958,740196,89580,27897, 1499031,987748,124919,42607, -2162213,-29,18 ],
  [ 2500,0,15,0,4, 148361,117201,13188,2770, 457642,416690,20802,1861, 1168909,842698,53132,14828, 1905989,1267700,86636,29013, 2680448,1836680,121839,38353, -854992,-13,0 ],
  [ 2500,0,25,0,4, 174028,122161,12657,3772, 826083,830841,17300,-100, 2468267,1607602,47467,16551, 4163023,2798319,80058,26244, 6045005,4060519,116250,38163, 2191856,34,0 ],
  [ 2500,0,50,0,4, 176263,135899,12590,2883, 826083,1187007,10413,-4549, 4400876,2961526,40008,13085, 7997443,5105444,72704,26291, 12104167,7911876,110038,38112, 6153661,58,0 ],
  [ 2500,0,75,0,4, 176196,135916,12585,2877, 1202358,1189692,15029,158, 4692467,3097656,41897,14239, 8143769,5342104,72712,25015, 12342377,8281141,110200,36261, 7476655,63,0 ],
  [ 2500,25,1,0,4, 17180,78501,17180,-61322, 29406,55902,29406,-26495, 74990,69356,74990,5634, 98785,58685,98785,40100, 137080,69002,137080,68077, -3614138,-41,27 ],
  [ 2500,25,2,0,4, 32398,81433,16199,-24518, 58063,76688,29032,-9312, 139790,82055,69895,28867, 197570,78847,98785,59362, 274159,197119,137080,38520, -3297076,-38,25 ],
  [ 2500,25,3,0,4, 47617,84365,15872,-12249, 86720,83590,28907,1044, 204590,109419,68197,31724, 296355,194343,98785,34004, 411239,354647,137080,18864, -3131457,-37,24 ],
  [ 2500,25,5,0,4, 70848,102177,15458,-6836, 138150,96251,27630,8380, 326257,331074,65251,-963, 487569,372345,97514,23045, 677192,442261,135438,46986, -3070639,-37,24 ],
  [ 2500,25,10,0,4, 118565,111372,14518,881, 290350,342326,24196,-4331, 714807,512056,59567,16896, 1125226,750169,93769,31255, 1569121,1002136,130760,47249, -2114424,-28,17 ],
  [ 2500,25,15,0,4, 155976,118583,13865,3324, 476679,421383,21667,2513, 1231122,854657,55960,17112, 1994889,1300242,90677,31575, 2805689,1877972,127531,42169, -525843,-7,0 ],
  [ 2500,25,25,0,4, 182606,137054,13280,3313, 859073,853196,17991,123, 2585732,1721346,49726,16623, 4355131,2866178,83753,28634, 6327543,4262498,121684,39712, 2448643,36,0 ],
  [ 2500,25,50,0,4, 184854,137488,13204,3383, 1242525,1214205,15662,357, 4830932,3137716,43918,15393, 8384618,5483788,76224,26371, 12708173,8582516,115529,37506, 7972944,65,0 ],
  [ 2500,25,75,0,4, 184854,137488,13204,3383, 1249273,1216206,15616,413, 4901394,3228486,43762,14937, 8513428,5524511,76013,26687, 12919080,8656986,115349,38054, 8168704,65,0 ],
  [ 2500,50,1,0,4, 19702,78959,19702,-59257, 31293,56173,31293,-24880, 88023,71926,88023,16097, 103200,59573,103200,43627, 143215,70273,143215,72942, -3567913,-40,26 ],
  [ 2500,50,2,0,4, 35483,81993,17741,-23255, 61088,77229,30544,-8071, 155665,85172,77833,35246, 206401,95594,103200,55403, 286429,199656,143215,43387, -3254378,-38,25 ],
  [ 2500,50,3,0,4, 51263,85027,17088,-11255, 90883,84401,30294,2161, 223307,113084,74436,36741, 309601,305455,103200,1382, 429644,358449,143215,23732, -3208153,-38,24 ],
  [ 2500,50,5,0,4, 75349,102994,16440,-6032, 144330,97555,28866,9355, 350251,335751,70050,2900, 509363,376697,101873,26533, 707486,532288,141497,35040, -3012686,-36,23 ],
  [ 2500,50,10,0,4, 124816,112507,15284,1507, 302376,345136,25198,-3563, 755357,534411,62946,18412, 1175495,855477,97958,26668, 1639212,1145335,136601,41156, -2156070,-29,18 ],
  [ 2500,50,15,0,4, 163590,119965,14541,3878, 495716,426076,22533,3165, 1293334,881130,58788,18737, 2083790,1317858,94718,34815, 2930930,2032405,133224,40842, -288722,-2,0 ],
  [ 2500,50,25,0,4, 191183,138611,13904,3823, 892062,861816,18682,633, 2703197,1743725,51985,18451, 4547239,2999168,87447,29771, 6610081,4435017,127117,41828, 2938180,39,0 ],
  [ 2500,50,50,0,4, 193512,139059,13822,3889, 1289203,1226766,16250,787, 5037129,3268189,45792,16081, 8748861,5788588,79535,26912, 13275486,8843450,120686,40291, 8589587,67,0 ],
  [ 2500,50,75,0,4, 193512,139059,13822,3889, 1296189,1228836,16202,842, 5110320,3296981,45628,16191, 8883086,5830406,79313,27256, 13495782,9032931,120498,39847, 8865156,68,0 ],
  [ 2500,100,1,0,4, 24748,79875,24748,-55127, 35068,56716,35068,-21649, 114089,77066,114089,37023, 112031,61350,112031,50681, 155485,72815,155485,82670, -3475462,-40,26 ],
  [ 2500,100,2,0,4, 41651,83113,20826,-20731, 67138,78312,33569,-5587, 187415,105922,93708,40747, 224062,99185,112031,62439, 310969,204779,155485,53095, -3158275,-37,24 ],
  [ 2500,100,3,0,4, 58555,86350,19518,-9265, 99208,86023,33069,4395, 260741,197413,86914,21110, 336093,310760,112031,8444, 466454,366054,155485,33467, -3217895,-38,24 ],
  [ 2500,100,5,0,4, 84353,104628,18404,-4424, 156691,100164,31338,11305, 398240,345106,79648,10627, 552950,385400,110590,33510, 768074,560453,153615,41524, -2788485,-35,22 ],
  [ 2500,100,10,0,4, 137318,114776,16814,2760, 326429,350757,27202,-2027, 836458,564605,69705,22654, 1276032,875474,106336,33380, 1779393,1273611,148283,42148, -1811904,-26,14 ],
  [ 2500,100,15,0,4, 178820,122729,15895,4986, 533789,523197,24263,481, 1417759,919413,64444,22652, 2261592,1556784,102800,32037, 3181411,2198769,144610,44666, -257517,-1,0 ],
  [ 2500,100,25,0,4, 208339,141725,15152,4845, 958042,892942,20064,1363, 2938127,1923413,56502,19514, 4931455,3308877,94836,31203, 7175157,4809915,137984,45485, 3476743,43,0 ],
  [ 2500,100,50,0,4, 210827,142202,15059,4902, 1382558,1265772,17427,1472, 5449523,3572550,49541,17063, 9477347,6180017,86158,29976, 14410113,9591998,131001,43801, 9838452,71,0 ],
  [ 2500,100,75,0,4, 210827,142202,15059,4902, 1390021,1267980,17375,1526, 5528173,3602356,49359,17195, 9622402,6304232,85914,29627, 14649188,9853330,130796,42820, 9981821,71,0 ],
  [ 2500,200,1,0,4, 34839,81707,34839,-46867, 42616,57802,42616,-15186, 166220,101860,166220,64361, 129692,64954,129692,64738, 180025,77948,180025,102077, -3324060,-39,25 ],
  [ 2500,200,2,0,4, 53989,85352,26995,-15681, 79237,80477,39619,-620, 250915,132905,125458,59005, 259384,186624,129692,36380, 360050,343738,180025,8156, -3142898,-37,24 ],
  [ 2500,200,3,0,4, 73139,102334,24380,-9732, 115858,89118,38619,8914, 335610,332635,111870,992, 389076,337046,129692,17343, 540075,397635,180025,47480, -3251231,-38,25 ],
  [ 2500,200,5,0,4, 102360,107897,22333,-1208, 181412,119267,36282,12429, 494217,378181,98843,23207, 640124,417832,128025,44458, 889250,585441,177850,60762, -2416923,-32,19 ],
  [ 2500,200,10,0,4, 162321,119314,19876,5266, 374534,375884,31211,-113, 998659,716378,83222,23523, 1477107,945871,123092,44270, 2059756,1363004,171646,58063, -1303772,-20,-40 ],
  [ 2500,200,15,0,4, 209279,141594,18603,6016, 609936,541820,27724,3096, 1666609,1179327,75755,22149, 2617196,1753035,118963,39280, 3682373,2545887,167381,51658, 289897,11,0 ],
  [ 2500,200,25,0,4, 242649,219652,17647,1672, 1090001,1045659,22827,929, 3407987,2146338,65538,24262, 5699887,3723550,109613,38006, 8305310,5558262,159718,52828, 4591807,50,0 ],
  [ 2500,200,50,0,4, 245458,220188,17533,1805, 1569268,1521986,19781,596, 6274310,4073721,57039,20005, 10934318,7072637,99403,35106, 16679367,11156204,151631,50211, 11515880,76,0 ],
  [ 2500,200,75,0,4, 245458,220188,17533,1805, 1577685,1524468,19721,665, 6363879,4091040,56820,20293, 11101034,7200979,99116,34822, 16955998,11327550,151393,50254, 11831980,77,0 ],
  [ 3000,0,1,0,4, 17735,78683,17735,-60948, 32846,56949,32846,-24103, 72178,68937,72178,3242, 109529,61037,109529,48492, 152197,72332,152197,79866, -3589731,-40,26 ],
  [ 3000,0,2,0,4, 35470,82152,17735,-23341, 65692,78785,32846,-6546, 144357,83239,72178,30559, 219058,98535,109529,60262, 304395,203783,152197,50306, -3242717,-38,25 ],
  [ 3000,0,3,0,4, 53205,85621,17735,-10805, 98538,86735,32846,3934, 216535,112205,72178,34777, 328588,309873,109529,6238, 456592,364645,152197,30649, -3163011,-38,24 ],
  [ 3000,0,5,0,4, 80279,104254,17515,-5231, 157678,115288,31536,8478, 352515,336817,70503,3140, 540521,383969,108104,31310, 751897,542468,150379,41886, -2949158,-36,23 ],
  [ 3000,0,10,0,4, 135898,115136,16641,2542, 333694,354054,27808,-1697, 789070,542874,65756,20516, 1247187,872307,103932,31240, 1742426,1268661,145202,39480, -1926064,-27,16 ],
  [ 3000,0,15,0,4, 179510,137006,15956,3778, 550317,529429,25014,949, 1371792,899715,62354,21458, 2212337,1551995,100561,30016, 3115372,2190435,141608,42043, -447676,-5,0 ],
  [ 3000,0,25,0,4, 210562,143084,15314,4908, 998099,907250,20903,1903, 2912823,1927325,56016,18952, 4843938,3222930,93153,31173, 7021115,4791313,135021,42881, 3395914,42,0 ],
  [ 3000,0,50,0,4, 213184,143597,15227,4971, 1448994,1376729,18265,911, 5488818,3612646,49898,17056, 9367887,6198014,85163,28817, 14094092,9556406,128128,41252, 9311411,69,0 ],
  [ 3000,0,75,0,4, 213184,143597,15227,4971, 1456934,1379098,18212,973, 5570079,3643266,49733,17204, 9513179,6322715,84939,28486, 14327995,9620380,127929,42032, 9596553,70,0 ],
  [ 3000,25,1,0,4, 20258,79141,20258,-58883, 34733,57221,34733,-22488, 85211,71507,85211,13705, 113945,61926,113945,52019, 158332,73602,158332,84730, -3543506,-40,26 ],
  [ 3000,25,2,0,4, 38554,82712,19277,-22079, 68717,79326,34358,-5305, 160232,86356,80116,36938, 227889,100305,113945,63792, 316665,319410,158332,-1373, -3258547,-38,25 ],
  [ 3000,25,3,0,4, 56851,86283,18950,-9811, 102701,87547,34234,5051, 235252,192870,78417,14128, 341834,327502,113945,4777, 474997,368447,158332,35517, -3278235,-38,25 ],
  [ 3000,25,5,0,4, 84780,105071,18498,-4427, 163858,116592,32772,9453, 376509,341494,75302,7003, 562314,388320,112463,34799, 782191,564386,156438,43561, -2842644,-35,22 ],
  [ 3000,25,10,0,4, 142149,116270,17406,3169, 345720,370749,28810,-2086, 829621,550564,69135,23255, 1297455,897257,108121,33350, 1812517,1282999,151043,44126, -1767464,-25,14 ],
  [ 3000,25,15,0,4, 187125,138388,16633,4332, 569354,534122,25880,1601, 1434005,926188,65182,23083, 2301238,1584588,104602,32575, 3240612,2231777,147301,45856, -152027,2,0 ],
  [ 3000,25,25,0,4, 219140,144641,15937,5418, 1031089,929756,21593,2122, 3030288,2041069,58275,19023, 5036046,3355970,96847,32309, 7303653,4963832,140455,44997, 3631870,44,0 ],
  [ 3000,25,50,0,4, 221842,216869,15846,355, 1495671,1507525,18853,-149, 5695015,3755984,51773,17628, 9732130,6394404,88474,30343, 14661406,9914259,133286,43156, 9320980,69,0 ],
  [ 3000,25,75,0,4, 221842,216869,15846,355, 1504103,1509962,18801,-73, 5781604,3772596,51621,17938, 9866910,6537266,88097,29729, 14767517,10066295,131853,41975, 9455682,70,0 ],
  [ 3000,50,1,0,4, 22781,79599,22781,-56818, 36620,57492,36620,-20872, 98244,74076,98244,24168, 118360,62814,118360,55546, 164467,74873,164467,89594, -3497280,-40,26 ],
  [ 3000,50,2,0,4, 41639,83272,20819,-20816, 71742,79868,35871,-4063, 176107,103988,88053,36060, 236720,182433,118360,27143, 328935,321997,164467,3469, -3345770,-39,25 ],
  [ 3000,50,3,0,4, 60497,86945,20166,-8816, 106863,88358,35621,6168, 253970,196534,84657,19145, 355080,330155,118360,8308, 493402,387921,164467,35160, -3206030,-38,24 ],
  [ 3000,50,5,0,4, 89282,105888,19480,-3623, 170038,117897,34008,10428, 400503,346171,80101,10866, 584108,392672,116822,38287, 812485,570633,162497,48371, -2724957,-34,22 ],
  [ 3000,50,10,0,4, 148400,117405,18171,3795, 357746,373560,29812,-1318, 870171,572918,72514,24771, 1347724,907280,112310,36704, 1882607,1313108,156884,47458, -1587851,-23,12 ],
  [ 3000,50,15,0,4, 194739,139771,17310,4886, 588390,538815,26745,2253, 1496217,952662,68010,24707, 2390139,1617180,108643,35134, 3365853,2257448,152993,50382, 154795,8,0 ],
  [ 3000,50,25,0,4, 227717,217898,16561,714, 1064079,938376,22284,2633, 3147753,2077962,60534,20573, 5228154,3408702,100541,34989, 7586191,5165711,145888,46548, 3891551,46,0 ],
  [ 3000,50,50,0,4, 230500,218440,16464,861, 1542349,1520086,19441,281, 5901212,3915507,53647,18052, 10096373,6684727,91785,31015, 15228719,10288783,138443,44909, 3802975,45,0 ],
  [ 3000,50,75,0,4, 230500,218440,16464,861, 1550766,1522592,19385,352, 5987932,3947141,53464,18221, 10252495,6731210,91540,31440, 15481400,10455950,138227,44870, 10177860,72,0 ],
  [ 3000,100,1,0,4, 27826,80514,27826,-52688, 40394,58035,40394,-17641, 124310,79216,124310,45094, 127190,64591,127190,62599, 176737,77415,176737,99323, -3404829,-39,26 ],
  [ 3000,100,2,0,4, 47808,84391,23904,-18292, 77791,80950,38896,-1579, 207857,110222,103928,48817, 254381,185974,127190,34203, 353475,342741,176737,5367, -3227339,-38,24 ],
  [ 3000,100,3,0,4, 67789,101605,22596,-11272, 115188,89830,38396,8453, 291404,218378,97135,24342, 381571,335659,127190,15304, 530212,395726,176737,44829, -3127467,-37,24 ],
  [ 3000,100,5,0,4, 98286,107522,21444,-2015, 182399,194356,36480,-2391, 448492,370041,89698,15690, 627695,416401,125539,42259, 873073,583177,174615,57979, -2778967,-34,22 ],
  [ 3000,100,10,0,4, 160901,119674,19702,5048, 381799,379181,31817,218, 951272,709162,79273,20176, 1448261,942754,120688,42126, 2022788,1358104,168566,55390, -1451432,-21,-48 ],
  [ 3000,100,15,0,4, 209969,142535,18664,5994, 626464,562086,28476,2926, 1620642,1173994,73666,20302, 2567941,1748146,116725,37263, 3616334,2537403,164379,49042, 77347,7,0 ],
  [ 3000,100,25,0,4, 244873,221012,17809,1735, 1130058,1147702,23666,-369, 3382683,2150100,65052,23704, 5612370,3717911,107930,36432, 8151268,5523938,156755,50526, 4126263,47,0 ],
  [ 3000,100,50,0,4, 247816,221583,17701,1874, 1635704,1559092,20618,966, 6313605,4113817,57396,19998, 10824859,7090633,98408,33948, 16363346,11007521,148758,48689, 11303252,75,0 ],
  [ 3000,100,75,0,4, 247816,221583,17701,1874, 1644598,1561736,20557,1036, 6405785,4146466,57195,20172, 10991811,7219512,98141,33681, 16634806,11207740,148525,48456, 11566361,76,0 ],
  [ 3000,200,1,0,4, 37917,82346,37917,-44429, 47942,59121,47942,-11179, 176441,104010,176441,72431, 144852,68195,144852,76657, 201278,82548,201278,118730, -3253427,-38,25 ],
  [ 3000,200,2,0,4, 60145,86631,30073,-13243, 89890,83115,44945,3388, 271357,214205,135678,28576, 289703,193106,144852,48299, 402555,352938,201278,24809, -3178130,-38,24 ],
  [ 3000,200,3,0,4, 82373,104252,27458,-7293, 131838,93075,43946,12921, 366272,339085,122091,9062, 434555,346769,144852,29262, 603833,411436,201278,64132, -3039333,-37,23 ],
  [ 3000,200,5,0,4, 116293,110791,25373,1201, 207120,199573,41424,1509, 544469,403266,108894,28241, 714869,514165,142974,40141, 994249,736976,198850,51455, -2552048,-33,20 ],
  [ 3000,200,10,0,4, 185905,124212,22764,7554, 429904,390423,35825,3290, 1113473,755036,92789,29870, 1649335,1186442,137445,38574, 2303151,1643917,191929,54936, -1051200,-16,-32 ],
  [ 3000,200,15,0,4, 240428,219763,21371,1837, 702611,685208,31937,791, 1869492,1249358,84977,28188, 2923544,1956923,132888,43937, 4117297,2785951,187150,60516, 530737,15,0 ],
  [ 3000,200,25,0,4, 279183,328106,20304,-3558, 1262018,1194568,26430,1413, 3852544,2466061,74087,26663, 6380802,4214842,122708,41653, 9281421,6245425,178489,58385, 5138754,53,0 ],
  [ 3000,200,50,0,4, 282447,328736,20175,-3306, 1822414,1709455,22972,1424, 7138392,4616489,64894,22926, 12281830,7984753,111653,39064, 18632600,12489946,169387,55842, 13247346,81,0 ],
  [ 3000,200,75,0,4, 282447,328736,20175,-3306, 1832261,1712374,22903,1499, 7241491,4742700,64656,22311, 12470444,8226719,111343,37890, 18941616,12668789,169122,56007, 13229173,81,0 ],
  [ 1000,0,1,1,4, 9640,76835,9640,-67194, 18511,53178,18511,-34667, 45065,48397,45065,-3332, 66546,36506,66546,30041, 87753,58065,87753,29688, -3726693,-41,27 ],
  [ 1000,0,2,1,4, 19280,78455,9640,-29587, 37022,57358,18511,-10168, 90130,71340,45065,9395, 133093,64548,66546,34272, 175507,75948,87753,49779, -3491253,-40,26 ],
  [ 1000,0,3,1,4, 28921,80076,9640,-17052, 55533,75423,18511,-6630, 135195,79617,45065,18526, 199639,77564,66546,40692, 263260,193183,87753,23359, -3314701,-39,25 ],
  [ 1000,0,5,1,4, 43640,82552,9521,-8490, 88092,83015,17618,1015, 218652,109653,43730,21800, 329391,306578,65878,4563, 434126,356555,86825,15514, -3204353,-38,24 ],
  [ 1000,0,10,1,4, 73892,100983,9048,-3317, 181011,119677,15084,5111, 479388,355124,39949,10355, 765635,516568,63803,20756, 1009984,732449,84165,23128, -2467923,-32,20 ],
  [ 1000,0,15,1,4, 97625,104983,8678,-654, 290972,340172,13226,-2236, 819265,536158,37239,12869, 1359510,879093,61796,21837, 1813300,1251552,82423,25534, -1829301,-26,15 ],
  [ 1000,0,25,1,4, 114536,107839,8330,487, 506342,426669,10604,1669, 1680662,961037,32320,13839, 2946970,1803138,56673,21997, 4137454,2636841,79566,28858, -1829301,-26,15 ],
  [ 1000,0,50,1,4, 115965,108082,8283,563, 716763,705483,9035,142, 3055352,1775558,27776,11634, 5583926,3402776,50763,19829, 8403077,5342647,76392,27822, 4995245,52,0 ],
  [ 1000,0,75,1,4, 115965,108082,8283,563, 720463,706524,9006,174, 3097491,1903469,27656,10661, 5666572,3418659,50594,20071, 8544587,5499435,76291,27189, 4893382,51,0 ],
  [ 1000,25,1,1,4, 13254,77490,13254,-64237, 21334,53602,21334,-32268, 61387,66128,61387,-4741, 72556,37765,72556,34791, 95766,59776,95766,35991, -3698835,-41,27 ],
  [ 1000,25,2,1,4, 23892,79292,11946,-27700, 41669,58204,20835,-8267, 110544,75343,55272,17601, 145112,66959,72556,39077, 191533,79263,95766,56135, -3402905,-39,26 ],
  [ 1000,25,3,1,4, 34531,81095,11510,-15521, 62004,76690,20668,-4895, 159702,84409,53234,25098, 217668,81176,72556,45497, 287299,198152,95766,29716, -3199362,-38,24 ],
  [ 1000,25,5,1,4, 50771,83846,11077,-7216, 97810,85060,19562,2550, 250805,192913,50161,11578, 359112,312515,71822,9319, 473751,364729,94750,21805, -3215786,-38,24 ],
  [ 1000,25,10,1,4, 84133,102841,10302,-2291, 200202,197982,16683,185, 535764,380525,44647,12937, 834580,545277,69548,24109, 1102060,767074,91838,27916, -2449512,-32,20 ],
  [ 1000,25,15,1,4, 110290,107282,9804,267, 321608,347678,14619,-1185, 907608,553121,41255,16113, 1481983,918273,67363,25623, 1978384,1300999,89927,30790, -1344640,-20,-39 ],
  [ 1000,25,25,1,4, 128913,110449,9375,1343, 560034,528386,11728,663, 1851936,1191052,35614,12709, 3214048,2074828,61809,21908, 4512525,2941745,86779,30207, 1086785,23,0 ],
  [ 1000,25,50,1,4, 130485,110717,9320,1412, 793247,739910,9999,672, 3362640,2045491,30569,11974, 6095498,3736848,55414,21442, 9162054,5968347,83291,29034, 5618216,55,0 ],
  [ 1000,25,75,1,4, 130485,110717,9320,1412, 797344,741066,9967,703, 3409022,2068140,30438,11972, 6185905,3849012,55231,20865, 9316298,6014082,83181,29484, 5727066,55,0 ],
  [ 1000,50,1,1,4, 16867,78146,16867,-61279, 24157,54026,24157,-29869, 77708,69343,77708,8365, 78566,53952,78566,24614, 103780,61436,103780,42343, -3659579,-41,27 ],
  [ 1000,50,2,1,4, 28504,80130,14252,-25813, 46316,59049,23158,-6367, 130959,79347,65479,25806, 157132,69370,78566,43881, 207559,82578,103780,62491, -3314556,-39,25 ],
  [ 1000,50,3,1,4, 40141,82113,13380,-13991, 68475,77958,22825,-3161, 184209,103716,61403,26831, 235697,99815,78566,45294, 311339,203171,103780,36056, -3139628,-37,24 ],
  [ 1000,50,5,1,4, 57740,84856,12598,-5916, 107896,86267,21579,4326, 241535,189864,48307,10334, 322393,314776,64479,1523, 418552,208171,83710,42076, -3162966,-38,24 ],
  [ 1000,50,10,1,4, 94374,104700,11556,-1264, 219393,202437,18283,1413, 592139,391411,49345,16727, 903525,558960,75294,28714, 1194136,785979,99511,34013, -2130543,-29,17 ],
  [ 1000,50,15,1,4, 122956,109581,10929,1189, 352245,355184,16011,-134, 995951,690650,45271,13877, 1604455,958003,72930,29384, 2143468,1366667,97430,35309, -1150032,-17,-29 ],
  [ 1000,50,25,1,4, 143290,113059,10421,2199, 613726,542367,12853,1494, 2023211,1238168,38908,15097, 3481126,2142273,66945,25747, 4887596,3148781,93992,33439, 1959592,32,0 ],
  [ 1000,50,50,1,4, 145005,113353,10358,2261, 869731,848187,10963,272, 3669928,2132374,33363,13978, 6607071,4070421,60064,23060, 9921031,6396677,90191,32040, 6569647,59,0 ],
  [ 1000,50,75,1,4, 145005,113353,10358,2261, 874225,849457,10928,310, 3720554,2155810,33219,13971, 6705238,4184080,59868,22510, 10088008,6444948,90071,32527, 6703828,60,0 ],
  [ 1000,100,1,1,4, 24094,79458,24094,-55364, 29803,54874,29803,-25071, 110351,75774,110351,34577, 90585,56372,90585,34214, 119806,64757,119806,55048, -3536862,-40,26 ],
  [ 1000,100,2,1,4, 37728,81804,18864,-22038, 55610,60741,27805,-2565, 171787,101869,85893,34959, 181170,74242,90585,53464, 239612,188708,119806,25452, -3242267,-38,25 ],
  [ 1000,100,3,1,4, 51361,84149,17120,-10929, 81417,80492,27139,308, 233223,113300,77741,39974, 271756,187347,90585,28136, 359417,326198,119806,11073, -3108107,-37,24 ],
  [ 1000,100,5,1,4, 72166,101066,15745,-6306, 126964,91045,25393,7184, 347262,332257,69452,3001, 448273,346004,89655,20454, 592625,405622,118525,37401, -3087441,-37,24 ],
  [ 1000,100,10,1,4, 114856,108418,14064,788, 257774,315696,21481,-4827, 704891,426198,58741,23224, 1041415,709813,86785,27633, 1378288,922789,114857,37958, -2105811,-28,17 ],
  [ 1000,100,15,1,4, 148288,114179,13181,3032, 413518,384081,18796,1338, 1172637,753457,53302,19054, 1849400,1225230,84064,28371, 2473635,1662481,112438,36871, -2105811,-28,17 ],
  [ 1000,100,25,1,4, 172045,118278,12512,3910, 721110,688565,15102,682, 2365760,1528313,45495,16105, 4015281,2496435,77217,29209, 5637738,3660271,108418,38028, 2487359,36,0 ],
  [ 1000,100,50,1,4, 174045,118624,12432,3959, 1022700,903156,12891,1507, 4284504,2580855,38950,15488, 7630216,4724939,69366,26412, 11438985,7337716,103991,37284, 8311495,66,0 ],
  [ 1000,100,75,1,4, 174045,118624,12432,3959, 1027988,904655,12850,1542, 4343616,2591352,38782,15645, 7743905,4869694,69142,25663, 11631428,7474792,103852,37113, 8428552,66,0 ],
  [ 1000,200,1,1,4, 38547,82081,38547,-43534, 41095,56570,41095,-15475, 175637,103151,175637,72486, 114624,61261,114624,53363, 151858,71450,151858,80409, -3324928,-39,25 ],
  [ 1000,200,2,1,4, 56175,85152,28087,-14489, 74198,78008,37099,-1905, 253444,132249,126722,60597, 229248,98913,114624,65168, 303717,201967,151858,50875, -2988060,-36,23 ],
  [ 1000,200,3,1,4, 73802,101559,24601,-9252, 107300,85412,35767,7296, 331251,330033,110417,406, 343872,310956,114624,10972, 455575,362444,151858,31044, -3328070,-39,25 ],
  [ 1000,200,5,1,4, 100693,106244,21969,-1211, 165835,99226,33167,13322, 475873,371811,95175,20812, 567154,384780,113431,36475, 751124,537819,150225,42661, -2553694,-33,20 ],
  [ 1000,200,10,1,4, 155820,115853,19080,4894, 334537,347400,27878,-1072, 930393,575621,77533,29564, 1317194,874906,109766,36857, 1746592,1242340,145549,42021, -1453634,-22,-53 ],
  [ 1000,200,15,1,4, 198951,123375,17685,6718, 536063,501841,24367,1556, 1526009,927191,69364,27219, 2339291,1555841,106331,35611, 3133970,2140969,142453,45136, 250923,10,0 ],
  [ 1000,200,25,1,4, 229554,142053,16695,6364, 935877,845961,19600,1883, 3050858,1914038,58670,21862, 5083591,3269689,97761,34883, 7138023,4713161,137270,46632, 4245720,48,0 ],
  [ 1000,200,50,1,4, 232125,142502,16580,6402, 1328637,1205029,16748,1558, 5513657,3293137,50124,20187, 9676507,6090330,87968,32602, 14474893,9416167,131590,45988, 11333041,75,0 ],
  [ 1000,200,75,1,4, 232125,142502,16580,6402, 1335514,1206986,16694,1607, 5589742,3321299,49908,20254, 9821238,6117684,87690,33067, 14718269,9495331,131413,46633, 11748657,76,0 ],
  [ 1500,0,1,1,4, 15112,77971,15112,-62859, 27050,55240,27050,-28190, 59783,51494,59783,8289, 87180,55898,87180,31282, 115512,64078,115512,51434, -3644466,-41,27 ],
  [ 1500,0,2,1,4, 30225,80729,15112,-25252, 54101,75366,27050,-10633, 119566,77384,59783,21091, 174360,73380,87180,50490, 231024,103595,115512,63715, -3337350,-39,25 ],
  [ 1500,0,3,1,4, 45337,83486,15112,-12716, 81151,81608,27050,-152, 179349,88908,59783,30147, 261540,186096,87180,25148, 346536,324311,115512,7408, -3222970,-38,24 ],
  [ 1500,0,5,1,4, 68409,101033,14926,-7118, 129381,92999,25876,7276, 291220,201921,58244,17860, 431332,343513,86266,17564, 571474,402119,114295,33871, -2963541,-36,23 ],
  [ 1500,0,10,1,4, 115818,109690,14182,750, 270473,334876,22539,-5367, 646601,417672,53883,19077, 1001941,705576,83495,24697, 1329788,916331,110816,34455, -2323686,-31,19 ],
  [ 1500,0,15,1,4, 153001,116484,13600,3246, 441496,408326,20068,1508, 1116691,733474,50759,17419, 1781766,1218523,80989,25602, 2386692,1651312,108486,33426, -914894,-14,-20 ],
  [ 1500,0,25,1,4, 179485,121327,13053,4230, 788173,739844,16506,1012, 2341665,1536539,45032,15483, 3894650,2488875,74897,27034, 5433441,3551794,104489,36186, 2245954,34,0 ],
  [ 1500,0,50,1,4, 181723,121737,12980,4285, 1133713,1147484,14291,-174, 4361569,2756597,39651,14591, 7480268,4760592,68002,24724, 11017026,7290410,100155,33878, 7139333,61,0 ],
  [ 1500,0,75,1,4, 181723,121737,12980,4285, 1139797,1149252,14247,-118, 4424809,2768376,39507,14790, 7594356,4891082,67807,24136, 11202488,7343053,100022,34459, 7341446,62,0 ],
  [ 1500,25,1,1,4, 18726,78627,18726,-59901, 29873,55664,29873,-25791, 76105,69225,76105,6880, 93190,57158,93190,36032, 123525,65788,123525,57737, -3616608,-41,27 ],
  [ 1500,25,2,1,4, 34836,81566,17418,-23365, 58748,76212,29374,-8732, 139980,81388,69990,29296, 186380,75791,93190,55294, 247050,190690,123525,28180, -3308735,-39,25 ],
  [ 1500,25,3,1,4, 50947,84504,16982,-11186, 87622,82875,29207,1582, 203856,108215,67952,31880, 279570,189758,93190,29937, 370575,345001,123525,8525, -3152305,-37,24 ],
  [ 1500,25,5,1,4, 75541,102328,16482,-5844, 139098,95044,27820,8811, 323372,314231,64674,1828, 461052,349951,92210,22220, 611099,410793,122220,40061, -3043854,-37,23 ],
  [ 1500,25,10,1,4, 126059,111549,15436,1777, 289664,339331,24139,-4139, 702976,428558,58581,22868, 1070886,734236,89241,28054, 1421864,950906,118489,39246, -2037994,-28,17 ],
  [ 1500,25,15,1,4, 165666,118783,14726,4167, 472132,415832,21461,2559, 1205034,841952,54774,16504, 1904238,1242776,86556,30066, 2551776,1700809,115990,38680, -618128,-9,0 ],
  [ 1500,25,25,1,4, 193862,137273,14099,4116, 841865,827525,17631,300, 2512940,1583654,48326,17871, 4161728,2651755,80033,29038, 5808512,3856399,111702,37541, 2620199,37,0 ],
  [ 1500,25,50,1,4, 196243,137709,14017,4181, 1210198,1181761,15255,358, 4668857,2920480,42444,15894, 7991841,5094314,72653,26341, 11776003,7718890,107055,36883, 8093355,65,0 ],
  [ 1500,25,75,1,4, 196243,137709,14017,4181, 1216678,1183643,15208,413, 4736341,2933047,42289,16101, 8113690,5132817,72444,26615, 11974198,7857850,106912,36753, 8399034,66,0 ],
  [ 1500,50,1,1,4, 22339,79283,22339,-56944, 32696,56088,32696,-23392, 92426,72440,92426,19986, 99200,58368,99200,40832, 131538,67449,131538,64090, -3555249,-40,26 ],
  [ 1500,50,2,1,4, 39448,82403,19724,-21477, 63395,77058,31697,-6832, 160394,85391,80197,37502, 198399,78202,99200,60098, 263077,194004,131538,34536, -3220387,-38,25 ],
  [ 1500,50,3,1,4, 56557,85523,18852,-9655, 94093,84142,31364,3317, 228363,113007,76121,38452, 297599,193370,99200,34743, 394615,349970,131538,14882, -3036967,-37,23 ],
  [ 1500,50,5,1,4, 82672,103622,18038,-4571, 148816,97089,29763,10345, 355525,335006,71105,4104, 490773,355938,98155,26967, 650724,434688,130145,43207, -2923463,-36,23 ],
  [ 1500,50,10,1,4, 136300,113408,16690,2803, 308855,343785,25738,-2911, 759352,530959,63279,19033, 1139831,747969,94986,32655, 1513940,985532,126162,44034, -1940196,-27,16 ],
  [ 1500,50,15,1,4, 178332,121082,15852,5089, 502769,423338,22853,3610, 1293377,873431,58790,19088, 2026711,1282006,92123,33850, 2716860,1834086,123494,40126, -226702,0,0 ],
  [ 1500,50,25,1,4, 208240,139883,15145,4971, 895557,855392,18755,841, 2684214,1722134,51620,18502, 4428806,2842687,85169,30502, 6183583,4063434,118915,40772, 3057246,40,0 ],
  [ 1500,50,50,1,4, 210763,140344,15054,5030, 1286682,1216188,16219,889, 4976145,3113413,45238,16934, 8503414,5428386,77304,27955, 12534980,8231500,113954,39123, 8973455,68,0 ],
  [ 1500,50,75,1,4, 210763,140344,15054,5030, 1293772,1218185,16172,945, 5040800,3218232,45007,16273, 8619880,5465545,76963,28164, 12724058,8285114,113608,39633, 9110805,69,0 ],
  [ 1500,100,1,1,4, 29566,80595,29566,-51029, 38342,56936,38342,-18593, 125069,78871,125069,46198, 111219,60788,111219,50431, 147565,70770,147565,76795, -3432532,-39,26 ],
  [ 1500,100,2,1,4, 48672,84077,24336,-17703, 72688,78749,36344,-3030, 201223,107914,100611,46655, 222438,98051,111219,62194, 295129,200683,147565,47223, -3099294,-39,24 ],
  [ 1500,100,3,1,4, 67778,100896,22593,-11039, 107035,86527,35678,6836, 277377,199591,92459,25928, 333657,309205,111219,8151, 442694,360057,147565,27545, -3197555,-38,24 ],
  [ 1500,100,5,1,4, 96936,106211,21150,-2024, 168252,115065,33650,10637, 419830,347376,83966,14491, 550213,382789,110043,33485, 729973,534766,145995,39041, -2718755,-34,21 ],
  [ 1500,100,10,1,4, 156782,117126,19198,4856, 347236,352695,28936,-455, 872103,567246,72675,25405, 1277721,870669,106477,33921, 1698092,1235933,141508,38513, -2718755,-34,21 ],
  [ 1500,100,15,1,4, 203664,139016,18103,5746, 564041,525935,25638,1732, 1470063,921723,66821,24925, 2271656,1549334,103257,32833, 3047027,2046219,138501,45491, 17830,6,0 ],
  [ 1500,100,25,1,4, 236994,216802,17236,1469, 1002940,897240,21004,2214, 3026763,1922264,58207,21240, 4962961,3196799,95442,33965, 6933725,4604685,133341,44789, 3806751,45,0 ],
  [ 1500,100,50,1,4, 239803,217315,17129,1606, 1439650,1345007,18147,1193, 5590721,3547379,50825,18576, 9526559,6111007,86605,31050, 14052934,9172489,127754,44368, 10179488,72,0 ],
  [ 1500,100,75,1,4, 239803,217315,17129,1606, 1447322,1347233,18092,1251, 5670935,3576824,50633,18697, 9671690,6154049,86354,31408, 14289329,9447872,127583,43227, 10435853,73,0 ],
  [ 1500,200,1,1,4, 44019,83218,44019,-39199, 49634,58631,49634,-8997, 190355,106248,190355,84107, 135258,65677,135258,69581, 179617,77462,179617,102155, -3220598,-38,24 ],
  [ 1500,200,2,1,4, 67119,87425,33559,-10153, 91276,82131,45638,4572, 282880,215444,141440,33718, 270516,188053,135258,41232, 359234,342754,179617,8240, -3166227,-38,24 ],
  [ 1500,200,3,1,4, 90219,104969,30073,-4917, 132918,91596,44306,13774, 375404,353839,125135,7188, 405773,339231,135258,22181, 538851,396203,179617,47549, -3081856,-37,24 ],
  [ 1500,200,5,1,4, 125462,111389,27374,3070, 207124,197095,41425,2006, 548441,401445,109688,29399, 669095,421615,133819,49496, 888472,583233,177694,61048, -2392555,-31,19 ],
  [ 1500,200,10,1,4, 197746,124561,24214,8961, 423999,384399,35333,3300, 1097606,745719,91467,29324, 1553500,955954,129458,49795, 2066396,1343442,172200,60246, -774565,-12,0 ],
  [ 1500,200,15,1,4, 254327,219912,22607,3059, 686587,569844,31208,5306, 1823435,1216022,82883,27610, 2761546,1772034,125525,44978, 3707362,2540928,168516,53020, 788832,19,0 ],
  [ 1500,200,25,1,4, 294504,328107,21418,-2444, 1217708,1157636,25502,1258, 3711861,2383489,71382,25546, 6031271,3862943,115986,41699, 8434009,5629115,162192,53940, 4883984,19,0 ],
  [ 1500,200,50,1,4, 297882,328724,21277,-2203, 1745587,1571680,22003,2192, 6819874,4168126,61999,24107, 11572850,7353861,105208,38354, 17088842,11168059,155353,53825, 13529624,81,0 ],
  [ 1500,200,75,1,4, 297882,328724,21277,-2203, 1754848,1648214,21936,1333, 6917061,4369256,61759,22748, 11749023,7498624,104902,37950, 17376170,11340949,155144,53886, 13205158,81,0 ],
  [ 2000,0,1,1,4, 20584,79108,20584,-58523, 35590,57302,35590,-21712, 74501,69107,74501,5394, 107814,60364,107814,47450, 143271,70140,143271,73131, -3573637,-40,26 ],
  [ 2000,0,2,1,4, 41169,83002,20584,-20916, 71179,79490,35590,-4155, 149002,83578,74501,32712, 215628,97189,107814,59220, 286542,199400,143271,43571, -3210527,-38,24 ],
  [ 2000,0,3,1,4, 61753,100233,20584,-12826, 106769,87643,35590,6375, 223503,112715,74501,36929, 323442,308004,107814,5146, 429812,358220,143271,23864, -3169420,-38,24 ],
  [ 2000,0,5,1,4, 93179,106178,20330,-2836, 170669,117018,34134,10730, 363788,337605,72758,5237, 533273,380849,106655,30485, 708822,531813,141764,35402, -2873733,-35,22 ],
  [ 2000,0,10,1,4, 157744,118398,19316,4818, 359936,371875,29995,-995, 813813,544205,67818,22467, 1238247,866382,103187,30989, 1649592,1145645,137466,41996, -1752566,-25,14 ],
  [ 2000,0,15,1,4, 207856,118398,18476,7952, 414882,521091,18858,-4828, 964432,549205,43838,18874, 1343137,866382,61052,21671, 1771575,1145645,80526,28451, -1276469,-19,-41 ],
  [ 2000,0,25,1,4, 244434,219851,17777,1788, 1070003,934633,22408,2835, 3002669,1930640,57744,20616, 4842330,3189239,93122,31790, 6729427,4466948,129412,43509, 3629827,44,0 ],
  [ 2000,0,50,1,4, 247480,220428,17677,1932, 1550664,1515485,19546,443, 5667785,3617071,51525,18643, 9376611,6146160,85242,29368, 13630975,9040903,123918,41728, 9546219,70,0 ],
  [ 2000,0,75,1,4, 247480,220428,17677,1932, 1559131,1517979,19489,514, 5752128,3647798,51358,18789, 9522141,6189914,85019,29752, 13860388,9202542,123753,41588, 9886434,71,0 ],
  [ 2000,25,1,1,4, 26623,223064,26623,-196441, 51803,1522521,51803,-1470718, 195564,3868519,195564,-3672955, 4692431,6525483,4692431,-1833052, 13241066,9717689,13241066,3523377, -17430834,-88,59 ],
  [ 2000,25,2,1,4, 45781,83839,22890,-19029, 75826,80335,37913,-2254, 169416,87582,84708,40917, 227647,99600,113824,64024, 302568,202715,151284,49927, -3122179,-37,24 ],
  [ 2000,25,3,1,4, 67364,101251,22455,-11296, 113240,88910,37747,8110, 248010,194507,82670,17834, 341471,311616,113824,9952, 453852,363189,151284,30221, -3230580,-38,24 ],
  [ 2000,25,5,1,4, 100310,107473,21886,-1563, 180387,119063,36077,12265, 395940,343865,79188,10415, 562993,386786,112599,35241, 748447,539987,149689,41692, -2708669,-34,21 ],
  [ 2000,25,10,1,4, 167985,120257,20570,5844, 379126,376329,31594,233, 870189,569606,72516,25049, 1307192,880116,108933,35590, 1741668,1248379,145139,41107, -2708669,-34,21 ],
  [ 2000,25,15,1,4, 221042,143620,19648,6882, 622656,543800,28303,3584, 1502460,933369,68294,25868, 2326494,1581857,105750,33847, 3125168,2168377,142053,43490, 248206,10,0 ],
  [ 2000,25,25,1,4, 258812,222461,18823,2644, 1123695,1052965,23533,1481, 3173943,2067770,61037,21273, 5109408,3352019,98258,33796, 7104498,4770953,136625,44876, 3755927,45,0 ],
  [ 2000,25,50,1,4, 258812,223063,18487,2553, 1627148,1549059,14164,-5362, 6061706,3792417,55106,20630, 10035981,6368269,91236,33343, 13622380,9550859,123840,37014, 10480845,73,0 ],
  [ 2000,25,75,1,4, 262000,223064,18714,2781, 1636012,1552521,20450,1044, 6063659,3918519,54140,19153, 10041474,6525483,89656,31393, 14632098,9717689,130644,43879, 10615367,73,0 ],
  [ 2000,50,1,1,4, 27811,80420,27811,-52608, 41236,58149,41236,-16914, 107144,75538,107144,31607, 119833,62784,119833,57049, 159297,73461,159297,85836, -3450919,-40,26 ],
  [ 2000,50,2,1,4, 50393,84676,25196,-17142, 80473,81181,40237,-354, 189830,106101,94915,41865, 239667,182368,119833,28649, 318594,319170,159297,-288, -3266487,-38,25 ],
  [ 2000,50,3,1,4, 72974,102269,24325,-9765, 119711,90177,39904,9844, 272517,199299,90839,24406, 359500,330205,119833,9765, 477891,368157,159297,36578, -3137345,-37,24 ],
  [ 2000,50,5,1,4, 107442,108767,23442,-289, 190105,194959,38021,-971, 428093,350125,85619,15594, 592713,392723,118543,39998, 788072,563832,157614,44848, -2788558,-35,22 ],
  [ 2000,50,10,1,4, 170618,120859,20892,6093, 247005,366045,20584,-9920, 702192,561063,58516,11761, 1019962,867824,84997,12678, 1349455,573493,112455,64664, -2477321,-32,20 ],
  [ 2000,50,15,1,4, 233708,217618,20774,1430, 653292,565191,29695,4005, 1590803,1070747,72309,23639, 2448966,1621587,111317,37608, 3290251,2218324,149557,48724, 116420,8,0 ],
  [ 2000,50,25,1,4, 273189,225070,19868,3500, 1177387,1154681,24657,476, 3345218,2114735,64331,23663, 5376486,3419464,103394,37635, 7479569,4977938,143838,48108, 4351369,48,0 ],
  [ 2000,50,50,1,4, 276520,225699,19751,3630, 1703632,1584339,21474,1504, 6282362,3988402,57112,20854, 10399756,6705394,94543,33585, 15148929,10065673,137718,46211, 11501874,76,0 ],
  [ 2000,50,75,1,4, 276520,225699,19751,3630, 1712894,1587062,21411,1573, 6375191,4083189,56921,20464, 10560808,6767068,94293,33873, 15403809,10261646,137534,45912, 11706751,76,0 ],
  [ 2000,100,1,1,4, 35038,81731,35038,-46693, 46882,58997,46882,-12116, 139787,81968,139787,57819, 131853,65204,131853,66649, 175323,76782,175323,98541, -3328202,-39,25 ],
  [ 2000,100,2,1,4, 59616,86350,29808,-13367, 89767,82872,44884,3447, 230659,114108,115329,58275, 263706,187190,131853,38258, 350647,341470,175323,4588, -3100963,-37,24 ],
  [ 2000,100,3,1,4, 84194,104306,28065,-6704, 132652,92712,44217,13314, 321531,329448,107177,-2639, 395558,337980,131853,19193, 525970,394316,175323,43885, -3196719,-38,24 ],
  [ 2000,100,5,1,4, 121705,111356,26554,2258, 209541,199049,41908,2098, 492398,377159,98480,23048, 652154,419624,130431,46506, 867321,580230,173464,57418, -2514033,-32,20 ],
  [ 2000,100,10,1,4, 198708,139170,24332,7290, 436699,389544,36392,3930, 1039316,722829,86610,26374, 1514027,951818,126169,46851, 2017896,1337135,168158,56730, -970049,-15,-26 ],
  [ 2000,100,15,1,4, 259040,222216,23026,3273, 714565,684553,32480,1364, 1767489,1194689,80340,26036, 2693912,1765327,122451,42208, 3620419,2529309,164564,49596, -851371,-13,-19 ],
  [ 2000,100,25,1,4, 301944,331156,21960,-2125, 1284771,1195029,26906,1879, 3687767,2391865,70919,24921, 5910641,3855383,113666,39524, 8229712,5520688,158264,52097, 4686162,50,0 ],
  [ 2000,100,50,1,4, 305560,331837,21826,-1877, 1856601,1711658,23403,1827, 6896938,4422368,62699,22496, 11422902,7389515,103845,36667, 16666883,11008162,151517,51443, 12588217,79,0 ],
  [ 2000,100,75,1,4, 305560,331837,21826,-1877, 1866656,1714610,23333,1901, 6998254,4456246,62484,22697, 11599474,7519562,103567,36428, 16947229,11209260,151315,51232, 12890331,80,0 ],
  [ 2000,200,1,1,4, 49491,84355,49491,-34863, 58174,60693,58174,-2519, 205073,109345,205073,95728, 155892,70093,155892,85798, 207376,83475,207376,123901, -3116268,-37,24 ],
  [ 2000,200,2,1,4, 78063,103035,39032,-12486, 108355,86105,54177,11125, 312316,221638,156158,45339, 311783,305495,155892,3144, 414752,354929,207376,29911, -3172334,-38,24 ],
  [ 2000,200,3,1,4, 106635,108379,35545,-581, 158536,97781,52845,20252, 419558,363131,139853,18809, 467675,352479,155892,38399, 622127,414241,207376,69296, -2768866,-35,22 ],
  [ 2000,200,5,1,4, 150231,116534,32778,7352, 248412,207230,49682,8236, 621008,416713,124202,40859, 771035,523731,154207,49461, 1025820,741738,205164,56817, -2097605,-29,17 ],
  [ 2000,200,10,1,4, 239672,218305,29348,2616, 513461,421248,42788,7684, 1264818,872252,105402,32714, 1789806,1225371,149150,47036, 2386200,1656686,198850,60793, -771930,-12,0 ],
  [ 2000,200,15,1,4, 309703,332279,27529,-2007, 837111,726963,38050,5007, 2120861,1382938,96403,33542, 3183802,2097488,144718,49378, 4280753,2925567,194580,61599, 1108685,23,0 ],
  [ 2000,200,25,1,4, 359453,354931,26142,329, 1499538,1352425,31404,3081, 4372865,2777590,84094,30678, 6978951,4548330,134211,46743, 9729996,6460488,187115,62875, 6643679,59,0 ],
  [ 2000,200,50,1,4, 363640,355716,25974,566, 2162538,2013530,27259,1878, 8126091,5134650,73874,27195, 13469192,8631470,122447,43979, 19702791,13002832,179116,60909, 15851676,87,0 ],
  [ 2000,200,75,1,4, 363640,355716,25974,566, 2174182,2016941,27177,1966, 8244379,5248678,73611,26747, 13676808,8782479,122114,43699, 20034070,13313529,178876,60005, 15985602,87,0 ],
  [ 2500,0,1,1,4, 26057,80244,26057,-54188, 44129,59363,44129,-15234, 89219,72204,89219,17015, 128448,64780,128448,63667, 171030,76153,171030,94877, -3469306,-40,26 ],
  [ 2500,0,2,1,4, 52113,85275,26057,-16581, 88258,83613,44129,2323, 178438,89772,89219,44333, 256895,186328,128448,35284, 342059,324516,171030,8772, -3201023,-38,24 ],
  [ 2500,0,3,1,4, 78170,103642,26057,-8491, 132387,93827,44129,12853, 267657,199006,89219,22884, 385343,336228,128448,16372, 513089,391929,171030,40387, -3066204,-37,23 ],
  [ 2500,0,5,1,4, 117948,111323,25734,1445, 211957,201003,42391,2191, 436355,352874,87271,16696, 635213,417634,127043,43516, 846170,577228,169234,53789, -2635511,-33,21 ],
  [ 2500,0,10,1,4, 199670,140442,24449,7252, 449398,408723,37450,3390, 981026,699788,81752,23436, 1474553,947531,122879,43919, 1969396,1330627,164116,53231, -1154424,-18,-37 ],
  [ 2500,0,15,1,4, 263753,224521,23445,3487, 742544,708798,33752,1534, 1711542,1189221,77797,23742, 2626277,1758670,119376,39437, 3533476,2418739,160613,50670, 133934,8,0 ],
  [ 2500,0,25,1,4, 309384,347542,22501,-2775, 1351834,1232273,28311,2504, 3663672,2414756,70455,24018, 5790011,3767716,111346,38890, 8025414,5383151,154335,50813, 4539569,49,0 ],
  [ 2500,0,50,1,4, 313237,348287,22374,-2504, 1967614,1881986,24802,1079, 6974002,4492060,63400,22563, 11272954,7424818,102481,34983, 16244924,10876726,147681,48802, 11900255,77,0 ],
  [ 2500,0,75,1,4, 313237,348287,22374,-2504, 1978465,1885207,24731,1166, 7079447,4527220,63209,22788, 11449926,7475270,102231,35488, 16518288,11047860,147485,48843, 12345009,78,0 ],
  [ 2500,25,1,1,4, 327757,81204,327757,246553, 2055346,63748,2055346,1991598, -119782,84430,-119782,-204213, -231567,75001,-231567,-306569, 2005391,84230,2005391,1921161, 4369810,46,0 ],
  [ 2500,25,2,1,4, 56725,86112,28362,-14694, 92905,84458,46452,4223, 198852,108291,99426,45280, 268915,188789,134457,40063, 358085,343551,179043,7267, -3157348,-38,24 ],
  [ 2500,25,3,1,4, 83780,104661,27927,-6960, 138858,95095,46286,14588, 292164,203798,97388,29455, 403372,339841,134457,21177, 537128,396898,179043,46743, -2950866,-36,23 ],
  [ 2500,25,5,1,4, 125080,112617,27290,2719, 221675,203048,44335,3726, 468508,373648,93702,18972, 664934,423621,132987,48263, 885795,585452,177159,60069, -2503946,-32,20 ],
  [ 2500,25,10,1,4, 209911,142301,25703,8279, 468589,413178,39049,4618, 1037401,725189,86450,26018, 1543498,961264,128625,48519, 2061472,1365252,171789,58018, -880129,-14,-20 ],
  [ 2500,25,15,1,4, 276418,327686,24571,-4557, 773180,714804,35145,2653, 1799885,1220700,81813,26327, 2748749,1799400,124943,43152, 3698559,2569187,168116,51335, 100513,8,0 ],
  [ 2500,25,25,1,4, 323761,350151,23546,-1919, 1405526,1260139,29435,3045, 3834947,2461721,73749,26408, 6057088,4038906,116482,38811, 8400485,5687556,161548,52172, 4998631,52,0 ],
  [ 2500,25,50,1,4, 327757,350922,23411,-1655, 2044099,1990263,25766,679, 7281290,4761993,66194,22903, 11784526,7650430,107132,37583, 17003901,11389336,154581,51042, 12530151,79,0 ],
  [ 2500,25,75,1,4, 327757,350922,23411,-1655, 2055346,1993598,25692,772, 7390978,4797941,65991,23152, 11969259,7891146,106868,36412, 17289999,11592318,154375,50872, 12700739,79,0 ],
  [ 2500,50,1,1,4, 33283,81556,33283,-48273, 49775,60211,49775,-10436, 121862,78635,121862,43227, 140467,67200,140467,73267, 187056,79474,187056,107582, -3346589,-39,25 ],
  [ 2500,50,2,1,4, 61337,86949,30668,-12806, 97552,85304,48776,6124, 219266,112295,109633,53486, 280934,191200,140467,44867, 374112,346866,187056,13623, -3069000,-37,24 ],
  [ 2500,50,3,1,4, 89390,105679,29797,-5430, 145329,96362,48443,16322, 316671,329155,105557,-4162, 421401,344003,140467,25799, 561167,402416,187056,52917, -3114406,-37,24 ],
  [ 2500,50,5,1,4, 132211,113912,28846,3993, 231393,205093,46279,5260, 500661,379908,100132,24150, 694654,509866,138931,36958, 925420,609297,185084,63225, -2468579,-32,20 ],
  [ 2500,50,10,1,4, 220152,144160,26957,9305, 487779,417633,40648,5846, 1093777,736075,91148,29809, 1612443,1098384,134370,42838, 2153548,1467937,179462,57134, -803071,-13,-14 ],
  [ 2500,50,15,1,4, 289084,329985,25696,-3636, 803816,722310,36537,3705, 1888228,1237663,85829,29571, 2871222,1947040,130510,42008, 3863643,2618634,175620,56591, 425099,14,0 ],
  [ 2500,50,25,1,4, 338138,352761,24592,-1063, 1459217,1347971,30560,2330, 4006221,2585836,77043,27315, 6324166,4201636,121619,40818, 8775556,6007682,168761,53228, 5239899,53,0 ],
  [ 2500,50,50,1,4, 342277,353558,24448,-806, 2120583,2010805,26730,1384, 7588579,4849026,68987,24905, 12296099,7984002,111783,39201, 17762878,11817715,161481,54047, 13758948,82,0 ],
  [ 2500,50,75,1,4, 342277,353558,24448,-806, 2132228,2028140,26653,1301, 7702510,4962612,68772,24463, 12488592,8241191,111505,37923, 18061709,12106964,161265,53167, 13652947,82,0 ],
  [ 2500,100,1,1,4, 76660,106764,76660,-30104, 1807864,233839,1807864,1574024, 4654790,532284,4654790,4122507, 6247300,760709,6247300,5486591, 9146309,1052856,9146309,8093453, 24831237,103,0 ],
  [ 2500,100,2,1,4, 70560,101960,35280,-15700, 106846,86845,53423,10000, 260095,197302,130047,31396, 304973,196172,152487,54400, 406164,353645,203082,26259, -3123494,-37,24 ],
  [ 2500,100,3,1,4, 100611,107716,33537,-2368, 158270,98897,52757,19791, 365685,338739,121895,8982, 457460,351228,152487,35411, 609246,412354,203082,65631, -2883729,-35,22 ],
  [ 2500,100,5,1,4, 146474,116501,31958,6540, 250829,209183,50166,8329, 564966,406943,112993,31605, 754095,521791,150819,46461, 1004669,738785,200934,53177, -2252583,-30,18 ],
  [ 2500,100,10,1,4, 240634,219577,29465,2578, 526161,500393,43847,2147, 1206528,849362,100544,29764, 1750332,1221084,145861,44104, 2337700,1650228,194808,57289, -1146503,-17,-33 ],
  [ 2500,100,15,1,4, 314416,347920,27948,-2978, 865089,824907,39322,1826, 2064914,1300470,93860,34747, 3116167,2105958,141644,45919, 4193810,2914598,190628,58146, 736471,18,0 ],
  [ 2500,100,25,1,4, 366893,357980,26683,648, 1566601,1494169,32808,1517, 4348770,2798981,83630,29804, 6858321,4540821,131891,44567, 9525698,6435392,183187,59429, 6026002,57,0 ],
  [ 2500,100,50,1,4, 371317,358829,26523,892, 2273551,2079659,28658,2444, 8203155,5297357,74574,26416, 13319244,8666673,121084,42296, 19280832,12871795,175280,58264, 15335030,86,0 ],
  [ 2500,100,75,1,4, 371317,358829,26523,892, 2285990,2083338,28575,2533, 8325572,5321153,74335,26825, 13527259,8818345,120779,42044, 19605129,13053028,175046,58501, 15773977,87,0 ],
  [ 2500,200,1,1,4, 54963,85491,54963,-30528, 66713,76639,66713,-9926, 219791,126807,219791,92984, 176525,74559,176525,101966, 235135,188939,235135,46196, -3159929,-37,24 ],
  [ 2500,200,2,1,4, 89007,105308,44504,-8150, 125433,90228,62717,17603, 341752,333882,170876,3935, 353051,329803,176525,11624, 470269,367454,235135,51408, -3231155,-38,24 ],
  [ 2500,200,3,1,4, 123051,111789,41017,3754, 184154,117851,61385,22101, 463712,372272,154571,30480, 529576,380703,176525,49624, 705404,531680,235135,57908, -2592470,-33,20 ],
  [ 2500,200,5,1,4, 175001,121678,38182,11634, 289701,335599,57940,-9180, 693576,444847,138715,49746, 872976,560566,174595,62482, 1163168,786652,232634,75303, -2029306,-28,16 ],
  [ 2500,200,10,1,4, 281598,327879,34481,-5667, 602924,530597,50244,6027, 1432031,921785,119336,42520, 2026112,1307369,168843,59895, 2706004,1842518,225500,71957, -521172,-6,0 ],
  [ 2500,200,15,1,4, 365079,357116,32451,-4114, 987634,868816,44892,3288, 2418286,1594769,109922,23193, 3606058,2437118,163912,32670, 4854145,3325526,220643,39249, 1386729,26,0 ],
  [ 2500,200,25,1,4, 424402,368419,30866,4072, 1781368,1651715,37306,2715, 5033868,3261706,96805,34080, 7926631,5140134,152435,53586, 11025983,7375042,212038,70210, 7999685,65,0 ],
  [ 2500,200,50,1,4, 429397,369371,30671,4288, 2579488,2381681,32515,2493, 9432308,6009639,85748,31115, 15365535,9988785,139687,48880, 22316740,14837005,202879,67998, 18555554,92,0 ],
  [ 2500,200,75,1,4, 429397,369371,30671,4288, 2593516,2385819,32419,2596, 9571698,6128100,85462,30746, 15604592,10066185,139327,49450, 22691970,15157197,202607,67275, 18912544,93,0 ],
  [ 3000,0,1,1,4, 31529,81381,31529,-49852, 52668,75310,52668,-22642, 103937,75151,103937,28786, 149081,69196,149081,79885, 198788,82115,198788,116673, -3408560,-39,26 ],
  [ 3000,0,2,1,4, 63057,100885,31529,-18914, 105337,87586,52668,8875, 207874,110482,103937,48696, 298163,195360,149081,51401, 397577,352412,198788,22582, -3091730,-37,24 ],
  [ 3000,0,3,1,4, 94586,107052,31529,-4155, 158005,113897,52668,14703, 311811,314198,103937,-796, 447244,349977,149081,32423, 596365,410417,198788,61983, -3042175,-37,23 ],
  [ 3000,0,5,1,4, 142717,116468,31138,5727, 253246,315487,50649,-12448, 508923,381157,101785,25553, 737154,519800,147431,43471, 983518,735282,196704,49647, -2700682,-34,21 ],
  [ 3000,0,10,1,4, 244655,220804,29958,2921, 547694,505449,45641,3520, 1080505,840148,90042,20030, 1704965,1162627,142080,45195, 2281196,1575164,190100,58836, -1323171,-20,-40 ],
  [ 3000,0,15,1,4, 319129,350224,28367,-2764, 893067,835266,40594,2627, 2008968,1280637,91317,33106, 3048532,2099251,138570,43149, 4106867,2790388,186676,59840, 656053,17,0 ],
  [ 3000,0,25,1,4, 374333,361029,27224,968, 1633664,1531562,34213,2138, 4324676,2884357,83167,27698, 6737691,4548238,129571,42105, 9321401,6410745,179258,55974, 5569844,55,0 ],
  [ 3000,0,50,1,4, 378995,361942,27071,1218, 2384565,2323987,30058,764, 8280219,5367050,75275,26483, 13169296,8701826,119721,40613, 18858873,12710899,171444,55891, 14488877,84,0 ],
  [ 3000,0,75,1,4, 378995,361942,27071,1218, 2397799,2327935,29972,873, 8406765,5512692,75060,25840, 13377710,8839783,119444,40517, 19176189,13005119,171216,55099, 14593636,84,0 ],
  [ 3000,25,1,1,4, 35142,82037,35142,-46895, 55491,75734,55491,-20242, 120258,78366,120258,41892, 155091,70406,155091,84685, 206801,99447,206801,107354, -3358374,-39,25 ],
  [ 3000,25,2,1,4, 67669,101722,33835,-17026, 109984,88432,54992,10776, 228288,114486,114144,56901, 310182,306231,155091,1976, 413603,355727,206801,28938, -3163455,-38,24 ],
  [ 3000,25,3,1,4, 100196,108071,33399,-2625, 164476,115165,54825,16437, 336318,333505,112106,938, 465274,353639,155091,37212, 620404,431106,206801,63099, -2971511,-36,23 ],
  [ 3000,25,5,1,4, 149849,117762,32694,7001, 262964,331417,52593,-13691, 541076,387267,108215,30762, 766874,540714,153375,45232, 1023143,743406,204629,55947, -2601304,-33,21 ],
  [ 3000,25,10,1,4, 251837,222709,30837,3567, 558051,524027,46504,2835, 1204614,851722,100384,29408, 1779804,1230530,148317,45773, 2381276,1662675,198440,59883, -1045409,-16,-27 ],
  [ 3000,25,15,1,4, 331794,352523,29493,-1843, 923704,856657,41987,3048, 2097311,1388966,95332,32198, 3171005,2123504,144137,47614, 4271951,2952925,194180,59956, 828603,20,0 ],
  [ 3000,25,25,1,4, 388710,363639,28270,1823, 1687356,1559428,35337,2679, 4495950,2931322,86461,30089, 7004768,4630660,134707,45656, 9696472,6602060,186471,59508, 6388137,58,0 ],
  [ 3000,25,50,1,4, 393515,364578,28108,2067, 2461049,2358414,31022,1294, 8587507,5636982,78068,26823, 13680869,9035898,124372,42227, 19617850,13139728,178344,58892, 15252215,58,0 ],
  [ 3000,25,75,1,4, 393515,364578,28108,2067, 2474680,2362476,30934,1403, 8718297,5677363,77842,27151, 13897044,9189828,124081,42029, 19947899,13435986,178106,58142, 15605578,86,0 ],
  [ 3000,50,1,1,4, 38755,82693,38755,-43937, 58314,76158,58314,-17843, 136580,81582,136580,54998, 161101,71616,161101,89485, 214815,101108,214815,113707, -3297016,-38,25 ],
  [ 3000,50,2,1,4, 72281,102559,36141,-15139, 114630,89277,57315,12677, 248702,195489,124351,26607, 322202,308642,161101,6780, 429629,359041,214815,35294, -3251604,-38,24 ],
  [ 3000,50,3,1,4, 105807,109089,35269,-1094, 170947,116432,56982,18172, 360825,338297,120275,7509, 483303,372228,161101,37025, 644444,436075,214815,69456, -2878276,-35,22 ],
  [ 3000,50,5,1,4, 158563,119041,34596,8623, 275828,332867,55166,-11408, 550713,407897,110143,28563, 763013,532025,152603,46198, 1017387,743029,203477,54872, -2559092,-33,20 ],
  [ 3000,50,10,1,4, 262078,224567,32091,4593, 577242,528482,48103,4063, 1260989,877123,105082,31989, 1848749,1259240,154062,49126, 2473352,1697300,206113,64671, -793217,-12,0 ],
  [ 3000,50,15,1,4, 344460,354822,30619,-921, 954340,864163,43379,4099, 2185654,1526494,99348,29962, 3293477,2163234,149704,51375, 4437035,3002922,201683,65187, 1034385,22,0 ],
  [ 3000,50,25,1,4, 403088,366248,29315,2679, 1741048,1573410,36462,3511, 4667225,3099002,89754,30158, 7271846,4887423,139843,45854, 10071543,6838955,193684,62165, 6682569,59,0 ],
  [ 3000,50,50,1,4, 408035,367213,29145,2916, 2537533,2378956,31986,1999, 8894796,5801015,80862,28125, 14192442,9341318,129022,44101, 20376827,13764979,185244,60108, 16205697,87,0 ],
  [ 3000,50,75,1,4, 408035,367213,29145,2916, 2551562,2397017,31895,1932, 9029828,5948084,80623,27516, 14416377,9525397,128718,43669, 20719609,13966804,184997,60293, 16323338,88,0 ],
  [ 3000,100,1,1,4, 50055,378321,50055,-328266, 461985,298860,461985,163125, 1164415,608775,1164415,555640, 1792434,862366,1792434,930069, 2591418,1213134,2591418,1378284, -562424,-5,0 ],
  [ 3000,100,2,1,4, 81505,104233,40752,-11364, 123924,90969,61962,16478, 289531,218012,144765,35760, 346241,328491,173120,8875, 461682,365720,230841,47981, -3130511,-37,24 ],
  [ 3000,100,3,1,4, 117027,111125,39009,1967, 183888,118967,61296,21641, 409838,362396,136613,15814, 519361,379502,173120,46620, 692522,446062,230841,82153, -2681099,-34,21 ],
  [ 3000,100,5,1,4, 171244,121645,37362,10821, 292117,337552,58423,-9087, 637534,420561,127507,43394, 856035,558576,171207,59492, 1142017,783649,228403,71674, -2150784,-29,17 ],
  [ 3000,100,10,1,4, 282560,329152,34599,-5705, 615623,535892,51302,6644, 1373741,913410,114478,38361, 1986638,1303132,165553,56959, 2657504,1836111,221459,68449, -695463,-10,0 ],
  [ 3000,100,15,1,4, 369792,359420,32870,922, 1015613,893060,46164,5571, 2362340,1574786,107379,35798, 3538423,2430411,160837,50364, 4767202,3185596,216691,71891, 1588289,28,0 ],
  [ 3000,100,25,1,4, 431842,384804,31407,3421, 1848432,1688958,38711,3340, 5009774,3270082,96342,33456, 7806001,5132725,150115,51409, 10821685,7350545,208109,66753, 7687436,63,0 ],
  [ 3000,100,50,1,4, 437074,385821,31220,3661, 2690502,2521510,33914,2130, 9509372,6157831,86449,30469, 15215587,10024088,138324,47195, 21894781,14706068,199043,65352, 17783935,91,0 ],
  [ 3000,100,75,1,4, 437074,385821,31220,3661, 2705324,2525915,33817,2243, 9652891,6306625,86187,29877, 15455044,10102700,137991,47789, 22263030,14913017,198777,65625, 18155894,92,0 ],
  [ 3000,200,1,1,4, 60436,86628,60436,-26192, 75252,78701,75252,-3449, 234509,129905,234509,104605, 197159,78975,197159,118184, 262893,194951,262893,67942, -3055599,-37,23 ],
  [ 3000,200,2,1,4, 99952,107582,49976,-3815, 142512,94351,71256,24081, 371188,354592,185594,8298, 394318,338685,197159,27816, 525787,395200,262893,65293, -3067168,-37,23 ],
  [ 3000,200,3,1,4, 139468,115199,46489,8090, 209772,197886,69924,3962, 507866,396079,169289,37262, 591478,394001,197159,65825, 788680,565439,262893,74414, -2557934,-33,20 ],
  [ 3000,200,5,1,4, 199770,140160,43586,13006, 330989,345583,66198,-2919, 766144,551631,153229,42903, 974917,706012,194983,53781, 1300516,916046,260103,76894, -2041802,-28,16 ],
  [ 3000,200,10,1,4, 323524,349924,39615,-3233, 692386,567446,57699,10412, 1599243,1091883,133270,42280, 2262418,1562359,188535,58338, 3025808,2072532,252151,79440, -245095,0,0 ],
  [ 3000,200,15,1,4, 420455,368616,37374,4608, 1138158,936970,51734,9145, 2715712,1763035,123441,43303, 4028313,2652612,183105,62532, 5427537,3679804,246706,79442, 2898653,39,0 ],
  [ 3000,200,25,1,4, 489352,395243,35589,6844, 2063199,1877004,43208,3899, 5694872,3654307,109517,39242, 8874311,5825522,170660,58631, 12321969,8289695,236961,77544, 9606805,70,0 ],
  [ 3000,200,50,1,4, 495154,396363,35368,7057, 2996439,2823532,37770,2179, 10738525,6884628,97623,35035, 17261878,11265943,156926,54508, 24930689,16671328,226643,75085, 21089483,97,0 ],
  [ 3000,200,75,1,4, 495154,396363,35368,7057, 3012850,2828396,37661,2306, 10899017,7007522,97313,34745, 17532377,11538808,156539,53514, 25349871,16917235,226338,75291, 21332148,97,0 ],
  [ 1000,0,1,2,4, 15063,77746,15063,-62683, 27143,55018,27143,-27876, 61339,51442,61339,9897, 86918,55474,86918,31444, 111063,62809,111063,48254, -3641091,-41,27 ],
  [ 1000,0,2,2,4, 30126,80279,15063,-25077, 54285,61038,27143,-3376, 122677,77429,61339,22624, 173835,72532,86918,50652, 222125,101107,111063,60509, -3287015,-38,25 ],
  [ 1000,0,3,2,4, 45189,82811,15063,-12541, 81428,80943,27143,162, 184016,88751,61339,31755, 260753,184824,86918,25310, 333188,320504,111063,4228, -3212844,-38,24 ],
  [ 1000,0,5,2,4, 68187,100016,14877,-6945, 129264,91785,25853,7496, 298081,201479,59616,19320, 430839,341529,86168,17862, 550054,395918,110011,30827, -2948689,-36,23 ],
  [ 1000,0,10,2,4, 115456,107976,14137,916, 266296,331250,22191,-5413, 656831,415383,54736,20121, 1005662,701613,83805,25337, 1283949,902421,106996,31794, -2302571,-30,19 ],
  [ 1000,0,15,2,4, 152539,114227,13559,3405, 429158,386980,19507,1917, 1127255,727815,51239,18156, 1791527,1196678,81433,27039, 2311196,1498607,105054,36936, -2302571,-30,19 ],
  [ 1000,0,25,2,4, 178962,118690,13015,4383, 750467,708185,15717,885, 2333107,1395849,44867,18024, 3908894,2454831,75171,27963, 5302120,3471582,101964,35203, 2574569,37,0 ],
  [ 1000,0,50,2,4, 181196,119068,12943,4438, 1065916,925951,13436,1764, 4283671,2491839,38942,16289, 7459474,4584881,67813,26133, 10831146,6944460,98465,35334, 8407468,66,0 ],
  [ 1000,0,75,2,4, 181196,119068,12943,4438, 1071466,927513,13393,1799, 4343994,2579349,38786,15756, 7571442,4620625,67602,26347, 11015364,6995895,98351,35888, 8564949,67,0 ],
  [ 1000,25,1,2,4, 19892,78623,19892,-58731, 31065,55629,31065,-24564, 81176,69862,81176,11314, 94767,57105,94767,37662, 121204,64961,121204,56243, -3596420,-41,26 ],
  [ 1000,25,2,2,4, 36514,81438,18257,-22462, 60880,76141,30440,-7630, 148085,82257,74043,32914, 189534,75682,94767,56926, 242409,189033,121204,26688, -3500047,-40,26 ],
  [ 1000,25,3,2,4, 53137,84254,17712,-10372, 90696,82768,30232,2643, 214994,109317,71665,35226, 284301,189594,94767,31569, 363613,326845,121204,12256, -3094008,-37,24 ],
  [ 1000,25,5,2,4, 78513,101891,17130,-5101, 143302,94737,28660,9713, 339511,315587,67902,4785, 469713,349798,93943,23983, 600260,406778,120052,38696, -2973552,-36,23 ],
  [ 1000,25,10,2,4, 130640,110732,15997,2438, 294307,337726,24526,-3618, 731604,506808,60967,18733, 1096226,719591,91352,31386, 1401003,942129,116750,38240, -2059406,-28,17 ],
  [ 1000,25,15,2,4, 171512,117671,15245,4786, 474129,411840,21551,2831, 1246292,842020,56650,18376, 1952930,1243606,88770,32242, 2521611,1670423,114619,38690, -405403,-5,0 ],
  [ 1000,25,25,2,4, 200610,135955,14590,4702, 829846,742545,17379,1828, 2568296,1575516,49390,19092, 4263213,2650360,81985,31016, 5782767,3813803,111207,37865, 3193663,41,0 ],
  [ 1000,25,50,2,4, 203066,136374,14505,4764, 1179465,1148346,14867,392, 4712002,2797584,42836,17404, 8143126,4982652,74028,28732, 11809441,7532319,107359,38883, 9061001,41,0 ],
  [ 1000,25,75,2,4, 203066,136374,14505,4764, 1185611,1150079,14820,444, 4778404,2886221,42664,16894, 8265612,5100731,73800,28258, 12010237,7700148,107234,38483, 9054012,68,0 ],
  [ 1000,50,1,2,4, 24720,79499,24720,-54779, 34987,56239,34987,-21252, 101014,73767,101014,27247, 102617,58685,102617,43931, 131346,67063,131346,64283, -3518250,-40,26 ],
  [ 1000,50,2,2,4, 42903,82598,21452,-19847, 67476,77358,33738,-4941, 173493,87235,86747,43129, 205233,78833,102617,63200, 262692,193230,131346,34731, -3159826,-37,24 ],
  [ 1000,50,3,2,4, 61086,85697,20362,-8204, 99965,84593,33322,5124, 245973,192368,81991,17868, 307850,194314,102617,37845, 394038,348807,131346,15077, -3129343,-37,24 ],
  [ 1000,50,5,2,4, 88839,103765,19383,-3257, 157341,97688,31468,11930, 380942,338159,76188,8557, 508588,357617,101718,30194, 650467,417188,130093,46656, -2786537,-35,22 ],
  [ 1000,50,10,2,4, 145825,113488,17856,3960, 322319,344202,26860,-1824, 806376,535748,67198,22552, 1186789,752595,98899,36183, 1518058,966216,126505,45987, -1684173,-24,13 ],
  [ 1000,50,15,2,4, 190485,121115,16932,6166, 519100,422815,23595,4377, 1365328,879375,62060,22089, 2114333,1290534,96106,37445, 2732026,1828650,124183,41063, 159069,8,0 ],
  [ 1000,50,25,2,4, 222257,139885,16164,5991, 909225,850905,19041,1221, 2803485,1726132,53913,20718, 4617532,2858414,88799,33829, 6263413,4042283,120450,42714, 3763734,45,0 ],
  [ 1000,50,50,2,4, 224937,140344,16067,6042, 1293015,1192690,16299,1265, 5140332,3104828,46730,18505, 8826778,5445604,80243,30738, 12787736,8217948,116252,41544, 10163749,72,0 ],
  [ 1000,50,75,2,4, 224937,140344,16067,6042, 1299756,1194595,16247,1315, 5212814,3117593,46543,18707, 8959782,5485403,79998,31021, 13005110,8275989,116117,42224, 10567101,73,0 ],
  [ 1000,100,1,2,4, 34378,81252,34378,-46874, 42831,57459,42831,-14628, 140689,96092,140689,44597, 118315,61897,118315,56419, 151629,71318,151629,80311, -3395408,-39,25 ],
  [ 1000,100,2,2,4, 55681,84917,27840,-14618, 80666,79793,40333,436, 224310,111706,112155,56302, 236631,100160,118315,68235, 303258,201673,151629,50793, -2984924,-36,23 ],
  [ 1000,100,3,2,4, 76984,101919,25661,-8312, 118501,88092,39500,10136, 307931,218986,102644,29648, 354946,312414,118315,14177, 454888,361588,151629,31100, -3072939,-37,24 ],
  [ 1000,100,5,2,4, 109491,107513,23889,431, 185417,117477,37083,13588, 463803,368640,92761,19033, 586336,388182,117267,39631, 750879,537359,150176,42704, -2515603,-32,20 ],
  [ 1000,100,10,2,4, 176194,119000,21575,7003, 378342,357153,31528,1766, 955922,579113,79660,31401, 1367916,883885,113993,40336, 1752167,1242553,146014,42468, -1159342,-18,-40 ],
  [ 1000,100,15,2,4, 230046,141308,20449,7888, 616177,532159,28008,3819, 1539456,953143,69975,26651, 2328216,1533468,105828,36125, 3152855,2074710,143312,49007, 506358,14,0 ],
  [ 1000,100,25,2,4, 265552,219443,19313,3353, 1067983,906041,22366,3391, 3273864,2042050,62959,23689, 5326170,3342104,102426,38155, 7224706,4741595,138937,47752, 4814143,51,0 ],
  [ 1000,100,50,2,4, 268677,219983,19191,3478, 1520113,1355229,19161,2078, 5996993,3598751,54518,21802, 10194081,6305626,92673,35350, 14744326,9491237,134039,47755, 12286239,78,0 ],
  [ 1000,100,75,2,4, 268677,219983,19191,3478, 1528046,1357478,19101,2132, 6081633,3628288,54300,21905, 10348122,6349531,92394,35702, 14994857,9655385,133883,47674, 12654555,79,0 ],
  [ 1000,200,1,2,4, 53692,84758,53692,-31065, 58519,59899,58519,-1380, 220039,126227,220039,93812, 149713,68269,149713,81444, 192196,79777,192196,112419, -3116225,-37,24 ],
  [ 1000,200,2,2,4, 81236,102892,40618,-10828, 107047,84513,53523,11267, 325943,223134,162971,51405, 299426,193269,149713,53079, 384391,347420,192196,18486, -2998931,-36,23 ],
  [ 1000,200,3,2,4, 108779,107690,36260,363, 155575,95392,51858,20061, 431846,363755,143949,22697, 449139,346821,149713,34106, 576587,402971,192196,57872, -2775751,-35,22 ],
  [ 1000,200,5,2,4, 152094,114999,33184,8093, 244230,203050,48846,8236, 635640,415303,127128,44067, 711501,514509,142300,39398, 911981,594079,182396,63580, -2097764,-29,17 ],
  [ 1000,200,10,2,4, 236931,143361,29012,11458, 490388,396792,40866,7800, 1255012,863258,104584,32646, 1730169,1190293,144181,44990, 2220385,1470245,185032,62512, -494814,-7,0 ],
  [ 1000,200,15,2,4, 304323,327680,27051,-2076, 788927,692984,35860,4361, 2079545,1270237,94525,36787, 3082750,1966713,140125,50729, 3994514,2607887,181569,63028, 1290672,25,0 ],
  [ 1000,200,25,2,4, 352142,336026,25610,1172, 1385498,1206896,29016,2009, 4214622,2580700,81050,15564, 6743446,4215250,129682,21462, 9147292,6011959,175909,26666, 2680449,38,0 ],
  [ 1000,200,50,2,4, 356158,350065,25440,435, 1974310,1723042,24886,3167, 7710314,4720028,70094,27184, 12928688,8027921,117534,44552, 18657506,12039514,169614,60164, 16202017,87,0 ],
  [ 1000,200,75,2,4, 356158,350065,25440,435, 1984625,1725978,24808,3233, 7819271,4754078,69815,27368, 13124803,8188398,117186,44075, 18974349,12216873,169414,60335, 16549005,88,0 ],
  [ 1500,0,1,2,4, 23613,79522,23613,-55909, 39658,57988,39658,-18330, 81371,70174,81371,11198, 113868,61297,113868,52571, 146195,70473,146195,75722, -3529514,-40,26 ],
  [ 1500,0,2,2,4, 47226,83831,23613,-18302, 79316,80862,39658,-773, 162743,85712,81371,38515, 227736,99055,113868,64341, 292390,200065,146195,46162, -3122282,-37,24 ],
  [ 1500,0,3,2,4, 70839,101476,23613,-10212, 118974,89701,39658,9758, 244114,192916,81371,17066, 341604,310803,113868,10267, 438585,359218,146195,26456, -3213550,-38,24 ],
  [ 1500,0,5,2,4, 106889,108055,23321,-254, 189845,194157,37969,-862, 397052,342722,79410,10866, 564187,385607,112837,35716, 724088,533578,144818,38102, -2896002,-35,23 ],
  [ 1500,0,10,2,4, 106889,108055,13088,-143, 189845,194157,15820,-359, 397052,554796,33088,-13145, 1339408,878099,111617,38442, 1720463,1234696,143372,40481, -2755134,-34,22 ],
  [ 1500,0,15,2,4, 239064,217233,21250,1941, 651516,561525,29614,4090, 1537212,934117,69873,27413, 2348218,1579540,106737,34940, 3042141,2045233,138279,45314, 197489,-34,0 ],
  [ 1500,0,25,2,4, 280445,224800,20396,4047, 1168968,1146969,24481,461, 3253069,2069183,62559,22767, 5167133,3332398,99368,35283, 6963091,4610173,133906,45248, 3986801,46,0 ],
  [ 1500,0,50,2,4, 283942,225441,20282,4179, 1687012,1558462,21265,1620, 6121795,3794917,55653,21153, 9997289,6343480,90884,33216, 14201231,9233242,129102,45164, 11497543,76,0 ],
  [ 1500,0,75,2,4, 283942,225441,20282,4179, 1696137,1574998,21202,1514, 6212414,3917691,55468,20489, 10151928,6403790,90642,33466, 14442702,9510038,128953,44042, 11513205,76,0 ],
  [ 1500,25,1,2,4, 28442,80399,28442,-51957, 43580,58598,43580,-15018, 101209,74079,101209,27130, 121717,62878,121717,58839, 156337,72575,156337,83761, -3451343,78,26 ],
  [ 1500,25,2,2,4, 53615,84990,26807,-15688, 85911,82079,42956,1916, 188151,90690,94075,48730, 243435,182513,121717,30461, 312673,204262,156337,54206, -3125553,-37,24 ],
  [ 1500,25,3,2,4, 78788,102919,26263,-8044, 128243,91526,42748,12239, 275093,198967,91698,25375, 365152,330500,121717,11551, 469010,365509,156337,34500, -3083318,-37,24 ],
  [ 1500,25,5,2,4, 117215,109929,25574,1590, 203884,197109,40777,1355, 438483,350780,87697,17541, 603061,393376,120612,41937, 774295,543938,154859,46071, -2675487,-34,21 ],
  [ 1500,25,10,2,4, 196150,137674,24018,7160, 426047,385127,35504,3410, 961027,689998,80086,22586, 1406715,911965,117226,41229, 1807626,1274746,150635,44407, -1303176,-20,-47 ],
  [ 1500,25,15,2,4, 258037,220677,22937,3321, 696487,572500,31659,5636, 1656249,1154521,75284,22806, 2509621,1626967,114074,40121, 3252555,2203960,147843,47663, 340085,12,0 ],
  [ 1500,25,25,2,4, 302093,329596,21970,-2000, 1248347,1179979,26143,1432, 3488259,2128284,67082,26153, 5521452,3528726,106182,38322, 7443737,4855775,143149,49769, 4595250,50,0 ],
  [ 1500,25,50,2,4, 305812,330277,21844,-1747, 1800562,1689041,22696,1406, 6550125,4102011,59547,22256, 10680940,6742601,97099,35803, 15179526,9920321,137996,47811, 12005674,77,0 ],
  [ 1500,25,75,2,4, 305812,330277,21844,-1747, 1810282,1691864,22629,1480, 6646824,4120013,59347,22561, 10846098,6978230,96840,34535, 15437575,10086879,137835,47774, 12169121,78,0 ],
  [ 1500,50,1,2,4, 33270,81275,33270,-48005, 47502,59208,47502,-11706, 121046,77984,121046,43063, 129567,64459,129567,65108, 166478,74677,166478,91801, -3373172,-39,25 ],
  [ 1500,50,2,2,4, 60004,86150,30002,-13073, 92507,83297,46253,4605, 213559,110183,106780,51688, 259134,185713,129567,36710, 332956,321599,166478,5679, -3124432,-37,24 ],
  [ 1500,50,3,2,4, 86737,104361,28912,-5875, 137511,93351,45837,14720, 306072,205018,102024,33685, 388700,335220,129567,17827, 499434,387470,166478,37321, -2942156,-36,23 ],
  [ 1500,50,5,2,4, 127541,111804,27827,3434, 217922,200061,43584,3572, 479914,373353,95983,21312, 641935,416172,128387,45153, 824501,570019,164900,50896, -2521749,-32,20 ],
  [ 1500,50,10,2,4, 211334,140430,25878,8682, 454059,391602,37838,5205, 1035800,718938,86317,26405, 1497278,944969,124773,46026, 1924680,1298833,160390,52154, -927943,-15,-26 ],
  [ 1500,50,15,2,4, 277010,224120,24623,4701, 741458,687825,33703,2438, 1775285,1190376,80695,26587, 2671024,1754203,121410,41674, 3462970,2262235,157408,54579, 530319,15,0 ],
  [ 1500,50,25,2,4, 323740,346862,23545,-1682, 1327726,1200454,27806,2665, 3723448,2385100,71605,25737, 5875771,3737431,112996,41122, 7924384,5198045,152392,52430, 5061983,52,0 ],
  [ 1500,50,50,2,4, 327682,347583,23406,-1421, 1914111,1733236,24127,2280, 6978456,4409255,63441,23356, 11364592,7205703,103314,37808, 16157821,10508680,146889,51356, 13123189,80,0 ],
  [ 1500,50,75,2,4, 327682,347583,23406,-1421, 1924427,1736231,24055,2352, 7081234,4428386,63225,23686, 11540268,7378028,103038,37163, 16432449,10791632,146718,50364, 13337110,81,0 ],
  [ 1500,100,1,2,4, 42928,83028,42928,-40100, 55346,60428,55346,-5082, 160722,100309,160722,60413, 145266,67670,145266,77596, 186761,78932,186761,107829, -3250331,-38,25 ],
  [ 1500,100,2,2,4, 72781,101806,36391,-14512, 105697,85582,52848,10058, 264375,197139,132188,33618, 290531,192164,145266,49184, 373523,345813,186761,13855, -3136290,-37,24 ],
  [ 1500,100,3,2,4, 102634,107247,34211,-1538, 156048,97000,52016,19682, 368029,337685,122676,10115, 435797,345210,145266,30196, 560284,400602,186761,53227, -2916362,-36,23 ],
  [ 1500,100,5,2,4, 148193,115552,32333,7122, 245998,205964,49200,8007, 562775,403984,112555,31758, 719683,512067,143937,41523, 924913,590788,184983,66825, -2232744,-30,18 ],
  [ 1500,100,10,2,4, 241703,217642,29596,2946, 510082,418439,42507,7637, 1185345,839153,98779,28849, 1678405,1184719,139867,41140, 2158789,1462030,179899,58063, -996620,-15,-27 ],
  [ 1500,100,15,2,4, 314956,331875,27996,-1504, 831400,722160,37791,4965, 2013357,1264935,91516,34019, 2993830,1958019,136083,47082, 3883799,2593778,176536,58637, 1032208,22,0 ],
  [ 1500,100,25,2,4, 367035,354720,26693,896, 1486483,1343324,31131,2998, 4193827,2594818,80651,30750, 6584409,4220621,126623,45457, 8885677,5881137,170878,57780, 6385563,58,0 ],
  [ 1500,100,50,2,4, 371423,355522,26530,1136, 2141209,2000124,26990,1778, 7835116,4824679,71228,27368, 12731896,8065725,115745,42420, 18114412,11781469,164676,57572, 15389539,86,0 ],
  [ 1500,100,75,2,4, 371423,355522,26530,1136, 2152717,2003463,26909,1866, 7950053,4937581,70983,26897, 12928608,8242157,115434,41843, 18422195,12071076,164484,56706, 15462835,86,0 ],
  [ 1500,200,1,2,4, 62243,86534,62243,-24291, 71034,76754,71034,-5719, 240072,130293,240072,109779, 176663,74042,176663,102621, 227328,103012,227328,124316, -3025905,-36,23 ],
  [ 1500,200,2,2,4, 98336,106444,49168,-4054, 132078,90452,66039,20813, 366008,352132,183004,6938, 353327,313825,176663,19751, 454656,363199,227328,45728, -3147729,-37,24 ],
  [ 1500,200,3,2,4, 134429,113018,44810,7137, 193121,118185,64374,24979, 491945,390770,163982,33725, 529990,379167,176663,50274, 681983,441485,227328,80166, -2450880,-32,19 ],
  [ 1500,200,5,2,4, 189497,123049,41345,14498, 302151,336006,60430,-6771, 728498,449080,145700,55884, 875180,558170,175036,63402, 1125739,776160,225148,69916, -1879472,-26,15 ],
  [ 1500,200,10,2,4, 302441,329533,37034,-3317, 622129,530577,51844,7629, 1484436,940248,123703,45349, 2040658,1288233,170055,62702, 2627007,1803662,218917,68612, -282907,-1,0 ],
  [ 1500,200,15,2,4, 390848,358986,34742,2832, 1011285,867530,45967,6534, 2489502,1597104,113159,40564, 3639441,2415455,165429,55636, 4725458,3141424,214794,72002, 2156640,33,0 ],
  [ 1500,200,25,2,4, 453625,370437,32991,6050, 1803999,1645680,37780,3316, 5134585,3239519,98742,36444, 8001685,5092767,153879,55941, 10808263,7037410,207851,72516, 8749929,67,0 ],
  [ 1500,200,50,2,4, 458904,371401,32779,6250, 2595406,2355702,32715,3021, 9548438,5946105,86804,32748, 15466503,9814523,140605,51382, 22027592,14411927,200251,69233, 19715983,94,0 ],
  [ 1500,200,75,2,4, 458904,371401,32779,6250, 2609297,2359728,32616,3120, 9687691,5986520,86497,33046, 15705288,9970913,140226,51200, 22401688,14630964,200015,69381, 20205807,95,0 ],
  [ 2000,0,1,2,4, 32163,81298,32163,-49135, 52174,60957,52174,-8784, 101404,74390,101404,27014, 140818,67070,140818,73748, 181327,78087,181327,103240, -3384437,-39,25 ],
  [ 2000,0,2,2,4, 64326,100719,32163,-18196, 104347,86651,52174,8848, 202808,108660,101404,47074, 281636,191109,140818,45264, 362654,344256,181327,9199, -3130650,-37,24 ],
  [ 2000,0,3,2,4, 96490,106804,32163,-3438, 156521,98609,52174,19304, 304212,205566,101404,32882, 422455,343099,140818,26452, 543981,397732,181327,48750, -2811596,-35,22 ],
  [ 2000,0,5,2,4, 145592,116094,31765,6436, 250426,208795,50085,8326, 496024,378066,99205,23591, 697534,509493,139507,37608, 898123,587007,179625,62223, -2379362,-31,19 ],
  [ 2000,0,10,2,4, 246475,220224,30181,3214, 529776,426201,44148,8631, 1115677,738197,92973,31457, 1626641,1098837,135553,43984, 2097194,1370085,174766,60592, -806101,-12,0 ],
  [ 2000,0,15,2,4, 325589,349405,28941,-2117, 873874,825036,39722,2220, 1947169,1245118,88508,31911, 2904909,1949425,132041,43431, 3773085,2579770,171504,54242, 518770,15,0 ],
  [ 2000,0,25,2,4, 381929,360078,27777,1589, 1587469,1510403,33245,1614, 4173032,2728001,80251,27789, 6425373,4211415,123565,42576, 8624062,5736576,165847,55529, 5556348,54,0 ],
  [ 2000,0,50,2,4, 386688,360980,27621,1836, 2308109,2099007,29094,2636, 7959918,5128395,72363,25741, 12535103,8104080,113955,40282, 17571317,11721344,159739,53182, 14541721,84,0 ],
  [ 2000,0,75,2,4, 386688,360980,27621,1836, 2320809,2102748,29010,2726, 8080835,5166148,72150,26024, 12732414,8281489,113682,39740, 17870041,11813238,159554,54079, 14937423,85,0 ],
  [ 2000,25,1,2,4, 36992,82175,36992,-45183, 56096,75452,56096,-19357, 121242,78145,121242,43097, 148668,68651,148668,80017, 191469,80139,191469,111329, -3349849,-39,25 ],
  [ 2000,25,2,2,4, 70715,101879,35358,-15582, 110942,87868,55471,11537, 228216,113638,114108,57289, 297335,194259,148668,51538, 382937,348452,191469,17243, -3015397,-36,23 ],
  [ 2000,25,3,2,4, 104438,108247,34813,-1269, 165789,114319,55263,17157, 335191,332032,111730,1053, 446003,348369,148668,32544, 574406,404523,191469,56628, -2981721,-36,23 ],
  [ 2000,25,5,2,4, 155917,117968,34018,8280, 264465,316096,52893,-10326, 537454,384624,107491,30566, 736409,517262,147282,43829, 948329,612538,189666,67158, -2496642,-32,20 ],
  [ 2000,25,10,2,4, 261660,222980,32040,4736, 557788,506527,46482,4272, 1190450,844137,99204,28859, 1717204,1197173,143100,43336, 2214248,1493623,184521,60052, -1008476,-16,-27 ],
  [ 2000,25,15,2,4, 344562,352849,30628,-737, 918845,836011,41766,3765, 2066205,1282473,93918,35624, 3066312,2076660,139378,44984, 3983500,2638545,181068,61134, 1035625,22,0 ],
  [ 2000,25,25,2,4, 403576,364007,29351,2878, 1666848,1531028,34908,2844, 4408222,2801767,84773,30893, 6779692,4434445,130379,45101, 9104708,6078196,175091,58202, 6477547,59,0 ],
  [ 2000,25,50,2,4, 408558,364950,29183,3115, 2421658,2321552,30525,1262, 8388249,5328089,76257,27820, 13218755,8501200,120171,42887, 18549612,12292883,168633,56879, 15506497,86,0 ],
  [ 2000,25,75,2,4, 408558,364950,29183,3115, 2434954,2325465,30437,1369, 8515244,5366971,76029,28110, 13426584,8665661,119880,42508, 18864914,12501170,168437,56819, 15878358,87,0 ],
  [ 2000,50,1,2,4, 41821,83051,41821,-41230, 60018,76062,60018,-16045, 141079,82050,141079,59029, 156517,70232,156517,86285, 201610,82242,201610,119369, -3271679,-38,25 ],
  [ 2000,50,2,2,4, 76749,101010,38374,-12131, 80232,88100,40116,-3934, 203849,82130,101925,60859, 242656,70232,121328,86212, 308605,82242,154303,113182, -2829535,-35,22 ],
  [ 2000,50,3,2,4, 112387,109689,37462,899, 175057,116144,58352,19638, 366170,338083,122057,9362, 469551,353090,156517,38820, 604831,410813,201610,64673, -2829386,-35,22 ],
  [ 2000,50,5,2,4, 166243,119842,36271,10124, 278503,332933,55701,-10886, 578885,407047,115777,34368, 775283,540057,155057,47045, 998535,735989,199707,52509, -2455946,-32,20 ],
  [ 2000,50,10,2,4, 276844,225736,33899,6258, 585799,526888,48817,4909, 1265223,872927,105435,32691, 1807768,1230177,150647,48133, 2331303,1646422,194275,57073, -768632,-12,0 ],
  [ 2000,50,15,2,4, 363535,356293,32314,644, 963816,860871,43810,4679, 2185242,1396677,99329,35844, 3227715,2123588,146714,50188, 4193914,2796722,190632,63509, 1380016,26,0 ],
  [ 2000,50,25,2,4, 425224,367936,30925,4166, 1746226,1565538,36570,3784, 4643411,2952384,89296,32520, 7134011,4614347,137193,48455, 9585355,6435438,184334,60575, 7231144,62,0 ],
  [ 2000,50,50,2,4, 430428,382256,30745,3441, 2535207,2365746,31956,2136, 8816579,5620818,80151,29052, 13902407,8992405,126386,44636, 19527907,12994283,177526,59397, 16535330,88,0 ],
  [ 2000,50,75,2,4, 430428,382256,30745,3441, 2549099,2369831,31864,2241, 8949654,5660828,79908,29365, 14120754,9145717,126078,44420, 19859787,13092782,177320,60420, 17041955,89,0 ],
  [ 2000,100,1,2,4, 51478,84804,51478,-33326, 67862,77283,67862,-9421, 180754,104375,180754,76379, 172216,73443,172216,98773, 221894,102167,221894,119726, -3160011,-37,24 ],
  [ 2000,100,2,2,4, 89881,105358,44941,-7738, 130728,91520,65364,19604, 304441,220088,152221,42177, 344432,312220,172216,16106, 443787,361091,221894,41348, -3039710,-37,23 ],
  [ 2000,100,3,2,4, 128285,112575,42762,5237, 193594,119793,64531,24600, 428128,364700,142709,21142, 516648,377556,172216,46364, 665681,439115,221894,75522, -2591491,-33,20 ],
  [ 2000,100,5,2,4, 186895,123591,40777,13812, 306579,338836,61316,-6451, 661747,423162,132349,47717, 853031,555596,170606,59487, 1098948,772379,219790,65314, -2026090,-28,16 ],
  [ 2000,100,10,2,4, 307213,332115,37618,-3049, 641823,538340,53485,8624, 1414768,916292,117897,41540, 1988894,1282659,165741,58853, 2565412,1711717,213784,71141, -387411,-4,0 ],
  [ 2000,100,15,2,4, 401481,363180,35687,3404, 1053758,896705,47898,7139, 2423314,1577286,110151,38456, 3550521,2406711,161387,51991, 4614744,3127265,209761,67613, 1931676,31,0 ],
  [ 2000,100,25,2,4, 468518,389131,34074,5774, 1904984,1708258,39895,4120, 5113790,3268152,98342,35493, 7842649,5098187,150820,52778, 10546648,7006089,202820,68088, 8335285,66,0 ],
  [ 2000,100,50,2,4, 474169,390195,33869,5998, 2762306,2528285,34819,2950, 9673240,6129256,87939,32218, 15269710,9852477,138816,49248, 21484497,14267622,195314,65608, 18918303,93,0 ],
  [ 2000,100,75,2,4, 474169,390195,33869,5998, 2777389,2532713,34717,3058, 9818473,6171523,87665,32562, 15509094,10009846,138474,49100, 21849533,14456507,195085,66009, 19434567,94,0 ],
  [ 2000,200,1,2,4, 70793,101646,70793,-30854, 83550,79573,83550,3977, 260105,134510,260105,125595, 203614,79965,203614,123648, 262460,194557,262460,67903, -2995255,-36,23 ],
  [ 2000,200,2,2,4, 115436,109996,57718,2720, 157109,96390,78554,30359, 406074,360565,203037,22754, 407227,340348,203614,33439, 524920,394098,262460,65411, -2890851,-36,22 ],
  [ 2000,200,3,2,4, 160080,118346,53360,13911, 230668,200943,76889,9908, 552043,403420,184014,49541, 610841,396487,203614,71451, 787380,563779,262460,74534, -2320337,-31,18 ],
  [ 2000,200,5,2,4, 228199,216124,49789,2635, 362732,350493,72546,2448, 827470,561424,165494,53209, 1008528,710358,201706,59634, 1299773,913520,259955,77251, -1989291,-27,16 ],
  [ 2000,200,10,2,4, 367950,356476,45055,1405, 753869,682328,62822,5962, 1713859,1184422,142822,44120, 2351148,1589017,195929,63511, 3033630,2051949,252802,81807, -96636,3,0 ],
  [ 2000,200,15,2,4, 477373,390292,42433,7741, 1233643,1132540,56075,4596, 2899459,1908105,131794,45062, 4196132,2798816,190733,63514, 5456402,3575010,248018,85518, 2940399,39,0 ],
  [ 2000,200,25,2,4, 555108,404848,40371,10928, 2222499,1996729,46544,4728, 6054548,3913002,116434,41184, 9259925,5970334,178075,63261, 12469234,8275502,239793,80649, 10662602,73,0 ],
  [ 2000,200,50,2,4, 561650,406074,40118,11113, 3216502,2883862,40544,4193, 11386561,7173683,103514,38299, 18004318,11573122,163676,58465, 25397678,16814299,230888,78031, 23522530,101,0 ],
  [ 2000,200,75,2,4, 561650,406074,40118,11113, 3233968,3007213,40425,2834, 11556111,7295813,103180,38038, 18285774,11738602,163266,58457, 25829026,17031516,230616,78549, 23619663,101,0 ],
  [ 2500,0,1,2,4, 40713,83074,40713,-42361, 64689,77812,64689,-13123, 121437,78457,121437,42980, 167768,72844,167768,94925, 216459,101322,216459,115137, -3294116,-38,25 ],
  [ 2500,0,2,2,4, 81427,104271,40713,-11422, 129378,92589,64689,18394, 242874,194094,121437,24390, 335537,311115,167768,12211, 432918,359484,216459,36717, -3177068,-38,24 ],
  [ 2500,0,3,2,4, 122140,112132,40713,3336, 194067,195252,64689,-395, 364311,338630,121437,8560, 503305,375945,167768,42453, 649378,436746,216459,70877, -2965883,-36,23 ],
  [ 2500,0,5,2,4, 184294,137469,40210,10216, 311007,341517,62201,-6102, 594995,397245,118999,39550, 830882,553171,166176,55542, 1072157,768748,214431,60682, -2227401,-30,18 ],
  [ 2500,0,10,2,4, 311985,348033,38202,-4414, 661516,559837,55126,8473, 1345101,892187,112092,37743, 1937130,1277235,161428,54991, 2503816,1703652,208651,66680, -649926,-9,0 ],
  [ 2500,0,15,2,4, 412114,367374,36632,3977, 1096232,911996,49829,8374, 2357126,1572134,107142,35681, 3461600,2209249,157345,56925, 4504029,3097536,204729,63932, 2006567,32,0 ],
  [ 2500,0,25,2,4, 483412,394489,35157,6467, 2005969,1861452,42010,3027, 5092995,3295434,97942,34568, 7683612,5008174,147762,51451, 10285033,6974168,197789,63670, 7832923,64,0 ],
  [ 2500,0,50,2,4, 489433,395653,34960,6699, 2929205,2731518,36923,2492, 9798042,6354472,89073,31305, 15072918,9890832,137027,47110, 20941403,14010126,190376,63012, 18060728,91,0 ],
  [ 2500,0,75,2,4, 489433,395653,34960,6699, 2945481,2736349,36819,2614, 9949255,6475591,88833,31015, 15312899,10049178,136722,46998, 21297379,14311260,190155,62376, 18325404,92,0 ],
  [ 2500,25,1,2,4, 45542,83951,45542,-38408, 68611,78422,68611,-9811, 141275,82362,141275,58913, 175618,74424,175618,101194, 226601,103425,226601,123176, -3215946,-38,24 ],
  [ 2500,25,2,2,4, 87816,105431,43908,-8808, 135973,93807,67987,21083, 268282,199072,134141,34605, 351236,329242,175618,10997, 453202,363681,226601,44761, -3083918,-37,24 ],
  [ 2500,25,3,2,4, 130089,113575,43363,5505, 203335,197077,67778,2086, 395290,344682,131763,16869, 526854,380665,175618,48729, 679803,443036,226601,78922, -2813547,-35,22 ],
  [ 2500,25,5,2,4, 194620,139343,42462,12060, 325046,344469,65009,-3885, 636426,419818,127285,43322, 869756,560990,173951,61753, 1122363,779158,224473,68641, -2040387,-28,16 ],
  [ 2500,25,10,2,4, 327169,350789,40062,-2892, 689528,566313,57461,10268, 1419873,921127,118323,41562, 2027693,1310239,168974,59788, 2620870,1827190,218406,66140, -345601,-3,0 ],
  [ 2500,25,15,2,4, 431087,384155,38319,4172, 1141203,936706,51873,9295, 2476162,1609339,112553,39401, 3623003,2445095,164682,53541, 4714444,3172083,214293,70107, 2253899,34,0 ],
  [ 2500,25,25,2,4, 505059,398418,36732,7756, 2085348,1895962,43672,3966, 5328185,3537586,102465,34435, 8037931,5311962,154576,52422, 10765679,7316189,207032,66336, 8203638,65,0 ],
  [ 2500,25,50,2,4, 511304,399622,36522,7977, 3042754,2849712,38354,2433, 10226372,6555667,92967,33370, 15756570,10368260,143242,48985, 21919698,14695255,199270,65677, 19152970,93,0 ],
  [ 2500,25,75,2,4, 511304,399622,36522,7977, 3059625,2868600,38245,2388, 10383664,6783813,92711,32142, 16007069,10448827,142920,49627, 22292252,14902722,199038,65978, 19351354,94,0 ],
  [ 2500,50,1,2,4, 50371,84827,50371,-34456, 72533,79032,72533,-6499, 161112,86267,161112,74845, 183467,76005,183467,107462, 236742,189307,236742,47435, -3197509,-38,24 ],
  [ 2500,50,2,2,4, 94204,106591,47102,-6193, 142568,95024,71284,23772, 293690,204050,146845,44820, 366935,332392,183467,17271, 473485,367877,236742,52804, -2968665,-36,23 ],
  [ 2500,50,3,2,4, 138038,115017,46013,7674, 212604,198902,70868,4567, 426268,350733,142089,25179, 550402,385386,183467,55005, 710227,533107,236742,59040, -2720946,-34,21 ],
  [ 2500,50,5,2,4, 204946,141218,44715,13904, 339084,347420,67817,-1667, 677857,427875,135571,49996, 908630,583735,181726,64979, 1172570,789518,234514,76610, -1841975,-26,15 ],
  [ 2500,50,10,2,4, 342354,353545,41921,-1370, 717540,572789,59795,12063, 1494646,935552,124554,46591, 2118257,1408524,176521,59144, 2737925,1866898,228160,72586, -44462,4,0 ],
  [ 2500,50,15,2,4, 450060,387599,40005,5552, 1186174,1125881,53917,2741, 2595199,1722193,117964,39682, 3784406,2506999,172018,58064, 4924859,3343449,223857,71882, 2049643,32,0 ],
  [ 2500,50,25,2,4, 526707,402347,38306,9044, 2164727,2004322,45335,3359, 5563374,3611202,106988,37542, 8392250,5491864,161389,55777, 11246326,7544669,216275,71186, 8991757,68,0 ],
  [ 2500,50,50,2,4, 533174,403592,38084,9256, 3156303,2894056,39785,3306, 10654703,6848396,96861,34603, 16440221,10750854,149457,51722, 22897993,15283415,208164,69223, 20477202,96,0 ],
  [ 2500,50,75,2,4, 533174,403592,38084,9256, 3173770,2913116,39672,3258, 10818074,6909136,96590,34901, 16701240,10913306,149118,51678, 23287125,15591154,207921,68714, 20910664,97,0 ],
  [ 2500,100,1,2,4, 60028,86580,60028,-26552, 80377,80252,80377,125, 200787,108592,200787,92196, 199166,79216,199166,119950, 257026,193562,257026,63464, -3074668,-37,24 ],
  [ 2500,100,2,2,4, 106982,108910,53491,-964, 155759,97459,77879,29150, 344507,334571,172253,4968, 398332,339243,199166,29545, 514051,392491,257026,60780, -3028210,-37,23 ],
  [ 2500,100,3,2,4, 153935,117903,51312,12011, 231140,202552,77047,9530, 488226,377350,162742,36959, 597498,394876,199166,67541, 771077,561410,257026,69889, -2460948,-32,20 ],
  [ 2500,100,5,2,4, 225598,216666,49221,1949, 367160,353324,73432,2767, 760718,535506,152144,45042, 986379,707784,197276,55719, 1272982,909739,254596,72649, -2135909,-29,17 ],
  [ 2500,100,10,2,4, 372722,359057,45639,1673, 773563,703975,64464,5799, 1644191,1174832,137016,39113, 2299384,1583493,191615,59658, 2972034,2043784,247669,77354, -337957,-3,0 ],
  [ 2500,100,15,2,4, 488006,394486,43378,8313, 1276117,1161716,58005,5200, 2833271,1902803,128785,42294, 4107211,2681662,186691,64798, 5345688,3560901,242986,81127, 2842009,38,0 ],
  [ 2500,100,25,2,4, 570002,423542,41455,10652, 2323485,2059307,48659,5533, 6033753,3927120,116034,40512, 9100888,5895396,175017,61644, 12207619,8244132,234762,76221, 10399982,72,0 ],
  [ 2500,100,50,2,4, 576914,424868,41208,10860, 3383402,3160795,42648,2806, 11511363,7355334,104649,37782, 17807525,11611077,161887,56331, 24854583,16556403,225951,75438, 22478861,99,0 ],
  [ 2500,100,75,2,4, 576914,424868,41208,10860, 3402060,3180199,42526,2773, 11686893,7524380,104347,37165, 18089580,11778085,161514,56353, 25276872,16871248,225686,75050, 22736318,100,0 ],
  [ 2500,200,1,2,4, 79343,103422,79343,-24079, 96065,82542,96065,13523, 280138,215727,280138,64411, 230564,100715,230564,129849, 297592,202171,297592,95421, -3048779,-37,23 ],
  [ 2500,200,2,2,4, 132537,113548,66268,9494, 182140,116214,91070,32963, 446139,368848,223070,38646, 461128,351895,230564,54616, 595184,409277,297592,92954, -2644280,-34,21 ],
  [ 2500,200,3,2,4, 185731,123674,61910,20686, 268214,314201,89405,-15329, 612141,429085,204047,61019, 691691,428833,230564,87619, 892776,586172,297592,102201, -2267330,-30,18 ],
  [ 2500,200,5,2,4, 266902,224163,58233,9325, 423313,379016,84663,8860, 926441,596617,185288,65965, 1141875,753937,228375,77588, 1473807,982570,294761,98247, -1408884,-21,-55 ],
  [ 2500,200,10,2,4, 433460,370081,53077,7761, 885609,729879,73801,12978, 1943282,1261562,161940,56810, 2661637,1765765,221803,74656, 3440252,2286496,286688,96146, 1051228,22,0 ],
  [ 2500,200,15,2,4, 563898,408261,50124,13834, 1456001,1233386,66182,10119, 3309416,2114406,150428,54319, 4752823,3058091,216037,77033, 6187347,4220937,281243,89382, 4571685,49,0 ],
  [ 2500,200,25,2,4, 656591,439259,47752,15806, 2641000,2361663,55309,5850, 6974511,4465770,134125,48245, 10518164,6847350,202272,70593, 14130205,9512995,271735,88792, 12810569,79,0 ],
  [ 2500,200,50,2,4, 664396,440747,47457,15975, 3837599,3530258,48373,3874, 13224685,8476611,120224,43164, 20542132,13331722,186747,65549, 28767763,19103031,261525,87861, 26863006,106,0 ],
  [ 2500,200,75,2,4, 664396,440747,47457,15975, 3858640,3536464,48233,4027, 13424531,8529755,119862,43703, 20866260,13549420,186306,65329, 29256364,19446257,261218,87590, 27506843,107,0 ],
  [ 3000,0,1,2,4, 49264,84850,49264,-35587, 77204,80781,77204,-3576, 141470,82673,141470,58796, 194719,78617,194719,116102, 251591,192717,251591,58874, -3208773,-38,24 ],
  [ 3000,0,2,2,4, 98527,107823,49264,-4648, 154409,98528,77204,27940, 282939,202527,141470,40206, 389437,337638,194719,25900, 503183,390384,251591,56400, -2920190,-36,23 ],
  [ 3000,0,3,2,4, 147791,117460,49264,10110, 231613,204160,77204,9151, 424409,351280,141470,24376, 584156,393265,194719,63630, 754774,559040,251591,65245, -2601559,-33,21 ],
  [ 3000,0,5,2,4, 222996,217208,48654,1263, 371588,370039,74318,310, 693967,509439,138793,36906, 964230,705209,192846,51804, 1246191,905908,249238,68057, -2326111,-31,19 ],
  [ 3000,0,10,2,4, 222996,217208,27306,709, 371588,370039,30966,129, 709084,527182,59090,15158, 964230,705259,80352,21581, 1246191,905958,103849,28353, -2332360,-31,19 ],
  [ 3000,0,15,2,4, 498639,398680,44323,8885, 1318590,1190892,59936,5804, 2767083,1776935,125776,45007, 4018291,2672418,182650,61176, 5234974,3546243,237953,76760, 2862423,39,0 ],
  [ 3000,0,25,2,4, 584895,428900,42538,11345, 2424470,2300236,50774,2602, 6012958,3954253,115634,39590, 8941852,5885690,171959,58772, 11946004,8128381,229731,73416, 9561466,70,0 ],
  [ 3000,0,50,2,4, 592179,430326,42299,11561, 3550301,3273563,44752,3488, 11636166,7658900,105783,36157, 17610732,11664407,160098,54057, 24311489,16396777,221014,71952, 21636263,98,0 ],
  [ 3000,0,75,2,4, 592179,430326,42299,11561, 3570152,3273563,44627,3707, 11801892,7709383,105374,36540, 17849633,11831844,159372,53730, 24541522,16612861,219121,70792, 22078562,99,0 ],
  [ 3000,25,1,2,4, 54092,85727,54092,-31634, 81126,81391,81126,-265, 161307,86578,161307,74729, 202568,95174,202568,107394, 261733,194819,261733,66914, -3152706,-37,24 ],
  [ 3000,25,2,2,4, 104916,108983,52458,-2033, 161004,99745,80502,30629, 305436,313555,152718,-4059, 354963,315940,177482,19512, 523246,395080,261623,64083, -3093784,-37,24 ],
  [ 3000,25,3,2,4, 155740,118902,51913,12279, 240882,205985,80294,11632, 455388,371846,151796,27847, 607704,413012,202568,64898, 785199,565381,261733,73273, -2504827,-32,20 ],
  [ 3000,25,5,2,4, 233322,219082,50907,3107, 385627,372991,77125,2527, 735397,532011,147079,40677, 1003104,713028,200621,58015, 1296398,916318,259280,76016, -2139096,-29,17 ],
  [ 3000,25,10,2,4, 392679,364394,48083,3463, 821268,718213,68439,8588, 1649297,1179816,137441,39123, 2338183,1595947,194849,61853, 3027493,2075377,252291,79343, -116033,3,0 ],
  [ 3000,25,15,2,4, 517612,402124,46010,10266, 1363561,1201867,61980,7350, 2886119,1920340,131187,43899, 4179694,2828306,189986,61427, 5445388,3818060,247518,73969, 2940811,39,0 ],
  [ 3000,25,25,2,4, 606543,432829,44112,12634, 2503849,2334746,52437,3541, 6248147,4104869,120157,41217, 9296171,6189029,178773,59753, 12426650,8356861,238974,78265, 10224691,72,0 ],
  [ 3000,25,50,2,4, 614049,434295,43861,12840, 3663850,3496107,46183,2114, 12064496,7767079,109677,39067, 18294384,12046502,166313,56799, 25289784,16983937,229907,75508, 22821968,100,0 ],
  [ 3000,25,75,2,4, 614049,434295,43861,12840, 3684297,3502200,46054,2276, 12252084,8016256,109394,37820, 18587555,12216516,165960,56884, 25719590,17316963,229639,75023, 22975566,100,0 ],
  [ 3000,50,1,2,4, 58921,86603,58921,-27682, 85048,82001,85048,3047, 181145,104998,181145,76147, 210418,96805,210418,113613, 271875,196972,271875,74903, -3108036,-37,24 ],
  [ 3000,50,2,2,4, 111305,110142,55652,581, 167599,114848,83800,26376, 333756,332898,166878,429, 420835,344489,210418,38173, 543749,399277,271875,72236, -3012145,-36,23 ],
  [ 3000,50,3,2,4, 163688,120345,54563,14448, 250150,207810,83383,14113, 486367,377898,162122,36156, 631253,417732,210418,71174, 815624,571671,271875,81318, -2352491,-31,19 ],
  [ 3000,50,5,2,4, 243648,220956,53160,4951, 399665,375943,79933,4744, 776828,540069,155366,47352, 1041978,735774,208396,61241, 1346604,942349,269321,80851, -1951858,-27,16 ],
  [ 3000,50,10,2,4, 407863,367151,49942,4985, 849280,724689,70773,10383, 1724069,1194241,143672,44152, 2428746,1628901,202396,66654, 3144547,2198865,262046,78807, 221792,10,0 ],
  [ 3000,50,15,2,4, 536585,405568,47696,11646, 1408532,1226726,64024,8264, 3005155,2034545,136598,44119, 4341097,2875234,197323,66630, 5655803,3876786,257082,80864, 3356109,42,0 ],
  [ 3000,50,25,2,4, 628190,436758,45687,13922, 2583228,2369256,54099,4481, 6483337,4163971,124680,44603, 9650490,6368881,185586,63108, 12907297,8698382,248217,80941, 11199459,75,0 ],
  [ 3000,50,50,2,4, 635920,438265,45423,14118, 3777400,3540452,47614,2987, 12492826,8074324,113571,40168, 18978036,12509453,172528,58805, 26268079,17669566,238801,78168, 23924717,102,0 ],
  [ 3000,50,75,2,4, 635920,438265,45423,14118, 3798442,3546717,47481,3147, 12686494,8218578,113272,39892, 19281725,12615665,172158,59518, 26714463,17907976,238522,78629, 24524258,103,0 ],
  [ 3000,100,1,2,4, 68578,101693,68578,-33114, 92893,83071,92893,9821, 220820,112808,220820,108012, 226116,100116,226116,126001, 292158,201326,292158,90832, -3006387,-36,23 ],
  [ 3000,100,2,2,4, 124082,112462,62041,5810, 180790,117283,90395,31753, 384572,342854,192286,20859, 452233,350789,226116,50722, 584316,407669,292158,88323, -2781639,-35,22 ],
  [ 3000,100,3,2,4, 179586,123231,59862,18785, 268687,315810,89562,-15708, 548324,403015,182775,48436, 678349,427222,226116,83709, 876474,583802,292158,97557, -2407941,-31,19 ],
  [ 3000,100,5,2,4, 264300,224705,57665,8639, 427741,381846,85548,9179, 859690,570700,171938,57798, 1119726,751362,223945,73673, 1447017,963119,289403,96780, -1544329,-23,11 ],
  [ 3000,100,10,2,4, 438232,385999,53661,6396, 905303,825226,75442,6673, 1873615,1251971,156135,51804, 2609873,1760391,217489,70790, 3378656,2262810,281555,92987, 532605,15,0 ],
  [ 3000,100,15,2,4, 574531,425792,51069,13221, 1498475,1248526,68112,11361, 3243228,2109254,147419,51544, 4663902,3049547,211996,73380, 6076632,4093938,276211,90122, 4382744,48,0 ],
  [ 3000,100,25,2,4, 671485,529653,48835,10315, 2741986,2498091,57424,5108, 6953716,4494403,133725,47294, 10359128,6772463,199214,68974, 13868589,9382223,266704,86276, 12057591,77,0 ],
  [ 3000,100,50,2,4, 679660,531241,48547,10601, 4004498,3702840,50477,3802, 13349487,8582762,121359,43334, 20345340,13369676,184958,63415, 28224669,19026835,256588,83617, 25899279,105,0 ],
  [ 3000,100,75,2,4, 679660,531241,48547,10601, 4026732,3813799,50334,2662, 13555313,8833823,121030,42156, 20670066,13588903,184554,63225, 28704210,19271850,256288,84217, 25836120,105,0 ],
  [ 3000,200,1,2,4, 87893,105198,87893,-17305, 108581,85512,108581,23069, 300170,219943,300170,80227, 257514,186796,257514,70718, 332724,322876,332724,9848, -3102858,-37,24 ],
  [ 3000,200,2,2,4, 149637,117100,74819,16269, 207171,196003,103585,5584, 486205,391796,243103,47204, 515028,378467,257514,68280, 665449,440226,332724,112611, -2654684,-34,21 ],
  [ 3000,200,3,2,4, 211381,142338,70460,23014, 305760,336844,101920,-10361, 672240,441585,224080,76885, 772542,541587,257514,76985, 998173,737876,332724,86765, -2162808,-29,17 ],
  [ 3000,200,5,2,4, 305604,333068,66677,-5992, 483894,392153,96779,18348, 1025413,738011,205083,57480, 1275223,879822,255045,79080, 1647842,1234871,329568,82594, -1691662,-24,13 ],
  [ 3000,200,10,2,4, 498970,397024,61098,12483, 1017350,865014,84779,12695, 2172705,1415552,181059,63096, 2972126,1985792,247677,82195, 3846874,2618564,320573,102359, 1557424,27,0 ],
  [ 3000,200,15,2,4, 650423,439567,57815,18743, 1678359,1498396,76289,8180, 3719373,2439923,169062,58157, 5309514,3426526,241342,85590, 6918291,4754024,314468,98376, 5273140,53,0 ],
  [ 3000,200,25,2,4, 758075,545370,55133,15469, 3059501,2800447,64073,5425, 7894473,5139104,151817,52988, 11776404,7753069,226469,77372, 15791175,10538496,303676,101013, 14261883,83,0 ],
  [ 3000,200,50,2,4, 767141,547119,54796,15716, 4458695,4162768,56202,3730, 15062808,9702689,136935,48728, 20856620,15010014,189606,53151, 32137849,21573012,292162,96044, 26837543,106,0 ],
  [ 3000,200,75,2,4, 767141,547119,54796,15716, 4483311,4170064,56041,3916, 15292951,9762198,136544,49382, 23446746,15317109,209346,72586, 32683703,21831188,291819,96897, 30857969,112,0 ],
  [ 1000,0,1,3,4, 21690,78861,21690,-57170, 36425,56904,36425,-20478, 75187,68549,75187,6637, 101029,58298,101029,42731, 123746,65448,123746,58298, -3578868,-40,26 ],
  [ 1000,0,2,3,4, 34596,81994,17298,-23699, 54846,56904,27423,-1029, 144729,81021,72365,31854, 182590,78014,91295,52288, 192238,102500,96119,44869, -3235981,-38,25 ],
  [ 1000,0,3,3,4, 65071,86154,21690,-7028, 109276,86599,36425,7559, 225560,111043,75187,38172, 303087,193196,101029,36631, 371237,343994,123746,9081, -2970346,-36,23 ],
  [ 1000,0,5,3,4, 98189,105061,21423,-1499, 173609,114858,34722,11750, 366103,334595,73221,6302, 501729,355968,100346,29152, 613803,409399,122761,40881, -2810529,-35,22 ],
  [ 1000,0,10,3,4, 166257,116523,20358,6090, 358637,351665,29886,581, 811799,535603,67650,23016, 1177594,750013,98133,35632, 1439244,949533,119937,40809, -1562873,-23,12 ],
  [ 1000,0,15,3,4, 219656,138861,19525,7182, 579558,523409,26344,2552, 1400478,883838,63658,23484, 2106671,1287673,95758,37227, 2599887,1701233,118177,40848, 138463,8,0 ],
  [ 1000,0,25,3,4, 257705,145287,18742,8176, 1018816,890368,21336,2690, 2930067,1744942,56347,22791, 4635171,2857905,89138,34178, 6007713,3872215,115533,41067, 4321543,48,0 ],
  [ 1000,0,50,3,4, 260922,145832,18637,8221, 1452293,1259417,18306,2431, 5443534,3256449,49487,19883, 8925298,5470666,81139,31406, 12366877,7881911,112426,40772, 10976771,74,0 ],
  [ 1000,0,75,3,4, 260922,145832,18637,8221, 1459924,1275449,18249,2306, 5522052,3269968,49304,20108, 9061610,5510917,80907,31703, 12579938,8067651,112321,40288, 11260410,75,0 ],
  [ 1000,25,1,3,4, 27859,79980,27859,-52121, 41509,57705,41509,-16195, 97810,73001,97810,24810, 110153,60137,110153,50016, 135046,67792,135046,67253, -3485844,-40,26 ],
  [ 1000,25,2,3,4, 51796,84035,25898,-16119, 81520,80292,40760,614, 179825,88231,89913,45797, 220306,96722,110153,61792, 270091,194695,135046,37698, -3081814,-37,24 ],
  [ 1000,25,3,3,4, 75733,101426,25244,-8564, 121531,88845,40510,10895, 261840,195126,87280,22238, 330459,307297,110153,7721, 405137,351157,135046,17993, -3176283,-38,24 ],
  [ 1000,25,5,3,4, 112274,107618,24496,1016, 192278,118745,38456,14707, 415265,344150,83053,14223, 547000,379998,109400,33400, 669828,436637,133966,46638, -2573874,-33,20 ],
  [ 1000,25,10,3,4, 187338,120349,22939,8203, 396177,374148,33015,1836, 902403,552922,75200,29123, 1283647,866359,106971,34774, 1570460,992107,130872,48196, -1228572,-19,-44 ],
  [ 1000,25,15,3,4, 246192,143678,21884,9112, 640114,538077,29096,4638, 1546500,926356,70295,28188, 2296484,1435510,104386,39135, 2836588,1849177,128936,44882, 735024,17,0 ],
  [ 1000,25,25,3,4, 288093,222503,20952,4770, 1126427,932096,23590,4070, 3223514,2012662,61991,23286, 5055421,3174685,97220,36168, 6552315,4242949,126006,44411, 4672158,50,0 ],
  [ 1000,25,50,3,4, 291631,223106,20831,4895, 1606862,1506743,20255,1262, 5986081,3583531,54419,21841, 9743666,5989821,88579,34126, 13483901,8626278,122581,44160, 11691594,76,0 ],
  [ 1000,25,75,3,4, 291631,223106,20831,4895, 1615312,1509126,20191,1327, 6072518,3613185,54219,21958, 9892789,6141051,88328,33498, 13716139,8770957,122466,44153, 11900157,77,0 ],
  [ 1000,50,1,3,4, 34028,81100,34028,-47072, 46593,58505,46593,-11912, 120434,77452,120434,42983, 119277,61976,119277,57300, 146345,70137,146345,76209, -3392820,-39,25 ],
  [ 1000,50,2,3,4, 60212,85562,30106,-12675, 90190,81891,45095,4149, 209277,108512,104639,50383, 238553,180744,119277,28905, 292691,199424,146345,46634, -3094662,-37,24 ],
  [ 1000,50,3,3,4, 86395,103361,28798,-5655, 133786,91241,44595,14182, 298121,202208,99374,31971, 357830,312788,119277,15014, 439036,358171,146345,26955, -2990955,-36,23 ],
  [ 1000,50,5,3,4, 126359,110174,27569,3531, 210946,196481,42189,2893, 464426,368221,92885,19241, 592271,389102,118454,40634, 725854,532034,145171,38764, -2630957,-33,21 ],
  [ 1000,50,10,3,4, 208419,137512,25521,8682, 433718,382595,36143,4260, 993008,690957,82751,25171, 1389700,888120,115808,41798, 1701676,1232302,141806,39115, -1183998,-18,-44 ],
  [ 1000,50,15,3,4, 272729,220195,24243,4670, 700671,566629,31849,6093, 1692521,1074774,76933,28079, 2486296,1597022,113013,40422, 3073289,2042551,139695,46852, 697122,17,0 ],
  [ 1000,50,25,3,4, 318481,328885,23162,-757, 1234038,1150523,25844,1749, 3516961,2110347,67634,27050, 5475670,3384055,105301,40223, 7096918,4698013,136479,46133, 4831396,51,0 ],
  [ 1000,50,50,3,4, 322340,329547,23024,-515, 1761430,1560483,22203,2533, 6528627,3926778,59351,23653, 10562034,6523702,96018,36712, 14600924,9440855,132736,46910, 12789206,79,0 ],
  [ 1000,50,75,3,4, 322340,329547,23024,-515, 1770700,1563102,22134,2595, 6622985,3957901,59134,23795, 10723969,6649249,95750,36381, 14852339,9521244,132610,47599, 13130723,80,0 ],
  [ 1000,100,1,3,4, 46366,83339,46366,-36974, 56762,60107,56762,-3346, 165682,100869,165682,64813, 137524,65704,137524,71820, 168945,74875,168945,94069, -3240273,-38,25 ],
  [ 1000,100,2,3,4, 77042,101954,38521,-12456, 107529,84938,53764,11295, 268182,197046,134091,35568, 275049,188225,137524,43412, 337890,322022,168945,7934, -3128133,-37,24 ],
  [ 1000,100,3,3,4, 107719,107232,35906,162, 158296,96033,52765,20754, 370681,336938,123560,11248, 412573,339297,137524,24425, 506835,372748,168945,44696, -2921280,-36,23 ],
  [ 1000,100,5,3,4, 154529,115287,33715,8562, 248283,204253,49657,8806, 562750,401847,112550,32181, 682813,422237,136563,52115, 837905,570888,167581,53403, -2157870,-29,17 ],
  [ 1000,100,10,3,4, 250581,216865,30683,4128, 508799,413674,42400,7927, 1174216,754775,97851,34953, 1601806,960296,133484,53459, 1964108,1301930,163676,55182, -584576,-8,0 ],
  [ 1000,100,15,3,4, 325803,330695,28960,-435, 821784,712700,37354,4958, 1984564,1235160,90207,34064, 2865921,1783934,130269,49181, 3546691,2271279,161213,57973, 1141159,23,0 ],
  [ 1000,100,25,3,4, 379257,353253,27582,1891, 1449260,1233829,30351,4512, 4103855,2462736,78920,31560, 6316168,4002289,121465,44498, 8186123,5310368,157425,55303, 6601685,59,0 ],
  [ 1000,100,50,3,4, 383757,354031,27411,2123, 2070568,1862899,26100,2618, 7613720,4491057,69216,28388, 12198770,7546735,110898,42291, 16834971,10801078,153045,54854, 15681900,86,0 ],
  [ 1000,100,75,3,4, 383757,354031,27411,2123, 2081475,1865991,26018,2694, 7723918,4602119,68964,27873, 12386328,7596914,110592,42763, 17124739,10972887,152899,54927, 15991396,87,0 ],
  [ 1000,200,1,3,4, 71041,87818,71041,-16777, 77098,77196,77098,-98, 256177,133039,256177,123138, 174019,73111,174019,100909, 214144,84253,214144,129891, -2945262,-36,23 ],
  [ 1000,200,2,3,4, 110703,108064,55352,1320, 142207,91332,71103,25438, 385990,355194,192995,15398, 348039,311945,174019,18047, 428288,357009,214144,35640, -3055055,-37,23 ],
  [ 1000,200,3,3,4, 150366,114973,50122,11798, 207315,193352,69105,4654, 515803,394148,171934,40552, 522058,376338,174019,48573, 642432,416523,214144,75303, -2557609,-33,20 ],
  [ 1000,200,5,3,4, 210869,125514,46008,18623, 322957,338032,64591,-3015, 759398,529935,151880,45892, 863897,553787,172779,62022, 1062006,745417,212401,63318, -1890777,-27,15 ],
  [ 1000,200,10,3,4, 334905,333037,41009,229, 658961,534298,54913,10389, 1536634,945048,128053,49299, 2026019,1279889,168835,62177, 2488972,1670899,207414,68173, 36159,6,0 ],
  [ 1000,200,15,3,4, 431949,363298,38396,6102, 1064009,872841,48364,8689, 2568650,1602498,116757,43916, 3625171,2402331,164781,55584, 4493495,2969286,204250,69282, 2582177,36,0 ],
  [ 1000,200,25,3,4, 500808,388652,36422,8157, 1879705,1652640,39366,4755, 5277644,3243015,101493,39127, 7997165,5051640,153792,56645, 10364534,6776281,199318,69005, 9329779,69,0 ],
  [ 1000,200,50,3,4, 506592,389663,36185,8352, 2688843,2363531,33893,4101, 9783906,5924730,88945,35083, 15472242,9606878,140657,53321, 21303066,13803575,193664,68177, 20928301,97,0 ],
  [ 1000,200,75,3,4, 506592,389663,36185,8352, 2703026,2367568,33788,4193, 9925785,5964670,88623,35367, 15711045,9790372,140277,52863, 21669540,13906883,193478,69309, 21463226,98,0 ],
  [ 1500,0,1,3,4, 34003,81418,34003,-47415, 53212,60803,53212,-7591, 99742,73720,99742,26022, 132355,65018,132355,67337, 162890,73940,162890,88950, -3395536,-39,25 ],
  [ 1500,0,2,3,4, 68006,100959,34003,-16477, 106424,86343,53212,10041, 199484,92804,99742,53340, 264710,186953,132355,38878, 325780,320240,162890,2770, -3108176,-37,24 ],
  [ 1500,0,3,3,4, 102008,107163,34003,-1718, 159636,98147,53212,20496, 299226,203554,99742,31891, 397064,336941,132355,20041, 488670,369619,162890,39684, -2833721,-35,22 ],
  [ 1500,0,5,3,4, 153921,116637,33583,8135, 254964,207995,50993,9394, 487725,374713,97545,22602, 657033,419264,131407,47554, 808021,566689,161604,48266, -2318256,-31,18 ],
  [ 1500,0,10,3,4, 260591,221152,31909,4829, 536241,424079,44687,9347, 1095837,730143,91320,30475, 1541320,953836,128443,48957, 1895154,1292829,157929,50194, -752773,-12,0 ],
  [ 1500,0,15,3,4, 344252,350640,30600,-568, 880348,820938,40016,2700, 1910896,1230345,86859,30934, 2761674,1773978,125531,44895, 3422301,2255639,155559,53030, 602914,16,0 ],
  [ 1500,0,25,3,4, 403841,361538,29370,3077, 1588111,1487413,33259,2109, 4088974,2572895,78634,29155, 6129045,3897695,117866,42911, 7889981,5190586,151730,51911, 5770725,55,0 ],
  [ 1500,0,50,3,4, 408876,362459,29205,3315, 2300033,2070515,28992,2893, 7789503,4829918,70814,26905, 11968583,7587983,108805,39824, 16216054,10535930,147419,51637, 14790811,84,0 ],
  [ 1500,0,75,3,4, 408876,362459,29205,3315, 2312580,2088046,28907,2807, 7907585,4866264,70603,27155, 12156953,7762810,108544,39233, 16495386,10819817,147280,50675, 14979001,85,0 ],
  [ 1500,25,1,3,4, 40172,82538,40172,-42366, 58296,75489,58296,-17193, 122366,78021,122366,44345, 141479,66857,141479,74622, 174190,76234,174190,97956, -3346096,-39,25 ],
  [ 1500,25,2,3,4, 76421,102486,38210,-13033, 115093,87941,57547,13576, 228936,113086,114468,57925, 282957,190668,141479,46145, 348380,324969,174190,11705, -3002501,-36,23 ],
  [ 1500,25,3,3,4, 112670,109098,37557,1191, 171891,114428,57297,19154, 335507,331052,111836,1485, 424436,342982,141479,27151, 522569,392803,174190,43255, -2982028,-36,23 ],
  [ 1500,25,5,3,4, 168005,119194,36656,10650, 273632,316231,54726,-8520, 536887,382769,107377,30824, 702304,428318,140461,54797, 864047,577756,172809,57258, -2374945,-31,19 ],
  [ 1500,25,10,3,4, 281672,224978,34490,6942, 573782,506526,47815,5605, 1186442,839128,98870,28943, 1647374,1098384,137281,45749, 2026370,1335504,168864,57572, -860218,-13,-19 ],
  [ 1500,25,15,3,4, 370788,355457,32959,1363, 940904,835606,42768,4786, 2056918,1272863,93496,35639, 2951486,1949967,134158,45524, 3659002,2532344,166318,51212, 1066119,22,0 ],
  [ 1500,25,25,3,4, 434229,380390,31580,3916, 1695722,1528991,35513,3492, 4382421,2763615,84277,31131, 6549294,4214625,125948,44897, 8434584,5629579,162204,53942, 6488567,58,0 ],
  [ 1500,25,50,3,4, 439585,381370,31399,4158, 2454602,2317691,30940,1726, 8332049,5171515,75746,28732, 12786951,8107338,116245,42542, 17333078,11348606,157573,54404, 15662865,86,0 ],
  [ 1500,25,75,3,4, 439585,381370,31399,4158, 2467968,2321572,30850,1830, 8458052,5271966,75518,28447, 12988133,8269608,115965,42130, 17631586,11552533,157425,54277, 15876321,87,0 ],
  [ 1500,50,1,3,4, 46340,83657,46340,-37317, 63380,76290,63380,-12910, 144990,82472,144990,62518, 150602,68696,150602,81907, 185490,78578,185490,106911, -3253073,-38,25 ],
  [ 1500,50,2,3,4, 84836,104014,42418,-9589, 123763,89540,61881,17112, 258389,195853,129194,31268, 301205,194333,150602,53436, 370979,345319,185490,12830, -3050996,-37,23 ],
  [ 1500,50,3,3,4, 123332,111034,41111,4099, 184146,116825,61382,22440, 371787,338134,123929,11218, 451807,348473,150602,34445, 556469,399817,185490,52217, -2796700,-35,22 ],
  [ 1500,50,5,3,4, 182090,121750,39729,13165, 292300,334002,58460,-8340, 586049,406689,117210,35872, 747575,517730,149515,45969, 920072,589322,184014,66150, -2300621,-31,18 ],
  [ 1500,50,10,3,4, 302753,329671,37072,-3296, 611322,527508,50944,6985, 1277046,856447,106421,35050, 1753427,1201253,146119,46014, 2157586,1463359,179799,57852, -977222,-15,-22 ],
  [ 1500,50,15,3,4, 397325,360274,35318,3293, 1001460,864159,45521,6241, 2202939,1392231,100134,36850, 3141299,2082827,142786,48112, 3895703,2596457,177077,59057, 1524437,27,0 ],
  [ 1500,50,25,3,4, 464617,385905,33790,5724, 1803333,1570718,37766,4872, 4675868,2925285,89921,33665, 6969543,4530905,134030,46897, 8979186,5999812,172677,57296, 7378544,62,0 ],
  [ 1500,50,50,3,4, 470294,386944,33592,5954, 2609170,2372931,32889,2978, 8874596,5577247,80678,29976, 13605319,8639669,123685,45142, 18450101,12093524,167728,57787, 17079714,89,0 ],
  [ 1500,50,75,3,4, 470294,386944,33592,5954, 2609170,2373102,32615,2951, 8876902,5577739,79258,29457, 13605319,8639669,121476,44336, 18450101,12093524,164733,56755, 17083329,89,0 ],
  [ 1500,100,1,3,4, 58678,85897,58678,-27219, 73548,77892,73548,-4344, 190238,105890,190238,84348, 168850,72424,168850,96426, 208089,83317,208089,124772, -3100525,-37,24 ],
  [ 1500,100,2,3,4, 101667,107069,50833,-2701, 141102,92737,70551,24183, 317293,221902,158646,47695, 337700,310173,168850,13763, 416178,354726,208089,30726, -2966218,-36,23 ],
  [ 1500,100,3,3,4, 144655,114904,48218,9917, 208656,195467,69552,4396, 444348,366814,148116,25845, 506550,374482,168850,44023, 624267,413894,208089,70124, -2715428,-34,21 ],
  [ 1500,100,5,3,4, 144655,115418,31561,6379, 208656,196660,41731,2399, 446401,367259,89280,15829, 506550,374507,101310,26409, 624267,413913,124853,42071, -2717711,-34,21 ],
  [ 1500,100,10,3,4, 344915,350660,42234,-704, 686403,544553,57200,11821, 1458255,920416,121521,44820, 1965533,1273579,163794,57663, 2420018,1661948,201668,63172, -186731,1,0 ],
  [ 1500,100,15,3,4, 344915,350660,30659,-511, 686403,544553,31200,6448, 2191766,1392062,99626,36350, 2001089,1297003,90959,32004, 2467332,1704425,112151,34678, 433070,13,0 ],
  [ 1500,100,25,3,4, 525393,396937,38210,9342, 2018556,1728024,42273,6084, 5262762,3277675,101207,38175, 7810041,4960221,150193,54804, 10068392,6612018,193623,66469, 9248344,69,0 ],
  [ 1500,100,50,3,4, 531711,398092,37979,9544, 2918308,2675497,36785,3061, 9959689,6156041,90543,34579, 15242055,9662603,138564,50722, 20684149,13521756,188038,65113, 19945039,95,0 ],
  [ 1500,100,75,3,4, 531711,398092,37979,9544, 2918308,2675688,36479,3033, 9962004,6156535,88946,33977, 15242055,9662603,136090,49817, 20684149,13521756,184680,63950, 19948607,95,0 ],
  [ 1500,200,1,3,4, 654546,429242,654546,225304, 3555682,3141772,3555682,413910, 12213945,7544540,12213945,4669406, 18806389,11916861,18806389,6889528, 25584988,16770226,25584988,8814761, 25952031,105,0 ],
  [ 1500,200,2,3,4, 135328,113178,67664,11075, 175780,99131,87890,38325, 435101,365534,217551,34784, 410690,340361,205345,35165, 506576,389663,253288,58456, -2721669,-34,21 ],
  [ 1500,200,3,3,4, 135328,113588,45109,7247, 175780,99907,58593,25291, 436333,365802,145444,23510, 410690,340381,136897,23437, 506576,389678,168859,38966, -2723664,-34,21 ],
  [ 1500,200,5,3,4, 266600,222126,58167,9703, 404311,357168,80862,9429, 881020,570054,176204,62193, 1019201,710717,203840,61697, 1256225,902857,251245,70674, -1651371,-24,12 ],
  [ 1500,200,10,3,4, 429239,365966,52560,7748, 836565,711062,69714,10459, 1820673,1215089,151723,50465, 2389745,1592172,199145,66464, 2944881,2029367,245407,76293, 465876,14,0 ],
  [ 1500,200,15,3,4, 556545,402510,49471,13692, 1364799,1171870,62036,8769, 3079068,2026005,139958,47866, 4280174,2806829,194553,66970, 5315909,3522192,241632,81533, 3696312,44,0 ],
  [ 1500,200,25,3,4, 646944,432336,47050,15608, 2449000,2072985,51288,7875, 6436551,4057954,123780,45742, 9491038,6103055,182520,65154, 12246802,8077931,235515,80171, 12072248,77,0 ],
  [ 1500,200,50,3,4, 654546,433724,46753,15773, 3536582,3176129,44579,4544, 12129875,7498199,110272,42106, 18515528,11722696,168323,61753, 25152243,16440422,228657,79198, 25461173,104,0 ],
  [ 1500,200,75,3,4, 654546,433724,46753,15773, 3555682,3181665,44446,4675, 12311318,7623451,109922,41856, 18806389,11917279,167914,61510, 25584988,16770590,228437,78700, 25848023,105,0 ],
  [ 2000,0,1,3,4, 46315,83975,46315,-37660, 69999,78588,69999,-8589, 124298,78740,124298,45558, 163681,71737,163681,91943, 202034,82381,202034,119653, -3255789,-38,25 ],
  [ 2000,0,2,3,4, 92630,106074,46315,-6722, 139997,94142,69999,22928, 248595,194660,124298,26968, 327361,308902,163681,9230, 404068,352944,202034,25562, -3122759,-37,24 ],
  [ 2000,0,3,3,4, 138945,114835,46315,8037, 209996,197581,69999,4138, 372893,339480,124298,11138, 491042,372626,163681,39472, 606103,411265,202034,64946, -2873247,-35,22 ],
  [ 2000,0,5,3,4, 209652,141550,45742,14859, 336318,345366,67264,-1810, 609347,413181,121869,39233, 812338,548041,162468,52859, 1002240,737218,200448,53004, -2123245,-29,17 ],
  [ 2000,0,10,3,4, 354924,354947,43460,-3, 713846,568842,59487,12084, 1379876,895634,114990,40354, 1905047,1267120,158754,53161, 2351063,1652797,195922,58189, -398511,-5,0 ],
  [ 2000,0,15,3,4, 468848,389853,41675,7022, 1181137,1046118,53688,6137, 2421314,1576852,110060,38385, 3416676,2193452,155303,55601, 4244715,2921636,192942,60140, 2024592,32,0 ],
  [ 2000,0,25,3,4, 549977,418558,39998,9558, 2157406,1995343,45181,3394, 5247881,3310684,100921,37254, 7622918,4964237,146595,51128, 9772249,6576116,187928,61464, 8275796,65,0 ],
  [ 2000,0,50,3,4, 556830,419857,39774,9784, 3147773,2896848,39678,3163, 10135472,6480237,92141,33229, 15011868,9703950,136472,48254, 20065232,13369748,182411,60868, 18908543,93,0 ],
  [ 2000,0,75,3,4, 556830,419857,39774,9784, 3165236,2901992,39565,3291, 10293118,6525045,91903,33644, 15252296,9889866,136181,47879, 20410834,13570583,182240,61074, 19389737,94,0 ],
  [ 2000,25,1,3,4, 52484,85095,52484,-32611, 75083,79389,75083,-4306, 146921,83191,146921,63730, 172804,73576,172804,99228, 213334,100397,213334,112937, -3173938,-38,24 ],
  [ 2000,25,2,3,4, 101045,107601,50523,-3278, 148667,95740,74333,26463, 278047,200427,139024,38810, 345609,327543,172804,9033, 426668,357623,213334,34522, -3005687,-36,23 ],
  [ 2000,25,3,3,4, 149607,116771,49869,10945, 222251,199977,74084,7424, 409173,346563,136391,20870, 518413,378117,172804,46765, 640002,433950,213334,68684, -2699092,-34,21 ],
  [ 2000,25,5,3,4, 223737,144106,48815,17374, 354986,349252,70997,1147, 658509,422737,131702,47154, 857609,557095,171522,60103, 1058265,764456,211653,58762, -1864486,-26,15 ],
  [ 2000,25,10,3,4, 376005,358774,46041,2110, 751386,681789,62616,5800, 1470480,926118,122540,45364, 2011100,1288231,167592,60239, 2482279,1694972,206857,65609, -240121,-1,0 ],
  [ 2000,25,15,3,4, 495384,394669,44034,8952, 1241694,1148521,56441,4235, 2567336,1696221,116697,39596, 3606488,2434772,163931,53260, 4481416,3001420,203701,67273, 2077881,32,0 ],
  [ 2000,25,25,3,4, 580365,424073,42208,11367, 2265017,2037071,47435,4774, 5541328,3578403,106564,37749, 8043167,5281017,154676,53118, 10316852,6946850,198401,64808, 8920395,68,0 ],
  [ 2000,25,50,3,4, 580365,424073,41455,11164, 2265017,2037071,28551,2873, 9201039,5819341,83646,30743, 8265733,5467837,75143,25435, 10602841,7185849,96389,31064, 12249515,77,0 ],
  [ 2000,25,75,3,4, 587539,425431,41967,11579, 3320624,3061819,41508,3235, 10843585,6868262,96818,35494, 16083476,10396564,143602,50776, 21547034,14303199,192384,64677, 20718529,96,0 ],
  [ 2000,50,1,3,4, 58653,86215,58653,-27562, 80167,80190,80167,-23, 169545,102157,169545,67388, 181928,75465,181928,106463, 224634,102791,224634,121842, -3114415,-37,24 ],
  [ 2000,50,2,3,4, 109461,109129,54730,166, 157336,97339,78668,29999, 307500,220709,153750,43395, 363856,331259,181928,16299, 449267,362352,224634,43458, -2900011,-36,22 ],
  [ 2000,50,3,3,4, 160269,118706,53423,13854, 234506,202373,78169,10711, 445454,368160,148485,25765, 545784,383658,181928,54042, 673901,441013,224634,77629, -2547264,-33,20 ],
  [ 2000,50,5,3,4, 237822,218363,51888,4246, 373654,353138,74731,4103, 707671,523808,141534,36773, 902880,581176,180576,64341, 1114291,776072,222858,67644, -2120640,-29,17 ],
  [ 2000,50,10,3,4, 397086,362600,48623,4223, 788927,704271,65744,7055, 1561085,957953,130090,50261, 2117153,1404627,176429,59377, 2613495,1821377,217791,66010, 945,5,0 ],
  [ 2000,50,15,3,4, 397086,362600,35297,3065, 788927,704271,35860,3848, 2269420,1531983,103155,33520, 2160575,1445230,98208,32516, 2669420,1866243,121337,36508, 317847,11,0 ],
  [ 2000,50,25,3,4, 521921,399486,37958,8904, 1302250,1163188,27272,2912, 4967590,3229560,95531,33424, 3920278,2591895,75390,25546, 4878477,3297106,93817,30411, 4595755,49,0 ],
  [ 2000,50,50,3,4, 610753,429589,43625,12940, 2372628,2078799,29907,3704, 9671436,6134477,87922,32154, 8690290,5677140,79003,27392, 11156365,7558591,101422,32707, 13360822,80,0 ],
  [ 2000,50,75,3,4, 618248,431005,44161,13374, 3476011,3205031,43450,3387, 11394052,7212828,101733,37332, 16914655,10918239,151024,53539, 22683234,15051936,202529,68137, 22063301,99,0 ],
  [ 2000,100,1,3,4, 70990,101791,70990,-30800, 90335,81641,90335,8693, 214793,111060,214793,103733, 200176,79293,200176,120882, 247233,191410,247233,55823, -3042795,-37,23 ],
  [ 2000,100,2,3,4, 126291,112183,63146,7054, 174675,100536,87338,37070, 366404,338292,183202,14056, 400351,339089,200176,30631, 494466,387881,247233,53293, -2878210,-36,22 ],
  [ 2000,100,3,3,4, 181592,122576,60531,19672, 259016,207166,86339,17283, 518014,382325,172671,45230, 600527,394640,200176,68629, 741700,538820,247233,67626, -2236342,-30,18 ],
  [ 2000,100,5,3,4, 265992,223476,58035,9276, 410991,374795,82198,7239, 805995,542769,161199,52645, 993421,707744,198684,57136, 1226342,898607,245268,65547, -1855341,-26,15 ],
  [ 2000,100,10,3,4, 439249,370253,53786,8448, 864008,721466,72001,11879, 1742294,1190456,145191,45986, 2329259,1585713,194105,61962, 2875927,1891504,239661,82035, 389485,12,0 ],
  [ 2000,100,15,3,4, 574995,422456,51111,13559, 1423363,1206259,64698,9868, 3005400,1944190,136609,48237, 4175926,2796873,189815,62684, 5191519,3506553,235978,76589, 3568345,43,0 ],
  [ 2000,100,25,3,4, 671529,440620,48838,16793, 2587851,2340454,54196,5181, 6421669,4105478,123494,44542, 9303915,5998511,178921,63565, 11950660,7928839,229820,77343, 11361600,75,0 ],
  [ 2000,100,50,3,4, 679665,442152,48547,16965, 3766048,3488095,47471,3504, 12305658,7729510,111870,41601, 18285341,11778420,166230,59154, 24533326,16174274,223030,75991, 24466738,103,0 ],
  [ 2000,100,75,3,4, 679665,442152,48547,16965, 3786787,3494185,47335,3658, 12494985,7963246,111562,40462, 18577014,11974714,165866,58949, 24955635,16503979,222818,75461, 24616798,103,0 ],
  [ 2000,200,1,3,4, 95666,106270,95666,-10604, 110671,84845,110671,25826, 305288,220380,305288,84909, 236671,181984,236671,54687, 292432,200838,292432,91595, -3021326,-37,23 ],
  [ 2000,200,2,3,4, 159953,118293,79976,20830, 209353,194665,104677,7344, 484212,390240,242106,46986, 473342,353850,236671,59746, 584864,406647,292432,89109, -2665871,-34,21 ],
  [ 2000,200,3,3,4, 224239,143654,74746,26862, 308035,334835,102678,-8933, 663136,438035,221045,75034, 710013,512139,236671,65958, 877297,582245,292432,98350, -2138729,-29,17 ],
  [ 2000,200,5,3,4, 224635,143737,49011,17650, 308171,334835,61634,-5333, 874174,569924,174835,60850, 721078,529563,144216,38303, 892231,601245,178446,58197, -1968001,-28,16 ],
  [ 2000,200,10,3,4, 523573,398895,64111,15267, 1014170,843440,84514,14228, 2104711,1303729,175393,66749, 2753471,1795495,229456,79831, 3400791,2259123,283399,95139, 1705773,29,0 ],
  [ 2000,200,15,3,4, 523573,398895,46540,11082, 1014170,843440,46099,7760, 3154559,2065061,143389,49523, 2804068,1837802,127458,43921, 3469607,2322640,157709,52135, 2379763,34,0 ],
  [ 2000,200,25,3,4, 681142,441722,49538,17412, 1665589,1470900,34881,4077, 6481808,4118326,124650,45452, 5076585,3415155,97627,31951, 6331874,4359087,121767,37938, 7451034,62,0 ],
  [ 2000,200,50,3,4, 793080,547719,56649,17526, 3018295,2685415,38046,4196, 12493821,7945274,113580,41350, 11237638,7286239,102160,35922, 14477513,9780152,131614,42703, 18225511,91,0 ],
  [ 2000,200,75,3,4, 802500,549485,57321,18073, 4408338,3995762,55104,5157, 14696851,9219747,131222,48903, 21901732,14059212,195551,70023, 29500435,19437475,263397,89848, 30200096,111,0 ],
  [ 2500,0,1,3,4, 58627,86533,58627,-27905, 86785,82487,86785,4298, 148853,83910,148853,64943, 195006,78457,195006,116550, 241178,190325,241178,50854, -3143365,-37,24 ],
  [ 2500,0,2,3,4, 117255,111188,58627,3033, 173570,115826,86785,28872, 297706,204851,148853,46428, 390012,337317,195006,26348, 482357,369878,241178,56240, -2821784,-35,22 ],
  [ 2500,0,3,3,4, 175882,122508,58627,17792, 260356,209280,86785,17025, 446559,354991,148853,30523, 585019,392784,195006,64078, 723535,536192,241178,62448, -2394161,-32,19 ],
  [ 2500,0,5,3,4, 265383,224825,57902,8849, 417672,378537,83534,7827, 730970,530150,146194,40164, 967642,704821,193528,52564, 1196459,894458,239292,60400, -2049227,-28,16 ],
  [ 2500,0,10,3,4, 449258,387876,55011,7516, 891451,819455,74288,6000, 1663914,1180189,138660,40310, 2268773,1579453,189064,57443, 2806973,1882554,233914,77035, -144269,2,0 ],
  [ 2500,0,15,3,4, 593444,429065,52751,14611, 1481927,1240797,67360,10960, 2931732,1924860,133261,45767, 4071678,2678257,185076,63337, 5067129,3490714,230324,71655, 3512148,43,0 ],
  [ 2500,0,25,3,4, 696113,533941,50626,11794, 2726701,2415688,57104,6513, 6406788,4140137,123207,43589, 9116791,6002527,175323,59890, 11654518,7893487,224125,72328, 10699054,73,0 ],
  [ 2500,0,50,3,4, 704784,535618,50342,12083, 3995513,3709446,50364,3606, 12481441,8053706,113468,40252, 18055153,11819767,164138,56685, 23914409,16022267,217404,71747, 23136257,100,0 ],
  [ 2500,0,75,3,4, 704784,535618,50342,12083, 4017892,3716089,50224,3773, 12678652,8198341,113202,40003, 18347640,12016822,163818,56525, 24326281,16237469,217199,72222, 23587416,101,0 ],
  [ 2500,25,1,3,4, 64796,100989,64796,-36193, 91869,83138,91869,8731, 171477,88362,171477,83115, 204130,80446,204130,123684, 252478,192819,252478,59659, -3105034,-37,24 ],
  [ 2500,25,2,3,4, 125670,112716,62835,6477, 182240,117425,91120,32408, 327158,331182,163579,-2012, 408260,341532,204130,33364, 504956,390778,252478,57089, -2972659,-36,23 ],
  [ 2500,25,3,3,4, 186544,137779,62181,16255, 272611,315876,90870,-14422, 482840,375088,160947,35917, 612390,398475,204130,71305, 757434,542905,252478,71510, -2623647,-33,21 ],
  [ 2500,25,5,3,4, 279468,328248,60975,-10643, 436340,380923,87268,11083, 780132,539705,156026,48085, 1012913,715375,202583,59508, 1252484,907525,250497,68992, -2192969,-29,18 ],
  [ 2500,25,10,3,4, 470339,391702,57593,9629, 928991,828052,77416,8412, 1754519,1197659,146210,46405, 2374826,1600515,197902,64526, 2938189,2038269,244849,74993, 293611,11,0 ],
  [ 2500,25,15,3,4, 619980,433881,55109,16542, 1542483,1343200,70113,9058, 3077753,2044228,139898,46978, 4261491,2839269,193704,64646, 5303830,3554827,241083,79500, 3695135,44,0 ],
  [ 2500,25,25,3,4, 726501,539457,52836,13603, 2834312,2531266,59357,6347, 6700235,4407857,128851,44084, 9537040,6239000,183405,63424, 12199120,8248550,234598,75973, 11239569,75,0 ],
  [ 2500,25,50,3,4, 735493,541191,52535,13879, 4150081,3869037,52312,3543, 13023987,8395453,118400,42078, 18873522,12367125,171577,59149, 25031433,16834843,227558,74514, 24298818,102,0 ],
  [ 2500,25,75,3,4, 735493,541191,52535,13879, 4173279,3875916,52166,3717, 13229118,8527043,118117,41983, 19178819,12538447,171239,59289, 25462482,17053815,227344,75077, 24867870,103,0 ],
  [ 2500,50,1,3,4, 70965,102109,70965,-31144, 96953,83939,96953,13014, 194101,107328,194101,86773, 213254,97311,213254,115943, 263778,195213,263778,68565, -3067614,-37,24 ],
  [ 2500,50,2,3,4, 134085,114243,67043,9921, 190909,119023,95455,35943, 356611,336949,178305,9831, 426508,345198,213254,40655, 527556,395456,263778,66050, -2833483,-35,22 ],
  [ 2500,50,3,3,4, 197206,139715,65735,19164, 284866,332157,94955,-15764, 519120,382021,173040,45700, 639761,418943,213254,73606, 791334,565540,263778,75265, -2515179,-32,20 ],
  [ 2500,50,5,3,4, 293553,330805,64048,-8128, 455008,384809,91002,14040, 829293,549261,165859,56006, 1058184,739405,211637,63756, 1308510,919091,261702,77884, -1945140,-27,16 ],
  [ 2500,50,10,3,4, 491420,395529,60174,11742, 966532,836649,80544,10824, 1845123,1229643,153760,51290, 2480879,1636603,206740,70356, 3069405,2164724,255784,75390, 696784,17,0 ],
  [ 2500,50,15,3,4, 646517,438698,57468,18473, 1603040,1357868,72865,11144, 3223775,2086746,146535,51683, 4451303,2972129,202332,67235, 5540531,3831532,251842,77682, 4221994,47,0 ],
  [ 2500,50,25,3,4, 756889,544972,55046,15412, 2941923,2677344,61611,5541, 6993682,4491027,134494,48128, 9957289,6540303,191486,65711, 12743723,8618283,245072,79335, 12001526,77,0 ],
  [ 2500,50,50,3,4, 766201,546765,54729,15674, 4304650,3998127,54260,3864, 13566534,8724185,123332,44021, 19691890,12871303,179017,62005, 26148456,17466670,237713,78925, 25780566,105,0 ],
  [ 2500,50,75,3,4, 766201,546765,54729,15674, 4328667,4019128,54108,3869, 13779585,8871609,123032,43821, 20009999,13045145,178661,62186, 26598682,17802553,237488,78537, 26234745,105,0 ],
  [ 2500,100,1,3,4, 83303,104348,83303,-21045, 107121,85541,107121,21580, 239349,116230,239349,123118, 231501,181297,231501,50205, 286377,199902,286377,86475, -3000091,-36,23 ],
  [ 2500,100,2,3,4, 150916,117298,75458,16809, 208248,196070,104124,6089, 415515,362998,207757,26258, 463003,352578,231501,55212, 572755,404864,286377,83945, -2822413,-35,22 ],
  [ 2500,100,3,3,4, 218529,143585,72843,24981, 309375,336949,103125,-9191, 591681,410701,197227,60327, 694504,510283,231501,61407, 859132,579617,286377,93172, -2296547,-31,18 ],
  [ 2500,100,5,3,4, 321723,349255,70194,-6007, 492345,392432,98469,19983, 927617,582888,185523,68946, 1148726,757714,229745,78202, 1420560,958096,284112,92493, -1504642,-22,11 ],
  [ 2500,100,10,3,4, 533582,403182,65337,15967, 1041612,867729,86801,14490, 2026332,1293462,168861,61073, 2692985,1789086,224415,75325, 3331837,2250022,277653,90151, 1460493,26,0 ],
  [ 2500,100,15,3,4, 699591,533368,62186,14775, 1724152,1505288,78371,9948, 3515818,2367697,159810,52187, 4830928,3201370,219588,74071, 6013933,4059459,273361,88840, 4388496,48,0 ],
  [ 2500,100,25,3,4, 817665,569340,59467,18060, 3157146,2834499,66118,6757, 7580576,4843416,145780,52638, 10797788,7065054,207650,71783, 13832928,9230639,266018,88506, 13676005,81,0 ],
  [ 2500,100,50,3,4, 827619,571250,59116,18312, 4613787,4300543,58157,3948, 14651626,9379979,133197,47924, 21328626,13894387,193897,67584, 28382503,19008143,258023,85221, 28334068,108,0 ],
  [ 2500,100,75,3,4, 827619,571250,59116,18312, 4639443,4322016,57993,3968, 14880518,9544877,132862,47640, 21672358,14101770,193503,67595, 28871082,19254696,257778,85861, 28866464,109,0 ],
  [ 2500,200,1,3,4, 107978,108827,107978,-849, 127458,88744,127458,38713, 329844,346115,329844,-16271, 267997,189253,267997,78743, 331576,322970,331576,8607, -3197504,-38,24 ],
  [ 2500,200,2,3,4, 184577,123408,92289,30585, 242927,202464,121463,20231, 533323,400580,266662,66371, 535993,382265,267997,76864, 663153,439301,331576,111926, -2332486,-31,18 ],
  [ 2500,200,3,3,4, 261176,223026,87059,12717, 358395,346534,119465,3954, 736802,545061,245601,63914, 803990,547324,267997,85555, 994729,736532,331576,86066, -2206626,-30,18 ],
  [ 2500,200,5,3,4, 378063,359481,82486,4054, 567019,421861,113404,29032, 1124265,756041,224853,73645, 1329810,904790,265962,85004, 1644662,1149345,328932,99063, -1046585,-16,-36 ],
  [ 2500,200,10,3,4, 617907,431824,75662,22786, 1191774,915853,99315,22993, 2388750,1589785,199062,66580, 3117197,2092852,259766,85362, 3856700,2618141,321392,103213, 2450715,35,0 ],
  [ 2500,200,15,3,4, 805738,552634,71621,22498, 1966378,1665579,89381,13673, 4099904,2720520,186359,62699, 5590178,3696131,254099,86093, 6960737,4757266,316397,100158, 6099885,56,0 ],
  [ 2500,200,25,3,4, 939216,692269,68307,17960, 3587590,3178110,75133,8575, 8754364,5623695,168353,60205, 12478785,8209238,239977,82107, 16011339,10781682,307910,100570, 16081194,87,0 ],
  [ 2500,200,50,3,4, 950454,707749,67890,17336, 5232062,4799676,65950,5450, 16821812,10722137,152926,55452, 24602098,15875673,223655,79331, 32850598,21815219,298642,100322, 33635685,115,0 ],
  [ 2500,200,75,3,4, 950454,707749,67890,17336, 5260994,4808209,65762,5660, 17082385,10878528,152521,55392, 24997076,16187768,223188,78655, 33415883,22189742,298356,100233, 34197158,116,0 ],
  [ 3000,0,1,3,4, 70940,102427,70940,-31487, 103572,86237,103572,17335, 173409,89081,173409,84328, 226332,100303,226332,126029, 280323,198966,280323,81356, -3036830,-37,23 ],
  [ 3000,0,2,3,4, 141879,116303,70940,12788, 207144,197475,103572,4834, 346817,335756,173409,5531, 452664,351306,226332,50679, 560645,403082,280323,78781, -2978954,-36,23 ],
  [ 3000,0,3,3,4, 212819,143516,70940,23101, 310716,339064,103572,-9449, 520226,383367,173409,45620, 678996,428119,226332,83626, 840968,576988,280323,87993, -2335842,-31,19 ],
  [ 3000,0,5,3,4, 321114,350604,70061,-6434, 499026,410059,99805,17793, 852592,555603,170518,59398, 1122946,754741,224589,73641, 1390677,953846,278135,87366, -1708612,-24,13 ],
  [ 3000,0,10,3,4, 543592,420805,66562,15035, 1069055,891868,89088,14766, 1947953,1268680,162329,56606, 2632499,1782777,219375,70810, 3262882,2241022,271907,85155, 1194019,23,0 ],
  [ 3000,0,15,3,4, 718040,539977,63826,15828, 1782716,1553712,81033,10409, 3442150,2348217,156461,49724, 4726681,3191214,214849,69794, 5889543,4043570,267707,83908, 4128642,46,0 ],
  [ 3000,0,25,3,4, 842249,678491,61254,11910, 3295996,3026618,69026,5641, 7565695,4953425,145494,50236, 10610664,7070419,204051,68082, 13536786,9196087,260323,83475, 12401760,78,0 ],
  [ 3000,0,50,3,4, 852738,680545,60910,12299, 4843252,4520544,61049,4068, 14827409,9704176,134795,46575, 21098439,13856777,191804,65833, 27763586,18744395,252396,81993, 27137748,107,0 ],
  [ 3000,0,75,3,4, 852738,680545,60910,12299, 4870548,4528686,60882,4273, 15064185,9780122,134502,47179, 21442983,14160205,191455,65025, 28241729,19102676,252158,81599, 27712933,108,0 ],
  [ 3000,25,1,3,4, 77108,103547,77108,-26438, 108656,87038,108656,21618, 196033,108047,196033,87986, 235456,182499,235456,52957, 291622,201361,291622,90262, -3095831,-37,24 ],
  [ 3000,25,2,3,4, 150295,117831,75147,16232, 215813,199074,107907,8370, 376269,341523,188135,17373, 470911,354971,235456,57970, 583245,407761,291622,87742, -2839778,-35,22 ],
  [ 3000,25,3,3,4, 223481,217152,74494,2110, 322971,341460,107657,-6163, 556506,390449,185502,55352, 706367,513918,235456,64150, 874867,584001,291622,96955, -2563022,-33,20 ],
  [ 3000,25,5,3,4, 335199,353161,73134,-3919, 517694,413945,103539,20750, 901754,579674,180351,64416, 1168217,859129,233643,61818, 1446703,981134,289341,93114, -1623982,-24,12 ],
  [ 3000,25,10,3,4, 564673,424631,69144,17148, 1106596,900465,92216,17178, 2038557,1377664,169880,55074, 2738552,1803888,228213,77889, 3394098,2283696,282842,92534, 1502533,26,0 ],
  [ 3000,25,15,3,4, 564673,424631,50193,12448, 1106596,900465,50300,9370, 2038557,1377664,92662,30041, 2801294,1862400,127332,42677, 3473688,2348177,157895,51160, 1519548,27,0 ],
  [ 3000,25,25,3,4, 872637,684007,63465,13719, 3403607,3142196,71280,5475, 7859142,5144145,151137,52211, 11030913,7263763,212133,72445, 14081389,9566821,270796,86819, 13171253,80,0 ],
  [ 3000,25,50,3,4, 883447,699455,63103,13142, 4997821,4679985,62998,4006, 15369956,10045923,139727,48400, 21916807,14484592,199244,67566, 28880610,19489012,262551,85378, 28175652,108,0 ],
  [ 3000,25,75,3,4, 883447,699455,63103,13142, 5025935,4688363,62824,4220, 15614652,10123339,139417,49030, 22274163,14667054,198876,67921, 29377929,19722351,262303,86211, 29067664,109,0 ],
  [ 3000,50,1,3,4, 83277,104666,83277,-21389, 113740,87839,113740,25901, 218656,112498,218656,106158, 244580,184338,244580,60241, 302922,203705,302922,99217, -3002807,-36,23 ],
  [ 3000,50,2,3,4, 158710,119358,79355,19676, 224483,200672,112241,11905, 405722,347290,202861,29216, 489159,373613,244580,57773, 605844,412440,302922,96702, -2722705,-34,21 ],
  [ 3000,50,3,3,4, 234142,219087,78047,5019, 335225,343856,111742,-2877, 592787,412047,197596,60247, 733739,519459,244580,71427, 908766,606736,302922,100677, -2422368,-32,19 ],
  [ 3000,50,5,3,4, 349284,355717,76207,-1404, 536363,417831,107273,23706, 950916,695279,190183,51127, 1213488,868683,242698,68961, 1502728,993201,300546,101905, -1599427,-23,12 ],
  [ 3000,50,10,3,4, 585754,428458,71725,19261, 1144136,909063,95345,19589, 2129162,1395134,177430,61169, 2844605,1948386,237050,74685, 3525314,2523192,293776,83510, 1698501,28,0 ],
  [ 3000,50,15,3,4, 771113,549610,68543,19689, 1903829,1670782,86538,10593, 3734193,2447618,169736,58481, 5106306,3391653,232105,77939, 6362945,4384438,289225,89932, 5119455,52,0 ],
  [ 3000,50,25,3,4, 903025,702859,65675,14558, 3511219,3183774,73533,6857, 8152589,5305815,156781,54746, 11451163,7580193,220215,74442, 14625991,9921533,281269,90470, 14017710,82,0 ],
  [ 3000,50,50,3,4, 914155,705029,65297,14938, 5152390,4735225,64946,5258, 15912502,10374655,144659,50344, 22735175,14988771,206683,70422, 29997633,20105168,272706,89932, 29902354,111,0 ],
  [ 3000,50,75,3,4, 914155,705029,65297,14938, 5181323,4831575,64767,4372, 16165118,10453390,144331,50998, 23105342,15188678,206298,70685, 30514130,20471038,272448,89670, 30445936,111,0 ],
  [ 3000,100,1,3,4, 95615,106906,95615,-11291, 123908,89441,123908,34468, 263904,212915,263904,50989, 262827,188066,262827,74761, 325522,321534,325522,3987, -3107390,-37,24 ],
  [ 3000,100,2,3,4, 175541,122413,87770,26564, 241822,203869,120911,18976, 464626,373339,232313,45644, 525654,380993,262827,72330, 651043,437519,325522,106762, -2489027,-32,20 ],
  [ 3000,100,3,3,4, 255466,222957,85155,10836, 359735,348648,119912,3696, 665347,440727,221782,74874, 788481,545468,262827,81005, 976565,733903,325522,80887, -2187947,-30,18 ],
  [ 3000,100,5,3,4, 377454,360830,82354,3627, 573699,499453,114740,14849, 1049240,728906,209848,64067, 1304030,901817,260806,80443, 1614779,1145146,322956,93927, -1440753,-21,10 ],
  [ 3000,100,10,3,4, 627916,436110,76888,23486, 1219217,1044492,101601,14560, 2310371,1563503,192531,62239, 3056712,2086393,254726,80860, 3787746,2592819,315646,99577, 1923487,30,0 ],
  [ 3000,100,15,3,4, 824187,673447,73261,13399, 2024942,1712353,92043,14209, 4026236,2609505,183011,64397, 5485931,3687175,249360,81762, 6836347,4726906,310743,95884, 5594715,54,0 ],
  [ 3000,100,25,3,4, 963800,713890,70095,18175, 3726441,3445429,78041,5885, 8739483,5656705,168067,59284, 12291661,8024486,236378,82061, 15715197,10548910,302215,99352, 15527612,86,0 ],
  [ 3000,100,50,3,4, 975573,716177,69684,18528, 5461527,5037791,68843,5341, 16997595,10953449,154524,54947, 24371911,15931397,221563,76732, 32231681,21662161,293015,96087, 32794398,114,0 ],
  [ 3000,100,75,3,4, 975573,716177,69684,18528, 5492099,5134613,68651,4469, 17266052,11218173,154161,53999, 24767701,16245203,221140,76094, 32786530,22036172,292737,95985, 32841718,114,0 ],
  [ 3000,200,1,3,4, 120290,111384,120290,8906, 144244,92644,144244,51600, 354399,351285,354399,3114, 299322,195973,299322,103350, 370721,347133,370721,23588, -3025347,-37,23 ],
  [ 3000,200,2,3,4, 209202,141859,104601,33671, 276500,314463,138250,-18982, 582434,423936,291217,79249, 598645,395904,299322,101370, 741441,539765,370721,100838, -2440372,-32,19 ],
  [ 3000,200,3,3,4, 298113,331565,99371,-11151, 408755,370618,136252,12713, 810469,560422,270156,83349, 897967,583959,299322,104669, 1112162,779128,370721,111011, -2147166,-29,18 ],
  [ 3000,200,5,3,4, 433794,371057,94646,13688, 648373,528882,129675,23898, 1245887,873009,249177,74576, 1485114,953110,297023,106401, 1838881,1290914,367776,109593, -741117,-12,0 ],
  [ 3000,200,10,3,4, 712240,536453,87213,21525, 1369379,1166466,114115,16909, 2672788,1753775,222732,76584, 3480924,2404636,290077,89691, 4312610,2960438,359384,112681, 2609219,36,0 ],
  [ 3000,200,15,3,4, 930334,692713,82696,21122, 2267168,1976994,103053,13190, 4610322,2960827,209560,74977, 6245181,4101628,283872,97434, 7783151,5311123,353780,112365, 7178638,61,0 ],
  [ 3000,200,25,3,4, 1085352,749290,78935,24441, 4156885,3686041,87055,9861, 9913271,6452999,190640,66544, 13972658,9167370,268705,92409, 17893607,12083482,344108,111733, 18596076,92,0 ],
  [ 3000,200,50,3,4, 1098408,751809,78458,24757, 6079802,5538424,76636,6824, 19167781,12372606,174253,61774, 27645383,17991490,251322,87763, 36699775,24580828,333634,110172, 38134034,121,0 ],
  [ 3000,200,75,3,4, 1098408,751809,78458,24757, 6113650,5622306,76421,6142, 19467918,12474824,173821,62438, 28092419,18314725,250825,87301, 37331331,25053498,333315,109624, 38724952,121,0 ],
  [ 1000,0,1,0,5, 5125,76125,5125,-71001, 10969,51673,10969,-40704, 29577,45821,29577,-16245, 46206,33047,46206,13159, 63497,38210,63497,25287, -3819043,-42,28 ],
  [ 1000,0,2,0,5, 10250,77037,5125,-33394, 21938,54348,10969,-16205, 59153,51673,29577,3740, 92413,57581,46206,17416, 126993,67530,63497,29732, -3664799,-41,27 ],
  [ 1000,0,3,0,5, 15375,77949,5125,-20858, 32907,57022,10969,-8038, 88730,72039,29577,5564, 138619,67189,46206,23810, 190490,81229,63497,36420, -3510778,-40,26 ],
  [ 1000,0,5,0,5, 23199,79341,5062,-12249, 52153,75726,10431,-4715, 143186,82605,28637,12116, 228277,180907,45655,9474, 313675,320080,62735,-1281, -3500108,-40,26 ],
  [ 1000,0,10,0,5, 39282,82207,4810,-5256, 106825,90080,8902,1395, 311703,313044,25975,-112, 527614,380892,43968,12227, 726625,539200,60552,15619, -3176777,-38,24 ],
  [ 1000,0,15,0,5, 51898,84457,4613,-2894, 171156,195375,7780,-1101, 529493,384069,24068,6610, 932745,588304,42398,15656, 1300133,920048,59097,17277, -2498673,-32,20 ],
  [ 1000,0,25,0,5, 60888,99400,4428,-2801, 295865,349113,6196,-1115, 1072292,726954,20621,6641, 2003844,1286216,38535,13801, 2945521,2043229,56645,17352, -1235601,-18,-26 ],
  [ 1000,0,50,0,5, 61648,99537,4403,-2706, 416836,412300,5254,57, 1920874,1232475,17462,6258, 3759396,2475893,34176,11668, 5936231,4030414,53966,17326, 1284963,25,0 ],
  [ 1000,0,75,0,5, 61648,99537,4403,-2706, 418961,412924,5237,75, 1946527,1237211,17380,6333, 3813930,2486602,34053,11851, 6034862,4050681,53883,17716, 1458212,27,0 ],
  [ 1000,25,1,0,5, 7509,76583,7509,-69074, 12744,51945,12744,-39200, 41894,48391,41894,-6497, 50379,33936,50379,16443, 69295,55152,69295,14143, -3787150,-42,27 ],
  [ 1000,25,2,0,5, 13165,77597,6582,-32216, 24794,54889,12397,-15047, 74156,69305,37078,2426, 100758,59402,50379,20678, 138589,70117,69295,34236, -3637919,-41,27 ],
  [ 1000,25,3,0,5, 18820,78611,6273,-19930, 36844,57833,12281,-6996, 106419,75703,35473,10239, 151138,69841,50379,27099, 207884,100703,69295,35727, -3444258,-39,26 ],
  [ 1000,25,5,0,5, 27454,80159,5990,-11499, 58009,77031,11602,-3804, 165862,87283,33172,15716, 248874,185259,49775,12723, 342306,326327,68461,3196, -3390434,-39,25 ],
  [ 1000,25,10,0,5, 45189,83341,5533,-4672, 118248,92890,9854,2113, 350027,335398,29169,1219, 575122,390916,47927,15351, 792866,569309,66072,18630, -3012404,-36,23 ],
  [ 1000,25,15,0,5, 59095,85839,5253,-2377, 189264,200068,8603,-491, 588289,396027,26740,8739, 1016764,714330,46217,13747, 1418496,961340,64477,20780, -2332090,-31,19 ],
  [ 1000,25,25,0,5, 68995,100957,5018,-2325, 327298,371618,6854,-928, 1183306,840698,22756,6589, 2185402,1434232,42027,14446, 3212544,2215748,61780,19169, -1070025,-16,-18 ],
  [ 1000,25,50,0,5, 69831,101108,4988,-2234, 461352,424860,5815,460, 2115747,1300463,19234,7412, 4103636,2657307,37306,13148, 6472390,4404488,58840,18799, 2059669,33,0 ],
  [ 1000,25,75,0,5, 69831,101108,4988,-2234, 463705,425554,5796,477, 2143980,1382706,19143,6797, 4163288,2792497,37172,12239, 6579895,4426626,58749,19226, 1890502,31,0 ],
  [ 1000,50,1,0,5, 9893,77041,9893,-67148, 14520,52216,14520,-37696, 54211,65476,54211,-11265, 54552,34874,54552,19678, 75093,56472,75093,18620, -3777585,-42,27 ],
  [ 1000,50,2,0,5, 16080,78157,8040,-31039, 27651,55430,13825,-13890, 89160,72422,44580,8369, 109104,61172,54552,23966, 150186,72653,75093,38766, -3577539,-40,26 ],
  [ 1000,50,3,0,5, 22266,79272,7422,-19002, 40782,58644,13594,-5954, 124108,79368,41369,14914, 163656,72494,54552,30387, 225279,104505,75093,40258, -3366564,-39,25 ],
  [ 1000,50,5,0,5, 31709,80976,6918,-10749, 63865,78335,12773,-2894, 188539,106475,37708,16413, 269470,189660,53894,15962, 370936,348295,74187,4528, -3325434,-39,25 ],
  [ 1000,50,10,0,5, 51097,84476,6257,-4087, 129671,95700,10806,2831, 388350,343238,32363,3759, 622630,400889,51886,18478, 859108,583696,71592,22951, -2803357,-35,22 ],
  [ 1000,50,15,0,5, 66292,100558,5893,-3046, 207371,204611,9426,125, 647085,422501,29413,10208, 1100783,747073,50036,16078, 1536859,1002832,69857,24274, -2115729,-28,17 ],
  [ 1000,50,25,0,5, 77101,102514,5607,-1848, 358731,380238,7513,-450, 1294321,877592,24891,8014, 2366961,1595424,45518,14837, 3479566,2501408,66915,18811, -784447,-11,0 ],
  [ 1000,50,50,0,5, 78013,102680,5572,-1762, 505868,525156,6376,-243, 2310621,1536836,21006,7034, 4447877,2962607,40435,13502, 7008550,4763291,63714,20411, 1997630,32,0 ],
  [ 1000,50,75,0,5, 78013,102680,5572,-1762, 508448,525918,6356,-218, 2341433,1542586,20906,7133, 4512647,2975405,40291,13725, 7124928,4802971,63615,20732, 2192225,34,0 ],
  [ 1000,100,1,0,5, 14662,77957,14662,-63295, 18071,52759,18071,-34688, 78845,70615,78845,8230, 62898,36651,62898,26246, 86689,59014,86689,27675, -3691453,-41,27 ],
  [ 1000,100,2,0,5, 21910,79277,10955,-28683, 33363,56513,16682,-11575, 119166,78656,59583,20255, 125795,64713,62898,30541, 173378,77727,86689,47826, -3456780,-40,26 ],
  [ 1000,100,3,0,5, 29158,80596,9719,-17146, 48656,60267,16219,-3870, 159487,101211,53162,19425, 188693,77848,62898,36948, 260067,195941,86689,21376, -3304411,-38,25 ],
  [ 1000,100,5,0,5, 40218,82610,8775,-9249, 75577,80944,15115,-1073, 233892,115830,46778,23612, 310664,306823,62133,768, 428197,360788,85639,13482, -3266161,-38,25 ],
  [ 1000,100,10,0,5, 62912,86745,7704,-2918, 152517,115206,12710,3109, 464997,373282,38750,7643, 717646,516170,59804,16790, 991591,741233,82633,20863, -2694780,-34,21 ],
  [ 1000,100,15,0,5, 80685,103323,7172,-2012, 243587,332232,11072,-4029, 764677,536283,34758,10382, 1268821,877539,57674,17786, 1773585,1281787,80618,22354, -2323612,-30,19 ],
  [ 1000,100,25,0,5, 93314,105628,6787,-896, 421597,411364,8829,214, 1516350,936714,29161,11147, 2730077,1781147,52501,18249, 4013612,2762666,77185,24057, 199249,10,0 ],
  [ 1000,100,50,0,5, 94378,105823,6741,-817, 594900,564163,7499,387, 2700368,1735147,24549,8775, 5136358,3353536,46694,16207, 8080869,5511339,73462,23359, 3318910,42,0 ],
  [ 1000,100,75,0,5, 94378,105823,6741,-817, 597936,565062,7474,411, 2736340,1741911,24432,8879, 5211363,3383399,46530,16321, 8214994,5638541,73348,23004, 3464186,43,0 ],
  [ 1000,200,1,0,5, 24199,79789,24199,-55590, 25173,53845,25173,-28672, 128114,95410,128114,32704, 79589,55232,79589,24357, 109882,64147,109882,45735, -3574794,-40,26 ],
  [ 1000,200,2,0,5, 33570,81516,16785,-23973, 44789,58678,22395,-6945, 179179,105639,89589,36770, 159178,71845,79589,43667, 219763,103594,109882,58085, -3259935,-38,25 ],
  [ 1000,200,3,0,5, 42941,83243,14314,-13434, 64405,77396,21468,-4330, 230244,115719,76748,38175, 238767,183742,79589,18342, 329645,324191,109882,1818, -3258479,-38,25 ],
  [ 1000,200,5,0,5, 57236,85878,12488,-6249, 99000,86162,19800,2568, 324598,332105,64920,-1501, 393051,339756,78610,10659, 542718,401997,108544,28144, -3316118,-39,25 ],
  [ 1000,200,10,0,5, 86542,104620,10597,-2214, 198209,200148,16517,-162, 618291,419155,51524,16595, 907678,586218,75640,26788, 1256557,914106,104713,28538, -2306856,-30,19 ],
  [ 1000,200,15,0,5, 109471,108851,9731,55, 316017,351004,14364,-1590, 999861,719197,45448,12757, 1604897,1167123,72950,19899, 2247038,1628755,102138,28104, -1781360,-25,14 ],
  [ 1000,200,25,0,5, 125741,111856,9145,1010, 547330,533580,11462,288, 1960408,1267190,37700,13331, 3456311,2400064,66468,20312, 5081702,3496342,97725,30488, 973122,21,0 ],
  [ 1000,200,50,0,5, 127107,112108,9079,1071, 772963,806491,9743,-423, 3479861,2342518,31635,10339, 6513319,4246655,59212,20606, 10225507,6963004,92959,29659, 4823236,51,0 ],
  [ 1000,200,75,0,5, 127107,112108,9079,1071, 776910,821551,9711,-558, 3526153,2351160,31484,10491, 6608797,4389156,59007,19818, 10395126,7097640,92814,29442, 4829892,51,0 ],
  [ 1500,0,1,0,5, 8034,76765,8034,-68731, 16022,52992,16022,-36970, 39236,47971,39236,-8735, 60533,36288,60533,24245, 83582,58481,83582,25101, -3764491,-42,27 ],
  [ 1500,0,2,0,5, 16068,78316,8034,-31124, 32044,56986,16022,-12471, 78472,70488,39236,3992, 121067,64113,60533,28477, 167165,76780,83582,45192, -3566849,-40,26 ],
  [ 1500,0,3,0,5, 24102,79867,8034,-18588, 48065,60979,16022,-4305, 117708,78489,39236,13073, 181600,76912,60533,34896, 250747,194481,83582,18755, -3384511,-39,25 ],
  [ 1500,0,5,0,5, 36367,82235,7935,-10008, 76547,82183,15309,-1127, 190678,93176,38136,19501, 298917,305342,59783,-1285, 412908,358474,82582,10887, -3352661,-39,25 ],
  [ 1500,0,10,0,5, 61570,100442,7539,-4760, 159421,118353,13285,3422, 420212,351551,35018,5722, 690385,513153,57532,14769, 956654,736433,79721,18352, -2854707,-35,22 ],
  [ 1500,0,15,0,5, 81337,104263,7230,-2038, 259259,338613,11785,-3607, 721235,531100,32783,8642, 1222271,872650,55558,15892, 1711173,1273353,77781,19901, -2483076,-32,20 ],
  [ 1500,0,25,0,5, 95416,106987,6939,-842, 459582,425672,9625,710, 1492435,940627,28701,10612, 2647366,1790484,50911,16479, 3868029,2644612,74385,23527, 57947,7,0 ],
  [ 1500,0,50,0,5, 96606,107218,6900,-758, 657916,705620,8293,-601, 2737505,1773743,24886,8761, 5032909,3386509,45754,14967, 7782202,5362157,70747,22000, 2762501,39,0 ],
  [ 1500,0,75,0,5, 96606,107218,6900,-758, 661405,706680,8268,-566, 2775945,1887371,24785,7934, 5108138,3402383,45608,15230, 7911440,5518682,70638,21364, 2664790,38,0 ],
  [ 1500,25,1,0,5, 10418,77223,10418,-66805, 17797,53264,17797,-35466, 51553,50541,51553,1012, 64706,37177,64706,27530, 89380,59752,89380,29629, -3721425,-41,27 ],
  [ 1500,25,2,0,5, 18983,78876,9491,-29946, 34900,57527,17450,-11314, 93475,73605,46738,9935, 129412,65884,64706,31764, 178761,79317,89380,49722, -3506469,-40,26 ],
  [ 1500,25,3,0,5, 27547,80529,9182,-17660, 52003,75675,17334,-7891, 135398,82004,45133,17798, 194119,79564,64706,38185, 268141,198234,89380,23302, -3350401,-39,25 ],
  [ 1500,25,5,0,5, 40622,83053,8863,-9258, 82403,83487,16481,-217, 213355,112368,42671,20197, 319514,309743,63903,1954, 441539,364771,88308,15353, -3276488,-38,25 ],
  [ 1500,25,10,0,5, 67478,101576,8263,-4175, 170844,195014,14237,-2014, 458536,373906,38211,7052, 737893,538153,61491,16645, 1022895,766542,85241,21363, -2946218,-36,23 ],
  [ 1500,25,15,0,5, 88533,105645,7870,-1521, 277367,343306,12608,-2997, 780031,543059,35456,10771, 1306289,905192,59377,18232, 1829536,1314645,83161,23404, -2178522,-29,18 ],
  [ 1500,25,25,0,5, 103523,108544,7529,-365, 491015,522027,10283,-649, 1603450,1160420,30836,8520, 2828925,1952176,54402,16861, 4135051,2930721,79520,23160, -355715,-2,0 ],
  [ 1500,25,50,0,5, 104788,108789,7485,-286, 702432,718180,8854,-199, 2932379,1947781,26658,8951, 5377150,3676883,48883,15457, 8318361,5736731,75621,23469, 3131755,41,0 ],
  [ 1500,25,75,0,5, 104788,108789,7485,-286, 706149,733195,8827,-338, 2973398,2032716,26548,8399, 5457497,3693301,48728,15752, 8456473,5894577,75504,22874, 3075600,41,0 ],
  [ 1500,50,1,0,5, 12802,77681,12802,-64878, 19573,53535,19573,-33962, 63870,67626,63870,-3756, 68879,53092,68879,15787, 95179,61073,95179,34106, -3733963,-41,27 ],
  [ 1500,50,2,0,5, 21898,79436,10949,-28769, 37756,58068,18878,-10156, 108479,76722,54239,15878, 137758,67654,68879,35052, 190357,81854,95179,54252, -3446090,-39,26 ],
  [ 1500,50,3,0,5, 30993,81191,10331,-16732, 55940,76486,18647,-6849, 153087,85668,51029,22473, 206637,97193,68879,36481, 285536,202036,95179,27833, -3294810,-38,25 ],
  [ 1500,50,5,0,5, 44876,83870,9791,-8508, 88259,84792,17652,693, 236032,194046,47206,8397, 340111,329071,68022,2208, 470169,371018,94034,19830, -3365415,-39,25 ],
  [ 1500,50,10,0,5, 73385,102711,8986,-3591, 182267,197824,15189,-1296, 496859,381745,41405,9593, 785401,548126,65450,19773, 1089137,780929,90761,25684, -2737171,-34,22 ],
  [ 1500,50,15,0,5, 95730,107027,8509,-1004, 295475,347999,13431,-2387, 838827,569532,38128,12241, 1390308,922808,63196,21250, 1947899,1340316,88541,27617, -1874192,-26,15 ],
  [ 1500,50,25,0,5, 111629,110101,8118,111, 522448,530648,10941,-172, 1714464,1197314,32970,9945, 3010483,2085216,57894,17794, 4402074,3103291,84655,24977, 52046,7,0 ],
  [ 1500,50,50,0,5, 112970,110361,8069,186, 746948,744626,9415,29, 3127252,2078104,28430,9538, 5721390,3858246,52013,16938, 8854521,6095034,80496,25086, 3731052,45,0 ],
  [ 1500,50,75,0,5, 112970,110361,8069,186, 750892,745824,9386,63, 3170851,2086696,28311,9680, 5806855,3890685,51847,17109, 9001506,6225490,80371,24786, 3910032,46,0 ],
  [ 1500,100,1,0,5, 17571,78596,17571,-61025, 23124,54078,23124,-30954, 88505,72766,88505,15739, 77225,54869,77225,22356, 106775,63614,106775,43161, -3647832,-41,27 ],
  [ 1500,100,2,0,5, 27728,80555,13864,-26414, 43469,59151,21735,-7841, 138485,82956,69243,27764, 154449,71195,77225,41627, 213550,102598,106775,55476, -3336503,-39,25 ],
  [ 1500,100,3,0,5, 37885,82514,12628,-14876, 63814,78109,21271,-4765, 188466,107512,62822,26985, 231674,182855,77225,16273, 320325,322782,106775,-819, -3372080,-39,25 ],
  [ 1500,100,5,0,5, 53385,85504,11648,-7008, 99970,87401,19994,2514, 281385,217915,56277,12694, 381304,337824,76261,8696, 527430,399233,105486,25639, -3190741,-38,24 ],
  [ 1500,100,10,0,5, 85200,104980,10433,-2422, 205112,203445,17093,139, 573506,411940,47792,13464, 880417,583100,73368,24776, 1221620,909206,101802,26035, -2445589,-32,20 ],
  [ 1500,100,15,0,5, 110123,109791,9789,29, 331690,371270,15077,-1799, 956419,713864,43474,11025, 1558346,1096903,70834,20975, 2184625,1620271,99301,25652, -1887987,-26,15 ],
  [ 1500,100,25,0,5, 127842,113215,9298,1064, 585314,561773,12258,493, 1936493,1270952,37240,12799, 3373600,2205657,64877,22460, 4936119,3477689,94925,28047, 1018030,22,0 ],
  [ 1500,100,50,0,5, 129335,113504,9238,1131, 835980,857483,10538,-271, 3516999,2382464,31973,10314, 6409871,4264652,58272,19502, 9926840,6927362,90244,27268, 4491336,49,0 ],
  [ 1500,100,75,0,5, 129335,113504,9238,1131, 840380,858818,10505,-230, 3565758,2392071,31837,10479, 6505572,4407640,58085,18732, 10091572,6977780,90103,27802, 4602495,50,0 ],
  [ 1500,200,1,0,5, 27108,80428,27108,-53320, 30226,55164,30226,-24938, 137773,97560,137773,40214, 93916,58472,93916,35444, 129967,68747,129967,61220, -3509069,-40,26 ],
  [ 1500,200,2,0,5, 39388,82795,19694,-21703, 54895,61316,27447,-3211, 198498,109939,99249,44279, 187832,78326,93916,54753, 259935,196575,129967,31680, -3188219,-38,24 ],
  [ 1500,200,3,0,5, 51668,85161,17223,-11164, 79564,81353,26521,-597, 259223,213684,86408,15179, 281748,193514,93916,29411, 389902,353713,129967,12063, -3282476,-38,25 ],
  [ 1500,200,5,0,5, 63891,100267,13940,-7937, 123394,81353,24679,8408, 362443,357174,72489,1054, 429163,222588,85833,41315, 530718,411220,106144,23899, -3015112,-36,23 ],
  [ 1500,200,10,0,5, 108831,109518,13326,-84, 250804,319037,20900,-5686, 726800,533313,60567,16124, 1070449,746481,89204,26997, 1486586,997648,123882,40745, -2350546,-31,19 ],
  [ 1500,200,15,0,5, 138910,115320,12348,2097, 404121,390042,18369,640, 1191603,867729,54164,14722, 1894422,1277528,86110,28041, 2658077,1869319,120822,35853, -1047322,-16,-25 ],
  [ 1500,200,25,0,5, 160268,119442,11656,2969, 711047,714490,14891,-72, 2380551,1585413,45780,15291, 4099833,2809548,78843,24813, 6004210,4226486,115466,34187, 1743493,30,0 ],
  [ 1500,200,50,0,5, 162064,119789,11576,3020, 1014043,921611,12782,1165, 4296493,2885286,39059,12829, 7786833,5265731,70789,22919, 12071478,8295247,109741,34329, 6701102,60,0 ],
  [ 1500,200,75,0,5, 162064,119789,11576,3020, 1019354,937106,12742,1028, 4355571,2896770,38889,13025, 7903005,5289460,70563,23335, 12271704,8549970,109569,33230, 6875527,60,0 ],
  [ 2000,0,1,0,5, 10943,77404,10943,-66461, 21075,54311,21075,-33237, 48896,50122,48896,-1226, 74860,54506,74860,20355, 103668,63081,103668,40587, -3720870,-41,27 ],
  [ 2000,0,2,0,5, 21886,79595,10943,-28854, 42149,59624,21075,-8737, 97791,74788,48896,11502, 149721,70595,74860,39563, 207336,101652,103668,52842, -3446572,-39,26 ],
  [ 2000,0,3,0,5, 32829,81785,10943,-16319, 63224,78821,21075,-5199, 146687,84790,48896,20632, 224581,181918,74860,14221, 311004,321322,103668,-3440, -3452179,-40,26 ],
  [ 2000,0,5,0,5, 49535,85129,10808,-7766, 100941,88639,20188,2460, 238171,195261,47634,8582, 369557,336343,73911,6643, 512141,396919,102428,23044, -3277242,-38,25 ],
  [ 2000,0,10,0,5, 83858,105340,10268,-2630, 212016,206742,17668,440, 528721,390209,44060,11543, 853156,579932,71096,22769, 1186683,904256,98890,23536, -2550822,-33,21 ],
  [ 2000,0,15,0,5, 110775,110732,9847,4, 347363,377652,15789,-1377, 912977,694167,41499,9946, 1511796,1091964,68718,19083, 2122212,1498696,96464,28342, -1933319,-27,16 ],
  [ 2000,0,25,0,5, 129944,114574,9450,1118, 623299,680431,13053,-1196, 1912578,1273364,36780,12293, 3290888,2200018,63286,20978, 4790536,3458586,92126,25614, 501302,15,0 ],
  [ 2000,0,50,0,5, 131563,114899,9397,1190, 898996,894589,11332,56, 3554137,2422561,32310,10287, 6306423,4282649,57331,18398, 9628173,6807990,87529,25638, 4262754,48,0 ],
  [ 2000,0,75,0,5, 131563,114899,9397,1190, 903849,896086,11298,97, 3605363,2447496,32191,10338, 6402346,4426173,57164,17644, 9788018,6941751,87393,25413, 4281864,48,0 ],
  [ 2000,25,1,0,5, 13327,77862,13327,-64535, 22850,54583,22850,-31733, 61213,67206,61213,-5994, 79033,55444,79033,23589, 109466,64402,109466,45064, -3711304,-41,27 ],
  [ 2000,25,2,0,5, 24801,80154,12400,-27677, 45005,60165,22503,-7580, 112795,77905,56397,17445, 158066,72365,79033,42851, 218932,104189,109466,57372, -3386193,-39,25 ],
  [ 2000,25,3,0,5, 36275,82447,12092,-15391, 67161,79632,22387,-4157, 164376,88454,54792,25307, 237100,184570,79033,17510, 328398,325125,109466,1091, -3374486,-39,25 ],
  [ 2000,25,5,0,5, 53789,85946,11736,-7016, 106797,89944,21359,3371, 260848,199938,52170,12182, 390154,340694,78031,9892, 540771,403166,108154,27521, -3167568,-38,24 ],
  [ 2000,25,10,0,5, 89766,106474,10992,-2046, 223439,313902,18620,-7539, 567044,411063,47254,12998, 900664,698416,75055,16854, 1252924,918193,104410,27894, -2861971,-35,23 ],
  [ 2000,25,15,0,5, 117972,112114,10486,521, 365470,382345,16612,-767, 971773,720640,44171,11415, 1595815,1189888,72537,18451, 2240575,1653129,101844,26702, -1839318,-26,15 ],
  [ 2000,25,25,0,5, 138050,116131,10040,1594, 654732,702937,13712,-1010, 2023593,1387108,38915,12240, 3472447,2441518,66778,19826, 5057559,3547325,97261,29043, 588641,16,0 ],
  [ 2000,25,50,0,5, 138050,116145,9861,1565, 654732,706639,8253,-654, 2053889,1408470,18672,5867, 3472447,2441568,31568,9372, 5057559,3547375,45978,13729, 597112,17,0 ],
  [ 2000,25,75,0,5, 139745,116470,9982,1663, 948593,922600,11857,325, 3802816,2578326,33954,10933, 6751705,4608581,60283,19135, 10333051,7317596,92259,26924, 4886248,51,0 ],
  [ 2000,50,1,0,5, 15711,78320,15711,-62609, 24625,54854,24625,-30229, 73530,69776,73530,3754, 83206,56333,83206,26874, 115264,65673,115264,49591, -3668238,-41,27 ],
  [ 2000,50,2,0,5, 27716,80714,13858,-26499, 47862,60706,23931,-6422, 127798,81022,63899,23388, 166412,74136,83206,46138, 230529,190506,115264,20011, -3385547,-39,25 ],
  [ 2000,50,3,0,5, 39720,83109,13240,-14463, 71098,80444,23699,-3115, 182066,106633,60689,25144, 249618,187273,83206,20782, 345793,344649,115264,381, -3341465,-39,25 ],
  [ 2000,50,5,0,5, 58044,86764,12664,-6266, 112652,91248,22530,4281, 283524,204616,56705,15782, 410751,345046,82150,13141, 569402,409413,113880,31998, -3057894,-37,24 ],
  [ 2000,50,10,0,5, 95674,107609,11715,-1461, 234862,316713,19572,-6821, 605368,418903,50447,15539, 948172,708390,79014,19982, 1319166,948252,109930,30909, -2664097,-33,21 ],
  [ 2000,50,15,0,5, 95674,107609,8504,-1061, 234862,316713,10676,-3720, 902433,691892,41020,9570, 967075,728296,43958,10854, 1346794,970939,61218,17084, -2608306,-33,21 ],
  [ 2000,50,25,0,5, 146157,117688,10630,2070, 686165,711557,14370,-532, 2134607,1530052,41050,11626, 3654005,2509727,70269,22005, 5324581,3833485,102396,28675, 766813,19,0 ],
  [ 2000,50,50,0,5, 147928,118042,10566,2135, 988028,933596,12454,686, 3943884,2726921,35853,11063, 6994904,4769362,63590,20232, 10700492,7511557,97277,28990, 5230099,53,0 ],
  [ 2000,50,75,0,5, 147928,118042,10566,2135, 988028,933664,12350,680, 3944496,2727060,35219,10870, 6994904,4769362,62454,19871, 10700492,7511557,95540,28473, 5230969,53,0 ],
  [ 2000,100,1,0,5, 20480,79236,20480,-58756, 28176,55397,28176,-27221, 98164,74916,98164,23249, 91552,58109,91552,33442, 126861,68214,126861,58646, -3582107,-40,26 ],
  [ 2000,100,2,0,5, 33546,81834,16773,-24144, 53575,75674,26787,-11050, 157804,101621,78902,28091, 183103,77726,91552,52688, 253721,195579,126861,29071, -3341872,-39,25 ],
  [ 2000,100,3,0,5, 46612,84432,15537,-12607, 78973,82066,26324,-1031, 217444,113962,72481,34494, 274655,192578,91552,27359, 380582,352254,126861,9443, -3186078,-38,24 ],
  [ 2000,100,5,0,5, 66553,101734,14521,-7676, 124364,93707,24873,6131, 328878,334536,65776,-1132, 451944,354449,90389,19499, 626663,438278,125333,37677, -3183291,-38,24 ],
  [ 2000,100,10,0,5, 107489,109878,13162,-293, 257708,336219,21476,-6543, 682015,525947,56835,13006, 1043188,743363,86932,24985, 1451649,992698,120971,38246, -2532862,-32,21 ],
  [ 2000,100,15,0,5, 139562,116260,12405,2071, 419793,410309,19082,431, 1148161,847881,52189,13649, 1847872,1272589,83994,26149, 2595664,1845114,117985,34116, -1205696,-18,-33 ],
  [ 2000,100,25,0,5, 162370,120801,11809,3023, 749032,742683,15687,133, 2356636,1603690,45320,14480, 4017122,2803959,77252,23330, 5858627,4207883,112666,31745, 1476303,27,0 ],
  [ 2000,100,50,0,5, 164292,121185,11735,3079, 1077060,1150803,13576,-930, 4333631,2923732,39397,12817, 7683385,5283728,69849,21815, 11772811,8259105,107026,31943, 5808800,56,0 ],
  [ 2000,100,75,0,5, 164292,121185,11735,3079, 1082824,1152574,13535,-872, 4395176,2936181,39243,13027, 7799780,5322920,69641,22115, 11968150,8316520,106858,32604, 6146256,57,0 ],
  [ 2000,200,1,0,5, 30017,81067,30017,-51050, 35278,56483,35278,-21205, 147433,99710,147433,47723, 108243,61713,108243,46530, 150053,73347,150053,76706, -3443345,-39,26 ],
  [ 2000,200,2,0,5, 45206,84073,22603,-19434, 65000,77839,32500,-6420, 217817,114090,108909,51864, 216486,99785,108243,58351, 300106,318816,150053,-9355, -3203088,-38,24 ],
  [ 2000,200,3,0,5, 60395,87079,20132,-8895, 94722,85310,31574,3137, 288201,220135,96067,22689, 324729,311697,108243,4344, 450159,367514,150053,27549, -3245375,-38,24 ],
  [ 2000,200,5,0,5, 83572,105003,18234,-4676, 147788,98925,29558,9773, 419584,367760,83917,10365, 534331,386881,106866,29490, 741184,562767,148237,35683, -2871108,-35,22 ],
  [ 2000,200,10,0,5, 131119,114416,16055,2045, 303399,347460,25283,-3672, 835309,571821,69609,21957, 1233220,878592,102768,29552, 1716615,1278511,143051,36509, -2033516,-28,17 ],
  [ 2000,200,15,0,5, 168348,121789,14964,4139, 492224,502931,22374,-487, 1383345,924745,62879,20845, 2183947,1561673,99270,28285, 3069116,2207253,139505,39176, -663020,-10,0 ],
  [ 2000,200,25,0,5, 168348,122013,12244,3370, 492224,507767,10308,-326, 1395649,942044,26839,8723, 2183947,1561734,41999,11966, 3069116,2207311,59021,16573, -690960,-10,0 ],
  [ 2000,200,50,0,5, 197022,140807,14073,4015, 1255123,1228666,15821,333, 5113125,3426403,46483,15334, 9060347,6161521,82367,26353, 13917449,9710870,126522,38242, 8042732,65,0 ],
  [ 2000,200,75,0,5, 197022,140807,14073,4015, 1261798,1230712,15772,389, 5184989,3546930,46295,14626, 9197213,6285698,82118,25996, 14148282,9905030,126324,37886, 7989520,65,0 ],
  [ 2500,0,1,0,5, 13852,78044,13852,-64192, 26127,55630,26127,-29503, 58555,52272,58555,6284, 89187,57746,89187,31441, 123754,67682,123754,56072, -3655145,-41,27 ],
  [ 2500,0,2,0,5, 27704,80873,13852,-26585, 52255,76147,26127,-11946, 117110,78938,58555,19086, 178375,77077,89187,50649, 247507,194583,123754,26462, -3418440,-39,26 ],
  [ 2500,0,3,0,5, 41556,83703,13852,-14049, 78382,82778,26127,-1466, 175666,91240,58555,28142, 267562,191641,89187,25307, 371261,350794,123754,6822, -3266178,-38,25 ],
  [ 2500,0,5,0,5, 41556,83888,9067,-9236, 78382,83302,15676,-984, 176835,91506,35367,17066, 267562,191650,53512,15183, 371261,350801,74252,4092, -3266554,-38,25 ],
  [ 2500,0,10,0,5, 106147,110238,12998,-501, 264611,339515,22051,-6242, 637230,427216,53102,17501, 1015927,740196,84661,22978, 1416712,987748,118059,35747, -2461598,-32,20 ],
  [ 2500,0,15,0,5, 140214,117201,12463,2046, 435466,416690,19794,853, 1104719,842698,50214,11910, 1801321,1267700,81878,24256, 2533252,1836680,115148,31662, -1365160,-20,-42 ],
  [ 2500,0,25,0,5, 140214,117201,10197,1674, 435466,416690,9120,393, 1824723,1245726,35091,11135, 1863653,1327657,35839,10308, 2623311,1921029,50448,13505, -632815,-9,0 ],
  [ 2500,0,50,0,5, 166520,135916,11894,2186, 1140076,1187759,14371,-601, 4370768,2963828,39734,12790, 7579937,5301874,68909,20710, 11474144,8223663,104310,29550, 5465790,54,0 ],
  [ 2500,0,75,0,5, 166520,135916,11894,2186, 1146293,1189692,14329,-542, 4434781,3097656,39596,11939, 7696555,5342104,68719,21022, 11664596,8281141,104148,30209, 5525554,55,0 ],
  [ 2500,25,1,0,5, 174352,137410,174352,36942, 1190918,1216206,1190918,-25287, 1410164,944458,1410164,465706, 7816254,5317890,7816254,2498364, 11873279,8355230,11873279,3518049, 3929806,46,0 ],
  [ 2500,25,2,0,5, 30619,81433,15309,-25407, 55111,76688,27555,-10789, 132114,82055,66057,25029, 186721,78847,93360,53937, 259104,197119,129552,30992, -3358061,-39,25 ],
  [ 2500,25,3,0,5, 45002,84365,15001,-13121, 82319,83590,27440,-423, 193355,109419,64452,27979, 280081,194343,93360,28579, 388655,354647,129552,11336, -3221984,-38,24 ],
  [ 2500,25,5,0,5, 64544,100969,14082,-7947, 132702,96230,26540,7294, 255708,312253,51142,-11309, 376754,199554,75351,35440, 521443,355267,104289,33235, -3187365,-38,24 ],
  [ 2500,25,10,0,5, 112054,111372,13721,84, 276034,342326,23003,-5524, 675553,512056,56296,13625, 1063435,750169,88620,26105, 1482953,1002136,123579,40068, -2429049,-31,20 ],
  [ 2500,25,15,0,5, 112054,111372,9960,61, 276034,342326,12547,-3013, 1018182,733007,46281,12962, 1086278,771028,49376,14330, 1515891,1041777,68904,21551, -2153551,-29,18 ],
  [ 2500,25,25,0,5, 172578,137054,12551,2584, 818449,853196,17140,-728, 2443736,1721346,46995,13892, 4115969,2866178,79153,24034, 5980066,4262498,115001,33030, 1352729,26,0 ],
  [ 2500,25,50,0,5, 174703,137488,12479,2658, 1184592,1214205,14932,-373, 4565642,3137716,41506,12981, 7924177,5483788,72038,22185, 12010304,8582516,109185,31162, 5962708,57,0 ],
  [ 2500,25,75,0,5, 174703,137488,12479,2658, 1184592,1214205,14807,-370, 4610504,3223973,41165,12380, 7927871,5484634,70785,21815, 12015821,8599468,107284,30503, 5863873,56,0 ],
  [ 2500,50,1,0,5, 18620,78959,18620,-60339, 29678,56173,29678,-26495, 83190,71926,83190,11263, 97533,59573,97533,37960, 135350,70273,135350,65077, -3602514,-41,27 ],
  [ 2500,50,2,0,5, 33534,81993,16767,-24229, 57967,77229,28984,-9631, 147117,85172,73558,30972, 195066,95594,97533,49736, 270700,199656,135350,35522, -3319785,-39,25 ],
  [ 2500,50,3,0,5, 48448,85027,16149,-12193, 86256,84401,28752,619, 211044,113084,70348,32653, 292599,305455,97533,-4285, 406050,358449,135350,15867, -3304365,-39,25 ],
  [ 2500,50,5,0,5, 71212,102994,15537,-6934, 137046,97555,27409,7898, 331017,335751,66203,-947, 481391,376697,96278,20939, 668635,532288,133727,27269, -3165782,-38,24 ],
  [ 2500,50,10,0,5, 71212,103405,8720,-3942, 137046,173173,11421,-3011, 337006,337109,28084,-9, 481391,376717,40116,8723, 668635,532303,55720,11361, -3396298,-39,25 ],
  [ 2500,50,15,0,5, 117962,112507,10486,485, 287457,345136,13066,-2622, 1070743,758215,48670,14206, 1134310,876456,51560,11721, 1583198,1169552,71964,18802, -2171990,-29,18 ],
  [ 2500,50,25,0,5, 117962,113048,8579,357, 287457,499929,6020,-4450, 1090484,777198,20971,6025, 1134310,876532,21814,4957, 1583198,1169622,30446,7953, -2662807,-33,22 ],
  [ 2500,50,50,0,5, 182885,139059,13063,3130, 1229108,1226766,15493,30, 4760515,3268189,43277,13567, 8268417,5788588,75167,22544, 12546463,8843450,114059,33664, 6492870,59,0 ],
  [ 2500,50,75,0,5, 182885,139059,13063,3130, 1235780,1228836,15447,87, 4829687,3296981,43122,13685, 8395271,5830406,74958,22901, 12754662,9032931,113881,33230, 6738726,60,0 ],
  [ 2500,100,1,0,5, 23389,79875,23389,-56486, 33229,56716,33229,-23487, 107824,77066,107824,30758, 105879,61350,105879,44528, 146946,72815,146946,74132, -3516382,-40,26 ],
  [ 2500,100,2,0,5, 39364,83113,19682,-21874, 63680,78312,31840,-7316, 177123,105922,88562,35601, 211758,99185,105879,56286, 293892,204779,146946,44557, -3232525,-38,25 ],
  [ 2500,100,3,0,5, 55339,86350,18446,-10337, 94131,86023,31377,2703, 246423,197413,82141,16337, 317636,310760,105879,2292, 440839,366054,146946,24928, -3325475,-39,25 ],
  [ 2500,100,5,0,5, 79721,104628,17394,-5434, 148758,100164,29752,9719, 376370,345106,75274,6253, 522585,385400,104517,27437, 725896,560453,145179,33089, -2957608,-36,23 ],
  [ 2500,100,10,0,5, 129777,114776,15891,1837, 310303,350757,25859,-3371, 790524,564605,65877,18827, 1205959,875474,100497,27540, 1681678,1273611,140140,34006, -2172250,-29,18 ],
  [ 2500,100,15,0,5, 169000,122729,15022,4113, 507897,523197,23086,-695, 1339903,919413,60905,19113, 2137397,1556784,97154,26391, 3006704,2198769,136668,36724, -866068,-13,-10 ],
  [ 2500,100,25,0,5, 196898,141725,14320,4013, 912749,892942,19115,415, 2776780,1923413,53400,16411, 4660644,3308877,89628,25996, 6781134,4809915,130406,37908, 2235998,34,0 ],
  [ 2500,100,50,0,5, 199250,142202,14232,4075, 1318140,1265772,16615,660, 5150262,3572550,46821,14343, 8956898,6180017,81426,25244, 13618782,9591998,123807,36607, 7568773,63,0 ],
  [ 2500,100,75,0,5, 199250,142202,14232,4075, 1325268,1267980,16566,716, 5224594,3602356,46648,14484, 9093988,6304232,81196,24909, 13844728,9853330,123614,35637, 7680062,63,0 ],
  [ 2500,200,1,0,5, 32926,81707,32926,-48781, 40331,57802,40331,-17471, 157092,101860,157092,55233, 122570,64954,122570,57616, 170139,77948,170139,92191, -3377620,-39,25 ],
  [ 2500,200,2,0,5, 51024,85352,25512,-17164, 75106,80477,37553,-2686, 237136,132905,118568,52116, 245140,186624,122570,29258, 340278,343738,170139,-1730, -3234836,-38,24 ],
  [ 2500,200,3,0,5, 69122,102334,23041,-11071, 109880,89118,36627,6921, 317180,332635,105727,-5152, 367710,337046,122570,10221, 510416,397635,170139,37594, -3381548,-39,25 ],
  [ 2500,200,5,0,5, 69239,102360,15107,-7226, 109919,89118,21984,4160, 426671,369898,85334,11355, 373679,338464,74736,7043, 519374,399766,103875,23922, -3204027,-38,24 ],
  [ 2500,200,10,0,5, 153408,119314,18785,4175, 355995,375884,29666,-1657, 943818,716378,78651,18953, 1395991,945871,116333,37510, 1946644,1363004,162220,48637, -1725078,-25,14 ],
  [ 2500,200,15,0,5, 179366,141566,15944,3360, 580322,541819,26378,1750, 1574266,1179326,71558,17952, 2464059,1753034,112003,32319, 3455272,2545886,157058,41336, -525972,-7,0 ],
  [ 2500,200,25,0,5, 197787,141594,14384,4087, 580327,541820,12153,806, 2749358,1917709,52872,15993, 2545710,1815961,48956,14034, 3591678,2651591,69071,18079, 596517,16,0 ],
  [ 2500,200,50,0,5, 229324,219652,16380,691, 1038481,1045659,13090,-90, 5113663,3546433,46488,14248, 5512052,3829805,50110,15293, 8045585,5731931,73142,21033, 4325012,48,0 ],
  [ 2500,200,75,0,5, 231979,220188,16570,842, 1504242,1524468,18803,-253, 6014407,4091040,53700,17173, 10491421,7200979,93673,29379, 16024860,11327550,143079,41940, 9179563,69,0 ],
  [ 3000,0,1,0,5, 16761,78683,16761,-61922, 31180,56949,31180,-25769, 68215,68937,68215,-722, 103514,61037,103514,42477, 143839,72332,143839,71508, -3622920,-41,27 ],
  [ 3000,0,2,0,5, 33522,82152,16761,-24315, 62360,78785,31180,-8212, 136429,83239,68215,26595, 207029,98535,103514,54247, 287679,203783,143839,41948, -3309094,-39,25 ],
  [ 3000,0,3,0,5, 50283,85621,16761,-11779, 93540,86735,31180,2268, 204644,112205,68215,30813, 310543,309873,103514,223, 431518,364645,143839,22291, -3262578,-38,25 ],
  [ 3000,0,5,0,5, 75870,104254,16553,-6193, 149728,115288,29946,6888, 333157,336817,66631,-732, 510838,383969,102168,25374, 710607,542468,142121,33628, -3110019,-37,24 ],
  [ 3000,0,10,0,5, 128435,115136,15727,1629, 317207,354054,26434,-3071, 745739,542874,62145,16905, 1178698,872307,98225,25533, 1646741,1268661,137228,31507, -2277483,-30,19 ],
  [ 3000,0,15,0,5, 169652,137006,15080,2902, 523569,529429,23799,-266, 1296460,899715,58930,18034, 2090847,1551995,95038,24493, 2944291,2190435,133831,34266, -1046725,-16,-26 ],
  [ 3000,0,25,0,5, 198999,143084,14473,4067, 950733,907250,19911,911, 2752865,1927325,52940,15876, 4577933,3222930,88037,26058, 6635551,4791313,127607,35466, 2164416,33,0 ],
  [ 3000,0,50,0,5, 201477,143597,14391,4134, 1381156,1376729,17410,56, 5187400,3612646,47158,14316, 8853450,6198014,80486,24140, 13320116,9556406,121092,34216, 7046675,61,0 ],
  [ 3000,0,75,0,5, 201477,143597,14391,4134, 1388737,1379098,17359,120, 5264199,3643266,47002,14473, 8990763,6322715,80275,23822, 13541174,9620380,120903,35007, 7299516,62,0 ],
  [ 3000,25,1,0,5, 209660,215192,209660,-5532, 1433481,1183077,1433481,250404, 5401070,3730005,5401070,1671065, 9340121,6548070,9340121,2792051, 14086207,10079394,14086207,4006812, 8161048,65,0 ],
  [ 3000,25,2,0,5, 36437,82712,18219,-23137, 65216,79326,32608,-7055, 151433,86356,75716,32539, 215375,100305,107687,57535, 299275,319410,149638,-10068, -3329346,-39,25 ],
  [ 3000,25,3,0,5, 53729,86283,17910,-10851, 97477,87547,32492,3310, 222334,192870,74111,9821, 323062,327502,107687,-1480, 448913,368447,149638,26822, -3383485,-39,25 ],
  [ 3000,25,5,0,5, 80125,105071,17482,-5443, 155584,116592,31117,7798, 355833,341494,71167,2868, 531435,388320,106287,28623, 739237,564386,147847,34970, -3011518,-36,23 ],
  [ 3000,25,10,0,5, 134343,116270,16450,2213, 328630,370749,27386,-3510, 784062,550564,65339,19458, 1226206,897257,102184,27412, 1712982,1282999,142749,35832, -2134123,-29,17 ],
  [ 3000,25,15,0,5, 176849,138388,15720,3419, 541677,534122,24622,343, 1355256,926188,61603,19503, 2174865,1584588,98858,26831, 3062654,2231777,139212,37767, -775672,-12,0 ],
  [ 3000,25,25,0,5, 207106,144641,15062,4543, 982166,929756,20569,1098, 2863880,2041069,55075,15823, 4759492,3355970,91529,26991, 6902573,4963832,132742,37283, 2352095,35,0 ],
  [ 3000,25,50,0,5, 209660,216869,14976,-515, 1425672,1507525,17971,-1032, 5382273,3755984,48930,14784, 9197691,6394404,83615,25484, 13856275,9914259,125966,35837, 6969764,61,0 ],
  [ 3000,25,75,0,5, 209660,216869,14976,-515, 1433481,1509962,17919,-956, 5461652,3772596,48765,15081, 9340121,6548252,83394,24927, 14086207,10079506,125770,35774, 7159907,62,0 ],
  [ 3000,50,1,0,5, 21530,79599,21530,-58069, 34731,57492,34731,-22761, 92849,74076,92849,18773, 111860,62814,111860,49046, 155436,74873,155436,80562, -3536789,-40,26 ],
  [ 3000,50,2,0,5, 39352,83272,19676,-21960, 68073,79868,34036,-5897, 166436,103988,83218,31224, 223720,182433,111860,20644, 310871,321997,155436,-5563, -3420991,-39,25 ],
  [ 3000,50,3,0,5, 57175,86945,19058,-9923, 101415,88358,33805,4352, 240023,196534,80008,14496, 335580,330155,111860,1809, 466307,387921,155436,26129, -3316965,-39,25 ],
  [ 3000,50,5,0,5, 84379,105888,18410,-4693, 161440,117897,32288,8709, 378510,346171,75702,6468, 552031,392672,110406,31872, 767868,570633,153574,39447, -2901845,-36,23 ],
  [ 3000,50,10,0,5, 140250,117405,17174,2797, 340053,373560,28338,-2792, 822386,572918,68532,20789, 1273714,907280,106143,30536, 1779224,1313108,148269,38843, -1969750,-27,16 ],
  [ 3000,50,15,0,5, 184045,139771,16360,3936, 559785,538815,25445,953, 1414052,952662,64275,20972, 2258884,1617180,102677,29168, 3181017,2257448,144592,41980, -493445,-6,0 ],
  [ 3000,50,25,0,5, 215212,217898,15652,-195, 1013600,938376,21227,1575, 2974894,2077962,57210,17249, 4941050,3408702,95020,29468, 7169596,5165711,137877,38536, 2563498,37,0 ],
  [ 3000,50,50,0,5, 217842,218440,15560,-43, 1470188,1520086,18532,-629, 5577147,3915507,50701,15106, 9541931,6684727,86745,25975, 14392435,10288783,130840,37306, 7372518,62,0 ],
  [ 3000,50,75,0,5, 217842,218440,15560,-43, 1473509,1521147,18419,-595, 5632134,3941475,50287,15095, 9546405,6685802,85236,25541, 14399158,10306065,128564,36545, 7443556,63,0 ],
  [ 3000,100,1,0,5, 26298,80514,26298,-54216, 38282,58035,38282,-19753, 117483,79216,117483,38267, 120206,64591,120206,55615, 167032,77415,167032,89617, -3450657,-40,26 ],
  [ 3000,100,2,0,5, 45182,84391,22591,-19605, 73786,80950,36893,-3582, 196442,110222,98221,43110, 240412,185974,120206,27219, 334064,342741,167032,-4339, -3311405,-39,25 ],
  [ 3000,100,3,0,5, 64066,101605,21355,-12513, 109289,89830,36430,6486, 275401,218378,91800,19008, 360617,335659,120206,8319, 501096,395726,167032,35123, -3249770,-38,25 ],
  [ 3000,100,5,0,5, 92889,107522,20267,-3193, 173152,194356,34630,-4241, 423863,370041,84773,10764, 593225,416401,118645,35365, 825128,583177,165026,48390, -2971882,-36,23 ],
  [ 3000,100,10,0,5, 152066,119674,18620,3966, 362898,379181,30242,-1357, 899033,709162,74919,15823, 1368730,942754,114061,35498, 1911707,1358104,159309,46134, -1863811,-26,15 ],
  [ 3000,100,15,0,5, 198439,142535,17639,4969, 596000,562086,27091,1542, 1531645,1173994,69620,16257, 2426922,1748146,110315,30853, 3417743,2537403,155352,40015, -620084,-9,0 ],
  [ 3000,100,25,0,5, 231425,221012,16831,757, 1076466,1147702,22544,-1492, 3196923,2150100,61479,20131, 5304167,3717911,102003,30505, 7703641,5523938,148147,41917, 2701655,38,0 ],
  [ 3000,100,50,0,5, 234207,221583,16729,902, 1559220,1559092,19654,2, 5966894,4113817,54244,16846, 10230412,7090633,93004,28543, 15464754,11007521,140589,40520, 8692592,67,0 ],
  [ 3000,100,75,0,5, 234207,221583,16729,902, 1567711,1561736,19596,75, 6054012,4146466,54054,17032, 10388196,7219512,92752,28292, 15721306,11207740,140369,40300, 8918665,68,0 ],
  [ 3000,200,1,0,5, 35835,82346,35835,-46511, 45384,59121,45384,-13737, 166752,104010,166752,62742, 136897,68195,136897,68702, 190224,82548,190224,107677, -3311895,-39,25 ],
  [ 3000,200,2,0,5, 56842,86631,28421,-14894, 85211,83115,42606,1048, 256455,214205,128228,21125, 273794,193106,136897,40344, 380449,352938,190224,13756, -3279884,-38,25 ],
  [ 3000,200,3,0,5, 77850,104252,25950,-8801, 125038,93075,41679,10655, 346159,339085,115386,2358, 410691,346769,136897,21308, 570673,411436,190224,53079, -3184373,-38,24 ],
  [ 3000,200,5,0,5, 109907,110791,23980,-193, 196575,199573,39315,-600, 514569,403266,102914,22261, 675612,514165,135122,32289, 939650,736976,187930,40535, -2777017,-35,22 ],
  [ 3000,200,10,0,5, 175696,124212,21514,6304, 408590,390423,34049,1514, 1052327,755036,87694,24774, 1558762,1186442,129897,31027, 2176673,1643917,181389,44396, -1524539,-22,11 ],
  [ 3000,200,15,0,5, 227225,219763,20198,663, 668431,685208,30383,-763, 1766829,1249358,80310,23521, 2762998,1956923,125591,36640, 3891195,2785951,176873,50238, -265077,0,0 ],
  [ 3000,200,25,0,5, 263851,328106,19189,-4673, 1202199,1194568,25177,160, 3640981,2466061,70019,22595, 6030400,4214842,115969,34915, 8771732,6245425,168687,48583, 3521037,44,0 ],
  [ 3000,200,50,0,5, 263851,328106,18847,-4590, 1202199,1194568,15154,96, 5800567,3968719,52732,16653, 6177760,4341305,56161,16695, 9000613,6571229,81824,22085, 4981742,52,0 ],
  [ 3000,200,75,0,5, 266936,328736,19067,-4414, 1746686,1712374,21834,429, 6843825,4742700,61106,18760, 11785630,8226719,105229,31776, 17901438,12668789,159834,46720, 10230819,72,0 ],
  [ 1000,0,1,1,5, 9111,76835,9111,-67724, 17585,53178,17585,-35593, 42590,48397,42590,-5807, 62892,36506,62892,26386, 82934,58065,82934,24869, -3746297,-41,27 ],
  [ 1000,0,2,1,5, 18222,78455,9111,-30117, 35170,57358,17585,-11094, 85181,71340,42590,6921, 125784,64548,62892,30618, 165869,75948,82934,44960, -3530461,-40,26 ],
  [ 1000,0,3,1,5, 27332,80076,9111,-17581, 52755,75423,17585,-7556, 127771,79617,42590,16051, 188676,77564,62892,37037, 248803,193183,82934,18540, -3373512,-39,25 ],
  [ 1000,0,5,1,5, 41243,82552,8999,-9013, 83716,83015,16743,140, 206645,109653,41329,19398, 311303,306578,62261,945, 410286,356555,82057,10746, -3299249,-39,25 ],
  [ 1000,0,10,1,5, 69834,100983,8551,-3814, 172237,119677,14353,4380, 453063,355124,37755,8162, 723591,516568,60299,17252, 954521,732449,79543,18506, -2674277,-34,21 ],
  [ 1000,0,15,1,5, 92264,104983,8201,-1131, 277163,340172,12598,-2864, 774275,536158,35194,10824, 1284853,879093,58402,18444, 1713723,1251552,77897,21008, -2179305,-29,18 ],
  [ 1000,0,25,1,5, 108246,107839,7872,30, 483096,426669,10117,1182, 1588368,961037,30546,12064, 2785138,1803138,53560,18885, 3910246,2636841,75197,24489, 571606,16,0 ],
  [ 1000,0,50,1,5, 109597,108082,7828,108, 684517,705483,8628,-264, 2887567,1775558,26251,10109, 5277285,3402776,47975,17041, 7941622,5342647,72197,23627, 3700888,45,0 ],
  [ 1000,0,75,1,5, 109597,108082,7828,108, 688059,706524,8601,-231, 2927392,1903469,26137,9142, 5355392,3418659,47816,17292, 8075362,5499435,72101,22999, 3580982,44,0 ],
  [ 1000,25,1,1,5, 12526,77490,12526,-64965, 20243,53602,20243,-33360, 58016,66128,58016,-8112, 68572,37765,68572,30806, 90507,59776,90507,30732, -3722630,-41,27 ],
  [ 1000,25,2,1,5, 22580,79292,11290,-28356, 39560,58204,19780,-9322, 104474,75343,52237,14565, 137143,66959,68572,35092, 181015,79263,90507,50876, -3448137,-39,26 ],
  [ 1000,25,3,1,5, 32635,81095,10878,-16153, 58876,76690,19625,-5938, 150932,84409,50311,22174, 205715,81176,68572,41513, 271522,198152,90507,24457, -3266031,-38,25 ],
  [ 1000,25,5,1,5, 47983,83846,10469,-7825, 92923,85060,18585,1573, 237032,192913,47406,8824, 339391,312515,67878,5375, 447735,364729,89547,16601, -3321912,-39,25 ],
  [ 1000,25,10,1,5, 79513,102841,9736,-2857, 190462,197982,15872,-627, 506342,380525,42195,10485, 788749,545277,65729,20289, 1041541,767074,86795,22872, -2677517,-34,21 ],
  [ 1000,25,15,1,5, 104234,107282,9265,-271, 306299,347678,13923,-1881, 857767,553121,38989,13848, 1400600,918273,63664,21924, 1869741,1300999,84988,25852, -1729754,-24,14 ],
  [ 1000,25,25,1,5, 121834,110449,8861,828, 534250,528386,11188,123, 1750237,1191052,33658,10754, 3037549,2074828,58414,18514, 4264720,2941745,82014,25442, 305850,12,0 ],
  [ 1000,25,50,1,5, 123320,110717,8809,900, 757456,739910,9548,221, 3177981,2045491,28891,10295, 5760764,3736848,52371,18399, 8658920,5968347,78717,24460, 4199507,48,0 ],
  [ 1000,25,75,1,5, 123320,110717,8809,900, 761378,741066,9517,254, 3221816,2068140,28766,10301, 5846206,3849012,52198,17832, 8804693,6014082,78613,24916, 4288597,48,0 ],
  [ 1000,50,1,1,5, 15941,78146,15941,-62206, 22901,54026,22901,-31126, 73441,69343,73441,4098, 74251,53952,74251,20299, 98080,61436,98080,36644, -3687567,-41,27 ],
  [ 1000,50,2,1,5, 26939,80130,13469,-26595, 43949,59049,21975,-7550, 123767,79347,61883,22210, 148503,69370,74251,39566, 196161,82578,98080,56792, -3365813,-39,25 ],
  [ 1000,50,3,1,5, 37937,82113,12646,-14725, 64998,77958,21666,-4320, 174093,103716,58031,23459, 222754,99815,74251,40980, 294241,203171,98080,30357, -3214153,-38,24 ],
  [ 1000,50,5,1,5, 54723,85141,11940,-6637, 102130,87105,20426,3005, 267419,199172,53484,13649, 367479,333429,73496,6810, 485184,388574,97037,19322, -3201354,-38,24 ],
  [ 1000,50,10,1,5, 89191,104700,10921,-1899, 208687,202437,17391,521, 559622,391411,46635,14018, 853908,558960,71159,24579, 1128560,785979,94047,28548, -2380200,-31,19 ],
  [ 1000,50,15,1,5, 116204,109581,10329,589, 335436,355184,15247,-898, 941259,690650,42784,11391, 1516347,958003,68925,25379, 2025759,1366667,92080,29959, -1570254,-23,-49 ],
  [ 1000,50,25,1,5, 135422,113059,9849,1626, 585404,542367,12260,901, 1912106,1238168,36771,12960, 3289960,2142273,63268,22071, 4619194,3148781,88831,28277, 1109495,23,0 ],
  [ 1000,50,50,1,5, 137042,113353,9789,1692, 830395,848187,10467,-224, 3468394,2132374,31531,12146, 6244244,4070421,56766,19762, 9376218,6396677,85238,27087, 5026588,52,0 ],
  [ 1000,50,75,1,5, 137042,113353,9789,1692, 834696,849457,10434,-185, 3516240,2155810,31395,12147, 6337020,4184080,56581,19223, 9534025,6444948,85125,27581, 5139291,53,0 ],
  [ 1000,100,1,1,5, 22771,79458,22771,-56687, 28216,54874,28216,-26658, 104291,75774,104291,28517, 85611,56372,85611,29239, 113227,64757,113227,48469, -3573233,-40,26 ],
  [ 1000,100,2,1,5, 35656,81804,17828,-23074, 52728,60741,26364,-4006, 162353,101869,81177,30242, 171221,74242,85611,48490, 226453,188708,113227,18872, -3305572,-39,25 ],
  [ 1000,100,3,1,5, 48541,84149,16180,-11869, 77240,80492,25747,-1084, 220415,113300,73472,35705, 256832,187347,85611,23162, 339680,326198,113227,4494, -3198347,-38,24 ],
  [ 1000,100,5,1,5, 68203,101066,14881,-7170, 120545,91045,24109,5900, 328193,332257,65639,-813, 423656,346004,84731,15530, 560081,405622,112016,30892, -3227256,-38,24 ],
  [ 1000,100,10,1,5, 108549,108418,13292,16, 245138,315696,20428,-5880, 666182,426198,55515,19999, 984225,709813,82019,22868, 1302600,922789,108550,31651, -2398772,-31,20 ],
  [ 1000,100,15,1,5, 140144,114179,12457,2308, 393710,384081,17896,438, 1108242,753457,50375,16127, 1747841,1225230,79447,23755, 2337796,1662481,106263,30696, -1200700,-18,-35 ],
  [ 1000,100,25,1,5, 162597,118278,11825,3223, 687712,688565,14402,-18, 2235844,1528313,42997,13606, 3794782,2496435,72977,24968, 5328142,3660271,102464,32074, 1498939,27,0 ],
  [ 1000,100,50,1,5, 162597,118278,11614,3166, 687712,688565,8669,-11, 3489846,2155362,31726,12132, 3868001,2559086,35164,11899, 5438575,3878312,49442,14184, 2872626,39,0 ],
  [ 1000,100,75,1,5, 164488,118624,11749,3276, 981333,904655,12267,958, 4105087,2591352,36653,13515, 7318649,4869694,65345,21866, 10992689,7474792,98149,31410, 6611879,59,0 ],
  [ 1000,200,1,1,5, 36430,82081,36430,-45651, 38848,56570,38848,-17722, 165992,103151,165992,62841, 108329,61261,108329,47068, 143519,71450,143519,72069, -3378066,-39,25 ],
  [ 1000,200,2,1,5, 53090,85152,26545,-16031, 70286,78008,35143,-3861, 239526,132249,119763,53638, 216659,98913,108329,58873, 287038,201967,143519,42536, -3075463,-37,24 ],
  [ 1000,200,3,1,5, 69749,101559,23250,-10603, 101725,85412,33908,5438, 313060,330033,104353,-5658, 324988,310956,108329,4677, 430557,362444,143519,22704, -3449738,-40,26 ],
  [ 1000,200,5,1,5, 95163,106244,20763,-2418, 157373,99226,31475,11629, 449740,371811,89948,15586, 536009,384780,107202,30246, 709877,537819,141975,34412, -2738430,-34,22 ],
  [ 1000,200,10,1,5, 147263,115853,18032,3846, 318038,347400,26503,-2447, 879301,575621,73275,25307, 1244860,874906,103738,30830, 1650678,1242340,137557,34028, -1833202,-26,15 ],
  [ 1000,200,15,1,5, 188025,123375,16713,5747, 510257,501841,23194,383, 1442208,927191,65555,23410, 2210829,1555841,100492,29772, 2961868,2140969,134630,37314, -379953,-4,0 ],
  [ 1000,200,25,1,5, 216948,142053,15778,5447, 892329,845961,18688,971, 2883320,1914038,55448,18640, 4804426,3269689,92393,29514, 6746038,4713161,129732,39094, 2980655,40,0 ],
  [ 1000,200,50,1,5, 219378,142502,15670,5491, 1268028,1205029,15984,794, 5210875,3293137,47372,17434, 9145122,6090330,83137,27771, 13680004,9416167,124364,38762, 9043875,68,0 ],
  [ 1000,200,75,1,5, 219378,142502,15670,5491, 1274607,1206986,15933,845, 5282782,3321299,47168,17513, 9281905,6117684,82874,28252, 13910016,9495331,124197,39417, 9427711,70,0 ],
  [ 1500,0,1,1,5, 14282,77971,14282,-63689, 25680,55240,25680,-29560, 56500,51494,56500,5006, 82393,55898,82393,26495, 109169,64078,109169,45091, -3671319,-41,27 ],
  [ 1500,0,2,1,5, 28565,80729,14282,-26082, 51361,75366,25680,-12003, 113000,77384,56500,17808, 164785,73380,82393,45703, 218337,103595,109169,57371, -3391055,-39,25 ],
  [ 1500,0,3,1,5, 42847,83486,14282,-13546, 77041,81608,25680,-1522, 169500,88908,56500,26864, 247178,186096,82393,20361, 327506,324311,109169,1065, -3303529,-39,25 ],
  [ 1500,0,5,1,5, 64652,101033,14106,-7938, 122873,92999,24575,5975, 275228,201921,55046,14661, 407645,343513,81529,12826, 540092,402119,108018,27595, -3093535,-37,24 ],
  [ 1500,0,10,1,5, 109458,109690,13403,-28, 257183,334876,21432,-6474, 611093,417672,50924,16118, 946920,705576,78910,20112, 1256763,916331,104730,28369, -2606498,-33,21 ],
  [ 1500,0,15,1,5, 144599,116484,12853,2499, 420220,408326,19101,541, 1055368,733474,47971,14632, 1683920,1218523,76542,21154, 2255627,1651312,102528,27469, -1395115,-21,-47 ],
  [ 1500,0,25,1,5, 169629,121327,12337,3513, 751284,739844,15734,240, 2213073,1536539,42559,13010, 3680776,2488875,70784,22921, 5135063,3551794,98751,30447, 1265616,25,0 ],
  [ 1500,0,50,1,5, 171743,121737,12267,3572, 1081552,1147484,13633,-831, 4122053,2756597,37473,12413, 7069490,4760592,64268,20990, 10412026,7290410,94655,28378, 5346663,54,0 ],
  [ 1500,0,75,1,5, 171743,121737,12267,3572, 1087367,1149252,13592,-774, 4181821,2768376,37338,12620, 7177313,4891082,64083,20413, 10587303,7343053,94529,28967, 5523458,54,0 ],
  [ 1500,25,1,1,5, 17697,78627,17697,-60930, 28338,55664,28338,-27326, 71925,69225,71925,2700, 88072,57158,88072,30914, 116742,65788,116742,50954, -3647653,-41,27 ],
  [ 1500,25,2,1,5, 32923,81566,16462,-24321, 55751,76212,27875,-10231, 132293,81388,66147,25453, 176145,75791,88072,50177, 233484,190690,116742,21397, -3368466,-39,25 ],
  [ 1500,25,3,1,5, 48149,84504,16050,-12118, 83163,82875,27721,96, 192661,108215,64220,28149, 264217,189758,88072,24820, 350225,345001,116742,1741, -3240721,-38,25 ],
  [ 1500,25,5,1,5, 71392,102328,15577,-6750, 132080,95044,26416,7407, 305614,314231,61123,-1723, 435734,349951,87147,17157, 577541,410793,115508,33350, -3185078,-38,24 ],
  [ 1500,25,10,1,5, 119137,111549,14588,929, 275408,339331,22951,-5327, 664372,428558,55364,19651, 1012078,734236,84340,23154, 1343783,950906,111982,32740, -2342458,-31,19 ],
  [ 1500,25,15,1,5, 156569,118783,13917,3359, 449357,415832,20425,1524, 1138860,841952,51766,13496, 1799667,1242776,81803,25313, 2411645,1700809,109620,32311, -1133458,-17,-32 ],
  [ 1500,25,25,1,5, 183216,137273,13325,3341, 802439,827525,16805,-525, 2374942,1583654,45672,15217, 3933187,2651755,75638,24643, 5489537,3856399,105568,31407, 1570700,28,0 ],
  [ 1500,25,50,1,5, 185466,137709,13248,3411, 1154491,1181761,14552,-344, 4412467,2920480,40113,13564, 7552969,5094314,68663,22351, 11129324,7718890,101176,31004, 6176334,57,0 ],
  [ 1500,25,75,1,5, 185466,137709,13248,3411, 1160685,1183643,14509,-287, 4476245,2933047,39966,13779, 7668127,5132817,68465,22637, 11316635,7857850,101041,30882, 6454978,59,0 ],
  [ 1500,50,1,1,5, 21112,79283,21112,-58171, 30996,56088,30996,-25092, 87350,72440,87350,14910, 93752,58368,93752,35384, 124315,67449,124315,56866, -3590486,-40,26 ],
  [ 1500,50,2,1,5, 37282,82403,18641,-22560, 60140,77058,30070,-8459, 151586,85391,75793,33098, 187504,78202,93752,54651, 248630,194004,124315,27313, -3286142,-38,25 ],
  [ 1500,50,3,1,5, 53452,85523,17817,-10690, 89284,84142,29761,1714, 215822,113007,71941,34272, 281256,193370,93752,29295, 372945,349970,124315,7658, -3133240,-37,24 ],
  [ 1500,50,5,1,5, 78132,103622,17047,-5561, 141287,97089,28257,8840, 336001,335006,67200,199, 463822,355938,92764,21577, 614989,434688,122998,36060, -3075917,-37,24 ],
  [ 1500,50,10,1,5, 128815,113408,15773,1887, 293633,343785,24469,-4179, 717652,530959,59804,15558, 1077237,747969,89770,27439, 1430802,985532,119234,37106, -2266312,-30,19 ],
  [ 1500,50,15,1,5, 168539,121082,14981,4218, 478493,423338,21750,2507, 1222351,873431,55561,15860, 1915414,1282006,87064,28791, 2567663,1834086,116712,33344, -777141,-12,0 ],
  [ 1500,50,25,1,5, 196804,139883,14313,4140, 853593,855392,17876,-38, 2536811,1722134,48785,15667, 4185598,2842687,80492,25825, 5844011,4063434,112385,34242, 1938586,31,0 ],
  [ 1500,50,50,1,5, 199189,140344,14228,4203, 1227430,1216188,15472,142, 4702880,3113413,42753,14450, 8036449,5428386,73059,23710, 11846622,8231500,107697,32865, 6932084,61,0 ],
  [ 1500,50,75,1,5, 199189,140344,14228,4203, 1234004,1218185,15425,198, 4770668,3218282,42595,13861, 8158941,5468436,72848,24022, 12045967,8289266,107553,33542, 7084077,61,0 ],
  [ 1500,100,1,1,5, 27942,80595,27942,-52652, 36312,56936,36312,-20624, 118201,78871,118201,39330, 105111,60788,105111,44324, 139461,70770,139461,68691, -3476152,-40,26 ],
  [ 1500,100,2,1,5, 45999,84077,22999,-19039, 68919,78749,34460,-4915, 190173,107914,95086,41129, 210223,98051,105111,56086, 278922,200683,139461,39119, -3177097,-38,24 ],
  [ 1500,100,3,1,5, 64056,100896,21352,-12280, 101527,86527,33842,5000, 262145,199591,87382,20851, 315334,309205,105111,2043, 418383,360057,139461,19442, -3309542,-39,25 ],
  [ 1500,100,5,1,5, 91612,106211,19988,-3185, 159702,115065,31940,8927, 396775,347376,79355,9880, 519998,382789,104000,27442, 689887,534766,137977,31024, -2893669,-36,22 ],
  [ 1500,100,10,1,5, 148172,117126,18144,3802, 330084,352695,27507,-1884, 824212,567246,68684,21414, 1207555,870669,100630,28074, 1604842,1235933,133737,30742, -1997344,-28,16 ],
  [ 1500,100,15,1,5, 192480,139016,17109,4752, 536767,525935,24399,492, 1389334,921723,63152,21255, 2146908,1549334,97587,27162, 2879700,2046219,130895,37885, -602827,-9,0 ],
  [ 1500,100,25,1,5, 223980,216802,16289,522, 955901,897240,20019,1229, 2860549,1922264,55011,18044, 4690420,3196799,90200,28723, 6552960,4604685,126018,37467, 2549769,37,0 ],
  [ 1500,100,50,1,5, 226634,217315,16188,666, 1373307,1345007,17311,357, 5283707,3547379,48034,15785, 9003408,6111007,81849,26295, 13281217,9172489,120738,37352, 7889414,64,0 ],
  [ 1500,100,75,1,5, 226634,217315,16188,666, 1380641,1347233,17258,418, 5359516,3576824,47853,15917, 9140569,6154049,81612,26665, 13504631,9447872,120577,36221, 8113592,65,0 ],
  [ 1500,200,1,1,5, 41602,83218,41602,-41616, 46943,58631,46943,-11688, 179902,106248,179902,73654, 127830,65677,127830,62153, 169753,77462,169753,92291, -3280985,-38,25 ],
  [ 1500,200,2,1,5, 63433,87425,31717,-11996, 86477,82131,43239,2173, 267346,215444,133673,25951, 255660,188053,127830,33804, 339507,342754,169753,-1623, -3268128,-38,25 ],
  [ 1500,200,3,1,5, 85264,104969,28421,-6568, 126012,91596,42004,11472, 354789,353839,118263,317, 383490,339231,127830,14753, 509260,396203,169753,37686, -3225272,-38,24 ],
  [ 1500,200,5,1,5, 118572,111389,25870,1567, 196530,197095,39306,-113, 518323,401445,103665,23376, 632351,421615,126470,42147, 839682,583233,167936,51290, -2612389,-33,21 ],
  [ 1500,200,10,1,5, 186887,124561,22884,7632, 402984,384399,33582,1549, 1037331,745719,86444,24301, 1468189,955954,122349,42686, 1952920,1343442,162743,50790, -1230590,-19,-41 ],
  [ 1500,200,15,1,5, 240360,219912,21365,1818, 653314,569844,29696,3794, 1723301,1216022,78332,23058, 2609896,1772034,118632,38085, 3503772,2540928,159262,43766, 27740,6,0 ],
  [ 1500,200,25,1,5, 278331,328107,20242,-3620, 1160518,1157636,24304,60, 3508025,2383489,67462,21626, 5700064,3862943,109617,35329, 7970856,5629115,153286,45033, 3350356,43,0 ],
  [ 1500,200,50,1,5, 281524,328724,20109,-3371, 1665063,1571680,20988,1177, 6445361,4168126,58594,20702, 10937327,7353861,99430,32577, 16150408,11168059,146822,45294, 10742146,74,0 ],
  [ 1500,200,75,1,5, 281524,328724,20109,-3371, 1673915,1648214,20924,321, 6537211,4369256,58368,19357, 11103826,7498624,99141,32189, 16421958,11340949,146625,45366, 10378624,73,0 ],
  [ 2000,0,1,1,5, 19454,79108,19454,-59654, 33776,57302,33776,-23526, 70410,69107,70410,1303, 101893,60364,101893,41529, 135403,70140,135403,65263, -3607738,-41,27 ],
  [ 2000,0,2,1,5, 38908,83002,19454,-22047, 67552,79490,33776,-5969, 140820,83578,70410,28621, 203787,97189,101893,53299, 270806,199400,135403,35703, -3278731,-38,25 ],
  [ 2000,0,3,1,5, 58362,100233,19454,-13957, 101328,87643,33776,4562, 211229,112715,70410,32838, 305680,308004,101893,-775, 406209,358220,135403,15996, -3271726,-38,25 ],
  [ 2000,0,5,1,5, 88062,106178,19213,-3953, 162030,117018,32406,9002, 343810,337605,68762,1241, 503988,380849,100798,24628, 669897,531813,133979,27617, -3038826,-37,23 ],
  [ 2000,0,10,1,5, 149082,118398,18255,3757, 342129,371875,28511,-2479, 769123,544205,64094,18743, 1170249,866382,97521,25322, 1559005,1145645,129917,34447, -2111836,-29,17 ],
  [ 2000,0,15,1,5, 196934,141321,17505,4943, 563277,536294,25604,1226, 1336460,916405,60748,19093, 2082987,1434217,94681,29490, 2797531,2035150,127160,34654, -627084,-9,0 ],
  [ 2000,0,25,1,5, 231011,219851,16801,812, 1019473,934633,21350,1777, 2837777,1930640,54573,17445, 4576414,3189239,88008,26676, 6359881,4466948,122305,36403, 2380926,35,0 ],
  [ 2000,0,50,1,5, 233890,220428,16706,962, 1478587,1515485,18638,-465, 5356539,3617071,48696,15813, 8861695,6146160,80561,24687, 12882430,9040903,117113,34923, 7255237,62,0 ],
  [ 2000,0,75,1,5, 233890,220428,16706,962, 1486675,1517979,18583,-391, 5436250,3647798,48538,15968, 8999233,6189914,80350,25083, 13099245,9202542,116958,34792, 7562859,63,0 ],
  [ 2000,25,1,1,5, 22869,79764,22869,-56895, 36434,57726,36434,-21292, 85835,72322,85835,13513, 107573,61574,107573,45999, 142976,71801,142976,71175, -3550572,-40,26 ],
  [ 2000,25,2,1,5, 43267,83839,21633,-20286, 71941,80335,35971,-4197, 160113,87582,80056,36265, 215146,99600,107573,57773, 285952,202715,142976,41619, -3196407,-38,24 ],
  [ 2000,25,3,1,5, 63664,101251,21221,-12529, 107449,88910,35816,6180, 234390,194507,78130,13295, 322719,311616,107573,3701, 428928,363189,142976,21913, -3340742,-39,25 ],
  [ 2000,25,5,1,5, 94802,107473,20684,-2765, 171237,119063,34247,10435, 374197,343865,74839,6066, 532076,386786,106415,29058, 707346,539987,141469,33472, -2884991,-35,22 ],
  [ 2000,25,10,1,5, 158760,120257,19440,4715, 360354,376329,30030,-1331, 822402,569606,68534,21066, 1235408,880116,102951,29608, 1646025,1248379,137169,33137, -1907753,-27,15 ],
  [ 2000,25,15,1,5, 208904,143620,18569,5803, 592414,543800,26928,2210, 1419952,933369,64543,22117, 2198734,1581857,99942,28040, 2953549,2168377,134252,35690, -397342,-5,0 ],
  [ 2000,25,25,1,5, 244599,222461,17789,1610, 1070627,1052965,22422,370, 2999646,2067770,57686,17921, 4828825,3352019,92862,28400, 6714355,4770953,129122,37373, 2437865,36,0 ],
  [ 2000,25,50,1,5, 247612,223064,17687,1753, 1551526,1549912,19557,20, 5646953,3795469,51336,16832, 9345174,6371322,84956,27035, 13599728,9553063,123634,36788, 8206441,65,0 ],
  [ 2000,25,75,1,5, 247612,223064,17687,1753, 1558698,1552521,19484,77, 5693222,3908851,50832,15932, 9424783,6376322,84150,27218, 13724680,9553563,122542,37242, 8262819,66,0 ],
  [ 2000,50,1,1,5, 26284,80420,26284,-54136, 39092,58149,39092,-19058, 101260,75538,101260,25723, 113253,62784,113253,50469, 150549,73461,150549,77088, -3493405,-40,26 ],
  [ 2000,50,2,1,5, 47625,84676,23813,-18525, 76331,81181,38166,-2425, 179406,106101,89703,36653, 226505,182368,113253,22069, 301098,319170,150549,-9036, -3346739,-39,25 ],
  [ 2000,50,3,1,5, 68966,102269,22989,-11101, 113570,90177,37857,7798, 257552,199299,85851,19418, 339758,330205,113253,3184, 451648,368157,150549,27830, -3255365,-38,25 ],
  [ 2000,50,5,1,5, 101542,108767,22155,-1576, 180445,194959,36089,-2903, 404584,350125,80917,10892, 560164,392723,112033,33488, 744795,563832,148959,36193, -2976111,-36,23 ],
  [ 2000,50,10,1,5, 168439,122115,20625,5672, 378580,380784,31548,-184, 875682,580492,72974,24599, 1300566,908775,108381,32649, 1733044,1282955,144420,37507, -1643713,-24,13 ],
  [ 2000,50,15,1,5, 220874,217618,19633,289, 621551,565191,28252,2562, 1503444,1070747,68338,19668, 2314481,1621587,105204,31495, 3109567,2218324,141344,40511, -564236,-8,0 ],
  [ 2000,50,25,1,5, 258187,225070,18777,2408, 1121781,1154681,23493,-689, 3161515,2114735,60798,20130, 5081236,3419464,97716,31957, 7068829,4977938,135939,40209, 2964146,40,0 ],
  [ 2000,50,50,1,5, 261335,225699,18667,2545, 1624464,1584339,20476,506, 5937366,3988402,53976,17718, 9828654,6705394,89351,28393, 14317026,10065673,130155,38649, 8962190,68,0 ],
  [ 2000,50,75,1,5, 261335,225699,18667,2545, 1633312,1587062,20416,578, 6025097,4083189,53796,17338, 9980861,6767068,89115,28695, 14557909,10261646,129981,38359, 9131039,69,0 ],
  [ 2000,100,1,1,5, 33114,81731,33114,-48617, 44407,58997,44407,-14590, 132111,81968,132111,50142, 124612,65204,124612,59408, 165695,76782,165695,88913, -3379071,-39,25 ],
  [ 2000,100,2,1,5, 56342,86350,28171,-15004, 85110,82872,42555,1119, 217992,114108,108996,51942, 249224,187190,124612,31017, 331391,341470,165695,-5040, -3193264,-38,24 ],
  [ 2000,100,3,1,5, 79571,104306,26524,-8245, 125813,92712,41938,11034, 303874,329448,101291,-8525, 373836,337980,124612,11952, 497086,394316,165695,34257, -3330453,-39,25 ],
  [ 2000,100,5,1,5, 115022,111356,25096,800, 198859,199049,39772,-38, 465358,377159,93072,17640, 616341,419624,123268,39343, 819693,580230,163939,47892, -2724046,-34,22 ],
  [ 2000,100,10,1,5, 187796,139170,22995,5954, 415030,389544,34586,2124, 982242,722829,81853,21618, 1430884,951818,119240,39922, 1907083,1337135,158924,47496, -1415926,-21,-50 ],
  [ 2000,100,15,1,5, 244815,222216,21761,2009, 679824,684553,30901,-215, 1670427,1194689,75929,21624, 2545975,1765327,115726,35484, 3421603,2529309,155527,40559, -449713,-5,0 ],
  [ 2000,100,25,1,5, 285362,331156,20754,-3330, 1224090,1195029,25635,609, 3485253,2391865,67024,21027, 5586058,3855383,107424,33282, 7777777,5520688,149573,43406, 3160617,41,0 ],
  [ 2000,100,50,1,5, 288780,331837,20627,-3075, 1770342,1711658,22315,740, 6518193,4422368,59256,19053, 10795613,7389515,98142,30965, 15751621,11008162,143197,43122, 9799831,71,0 ],
  [ 2000,100,75,1,5, 288780,331837,20627,-3075, 1779949,1714610,22249,817, 6613945,4456246,59053,19265, 10962489,7519562,97879,30740, 16016572,11209260,143005,42922, 10062483,72,0 ],
  [ 2000,200,1,1,5, 46773,84355,46773,-37581, 55039,60693,55039,-5654, 193812,109345,193812,84466, 147331,70093,147331,77238, 195988,83475,195988,112513, -3183904,-38,24 ],
  [ 2000,200,2,1,5, 73776,103035,36888,-14629, 102668,86105,51334,8282, 295165,221638,147582,36763, 294662,305495,147331,-5417, 391975,354929,195988,18523, -3288733,-38,25 ],
  [ 2000,200,3,1,5, 100779,108379,33593,-2533, 150298,97781,50099,17506, 396518,363131,132173,11129, 441992,352479,147331,29838, 587963,414241,195988,57908, -2934028,-36,23 ],
  [ 2000,200,5,1,5, 141982,116534,30978,5552, 235687,207230,47137,5692, 586906,416713,117381,34038, 728694,523731,145739,40993, 969488,741738,193898,45550, -2352537,-31,19 ],
  [ 2000,200,10,1,5, 226511,218305,27736,1005, 487931,421248,40661,5557, 1195361,872252,99613,26926, 1691519,1225371,140960,38846, 2255162,1656686,187930,49873, -1304414,-20,-43 ],
  [ 2000,200,15,1,5, 292695,332279,26017,-3519, 796371,726963,36199,3155, 2004394,1382938,91109,28248, 3008964,2097488,136771,41431, 4045676,2925567,183894,50914, 217376,10,0 ],
  [ 2000,200,25,1,5, 339714,354931,24706,-1107, 1428706,1352425,29921,1598, 4132729,2777590,79476,26060, 6595702,4548330,126840,39373, 9195673,6460488,176840,52600, 4841489,51,0 ],
  [ 2000,200,50,1,5, 343671,355716,24548,-860, 2062098,2013530,25993,612, 7679847,5134650,69817,23138, 12729532,8631470,115723,37255, 18620812,13002832,169280,51073, 12565885,79,0 ],
  [ 2000,200,75,1,5, 343671,355716,24548,-860, 2073223,2016941,25915,704, 7791640,5248678,69568,22705, 12925746,8782479,115408,36993, 18933900,13313529,169053,50182, 12653480,79,0 ],
  [ 2500,0,1,1,5, 24626,80244,24626,-55619, 41871,59363,41871,-17492, 84319,72204,84319,12116, 121394,64780,121394,56614, 161637,76153,161637,85485, -3510657,-40,26 ],
  [ 2500,0,2,1,5, 49251,85275,24626,-18012, 83743,83613,41871,65, 168639,89772,84319,39433, 242788,186328,121394,28230, 323275,324516,161637,-620, -3283725,-38,25 ],
  [ 2500,0,3,1,5, 73877,103642,24626,-9922, 125614,93827,41871,10596, 252958,199006,84319,17984, 364182,336228,121394,9318, 484912,391929,161637,30994, -3190257,-38,24 ],
  [ 2500,0,5,1,5, 111471,111323,24321,32, 201187,201003,40237,37, 412393,352874,82479,11904, 600331,417634,120066,36539, 799703,577228,159941,44495, -2835702,-35,22 ],
  [ 2500,0,10,1,5, 188705,140442,23107,5910, 427075,408723,35590,1529, 927153,699788,77263,18947, 1393578,947531,116132,37171, 1861247,1330627,155104,44218, -1590152,-23,12 ],
  [ 2500,0,15,1,5, 249269,224521,22157,2200, 706334,708798,32106,-112, 1617553,1189221,73525,19470, 2482055,1758670,112821,32881, 3339435,2418739,151792,41850, -606721,-8,0 ],
  [ 2500,0,25,1,5, 292394,347542,21265,-4011, 1287661,1232273,26967,1160, 3462482,2414756,66586,20149, 5472052,3767716,105232,32776, 7584699,5383151,145860,42337, 3022105,40,0 ],
  [ 2500,0,50,1,5, 296036,348287,21145,-3732, 1875621,1881986,23642,-80, 6591025,4492060,59918,19081, 10653900,7424818,96854,29355, 15352835,10876726,139571,40692, 9110961,69,0 ],
  [ 2500,0,75,1,5, 296036,348287,21145,-3732, 1885982,1885207,23575,10, 6690679,4527220,59738,19317, 10821153,7475270,96617,29874, 15611187,11047860,139386,40744, 9515847,70,0 ],
  [ 2500,25,1,1,5, 28041,80900,28041,-52860, 44529,59787,44529,-15258, 99745,75419,99745,24326, 127074,65990,127074,61084, 169211,77813,169211,91397, -3453490,-40,26 ],
  [ 2500,25,2,1,5, 53610,86112,26805,-16251, 88132,84458,44066,1837, 187932,108291,93966,39820, 254147,188789,127074,32679, 338421,343551,169211,-2565, -3246074,-38,24 ],
  [ 2500,25,3,1,5, 79179,104661,26393,-8494, 131736,95095,43912,12214, 276120,203798,92040,24107, 381221,339841,127074,13793, 507632,396898,169211,36911, -3082776,-37,24 ],
  [ 2500,25,5,1,5, 118211,112617,25791,1220, 210394,203048,42079,1469, 442780,373648,88556,13826, 628419,423621,125684,40960, 837152,585452,167430,50340, -2715368,-34,21 ],
  [ 2500,25,10,1,5, 198384,142301,24292,6867, 445301,413178,37108,2677, 980432,725189,81703,21270, 1458737,961264,121561,41456, 1948266,1365252,162356,48585, -1337508,-20,-47 ],
  [ 2500,25,15,1,5, 261239,327686,23221,-5906, 735471,714804,33431,939, 1701045,1220700,77320,21834, 2597802,1799400,118082,36291, 3495453,2569187,158884,42103, -675251,-9,0 ],
  [ 2500,25,25,1,5, 305982,350151,22253,-3212, 1338816,1260139,28038,1648, 3624351,2461721,69699,22358, 5724463,4038906,110086,32415, 7939173,5687556,152676,43300, 3412006,43,0 ],
  [ 2500,25,50,1,5, 309758,350922,22126,-2940, 1948560,1990263,24562,-526, 6881439,4761993,62559,19268, 11137379,7650430,101249,31700, 16070132,11389336,146092,42553, 9616506,70,0 ],
  [ 2500,25,75,1,5, 309758,350922,22126,-2940, 1959301,1993598,24491,-429, 6985103,4797941,62367,19528, 11311967,7891146,101000,30543, 16340519,11592318,145897,42395, 9745509,71,0 ],
  [ 2500,50,1,1,5, 31456,81556,31456,-50101, 47187,60211,47187,-13024, 115170,78635,115170,36535, 132753,67200,132753,65553, 176784,79474,176784,97310, -3396323,-39,25 ],
  [ 2500,50,2,1,5, 57968,86949,28984,-14490, 92522,85304,46261,3609, 207225,112295,103613,47465, 265507,191200,132753,37153, 353567,346866,176784,3351, -3163750,-38,24 ],
  [ 2500,50,3,1,5, 84481,105679,28160,-7066, 137857,96362,45952,13832, 299281,329155,99760,-9958, 398260,344003,132753,18086, 530351,402416,176784,42645, -3254172,-38,25 ],
  [ 2500,50,5,1,5, 124951,113912,27262,2409, 219602,205093,43920,2902, 473167,379908,94633,18652, 656507,509866,131301,29328, 874601,609297,174920,53061, -2691231,-34,21 ],
  [ 2500,50,10,1,5, 208063,144160,25477,7825, 463526,417633,38627,3824, 1033712,736075,86143,24803, 1523896,1098384,126991,35459, 2035286,1467937,169607,47279, -1282103,-20,-47 ],
  [ 2500,50,15,1,5, 273209,329985,24285,-5047, 764608,722310,34755,1923, 1784536,1237663,81115,24858, 2713549,1947040,123343,34841, 3651471,2618634,165976,46947, -385774,-3,0 ],
  [ 2500,50,25,1,5, 319569,352761,23241,-2414, 1389970,1347971,29109,880, 3786220,2585836,72812,23084, 5976874,4201636,114940,34139, 8293647,6007682,159493,43961, 3584112,44,0 ],
  [ 2500,50,50,1,5, 323481,353558,23106,-2148, 2021499,2010805,25481,135, 7171852,4849026,65199,21117, 11620859,7984002,105644,33062, 16787430,11817715,152613,45179, 10720951,74,0 ],
  [ 2500,50,75,1,5, 323481,353558,23106,-2148, 2032619,2028140,25408,56, 7279526,4962612,64996,20687, 11802782,8241191,105382,31800, 17069851,12106964,152409,44311, 10571648,73,0 ],
  [ 2500,100,1,1,5, 38285,82868,38285,-44582, 52503,61059,52503,-8556, 146020,99581,146020,46440, 144113,69670,144113,74443, 191930,82845,191930,109085, -3315490,-39,25 ],
  [ 2500,100,2,1,5, 66685,101960,33343,-17637, 101301,86845,50651,7228, 245812,197302,122906,24255, 288225,196172,144113,46027, 383860,353645,191930,15107, -3230293,-38,24 ],
  [ 2500,100,3,1,5, 95086,107716,31695,-4210, 150099,98897,50033,17068, 345603,338739,115201,2288, 432338,351228,144113,27037, 575789,412354,191930,54479, -3039210,-37,23 ],
  [ 2500,100,5,1,5, 138431,116501,30203,4785, 238016,209183,47603,5767, 533941,406943,106788,25400, 712684,521791,142537,38179, 949498,738785,189900,42143, -2497694,-32,20 ],
  [ 2500,100,10,1,5, 227420,219577,27847,960, 499976,500393,41665,-35, 1140272,849362,95023,24243, 1654213,1221084,137851,36094, 2209325,1650228,184110,46591, -1668837,-24,13 ],
  [ 2500,100,15,1,5, 297150,347920,26413,-4513, 822881,824907,37404,-92, 1951520,1300470,88705,29593, 2945043,2105958,133866,38140, 3963507,2914598,180159,47678, -144619,3,0 ],
  [ 2500,100,25,1,5, 346745,357980,25218,-817, 1492278,1494169,31252,-40, 4109958,2798981,79038,25211, 6481696,4540821,124648,37325, 9002595,6435392,173127,49369, 4231893,48,0 ],
  [ 2500,100,50,1,5, 350472,358749,25034,-591, 2188256,2078705,27583,1381, 6106725,5090187,55516,9241, 9812288,4590832,89203,47468, 13993457,6513896,127213,67996, 12235427,78,0 ],
  [ 2500,100,75,1,5, 350926,358829,25066,-564, 2179256,2083338,27241,1199, 7868374,5321153,70253,22743, 12784410,8818345,114147,35411, 18528514,13053028,165433,48888, 12440542,78,0 ],
  [ 2500,200,1,1,5, 51945,85491,51945,-33546, 63134,76639,63134,-13505, 207721,126807,207721,80914, 166832,74559,166832,92272, 222222,188939,222222,33283, -3234814,-38,25 ],
  [ 2500,200,2,1,5, 84120,105308,42060,-10594, 118859,90228,59430,14316, 322984,333882,161492,-5449, 333663,329803,166832,1930, 444444,367454,222222,38495, -3362052,-39,25 ],
  [ 2500,200,3,1,5, 116294,111789,38765,1502, 174585,117851,58195,18911, 438248,372272,146083,21992, 500495,380703,166832,39930, 666666,531680,222222,44996, -2779379,-35,22 ],
  [ 2500,200,5,1,5, 165391,121678,36085,9537, 274845,335599,54969,-12151, 655488,444847,131098,42128, 825036,560566,165007,52894, 1099293,786652,219859,62528, -2319338,-31,19 ],
  [ 2500,200,10,1,5, 266134,327879,32588,-7561, 572877,530597,47740,3523, 1353391,921785,112783,35967, 1914848,1307369,159571,50623, 2557404,1842518,213117,59574, -1130113,-17,-26 ],
  [ 2500,200,15,1,5, 345030,357116,30669,-1074, 939429,868816,42701,3210, 2285486,1594769,103886,31396, 3408031,2437118,154911,44132, 4587580,3325526,208526,57366, 679592,17,0 ],
  [ 2500,200,25,1,5, 401096,368419,29171,2377, 1696895,1651715,35537,946, 4757434,3261706,91489,28764, 7491340,5140134,144064,45216, 10420491,7375042,200394,58566, 5928931,56,0 ],
  [ 2500,200,50,1,5, 405817,369371,28987,2603, 2459132,2381681,30997,976, 8914333,6009639,81039,26406, 14521737,9988785,132016,41209, 21091217,14837005,191738,56856, 14771451,84,0 ],
  [ 2500,200,75,1,5, 405817,369371,28987,2603, 2472530,2385819,30907,1084, 9046069,6128100,80768,26053, 14747666,10066185,131676,41799, 21445841,15157197,191481,56149, 15074835,85,0 ],
  [ 3000,0,1,1,5, 29797,81381,29797,-51584, 49967,75310,49967,-25343, 98229,75151,98229,23078, 140895,69196,140895,71698, 187872,82115,187872,105757, -3457160,-40,26 ],
  [ 3000,0,2,1,5, 59595,100885,29797,-20645, 99934,87586,49967,6174, 196458,110482,98229,42988, 281789,195360,140895,43215, 375744,352412,187872,11666, -3188929,-38,24 ],
  [ 3000,0,3,1,5, 89392,107052,29797,-5887, 149901,113897,49967,12001, 294688,314198,98229,-6503, 422684,349977,140895,24236, 563615,410417,187872,51066, -3187975,-38,24 ],
  [ 3000,0,5,1,5, 134880,116468,29428,4017, 240344,315487,48069,-15028, 480976,381157,96195,19964, 696673,519800,139335,35375, 929509,735282,185902,38845, -2935972,-36,23 ],
  [ 3000,0,10,1,5, 228329,220850,27959,916, 512022,505687,42668,528, 1085183,840986,90432,20350, 1616907,1216847,134742,33338, 2163489,1643821,180291,43306, -1832980,-26,15 ],
  [ 3000,0,15,1,5, 301604,350224,26809,-4322, 849391,835266,38609,642, 1898646,1280637,86302,28091, 2881122,2099251,130960,35540, 3881339,2790388,176424,49589, -214819,1,0 ],
  [ 3000,0,25,1,5, 353776,361029,25729,-527, 1555850,1531562,32583,509, 4087186,2884357,78600,23131, 6367690,4548238,122456,34989, 8809516,6410745,169414,46130, 3783818,45,0 ],
  [ 3000,0,50,1,5, 358182,361942,25584,-269, 2272656,2323987,28647,-647, 7825511,5367050,71141,22350, 12446105,8701826,113146,34039, 17823239,12710899,162029,46476, 11201271,75,0 ],
  [ 3000,0,75,1,5, 358182,361942,25584,-269, 2285290,2327935,28566,-533, 7945108,5512692,70938,21718, 12643074,8839783,112885,33958, 18123129,13005119,161814,45697, 11258887,75,0 ],
  [ 3000,25,1,1,5, 33212,82037,33212,-48825, 52625,75734,52625,-23109, 113654,78366,113654,35288, 146574,70406,146574,76168, 195445,99447,195445,95998, -3411166,-39,26 ],
  [ 3000,25,2,1,5, 63953,101722,31977,-18884, 104323,88432,52162,7946, 215752,114486,107876,50633, 293149,306231,146574,-6541, 390890,355727,195445,17582, -3266679,-38,25 ],
  [ 3000,25,3,1,5, 94694,108071,31565,-4459, 156022,115165,52007,13619, 317849,333505,105950,-5219, 439723,353639,146574,28695, 586335,431106,195445,51743, -3125167,-37,24 ],
  [ 3000,25,5,1,5, 141620,117762,30899,5205, 249552,331417,49910,-16373, 511363,387267,102273,24819, 724761,540714,144952,36810, 966957,743406,193391,44710, -2847824,-35,22 ],
  [ 3000,25,10,1,5, 238008,222709,29144,1873, 530247,524027,44187,518, 1138462,851722,94872,23895, 1682066,1230530,140172,37628, 2250508,1662675,187542,48986, -1579246,-23,-55 ],
  [ 3000,25,15,1,5, 313574,352523,27873,-3462, 878528,856657,39933,994, 1982137,1388966,90097,26962, 2996869,2123504,136221,39698, 4037357,2952925,183516,49292, -77378,4,0 ],
  [ 3000,25,25,1,5, 367364,363639,26717,271, 1607004,1559428,33655,996, 4249055,2931322,81713,25341, 6620102,4630660,127310,38258, 9163990,6602060,176231,49268, 4532949,49,0 ],
  [ 3000,25,50,1,5, 371905,364578,26565,523, 2345595,2358414,29566,-162, 8115925,5636982,73781,22536, 12929585,9035898,117542,35397, 18540536,13139728,168550,49098, 11840257,77,0 ],
  [ 3000,25,75,1,5, 371905,364578,26565,523, 2358609,2362476,29483,-48, 8239532,5677363,73567,22877, 13133888,9189828,117267,35215, 18852461,13435986,168326,48361, 12144760,78,0 ],
  [ 3000,50,1,1,5, 36627,82693,36627,-46066, 55283,76158,55283,-20875, 129080,81582,129080,47498, 152254,71616,152254,80638, 203018,101108,203018,101910, -3353999,-39,25 ],
  [ 3000,50,2,1,5, 68312,102559,34156,-17124, 108713,89277,54356,9718, 235045,195489,117522,19778, 304508,308642,152254,-2067, 406036,359041,203018,23497, -3360853,-39,25 ],
  [ 3000,50,3,1,5, 99996,109089,33332,-3031, 162143,116432,54048,15237, 341010,338297,113670,904, 456762,372228,152254,28178, 609054,436075,203018,57660, -3039789,-37,23 ],
  [ 3000,50,5,1,5, 148360,119057,32369,6393, 258759,333462,51752,-14941, 541750,408042,108350,26742, 752850,546701,150570,41230, 1004406,767301,200881,47421, -2738663,-34,22 ],
  [ 3000,50,10,1,5, 247686,224567,30329,2831, 548472,528482,45706,1666, 1191742,877123,99312,26218, 1747225,1259240,145602,40665, 2337528,1697300,194794,53352, -1348706,-20,-43 ],
  [ 3000,50,15,1,5, 247686,224567,22017,2055, 548472,528482,24931,909, 1807013,1247294,82137,25442, 1785807,1283754,81173,22821, 2390985,1741757,108681,29510, -761392,-12,0 ],
  [ 3000,50,25,1,5, 380952,366248,27706,1069, 1658158,1573410,34726,1775, 4410924,3099002,84825,25229, 6872513,4887423,132164,38175, 9518464,6838955,183047,51529, 4758219,51,0 ],
  [ 3000,50,50,1,5, 385627,367213,27545,1315, 2418534,2378956,30486,499, 8406338,5801015,76421,23685, 13413064,9341318,121937,37016, 19257834,13764979,175071,49935, 12669388,79,0 ],
  [ 3000,50,75,1,5, 385627,367213,27545,1315, 2431927,2397017,30399,436, 8533955,5948084,76196,23088, 13624702,9525397,121649,36601, 19581793,13966804,174837,50134, 12736452,79,0 ],
  [ 3000,100,1,1,5, 43457,84004,43457,-40547, 60598,77006,60598,-16407, 159930,102528,159930,57402, 163613,74086,163613,89528, 218164,104479,218164,113686, -3273166,-38,25 ],
  [ 3000,100,2,1,5, 77029,104233,38514,-13602, 117492,90969,58746,13262, 273631,218012,136816,27810, 327227,328491,163613,-632, 436328,365720,218164,35304, -3251808,-38,24 ],
  [ 3000,100,3,1,5, 110600,111125,36867,-175, 174386,118967,58129,18473, 387332,362396,129111,8312, 490840,379502,163613,37113, 654492,446062,218164,69477, -2858327,-35,22 ],
  [ 3000,100,5,1,5, 161840,121645,35311,8770, 277173,337552,55435,-12076, 602523,420561,120505,36392, 809026,558576,161805,50090, 1079304,783649,215861,59131, -2430994,-32,20 ],
  [ 3000,100,10,1,5, 267044,329152,32699,-7605, 584922,535892,48744,4086, 1298302,913410,108192,32074, 1877542,1303132,156462,47867, 2511567,1836111,209297,56288, -1294256,-19,-34 ],
  [ 3000,100,15,1,5, 349485,359420,31065,-883, 965939,893060,43906,3313, 2232612,1574786,101482,29901, 3344110,2430411,152005,41532, 4505411,3185596,204791,59992, 576982,16,0 ],
  [ 3000,100,25,1,5, 408128,384804,29682,1696, 1760467,1688958,36868,1498, 4734662,3270082,91051,28165, 7377335,5132725,141872,43166, 10227412,7350545,196681,55324, 5624764,55,0 ],
  [ 3000,100,50,1,5, 413073,385821,29505,1947, 2564412,2521510,32325,541, 8987165,6157831,81702,25721, 14380024,10024088,130727,39599, 20692430,14706068,188113,54421, 13998924,82,0 ],
  [ 3000,100,75,1,5, 413073,385821,29505,1947, 2578564,2525915,32232,658, 9122803,6306625,81454,25144, 14606330,10102700,130414,40211, 21040456,14913017,187861,54709, 14316871,83,0 ],
  [ 3000,200,1,1,5, 57117,86628,57117,-29511, 71230,78701,71230,-7472, 221631,129905,221631,91727, 186332,78975,186332,107357, 248456,194951,248456,53505, -3137733,-37,24 ],
  [ 3000,200,2,1,5, 94463,107582,47231,-6559, 135050,94351,67525,20350, 350804,354592,175402,-1894, 372664,338685,186332,16989, 496913,395200,248456,50857, -3212563,-38,24 ],
  [ 3000,200,3,1,5, 131809,115199,43936,5537, 198871,197886,66290,328, 479977,396079,159992,27966, 558997,394001,186332,54998, 745369,565439,248456,59977, -2766590,-35,22 ],
  [ 3000,200,5,1,5, 188800,140160,41193,10612, 314002,345583,62800,-6316, 724071,551631,144814,34488, 921379,706012,184276,43074, 1229099,916046,245820,62610, -2366932,-31,19 ],
  [ 3000,200,10,1,5, 305758,349924,37440,-5408, 657823,567446,54819,7531, 1511421,1091883,125952,34961, 2138177,1562359,178181,47985, 2859646,2072532,238304,65593, -930495,-14,-16 ],
  [ 3000,200,15,1,5, 397365,368616,35321,2556, 1082486,936970,49204,6614, 2566579,1763035,116663,36525, 3807098,2652612,173050,52477, 5129484,3679804,233158,65895, 1746910,29,0 ],
  [ 3000,200,25,1,5, 462479,395243,33635,4890, 1965083,1877004,41154,1845, 5382138,3654307,103503,33228, 8386979,5825522,161288,49259, 11645309,8289695,223948,64531, 7267488,62,0 ],
  [ 3000,200,50,1,5, 467963,396363,33426,5114, 2856167,2823532,36002,411, 10148819,6884628,92262,29674, 16313942,11265943,148309,45891, 23561621,16671328,214197,62639, 16807068,89,0 ],
  [ 3000,200,75,1,5, 467963,396363,33426,5114, 2871838,2828396,35898,543, 10300498,7007522,91969,29402, 16569587,11538808,147943,44918, 23957783,16917235,213909,62862, 16988852,89,0 ],
  [ 1000,0,1,2,5, 14236,77746,14236,-63511, 25774,55018,25774,-29245, 57970,51442,57970,6528, 82145,55474,82145,26671, 104964,62809,104964,42155, -3667930,-41,27 ],
  [ 1000,0,2,2,5, 28471,80279,14236,-25904, 51547,61038,25774,-4746, 115940,77429,57970,19256, 164289,72532,82145,45879, 209927,101107,104964,54410, -3340694,-39,25 ],
  [ 1000,0,3,2,5, 42707,82811,14236,-13368, 77321,80943,25774,-1207, 173910,88751,57970,28387, 246434,184824,82145,20537, 314891,320504,104964,-1871, -3293362,-38,25 ],
  [ 1000,0,5,2,5, 64442,100016,14060,-7762, 122793,91785,24559,6202, 281711,201479,56342,16047, 407180,341529,81436,13130, 519848,395918,103970,24786, -3078504,-37,24 ],
  [ 1000,0,10,2,5, 109116,107976,13361,140, 253307,331250,21109,-6495, 620761,415383,51730,17115, 950436,701613,79203,20735, 1213441,902421,101120,25918, -2584141,-33,21 ],
  [ 1000,0,15,2,5, 144162,114227,12814,2661, 408689,386980,18577,987, 1065352,727815,48425,15343, 1693146,1196678,76961,22567, 2184277,1498607,99285,31167, -1216796,-18,-37 ],
  [ 1000,0,25,2,5, 169134,118690,12301,3669, 715907,708185,14993,162, 2204984,1395849,42404,15560, 3694237,2454831,71043,23835, 5010954,3471582,96365,29603, 1606783,28,0 ],
  [ 1000,0,50,2,5, 171246,119068,12232,3727, 1017864,925951,12830,1159, 4048434,2491839,36804,14151, 7049837,4584881,64089,22409, 10236353,6944460,93058,29926, 6646694,59,0 ],
  [ 1000,0,75,2,5, 171246,119068,12232,3727, 1023177,927513,12790,1196, 4105444,2579349,36656,13626, 7155656,4620625,63890,22634, 10410456,6995895,92950,30487, 6779545,60,0 ],
  [ 1000,25,1,2,5, 18799,78623,18799,-59824, 29468,55629,29468,-26160, 76718,69862,76718,6856, 89563,57105,89563,32458, 114548,64961,114548,49588, -3628597,-41,27 ],
  [ 1000,25,2,2,5, 34509,81438,17255,-23465, 57779,76141,28889,-9181, 139953,82257,69977,28848, 179126,75682,89563,51722, 229097,189033,114548,20032, -3336613,-39,25 ],
  [ 1000,25,3,2,5, 50219,84254,16740,-11345, 86089,82768,28696,1107, 203188,109317,67729,31290, 268689,189594,89563,26365, 343645,326845,114548,5600, -3184897,-38,24 ],
  [ 1000,25,5,2,5, 74201,101891,16189,-6041, 136093,94737,27219,8271, 320867,315587,64173,1056, 443919,349798,88784,18824, 567297,406778,113459,32104, -3118360,-37,24 ],
  [ 1000,25,10,2,5, 123466,110732,15118,1559, 279907,337726,23326,-4818, 691428,506808,57619,15385, 1036026,719591,86336,26370, 1324067,942129,110339,31828, -2370199,-31,19 ],
  [ 1000,25,15,2,5, 162093,117671,14408,3949, 451455,411840,20521,1801, 1177852,842020,53539,15265, 1845685,1243606,83895,27367, 2383137,1670423,108324,32396, -929686,-14,-21 ],
  [ 1000,25,25,2,5, 189593,135955,13789,3901, 791530,742545,16577,1026, 2427258,1575516,46678,16380, 4029099,2650360,77483,26514, 5465206,3813803,105100,31758, 2131973,33,0 ],
  [ 1000,25,50,2,5, 191915,136374,13708,3967, 1126147,1148346,14195,-280, 4453242,2797584,40484,15051, 7695946,4982652,69963,24666, 11160925,7532319,101463,32987, 7131002,61,0 ],
  [ 1000,25,75,2,5, 191915,136374,13708,3967, 1132029,1150079,14150,-226, 4515998,2886221,40321,14552, 7811706,5100731,69747,24205, 11350695,7700148,101345,32594, 7097033,61,0 ],
  [ 1000,50,1,2,5, 23363,79499,23363,-56136, 33163,56239,33163,-23076, 95467,73767,95467,21700, 96981,58685,96981,38296, 124133,67063,124133,57070, -3555764,-40,26 ],
  [ 1000,50,2,2,5, 40547,82598,20274,-21025, 64010,77358,32005,-6674, 163966,87235,81983,38365, 193963,78833,96981,57565, 248266,193230,124133,27518, -3229214,-38,25 ],
  [ 1000,50,3,2,5, 57732,85697,19244,-9322, 94858,84593,31619,3422, 232466,192368,77489,13366, 290944,194314,96981,32210, 372399,348807,124133,7864, -3230604,-38,24 ],
  [ 1000,50,5,2,5, 83960,103765,18319,-4321, 149393,97688,29879,10341, 360023,338159,72005,4373, 480658,357617,96132,24608, 614746,417188,122949,39512, -2946338,-36,23 ],
  [ 1000,50,10,2,5, 137817,113488,16876,2979, 306506,344202,25542,-3141, 762094,535748,63508,18862, 1121616,752595,93468,30752, 1434694,966216,119558,39040, -2024191,-28,16 ],
  [ 1000,50,15,2,5, 180024,121115,16002,5236, 494221,422815,22465,3246, 1290351,879375,58652,18681, 1998224,1290534,90828,32168, 2581997,1828650,117363,34243, -412770,-5,0 ],
  [ 1000,50,25,2,5, 210052,139885,15276,5103, 867153,850905,18160,340, 2649532,1726132,50953,17758, 4363960,2858414,83922,28953, 5919458,4042283,113836,36100, 2608141,37,0 ],
  [ 1000,50,50,2,5, 212584,140344,15185,5160, 1234429,1192690,15560,526, 4858051,3104828,44164,15938, 8342055,5445604,75837,26331, 12085497,8217948,109868,35160, 8064526,65,0 ],
  [ 1000,50,75,2,5, 212584,140344,15185,5160, 1240881,1194595,15511,579, 4926552,3117593,43987,16151, 8467756,5485403,75605,26628, 12290935,8275989,109740,35848, 8438547,66,0 ],
  [ 1000,100,1,2,5, 32490,81252,32490,-48762, 40552,57459,40552,-16907, 132963,96092,132963,36871, 111818,61897,111818,49922, 143302,71318,143302,71985, -3443597,-39,26 ],
  [ 1000,100,2,2,5, 52623,84917,26311,-16147, 76474,79793,38237,-1660, 211992,111706,105996,50143, 223636,100160,111818,61738, 286605,201673,143302,42466, -3070019,-37,24 ],
  [ 1000,100,3,2,5, 72756,101919,24252,-9721, 112395,88092,37465,8101, 291021,218986,97007,24012, 335454,312414,111818,7680, 429907,361588,143302,22773, -3194941,-38,24 ],
  [ 1000,100,5,2,5, 103478,107513,22577,-880, 175994,117477,35199,11703, 438334,368640,87667,13939, 554137,388182,110827,33191, 709645,537359,141929,34457, -2705389,-34,21 ],
  [ 1000,100,10,2,5, 166518,119000,20390,5819, 359706,357153,29975,213, 903427,579113,75286,27026, 1292797,883885,107733,34076, 1655947,1242553,137996,34449, -1557806,-23,11 ],
  [ 1000,100,15,2,5, 215887,141339,19190,6626, 579753,532350,26352,2155, 1515350,953934,68880,25519, 2303303,1588284,104696,32501, 2979716,2143172,135442,38025, -10249,5,0 ],
  [ 1000,100,25,2,5, 250969,219443,18252,2293, 1018399,906041,21328,2353, 3094080,2042050,59502,20231, 5033683,3342104,96802,32530, 6827961,4741595,131307,40122, 3470742,43,0 ],
  [ 1000,100,50,2,5, 253923,219983,18137,2424, 1450995,1355229,18290,1207, 5667668,3598751,51524,18808, 9634274,6305626,87584,30260, 13934641,9491237,126679,40395, 9848566,71,0 ],
  [ 1000,100,75,2,5, 253923,219983,18137,2424, 1458585,1357478,18232,1264, 5747660,3628288,51318,18923, 9779855,6349531,87320,30628, 14171415,9655385,126530,40322, 10182852,72,0 ],
  [ 1000,200,1,2,5, 50744,84758,50744,-34014, 55331,59899,55331,-4569, 207956,126227,207956,81729, 141492,68269,141492,73222, 181641,79777,181641,101864, -3185764,-38,24 ],
  [ 1000,200,2,2,5, 76775,102892,38387,-13059, 101400,84513,50700,8444, 308044,223134,154022,42455, 282983,193269,141492,44857, 363282,347420,181641,7931, -3115444,-37,24 ],
  [ 1000,200,3,2,5, 102805,107690,34268,-1628, 147470,95392,49157,17359, 408131,363755,136044,14792, 424475,346821,141492,25884, 544924,402971,181641,47317, -2939237,-36,23 ],
  [ 1000,200,5,2,5, 142514,115010,31094,6001, 229194,203134,45839,5212, 594956,415386,118991,35914, 701095,514592,140219,37301, 899441,610190,179888,57850, -2313557,-31,19 ],
  [ 1000,200,10,2,5, 223920,143361,27419,9864, 466105,396792,38842,5776, 1186093,863258,98841,26903, 1635157,1190293,136263,37072, 2098453,1470245,174871,52351, -1010172,-16,-33 ],
  [ 1000,200,15,2,5, 287611,327680,25565,-3562, 750817,692984,34128,2629, 1965347,1270237,89334,31596, 2913461,1966713,132430,43034, 3775155,2607887,171598,53058, 433494,14,0 ],
  [ 1000,200,25,2,5, 332804,336026,24204,-234, 1320891,1206896,27663,2387, 3983176,2580700,76600,26971, 6373130,4215250,122560,41498, 8644969,6011959,166249,50635, 5210565,53,0 ],
  [ 1000,200,50,2,5, 336600,350065,24043,-962, 1884125,1723042,23749,2030, 7286902,4720028,66245,23335, 12218710,8027921,111079,38098, 17632929,12039514,160299,50849, 13087446,80,0 ],
  [ 1000,200,75,2,5, 336600,350065,24043,-962, 1893994,1725978,23675,2100, 7389876,4754078,65981,23534, 12404054,8188398,110750,37640, 17932374,12216873,160110,51031, 13391003,81,0 ],
  [ 1500,0,1,2,5, 22316,79522,22316,-57206, 37634,57988,37634,-20354, 76903,70174,76903,6729, 107615,61297,107615,46318, 138167,70473,138167,67694, -3566434,-40,26 ],
  [ 1500,0,2,2,5, 44633,83831,22316,-19599, 75268,80862,37634,-2797, 153806,85712,76903,34047, 215230,99055,107615,58088, 276333,200065,138167,38134, -3196122,-38,24 ],
  [ 1500,0,3,2,5, 66949,101476,22316,-11509, 112902,89701,37634,7734, 230708,192916,76903,12598, 322845,310803,107615,4014, 414500,359218,138167,18427, -3324310,-39,25 ],
  [ 1500,0,5,2,5, 101019,108055,22041,-1535, 180226,194157,36045,-2786, 375248,342722,75050,6505, 533205,385607,106641,29520, 684325,533578,136865,30149, -3074575,-37,24 ],
  [ 1500,0,10,2,5, 171028,121581,20942,6055, 378364,378801,31530,-36, 837586,555007,69799,23548, 1243875,878311,103656,30464, 1597734,1234338,133144,30283, -1754604,-25,14 ],
  [ 1500,0,15,2,5, 225935,217233,20083,774, 619976,561525,28181,2657, 1452796,934117,66036,23576, 2219266,1579540,100876,29078, 2875082,2045233,130686,37720, -459107,-6,0 ],
  [ 1500,0,25,2,5, 265045,224800,19276,2927, 1114106,1146969,23332,-688, 3074427,2069183,59124,19332, 4883380,3332398,93911,29827, 6580713,4610173,126552,37895, 2649087,37,0 ],
  [ 1500,0,50,2,5, 268349,225441,19168,3065, 1609252,1558462,20285,640, 5785617,3794917,52597,18097, 9448288,6343480,85894,28226, 13421371,9233242,122012,38074, 9050579,68,0 ],
  [ 1500,0,75,2,5, 268349,225441,19168,3065, 1617974,1574998,20225,537, 5871260,3917691,52422,17443, 9594435,6403790,85665,28488, 13649582,9510038,121871,36960, 9031572,68,0 ],
  [ 1500,25,1,2,5, 26880,80399,26880,-53519, 41329,58598,41329,-17269, 95651,74079,95651,21572, 115033,62878,115033,52155, 147751,72575,147751,75176, -3493601,-40,26 ],
  [ 1500,25,2,2,5, 50671,84990,25335,-17160, 81499,82079,40750,-290, 177819,90690,88909,43564, 230066,182513,115033,23777, 295503,204262,147751,45620, -3207247,-38,24 ],
  [ 1500,25,3,2,5, 74461,102919,24820,-9486, 121670,91526,40557,10048, 259986,198967,86662,20340, 345100,330500,115033,4867, 443254,365509,147751,25915, -3204449,-38,24 ],
  [ 1500,25,5,2,5, 110778,109929,24170,185, 193526,197109,38705,-717, 414404,350780,82881,12725, 569944,393376,113989,35314, 731774,543938,146355,37567, -2869053,-35,22 ],
  [ 1500,25,10,2,5, 185378,137674,22699,5841, 404964,385127,33747,1653, 908252,689998,75688,18188, 1329465,911965,110789,34792, 1708360,1274746,142363,36134, -1719840,-25,13 ],
  [ 1500,25,15,2,5, 243867,220677,21677,2061, 662742,572500,30125,4102, 1565296,1154521,71150,18672, 2371805,1626967,107809,33856, 3073941,2203960,139725,39545, -364067,-4,0 ],
  [ 1500,25,25,2,5, 285503,329596,20764,-3207, 1189729,1179979,24916,204, 3296701,2128284,63398,22470, 5218242,3528726,100351,32491, 7034965,4855775,135288,41908, 3163633,41,0 ],
  [ 1500,25,50,2,5, 289018,330277,20644,-2947, 1717534,1689041,21650,359, 6190425,4102011,56277,18986, 10094397,6742601,91767,30471, 14345943,9920321,130418,40233, 9389486,70,0 ],
  [ 1500,25,75,2,5, 289018,330277,20644,-2947, 1726826,1691864,21585,437, 6281814,4120013,56088,19302, 10250485,6978230,91522,29217, 14589821,10086879,130266,40205, 9515913,70,0 ],
  [ 1500,50,1,2,5, 31443,81275,31443,-49832, 45023,59208,45023,-14185, 114399,77984,114399,36416, 122452,64459,122452,57993, 157336,74677,157336,82659, -3420767,-39,26 ],
  [ 1500,50,2,2,5, 56708,86150,28354,-14721, 87731,83297,43866,2217, 201831,110183,100916,45824, 244903,185713,122452,29595, 314672,321599,157336,-3463, -3213980,-38,24 ],
  [ 1500,50,3,2,5, 81974,104361,27325,-7463, 130439,93351,43480,12363, 289264,205018,96421,28082, 367355,335220,122452,10712, 472008,387470,157336,28179, -3073657,-37,23 ],
  [ 1500,50,5,2,5, 120537,111804,26299,1906, 206827,200061,41365,1353, 453559,373353,90712,16041, 606683,416172,121337,38102, 779223,570019,155845,41841, -2730309,-34,21 ],
  [ 1500,50,10,2,5, 199729,140430,24457,7261, 431564,391602,35964,3330, 978919,718938,81577,21665, 1415055,944969,117921,39174, 1818987,1298833,151582,43346, -1373831,-21,-51 ],
  [ 1500,50,15,2,5, 261798,224120,23271,3349, 705508,687825,32069,804, 1677795,1190376,76263,22155, 2524345,1754203,114743,35006, 3272801,2262235,148764,45935, -221389,0,0 ],
  [ 1500,50,25,2,5, 305962,346862,22252,-2975, 1265352,1200454,26500,1359, 3518975,2385100,67673,21805, 5553104,3737431,106790,34917, 7489217,5198045,144023,44061, 3536463,44,0 ],
  [ 1500,50,50,2,5, 309688,347583,22121,-2707, 1825817,1733236,23015,1167, 6595234,4409255,59957,19873, 10740506,7205703,97641,32135, 15270515,10508680,138823,43289, 10337775,73,0 ],
  [ 1500,50,75,2,5, 309688,347583,22121,-2707, 1835678,1736231,22946,1243, 6692368,4428386,59753,20214, 10906534,7378028,97380,31505, 15530061,10791632,138661,42307, 10512328,73,0 ],
  [ 1500,100,1,2,5, 40570,83028,40570,-42458, 52412,60428,52412,-8016, 151896,100309,151896,51587, 137288,67670,137288,69619, 176505,78932,176505,97573, -3308601,-39,25 ],
  [ 1500,100,2,2,5, 68784,101806,34392,-16511, 100194,85582,50097,7306, 249857,197139,124929,26359, 274577,192164,137288,41206, 353011,345813,176505,3599, -3241546,-38,24 ],
  [ 1500,100,3,2,5, 96998,107247,32333,-3416, 147976,97000,49325,16992, 347819,337685,115940,3378, 411865,345210,137288,22218, 529516,400602,176505,42971, -3068606,-37,23 ],
  [ 1500,100,5,2,5, 140055,115552,30557,5346, 233427,205964,46685,5493, 531870,403984,106374,25577, 680162,512067,136032,33619, 874122,590788,174824,56667, -2471289,-32,20 ],
  [ 1500,100,10,2,5, 228430,217642,27971,1321, 484763,418439,40397,5527, 1120252,839153,93354,23425, 1586235,1184719,132186,33460, 2040239,1462030,170020,48184, -1500954,-22,-55 ],
  [ 1500,100,15,2,5, 297660,331875,26459,-3041, 791040,722160,35956,3131, 1902794,1264935,86491,28994, 2829423,1958019,128610,39609, 3670521,2593778,166842,48943, 185386,10,0 ],
  [ 1500,100,25,2,5, 346879,354720,25228,-570, 1416599,1343324,29667,1535, 3963523,2594818,76222,26321, 6222827,4220621,119670,38504, 8397720,5881137,161495,48396, 4672235,50,0 ],
  [ 1500,100,50,2,5, 351026,355522,25073,-321, 2042382,2000124,25744,533, 7404851,4824679,67317,23456, 12032724,8065725,109388,36064, 17119659,11781469,155633,48529, 12265676,78,0 ],
  [ 1500,100,75,2,5, 351026,355522,25073,-321, 2053382,2003463,25667,624, 7513476,4937581,67085,22999, 12218634,8242157,109095,35504, 17410541,12071076,155451,47674, 12294903,78,0 ],
  [ 1500,200,1,2,5, 58825,86534,58825,-27709, 67191,76754,67191,-9563, 226888,130293,226888,96595, 166962,74042,166962,92920, 214844,103012,214844,111832, -3105524,-37,24 ],
  [ 1500,200,2,2,5, 92936,106444,46468,-6754, 125121,90452,62560,17335, 345909,352132,172954,-3111, 333924,313825,166962,10049, 429688,363199,214844,33245, -3284402,-38,25 ],
  [ 1500,200,3,2,5, 127047,113018,42349,4676, 183051,118185,61017,21622, 464929,390770,154976,24720, 500885,379167,166962,40573, 644532,441485,214844,67682, -2644608,-34,21 ],
  [ 1500,200,5,2,5, 179091,123049,39074,12227, 286628,336006,57326,-9876, 688493,449080,137699,47883, 827119,558170,165424,53790, 1063919,776160,212784,57552, -2177989,-29,18 ],
  [ 1500,200,10,2,5, 285832,329533,35000,-5351, 591162,530577,49263,5049, 1402918,940248,116910,38556, 1928596,1288233,160716,53364, 2482745,1803662,206895,56590, -904136,-14,-12 ],
  [ 1500,200,15,2,5, 369385,358986,32834,924, 962104,867530,43732,4299, 2352791,1597104,106945,34349, 3439581,2415455,156345,46551, 4465960,3141424,202998,60206, 1119593,23,0 ],
  [ 1500,200,25,2,5, 428714,370437,31179,4238, 1719091,1645680,36002,1537, 4852619,3239519,93320,31021, 7562273,5092767,145428,47491, 10214727,7037410,196437,61102, 6660986,59,0 ],
  [ 1500,200,50,2,5, 433703,371401,30979,4450, 2475513,2355702,31204,1510, 9024085,5946105,82037,27982, 14617161,9814523,132883,43660, 20817947,14411927,189254,58237, 15915222,87,0 ],
  [ 1500,200,75,2,5, 433703,371401,30979,4450, 2488791,2359728,31110,1613, 9155692,5986520,81747,28296, 14842833,9970913,132525,43499, 21171500,14630964,189031,58398, 16351577,88,0 ],
  [ 2000,0,1,2,5, 30397,81298,30397,-50901, 49494,60957,49494,-11463, 95836,74390,95836,21445, 133085,67070,133085,66015, 171370,78087,171370,93282, -3431437,-39,26 ],
  [ 2000,0,2,2,5, 60794,100719,30397,-19963, 98988,86651,49494,6169, 191671,108660,95836,41505, 266170,191109,133085,37531, 342739,344256,171370,-758, -3224651,-38,24 ],
  [ 2000,0,3,2,5, 91191,106804,30397,-5204, 148483,98609,49494,16625, 287507,205566,95836,27314, 399255,343099,133085,18719, 514109,397732,171370,38792, -2952597,-36,23 ],
  [ 2000,0,5,2,5, 137596,116094,30021,4691, 237659,208795,47532,5773, 468785,378066,93757,18144, 659229,509493,131846,29947, 848802,587007,169760,52359, -2606695,-33,21 ],
  [ 2000,0,10,2,5, 232940,220224,28523,1557, 503421,426201,41952,6435, 1054410,738197,87868,26351, 1537314,1098837,128109,36540, 1982026,1370085,165169,50995, -1299412,-20,-44 ],
  [ 2000,0,15,2,5, 307709,349405,27352,-3706, 831263,825036,37785,283, 1840240,1245118,83647,27051, 2745386,1949425,124790,36180, 3565886,2579770,162086,44823, -317696,-2,0 ],
  [ 2000,0,25,2,5, 360955,360078,26251,64, 1512306,1510403,31671,40, 3943870,2728001,75844,23382, 6072524,4211415,116779,35791, 8150472,5736576,156740,46421, 3848708,46,0 ],
  [ 2000,0,50,2,5, 365453,360980,26104,319, 2200639,2099007,27739,1281, 7522800,5128395,68389,21767, 11846738,8104080,107698,34024, 16606389,11721344,150967,44409, 11408566,76,0 ],
  [ 2000,0,75,2,5, 365453,360980,26104,319, 2212771,2102748,27660,1375, 7637076,5166148,68188,22062, 12033213,8281489,107439,33498, 16888708,11813238,150792,45317, 11759562,77,0 ],
  [ 2000,25,1,2,5, 34961,82175,34961,-47214, 53189,75452,53189,-22263, 114584,78145,114584,36439, 140504,68651,140504,71852, 180954,80139,180954,100815, -3402187,-39,25 ],
  [ 2000,25,2,2,5, 66832,101879,33416,-17524, 105220,87868,52610,8676, 215684,113638,107842,51023, 281007,194259,140504,43374, 361908,348452,180954,6728, -3117252,-37,24 ],
  [ 2000,25,3,2,5, 98703,108247,32901,-3181, 157251,114319,52417,14311, 316784,332032,105595,-5083, 421511,348369,140504,24380, 542863,404523,180954,46113, -3133094,-37,24 ],
  [ 2000,25,5,2,5, 147355,117968,32150,6412, 250960,316096,50192,-13027, 507940,384624,101588,24663, 695969,517262,139194,35741, 896251,612538,179250,56743, -2738967,-34,22 ],
  [ 2000,25,10,2,5, 247291,222980,30280,2977, 530021,506527,44168,1958, 1125077,844137,93756,23412, 1622904,1197173,135242,35478, 2092653,1493623,174388,49919, -1531011,-22,-54 ],
  [ 2000,25,15,2,5, 325640,352849,28946,-2419, 874029,836011,39729,1728, 1952740,1282473,88761,30467, 2897926,2076660,131724,37330, 3764746,2638545,171125,51191, 151603,9,0 ],
  [ 2000,25,25,2,5, 381414,364007,27739,1266, 1587929,1531028,33255,1192, 4166144,2801767,80118,26238, 6407385,4434445,123219,37941, 8604723,6078196,165475,48587, 4676002,50,0 ],
  [ 2000,25,50,2,5, 386122,364950,27580,1512, 2308922,2321552,29104,-159, 7927608,5328089,72069,23632, 12492847,8501200,113571,36288, 17530961,12292883,159372,47619, 12204118,78,0 ],
  [ 2000,25,75,2,5, 386122,364950,27580,1512, 2321623,2325465,29020,-48, 8047630,5366971,71854,23934, 12689263,8665661,113297,35925, 17828948,12501170,159187,47569, 12528922,79,0 ],
  [ 2000,50,1,2,5, 39524,83051,39524,-43527, 56883,76062,56883,-19179, 133332,82050,133332,51282, 147922,70232,147922,77690, 190539,82242,190539,108297, -3329354,-39,25 ],
  [ 2000,50,2,2,5, 72870,103039,36435,-15084, 111452,89086,55726,11183, 239697,195617,119848,22040, 295844,305869,147922,-5013, 381078,352648,190539,14215, -3346425,-39,25 ],
  [ 2000,50,3,2,5, 106216,109689,35405,-1158, 166020,116144,55340,16625, 346062,338083,115354,2660, 443766,353090,147922,30225, 571617,410813,190539,53601, -2991129,-36,23 ],
  [ 2000,50,5,2,5, 157114,119842,34279,8132, 264260,332933,52852,-13735, 547096,407047,109419,28010, 732708,540057,146542,38530, 943701,735989,188740,41542, -2713265,-34,21 ],
  [ 2000,50,10,2,5, 261641,225736,32038,4397, 556621,526888,46385,2478, 1195743,872927,99645,26901, 1708494,1230177,142375,39860, 2203279,1646422,183607,46405, -1320391,-20,-45 ],
  [ 2000,50,15,2,5, 343571,356293,30540,-1131, 916795,860871,41673,2542, 2065239,1396677,93875,30389, 3050465,2123588,138657,42131, 3963606,2796722,180164,53040, 448438,14,0 ],
  [ 2000,50,25,2,5, 401872,367936,29227,2468, 1663552,1565538,34839,2053, 4388418,2952384,84393,27616, 6742247,4614347,129659,40921, 9058975,6435438,174211,50453, 5335696,53,0 ],
  [ 2000,50,50,2,5, 406791,382256,29057,1753, 2417205,2365746,30469,649, 8332417,5620818,75749,24651, 13138957,8992405,119445,37696, 18455533,12994283,167778,49648, 13063726,80,0 ],
  [ 2000,50,75,2,5, 406791,382256,29057,1753, 2417205,2365746,30215,643, 8418010,5652591,75161,24691, 13144832,9008777,117365,36929, 18463605,12996208,164854,48816, 13175863,80,0 ],
  [ 2000,100,1,2,5, 48651,84804,48651,-36153, 64273,77283,64273,-13010, 170828,104375,170828,66453, 162759,73443,162759,89316, 209708,102167,209708,107541, -3228361,-38,25 ],
  [ 2000,100,2,2,5, 84946,105358,42473,-10206, 123915,91520,61958,16197, 287723,220088,143861,33817, 325517,312220,162759,6649, 419416,361091,209708,29163, -3165127,-38,24 ],
  [ 2000,100,3,2,5, 121240,112575,40413,2888, 183557,119793,61186,21255, 404617,364700,134872,13306, 488276,377556,162759,36907, 629125,439115,209708,63336, -2773976,-35,22 ],
  [ 2000,100,5,2,5, 176632,123591,38538,11573, 290860,338836,58172,-9595, 625407,423162,125081,40449, 806187,555596,161237,50118, 1038599,772379,207720,53244, -2313394,-31,19 ],
  [ 2000,100,10,2,5, 290342,332115,35552,-5115, 609820,538340,50818,5957, 1337076,916292,111423,35065, 1879674,1282659,156640,49751, 2424532,1711717,202044,59401, -997616,-15,-21 ],
  [ 2000,100,15,2,5, 379434,363180,33727,1445, 1002327,896705,45560,4801, 2290238,1577286,104102,32407, 3355544,2406711,152525,43129, 4361325,3127265,198242,56094, 904985,20,0 ],
  [ 2000,100,25,2,5, 442790,389131,32203,3902, 1814798,1708258,38006,2231, 4832966,3268152,92942,30093, 7411970,5098187,142538,44496, 9967479,7006089,191682,56950, 6252029,58,0 ],
  [ 2000,100,50,2,5, 448130,390195,32009,4138, 2633770,2528285,33199,1330, 9142034,6129256,83109,27389, 14431175,9852477,131192,41625, 20304677,14267622,184588,54882, 15108251,85,0 ],
  [ 2000,100,75,2,5, 448130,390195,32009,4138, 2648180,2532713,33102,1443, 9279292,6171523,82851,27748, 14657412,10009846,130870,41496, 20649667,14456507,184372,55296, 15570407,86,0 ],
  [ 2000,200,1,2,5, 66905,101646,66905,-34741, 79051,79573,79051,-522, 245821,134510,245821,111311, 192432,79965,192432,112467, 248047,194557,248047,53490, -3084955,-37,24 ],
  [ 2000,200,2,2,5, 109097,109996,54549,-450, 148842,96390,74421,26226, 383774,360565,191887,11605, 384864,340348,192432,22258, 496094,394098,248047,50998, -3047686,-37,23 ],
  [ 2000,200,3,2,5, 151289,118346,50430,10981, 218632,200943,72877,5896, 521727,403420,173909,39436, 577296,396487,192432,60270, 744141,563779,248047,60121, -2544306,-33,20 ],
  [ 2000,200,5,2,5, 215668,216124,47055,-100, 344061,350493,68812,-1286, 782029,561424,156406,44121, 953144,710358,190629,48557, 1228396,913520,245679,62975, -2336567,-31,19 ],
  [ 2000,200,10,2,5, 347744,356476,42581,-1069, 716219,682328,59685,2824, 1619742,1184422,134979,36277, 2222034,1589017,185170,52751, 2867038,2051949,238920,67924, -823735,-12,0 ],
  [ 2000,200,15,2,5, 451158,390292,40103,5410, 1173391,1132540,53336,1857, 2740235,1908105,124556,37824, 3965701,2798816,180259,53040, 5156764,3575010,234398,71898, 1723482,29,0 ],
  [ 2000,200,25,2,5, 524624,404848,38154,8711, 2117291,1996729,44341,2525, 5722062,3913002,110040,34790, 8751416,5970334,168296,53482, 11784486,8275502,226625,67480, 8203731,65,0 ],
  [ 2000,200,50,2,5, 530807,406074,37915,8910, 3066901,2883862,38658,2307, 10761268,7173683,97830,32614, 17015611,11573122,154687,49477, 24002965,16814299,218209,65352, 19035578,93,0 ],
  [ 2000,200,75,2,5, 530807,406074,37915,8910, 3084104,3006663,38551,968, 10851083,7174233,96885,32829, 17163215,11573672,153243,49907, 24227035,16814299,216313,66185, 19282988,94,0 ],
  [ 2500,0,1,2,5, 38478,83074,38478,-44597, 61355,77812,61355,-16457, 114768,78457,114768,36311, 158555,72844,158555,85712, 204572,101322,204572,103250, -3351197,-39,25 ],
  [ 2500,0,2,2,5, 76955,104271,38478,-13658, 122709,92589,61355,15060, 229536,194094,114768,17721, 317111,311115,158555,2998, 409145,359484,204572,24830, -3291230,-39,25 ],
  [ 2500,0,3,2,5, 115433,112132,38478,1100, 184064,195252,61355,-3729, 344305,338630,114768,1891, 475666,375945,158555,33240, 613717,436746,204572,58990, -3137126,-37,24 ],
  [ 2500,0,5,2,5, 174173,137469,38001,8008, 295093,341517,59019,-9285, 562321,397245,112464,33015, 785254,553171,157051,46417, 1013280,768748,202656,48906, -2503492,-32,20 ],
  [ 2500,0,10,2,5, 294852,348033,36104,-6512, 628478,559837,52373,5720, 1271234,892187,105936,31587, 1830753,1277235,152563,46126, 2366319,1703652,197193,55222, -1249107,-19,-34 ],
  [ 2500,0,15,2,5, 389482,367374,34621,1965, 1042551,911996,47389,5934, 2227685,1572134,101258,29798, 3271506,2209249,148705,48284, 4256691,3097536,193486,52689, 990232,21,0 ],
  [ 2500,0,25,2,5, 456865,394489,33227,4536, 1910505,1861452,40011,1027, 4813314,3295434,92564,29190, 7261667,5008174,139647,43336, 9720230,6974168,186928,52809, 5755356,55,0 ],
  [ 2500,0,50,2,5, 462556,395653,33040,4779, 2792027,2731518,35194,763, 9259983,6354472,84182,26414, 14245189,9890832,129502,39585, 19791407,14010126,179922,52557, 14241384,83,0 ],
  [ 2500,0,75,2,5, 462556,395653,33040,4779, 2807568,2736349,35095,890, 9402892,6475591,83954,26137, 14471992,10049178,129214,39489, 20127834,14311260,179713,51934, 14451314,83,0 ],
  [ 2500,25,1,2,5, 43041,83951,43041,-40909, 65049,78422,65049,-13372, 133516,82362,133516,51155, 165974,74424,165974,91550, 214157,103425,214157,110732, -3278364,-38,25 ],
  [ 2500,25,2,2,5, 82993,105431,41497,-11219, 128941,93807,64470,17567, 253549,199072,126775,27239, 331948,329242,165974,1353, 428314,363681,214157,32317, -3205934,-38,24 ],
  [ 2500,25,3,2,5, 122945,113575,40982,3124, 192832,197077,64277,-1415, 373582,344682,124527,9634, 497921,380665,165974,39085, 642471,443036,214157,66478, -2995161,-36,23 ],
  [ 2500,25,5,2,5, 183932,139343,40131,9728, 308393,344469,61679,-7215, 601477,419818,120295,36332, 821993,560990,164399,52201, 1060729,779158,212146,56314, -2331471,-31,19 ],
  [ 2500,25,10,2,5, 309203,350789,37862,-5092, 655078,566313,54590,7397, 1341901,921127,111825,35065, 1916343,1310239,159695,50509, 2476945,1827190,206412,54146, -974006,-15,-21 ],
  [ 2500,25,15,2,5, 407414,384155,36215,2067, 1085316,936706,49333,6755, 2340184,1609339,106372,33220, 3424046,2445095,155638,44498, 4455550,3172083,202525,58339, 1190007,24,0 ],
  [ 2500,25,25,2,5, 477324,398418,34714,5739, 1986128,1895962,41594,1888, 5035588,3537586,96838,28808, 7596529,5311962,146087,43934, 10174482,7316189,195663,54967, 6032167,57,0 ],
  [ 2500,25,50,2,5, 483225,399622,34516,5972, 2900310,2849712,36559,638, 9664791,6555667,87862,28265, 14891298,10368260,135375,41119, 20715979,14695255,188327,54734, 15164401,85,0 ],
  [ 2500,25,75,2,5, 483225,399622,34516,5972, 2916421,2868600,36455,598, 9813446,6783813,87620,27050, 15128042,10448827,135072,41779, 21068074,14902722,188108,55048, 15305689,85,0 ],
  [ 2500,50,1,2,5, 47605,84827,47605,-37222, 68744,79032,68744,-10288, 152265,86267,152265,65998, 173392,76005,173392,97387, 223742,189307,223742,34434, -3265265,-38,25 ],
  [ 2500,50,2,2,5, 89031,106591,44516,-8780, 135172,95024,67586,20074, 277562,204050,138781,36756, 346784,332392,173392,7196, 447484,367877,223742,39803, -3098535,-37,24 ],
  [ 2500,50,3,2,5, 130458,115017,43486,5147, 201601,198902,67200,900, 402860,350733,134287,17376, 520176,385386,173392,44930, 671225,533107,223742,46039, -2912931,-36,23 ],
  [ 2500,50,5,2,5, 193691,141218,42260,11449, 321693,347420,64339,-5145, 640632,427875,128126,42551, 858733,583735,171747,54999, 1108178,789518,221636,63732, -2148052,-29,17 ],
  [ 2500,50,10,2,5, 323553,353545,39619,-3672, 681678,572789,56806,9074, 1412568,935552,117714,39751, 2001933,1408524,166828,49451, 2587572,1866898,215631,60056, -702091,-10,0 ],
  [ 2500,50,15,2,5, 425345,387599,37808,3355, 1128082,1125881,51276,100, 2452683,1722193,111486,33204, 3576585,2506999,162572,48618, 4654410,3343449,211564,59589, 938194,21,0 ],
  [ 2500,50,25,2,5, 497783,402347,36202,6941, 2061751,2004322,43178,1203, 5257862,3611202,101113,31667, 7931390,5491864,152527,46914, 10628734,7544669,204399,59309, 6726382,60,0 ],
  [ 2500,50,50,2,5, 503895,403592,35992,7164, 3008592,2894056,37923,1444, 10069600,6848396,91542,29284, 15537407,10750854,141249,43514, 21640551,15283415,196732,57792, 16319408,88,0 ],
  [ 2500,50,75,2,5, 503895,403592,35992,7164, 3025273,2913116,37816,1402, 10224000,6909136,91286,29597, 15784091,10913306,140929,43489, 22008313,15591154,196503,57296, 16693425,88,0 ],
  [ 2500,100,1,2,5, 56732,86580,56732,-29848, 76133,80252,76133,-4119, 189761,108592,189761,81169, 188229,79216,188229,109013, 242911,193562,242911,49349, -3153098,-37,24 ],
  [ 2500,100,2,2,5, 101107,108910,50553,-3901, 147636,97459,73818,25088, 325588,334571,162794,-4491, 376458,339243,188229,18607, 485822,392491,242911,46666, -3173788,-38,24 ],
  [ 2500,100,3,2,5, 145482,117903,48494,9193, 219138,202552,73046,5529, 461415,377350,153805,28022, 564687,394876,188229,56604, 728733,561410,242911,55775, -2673675,-34,21 ],
  [ 2500,100,5,2,5, 213209,216666,46518,-754, 348293,353324,69659,-1006, 718943,535506,143789,36687, 932212,707784,186442,44886, 1203076,909739,240615,58667, -2471972,-32,20 ],
  [ 2500,100,10,2,5, 352254,359057,43133,-833, 734877,703975,61240,2575, 1553901,1174832,129492,31589, 2173113,1583493,181093,49135, 2808825,2043784,234069,63753, -1054033,-16,-24 ],
  [ 2500,100,15,2,5, 461207,394486,40996,5931, 1213614,1161716,55164,2359, 2677682,1902803,121713,35222, 3881664,2681662,176439,54546, 5052130,3560901,229642,67783, 1635448,28,0 ],
  [ 2500,100,25,2,5, 538700,423542,39178,8375, 2212998,2059307,46346,3219, 5702410,3927120,109662,34140, 8601113,5895396,165406,52033, 11537238,8244132,221870,63329, 7946799,64,0 ],
  [ 2500,100,50,2,5, 545233,424868,38945,8598, 3225158,3160795,40653,811, 10879217,7355334,98902,32035, 16829625,11611077,152997,47441, 23489695,16556403,213543,63030, 17982618,91,0 ],
  [ 2500,100,75,2,5, 545233,424868,38945,8598, 3242977,3180199,40537,785, 11045108,7524380,98617,31435, 17096191,11778085,152645,47483, 23888793,16871248,213293,62657, 18175929,92,0 ],
  [ 2500,200,1,2,5, 74986,103422,74986,-28437, 90912,82542,90912,8369, 264754,215727,264754,49027, 217902,100715,217902,117187, 281250,202171,281250,79079, -3148559,-37,24 ],
  [ 2500,200,2,2,5, 125259,113548,62629,5855, 172562,116214,86281,28174, 421640,368848,210820,26396, 435805,351895,217902,41955, 562500,409277,281250,76612, -2821276,-35,22 ],
  [ 2500,200,3,2,5, 175531,123674,58510,17286, 254213,314201,84738,-19996, 578526,429085,192842,49814, 653707,428833,217902,74958, 843750,586172,281250,85859, -2521541,-32,20 ],
  [ 2500,200,5,2,5, 252245,224163,55035,6127, 401494,379016,80299,4496, 875566,596617,175113,55790, 1079169,753937,215834,65046, 1392873,982570,278575,82061, -1804919,-26,14 ],
  [ 2500,200,10,2,5, 409657,370081,50162,4846, 841276,729879,70106,9283, 1836567,1261562,153047,47917, 2515473,1765765,209623,62476, 3251331,2286496,270944,80403, 218258,9,0 ],
  [ 2500,200,15,2,5, 532932,408261,47372,11082, 1384678,1233386,62940,6877, 3127679,2114406,142167,46058, 4491822,3058091,204174,65170, 5847569,4220937,265799,73938, 3174899,41,0 ],
  [ 2500,200,25,2,5, 620535,439259,45130,13184, 2515490,2361663,52680,3222, 6591506,4465770,126760,40880, 9940560,6847350,191165,59485, 13354245,9512995,256812,73870, 9981771,71,0 ],
  [ 2500,200,50,2,5, 627910,440747,44851,13369, 3658288,3530258,46113,1614, 12498452,8476611,113622,36562, 19414062,13331722,176491,55294, 27187983,19103031,247163,73500, 21689864,98,0 ],
  [ 2500,200,75,2,5, 627910,440747,44851,13369, 3678385,3536464,45980,1774, 12687324,8529755,113280,37121, 19720390,13549420,176075,55098, 27649752,19446257,246873,73245, 22260156,99,0 ],
  [ 3000,0,1,2,5, 46558,84850,46558,-38292, 73215,80781,73215,-7566, 133701,82673,133701,51027, 184026,78617,184026,105409, 237775,192717,237775,45058, -3275935,-38,25 ],
  [ 3000,0,2,2,5, 93117,107823,46558,-7353, 146430,98528,73215,23951, 267402,202527,133701,32437, 368051,337638,184026,15207, 475551,390384,237775,42583, -3054513,-37,23 ],
  [ 3000,0,3,2,5, 139675,117460,46558,7405, 219645,204160,73215,5162, 401103,351280,133701,16607, 552077,393265,184026,52937, 713326,559040,237775,51429, -2803043,-35,22 ],
  [ 3000,0,5,2,5, 210750,217208,45982,-1409, 352526,370039,70505,-3503, 655858,509439,131172,29284, 911279,705209,182256,41214, 1177757,905908,235551,54370, -2650961,-33,21 ],
  [ 3000,0,10,2,5, 356764,361638,43685,-597, 753535,711737,62795,3483, 1488059,1073876,124005,34515, 2124191,1577919,177016,45523, 2750612,2035619,229218,59583, -1030750,-16,-25 ],
  [ 3000,0,15,2,5, 471256,398680,41889,6451, 1253838,1190892,56993,2861, 2615129,1776935,118869,38100, 3797627,2672418,172619,51146, 4947495,3546243,224886,63693, 1666218,29,0 ],
  [ 3000,0,25,2,5, 552776,428900,40202,9009, 2308705,2300236,48350,177, 5682757,3954253,109284,33240, 8450810,5885690,162516,49329, 11289989,8128381,217115,60800, 7113971,61,0 ],
  [ 3000,0,50,2,5, 552776,428900,39484,8848, 2308705,2300236,29101,107, 9473529,6528883,86123,26770, 8706332,6113511,79148,23571, 11631287,8431585,105739,29088, 9958733,71,0 ],
  [ 3000,0,75,2,5, 559660,430326,39976,9238, 3402366,3279484,42530,1536, 11168707,7709383,99721,30887, 16910770,11831844,150989,45348, 23366960,16612861,208634,60304, 17720864,91,0 ],
  [ 3000,25,1,2,5, 51122,85727,51122,-34605, 76910,81391,76910,-4481, 152449,86578,152449,65871, 191444,95174,191444,96270, 247360,194819,247360,52541, -3225205,-38,24 ],
  [ 3000,25,2,2,5, 99155,108983,49577,-4914, 152661,99745,76331,26458, 291415,313555,145707,-11070, 382888,341288,191444,20800, 494720,395080,247360,49820, -3192492,-38,24 ],
  [ 3000,25,3,2,5, 147187,118902,49062,9428, 228413,205985,76138,7476, 430380,371846,143460,19511, 574332,413012,191444,53774, 742080,565381,247360,58900, -2716682,-34,21 ],
  [ 3000,25,5,2,5, 220509,219082,48111,311, 365826,372991,73165,-1433, 695013,532011,139003,32600, 948018,713028,189604,46998, 1225206,916318,245041,61778, -2478939,-32,20 ],
  [ 3000,25,10,2,5, 371115,364394,45443,823, 780135,718213,65011,5160, 1558725,1179816,129894,31576, 2209781,1595947,184148,51153, 2861238,2075377,238437,65488, -850309,-13,0 ],
  [ 3000,25,15,2,5, 489187,402124,43483,7739, 1296604,1201867,58937,4306, 2727628,1920340,123983,36695, 3950166,2828306,179553,50994, 5146355,3818060,233925,60377, 1697049,29,0 ],
  [ 3000,25,25,2,5, 573234,432829,41690,10211, 2384328,2334746,49934,1038, 5905031,4104869,113558,34618, 8785672,6189029,168955,49935, 11744241,8356861,225851,65142, 7683292,63,0 ],
  [ 3000,25,50,2,5, 580329,434295,41452,10431, 3491697,3496107,44013,-56, 11401975,7767079,103654,33045, 17289749,12046502,157180,47666, 23900996,16983937,217282,62882, 18147209,91,0 ],
  [ 3000,25,75,2,5, 580329,434295,41452,10431, 3511218,3502200,43890,113, 11579261,8016256,103386,31813, 17566820,12216516,156847,47771, 24307200,17316963,217029,62413, 18233673,92,0 ],
  [ 3000,50,1,2,5, 55685,86603,55685,-30918, 80604,82001,80604,-1397, 171197,104998,171197,66199, 198862,96805,198862,102058, 256945,196972,256945,59973, -3185872,-38,24 ],
  [ 3000,50,2,2,5, 104443,86603,52221,8920, 117405,82001,58703,17702, 184015,104998,92007,39508, 219647,218582,109823,532, 375661,277356,187830,49152, -2963417,-36,23 ],
  [ 3000,50,3,2,5, 154700,120345,51567,11451, 237182,207810,79061,9791, 459658,377898,153219,27253, 596587,417732,198862,59619, 770834,571671,256945,66388, -2574718,-33,20 ],
  [ 3000,50,5,2,5, 230268,220956,50240,2032, 379126,375943,75825,637, 734169,540069,146834,38820, 984758,735774,196952,49797, 1272655,942349,254531,66061, -2306694,-30,19 ],
  [ 3000,50,10,2,5, 385465,367151,47200,2243, 806735,724689,67228,6837, 1629392,1194241,135783,36263, 2295372,1628901,191281,55539, 2971865,2198865,247655,64417, -541707,-7,0 ],
  [ 3000,50,15,2,5, 507118,405568,45077,9027, 1339369,1226726,60880,5120, 2840127,2034545,129097,36617, 4102706,2875234,186487,55794, 5345215,3876786,242964,66747, 2064791,32,0 ],
  [ 3000,50,25,2,5, 593693,436758,43178,11413, 2459951,2369256,51517,1899, 6127305,4163971,117833,37756, 9120533,6368881,175395,52916, 12198493,8698382,234586,67310, 8564157,66,0 ],
  [ 3000,50,50,2,5, 594979,438052,42499,11209, 3398927,2391756,42844,12695, 11312640,8072067,102842,29460, 16316506,8016713,148332,75453, 21423237,14241818,194757,65286, 25194018,104,0 ],
  [ 3000,50,75,2,5, 600998,438265,42928,11624, 3618629,3546717,45233,899, 11966991,8127794,106848,34279, 18220187,12598442,162680,50194, 25247439,17907976,225424,65531, 19783464,95,0 ],
  [ 3000,100,1,2,5, 64812,101693,64812,-36880, 87993,83071,87993,4922, 208694,112808,208694,95885, 213699,100116,213699,113583, 276114,201326,276114,74788, -3094898,-37,24 ],
  [ 3000,100,2,2,5, 117268,112462,58634,2403, 171356,117283,85678,27037, 363453,342854,181727,10300, 427398,350789,213699,38304, 552228,407669,276114,72279, -2947379,-36,23 ],
  [ 3000,100,3,2,5, 169724,123231,56575,15498, 254719,315810,84906,-20363, 518213,403015,172738,38399, 641098,427222,213699,71292, 828342,583802,276114,81513, -2650910,-34,21 ],
  [ 3000,100,5,2,5, 249786,224705,54499,5472, 405727,381846,81145,4776, 812480,570700,162496,48356, 1058236,751362,211647,61375, 1367554,963119,273511,80887, -1929151,-27,15 ],
  [ 3000,100,10,2,5, 414167,385999,50714,3449, 859934,825226,71661,2892, 1770725,1251971,147560,43229, 2466552,1760391,205546,58847, 3193117,2262810,266093,77526, -289341,-2,0 ],
  [ 3000,100,15,2,5, 542981,425792,48265,10417, 1424901,1248526,64768,8017, 3065126,2109254,139324,43449, 4407784,3049547,200354,61738, 5742934,4093938,261042,74954, 2996313,39,0 ],
  [ 3000,100,25,2,5, 634136,529653,46119,7599, 2592289,2078425,54289,10762, 6142692,3652834,118129,47882, 9017808,5469651,173419,68234, 13106996,9379223,252058,71688, 12238575,77,0 ],
  [ 3000,100,50,2,5, 641486,531092,45820,7885, 3854409,3700533,48585,1940, 9786666,8195532,88970,14465, 14114152,5594672,128310,77450, 20366303,9497330,185148,98809, 21419961,98,0 ],
  [ 3000,100,75,2,5, 642337,531241,45881,7935, 3834061,3702840,47926,1640, 12810923,8833323,114383,35514, 19475990,13419899,173893,54072, 26928106,19140575,240430,69532, 21034944,97,0 ],
  [ 3000,200,1,2,5, 89677,539619,89677,-449942, 691304,4087564,691304,-3396260, 283687,228490,283687,55197, 259821,199381,259821,60440, 9658823,8626391,9658823,1032432, -14979723,-81,59 ],
  [ 3000,200,2,2,5, 141420,117100,70710,12160, 196283,196003,98141,140, 459505,391796,229753,33854, 486745,378467,243373,54139, 628905,440226,314453,94340, -2851840,-35,22 ],
  [ 3000,200,3,2,5, 199773,142338,66591,19145, 289794,336844,96598,-15683, 635324,441585,211775,64580, 730118,541587,243373,62844, 943358,737876,314453,68494, -2447261,-32,19 ],
  [ 3000,200,5,2,5, 288822,333068,63016,-9654, 458927,392153,91785,13355, 969102,738011,193820,46218, 1205194,879822,241039,65074, 1557351,1234871,311470,64496, -2136456,-29,17 ],
  [ 3000,200,10,2,5, 470183,397024,57573,8958, 966333,865014,80528,8443, 2053391,1415552,171116,53153, 2804967,1985792,233747,68265, 3252778,2337552,271065,76269, 534473,15,0 ],
  [ 3000,200,15,2,5, 614705,439567,54640,15568, 1595965,1498396,72544,4435, 3515124,2439923,159778,48873, 5017942,3426526,228088,72337, 6538373,4754024,297199,81107, 3696483,44,0 ],
  [ 3000,200,25,2,5, 672184,529415,48886,10383, 2071545,1520896,43383,11532, 7460949,4173995,143480,63211, 10403456,7747492,200066,51076, 11640793,8318229,223861,63895, 12735649,79,0 ],
  [ 3000,200,50,2,5, 725014,547119,51787,12707, 4249676,4162768,53567,1095, 14235635,9702689,129415,41209, 21778794,12413646,197989,85138, 30373000,21573012,276118,80000, 28041743,108,0 ],
  [ 3000,200,75,2,5, 725014,547119,51787,12707, 4273182,4170064,53415,1289, 14453139,9762198,129046,41883, 22159169,15317109,197850,61090, 30888878,21831188,275794,80872, 24915054,103,0 ],
  [ 1000,0,1,3,5, 20499,78861,20499,-58361, 34570,56904,34570,-22333, 71058,68549,71058,2508, 95481,58298,95481,37183, 116950,65448,116950,51502, -3612121,-41,27 ],
  [ 1000,0,2,3,5, 32883,79318,16442,-23218, 38711,76468,19356,-18878, 107226,81021,53613,13102, 184378,75896,92189,54241, 185137,190016,92569,-2440, -3492700,-40,26 ],
  [ 1000,0,3,3,5, 61498,86154,20499,-8219, 103711,86599,34570,5704, 213173,111043,71058,34043, 286443,193196,95481,31082, 350851,343994,116950,2286, -3070108,-37,23 ],
  [ 1000,0,5,3,5, 92797,105061,20247,-2676, 164838,114858,32968,9996, 345998,334595,69200,2281, 435160,332437,87032,20545, 580096,409399,116019,34139, -2994038,-36,23 ],
  [ 1000,0,10,3,5, 157127,116523,19240,4972, 341016,351665,28418,-887, 767219,535603,63935,19301, 1112927,750013,92744,30243, 1360208,949533,113351,34223, -1910068,-27,15 ],
  [ 1000,0,15,3,5, 207593,138861,18453,6109, 551755,523409,25080,1288, 1323571,883838,60162,19988, 1990983,1287673,90499,31969, 2457114,1701233,111687,34358, -447831,-6,0 ],
  [ 1000,0,25,3,5, 243554,145287,17713,7147, 971726,890368,20350,1704, 2769162,1744942,53253,19697, 4380631,2857905,84243,29283, 5677799,3872215,109188,34723, 3134733,41,0 ],
  [ 1000,0,50,3,5, 246594,145832,17614,7197, 1386663,1259417,17479,1604, 5144603,3256449,46769,17165, 8435165,5470666,76683,26950, 11687750,7881911,106252,34599, 8817470,67,0 ],
  [ 1000,0,75,3,5, 246594,145832,17614,7197, 1393968,1275449,17425,1481, 5218809,3269968,46597,17400, 8563992,5510917,76464,27260, 11889111,8067651,106153,34120, 9070806,68,0 ],
  [ 1000,25,1,3,5, 26329,79980,26329,-53651, 39360,57705,39360,-18344, 92439,73001,92439,19439, 104104,60137,104104,43967, 127630,67792,127630,59837, -3525446,-40,26 ],
  [ 1000,25,2,3,5, 48952,84035,24476,-26825, 77332,80292,38666,9814, 169950,88231,46220,9719, 208208,96722,52052,21983, 255259,194695,63815,29919, -3525409,-40,26 ],
  [ 1000,25,3,3,5, 71574,101426,23858,-9951, 115303,88845,38434,8819, 247461,195126,82487,17445, 296588,307297,98863,-3570, 382139,230288,127380,50617, -3226217,-38,24 ],
  [ 1000,25,5,3,5, 106109,107618,23151,-329, 182522,118745,36504,12755, 366955,344150,73391,4561, 491952,379998,98390,22391, 585115,436637,117023,29696, -2882417,-35,22 ],
  [ 1000,25,10,3,5, 177050,120349,21680,6943, 376656,374148,31388,209, 852848,552922,71071,24994, 1213156,866359,101096,28900, 1484219,992107,123685,41009, -1611666,-24,12 ],
  [ 1000,25,15,3,5, 232673,143678,20682,7911, 609331,538077,27697,3239, 1461574,926356,66435,24328, 2170372,1435510,98653,33403, 2680817,1849177,121855,37802, 90289,7,0 ],
  [ 1000,25,25,3,5, 272273,222503,19802,3620, 1074235,932096,22497,2977, 3046495,2012662,58586,19881, 4777802,3174685,91881,30829, 6192495,4242949,119086,37491, 3370032,42,0 ],
  [ 1000,25,50,3,5, 275616,223106,19687,3751, 1534053,1506743,19337,344, 5657355,3583531,51431,18853, 9208593,5989821,83714,29262, 12743433,8626278,115849,37429, 9324298,69,0 ],
  [ 1000,25,75,3,5, 275616,223106,19687,3751, 1542141,1509126,19277,413, 5739046,3613185,51241,18981, 9349527,6141051,83478,28647, 12962917,8770957,115740,37428, 9499657,70,0 ],
  [ 1000,50,1,3,5, 32160,81100,32160,-48940, 44151,58505,44151,-14355, 113821,77452,113821,36369, 112727,61976,112727,50750, 138309,70137,138309,68172, -3438771,-39,26 ],
  [ 1000,50,2,3,5, 56905,85562,28453,-14329, 85523,81891,42762,1816, 197785,108512,98893,44636, 225453,180744,112727,22354, 276618,199424,138309,38597, -3180128,-38,24 ],
  [ 1000,50,3,3,5, 81651,103361,27217,-7237, 126896,91241,42299,11885, 281749,202208,93916,26514, 338180,312788,112727,8464, 414926,358171,138309,18919, -3115937,-37,24 ],
  [ 1000,50,5,3,5, 103893,103361,22668,116, 199536,91241,39907,21659, 294091,256237,58818,7571, 355893,381308,71179,-5083, 437268,512399,87454,-15026, -3059358,-37,23 ],
  [ 1000,50,10,3,5, 196974,137512,24119,7281, 412297,382595,34358,2475, 938477,690957,78206,20627, 1313385,888120,109449,35439, 1608229,1232302,134019,31327, -1602991,-23,12 ],
  [ 1000,50,15,3,5, 257752,220195,22911,3338, 666908,566629,30314,4558, 1599577,1074774,72708,23855, 2349761,1597022,106807,34215, 2904520,2042551,132024,39180, -6054,5,0 ],
  [ 1000,50,25,3,5, 300992,328885,21890,-2029, 1176744,1150523,24644,549, 3323827,2110347,63920,23336, 5174974,3384055,99519,34441, 6707191,4698013,128984,38638, 3413955,43,0 ],
  [ 1000,50,50,3,5, 304638,329547,21760,-1779, 1681443,1560483,21195,1525, 6170108,3926778,56092,20394, 9982020,6523702,90746,31439, 13799115,9440855,125446,39621, 10213915,72,0 ],
  [ 1000,50,75,3,5, 304638,329547,21760,-1779, 1690314,1563102,21129,1590, 6259284,3957901,55886,20548, 10135062,6649249,90492,31123, 14036723,9521244,125328,40317, 10519327,73,0 ],
  [ 1000,100,1,3,5, 43820,83339,43820,-39520, 53731,60107,53731,-6376, 156584,100869,156584,55714, 129972,65704,129972,64268, 159667,74875,159667,84792, -3298922,-38,25 ],
  [ 1000,100,2,3,5, 72811,101954,36406,-14571, 101906,84938,50953,8484, 253455,197046,126727,28204, 259944,188225,129972,35860, 319334,322022,159667,-1344, -3232558,-38,24 ],
  [ 1000,100,3,3,5, 101670,107232,33890,-1854, 148718,96033,49573,17562, 336074,336938,112025,-288, 334672,339297,111557,-1542, 417862,372688,139287,15058, -3234094,-38,24 ],
  [ 1000,100,5,3,5, 146043,115287,31864,6710, 235571,204253,47114,6264, 531847,401847,106369,26000, 645316,422237,129063,44616, 791891,570888,158378,44201, -2391890,-31,19 ],
  [ 1000,100,10,3,5, 236820,216865,28998,2444, 483578,413674,40298,5825, 1109734,754775,92478,29580, 1513843,960296,126154,46129, 1856249,1301930,154687,46193, -1075368,-17,-32 ],
  [ 1000,100,15,3,5, 300928,330508,26749,-2629, 741020,687008,33683,2455, 1478987,1194459,67227,12933, 2185393,971973,99336,55155, 2678010,1302769,121728,62511, 56921,8,0 ],
  [ 1000,100,25,3,5, 353472,351599,25707,136, 925880,1188939,19390,-5509, 3047785,2361602,58611,13196, 4555134,3762815,87599,15237, 5793606,1350199,111415,85450, 1659350,30,0 ],
  [ 1000,100,50,3,5, 362683,354031,25906,618, 1976222,1862899,24910,1428, 7195613,4491057,65415,24587, 11528875,7546735,104808,36201, 15910479,10801078,144641,46449, 12690618,79,0 ],
  [ 1000,100,75,3,5, 362683,354031,25906,618, 1986660,1865991,24833,1508, 7299760,4602119,65176,24086, 11706133,7596914,104519,36689, 16184335,10972887,144503,46531, 12958208,80,0 ],
  [ 1000,200,1,3,5, 108212,113690,108212,-5478, -4627,81892,-4627,-86519, 4080009,616814,4080009,3463195, 6544569,9667234,6544569,-3122665, 9237330,13739437,9237330,-4502107, -3515325,-43,22 ],
  [ 1000,200,2,3,5, 104624,108064,52312,-1720, 134672,91332,67336,21670, 364794,355194,182397,4800, 328926,311945,164463,8491, 404768,357009,202384,23880, -3197398,-38,24 ],
  [ 1000,200,3,3,5, 142108,114973,47369,9045, 196451,193352,65484,1033, 487478,394148,162493,31110, 493390,376338,164463,39017, 607153,416523,202384,63543, -2758251,-34,22 ],
  [ 1000,200,5,3,5, 199289,125514,43481,16096, 306303,338032,61261,-6346, 717695,529935,143539,37552, 816456,553787,163291,52534, 1003686,745417,200737,51654, -2198163,-30,18 ],
  [ 1000,200,10,3,5, 316514,333037,38757,-2023, 626139,534298,52178,7653, 1452250,945048,121021,42267, 1914760,1279889,159563,52906, 2352290,1670899,196024,56783, -598230,-8,0 ],
  [ 1000,200,15,3,5, 408229,363298,36287,3994, 1012366,872841,46017,6342, 2427593,1602498,110345,37504, 3426095,2402331,155732,46535, 4246735,2969286,193033,58066, 1528358,27,0 ],
  [ 1000,200,25,3,5, 473306,388652,34422,6157, 1791796,1652640,37525,2914, 4979042,2679311,95751,44226, 7511731,2940258,144456,87913, 9595849,6242244,184536,64492, 11783589,76,0 ],
  [ 1000,200,50,3,5, 478773,389663,34198,6365, 2565782,2363531,32342,2549, 9246623,5924730,84060,30199, 14622585,9606878,132933,45597, 20133209,13803575,183029,57542, 17105038,89,0 ],
  [ 1000,200,75,3,5, 478773,389663,34198,6365, 2579351,2367568,32242,2647, 9380711,5964670,83756,30500, 14848274,9790372,132574,45160, 20479558,13906883,182853,58685, 17586455,90,0 ],
  [ 1500,0,1,3,5, 41499,81418,41499,-39919, 284687,749974,284687,-465287, 2827695,73720,2827695,2753975, 190968,2967682,190968,-2776714, 250074,4693304,250074,-4443230, -5989598,-55,32 ],
  [ 1500,0,2,3,5, 62238,100959,31119,-19360, 86162,86343,43081,-90, 159653,90850,79826,34402, 290037,187003,145018,51517, 281100,197689,140550,41705, -3190078,-38,24 ],
  [ 1500,0,3,3,5, 96407,107163,32136,-3586, 151412,98147,50471,17755, 282794,203554,94265,26413, 355862,321964,118621,11299, 432003,369619,144001,20795, -2999495,-36,23 ],
  [ 1500,0,5,3,5, 145468,116637,31738,6290, 241933,207995,48387,6788, 460942,374713,92188,17246, 620952,419264,124190,40338, 763649,566689,152730,39392, -2540444,-33,20 ],
  [ 1500,0,10,3,5, 246280,221152,30157,3077, 509558,424079,42463,7123, 1035659,730143,86305,25460, 1456679,953836,121390,41904, 1791082,1292829,149257,41521, -1232979,-19,-43 ],
  [ 1500,0,15,3,5, 325347,350640,28920,-2248, 837503,820938,38068,753, 1805959,1230345,82089,26164, 2610016,1773978,118637,38002, 3234366,2255639,147017,44488, -208587,1,0 ],
  [ 1500,0,25,3,5, 381664,361538,27757,1464, 1513335,1487413,31693,543, 3864428,2572895,74316,24837, 5792468,3897695,111394,36438, 7456703,5190586,143398,43579, 4122716,47,0 ],
  [ 1500,0,50,3,5, 386423,362459,27602,1712, 2193786,2070515,27653,1554, 7361743,4829918,66925,23017, 11311329,7587983,102830,33849, 15325550,10535930,139323,43542, 11776961,77,0 ],
  [ 1500,0,75,3,5, 386423,362459,27602,1712, 2205779,2088046,27572,1472, 7473340,4866264,66726,23277, 11489354,7762810,102584,33273, 15589542,10819817,139192,42587, 11922330,77,0 ],
  [ 1500,25,1,3,5, 37966,82538,37966,-44572, 55261,75489,55261,-20228, 115646,78021,115646,37625, 133709,66857,133709,66853, 164624,76234,164624,88390, -3398438,-39,25 ],
  [ 1500,25,2,3,5, 57187,100221,28593,-21517, 81760,75489,40880,3135, 215186,109469,107593,52858, 249393,190348,124696,29523, 273103,251748,136551,10677, -3211491,-38,24 ],
  [ 1500,25,3,3,5, 106483,109098,35494,-872, 163005,114428,54335,16192, 317082,331052,105694,-4656, 401128,342982,133709,19382, 493872,392803,164624,33690, -3132618,-37,24 ],
  [ 1500,25,5,3,5, 158779,119194,34643,8637, 259616,316231,51923,-11323, 507404,382769,101481,24927, 663737,428318,132747,47084, 816598,577756,163320,47768, -2615475,-33,21 ],
  [ 1500,25,10,3,5, 266204,224978,32596,5048, 545199,506526,45433,3223, 1121288,839128,93441,23513, 1556908,1098384,129742,38210, 1915092,1335504,159591,48299, -1376323,-21,-48 ],
  [ 1500,25,15,3,5, 350426,355457,31149,-447, 895079,835606,40685,2703, 1943962,1272863,88362,30504, 2789405,1949967,126791,38156, 3458068,2532344,157185,42078, 196178,9,0 ],
  [ 1500,25,25,3,5, 410384,380390,29846,2181, 1615844,1528991,33840,1819, 4141760,2763615,79649,26503, 6189640,4214625,119032,37981, 7971399,5629579,153296,45035, 4725242,50,0 ],
  [ 1500,25,50,3,5, 414527,381370,29609,2368, 2234359,2048087,28164,2348, 7874495,5174515,71586,24545, 11788782,8107338,107171,33468, 15312549,11349606,139205,36027, 11746375,76,0 ],
  [ 1500,25,75,3,5, 414527,381370,29609,2368, 2234359,2320572,27929,-1078, 7874495,5271966,70308,23237, 11959661,8269608,106783,32947, 16202582,11553533,144666,41509, 11162852,75,0 ],
  [ 1500,50,1,3,5, 43796,83657,43796,-39862, 60051,76290,60051,-16239, 137028,82472,137028,54556, 142332,68696,142332,73636, 175303,78578,175303,96725, -3311763,-39,25 ],
  [ 1500,50,2,3,5, 80177,104014,40089,-11918, 117324,89540,58662,13892, 244199,195853,122100,24173, 284664,194333,142332,45165, 350607,345319,175303,2644, -3161941,-38,24 ],
  [ 1500,50,3,3,5, 116534,111034,38845,1833, 157822,116825,52607,13666, 317590,338134,105863,-6848, 426996,348473,142332,26174, 525910,399817,175303,42031, -3090538,-37,24 ],
  [ 1500,50,5,3,5, 172091,121750,37547,10983, 277299,334002,55460,-11341, 553866,406689,110773,29435, 706522,517730,141304,37759, 869547,589322,173909,56045, -2559492,-33,20 ],
  [ 1500,50,10,3,5, 286127,329671,35036,-5332, 580839,527508,48403,4444, 1206917,856447,100576,29206, 1657137,1201253,138095,37990, 2039102,1463359,169925,47979, -1529226,-22,-50 ],
  [ 1500,50,15,3,5, 375506,360274,33378,1354, 952656,864159,43303,4023, 2081965,1392231,94635,31352, 2968794,2082827,134945,40271, 3681771,2596457,167353,49332, 596054,16,0 ],
  [ 1500,50,25,3,5, 439103,385905,31935,3869, 1718352,1570718,35986,3092, 4419093,2925285,84983,28727, 6586811,4530905,126669,39537, 8486095,5999812,163194,47813, 5499903,54,0 ],
  [ 1500,50,50,3,5, 444467,386944,31748,4109, 2488566,2372931,31368,1458, 8387248,5577247,76248,25545, 12858183,8639669,116893,38350, 17436915,12093524,158517,48576, 13649872,81,0 ],
  [ 1500,50,75,3,5, 444467,386944,31748,4109, 2502125,2390934,31277,1390, 8513816,5616532,76016,25869, 13060425,8776306,116611,38251, 17737154,12271960,158367,48796, 14019413,82,0 ],
  [ 1500,100,1,3,5, 247309,85897,247309,161412, 697365,1024820,697365,-327455, 179791,95390,179791,84401, 1154165,72424,1154165,1081741, 4217556,79817,4217556,4137739, 964982,23,0 ],
  [ 1500,100,2,3,5, 96084,107069,48042,-5492, 133707,92737,66854,20485, 299869,221902,149934,38983, 319155,310173,159578,4491, 393324,354726,196662,19299, -3096122,-37,24 ],
  [ 1500,100,3,3,5, 136712,114904,45571,7269, 197782,195467,65927,772, 419947,366814,139982,17711, 478733,374482,159578,34750, 589985,413894,196662,58697, -2903848,-36,22 ],
  [ 1500,100,5,3,5, 198714,140200,43356,12767, 312665,341624,62533,-5792, 646790,425801,129358,44198, 792092,550964,158418,48226, 975444,741367,195089,46815, -2224913,-30,18 ],
  [ 1500,100,10,3,5, 325974,350660,39915,-3023, 652120,544553,54343,8964, 1378175,920416,114848,38147, 1857595,1273579,154800,48668, 2287122,1661948,190594,52098, -810533,-12,0 ],
  [ 1500,100,15,3,5, 425665,369907,37837,4956, 1067808,907379,48537,7292, 2357970,1583168,107180,35218, 3327572,2203408,151253,51098, 4129176,2937776,187690,54155, 1610414,28,0 ],
  [ 1500,100,25,3,5, 496541,396937,36112,7244, 1923370,1728024,40280,4091, 4973758,3277675,95649,32617, 7381153,4960221,141945,46556, 9515486,6612018,182990,55836, 7139072,61,0 ],
  [ 1500,100,50,3,5, 502512,398092,35894,7459, 2783346,2675497,35084,1359, 9412753,6156041,85570,29606, 14405038,9662603,130955,43113, 19548279,13521756,177712,54787, 16099207,87,0 ],
  [ 1500,100,75,3,5, 502512,398092,35894,7459, 2798471,2680088,34981,1480, 9554291,6304465,85306,29016, 14631495,9832831,130638,42845, 19884766,13753363,177543,54745, 16273219,88,0 ],
  [ 1500,200,1,3,5, 78776,103712,78776,-24936, 88793,80945,88793,7848, 265316,215209,265316,50107, 194069,79980,194069,114088, 239379,192346,239379,47033, -3160812,-38,24 ],
  [ 1500,200,2,3,5, 127896,113178,63948,7359, 166473,99131,83236,33671, 411208,365534,205604,22837, 388137,340361,194069,23888, 478757,389663,239379,44547, -2889491,-36,22 ],
  [ 1500,200,3,3,5, 177017,122645,59006,18124, 244153,205051,81384,13034, 557099,409659,185700,49147, 582206,396497,194069,61903, 718136,541449,239379,58896, -2317384,-31,18 ],
  [ 1500,200,5,3,5, 251960,222126,54973,6509, 383398,357168,76680,5246, 832639,570054,166528,52517, 963232,710717,192646,50503, 1187239,902857,237448,56877, -2020291,-28,16 ],
  [ 1500,200,10,3,5, 405667,365966,49674,4861, 794682,711062,66223,6968, 1720691,1215089,143391,42134, 2258512,1592172,188209,55528, 2783163,2029367,231930,62816, -301523,-2,0 ],
  [ 1500,200,15,3,5, 525983,402510,46754,10975, 1298114,1171870,59005,5738, 2909981,2026005,132272,40181, 4045128,2806829,183869,56286, 5023986,3522192,228363,68263, 2417286,35,0 ],
  [ 1500,200,25,3,5, 611417,432336,44467,13024, 2333405,2072985,48867,5454, 6083088,4057954,116982,38945, 8969838,6103055,172497,55130, 11574270,8077931,222582,67237, 9501713,69,0 ],
  [ 1500,200,50,3,5, 617856,433722,44133,13152, 2541746,3081732,32039,-6807, 9146075,7214835,83146,17557, 13755542,11277215,125050,22530, 18322532,15809988,166568,22841, 5448241,54,0 ],
  [ 1500,200,75,3,5, 618602,433724,44186,13206, 3391162,3181665,42390,2619, 11635242,7623451,103886,35820, 17773637,11917279,158693,52289, 24179989,16770590,215893,66155, 21104185,97,0 ],
  [ 2000,0,1,3,5, 43772,83975,43772,-40204, 66371,78588,66371,-12216, 117472,78740,117472,38732, 154692,71737,154692,82955, 190939,82381,190939,108558, -3314522,-39,25 ],
  [ 2000,0,2,3,5, 87543,106074,43772,-9265, 132743,94142,66371,19300, 234944,194660,117472,20142, 309384,308902,154692,241, 381879,352944,190939,14467, -3240225,-38,24 ],
  [ 2000,0,3,3,5, 131315,114835,43772,5493, 199114,197581,66371,511, 352416,339480,117472,4312, 464076,372626,154692,30483, 572818,411265,190939,53851, -3049446,-37,23 ],
  [ 2000,0,5,3,5, 198139,141550,43230,12347, 319027,345366,63805,-5268, 575885,413181,115177,32541, 767728,548041,153546,43937, 947202,737218,189440,41997, -2406968,-32,19 ],
  [ 2000,0,10,3,5, 335434,354947,41074,-2389, 678100,568842,56508,9105, 1304100,895634,108675,34039, 1800431,1267120,150036,44443, 2221955,1652797,185163,47430, -1011728,-16,-27 ],
  [ 2000,0,15,3,5, 441489,386571,39243,4882, 851566,583990,38708,12163, 1855290,905782,84331,43159, 2566284,1277712,116649,58571, 3159269,1667945,143603,67787, 2793029,38,0 ],
  [ 2000,0,25,3,5, 519720,418558,37798,7357, 1705350,1995343,35714,-6073, 4111856,3310684,79074,15407, 7204306,4964237,138544,43078, 9235606,6576116,177608,51144, 3116284,41,0 ],
  [ 2000,0,50,3,5, 526252,419857,37589,7600, 3000909,2896848,37827,1312, 9578883,6480237,87081,28170, 14187492,9703950,128977,40759, 18963350,13369748,172394,50851, 15040141,85,0 ],
  [ 2000,0,75,3,5, 526252,419857,37589,7600, 3017591,2901992,37720,1445, 9727872,6525045,86856,28597, 14414717,9889866,128703,40400, 19289973,13570583,172232,51066, 15466000,86,0 ],
  [ 2000,25,1,3,5, 49602,85095,49602,-35493, 71162,79389,71162,-8227, 138853,83191,138853,55662, 163315,73576,163315,89739, 201619,100397,201619,101222, -3239020,-38,25 ],
  [ 2000,25,2,3,5, 95497,107601,47748,-6052, 140934,95740,70467,22597, 262778,200427,131389,31176, 326630,327543,163315,-457, 403237,357623,201619,22807, -3132632,-37,24 ],
  [ 2000,25,3,3,5, 141391,116771,47130,8207, 210707,199977,70236,3576, 386704,346563,128901,13380, 489944,378117,163315,37276, 604856,433950,201619,56969, -2887901,-36,22 ],
  [ 2000,25,5,3,5, 211450,144106,46135,14693, 336710,349252,67342,-2508, 622347,422737,124469,39922, 810513,557095,162103,50684, 1000151,764456,200030,47139, -2166551,-29,17 ],
  [ 2000,25,10,3,5, 355357,358774,43513,-418, 713741,681789,59478,2663, 1389729,926118,115811,38634, 1900660,1288231,158388,51036, 2345965,1694972,195497,54249, -889237,-13,-15 ],
  [ 2000,25,15,3,5, 468180,394669,41616,6534, 1180827,1148521,53674,1468, 2426350,1696221,110289,33188, 3408438,2434772,154929,44258, 4235319,3001420,192515,56086, 982733,21,0 ],
  [ 2000,25,25,3,5, 523785,394669,38093,9390, 1719947,1148521,36020,11967, 3942067,3551730,75809,7506, 5622053,5278976,108116,6598, 7927619,5400357,152454,48601, 2125189,32,0 ],
  [ 2000,25,50,3,5, 555274,425431,39662,9275, 3148299,3056439,39684,1158, 10091635,6821984,91742,29724, 14960919,10251308,136008,42815, 20019032,14001125,181991,54708, 16123900,87,0 ],
  [ 2000,25,75,3,5, 555274,425431,39662,9275, 3165764,3061819,39572,1299, 10248110,6868262,91501,30177, 15200252,10396564,135717,42890, 20363779,14303199,181819,54112, 16583896,88,0 ],
  [ 2000,50,1,3,5, 55432,86215,55432,-30783, 75952,80190,75952,-4238, 160235,102157,160235,58077, 171938,75465,171938,96472, 212298,102791,212298,109507, -3185845,-38,24 ],
  [ 2000,50,2,3,5, 103450,109129,51725,-2839, 149126,97339,74563,25893, 290613,220709,145307,34952, 343875,331259,171938,6308, 424596,362352,212298,31122, -3036436,-37,23 ],
  [ 2000,50,3,3,5, 151468,118706,50489,10921, 222299,202373,74100,6642, 420992,368160,140331,17611, 515813,383658,171938,44052, 636894,441013,212298,65294, -2748683,-34,21 ],
  [ 2000,50,5,3,5, 224762,218363,49039,1396, 354393,353138,70879,251, 668809,523808,133762,29000, 853298,581176,170660,54424, 1053100,776072,210620,55405, -2441046,-32,20 ],
  [ 2000,50,10,3,5, 375280,362600,45953,1553, 749381,704271,62448,3759, 1475358,957953,122946,43117, 2000889,1404627,166741,49689, 2469975,1821377,205831,54050, -684070,-10,0 ],
  [ 2000,50,15,3,5, 493260,399486,43845,8335, 1238403,1163188,56291,3419, 2564353,1738739,116562,37528, 3587827,2487324,163083,50023, 4459022,3149364,202683,59530, 1661481,28,0 ],
  [ 2000,50,25,3,5, 577213,429589,41979,10736, 2259961,2078799,47329,3794, 5514358,3740073,106045,34121, 7998648,5488837,153820,48266, 10264998,7317083,197404,56691, 7630606,63,0 ],
  [ 2000,50,50,3,5, 584296,431005,41735,10949, 3295689,3185529,41542,1389, 10604388,7150716,96404,31397, 15734347,10755486,143040,45262, 21074715,14730371,191588,57676, 17328193,90,0 ],
  [ 2000,50,75,3,5, 584296,431005,41735,10949, 3313936,3205031,41424,1361, 10768348,7212828,96146,31746, 15985788,10918239,142730,45246, 21437585,15051936,191407,57015, 17717772,91,0 ],
  [ 2000,100,1,3,5, 67092,101791,67092,-34699, 85533,81641,85533,3891, 202998,111060,202998,91938, 189183,79293,189183,109890, 233656,191410,233656,42246, -3126922,-37,24 ],
  [ 2000,100,2,3,5, 119356,112183,59678,3586, 164613,100536,82307,32039, 295491,338292,147746,-21401, 376450,258781,188225,58834, 332728,387881,166364,-27576, -3133111,-37,24 ],
  [ 2000,100,3,3,5, 173237,122560,57746,16892, 235876,196897,78625,12993, 410375,378897,136792,10493, 480427,258923,160142,73835, 535206,387952,178402,49085, -2567686,-33,20 ],
  [ 2000,100,5,3,5, 251385,223476,54848,6089, 389759,374795,77952,2993, 761734,542769,152347,43793, 938868,707744,187774,46225, 1158997,898607,231799,52078, -2212429,-30,18 ],
  [ 2000,100,10,3,5, 415127,370253,50832,5495, 820662,721466,68389,8266, 1646616,1190456,137218,38013, 2201348,1585713,183446,51303, 2717996,1891504,226500,68874, -367329,-4,0 ],
  [ 2000,100,15,3,5, 485710,370253,43174,10263, 1196813,721466,54401,21607, 2255682,1190456,102531,48419, 2346628,1585713,106665,34587, 2957832,1891504,134447,48469, 2890715,38,0 ],
  [ 2000,100,25,3,5, 623591,434934,45352,13721, 2279285,2201495,47734,1629, 4274789,3864095,82207,7898, 5827009,1636609,112058,80585, 7382429,1964053,141970,104200, 8553598,67,0 ],
  [ 2000,100,50,3,5, 642341,442152,45882,14299, 3590469,3488095,45258,1290, 11629893,7729510,105726,35458, 17281202,11778420,157102,50025, 23186079,16174274,210783,63744, 19766355,94,0 ],
  [ 2000,100,75,3,5, 642341,442152,45882,14299, 3610282,3494185,45129,1451, 11808823,7963246,105436,34336, 17556858,11974714,156758,49841, 23585197,16503979,210582,63225, 19849478,95,0 ],
  [ 2000,200,1,3,5, 90412,106270,90412,-15857, 104694,84845,104694,19849, 288524,220380,288524,68144, 223674,181984,223674,41690, 276373,200838,276373,75536, -3130848,-37,24 ],
  [ 2000,200,2,3,5, 150567,106270,75284,22149, 139327,183951,69663,-22312, 330465,225380,165233,52543, 247852,181984,123926,32934, 301983,200838,150991,50573, -2954854,-36,23 ],
  [ 2000,200,3,3,5, 210905,143654,70302,22417, 261901,334835,87300,-24311, 563773,438035,187924,41913, 671022,512139,223674,52961, 798207,582245,266069,71987, -2681137,-34,21 ],
  [ 2000,200,5,3,5, 304630,334568,66465,-6532, 460492,388840,92098,14330, 947582,716072,189516,46302, 1110007,841344,222001,53733, 1370793,962645,274159,81629, -2083920,-28,17 ],
  [ 2000,200,10,3,5, 494821,398895,60590,11746, 963224,843440,80269,9982, 1989131,1303729,165761,57117, 2602264,1795495,216855,67231, 3214037,2259123,267836,79576, 805362,18,0 ],
  [ 2000,200,15,3,5, 643737,441722,57221,17957, 1583862,1470900,71994,5135, 3392369,2372512,154199,46357, 4664161,3291633,212007,62388, 5801237,4075098,263693,78461, 3364188,42,0 ],
  [ 2000,200,25,3,5, 749528,547719,54511,14677, 2875013,2685415,60210,3971, 7178353,4808757,138045,45569, 10381675,7061038,199648,63858, 13353173,9478533,256792,74512, 11095073,74,0 ],
  [ 2000,200,50,3,5, 758431,549485,54174,14925, 4180029,3988728,52689,2411, 13680903,9148668,124372,41202, 20374911,13838513,185226,59422, 27408808,19092941,249171,75599, 23980026,102,0 ],
  [ 2000,200,75,3,5, 758431,549485,54174,14925, 4202974,3995762,52537,2590, 13889774,9219747,124016,41697, 20698999,14059212,184812,59284, 27880420,19437475,248932,75383, 24589192,103,0 ],
  [ 2500,0,1,3,5, 55408,86533,55408,-31125, 82272,82487,82272,-215, 140679,83910,140679,56769, 184297,78457,184297,105841, 227934,190325,227934,37609, -3214838,-38,24 ],
  [ 2500,0,2,3,5, 110816,111188,55408,-186, 164544,115826,82272,24359, 246593,125471,123296,60561, 319956,337317,159978,-8681, 452618,369878,226309,41370, -2936566,-36,23 ],
  [ 2500,0,3,3,5, 166223,122508,55408,14572, 246816,209280,82272,12512, 422037,354991,140679,22349, 552892,392784,184297,53369, 683802,536192,227934,49204, -2608578,-33,20 ],
  [ 2500,0,5,3,5, 248875,220108,54300,6276, 396121,378537,79224,3517, 690829,530150,138166,32136, 914504,704821,182901,41937, 1125558,747993,225112,75513, -2282352,-30,18 ],
  [ 2500,0,10,3,5, 424587,387876,51990,4495, 846643,819455,70554,2266, 1572541,1180189,131045,32696, 2144183,1579453,178682,47061, 2652828,1882554,221069,64190, -890497,-14,-17 ],
  [ 2500,0,15,3,5, 560855,429065,49854,11715, 1408998,1240797,64045,7646, 2770736,1924860,125943,38449, 3848082,2678257,174913,53174, 4788868,3490714,217676,59007, 2250235,33,0 ],
  [ 2500,0,25,3,5, 657886,533941,47846,9014, 2596552,2415688,54378,3788, 6054959,4140137,116442,36824, 8616143,6002527,165695,50262, 11014510,7893487,211818,60020, 8128646,65,0 ],
  [ 2500,0,50,3,5, 666081,535618,47577,8853, 3808032,3709446,48000,2280, 11796023,8053706,107237,17407, 17063655,11819767,78329,-29124, 22601150,16022267,100132,-45525, 12320659,78,0 ],
  [ 2500,0,75,3,5, 666081,535618,47577,9319, 3829402,3716089,47868,1416, 11982404,8198341,106986,33786, 17340080,12016822,154822,47529, 22990404,16237469,205271,60294, 18796613,93,0 ],
  [ 2500,25,1,3,5, 61238,100989,61238,-39751, 87062,83138,87062,3924, 162060,88362,162060,73699, 192920,80446,192920,112475, 238613,192819,238613,45794, -3182856,-38,24 ],
  [ 2500,25,2,3,5, 118769,112716,59384,3026, 172735,117425,86368,27655, 309193,331182,154596,-10995, 385840,341532,192920,22154, 477227,390778,238613,43224, -3125084,-37,24 ],
  [ 2500,25,3,3,5, 176300,137779,58767,12840, 258409,315876,86136,-19156, 456325,375088,152108,27079, 578761,398475,192920,60095, 715840,542905,238613,57645, -2850675,-35,22 ],
  [ 2500,25,5,3,5, 264121,328248,57626,-13991, 413804,380923,82761,6576, 737291,539705,147458,39517, 957289,715375,191458,48383, 1183704,907525,236741,55236, -2556567,-33,21 ],
  [ 2500,25,10,3,5, 366411,391702,44867,-3097, 861160,828052,71763,2759, 1489736,1080766,124145,34081, 2187649,1600515,182304,48928, 2597840,2038269,216487,46631, -1205151,-18,-34 ],
  [ 2500,25,15,3,5, 585934,433881,52083,13516, 1370638,1129000,62302,10984, 2817440,1759130,128065,48105, 4027471,2838269,183067,54055, 5012570,3553827,227844,66307, 3197964,40,0 ],
  [ 2500,25,25,3,5, 686605,539457,49935,10702, 2699061,2531266,56525,3514, 6332292,4407857,121775,37008, 7993830,5474967,153727,48440, 10692310,8248550,205621,46995, 7580133,63,0 ],
  [ 2500,25,50,3,5, 695103,541191,49650,10994, 3955422,3869037,49858,1089, 12308775,8395453,111898,35576, 17837083,12367125,162155,49727, 23656832,16834843,215062,62018, 19367871,94,0 ],
  [ 2500,25,75,3,5, 695103,541191,49650,10994, 3977575,3875916,49720,1271, 12502642,8527043,111631,35496, 18125615,12538447,161836,49885, 24064210,17053815,214859,62593, 19866171,95,0 ],
  [ 2500,50,1,3,5, 67068,102109,67068,-35041, 91853,83939,91853,7913, 183442,107328,183442,76114, 201543,97311,201543,104232, 249293,195213,249293,54079, -3151784,-37,24 ],
  [ 2500,50,2,3,5, 126722,114243,63361,6239, 180927,119023,90463,30952, 337027,336949,168514,39, 403086,345198,201543,28944, 498585,395456,249293,51564, -2995387,-36,23 ],
  [ 2500,50,3,3,5, 186376,139715,62125,15554, 270001,332157,90000,-20719, 490613,382021,163538,36197, 604629,418943,201543,61895, 747878,565540,249293,60779, -2754817,-34,22 ],
  [ 2500,50,5,3,5, 277432,330805,60531,-11645, 431487,384809,86297,9336, 783753,549261,156751,46898, 1000074,739405,200015,52134, 1236653,919091,247331,63512, -2327080,-31,19 ],
  [ 2500,50,10,3,5, 464434,395529,56869,8437, 917924,836649,76494,6773, 1743798,1229643,145317,42846, 2344642,1636603,195387,59003, 2900848,2164724,241737,61344, -121242,2,0 ],
  [ 2500,50,15,3,5, 611014,438698,54312,15317, 1524151,1357868,69280,7558, 3046741,2086746,138488,43636, 4206860,2972129,191221,56124, 5236273,3831532,238012,63852, 2843199,38,0 ],
  [ 2500,50,25,3,5, 715324,544972,52024,12389, 2801570,2677344,58672,2602, 6609624,4491027,127108,40742, 9410485,6540303,180971,55196, 12043902,8618283,231613,65877, 9200486,69,0 ],
  [ 2500,50,50,3,5, 724125,546765,51723,12669, 4102812,3998127,51716,1320, 12821528,8724185,116559,37249, 18610510,12871303,169186,52175, 24712514,17466670,224659,65871, 20641623,96,0 ],
  [ 2500,50,75,3,5, 724125,546765,51723,12669, 4125748,4019128,51572,1333, 13022880,8871609,116276,37065, 18911150,13045145,168850,52375, 25138016,17802553,224447,65495, 21022151,97,0 ],
  [ 2500,100,1,3,5, 78728,104348,78728,-25620, 101433,85541,101433,15892, 226205,116230,226205,109975, 218789,181297,218789,37492, 270651,199902,270651,70749, -3096959,-37,24 ],
  [ 2500,100,2,3,5, 142628,117298,71314,12665, 197310,196070,98655,620, 392697,362998,196348,14849, 437577,352578,218789,42500, 541302,404864,270651,68219, -3003275,-36,23 ],
  [ 2500,100,3,3,5, 206529,143585,68843,20981, 293186,336949,97729,-14588, 559189,410701,186396,49496, 656366,510283,218789,48694, 811953,579617,270651,77445, -2561405,-33,20 ],
  [ 2500,100,5,3,5, 304055,349255,66339,-9862, 466853,392432,93371,14884, 876677,582888,175335,58758, 1085644,757714,217129,65586, 1342550,958096,268510,76891, -1923265,-27,16 ],
  [ 2500,100,10,3,5, 504281,403182,61749,12379, 989205,867729,82434,10123, 1915056,1293462,159588,51800, 2545100,1789086,212092,63001, 3148869,2250022,262406,74904, 570668,15,0 ],
  [ 2500,100,15,3,5, 661173,533368,58771,11360, 1639304,1505288,74514,6092, 3322747,2367697,151034,43411, 4565638,3201370,207529,62012, 5683678,4059459,258349,73828, 2892820,39,0 ],
  [ 2500,100,25,3,5, 772763,569340,56201,14794, 3006587,2834499,62965,3604, 7164289,4843416,137775,44632, 10204828,7065054,196247,60380, 13073293,9230639,251409,73897, 10644334,73,0 ],
  [ 2500,100,50,3,5, 782170,571250,55869,15066, 4397592,4300543,55432,1223, 13847033,9379979,125882,40610, 20157365,13894387,183249,56936, 26823879,19008143,243853,71052, 22779135,100,0 ],
  [ 2500,100,75,3,5, 782170,571250,55869,15066, 4422093,4322016,55276,1251, 14063355,9544877,125566,40344, 20482221,14101770,182877,56968, 27285628,19254696,243622,71705, 23232078,101,0 ],
  [ 2500,200,1,3,5, 102049,108827,102049,-6778, 120594,88744,120594,31850, 311731,346115,311731,-34384, 253280,189253,253280,64026, 313368,322970,313368,-9602, -3319766,-39,25 ],
  [ 2500,200,2,3,5, 174441,123408,87221,25517, 230075,202464,115038,13806, 504036,400580,252018,51728, 506559,382265,253280,62147, 626736,439301,313368,93717, -2551266,-33,20 ],
  [ 2500,200,3,3,5, 246834,223026,82278,7936, 339556,346534,113185,-2326, 696341,545061,232114,50427, 759839,547324,253280,70838, 940104,736532,313368,67857, -2521924,-32,20 ],
  [ 2500,200,5,3,5, 357301,359481,77957,-476, 537586,421861,107517,23145, 1062526,756041,212505,61297, 1256783,904790,251357,70399, 1554346,1149345,310869,81000, -1538574,-23,11 ],
  [ 2500,200,10,3,5, 583974,431824,71507,18631, 1131766,915853,94314,17993, 2257572,1589785,188131,55649, 2946017,2092852,245501,71097, 3644910,2618141,303742,85564, 1417293,25,0 ],
  [ 2500,200,15,3,5, 761490,552634,67688,18565, 1869610,1665579,84982,9274, 3874758,2720520,176125,52465, 5283194,3696131,240145,72139, 6578488,4757266,299022,82783, 4370446,48,0 ],
  [ 2500,200,25,3,5, 887639,692269,64556,14209, 3416622,3178110,71552,4995, 8273619,5623695,159108,50960, 11793513,8209238,226798,68928, 15132077,10781682,291001,83661, 12588260,78,0 ],
  [ 2500,200,50,3,5, 897109,707729,64079,13527, 3707563,4660683,46734,-12014, 12483871,10301112,113490,19843, 18076861,15269468,164335,25522, 23781533,20870363,216196,26465, 5474466,55,0 ],
  [ 2500,200,75,3,5, 898260,707749,64161,13608, 5014785,4808209,62685,2582, 16144306,10878528,144146,47016, 23624362,16187768,210932,66398, 31580851,22189742,281972,83849, 27719188,108,0 ],
  [ 3000,0,1,3,5, 44192,103092,44192,-58900, 4641213,95233,4641213,4545981, 1645739,107526,1645739,1538212, 2344833,1211598,2344833,1133235, 3103211,1611350,3103211,1491861, 17118359,87,0 ],
  [ 3000,0,2,3,5, 134088,116303,67044,8892, 196345,197475,98173,-565, 327772,335756,163886,-3992, 427806,351306,213903,38250, 529857,403082,264929,63388, -3147378,-37,24 ],
  [ 3000,0,3,3,5, 201132,143516,67044,19205, 294518,339064,98173,-14849, 491658,383367,163886,36097, 641709,428119,213903,71197, 794786,576988,264929,72599, -2588478,-33,20 ],
  [ 3000,0,5,3,5, 303480,350604,66214,-10282, 473215,410059,94643,12631, 805772,555603,161154,50034, 1061280,754741,212256,61308, 1314308,953846,262862,72092, -2115404,-29,17 ],
  [ 3000,0,10,3,5, 513741,420805,62907,11380, 1015185,891868,84599,10276, 1840981,1268680,153415,47692, 2487936,1782777,207328,58763, 3083701,2241022,256975,70223, 314781,11,0 ],
  [ 3000,0,15,3,5, 678608,539977,60321,12323, 1694746,1553712,77034,6411, 3253124,2348217,147869,41132, 4467115,3191214,203051,57996, 5566119,4043570,253005,69207, 2641521,37,0 ],
  [ 3000,0,25,3,5, 678608,678271,49353,25, 3138161,3023608,35492,-27830, 3253124,4948715,62560,-32608, 9436671,7065709,181474,45595, 12016109,9193396,231079,54283, -1442745,-19,-12 ],
  [ 3000,0,50,3,5, 805910,680545,57565,8955, 4610807,4520544,58119,1138, 13141454,6970131,119468,56103, 17684357,11253067,160767,58466, 26032087,18738395,236655,66306, 26200424,105,0 ],
  [ 3000,0,75,3,5, 805910,680545,57565,8955, 4615269,4527954,57691,1091, 13293949,7030131,118696,55927, 17860617,11253067,159470,58996, 26267990,18738395,234536,67229, 26789494,106,0 ],
  [ 3000,25,1,3,5, 72874,103547,72874,-30672, 102963,87038,102963,15925, 185267,108047,185267,77221, 222526,182499,222526,40027, 275608,201361,275608,74247, -3186392,-38,24 ],
  [ 3000,25,2,3,5, 142041,117831,71021,12105, 204537,199074,102268,2731, 355607,341523,177803,7042, 445051,354971,222526,45040, 551216,407761,275608,71727, -3017681,-37,23 ],
  [ 3000,25,3,3,5, 203500,207319,67833,-1273, 309252,340845,103084,-10531, 524922,386453,174974,46156, 563038,355316,187679,69240, 695837,407376,231946,96154, -2691419,-34,21 ],
  [ 3000,25,5,3,5, 316792,353161,69118,-7935, 490898,413945,98180,15391, 852234,579674,170447,54512, 1104065,859129,220813,48987, 1367257,981134,273451,77225, -2049115,-28,17 ],
  [ 3000,25,10,3,5, 533664,424631,65347,13351, 1050826,900465,87569,12530, 1926610,1377664,160551,45745, 2588165,1803888,215680,65356, 3207712,2283696,267309,77001, 587395,15,0 ],
  [ 3000,25,15,3,5, 701893,424631,62391,24646, 1746582,915465,79390,37778, 2732313,1392664,124196,60893, 3675082,1823888,167049,84145, 4544517,2283696,206569,102765, 7751586,63,0 ],
  [ 3000,25,25,3,5, 824716,684007,59979,20165, 3240670,3142196,67867,17406, 7427557,5144145,142838,25762, 10425151,7263763,200484,35600, 13308109,9566821,255925,43477, 12787567,79,0 ],
  [ 3000,25,50,3,5, 834932,699455,59638,9677, 4762545,4679985,60032,1041, 14525915,10045923,132054,40727, 20713246,14484592,188302,56624, 27294632,19489012,248133,70960, 22390154,99,0 ],
  [ 3000,25,75,3,5, 834932,699455,59638,9677, 4789386,4688363,59867,1263, 14757174,10123339,131760,41374, 21050978,14667054,187955,56999, 27764641,19722351,247899,71806, 23198899,101,0 ],
  [ 3000,50,1,3,5, 78704,104666,78704,-25962, 107753,87839,107753,19914, 206649,112498,206649,94151, 231148,184338,231148,46810, 286287,203705,286287,82582, -3099717,-37,24 ],
  [ 3000,50,2,3,5, 149994,119358,74997,15318, 212728,200672,106364,6028, 383441,347290,191721,18076, 462297,373613,231148,44342, 572574,412440,286287,80067, -2910088,-36,22 ],
  [ 3000,50,3,3,5, 221285,219087,73762,733, 317703,343856,105901,-8718, 560234,412047,186745,49396, 693445,519459,231148,57995, 858861,606736,286287,84042, -2700224,-34,21 ],
  [ 3000,50,5,3,5, 330103,355717,72023,-5589, 508581,417831,101716,18150, 898696,695279,179739,40683, 1146850,868683,229370,55633, 1420206,993201,284041,85401, -2042902,-28,17 ],
  [ 3000,50,10,3,5, 553587,428458,67786,15322, 1086466,909063,90539,14784, 2012239,1395134,167687,51425, 2688394,1948386,224033,61667, 3331722,2523192,277643,67378, 747464,17,0 ],
  [ 3000,50,15,3,5, 728767,549610,64779,15925, 1809899,1670782,82268,6323, 3529130,2447618,160415,49160, 4825893,3391653,219359,65193, 6013524,4384438,273342,74049, 3515453,43,0 ],
  [ 3000,50,25,3,5, 853435,702859,62068,10951, 3343178,3183774,70014,3338, 7704890,5305815,148171,46136, 10822322,7580193,208122,62349, 13822805,9921533,265823,75024, 10755471,73,0 ],
  [ 3000,50,50,3,5, 862793,705026,61628,11269, 3619613,4603653,45625,-12404, 11668761,9956280,106080,15568, 16573013,14403648,150664,19721, 21615603,19233996,196505,21651, 2851659,40,0 ],
  [ 3000,50,75,3,5, 863954,705029,61711,11352, 4937559,4831575,61719,4174, 15277411,9956280,104185,15290, 16573013,14403648,147973,19369, 21615603,19233996,192996,21264, 11172908,75,0 ],
  [ 3000,100,1,3,5, 90364,106906,90364,-16541, 117334,89441,117334,27893, 249412,212915,249412,36496, 248394,188066,248394,60328, 307646,321534,307646,-13889, -3216997,-38,24 ],
  [ 3000,100,2,3,5, 157524,120952,78762,18286, 157618,192877,78809,-17629, 351933,360796,175966,-4432, 381252,366689,190626,7281, 470308,429660,235154,20324, -3336529,-39,25 ],
  [ 3000,100,3,3,5, 241437,222957,80479,6160, 340888,348648,113629,-2587, 628810,440727,209603,62694, 745182,545468,248394,66571, 922937,733903,307646,63011, -2491024,-32,20 ],
  [ 3000,100,5,3,5, 356726,360830,77831,-895, 543948,499453,108790,8899, 991621,728906,198324,52543, 1232419,901817,246484,66120, 1526104,1145146,305221,76192, -1920910,-27,15 ],
  [ 3000,100,10,3,5, 567224,429959,69456,16808, 716509,999513,59709,-23584, 1636654,1493959,136388,11891, 2123836,1992101,176986,10978, 2617123,1160617,218094,121376, -2198046,-29,18 ],
  [ 3000,100,15,3,5, 758411,652720,67414,9395, 1962430,1711229,89201,11418, 3061710,2522098,139169,24528, 4120329,2108713,187288,91437, 5106043,2619293,232093,113034, 3774456,45,0 ],
  [ 3000,100,25,3,5, 910873,713890,66245,14326, 3548196,3445429,74308,2152, 8259555,5656705,158838,50055, 11616665,8024486,223397,69080, 14852197,10548910,285619,82756, 12034741,77,0 ],
  [ 3000,100,50,3,5, 921999,716177,65857,14702, 5204715,5037791,65606,2104, 16064173,10953449,146038,46461, 23033528,15931397,209396,64565, 30461679,21662161,276924,79996, 26384915,106,0 ],
  [ 3000,100,75,3,5, 921999,716177,65857,14702, 5233905,5134613,65424,1241, 16317887,11218173,145695,45533, 23407584,16245203,208996,63950, 30986059,22036172,276661,79910, 26340265,106,0 ],
  [ 3000,200,1,3,5, 113685,111384,113685,2300, 136495,92644,136495,43851, 334938,351285,334938,-16348, 282885,195973,282885,86912, 350362,347133,350362,3230, -3160348,-38,24 ],
  [ 3000,200,2,3,5, 197713,141859,98857,27927, 261876,314463,130938,-26293, 550450,423936,275225,63257, 565770,395904,282885,84933, 700725,539765,350362,80480, -2684631,-34,21 ],
  [ 3000,200,3,3,5, 281742,331565,93914,-16607, 387258,370618,129086,5547, 765962,560422,255321,68513, 848655,583959,282885,88232, 1051087,779128,350362,90653, -2500683,-32,20 ],
  [ 3000,200,5,3,5, 409972,371057,89448,8491, 614680,528882,122936,17160, 1177469,873009,235494,60892, 1403559,953110,280712,90090, 1737899,1290914,347580,89397, -1294640,-20,-50 ],
  [ 3000,200,10,3,5, 673128,536453,82424,16736, 1300309,1166466,108359,11154, 2526012,1753775,210501,64353, 3289769,2404636,274147,73761, 4075783,2960438,339649,92945, 1442786,26,0 ],
  [ 3000,200,15,3,5, 879244,692713,78155,16581, 2155358,1976994,97971,8107, 4357146,2960827,198052,63469, 5902227,4101628,268283,81845, 7355739,5311123,334352,92937, 5223992,52,0 ],
  [ 3000,200,25,3,5, 1025750,749290,74600,20106, 3958230,3686041,82895,5700, 9368884,6452999,180171,56075, 13205350,9167370,253949,77653, 16910980,12083482,325211,92837, 14641943,84,0 ],
  [ 3000,200,50,3,5, 1038089,751809,74149,20449, 5794275,5538424,73037,3225, 18115183,12372606,164683,52205, 26127238,17991490,237520,73961, 34684408,24580828,315313,91851, 30892570,112,0 ],
  [ 3000,200,75,3,5, 1038089,751809,74149,20449, 5794275,5538424,72428,3198, 18312446,12442302,163504,52412, 26138576,18009164,233380,72584, 34699722,24584484,309819,90315, 31183699,112,0 ]

];

export { data } ;

<template>
  <div class="proforma__content">
    <b-container fluid>
      <div class="proforma__intro">
        <h1>Increase Your Profits</h1>
        <p>Please fill out the form below to see an accurate estimate of the profit increase you can expect when you
          sign up for the
          <template v-if="isSHS">Sustainable Health Systems</template>
          <template v-else>Wellcoaches Healthcare</template>
          service.</p>
      </div>

      <b-row>
        <b-col class="proforma-form">
          <dl class="proforma-form__entry">
            <dt>
              What is the average number of patients in your practices?
            </dt>
            <dd>
              <Radios id="patientsEntry" :items="patientsOptions" :dflt="patientsEntry" @change="change"/>
            </dd>
          </dl>
          <dl class="proforma-form__entry">
            <dt>
              How many PCP's are in your network?
            </dt>
            <dd>
              <Radios id="pcpEntry" :items="pcpOptions" :dflt="pcpEntry" @change="change"/>
            </dd>
          </dl>
          <dl class="proforma-form__entry">
            <dt>
              What is your operating efficiency?
            </dt>
            <dd>
              <Radios id="efficiencyEntry" :items="efficiencyOptions" :dflt="efficiencyEntry" @change="change"/>
            </dd>
          </dl>
          <dl class="proforma-form__entry">
            <dt>
              What is your growth rate (patients/year)?
            </dt>
            <dd>
              <Radios id="growthEntry" :items="growthOptions" :dflt="growthEntry" @change="change" />
            </dd>
          </dl>
          <dl class="proforma-form__entry">
            <dt>
              What is your payer mix?
            </dt>
            <dd>
              <Radios id="mixEntry" :items="mixOptions" :dflt="mixEntry" @change="change"/>
            </dd>
          </dl>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped hover borderless small responsive="md" stacked="lg" ref="table"
                   class="proforma-table proforma-table__subtotals" :items="items"
                   :fields="fields">
            <template #cell(name)="data">
              <div class="proforma-table__head">{{ data.value }}</div>
            </template>
            <template #cell()="data">
              <div class="data">{{ number(data.value) }}</div>
            </template>
          </b-table>
          <b-table hover borderless small responsive="md" stacked="sm" ref="table"
                   class="proforma-table proforma-table__totals"
                   :items="items2"
                   :fields="fields2">
            <template #cell(name)="data">
              <div class="proforma-table__head">{{ data.value }}</div>
            </template>
            <template #cell()="data">
              <div class="performa-table__result data">{{ data.index ? percent(data.value) : number(data.value) }}</div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>

    <div class="proforma__cta">
      <h3>Need more detail?</h3>
      <a v-if="isSHS" href="mailto:shlynch@sustainablehealthsystems.com" class="button">Contact Us Today</a>
      <a v-else href="mailto:shlynch@sustainablehealthsystems.com" class="button">Contact Us Today</a>
      <p>and we can provide an extremely robust and highly detailed pro forma for your institution's
        service line</p>
    </div>

  </div>
</template>

<script>
import Radios from '@/components/Radios.vue';
import {data} from '@/data.js';

export default {
  name: 'Home',
  components: {
    Radios
  },
  data() {
    return {
      NUMBER_INTERVAL: 75,
      NUMBER_DURATION: 500,
      patientsOptions: [],
      growthOptions: [],
      pcpOptions: [],
      efficiencyOptions: ['Poor', 'Fair', 'Good', 'Excellent'],
      mixOptions: ['Mostly private', 'Strong Medicare', 'Mixed blend', 'No Medicaid', 'Strong Medicaid / managed care', 'Dominant Medicaid / FQHC'],
      tableLabels: ['Revenue', 'Expenses', 'Revenues per PCP-year', 'Margin per PCP-year'],
      patientsEntry: 2,
      pcpEntry: 4,
      efficiencyEntry: 2,
      growthEntry: 2,
      mixEntry: 0,
      fields: [
        {key: 'name', label: '', class: 'head', stickyColumn: true},
        {key: 'y1', label: 'Year 1'},
        {key: 'y2', label: 'Year 2'},
        {key: 'y3', label: 'Year 3'},
        {key: 'y4', label: 'Year 4'},
        {key: 'y5', label: 'Year 5'}],
      fields2: [
        {key: 'name', label: '', class: 'head', stickyColumn: true},
        {key: 'n', label: ''}],
      items: [
        {name: 'Revenue', y1: 0, y2: 0, y3: 0, y4: 0, y5: 0},
        {name: 'Expenses', y1: 0, y2: 0, y3: 0, y4: 0, y5: 0},
        {name: 'Revenues per PCP-year', y1: 0, y2: 0, y3: 0, y4: 0, y5: 0},
        {name: 'Margin per PCP-year', y1: 0, y2: 0, y3: 0, y4: 0, y5: 0},
      ],
      npv: 0, npvBase: 0, npvTarget: 0,
      aroi: 0, aroiBase: 0, aroiTarget: 0,
      irr: 0, irrBase: 0, irrTarget: 0,
      items2: [
        {name: 'Net Present Value', n: 0, spacer: ''},
        {name: 'Annualized Return of Investment (AROI)', n: 0, spacer: ''},
        {name: 'Internal Rate of Return (IRR)', n: 0, spacer: ''},
      ],
      startTimer: 0,
      intervalTimer: null,
    }
  },
  computed: {
    iconClass() {
      return this.showMenu ? 'menu-open' : 'menu-closed';
    },
    isLocalhost() {
      return window.location.host === 'localhost:8080';
    },
    isWellcoaches() {
      return (window.location.host === 'proforma.wellcoaches.app');
    },
    isSHS() {
      return this.isLocalhost || (window.location.host === 'proforma.sustainablehealthsystems.com');
    }
  },
  watch: {
    patientsEntry: function () {
      this.refresh()
    },
    pcpEntry: function () {
      this.refresh()
    },
    efficiencyEntry: function () {
      this.refresh()
    },
    growthEntry: function () {
      this.refresh()
    },
    mixEntry: function () {
      this.refresh()
    },
  },
  mounted() {
    //  retrieve available options
    let patientsOptions = [];
    let growthOptions = [];
    let pcpOptions = [];
    for (let record of data) {
      if (!patientsOptions[record[0]])
        patientsOptions[record[0]] = true;
      if (!growthOptions[record[1]])
        growthOptions[record[1]] = true;
      if (!pcpOptions[record[2]])
        pcpOptions[record[2]] = true;
    }
    this.patientsOptions = Object.keys(patientsOptions);
    this.growthOptions = Object.keys(growthOptions);
    this.pcpOptions = Object.keys(pcpOptions);
    this.refresh();
  },
  methods: {
    change(id, value) {
      this[id] = value;
    },
    number(val) {
      return (val || (val === 0)) ? ('$\xA0' + Number(val).toLocaleString()).padStart(11, '\xA0') : '';
    },
    percent(val) {
      return (val || (val === 0)) ? (Number(Math.round(val)).toLocaleString() + '%').padStart(1, '\xA0') : '';
    },
    refresh() {
      // clear animation
      if (this.intervalTimer) {
        // cleaer interval
        window.clearInterval(this.intervalTimer);
        this.intervalTimer = null;
        // set values to target
        this.npv = this.npvTarget;
        this.aroi = this.aroiTarget;
        this.irr = this.irrTarget;
      }
      // retrieve values
      let patients = this.patientsOptions[this.patientsEntry];
      let growth = this.growthOptions[this.growthEntry];
      let pcp = this.pcpOptions[this.pcpEntry];
      let efficiency = this.efficiencyEntry;
      let mix = this.mixEntry;
      // find data record
      //let count = 0;
      for (let record of data) {
        if ((record[0] == patients) &&
            (record[1] == growth) &&
            (record[2] == pcp) &&
            (record[3] == efficiency) &&
            (record[4] == mix)
        ) {
          // number of rows
          let rows = this.tableLabels.length;
          // remember current values
          this.itemsBase = [...this.items];
          this.npvBase = this.npv;
          this.aroiBase = this.aroi;
          this.irrBase = this.irr;
          // revenue,expense,margin targets
          this.itemsTarget = this.tableLabels.map(label => {
            return {name: label}
          });
          for (let y = 0; y < 5; y++)
            for (let i = 0; i < rows; i++)
              this.itemsTarget[i]['y' + (y + 1)] = record[5 + rows * y + i];
          // npv target
          this.npvTarget = record[5 + rows * 5];
          // aroi target
          this.aroiTarget = record[5 + rows * 5 + 1];
          // irr target
          this.irrTarget = record[5 + rows * 5 + 2];
          // animate
          this.startTimer = (new Date()).getTime();
          this.intervalTimer = window.setInterval(this.tick, this.NUMBER_INTERVAL);
          return;
        }
      }
      //  not found - clear items
      for (let i in this.items)
        for (let y = 1; y <= 5; y++)
          this.items[i]['y' + y] = 0;
      this.items2[0].n = this.npv = 0;
      this.items2[1].n = this.aroi = 0;
      this.items2[2].n = this.irr = 0;
    },
    tick() {
      let fraction = ((new Date()).getTime() - this.startTimer) / this.NUMBER_DURATION;
      if (fraction < 1) {
        // update values
        for (let y = 0; y < 5; y++) {
          let year = 'y' + (y + 1);
          for (let i = 0; i < this.items.length; i++)
            this.items[i][year] = this.itemsBase[i][year] ? Math.round(this.itemsBase[i][year] + fraction * (this.itemsTarget[i][year] - this.itemsBase[i][year])) : this.itemsTarget[i][year];
        }
        this.items2[0].n = this.npv = Math.round(this.npvBase + fraction * (this.npvTarget - this.npvBase));
        this.items2[1].n = this.aroi = Math.round(this.aroiBase + fraction * (this.aroiTarget - this.aroiBase));
        this.items2[2].n = this.irr = Math.round(this.irrBase + fraction * (this.irrTarget - this.irrBase));
      } else if (this.intervalTimer) {
        // clear interval timer
        window.clearInterval(this.intervalTimer);
        this.intervalTimer = null;
        // set values to end values
        for (let y = 0; y < 5; y++) {
          let year = 'y' + (y + 1);
          for (let i = 0; i < this.items.length; i++)
            this.items[i][year] = this.itemsTarget[i][year];
        }
        this.items2[0].n = this.npv = this.npvTarget;
        this.items2[1].n = this.aroi = this.aroiTarget;
        this.items2[2].n = this.irr = this.irrTarget;
      }
    },
  }
}
</script>


<style lang="scss">

$blue: #22AFE1;

.button {
  background-color: $blue;
  color: #fff;
  padding: .5em 1em;

  line-height: 1;
  border-radius: 3px;
  display: inline-block;

  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: lighten($blue, 10%);
  }
}

.proforma__content {
  padding: 1em 0;
  background: url(/../../images/chart-bg.png) no-repeat center top;
  background-size: 100% auto;
  @media (max-width: 767px) {
    background-size: 200% auto;
  }

  .data {
    font-family: Lucida Console, Courier, monospace;
  }
}

.proforma__intro {
  margin: 2em auto 2em auto;
  font-size: 1.15em;
  box-shadow: 10px 12px 0 0 rgba(24, 131, 177, .66);
  background-color: $blue;
  padding: 1em;
  color: #fff;
  max-width: 600px;
  @media (max-width: 767px) {
    max-width: 80%;
  }
}

.proforma-form {
  max-width: 1060px !important;
  margin: 0 auto;

  label {
    padding-right: 1.25em;
    @media (max-width: 990px) {
      margin: 5px 0;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.proforma-form__entry {
  background-color: #fff;
  padding: 1.5em;
  border: solid 2px #ddd;
  @media (min-width: 768px) {
    &:not(:last-child) {
      width: 49.5%;
    }
  }

  dd {
    margin-bottom: 0;
  }
}

.proforma-table {
  max-width: 1030px !important;
  margin: 0 auto 1rem auto;
  border: solid 2px #ddd;
  padding: 10px;
  background-color: #fff;

  .table-hover tbody tr:nth-of-type(even):hover,
  .table.b-table.table-striped > tbody > tr:nth-of-type(odd) > .table-b-table-default {
    background: transparent;
  }

  .table.b-table.table-hover > tbody > tr:hover > .table-b-table-default {
    background: transparent;
  }

  .table-sm th, .table-sm td {
    padding: .5em;
  }

  .table {
    th {
      padding-left: 3em;
    }

    td {
      &.spacer {
        width: 0;
        display: none;
      }
    }
  }
}

.proforma-table__head {
  white-space: nowrap;
  font-weight: bold;
  @media (min-width: 768px) {
    padding-left: 10px;
  }
}

.table.b-table.b-table-stacked-sm > tbody > tr > [data-label] > div {
  padding: 0 !important;
}

.proforma-table__subtotals {
  .table-hover tbody tr:nth-of-type(odd):hover {
    background-color: rgba(0, 0, 0, .05);
  }

  .proforma-table__head {
    @media (max-width: 990px) {
      font-size: 1.25em;
    }
  }

  td:not(:first-child) {
    padding: 5px 10px !important;
  }

  tr {
    @media (max-width: 990px) {
      padding-bottom: 1em;
      padding-top: 1em;
    }
  }

  }

.proforma-table__totals {
  margin: 0 auto;
  max-width: 600px !important;
  font-size: 1.25em;

  .table {
    width: 100%;

    th {
      display: none;
    }

    td {
      font-weight: bold;

      &:first-child {
        background-color: #fff !important;

        > div {
          text-align: center;
          width: 100% !important;
        }

        @media (min-width: 576px) {
          > div {
            text-align: right;
          }
        }
      }

      &:nth-child(2) {
        background-color: $blue;
        color: #fff;

        > div {
          text-align: center;
          width: 100% !important;
        }
      }
    }
  }

  tr:first-child {
    border-bottom: solid 5px #fff;
  }
}

.proforma__cta {
  margin: 1em auto;
  max-width: 500px;
  padding: 2em 3em;
  background: $blue;
  color: #fff;
  text-align: center;

  p {
    font-size: 1.25em;
  }

  .button {
    font-size: 1.75em;
    border: solid 2px #fff;
    margin: 10px 0 15px 0;
    text-decoration: none;
    @media (min-width: 768px) {
      font-size: 2em;
    }
  }
}

</style>
